import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../Config";
import { CITY_LIST_WITHOUT_PAGINATION, EMPLOYEE_PROFILE, EMPLOYEE_PROFILE_API, STATE_LIST_WITHOUT_PAGINATION } from "../../Helper/ApiPath";
import { ADDRESS, CANCEL, CITY, EDIT_PROFILE, EDIT_PROFILE_DETAILS, EDIT_PROFILE_INFORMATION, FIRST_NAME, HOME, LAST_NAME, SELECT, STATE, UPDATE } from "../../Helper/Constants";
import { ENTER_FNAME } from "../../Helper/Messages";
import Select from "react-select";

function EditEmployeeProfile() {
    const [isLoading, setIsLoading] = useState(false);

    let logToken = JSON.parse(localStorage.getItem("logToken")).token;
    let navigate = useNavigate();

    const [profileInfo, setProfileInfo] = useState({
        firstName: "",
        lastName: "",
        stateId: "",
        cityId: "",
        address: "",
    });
    const [city, setCity] = useState({
        stateId: "",
        cityId: "",
    });

    const handleCityChange = (e) => {
        setCity({ ...city, cityId: e.value });
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfileInfo({ ...profileInfo, [name]: value });
    };

    const fetchData = async () => {
        await axios
            .get(`${baseURL}/${EMPLOYEE_PROFILE_API}`, {
                headers: { authorization: logToken },
            })
            .then((res) => {
                setProfileInfo(res.data.data);
                setCity(res.data.data);
                setStateId(res.data.data.stateId);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleUpdate = async () => {
        if (!profileInfo.firstName) {
            toast.error(ENTER_FNAME);
        } else {
            setIsLoading(true);
            await axios
                .post(`${baseURL}/${EMPLOYEE_PROFILE}`, {
                    headers: { authorization: logToken },
                    firstName: profileInfo.firstName,
                    lastName: profileInfo.lastName,
                    state: city.stateId,
                    city: city.cityId,
                    address: profileInfo.address,
                })
                .then((res) => {
                    setIsLoading(false);

                    if (res.status === 200) {
                        window.location.reload();
                        setCity("");
                        setCityList("");
                        toast.success(res.data.message);
                    }
                })
                .catch((err) => {
                    setIsLoading(false);

                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const handleCancel = () => {
        navigate("/Profile");
    };

    const [stateList, setStateList] = useState([]);

    const stateData = async () => {
        await axios
            .post(`${baseURL}/${STATE_LIST_WITHOUT_PAGINATION}`)
            .then((res) => {
                setStateList(res.data.data);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };

    const [cityList, setCityList] = useState([]);

    const [stateId, setStateId] = useState("");

    const handleStateChange = (e) => {
        setCity({ ...city, stateId: e.value, cityId: "" });
        setCityList("");
        cityData();
        setStateId(e.value);
    };

    const cityData = async () => {
        axios
            .post(`${baseURL}/${CITY_LIST_WITHOUT_PAGINATION}`, {
                stateId: stateId,
                search: "",
            })
            .then((res) => {
                setCityList(res.data.data);
            });
    };
    const stateOptions =
        stateList &&
        stateList.map((item, i) => {
            return {
                id: item.id,
                value: item.id,
                label: item.name,
            };
        });

    const cityOptions =
        cityList &&
        cityList.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });
    useEffect(() => {
        stateData();
        cityData();
    }, [stateId]);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className="pull-left">
                                <div className="page-title">{EDIT_PROFILE_INFORMATION}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{EDIT_PROFILE}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="card card-box">
                                <div className="card-head">
                                    <header>{EDIT_PROFILE_DETAILS}</header>
                                </div>
                                <div className="card-body" id="bar-parent">
                                    <form action="#" id="form_sample_1" className="form-horizontal">
                                        <div className="form-body">
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {FIRST_NAME}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="firstName"
                                                        placeholder=" Enter first name"
                                                        value={profileInfo.firstName}
                                                        onChange={handleChange}
                                                        className="form-control input-height"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{LAST_NAME}</label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="lastName"
                                                        placeholder=" Enter last name"
                                                        value={profileInfo.lastName}
                                                        onChange={handleChange}
                                                        className="form-control input-height"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{ADDRESS}</label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="address"
                                                        placeholder="Enter Address"
                                                        value={profileInfo.address}
                                                        onChange={handleChange}
                                                        className="form-control input-height"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{STATE}</label>
                                                <div className="col-md-5">
                                                    <Select
                                                        name="stateId"
                                                        value={stateOptions && stateOptions.filter(({ value }) => value == city.stateId)}
                                                        options={stateOptions}
                                                        onChange={handleStateChange}
                                                        placeholder={SELECT}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{CITY}</label>
                                                <div className="col-md-5">
                                                    <Select
                                                        name="cityId"
                                                        value={cityOptions && cityOptions.filter(({ value }) => value == city.cityId)}
                                                        options={cityOptions}
                                                        onChange={handleCityChange}
                                                        placeholder={SELECT}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-actions">
                                                <div className="row">
                                                    <div className="offset-md-3 col-md-9">
                                                        {isLoading ? (
                                                            <>
                                                                <button type="button" className="btn btn-primary m-r-20" disabled>
                                                                    {UPDATE}
                                                                </button>
                                                                <i
                                                                    className="fa fa-spinner fa-spin fa-2x"
                                                                    style={{
                                                                        position: "relative",
                                                                        top: "9px",
                                                                        left: "-71px",
                                                                        color: "#fff",
                                                                    }}
                                                                ></i>
                                                            </>
                                                        ) : (
                                                            <button type="button" className="btn btn-primary m-r-20" onClick={handleUpdate}>
                                                                {UPDATE}
                                                            </button>
                                                        )}
                                                        <button type="button" className="btn btn-default m-r-20" onClick={handleCancel}>
                                                            {CANCEL}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditEmployeeProfile;
