import React from "react";
import { Link } from "react-router-dom";
import {
    ACCELERATE_WAITING_PERIOD,
    ACCELERATE_WAITING_PERIOD_TITLE,
    CANCEL,
    CA_MEDICARE_SOUTH,
    CLAIM_CODE,
    CLAIM_INFORMATION,
    DASH,
    DATE_OF_SERVICE,
    DIAGNOSES,
    FILE_NAME,
    GENERAL_INFORMATION,
    ID,
    INSURANCE_BACK,
    INSURANCE_FRONT,
    OTHERS,
    LOWER_JAW,
    NEW_PATIENT,
    NO,
    NOTE,
    PATIENT_DOCUMENTS,
    PATIENT_NAME,
    PAYER,
    PREVIOUS,
    PRE_AUTHORIZATION,
    PROCEDURE,
    PROCEDURE_DOCTOR_TYPE,
    PROCEDURE_TYPE,
    RENDERRING_PROVIDER,
    SAVE,
    SERVICE_LOCATION_TYPE,
    SERVICE_LOCATION_TYPE_TITLE,
    SUBMIT,
    THUMBNAIL,
    UPPER_JAW,
    XRAY,
    CREATED_DATE,
    REFERRING_PHYSICIAN_NAME,
} from "../../../Helper/Constants";
import { dateFormateMonthDateYear } from "../../../Helper/Helper";
import NoDataFound from "../../CommonComponent/NoDataFound";
import Zoom from "react-medium-image-zoom";
import { MAX_PRIORITY } from "../../../Helper/Messages";
import Cookies from "universal-cookie";

function ConfirmationClaim(props) {
    const cookies = new Cookies();
    const claimId = localStorage.getItem("claimId");

    let pageAuthorityRoleId = cookies.get("role");
    if (pageAuthorityRoleId) {
        pageAuthorityRoleId = cookies.get("role").pageAuthorityRoleId;
    }

    return (
        <>
            <div id="confirmClaim">
                <div className="page-bar">
                    <div className="page-title-breadcrumb">
                        <div className=" pull-left">
                            <div className="page-title">{GENERAL_INFORMATION}</div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="card card-box">
                            <div className="card-head">
                                <header>{CLAIM_INFORMATION}</header>
                            </div>

                            <div className="card-body" style={{ paddingRight: "300px", paddingLeft: " 35px" }}>
                                <div className="form-horizontal">
                                    <div className="form-body">
                                        <div className="pull-left">
                                            <div>
                                                <b>{PAYER}</b> : <span>{props.addClaim.payerLabel ? props.addClaim.payerLabel : CA_MEDICARE_SOUTH}</span>
                                            </div>
                                            <div>
                                                <b>{PATIENT_NAME}</b> : <span>{props.addClaim.patientLabel}</span>
                                            </div>
                                            <div>
                                                <b>{DATE_OF_SERVICE}</b> : <span>{props.addClaim.serviceDate ? dateFormateMonthDateYear(props.addClaim.serviceDate) : DASH}</span>
                                            </div>
                                            <div>
                                                <b>{PRE_AUTHORIZATION}</b> : <span>{props.addClaim.preAuthorization ? props.addClaim.preAuthorization : DASH}</span>
                                            </div>
                                        </div>
                                        <div className="pull-right">
                                            <div>
                                                <b>{SERVICE_LOCATION_TYPE_TITLE}</b> :{" "}
                                                {claimId ? (
                                                    <span>{Object.keys(SERVICE_LOCATION_TYPE).find((key) => SERVICE_LOCATION_TYPE[key] === props.addClaim.serviceLocationLabel)}</span>
                                                ) : (
                                                    <span>{props.addClaim.serviceLocationLabel}</span>
                                                )}
                                            </div>
                                            <div>
                                                <b>{REFERRING_PHYSICIAN_NAME}</b> : <span>{props.addClaim.referringPhysicianName ? props.addClaim.referringPhysicianName : DASH}</span>
                                            </div>
                                            <div>
                                                <b>{RENDERRING_PROVIDER}</b> : <span>{props.doctorLabel ? props.doctorLabel : props.addClaim.providerLabel}</span>
                                            </div>
                                            <div>
                                                <b>{ACCELERATE_WAITING_PERIOD_TITLE}</b> :{" "}
                                                {claimId ? (
                                                    <span>
                                                        {props.addClaim.waitingPeriodLabel
                                                            ? Object.keys(ACCELERATE_WAITING_PERIOD).find((key) => ACCELERATE_WAITING_PERIOD[key] === props.addClaim.waitingPeriodLabel)
                                                            : DASH}
                                                    </span>
                                                ) : (
                                                    <span>{props.addClaim.waitingPeriod ? props.addClaim.waitingPeriodLabel : DASH}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Image Section */}
                <div className="card">
                    <div className="card-head">
                        <header>{PATIENT_DOCUMENTS}</header>
                    </div>
                    <div className="card-body no-padding height-9">
                        <div className="row mx-2">
                            <div className="col-6">
                                <div className="d-flex justify-content-between my-2 mx-2">
                                    <div>
                                        <p style={{ fontSize: "Larger", fontWeight: "600" }}>{INSURANCE_FRONT}</p>
                                    </div>
                                </div>
                                <div className="table-scrollable">
                                    <table className="table table-hover table-checkable order-column full-width" id="example4">
                                        <thead>
                                            <tr>
                                                <th>{NO}</th>
                                                <th>{FILE_NAME}</th>
                                                <th>{THUMBNAIL}</th>
                                                <th>{CREATED_DATE}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!props.insuranceF ? (
                                                <>{<NoDataFound />}</>
                                            ) : (
                                                props.insuranceF &&
                                                props.insuranceF.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{(1 - 1) * 100 + i + 1}</td>
                                                            <td>
                                                                <div data-toggle="tooltip" title={item.fileName}>
                                                                    {item.fileName ? (item.fileName.length > 20 ? item.fileName.slice(0, 20) + "..." : item.fileName) : INSURANCE_FRONT + ".png"}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <Zoom>
                                                                    <img src={item.image} width="100" height="100" alt="Patient Document" style={{ height: "50px", width: "50px" }} />
                                                                </Zoom>
                                                            </td>
                                                            <td>{item.created_at ? dateFormateMonthDateYear(item.created_at) : "-"}</td>
                                                        </tr>
                                                    );
                                                })
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="d-flex justify-content-between my-2 mx-2">
                                    <div>
                                        <p style={{ fontSize: "Larger", fontWeight: "600" }}>{INSURANCE_BACK}</p>
                                    </div>
                                </div>
                                <div className="table-scrollable">
                                    <table className="table table-hover table-checkable order-column full-width" id="example4">
                                        <thead>
                                            <tr>
                                                <th>{NO}</th>
                                                <th>{FILE_NAME}</th>
                                                <th>{THUMBNAIL}</th>
                                                <th>{CREATED_DATE}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!props.insuranceB ? (
                                                <>{<NoDataFound />}</>
                                            ) : (
                                                props.insuranceB &&
                                                props.insuranceB.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{(1 - 1) * 100 + i + 1}</td>
                                                            <td>
                                                                <div data-toggle="tooltip" title={item.fileName}>
                                                                    {item.fileName ? (item.fileName.length > 20 ? item.fileName.slice(0, 20) + "..." : item.fileName) : INSURANCE_BACK + ".png"}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <Zoom>
                                                                    <img src={item.image} width="100" height="100" alt="Patient Document" style={{ height: "50px", width: "50px" }} />
                                                                </Zoom>
                                                            </td>
                                                            <td>{item.created_at ? dateFormateMonthDateYear(item.created_at) : "-"}</td>
                                                        </tr>
                                                    );
                                                })
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="d-flex justify-content-between my-2 mx-2">
                                    <div>
                                        <p style={{ fontSize: "Larger", fontWeight: "600" }}>{XRAY}</p>
                                    </div>
                                </div>
                                <div className="table-scrollable">
                                    <table className="table table-hover table-checkable order-column full-width" id="example4">
                                        <thead>
                                            <tr>
                                                <th>{NO}</th>
                                                <th>{FILE_NAME}</th>
                                                <th>{THUMBNAIL}</th>
                                                <th>{CREATED_DATE}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!props.imageX ? (
                                                <>{<NoDataFound />}</>
                                            ) : (
                                                props.imageX &&
                                                props.imageX.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{(1 - 1) * 100 + i + 1}</td>
                                                            <td>
                                                                <div data-toggle="tooltip" title={item.fileName}>
                                                                    {item.fileName ? (item.fileName.length > 20 ? item.fileName.slice(0, 20) + "..." : item.fileName) : XRAY + ".png"}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <Zoom>
                                                                    <img src={item.image} width="100" height="100" alt="Patient Document" style={{ height: "50px", width: "50px" }} />
                                                                </Zoom>
                                                            </td>
                                                            <td>{item.created_at ? dateFormateMonthDateYear(item.created_at) : "-"}</td>
                                                        </tr>
                                                    );
                                                })
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="d-flex justify-content-between my-2 mx-2">
                                    <div>
                                        <p style={{ fontSize: "Larger", fontWeight: "600" }}>{NOTE}</p>
                                    </div>
                                </div>
                                <div className="table-scrollable">
                                    <table className="table table-hover table-checkable order-column full-width" id="example4">
                                        <thead>
                                            <tr>
                                                <th>{NO}</th>
                                                <th>{FILE_NAME}</th>
                                                <th>{THUMBNAIL}</th>
                                                <th>{CREATED_DATE}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!props.imageN ? (
                                                <>{<NoDataFound />}</>
                                            ) : (
                                                props.imageN &&
                                                props.imageN.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{(1 - 1) * 100 + i + 1}</td>
                                                            <td>
                                                                <div data-toggle="tooltip" title={item.fileName}>
                                                                    {item.fileName ? (item.fileName.length > 20 ? item.fileName.slice(0, 20) + "..." : item.fileName) : NOTE + ".png"}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <Zoom>
                                                                    <img src={item.image} width="100" height="100" alt="Patient Document" style={{ height: "50px", width: "50px" }} />
                                                                </Zoom>
                                                            </td>
                                                            <td>{item.created_at ? dateFormateMonthDateYear(item.created_at) : "-"}</td>
                                                        </tr>
                                                    );
                                                })
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="d-flex justify-content-between my-2 mx-2">
                                    <div>
                                        <p style={{ fontSize: "Larger", fontWeight: "600" }}>{ID}</p>
                                    </div>
                                </div>
                                <div className="table-scrollable">
                                    <table className="table table-hover table-checkable order-column full-width" id="example4">
                                        <thead>
                                            <tr>
                                                <th>{NO}</th>
                                                <th>{FILE_NAME}</th>
                                                <th>{THUMBNAIL}</th>
                                                <th>{CREATED_DATE}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!props.imageId ? (
                                                <>{<NoDataFound />}</>
                                            ) : (
                                                props.imageId &&
                                                props.imageId.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{(1 - 1) * 100 + i + 1}</td>
                                                            <td>
                                                                <div data-toggle="tooltip" title={item.fileName}>
                                                                    {item.fileName ? (item.fileName.length > 20 ? item.fileName.slice(0, 20) + "..." : item.fileName) : ID + ".png"}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <Zoom>
                                                                    <img src={item.image} width="100" height="100" alt="Patient Document" style={{ height: "50px", width: "50px" }} />
                                                                </Zoom>
                                                            </td>
                                                            <td>{item.created_at ? dateFormateMonthDateYear(item.created_at) : "-"}</td>
                                                        </tr>
                                                    );
                                                })
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="d-flex justify-content-between my-2 mx-2">
                                    <div>
                                        <p style={{ fontSize: "Larger", fontWeight: "600" }}>{OTHERS}</p>
                                    </div>
                                </div>
                                <div className="table-scrollable">
                                    <table className="table table-hover table-checkable order-column full-width" id="example4">
                                        <thead>
                                            <tr>
                                                <th>{NO}</th>
                                                <th>{FILE_NAME}</th>
                                                <th>{THUMBNAIL}</th>
                                                <th>{CREATED_DATE}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!props.imageOt ? (
                                                <>{<NoDataFound />}</>
                                            ) : (
                                                props.imageOt &&
                                                props.imageOt.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{(1 - 1) * 100 + i + 1}</td>
                                                            <td>
                                                                <div data-toggle="tooltip" title={item.fileName}>
                                                                    {item.fileName ? (item.fileName.length > 20 ? item.fileName.slice(0, 20) + "..." : item.fileName) : OTHERS + ".png"}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <Zoom>
                                                                    <img src={item.image} width="100" height="100" alt="Patient Document" style={{ height: "50px", width: "50px" }} />
                                                                </Zoom>
                                                            </td>
                                                            <td>{item.created_at ? dateFormateMonthDateYear(item.created_at) : "-"}</td>
                                                        </tr>
                                                    );
                                                })
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {/* Diagnosis */}
                    <div className="col-xl-6 col-md-12 col-lg-12 col-sm-12">
                        <div className="card">
                            <div className="card-head">
                                <header>{DIAGNOSES}</header>
                            </div>
                            <div className="card-body ">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr style={{ background: "antiquewhite" }}>
                                            <th className="center">{DIAGNOSES}</th>
                                            <th className="center">{CLAIM_CODE}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.diagnoses &&
                                            props.diagnoses.map((item, i) => {
                                                return (
                                                    <tr className="unread panel-heading-gray" key={i}>
                                                        <td>{item.name}</td>
                                                        <td>{item.code}</td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ padding: "10px" }}>
                                <b>&nbsp;&nbsp;&nbsp;&nbsp;ETC Diagnoses </b> : <span>{props.addClaim.diagnosesEtc ? props.addClaim.diagnosesEtc && props.addClaim.diagnosesEtc : DASH}</span>
                            </div>
                        </div>
                    </div>

                    {/* Procedure */}
                    <div className="col-xl-6 col-md-12 col-lg-12 col-sm-12">
                        <div className="card">
                            <div className="card-head">
                                <header>{PROCEDURE}</header>
                                <header className="pull-right">
                                    <input
                                        name="speciality"
                                        className="form-control"
                                        value={Object.keys(PROCEDURE_DOCTOR_TYPE).find((key) => PROCEDURE_DOCTOR_TYPE[key] === props.speciality)}
                                        disabled
                                    ></input>
                                </header>
                            </div>
                            {Object.keys(props.procedure).map((item, i) => (
                                <div className="card-body" id="line-parent" key={i}>
                                    <div className="panel-group accordion" id={"accordion" + i}>
                                        <div className="panel panel-default">
                                            <div className="panel-heading panel-heading-gray">
                                                <div style={{ display: "flex" }}>
                                                    <div style={{ width: "97%" }}>
                                                        <h4>
                                                            {props.procedure[item].type}
                                                        </h4>
                                                    </div>
                                                    <div>
                                                        <a
                                                            className="accordion-toggle accordion-toggle-styled collapsed"
                                                            data-bs-toggle="collapse"
                                                            data-parent={"accordion" + i}
                                                            href={"#accodation_3_" + i}
                                                        >
                                                            <i className="fa fa-sort-desc" aria-hidden="true"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id={"accodation_3_" + i} className="panel-collapse in">
                                                <div className="panel-body" style={{ height: "auto", overflowY: "auto" }}>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            {props.procedure[item].data.map((prod, i) => (
                                                                <tr key={i} className={`${prod.priorityList != MAX_PRIORITY ? "bgPriorityRowColor" : ""}`}>
                                                                    <td className={`${prod.value > 0 ? "prodCode prodBorder col-1" : "d-none"} `}>{prod.code}</td>
                                                                    <td className={`${prod.value > 0 ? "prodDesc prodBorder col-7" : "d-none"} `}>{prod.description}</td>
                                                                    {pageAuthorityRoleId === 7 ? (<> </>) :
                                                                        <td className={`${prod.value > 0 ? "prodFee prodBorder col-1" : "d-none"} `}>{prod.fee}</td>
                                                                    }
                                                                    <td className={`${prod.value > 0 ? "prodAddFee prodBorder col-1" : "d-none"} `}>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            name={`prog${prod.id}`}
                                                                            data-type={props.procedure[item].type}
                                                                            value={prod.value}
                                                                            disabled
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="form-actions mt-5" id="button">
                        <div className="center">
                            <button type="submit" className="btn btn-info m-r-20 col-1 p-3" id="btnPrevious" onClick={props.previousStep}>
                                {PREVIOUS}
                            </button>
                            {props.saveBtnIsLoading ? (
                                <>
                                    <button type="submit" id="btnsave" className="btn btn-primary m-r-20 col-1 p-3" disabled>
                                        {SAVE}
                                    </button>
                                    <i className="fa fa-spinner fa-spin fa-2x saveBtnLoader"></i>
                                </>
                            ) : (
                                <button type="submit" id="btnsave" className="btn btn-primary m-r-20 col-1 p-3" onClick={props.handleSave}>
                                    {SAVE}
                                </button>
                            )}
                            {props.defSubmitBtnIsLoading ? (
                                <>
                                    <button type="submit" id="btnDefaultSubmit" className="btn btn-success m-r-20 col-1 p-3" disabled>
                                        {SUBMIT}
                                    </button>
                                    <i className="fa fa-spinner fa-spin fa-2x saveBtnLoader"></i>
                                </>
                            ) : (
                                <button type="submit" id="btnDefaultSubmit" className="btn btn-success m-r-20 col-1 p-3" onClick={props.handleDefaultSubmit}>
                                    {SUBMIT}
                                </button>
                            )}

                            <button type="submit" id="btnsubmit" className="btn btn-success m-r-20 col-1 p-3" style={{ display: "none" }} onClick={props.handleSubmit}>
                                {SUBMIT}
                            </button>

                            <Link to="/manageClaim" className="btn btn-default m-r-20 col-1 p-3">
                                {" "}
                                {CANCEL}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ConfirmationClaim;
