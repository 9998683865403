import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
    ADD,
    ADD_QUESTION,
    CANCEL,
    FORMTYPE_DROPDOWN,
    FORM_TYPE,
    GROUP_NAME,
    NAME,
    PRIORITY,
    SELECT_GROUP,
    SELECT_TAB,
    STATUS,
    TABS_STATUS,
    TAB_NAME,
    UPDATE,
    UPDATE_QUESTION,
} from "../../../Helper/Constants";
import Select from "react-select";

function Add_EditQuestions(props) {
    return (
        <>
            <Modal show={props.show} onHide={props.handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{props.edit ? UPDATE_QUESTION : ADD_QUESTION}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <label className="control-label col-md-12">
                            {NAME}
                            <span className="required"> * </span>
                        </label>
                        <div className="col-md-12">
                            <input type="text" value={props.questions.name} name="name" onChange={props.handlechange} placeholder="Enter Name" className="form-control input-height" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-6">
                            <label className="mt-2 col-md-6">
                                {TAB_NAME}
                                <span className="required"> * </span>
                            </label>
                            <Select
                                name="tabName"
                                value={props.tabSelectOptions && props.tabSelectOptions.filter(({ value }) => value == props.addTabId)}
                                options={props.tabSelectOptions}
                                onChange={props.handleAddTabChange}
                                placeholder={SELECT_TAB}
                            />
                        </div>
                        <div className="col-md-6">
                            <label className="mt-2 col-md-6">{GROUP_NAME}</label>
                            <Select
                                name="groupName"
                                value={props.groupSelectOptions && props.groupSelectOptions.filter(({ value }) => value == props.groupId)}
                                options={props.groupSelectOptions}
                                onChange={props.handleGroupChange}
                                placeholder={SELECT_GROUP}
                                isDisabled={props.addTabId == "" ? true : false}
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-md-4">
                            <label className="mt-2 col-md-6">
                                {FORM_TYPE}
                                <span className="required"> * </span>
                            </label>
                            <select className="form-control col-md-3" name="formType" value={props.questions.formType} onChange={props.handlechange}>
                                {Object.keys(FORMTYPE_DROPDOWN).map((item, i) => {
                                    return (
                                        <option key={i} value={FORMTYPE_DROPDOWN[item]}>
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label className="mt-2 col-md-6">
                                {STATUS}
                                <span className="required"> * </span>
                            </label>
                            <select className="form-control col-md-3" name="statusId" value={props.questions.statusId} onChange={props.handlechange}>
                                {Object.keys(TABS_STATUS).map((item, i) => {
                                    return (
                                        <option key={i} value={TABS_STATUS[item]}>
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label className="control-label col-md-6">{PRIORITY}</label>
                            <div className="col-md-12">
                                <input type="text" value={props.questions.priorityList} name="priorityList" onChange={props.handlechange} placeholder="9999" className="form-control input-height" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.addQuestionData}>
                        {props.edit ? UPDATE : ADD}
                    </Button>
                    <Button variant="default" onClick={props.handleClose}>
                        {CANCEL}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Add_EditQuestions;
