import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { baseURL } from "../../Config";
import { Modal, Button } from "react-bootstrap";
import { HOME, ROWS_PER_PAGE } from "../../Helper/Constants";

import ReactPaginate from "react-paginate";
import { dateFormateMonthDateYear } from "../../Helper/Helper";
import { NavLink, useNavigate } from "react-router-dom";
import { ACCEPT_REQUEST_API, MANAGE_REQUEST_API } from "../../Helper/ApiPath";
import NoDataFound from "../CommonComponent/NoDataFound";
import Loader from "../CommonComponent/Loader";

function RequestData() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [requestList, setRequestList] = useState([]);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const token = JSON.parse(localStorage.getItem("logToken"));
    const [showViewMdl, setShowViewMdl] = useState(false);
    const [viewIndex, setViewIndex] = useState(-1);
    const [changeReqLoader, setChangeReqLoader] = useState(0);
    const [filterValues, setFilterValues] = useState({
        status: "",
    });

    const handleFilterValues = (e) => {
        const { name, value } = e.target;
        setFilterValues({ ...filterValues, [name]: value });
    };
    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${MANAGE_REQUEST_API}`, {
                headers: { authorization: token.token },
                page: offset,
                rowsPerPage: ROWS_PER_PAGE,
                // statusId: filterValues.status,
            })
            .then((res) => {
                setIsLoading(false);
                if (res.data.data) {
                    setRequestList(res.data.data);
                }
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                setIsLoading(false);
                //   toast.error(err.response.data.message);
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        // postsPerPage(event.selected + 1);
    };

    useEffect(() => {
        fetchData();
    }, [offset, filterValues]);
    const handleViewMdl = (type, index = -1) => {
        setShowViewMdl(type ? true : false);
        setViewIndex(index);
        setChangeReqLoader(0);
    };
    const acceptReq = async (status) => {
        if (changeReqLoader == 0) {
            setChangeReqLoader(status);
            await axios
                .post(`${baseURL}/${ACCEPT_REQUEST_API}`, {
                    headers: { authorization: token.token },
                    status: status,
                    uniqId: requestList[viewIndex]["uniqueId"],
                })
                .then((res) => {
                    fetchData();
                    setChangeReqLoader(0);
                    toast.success(res.data.message);
                    handleViewMdl(0, -1);
                })
                .catch((err) => {
                    setChangeReqLoader(0);
                    toast.error(err.response.data.message);
                });
        }
    };
    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">Change Request List</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">Change Request List</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tabbable-line">
                                <div className="tab-content">
                                    <div className="tab-pane active fontawesome-demo" id="tab1">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card" style={isLoading ? { height: "35rem" } : {}}>
                                                    <div className="card-body ">
                                                        <div className="row">
                                                            {/* <div className="d-flex justify-content-end">
                                                                <div className="col-3">
                                                                    <div className="btn-group">
                                                                        <label className="mt-2 col-md-6">{STATUS}</label>
                                                                        <select className="form-control col-md-3" name="status" value={filterValues.status} onChange={handleFilterValues}>
                                                                            <option value="">{ALL}</option>
                                                                            {Object.keys(REQUEST_DATA_STATUS).map((item, i) => {
                                                                                return (
                                                                                    <option key={i} value={REQUEST_DATA_STATUS[item]}>
                                                                                        {item}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                        <div className="table-scrollable" style={isLoading ? { border: "none" } : {}}>
                                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Type</th>
                                                                        <th>Identifier</th>
                                                                        <th>Status</th>
                                                                        <th>Submitted by</th>
                                                                        <th>Created Date</th>
                                                                        <th>Updated Date</th>
                                                                        <th>View Data</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={isLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                                    {isLoading ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        <>
                                                                            {!requestList.length ? (
                                                                                <>{isLoading ? "" : <NoDataFound />}</>
                                                                            ) : (
                                                                                requestList &&
                                                                                requestList.map((item, index) => {
                                                                                    return (
                                                                                        <tr className="odd gradeX" key={index}>
                                                                                            <td className="left">{item.reqType}</td>
                                                                                            <td className="left">{item.identifier}</td>
                                                                                            <td className="left">
                                                                                                <span
                                                                                                    className={
                                                                                                        "badge " +
                                                                                                        (item.status == 0 ? "badge-warning" : item.status == 1 ? "badge-success" : "badge-danger")
                                                                                                    }
                                                                                                >
                                                                                                    {item.status == 0 ? "Pending" : item.status == 1 ? "Approve" : "Reject"}
                                                                                                </span>
                                                                                            </td>
                                                                                            <td className="left">{item.submitted_by}</td>
                                                                                            <td>{dateFormateMonthDateYear(item.created_at)}</td>
                                                                                            <td>{item.updated_at ? dateFormateMonthDateYear(item.updated_at) : "-"}</td>
                                                                                            <td>
                                                                                                {item.status == 0 ? (
                                                                                                    <button
                                                                                                        className="btn btn-warning btn-xs btn-radius"
                                                                                                        title="View Data"
                                                                                                        onClick={() => handleViewMdl(1, index)}
                                                                                                    >
                                                                                                        <i className="fa fa-eye "></i>
                                                                                                    </button>
                                                                                                ) : (
                                                                                                    "-"
                                                                                                )}
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mx-4">
                                                        <div>Total {requestList == null ? "0" : rowCount} Requests</div>
                                                        <div className="pagination-center">
                                                            <ReactPaginate
                                                                initialPage={0}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={0}
                                                                pageRangeDisplayed={3}
                                                                onPageChange={handlePageClick}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                pageLinkClassName="page-link"
                                                                breakLinkClassName="page-link"
                                                                nextLinkClassName="page-link"
                                                                previousLinkClassName="page-link"
                                                                pageClassName="page-item"
                                                                breakClassName="page-item"
                                                                nextClassName="page-item"
                                                                previousClassName="page-item"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"
                                                                disableInitialCallback={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showViewMdl} onHide={() => handleViewMdl(0)} centered>
                <Modal.Header closeButton>
                    <Modal.Title className="h5 mt-0 mb-0">
                        Request data of{" "}
                        <b>
                            {requestList[viewIndex] ? requestList[viewIndex]["identifier"] : ""}({requestList[viewIndex] ? requestList[viewIndex]["reqType"] : ""})
                        </b>{" "}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <table className="table">
                                <thead className="reqtheader">
                                    <tr>
                                        <th className="bb-none">#</th>
                                        <th className="bb-none">Old Value</th>
                                        <th className="bb-none">New Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {requestList[viewIndex] && JSON.parse(requestList[viewIndex]["data"])
                                        ? JSON.parse(requestList[viewIndex]["data"]).map((v) => {
                                              return (
                                                  <tr>
                                                      <td className="p-3">{v.viewtxt}</td>
                                                      <td className="p-3 text-black-50">{v.field == "state" || v.field == "city" ? v.viewOld : v.old}</td>
                                                      <td className="p-3">{v.field == "state" || v.field == "city" ? v.viewNew : v.new}</td>
                                                  </tr>
                                              );
                                          })
                                        : ""}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal.Body>
                <div className="d-flex mt-2 mb-4 justify-content-center mb-3">
                    <div className="center">
                        <Button className="mx-3 btn-danger btn-radius" variant="secondary" onClick={() => acceptReq(2)}>
                            {changeReqLoader == 2 ? <i className="spinner-border"></i> : ""} Reject
                        </Button>
                        <Button className="btn-success btn-radius" onClick={() => acceptReq(1)}>
                            {changeReqLoader == 1 ? <i className="spinner-border"></i> : ""} Confirm
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
export default RequestData;
