import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../Config";
import { ASSIGNED_DOCTOR_DETAIL, EDIT_ASSIGNED_DOCTOR, PAYER_LIST_WITHOUT_MIDDLEWARE_API, SPECIALTY_LIST_WITHOUT_PAGINATION } from "../../Helper/ApiPath";
import {
    ALL,
    ASSIGN_DOCTOR,
    BIRTH_DATE,
    CANCEL,
    CHOOSE_DOCTOR_TYPE,
    DOCTOR_SPECIALTY,
    DOCTOR_STATUS,
    EDIT_DOCTOR_DETAILS,
    EDIT_DOCTOR_TITLE,
    FEE,
    FEMALE,
    FIRST_NAME,
    GENDER,
    HOME,
    LAST_NAME,
    LICENSE_NUMBER,
    MALE,
    NPI1,
    NPI2,
    PAYER,
    PHONE_LENGTH,
    PHONE_NO,
    PTAN_NUMBER,
    SELECT_GENDER,
    SELECT_PAYER,
    SELECT_SPECIALTY,
    SSN,
    STATUS,
    TAXID,
    UPDATE,
} from "../../Helper/Constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { dateAPIFormate, dateFormateMonthDateYear, minYear } from "../../Helper/Helper";
import {
    ENTER_FEE_LESS_THAN_10,
    ENTER_FNAME,
    ENTER_LNAME,
    ENTER_NPI1,
    ENTER_NPI_LESS_THAN_10,
    ENTER_PAYER,
    ENTER_PTAN_LESS_THAN_10,
    ENTER_SPECIALTY,
    ENTER_SSN_LESS_THAN_10,
    ENTER_TAX_ID_LESS_THAN_10,
    MAX_LENGTH,
    PHONE_NUMBER,
} from "../../Helper/Messages";
import Select from "react-select";

function AdminAssignedDoctorEdit() {
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const [doctorInfo, setDoctorInfo] = useState({
        email: "",
        password: "",
        firstName: "",
        middleName: "",
        lastName: "",
        phoneNo1: "",
        statusId: "",
        dob: "",
        profilePic: "",
        npi1: "",
        npi2: "",
        ssn: "",
        taxId: "",
        licenseNumber: "",
        fee: "",
        gender: "",
        speciality: "",
        ptanNumber: "",
        payerId: "",
    });
    const [payer, setPayer] = useState([]);

    const doctorId = localStorage.getItem("doctorId");
    const officeId = localStorage.getItem("officeId");
    const token = JSON.parse(localStorage.getItem("logToken"));
    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${ASSIGNED_DOCTOR_DETAIL}`, {
                headers: { authorization: token.token },
                officeId: officeId,
                doctorId: doctorId,
            })
            .then((res) => {
                if (res.status === 200) {
                    setDoctorInfo(res.data.data);
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const onInputChanged = (e) => {
        const { name, value } = e.target;
        if (name === "ssn" || name === "taxId" || name === "npi1" || name === "npi2") {
            const onlyNumber = e.target.value.replace(/[^0-9,-]/g, "");
            setDoctorInfo({ ...doctorInfo, [name]: onlyNumber });
        } else {
            setDoctorInfo({ ...doctorInfo, [name]: value });
        }
    };
    const handlePayerChange = (e) => {
        let label = e.label;
        let value = e.id;
        setDoctorInfo({ ...doctorInfo, payerLabel: label, payerId: value });
    };
    const payerList = () => {
        axios
            .get(`${baseURL}/${PAYER_LIST_WITHOUT_MIDDLEWARE_API}`)
            .then((res) => {
                setPayer(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const payerOptions =
        payer &&
        payer.map((item, i) => {
            return {
                id: item.id,
                value: item.value,
                label: item.name,
            };
        });
        
    const [specialtyList, setSpecialtyList] = useState([]);

    const specialtyData = async () => {
        await axios
            .post(`${baseURL}/${SPECIALTY_LIST_WITHOUT_PAGINATION}`)
            .then((res) => {
                setSpecialtyList(res.data.data);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };
    
    const specialtyOptions =
        specialtyList &&
        specialtyList.map((item, i) => {
            return {
                id: item.id,
                value: item.id,
                label: item.name,
            };
        });

    const handleSpecialtyChange = (e) => {
        setDoctorInfo({ ...doctorInfo, speciality: e.value });
    };
    
    const updateDoctor = async () => {
        if (!doctorInfo.firstName) {
            toast.error(ENTER_FNAME);
        } else if (!doctorInfo.lastName) {
            toast.error(ENTER_LNAME);
        } else if (!doctorInfo.npi1) {
            toast.error(ENTER_NPI1);
        } else if (doctorInfo.npi1 && doctorInfo.npi1.length > MAX_LENGTH) {
            toast.error(ENTER_NPI_LESS_THAN_10);
        } else if (doctorInfo.npi2 && doctorInfo.npi2.length > MAX_LENGTH) {
            toast.error(ENTER_NPI_LESS_THAN_10);
        } else if (doctorInfo.ssn && doctorInfo.ssn.length > MAX_LENGTH) {
            toast.error(ENTER_SSN_LESS_THAN_10);
        } else if (doctorInfo.taxId && doctorInfo.taxId.length > MAX_LENGTH) {
            toast.error(ENTER_TAX_ID_LESS_THAN_10);
        } else if (doctorInfo.ptanNumber && doctorInfo.ptanNumber.length > MAX_LENGTH) {
            toast.error(ENTER_PTAN_LESS_THAN_10);
        } else if (doctorInfo.fee && doctorInfo.fee.length > MAX_LENGTH) {
            toast.error(ENTER_FEE_LESS_THAN_10);
        } else if (!doctorInfo.speciality) {
            toast.error(ENTER_SPECIALTY);
        } else if (!doctorInfo.payerId) {
            toast.error(ENTER_PAYER);
        } else if (doctorInfo.phoneNo1 && doctorInfo.phoneNo1.length > PHONE_LENGTH) {
            toast.error(PHONE_NUMBER);
        } else {
            setIsLoading(true);
            await axios
                .post(`${baseURL}/${EDIT_ASSIGNED_DOCTOR}`, {
                    headers: { authorization: token.token },
                    doctorId: doctorId,
                    officeId: officeId,
                    firstName: doctorInfo.firstName,
                    middleName: doctorInfo.middleName,
                    lastName: doctorInfo.lastName,
                    phoneNo1: doctorInfo.phoneNo1,
                    statusId: doctorInfo.statusId,
                    dob: dateAPIFormate(doctorInfo.dob),
                    npi1: doctorInfo.npi1,
                    npi2: doctorInfo.npi2,
                    ssn: doctorInfo.ssn,
                    taxId: doctorInfo.taxId,
                    licenseNumber: doctorInfo.licenseNumber,
                    fee: doctorInfo.fee,
                    gender: doctorInfo.gender,
                    speciality: doctorInfo.speciality,
                    ptanNumber: doctorInfo.ptanNumber,
                    payerId: doctorInfo.payerId,
                })
                .then((res) => {
                    if (res.status === 200) {
                        setIsLoading(false);
                        toast.success(res.data.message);
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const handleCancel = () => {
        navigate("/assignDoctor");
    };

    useEffect(() => {
        fetchData();
        payerList();
        specialtyData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content" style={{ minHeight: "600px" }}>
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{EDIT_DOCTOR_TITLE}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li>
                                    <i className="fa fa-user-md"></i>&nbsp;
                                    <NavLink className="parent-item" to="/assignDoctor">
                                        {ASSIGN_DOCTOR}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{EDIT_DOCTOR_TITLE}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="card card-box">
                                <div className="card-head">
                                    <header>{EDIT_DOCTOR_DETAILS}</header>
                                </div>
                                <div className="card-body" id="bar-parent">
                                    <div action="#" id="form_sample_1" className="form-horizontal">
                                        <div className="form-body">
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {FIRST_NAME}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="firstName"
                                                        value={doctorInfo && doctorInfo.firstName}
                                                        onChange={onInputChanged}
                                                        data-required="1"
                                                        placeholder="Enter first name"
                                                        className="form-control input-height"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {LAST_NAME}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="lastName"
                                                        value={doctorInfo && doctorInfo.lastName}
                                                        onChange={onInputChanged}
                                                        data-required="1"
                                                        placeholder="Enter last name"
                                                        className="form-control input-height"
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{GENDER}</label>
                                                <div className="col-md-5">
                                                    <select className="form-control input-height" name="gender" value={doctorInfo.gender} onChange={onInputChanged}>
                                                        <option value=""> {SELECT_GENDER}</option>
                                                        <option value="male">{MALE}</option>
                                                        <option value="female">{FEMALE}</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{BIRTH_DATE}</label>
                                                <div className="form-group col-5">
                                                    <DatePicker
                                                        className="form-control input-height "
                                                        value={dateFormateMonthDateYear(doctorInfo && doctorInfo.dob)}
                                                        maxDate={new Date(minYear)}
                                                        scrollableMonthDropdown={true}
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        onChange={(date) => setDoctorInfo({ ...doctorInfo, dob: date })}
                                                        placeholderText={"Please Select Date"}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{PHONE_NO}</label>
                                                <div className="col-md-5">
                                                    <input
                                                        name="phoneNo1"
                                                        type="number"
                                                        value={doctorInfo && doctorInfo.phoneNo1}
                                                        minLength={10}
                                                        maxLength={10}
                                                        onChange={onInputChanged}
                                                        placeholder="mobile number"
                                                        className="form-control input-height"
                                                    />{" "}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{STATUS}</label>
                                                <div className="col-md-5">
                                                    <select className="form-control input-height" name="statusId" value={doctorInfo.statusId} onChange={onInputChanged}>
                                                        <option value=""> {ALL}</option>
                                                        {Object.keys(DOCTOR_STATUS).map((item, i) => {
                                                            return (
                                                                <option key={i} value={DOCTOR_STATUS[item]}>
                                                                    {item}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {NPI1}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <input
                                                        name="npi1"
                                                        type="text"
                                                        onChange={onInputChanged}
                                                        value={doctorInfo && doctorInfo.npi1}
                                                        placeholder="Enter NPI 1"
                                                        className="form-control input-height"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{NPI2}</label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        className="form-control input-height"
                                                        name="npi2"
                                                        value={doctorInfo && doctorInfo.npi2}
                                                        placeholder="Enter NPI 2"
                                                        onChange={onInputChanged}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{SSN}</label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        className="form-control input-height"
                                                        name="ssn"
                                                        value={doctorInfo && doctorInfo.ssn}
                                                        placeholder="Enter SSN"
                                                        onChange={onInputChanged}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{TAXID}</label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        className="form-control input-height"
                                                        name="taxId"
                                                        value={doctorInfo && doctorInfo.taxId}
                                                        placeholder="Enter Tax ID"
                                                        onChange={onInputChanged}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{LICENSE_NUMBER}</label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        className="form-control input-height"
                                                        name="licenseNumber"
                                                        value={doctorInfo && doctorInfo.licenseNumber}
                                                        placeholder="Enter License Number"
                                                        onChange={onInputChanged}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{PTAN_NUMBER}</label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        className="form-control input-height"
                                                        name="ptanNumber"
                                                        value={doctorInfo && doctorInfo.ptanNumber}
                                                        placeholder="Enter PTAN Number"
                                                        onChange={onInputChanged}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{FEE}</label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="number"
                                                        className="form-control input-height"
                                                        name="fee"
                                                        value={doctorInfo && doctorInfo.fee}
                                                        placeholder="Enter fee"
                                                        onChange={onInputChanged}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {DOCTOR_SPECIALTY}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <Select
                                                        menuPlacement="top"
                                                        name="specialty"
                                                        value={specialtyOptions && specialtyOptions.filter(({ value }) => value == doctorInfo.speciality)}
                                                        options={specialtyOptions}
                                                        onChange={handleSpecialtyChange}
                                                        placeholder={SELECT_SPECIALTY}
                                                    />
                                                    {/* <select className="form-control input-height" name="speciality" value={doctorInfo.speciality} onChange={onInputChanged}>
                                                        {Object.keys(CHOOSE_DOCTOR_TYPE).map((item, i) => {
                                                            return (
                                                                <option key={i} value={CHOOSE_DOCTOR_TYPE[item]}>
                                                                    {item}
                                                                </option>
                                                            );
                                                        })}
                                                    </select> */}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {PAYER}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <Select
                                                        menuPlacement="top"
                                                        className="text-dark"
                                                        name="payerId"
                                                        value={payerOptions && payerOptions.filter(({ id }) => id === doctorInfo.payerId)}
                                                        options={payerOptions}
                                                        onChange={handlePayerChange}
                                                        placeholder={SELECT_PAYER}
                                                    ></Select>
                                                </div>
                                            </div>
                                            <div className="form-actions">
                                                <div className="row">
                                                    <div className="offset-md-3 col-md-9">
                                                        {isLoading ? (
                                                            <>
                                                                <button type="submit" className="btn btn-primary m-r-20" disabled>
                                                                    {UPDATE}
                                                                </button>
                                                                <i className="fa fa-spinner fa-spin fa-2x loaderUpdateBtnSpinner"></i>
                                                            </>
                                                        ) : (
                                                            <button type="submit" className="btn btn-primary m-r-20" onClick={updateDoctor}>
                                                                {UPDATE}
                                                            </button>
                                                        )}
                                                        <button type="button" className="btn btn-default m-r-20" onClick={handleCancel}>
                                                            {CANCEL}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminAssignedDoctorEdit;
