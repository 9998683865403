import { useEffect, useRef, useState } from "react";
import {
    ADD_NOTICE,
    HOME,
    TITLE,
    STATUS,
    ATTACHMENT,
    CANCEL,
    NOTICE_INFORMATION,
    NOTICESTATUS,
    SELECT,
    POPUP,
    POPUPSTATUS,
    POSTING_PERIOD,
    CONTENTS,
    SAVE,
    EDIT_NOTICE,
    NOTICE_LIST,
    UPDATE,
} from "../../../Helper/Constants";

import { ENTER_CONTENTS, ENTER_TITLE, SELECT_POPUP, SELECT_POSTING_FROM_PERIOD_DATE, SELECT_POSTING_TO_PERIOD_DATE, SELECT_STATUS } from "../../../Helper/Messages";
import axios from "axios";
import { baseURL } from "../../../Config";
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { dateAPIFormate, dateFormateMonthDateYear } from "../../../Helper/Helper";
import { NOTICE_ADD, NOTICE_DETAIL, NOTICE_EDIT, UPLOAD_ENDPOINT } from "../../../Helper/ApiPath";

function AddEmployee() {
    const noticeId = localStorage.getItem("noticeId");
    const [isLoading, setIsLoading] = useState(noticeId ? true : false);
    const [updateBtnisLoading, setUpdateBtnIsLoading] = useState(false);
    let token = JSON.parse(localStorage.getItem("logToken"));
    const ref = useRef();
    const navigate = useNavigate();

    const [notice, setNotice] = useState({
        title: "",
        statusId: "",
        popUp: "1",
        attachment: "",
        createBy: "",
        createDate: "",
        updateBy: "",
        updateDate: "",
    });

    const [attachmentInfo, setAttachmentInfo] = useState({ preview: "", data: "" });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNotice({ ...notice, [name]: value });

        if (e.target.name === "attachment") {
            const attachment = {
                preview: URL.createObjectURL(e.target.files[0]),
                data: e.target.files[0],
            };

            const fileExtension = attachment.data.name.split(".").at(-1).toLowerCase();
            const allowedFileTypes = ["pdf", "jpg", "png", "gif"];
            if (!allowedFileTypes.includes(fileExtension)) {
                e.target.value = null;
                alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
            } else {
                setAttachmentInfo(attachment);
            }
        }
    };

    let disabled = false;
    let enabled = true;

    const fromDateChange = (date) => {
        setNoticeDateValues({ ...noticeDateValues, postingPeriodStartDate: date });
    };

    const [editorValue, setEditorValue] = useState("");
    const [contentsEditValue, setContentsEditValue] = useState("");

    const handleEditorChange = (e) => {
        setEditorValue(e);
        setContentsEditValue(e);
    };
    const [noticeDateValues, setNoticeDateValues] = useState({
        postingPeriodStartDate: "",
        postingPeriodEndDate: "",
    });
    const fetchdata = async () => {
        await axios
            .get(`${baseURL}/${NOTICE_DETAIL}/${noticeId}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                setIsLoading(false);
                setNotice(res.data.data);
                setEditorValue(res.data.data.contents);
                setNoticeDateValues({ ...noticeDateValues, postingPeriodStartDate: res.data.data.postingPeriodStartDate, postingPeriodEndDate: res.data.data.postingPeriodEndDate });
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const handleSubmit = async (e) => {
        if (!notice.title) {
            toast.error(ENTER_TITLE);
        } else if (!notice.statusId) {
            toast.error(SELECT_STATUS);
        } else if (notice.popUp === "") {
            toast.error(SELECT_POPUP);
        } else if (!noticeDateValues.postingPeriodStartDate) {
            toast.error(SELECT_POSTING_FROM_PERIOD_DATE);
        } else if (!noticeDateValues.postingPeriodEndDate) {
            toast.error(SELECT_POSTING_TO_PERIOD_DATE);
        } else if (!editorValue) {
            toast.error(ENTER_CONTENTS);
        } else if (noticeId) {
            setUpdateBtnIsLoading(true);
            let updObj = {
                headers: { authorization: token.token },
                noticeId: noticeId,
                attachment: attachmentInfo.data,
                title: notice.title,
                popUp: notice.popUp,
                statusId: notice.statusId,
                contents: editorValue,
                postingPeriodStartDate: dateAPIFormate(noticeDateValues.postingPeriodStartDate),
                postingPeriodEndDate: dateAPIFormate(noticeDateValues.postingPeriodEndDate),
            };
            if (!contentsEditValue) {
                delete updObj.contents;
            }

            await axios
                .post(`${baseURL}/${NOTICE_EDIT}`, updObj)
                .then((res) => {
                    setIsLoading(false);

                    setUpdateBtnIsLoading(false);
                    if (res.status === 200) {
                        setNotice("");
                        setNoticeDateValues("");
                        setAttachmentInfo("");
                        setEditorValue("");
                        setContentsEditValue("");
                        toast.success(res.data.message);
                        navigate("/manage-notice");
                        ref.current.value = "";
                    }
                })
                .catch((err) => {
                    setUpdateBtnIsLoading(false);
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        } else {
            let formData = new FormData();
            formData.append("attachment", attachmentInfo.data);
            formData.append("title", notice.title);
            formData.append("popUp", notice.popUp);
            formData.append("statusId", notice.statusId);
            formData.append("contents", editorValue);
            formData.append("postingPeriodStartDate", dateAPIFormate(noticeDateValues.postingPeriodStartDate));
            formData.append("postingPeriodEndDate", dateAPIFormate(noticeDateValues.postingPeriodEndDate));
            setIsLoading(true);
            await axios
                .post(`${baseURL}/${NOTICE_ADD}`, formData, {
                    headers: { authorization: token.token, "Content-Type": "multipart/form-data" },
                })
                .then((res) => {
                    setIsLoading(false);
                    if (res.status === 200) {
                        setNotice("");
                        setNoticeDateValues("");
                        setAttachmentInfo("");
                        setEditorValue("");
                        toast.success(res.data.message);
                        ref.current.value = "";
                        navigate("/manage-notice");
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file) => {
                        body.append("notice", file);
                        axios
                            .post(`${baseURL}/${UPLOAD_ENDPOINT}`, body, {
                                headers: { authorization: token.token },
                            })
                            .then((res) => {
                                resolve({
                                    default: res.data.data.filePath,
                                });
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                });
            },
        };
    }
    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }
    const handleCancel = () => {
        navigate("/manage-notice");
    };

    useEffect(() => {
        if (noticeId) {
            fetchdata();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{noticeId ? EDIT_NOTICE : ADD_NOTICE}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li>
                                    <i className="fa fa-list-alt"></i>&nbsp;
                                    <NavLink className="parent-item" to="/manage-notice">
                                        {NOTICE_LIST}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{ADD_NOTICE}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="card card-box">
                                <div className="card-head">
                                    <header>{NOTICE_INFORMATION}</header>
                                </div>
                                <div className="card-body" id="bar-parent">
                                    <div action="#" id="form_sample_1" className="form-horizontal">
                                        {isLoading ? (
                                            <div className="form-body" style={{ height: "100px" }}>
                                                <div id="text">
                                                    <i className="fa fa-spinner fa-spin fa-2x"></i>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="form-body">
                                                <div className="form-group row">
                                                    <label className="control-label col-md-3">
                                                        {TITLE}
                                                        <span className="required"> * </span>
                                                    </label>
                                                    <div className="col-md-6">
                                                        <input
                                                            type="text"
                                                            name="title"
                                                            value={notice && notice.title}
                                                            onChange={handleChange}
                                                            data-required="1"
                                                            placeholder="Enter Title"
                                                            className="form-control input-height"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="control-label col-md-3">
                                                        {STATUS}
                                                        <span className="required"> * </span>
                                                    </label>
                                                    <div className="col-md-6">
                                                        <select className="form-control input-height" name="statusId" value={notice && notice.statusId} onChange={handleChange}>
                                                            <option value=""> {SELECT} </option>
                                                            {Object.keys(NOTICESTATUS).map((item, i) => {
                                                                return (
                                                                    <option key={i} value={NOTICESTATUS[item]}>
                                                                        {item}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="control-label col-md-3">
                                                        {POPUP}
                                                        <span className="required"> * </span>
                                                    </label>
                                                    <div className="col-md-6">
                                                        <select className="form-control input-height" name="popUp" value={notice && notice.popUp} onChange={handleChange}>
                                                            <option value=""> {SELECT} </option>
                                                            {Object.keys(POPUPSTATUS).map((item, i) => {
                                                                return (
                                                                    <option key={i} value={POPUPSTATUS[item]}>
                                                                        {item}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="control-label col-md-3">
                                                        {POSTING_PERIOD}
                                                        <span className="required"> * </span>
                                                    </label>
                                                    <div className="btn-group col-md-2 pr-0">
                                                        {noticeId ? (
                                                            <DatePicker
                                                                className="form-control input-height mr-sm-2"
                                                                value={dateFormateMonthDateYear(noticeDateValues && noticeDateValues.postingPeriodStartDate)}
                                                                placeholderText={"Please Select Date"}
                                                                minDate={new Date()}
                                                                scrollableMonthDropdown={true}
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                onChange={fromDateChange}
                                                            />
                                                        ) : (
                                                            <DatePicker
                                                                className="form-control input-height mr-sm-2"
                                                                selected={noticeDateValues.postingPeriodStartDate}
                                                                placeholderText={"Please Select Date"}
                                                                minDate={new Date()}
                                                                scrollableMonthDropdown={true}
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                onChange={fromDateChange}
                                                            />
                                                        )}

                                                        <span className="todate">To</span>
                                                    </div>

                                                    <div className="btn-group col-md-2">
                                                        {noticeId ? (
                                                            <DatePicker
                                                                className="form-control input-height mr-sm-2"
                                                                value={dateFormateMonthDateYear(noticeDateValues.postingPeriodEndDate)}
                                                                placeholderText={"Please Select Date"}
                                                                minDate={new Date()}
                                                                scrollableMonthDropdown={true}
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                onChange={(date) => setNoticeDateValues({ ...noticeDateValues, postingPeriodEndDate: date })}
                                                            />
                                                        ) : (
                                                            <DatePicker
                                                                id="toDate"
                                                                className="form-control input-height mr-sm-2 "
                                                                selected={noticeDateValues.postingPeriodEndDate}
                                                                minDate={noticeDateValues.postingPeriodStartDate}
                                                                yearDropdownItemNumber={100}
                                                                scrollableMonthDropdown={true}
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                onChange={(date) => setNoticeDateValues({ ...noticeDateValues, postingPeriodEndDate: date })}
                                                                placeholderText="Select End Date"
                                                                disabled={!noticeDateValues.postingPeriodStartDate ? enabled : disabled}
                                                            />
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="control-label col-md-3">
                                                        {CONTENTS}
                                                        <span className="required"> * </span>
                                                    </label>
                                                    <div className="col-md-6">
                                                        <CKEditor
                                                            config={{
                                                                extraPlugins: [uploadPlugin],
                                                                height: "130",
                                                            }}
                                                            editor={ClassicEditor}
                                                            id="editor1"
                                                            onReady={(editor) => {
                                                                editor.setData(editorValue);
                                                            }}
                                                            onChange={(event, editor) => {
                                                                handleEditorChange(editor.getData());
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                {noticeId ? (
                                                    ""
                                                ) : (
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-3">{ATTACHMENT}</label>
                                                        <div className="col-md-6">
                                                            <input type="file" className="form-control" ref={ref} name="attachment" onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                )}

                                                <div className="form-actions">
                                                    <div className="row">
                                                        <div className="offset-md-3 col-md-9">
                                                            {noticeId ? (
                                                                <>
                                                                    {updateBtnisLoading ? (
                                                                        <>
                                                                            <button type="submit" className="btn btn-info m-r-20" disabled>
                                                                                {UPDATE}
                                                                            </button>
                                                                            <i className="fa fa-spinner fa-spin fa-2x loaderBtnSpinner"></i>
                                                                        </>
                                                                    ) : (
                                                                        <button type="submit" className="btn btn-info m-r-20" onClick={handleSubmit}>
                                                                            {UPDATE}
                                                                        </button>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {isLoading ? (
                                                                        <>
                                                                            <button type="submit" className="btn btn-info m-r-20" disabled>
                                                                                {SAVE}
                                                                            </button>
                                                                            <i className="fa fa-spinner fa-spin fa-2x loaderBtnSpinner"></i>
                                                                        </>
                                                                    ) : (
                                                                        <button type="submit" className="btn btn-info m-r-20" onClick={handleSubmit}>
                                                                            {SAVE}
                                                                        </button>
                                                                    )}
                                                                </>
                                                            )}
                                                            <button type="button" className="btn btn-default  m-r-20" onClick={handleCancel}>
                                                                {CANCEL}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddEmployee;
