import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../Config";
import { ADD, ACTION, HOME, CANCEL, UPDATE, DIAGNOSES_STATUS, MANAGE_DIAGNOSES, STATUS, NAME, ROWS_PER_PAGE, SORT_PRIORITY, CODE, DASH, ALL, NOTES_ONLY, BOOL_VALUES } from "../../Helper/Constants";
import { Modal, Button } from "react-bootstrap";

import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { NavLink, useNavigate } from "react-router-dom";
import { DIAGNOSES_LIST_API, ADD_DIAGNOSES, ADMIN_DETAIL_DIAGNOSES, ADMIN_EDIT_DIAGNOSES } from "../../Helper/ApiPath";
import { DELETE_MESSAGE, ENTER_PRIORITY_LESS_THAN_MAX_PRIORITY, MAX_PRIORITY } from "../../Helper/Messages";
import NoDataFound from "../CommonComponent/NoDataFound";
import Swal from "sweetalert2";
import Loader from "../CommonComponent/Loader";

function ManageDiagnoses() {
    const [currentPage, setCurrentPage] = useState(0);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [diagnosesList, setDiagnosesList] = useState([]);
    const [offset, setOffset] = useState(1);
    const [rowCount, setRowCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const token = JSON.parse(localStorage.getItem("logToken"));
    const [showDiagnoses, setshowDiagnoses] = useState(false);
    const handleCloseDiagnoMdl = () => setshowDiagnoses(false);

    const [diagLoader, setDiagLoader] = useState(false);

    const [filterValues, setfilterValues] = useState({
        search: "",
        status: "",
        searchCode: "",
        isNote: "",
    });
    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${DIAGNOSES_LIST_API}`, {
                headers: { authorization: token.token },
                page: offset,
                rowsPerPage: ROWS_PER_PAGE,
                statusId: filterValues.status,
                search: filterValues.search,
                searchCode: filterValues.searchCode,
                isNote: filterValues.isNote,
            })
            .then((res) => {
                setIsLoading(false);
                setDiagnosesList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    useEffect(() => {
        fetchData();
    }, [offset, filterValues]);

    const [editDiagnoStatus, setEditDiagnoStatus] = useState(false);
    const [diagnoses, setDiagnoses] = useState({
        name: "",
        code: "",
        statusId: "",
        isNote: "0",
        priorityList: MAX_PRIORITY,
    });

    const handleFilterValues = (e) => {
        const { name, value } = e.target;
        setfilterValues({ ...filterValues, [name]: value });
        setOffset(1);
        setCurrentPage(0);
    };

    const handleShowDaigno = (type) => {
        if (type === "add") {
            setshowDiagnoses(true);
            setDiagnoses({
                name: "",
                code: "",
                isNote: "0",
                priorityList: MAX_PRIORITY,
            });
            setEditDiagnoStatus(false);
        } else {
            setshowDiagnoses(true);
        }
        setDiagLoader(false);
    };
    const handleAddSubmit = async () => {
        if (!diagLoader) {
            if (diagnoses.name == "") {
                toast.error("Enter diagnoses name");
                return false;
            }
            if (!diagnoses.code) {
                toast.error("Enter diagnoses code");
                return false;
            }
            if (diagnoses.priorityList && diagnoses.priorityList > MAX_PRIORITY) {
                toast.error(ENTER_PRIORITY_LESS_THAN_MAX_PRIORITY);
            } else {
                setDiagLoader(true);
                await axios
                    .post(`${baseURL}/${ADD_DIAGNOSES}`, {
                        headers: { authorization: token.token },
                        name: diagnoses.name,
                        code: diagnoses.code,
                        priorityList: diagnoses.priorityList,
                        isNote: diagnoses.isNote,
                    })
                    .then((res) => {
                        setDiagLoader(false);
                        if (res.status === 200) {
                            setDiagnoses({
                                name: "",
                                code: "",
                                isNote: "0",
                            });
                            toast.success("Diagnoses has been added successfully..");
                            handleCloseDiagnoMdl(false);
                            fetchData();
                        }
                    })
                    .catch((err) => {
                        setDiagLoader(false);
                        if (err.response) {
                            if (err.response.status == 500) {
                                localStorage.clear();
                                navigate("/login");
                            } else {
                                toast.error(err.response.data.message);
                            }
                        }
                    });
            }
        }
    };

    const handleEditDiagno = async (id) => {
        handleShowDaigno(true);
        setEditDiagnoStatus(true);
        await axios
            .get(`${baseURL}/${ADMIN_DETAIL_DIAGNOSES}/${id}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                if (res.status === 200) {
                    setDiagnoses(res.data.data);
                    fetchData();
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const handleUpdate = async () => {
        if (!diagLoader) {
            const token = JSON.parse(localStorage.getItem("logToken"));
            if (!diagnoses.name) {
                toast.error("Enter diagnoses name");
            }
            if (!diagnoses.code) {
                toast.error("Enter diagnoses code");
            }
            if (diagnoses.priorityList && diagnoses.priorityList > MAX_PRIORITY) {
                toast.error(ENTER_PRIORITY_LESS_THAN_MAX_PRIORITY);
            } else {
                setDiagLoader(true);
                await axios
                    .post(`${baseURL}/${ADMIN_EDIT_DIAGNOSES}`, {
                        headers: { authorization: token.token },
                        diagnosesId: diagnoses.id,
                        name: diagnoses.name,
                        code: diagnoses.code,
                        statusId: diagnoses.statusId,
                        priorityList: diagnoses.priorityList,
                        isNote: diagnoses.isNote,
                    })
                    .then((res) => {
                        setDiagLoader(false);
                        if (res.status === 200) {
                            setDiagnoses({
                                name: "",
                                code: "",
                                isNote: "0",
                            });
                            handleCloseDiagnoMdl(false);
                            toast.success(res.data.message);
                            fetchData();
                        }
                    })
                    .catch((err) => {
                        setDiagLoader(false);
                        if (err.response) {
                            if (err.response.status == 500) {
                                localStorage.clear();
                                navigate("/login");
                            } else {
                                toast.error(err.response.data.message);
                            }
                        }
                    });
            }
        }
    };
    const handleDeleteDiagnoses = async (id) => {
        Swal.fire({
            title: DELETE_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/${ADMIN_DETAIL_DIAGNOSES}/${id}`, {
                            headers: { authorization: token.token },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire(res.data.message, " ", "success");

                                fetchData();
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };
    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{MANAGE_DIAGNOSES}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">Diagnoses List</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tabbable-line">
                                <div className="tab-content">
                                    <div className="tab-pane active fontawesome-demo" id="tab1">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card" style={isLoading ? { height: "35rem" } : {}}>
                                                    <div className="card-head">
                                                        <header>{MANAGE_DIAGNOSES}</header>
                                                    </div>
                                                    <div className="card-body ">
                                                        <div className="justify-content-between">
                                                            <div className="row">
                                                                <div className="col-1">
                                                                    <button id="addRow" className="btn btn-info" onClick={() => handleShowDaigno("add")}>
                                                                        {ADD}
                                                                        <i className="fa fa-plus"></i>
                                                                    </button>
                                                                </div>
                                                                <div className="col-11">
                                                                    <div className="row">
                                                                        <div className="col-4"></div>
                                                                        <div className="col-2">
                                                                            <div className="row">
                                                                                <div className="col-4">
                                                                                    <label className="mt-2">{NAME}</label>
                                                                                </div>
                                                                                <div className="col-8">
                                                                                    <input
                                                                                        className="form-control"
                                                                                        name="search"
                                                                                        type="search"
                                                                                        placeholder="Search"
                                                                                        aria-label="Search"
                                                                                        value={filterValues.search}
                                                                                        onChange={handleFilterValues}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <div className="row">
                                                                                <div className="col-4">
                                                                                    <label className="mt-2">{CODE}</label>
                                                                                </div>
                                                                                <div className="col-8">
                                                                                    <input
                                                                                        className="form-control"
                                                                                        name="searchCode"
                                                                                        type="search"
                                                                                        placeholder="Search"
                                                                                        aria-label="Search"
                                                                                        value={filterValues.searchCode}
                                                                                        onChange={handleFilterValues}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <div className="row">
                                                                                <div className="col-5">
                                                                                    <label className="mt-2">{STATUS}</label>
                                                                                </div>
                                                                                <div className="col-7">
                                                                                    <select className="form-control col-md-3" name="status" value={filterValues.status} onChange={handleFilterValues}>
                                                                                        <option value="">{ALL}</option>
                                                                                        {Object.keys(DIAGNOSES_STATUS).map((item, i) => {
                                                                                            return (
                                                                                                <option key={i} value={DIAGNOSES_STATUS[item]}>
                                                                                                    {item}
                                                                                                </option>
                                                                                            );
                                                                                        })}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <div className="row">
                                                                                <div className="col-5">
                                                                                    <label className="mt-2">{NOTES_ONLY}</label>
                                                                                </div>
                                                                                <div className="col-7">
                                                                                    <select className="form-control col-md-3" name="isNote" value={filterValues.isNote} onChange={handleFilterValues}>
                                                                                        <option value="">{ALL}</option>
                                                                                        {Object.keys(BOOL_VALUES).map((item, i) => {
                                                                                            return (
                                                                                                <option key={i} value={BOOL_VALUES[item]}>
                                                                                                    {item}
                                                                                                </option>
                                                                                            );
                                                                                        })}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="table-scrollable" style={isLoading ? { border: "none" } : {}}>
                                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{NAME}</th>
                                                                        <th>{CODE}</th>
                                                                        <th>{SORT_PRIORITY}</th>
                                                                        <th>{NOTES_ONLY}</th>
                                                                        <th>{STATUS}</th>
                                                                        <th>{ACTION}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={isLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                                    {isLoading ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        <>
                                                                            {!diagnosesList ? (
                                                                                <>{isLoading ? "" : <NoDataFound />}</>
                                                                            ) : (
                                                                                diagnosesList &&
                                                                                diagnosesList.map((item, index) => {
                                                                                    return (
                                                                                        <tr className="odd gradeX" key={index}>
                                                                                            <td className="left">{item.name}</td>
                                                                                            <td className="left">{item.code ? item.code : DASH}</td>
                                                                                            <td>{item.priorityList ? item.priorityList : DASH}</td>
                                                                                            <td>
                                                                                                <span
                                                                                                    className={
                                                                                                        "badge " +
                                                                                                        (item.isNote == BOOL_VALUES.YES ? "activeStatus  badge-success" : "deactiveStatus badge-danger")
                                                                                                    }
                                                                                                >
                                                                                                    {Object.keys(BOOL_VALUES).find((key) => BOOL_VALUES[key] === item.isNote)}
                                                                                                </span>
                                                                                            </td>

                                                                                            <td className="left">
                                                                                                <span
                                                                                                    className={
                                                                                                        "badge " +
                                                                                                        (item.statusId == 2
                                                                                                            ? "deactiveStatus badge-warning"
                                                                                                            : item.statusId == 1
                                                                                                            ? "activeStatus badge-success"
                                                                                                            : "badge-danger")
                                                                                                    }
                                                                                                >
                                                                                                    {Object.keys(DIAGNOSES_STATUS).find((key) => DIAGNOSES_STATUS[key] === item.statusId)}
                                                                                                </span>
                                                                                            </td>
                                                                                            <td>
                                                                                                <button className="btn btn-primary btn-xs" onClick={() => handleEditDiagno(item.id)}>
                                                                                                    <i className="fa fa-pencil"></i>
                                                                                                </button>
                                                                                                <button type="button" className="btn btn-danger btn-xs" onClick={() => handleDeleteDiagnoses(item.id)}>
                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mx-4">
                                                        <div>Total {diagnosesList == null ? "0" : rowCount} Diagnoses</div>
                                                        <div className="pagination-center">
                                                            <ReactPaginate
                                                                initialPage={0}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={0}
                                                                pageRangeDisplayed={3}
                                                                onPageChange={handlePageClick}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                pageLinkClassName="page-link"
                                                                breakLinkClassName="page-link"
                                                                nextLinkClassName="page-link"
                                                                previousLinkClassName="page-link"
                                                                pageClassName="page-item"
                                                                breakClassName="page-item"
                                                                nextClassName="page-item"
                                                                previousClassName="page-item"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"
                                                                disableInitialCallback={true}
                                                                forcePage={currentPage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showDiagnoses} onHide={handleCloseDiagnoMdl}>
                <Modal.Header closeButton>{editDiagnoStatus ? <Modal.Title>Update Diagnoses </Modal.Title> : <Modal.Title>Add Diagnoses </Modal.Title>}</Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <label className="control-label col-md-12">
                            {NAME}
                            <span className="required"> * </span>
                        </label>
                        <div className="col-md-12">
                            <input
                                type="text"
                                value={diagnoses.name}
                                onChange={(e) => setDiagnoses({ ...diagnoses, name: e.target.value })}
                                placeholder="Enter Name"
                                className="form-control input-height"
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="control-label col-md-12">
                            {CODE}
                            <span className="required"> * </span>
                        </label>
                        <div className="col-md-12">
                            <input
                                type="text"
                                value={diagnoses.code}
                                onChange={(e) => setDiagnoses({ ...diagnoses, code: e.target.value })}
                                placeholder="Enter Code"
                                className="form-control input-height"
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-6">
                            <label className="control-label col-md-12">{SORT_PRIORITY}</label>
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    value={diagnoses.priorityList}
                                    onChange={(e) => setDiagnoses({ ...diagnoses, priorityList: e.target.value })}
                                    placeholder="Enter Sort Priority"
                                    className="form-control input-height"
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <label className="control-label col-md-12">{NOTES_ONLY}</label>
                            <div className="col-md-12">
                                <select className="form-control col-md-3" name="isNote" value={diagnoses.isNote} onChange={(e) => setDiagnoses({ ...diagnoses, isNote: e.target.value })}>
                                    {Object.keys(BOOL_VALUES).map((item, i) => {
                                        return (
                                            <option key={i} value={BOOL_VALUES[item]}>
                                                {item}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-12">
                            <label className="mt-2 col-md-6">{STATUS}</label>
                            <select className="form-control col-md-3" name="statusId" value={diagnoses.statusId} onChange={(e) => setDiagnoses({ ...diagnoses, statusId: e.target.value })}>
                                {Object.keys(DIAGNOSES_STATUS).map((item, i) => {
                                    return (
                                        <option key={i} value={DIAGNOSES_STATUS[item]}>
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                </Modal.Body>
                <div className="d-flex justify-content-center mb-3">
                    <div className="center">
                        {editDiagnoStatus ? (
                            <Button variant="primary" onClick={handleUpdate}>
                                {diagLoader ? <i className="spinner-border"></i> : ""} {UPDATE}
                            </Button>
                        ) : (
                            <Button variant="primary" onClick={handleAddSubmit}>
                                {diagLoader ? <i className="spinner-border"></i> : ""} {ADD}
                            </Button>
                        )}

                        <Button className="mx-3" variant="secondary" onClick={handleCloseDiagnoMdl}>
                            {CANCEL}
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default ManageDiagnoses;
