import React from "react";
import { Button, Modal } from "react-bootstrap";
import { ADD, ADD_GROUP, CANCEL, COMPREHENSIVE_PRIORITY, GROUP_STATUS, NAME, PRE_AUTH_PRIORITY, STATUS, UPDATE, UPDATE_GROUP } from "../../../../Helper/Constants";
import { MIN_PRIORITY } from "../../../../Helper/Messages";

function AddGroup(props) {
    return (
        <>
            <Modal show={props.addGroupShow} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.edit ? UPDATE_GROUP : ADD_GROUP}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <label className="control-label col-md-12">
                            {NAME}
                            <span className="required"> * </span>
                        </label>
                        <div className="col-md-12">
                            <input type="text" value={props.groupAdd.name} name="name" onChange={props.handleGroupAddChange} placeholder="Enter Group Name" className="form-control input-height" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="row">
                            <div className="col-8">
                                <label className="control-label col-md-12">{COMPREHENSIVE_PRIORITY}</label>
                            </div>
                            <div className="col-3">
                                <input type="checkbox" className="checkBox mt-2 mb-2 mx-2" checked={props.groupAdd.priorityList == MIN_PRIORITY ? true : false} onChange={props.handleCNCheck} />
                                Disable
                            </div>
                        </div>
                        <div className="col-md-12" id="comprehensiveCheck">
                            {props.groupAdd.priorityList == MIN_PRIORITY || props.disableCN ? (
                                <input
                                    type="text"
                                    value={props.groupAdd.priorityList}
                                    name="priorityList"
                                    onChange={props.handleGroupAddChange}
                                    placeholder="9999"
                                    className="form-control input-height"
                                    disabled="disabled"
                                />
                            ) : (
                                <input
                                    type="text"
                                    value={props.groupAdd.priorityList}
                                    name="priorityList"
                                    onChange={props.handleGroupAddChange}
                                    placeholder="9999"
                                    className="form-control input-height"
                                />
                            )}
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="row">
                            <div className="col-8">
                                <label className="control-label col-md-12">{PRE_AUTH_PRIORITY}</label>
                            </div>
                            <div className="col-3">
                                <input type="checkbox" className="checkBox mt-2 mb-2 mx-2" checked={props.groupAdd.preAuthPriority == MIN_PRIORITY ? true : false} onChange={props.handlePRACheck} />
                                Disable
                            </div>
                        </div>
                        {props.groupAdd.preAuthPriority == MIN_PRIORITY || props.disablePRA ? (
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    value={props.groupAdd.preAuthPriority}
                                    name="preAuthPriority"
                                    onChange={props.handleGroupAddChange}
                                    placeholder="9999"
                                    className="form-control input-height"
                                    disabled="disabled"
                                />
                            </div>
                        ) : (
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    value={props.groupAdd.preAuthPriority}
                                    name="preAuthPriority"
                                    onChange={props.handleGroupAddChange}
                                    placeholder="9999"
                                    className="form-control input-height"
                                />
                            </div>
                        )}
                    </div>
                    <div className="form-group row">
                        <div className="col-md-12">
                            <label className="mt-2 col-md-6">
                                {STATUS}
                                <span className="required"> * </span>
                            </label>
                            <select className="form-control col-md-3" name="statusId" value={props.groupAdd.statusId} onChange={props.handleGroupAddChange}>
                                {Object.keys(GROUP_STATUS).map((item, i) => {
                                    return (
                                        <option key={i} value={GROUP_STATUS[item]}>
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.handleGroupsave}>
                        {props.edit ? UPDATE : ADD}
                    </Button>
                    <Button variant="secondary" onClick={props.handleClose}>
                        {CANCEL}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddGroup;
