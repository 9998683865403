import axios from "axios";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL, imageSize_file } from "../../../Config";
import { UPLOAD_RECEIPT_DOCUMENT } from "../../../Helper/ApiPath";
import { ADD, CANCEL, EDIT, EDIT_RECEIPT_FILE, RECEIPT_FILE } from "../../../Helper/Constants";
import { UPLOAD_IMAGE, EXCEED_UPLOAD_SIZE_MAX_FILE } from "../../../Helper/Messages";

function ReceiptFileUpload(props) {
    let navigate = useNavigate();

    let logToken = JSON.parse(localStorage.getItem("logToken"));
    let receiptId = localStorage.getItem("receiptId");
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let [image, setImage] = useState({ preview: "", data: "" });

    const handleImageChange = (e) => {
        const data = {
            preview: URL.createObjectURL(e.target.files[0]),
            data: e.target.files[0],
            name: e.target.files[0].name,
        };
        const fileExtension = data.data.name.split(".").at(-1).toLowerCase();
        let allowedFileTypes = ["pdf", "jpg", "png", "gif", "jpeg"];
        if (!allowedFileTypes.includes(fileExtension)) {
            e.target.value = null;
            alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
        } else {
            document.getElementById("button").classList.remove("d-none");
            setImage(data);
        }
    };

    const handleUpload = async () => {
        let formData = new FormData();

        if (!image.data) {
            toast.error(UPLOAD_IMAGE);
        } else if (image.data.size > imageSize_file) {
            toast.error(EXCEED_UPLOAD_SIZE_MAX_FILE);
        } else {
            formData.append("image", image.data);
            formData.append("fileName", image.name);
            formData.append("receiptId", props.edit ? props.localReceiptId : receiptId);

            formData.append("receiptDocumentId", props.edit ? props.editId : "");
            await axios
                .post(`${baseURL}/${UPLOAD_RECEIPT_DOCUMENT}`, formData, {
                    headers: { authorization: logToken.token, "Content-Type": "multipart/form-data" },
                })
                .then((res) => {
                    toast.success(res.data.message);
                    props.edit ? props.closeDocumentEdit() : handleClose();
                    props.fetchThumbnailData();
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };
    return (
        <>
            {" "}
            <button className="btn btn-info mx-2" style={{ borderRadius: "50%", width: "35px", height: "35px" }} onClick={handleShow}>
                <i className="fa fa-plus" style={{ marginLeft: "-1px" }}></i>{" "}
            </button>
            <Modal show={props.edit ? props.show : show} onHide={props.edit ? props.closeDocumentEdit : handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.edit ? EDIT_RECEIPT_FILE : RECEIPT_FILE}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {" "}
                    <form>
                        <div className="offset-3 col-md-6">
                            <input type="file" className="form-control" name="image" onChange={handleImageChange} />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary d-none" id="button" onClick={handleUpload}>
                        {props.edit ? EDIT : ADD}
                    </Button>
                    <Button variant="default" onClick={props.edit ? props.closeDocumentEdit : handleClose}>
                        {CANCEL}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ReceiptFileUpload;
