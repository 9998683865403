import React, { useEffect, useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { baseURL, frontURL } from "../../Config";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { dateAPIFormate, dateFormateMonthDateYear, defaultTimeZone } from "../../Helper/Helper";
import {
    CLAIM_OP_NOTES_LIST,
    CLAIM_,
    DOCTOR,
    PATIENT,
    DATE_OF_SERVICE,
    AMOUNT,
    CREATED_DATE,
    ACTION,
    HOME,
    DASH,
    ROWS_PER_PAGE,
    CREATED_BY,
    SUBMITTED_DATE,
    SELECT_DOCTOR,
} from "../../Helper/Constants";
import { DETAIL_NOTE_API, ALL_DOCTOR_IN_SELECTED_OFFICE, CLAIM_LIST_API, OP_NOTE_DOWNLOAD_API } from "../../Helper/ApiPath";
import NoDataFound from "../CommonComponent/NoDataFound";
import Loader from "../CommonComponent/Loader";
import Select from "react-select";
import Swal from "sweetalert2";
import { DELETE_OP_NOTE_MESSAGE } from "../../Helper/Messages";
import Cookies from "universal-cookie";

function DoctorManageOpNotes() {
    const cookies = new Cookies();
    const opNotePagination = JSON.parse(sessionStorage.getItem("opNotePagination"));
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [offset, setOffset] = useState(opNotePagination && opNotePagination.offset ? opNotePagination.offset : 1);
    const [rowCount, setRowCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [claimList, setClaimList] = useState([]);
    const [doctorSelect, setDoctorSelect] = useState([]);
    const [doctorId, setDoctorId] = useState(opNotePagination && opNotePagination.doctorId ? opNotePagination.doctorId : "");

    const token = JSON.parse(localStorage.getItem("logToken"));
    let officeId = localStorage.getItem("officeId");
    let navigate = useNavigate();

    let pageAuthorityRoleId = cookies.get("role");
    if (pageAuthorityRoleId) {
        pageAuthorityRoleId = cookies.get("role").pageAuthorityRoleId;
    }

    const handlePdfDownload = async (doctorId, OPNoteId) => {
        axios
            .post(`${baseURL}/${OP_NOTE_DOWNLOAD_API}`, {
                headers: { authorization: token.token },
                OPNoteId: OPNoteId,
                doctorId: doctorId,
            })
            .then(async (res) => {
                axios({
                    url: `${frontURL}${res.data.data.path}`,
                    method: "GET",
                    responseType: "blob",
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `${res.data.data.patientName + "_op-note" + ".pdf"}`);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url);
                });
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleAddId = (id) => {
        sessionStorage.setItem(
            "opNotePagination",
            opNotePagination
                ? JSON.stringify(opNotePagination)
                : JSON.stringify({
                      offset: offset,
                      startDate: defaultTimeZone(filterValues.startDate),
                      endDate: defaultTimeZone(filterValues.endDate),
                      doctorId: doctorId,
                      patientSearch: filterValues.patientSearch,
                  })
        );
        localStorage.setItem("claimId", id);
        navigate("/OpNotes");
    };

    const handleClick = (id) => {
        sessionStorage.setItem(
            "opNotePagination",
            opNotePagination
                ? JSON.stringify(opNotePagination)
                : JSON.stringify({
                      offset: offset,
                      startDate: defaultTimeZone(filterValues.startDate),
                      endDate: defaultTimeZone(filterValues.endDate),
                      doctorId: doctorId,
                      patientSearch: filterValues.patientSearch,
                  })
        );
        localStorage.setItem("claimId", id);
        localStorage.removeItem("claimPath");
        localStorage.setItem("claimPath", "opNote");
    };

    const handleId = (id, opNoteId) => {
        sessionStorage.setItem(
            "opNotePagination",
            opNotePagination
                ? JSON.stringify(opNotePagination)
                : JSON.stringify({
                      offset: offset,
                      startDate: defaultTimeZone(filterValues.startDate),
                      endDate: defaultTimeZone(filterValues.endDate),
                      doctorId: doctorId,
                      patientSearch: filterValues.patientSearch,
                  })
        );
        localStorage.setItem("claimId", id);
        localStorage.setItem("opNoteId", opNoteId);
        navigate("/editOpNotes");
    };

    const handlePageClick = (event) => {
        sessionStorage.removeItem("opNotePagination");
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    var date = new Date();
    const [filterValues, setFilterValues] = useState({
        patientSearch: opNotePagination && opNotePagination.patientSearch ? opNotePagination.patientSearch : "",
        startDate: opNotePagination && opNotePagination.startDate ? defaultTimeZone(opNotePagination.startDate) : new Date(date.setMonth(date.getMonth() - 12)),
        endDate: opNotePagination && opNotePagination.endDate ? defaultTimeZone(opNotePagination.endDate) : new Date(date.setMonth(date.getMonth() + 13)),
    });

    const handleFilterValues = (e) => {
        const { name, value } = e.target;
        setFilterValues({ ...filterValues, [name]: value });
        sessionStorage.setItem(
            "opNotePagination",
            JSON.stringify({
                ...filterValues,
                [name]: value,
                offset: offset,
                doctorId: doctorId,
            })
        );
        setOffset(1);
        setCurrentPage(0);
    };

    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${CLAIM_LIST_API}`, {
                headers: { authorization: token.token },
                officeId: officeId,
                startDate: dateAPIFormate(filterValues.startDate),
                endDate: dateAPIFormate(filterValues.endDate),
                page: offset,
                rowsPerPage: ROWS_PER_PAGE,
                sortBy: "",
                isSubmitted: 1,
                submitted_date: "",
                sortDirection: "",
                doctorId: doctorId,
                patientSearch: filterValues.patientSearch,
            })
            .then((res) => {
                setIsLoading(false);
                setClaimList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    let doctorList = [
        {
            id: "",
            firstName: SELECT_DOCTOR,
        },
    ];
    const fetchDoctorList = async (e) => {
        await axios
            .post(`${baseURL}/${ALL_DOCTOR_IN_SELECTED_OFFICE}`, {
                headers: { authorization: token.token },
                officeId: officeId,
                isDoctor: true,
            })
            .then((res) => {
                setDoctorSelect(res.data.data ? doctorList.concat(res.data.data) : doctorList);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const doctorSelectOptions =
        doctorSelect &&
        doctorSelect.map((item, i) => {
            return {
                value: item.id,
                label: item.firstName + " " + (item.lastName ? item.lastName : ""),
            };
        });

    const handleDelete = (id) => {
        Swal.fire({
            title: DELETE_OP_NOTE_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/${DETAIL_NOTE_API}/${id}`, {
                            headers: { authorization: token.token },
                        })
                        .then((res) => {
                            Swal.fire(res.data.message, " ", "success");
                            fetchData();
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };
    
    const handleDoctorChange = async (e) => {
        sessionStorage.removeItem("opNotePagination");
        setDoctorId(e.value);
        setOffset(1);
        setCurrentPage(0);
    };

    useEffect(() => {
        fetchData();
        fetchDoctorList();
    }, [offset, filterValues, doctorId]);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{CLAIM_OP_NOTES_LIST}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{CLAIM_OP_NOTES_LIST}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tabbable-line">
                                <div className="tab-content">
                                    <div className="tab-pane active fontawesome-demo" id="tab1">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card">
                                                    <div className="card-body ">
                                                        <div className="row">
                                                            <div className="justify-content-between">
                                                                <div className="row">
                                                                    <div className="col-md-2">
                                                                        <label className="mt-2">{SUBMITTED_DATE}</label>
                                                                    </div>
                                                                    <div className="btn-group col-md-2 pr-0">
                                                                        <DatePicker
                                                                            className="form-control mr-sm-2"
                                                                            selected={filterValues.startDate}
                                                                            maxDate={new Date()}
                                                                            yearDropdownItemNumber={100}
                                                                            scrollableMonthDropdown={true}
                                                                            showYearDropdown
                                                                            showMonthDropdown
                                                                            onChange={(date) => {
                                                                                sessionStorage.setItem(
                                                                                    "opNotePagination",
                                                                                    JSON.stringify({
                                                                                        ...filterValues,
                                                                                        startDate: date,
                                                                                    })
                                                                                );
                                                                                setFilterValues({ ...filterValues, startDate: date });
                                                                            }}
                                                                        />
                                                                        <span className="todate">To</span>
                                                                    </div>
                                                                    <div className="btn-group col-md-2">
                                                                        <DatePicker
                                                                            className="form-control mr-sm-2"
                                                                            selected={filterValues.endDate}
                                                                            minDate={filterValues.startDate}
                                                                            maxDate={new Date().setMonth(new Date().getMonth() + 1)}
                                                                            yearDropdownItemNumber={100}
                                                                            scrollableMonthDropdown={true}
                                                                            showYearDropdown
                                                                            showMonthDropdown
                                                                            onChange={(date) => {
                                                                                sessionStorage.setItem(
                                                                                    "opNotePagination",
                                                                                    JSON.stringify({
                                                                                        ...filterValues,
                                                                                        endDate: date,
                                                                                    })
                                                                                );
                                                                                setFilterValues({ ...filterValues, endDate: date });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-2 mr-sm-2"></div>
                                                                    <div className="col-md-2 mr-sm-2 ">
                                                                        <Select
                                                                            name="doctorSelect"
                                                                            value={doctorSelectOptions && doctorSelectOptions.filter(({ value }) => value == doctorId)}
                                                                            options={doctorSelectOptions}
                                                                            onChange={handleDoctorChange}
                                                                            placeholder={SELECT_DOCTOR}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-2 mr-sm-2 d-flex">
                                                                        <input
                                                                            className="form-control mr-sm-2"
                                                                            name="patientSearch"
                                                                            type="search"
                                                                            placeholder="Patient"
                                                                            aria-label="Search"
                                                                            value={filterValues.patientSearch}
                                                                            onChange={handleFilterValues}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="table-scrollable" style={isLoading ? { border: "none" } : {}}>
                                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{CLAIM_}</th>
                                                                        <th>{DOCTOR}</th>
                                                                        <th>{PATIENT}</th>
                                                                        <th>{DATE_OF_SERVICE}</th>
                                                                        {pageAuthorityRoleId === 7 ? (<> </>) :
                                                                            <th>{AMOUNT}</th>
                                                                        }
                                                                        <th>{SUBMITTED_DATE}</th>
                                                                        <th>{CREATED_DATE}</th>
                                                                        <th>{CREATED_BY}</th>
                                                                        <th>{ACTION}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={isLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                                    {isLoading ? (
                                                                        <>
                                                                            <Loader />
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {!claimList ? (
                                                                                <>{isLoading ? "" : <NoDataFound />}</>
                                                                            ) : (
                                                                                claimList &&
                                                                                claimList.map((item, index) => {
                                                                                    return (
                                                                                        <tr className="odd gradeX" key={index}>
                                                                                            <td className="left">
                                                                                                <Link
                                                                                                    to="/DetailClaim"
                                                                                                    style={{ textDecoration: "underline", color: "#2a32be" }}
                                                                                                    onClick={() => {
                                                                                                        handleClick(item.id);
                                                                                                    }}
                                                                                                >
                                                                                                    {item.code}
                                                                                                </Link>
                                                                                            </td>
                                                                                            <td className="left">
                                                                                                {item.doctor.firstName} {item.doctor.lastName}{" "}
                                                                                            </td>
                                                                                            <td className="left">
                                                                                                {item.patient.firstName} {item.patient.lastName}
                                                                                            </td>
                                                                                            <td className="left">{item.dateOfService ? dateFormateMonthDateYear(item.dateOfService) : DASH}</td>
                                                                                            {pageAuthorityRoleId === 7 ? (<> </>) :
                                                                                                <td className="left">${item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                                                            }
                                                                                            <td className="left">{item.submitted_date ? dateFormateMonthDateYear(item.submitted_date) : DASH}</td>
                                                                                            <td className="left">{item.OPNote[0] ? dateFormateMonthDateYear(item.OPNote[0].created_at) : DASH}</td>

                                                                                            <td>{item.create_by ? item.create_by : DASH}</td>
                                                                                            <td className="">
                                                                                                {item.isOPNote ? (
                                                                                                    <>
                                                                                                        <div className="btn btn-primary btn-xs" onClick={() => handleId(item.id, item.OPNote[0].id)}>
                                                                                                            <i className="fa fa-pencil mt-1"></i>
                                                                                                        </div>
                                                                                                        <div className="btn btn-danger btn-xs" onClick={() => handleDelete(item.OPNote[0].id)}>
                                                                                                            <i className="fa fa-trash mt-1"></i>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="btn btn-success btn-xs"
                                                                                                            onClick={() => handlePdfDownload(item.doctor.uniqueId, item.OPNote[0].id)}
                                                                                                        >
                                                                                                            <i className="fa fa-download mt-1"></i>
                                                                                                        </div>
                                                                                                        {/* <ComponentToPrintWrapper
                                                                                                            key={index}
                                                                                                            noteId={item.OPNote.length ? item.OPNote[0].id : ""}
                                                                                                            claimId={item.id ? item.id : ""}
                                                                                                        /> */}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <div className="btn btn-warning btn-xs" onClick={() => handleAddId(item.id)}>
                                                                                                        <i className="fa fa-plus mt-1"></i>
                                                                                                    </div>
                                                                                                )}
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex justify-content-between mx-4">
                                                        <div>Total {claimList == null ? "0" : rowCount} Claim</div>
                                                        <div className="pagination-center">
                                                            <ReactPaginate
                                                                initialPage={0}
                                                                pageCount={claimList == null ? "0" : pageCount}
                                                                marginPagesDisplayed={0}
                                                                pageRangeDisplayed={3}
                                                                onPageChange={handlePageClick}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                pageLinkClassName="page-link"
                                                                breakLinkClassName="page-link"
                                                                nextLinkClassName="page-link"
                                                                previousLinkClassName="page-link"
                                                                pageClassName="page-item"
                                                                breakClassName="page-item"
                                                                nextClassName="page-item"
                                                                previousClassName="page-item"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"
                                                                disableInitialCallback={true}
                                                                forcePage={opNotePagination && opNotePagination.offset ? opNotePagination.offset - 1 : currentPage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DoctorManageOpNotes;
// const ComponentToPrint = React.forwardRef((props, ref) => {
//     let navigate = useNavigate();

//     let token = JSON.parse(localStorage.getItem("logToken"));
//     const [isLoading, setIsLoading] = useState(true);
//     const [claimDetail, setClaimDetail] = useState([]);
//     const [time, setTime] = useState({
//         startTime: "",
//         endTime: "",
//     });
//     const [imageURL, setImageURl] = useState(null);
//     const [editorValue, setEditorValue] = useState("");
//     const { noteId, claimId } = props;

//     const fetchdata = async () => {
//         await axios
//             .get(`${baseURL}/${DETAIL_NOTE_API}/${noteId}`, {
//                 headers: { authorization: token.token },
//             })
//             .then((res) => {
//                 setIsLoading(false);
//                 setEditorValue(res.data.data.note);
//                 setImageURl(res.data.data.signatureImage);
//                 setTime({ ...time, startTime: res.data.data.startTime, endTime: res.data.data.endTime });
//             })
//             .catch((err) => {
//                 setIsLoading(false);

//                 if (err.response) {
//                     if (err.response.status == 500) {
//                         localStorage.clear();
//                         navigate("/login");
//                     } else {
//                         toast.error(err.response.data.message);
//                     }
//                 }
//             });
//     };
//     const fetchClaimdata = async () => {
//         await axios
//             .get(`${baseURL}/${CLAIM_DETAIL}/${claimId}`, {
//                 headers: { authorization: token.token },
//             })
//             .then((res) => {
//                 setIsLoading(false);
//                 setClaimDetail(res.data.data);
//             })
//             .catch((err) => {
//                 setIsLoading(false);
//                 if (err.response) {
//                     if (err.response.status == 500) {
//                         localStorage.clear();
//                         navigate("/login");
//                     } else {
//                         toast.error(err.response.data.message);
//                     }
//                 }
//             });
//     };

//     useEffect(() => {
//         fetchdata();
//         fetchClaimdata();
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, []);

//     return (
//         <div className="print-source" ref={ref}>
//             {parse(editorValue)}
//             <div className="">
//                 <br />
//                 <br />
//                 <br />
//                 <b>Dr. Name:</b> {claimDetail.doctor ? claimDetail.doctor.firstName : ""} {claimDetail.doctor ? claimDetail.doctor.lastName : ""}
//                 <br />
//                 <b>License Number:</b> {claimDetail.doctor ? claimDetail.doctor.licenseNumber : DASH}
//                 <br />
//                 <b>Date:</b> {claimDetail.dateOfService ? dateFormateMonthDateYear(claimDetail.dateOfService) : DASH}
//                 <br />
//                 <b>Start Time:</b> {opNoteTime(time.startTime)} <span>&nbsp; &nbsp;</span> <b>End Time:</b> {opNoteTime(time.endTime)} <br />
//                 <b className={`${imageURL ? "my-5" : ""}`}>Sign :</b>
//                 {imageURL ? (
//                     <img
//                         className="mx-2 my-2"
//                         src={imageURL}
//                         alt="Sign"
//                         style={{
//                             display: "block",
//                         }}
//                     />
//                 ) : null}
//             </div>
//         </div>
//     );
// });
// const ComponentToPrintWrapper = ({ noteId, claimId }) => {
//     const componentRef = useRef();

//     return (
//         <>
//             <ReactToPrint
//                 trigger={() => (
//                     <div className="btn btn-default btn-xs">
//                         <i className="fa fa-print mt-1" style={{ fontSize: "1.1rem" }}></i>
//                     </div>
//                 )}
//                 content={() => componentRef.current}
//             />
//             <div style={{ display: "none" }}>
//                 <ComponentToPrint ref={componentRef} noteId={noteId} claimId={claimId} />
//             </div>
//         </>
//     );
// };
