import { useState } from "react";
import {
    ADD,
    ADD_DOCTOR,
    ASSIGN_DOCTOR,
    BACK,
    BIRTH_DATE,
    CANCEL,
    CHOOSE_DOCTOR_TYPE,
    DOCTOR_CODE,
    DOCTOR_DETAIL,
    DOCTOR_INFORMATION,
    DOCTOR_NAME,
    DOCTOR_SPECIALTY,
    FEMALE,
    FIRST_NAME,
    GENDER,
    HOME,
    LAST_NAME,
    LICENSE_NUMBER,
    MALE,
    NPI1,
    NPI2,
    OR,
    PAYER,
    PHONE_LENGTH,
    PHONE_NO,
    PTAN_NUMBER,
    SELECT,
    SELECT_DOCTOR,
    SELECT_GENDER,
    SELECT_PAYER,
    SELECT_SPECIALTY,
    SSN,
    TAXID,
} from "../../Helper/Constants";
import axios from "axios";
import { baseURL } from "../../Config";
import { toast } from "react-toastify";
import { PAYER_LIST_WITHOUT_MIDDLEWARE_API, PRACTICE_LIST_API, PROFILE_API, REQUEST_DATA_DOCTOR_ADD, REQUEST_DATA_DOCTOR_CREATE, SPECIALTY_LIST_WITHOUT_PAGINATION } from "../../Helper/ApiPath";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Button } from "reactstrap";
import {
    AGE_MORE_THAN_18,
    ENTER_FEE_LESS_THAN_10,
    ENTER_FNAME,
    ENTER_LNAME,
    ENTER_NPI1,
    ENTER_NPI_LESS_THAN_10,
    ENTER_PTAN_LESS_THAN_10,
    ENTER_SPECIALTY,
    ENTER_SSN_LESS_THAN_10,
    ENTER_TAX_ID_LESS_THAN_10,
    MAX_LENGTH,
    PHONE_NUMBER,
} from "../../Helper/Messages";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { dateAPIFormate, minYear } from "../../Helper/Helper";
import Select from "react-select";

function DoctorAddDoctor() {
    const [isLoading, setIsLoading] = useState(false);
    const token = JSON.parse(localStorage.getItem("logToken")).token;
    const officeId = localStorage.getItem("doctorOfficeId");
    const navigate = useNavigate();
    const [payer, setPayer] = useState([]);

    const [doctor, setDoctor] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        phoneNo1: "",
        dob: "",
        npi1: "",
        npi2: "",
        ssn: "",
        taxId: "",
        licenseNumber: "",
        gender: "",
        speciality: "",
        ptanNumber: "",
        payer: "4b2ea166e5d146d5bc587d7e766166f80a2d1d7430f04edf97b34666e8719343",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "ssn" || name === "taxId" || name === "npi1" || name === "npi2") {
            const onlyNumber = e.target.value.replace(/[^0-9,-]/g, "");
            setDoctor({ ...doctor, [name]: onlyNumber });
        } else {
            setDoctor({ ...doctor, [name]: value });
        }
    };
    const handlePayerChange = (e) => {
        let label = e.label;
        let value = e.id;
        setDoctor({ ...doctor, payerLabel: label, payer: value });
    };
    const payerList = () => {
        axios
            .get(`${baseURL}/${PAYER_LIST_WITHOUT_MIDDLEWARE_API}`)
            .then((res) => {
                setPayer(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const payerOptions =
        payer &&
        payer.map((item, i) => {
            return {
                id: item.id,
                value: item.value,
                label: item.name,
            };
        });
        
    const [specialtyList, setSpecialtyList] = useState([]);

    const specialtyData = async () => {
        await axios
            .post(`${baseURL}/${SPECIALTY_LIST_WITHOUT_PAGINATION}`)
            .then((res) => {
                setSpecialtyList(res.data.data);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };
    
    const specialtyOptions =
        specialtyList &&
        specialtyList.map((item, i) => {
            return {
                id: item.id,
                value: item.id,
                label: item.name,
            };
        });

    const handleSpecialtyChange = (e) => {
        setDoctor({ ...doctor, speciality: e.value });
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const dob = doctor.dob;
        var today = new Date();
        var birthDate = new Date(dob);
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--;
        }

        if (!doctor.firstName) {
            toast.error(ENTER_FNAME);
        } else if (!doctor.lastName) {
            toast.error(ENTER_LNAME);
        } else if (!doctor.npi1) {
            toast.error(ENTER_NPI1);
        } else if (doctor.npi1 && doctor.npi1.length > MAX_LENGTH) {
            toast.error(ENTER_NPI_LESS_THAN_10);
        } else if (doctor.npi2 && doctor.npi2.length > MAX_LENGTH) {
            toast.error(ENTER_NPI_LESS_THAN_10);
        } else if (doctor.ssn && doctor.ssn.length > MAX_LENGTH) {
            toast.error(ENTER_SSN_LESS_THAN_10);
        } else if (doctor.taxId && doctor.taxId.length > MAX_LENGTH) {
            toast.error(ENTER_TAX_ID_LESS_THAN_10);
        } else if (doctor.ptanNumber && doctor.ptanNumber.length > MAX_LENGTH) {
            toast.error(ENTER_PTAN_LESS_THAN_10);
        } else if (doctor.fee && doctor.fee.length > MAX_LENGTH) {
            toast.error(ENTER_FEE_LESS_THAN_10);
        } else if (!doctor.speciality) {
            toast.error(ENTER_SPECIALTY);
        } else if (doctor.dob && age_now < 18) {
            toast.error(AGE_MORE_THAN_18);
        } else if (doctor.phoneNo1 && doctor.phoneNo1.length > PHONE_LENGTH) {
            toast.error(PHONE_NUMBER);
        } else {
            setIsLoading(true);
            await axios
                .post(`${baseURL}/${REQUEST_DATA_DOCTOR_CREATE}`, {
                    headers: { authorization: token },
                    firstName: doctor.firstName,
                    middleName: doctor.middleName,
                    lastName: doctor.lastName,
                    phoneNo1: doctor.phoneNo1,
                    dob: dateAPIFormate(doctor.dob),
                    profilePic: "",
                    npi1: doctor.npi1,
                    npi2: doctor.npi2,
                    ssn: doctor.ssn,
                    taxId: doctor.taxId,
                    licenseNumber: doctor.licenseNumber,
                    gender: doctor.gender,
                    speciality: doctor.speciality,
                    ptanNumber: doctor.ptanNumber,
                    officeId: officeId,
                    payerId: doctor.payer,
                })
                .then((res) => {
                    setIsLoading(false);
                    if (res.status === 200) {
                        setDoctor("");
                        toast.success(res.data.message);
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const [doctorSelect, setDoctorSelect] = useState([]);

    let paracticeSelect = [
        {
            id: "",
            firstName: SELECT,
            lastName: "",
        },
    ];

    const fetchDoctorList = async (e) => {
        await axios
            .post(`${baseURL}/${PRACTICE_LIST_API}`, {
                headers: { authorization: token },
            })
            .then((res) => {
                setDoctorSelect(res.data.data ? paracticeSelect.concat(res.data.data) : res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const doctorSelectOptions =
        doctorSelect &&
        doctorSelect.map((item, i) => {
            return {
                value: item.id,
                label: item.firstName + " " + item.lastName,
            };
        });
    const [doctorId, setDoctorId] = useState("");
    const [doctorDetail, setDoctorDetail] = useState([]);

    const handleDoctorChange = async (e) => {
        setDoctorId(e.value);
        document.getElementById("doctorRow").style.display = "none";

        await axios
            .get(`${baseURL}/${PROFILE_API}/${e.value}`, {
                headers: { authorization: token },
            })
            .then((res) => {
                setDoctorDetail(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });

        if (e.value == 0) {
            setDoctorId("");
            document.getElementById("doctorRow").style.display = "block";
        } else {
            document.getElementById("button").style.display = "block";
            document.getElementById("doctorRow").style.display = "none";
        }
    };

    const handleBack = (e) => {
        setDoctorSelect("");
        fetchDoctorList();
        setDoctorId("");
        document.getElementById("doctorRow").style.display = "block";
        document.getElementById("button").style.display = "none";
    };

    const handleCancel = () => {
        navigate("/assignDoctor");
    };

    const addByDoctorList = async (e) => {
        setIsLoading(true);
        await axios
            .post(`${baseURL}/${REQUEST_DATA_DOCTOR_ADD}`, {
                headers: { authorization: token },
                doctorId: doctorId,
                officeId: officeId,
            })
            .then((res) => {
                setIsLoading(false);
                if (res.status == 200) {
                    setDoctorSelect("");
                    setDoctorId("");
                    fetchDoctorList();
                    document.getElementById("doctorRow").style.display = "block";
                    toast.success(res.data.message);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        fetchDoctorList();
        payerList();
        specialtyData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{ADD_DOCTOR}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li>
                                    <i className="fa fa-user-md"></i>&nbsp;
                                    <NavLink className="parent-item" to="/assignDoctor">
                                        {ASSIGN_DOCTOR}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{ADD_DOCTOR}</li>
                            </ol>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="card card-box">
                                <div className="card-head">
                                    <header>{DOCTOR_INFORMATION}</header>
                                </div>
                                <div className="card-body" id="bar-parent">
                                    <div action="#" id="form_sample_1" className="form-horizontal">
                                        <div className="form-body">
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    Select Doctor
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <Select
                                                        name="doctorSelect"
                                                        value={doctorSelectOptions && doctorSelectOptions.filter(({ value }) => value == doctorId)}
                                                        options={doctorSelectOptions}
                                                        onChange={handleDoctorChange}
                                                        placeholder={SELECT_DOCTOR}
                                                    ></Select>

                                                    {doctorId == 0 ? (
                                                        ""
                                                    ) : (
                                                        <div className="form-group mt-5" id="button">
                                                            <div id="button">
                                                                <div className="card">
                                                                    <div className="card-head">
                                                                        <header>{DOCTOR_DETAIL}</header>
                                                                    </div>
                                                                    <div className="card-body no-padding height-9">
                                                                        <ul className="performance-list">
                                                                            <li>
                                                                                <a>
                                                                                    <i className="fa fa-circle-o" style={{ color: "#F39C12" }}></i>
                                                                                    {DOCTOR_NAME}
                                                                                    <span className="pull-right">
                                                                                        {doctorDetail.firstName} {doctorDetail.lastName}
                                                                                    </span>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a>
                                                                                    <i className="fa fa-circle-o" style={{ color: "#DD4B39" }}></i>
                                                                                    {DOCTOR_CODE}
                                                                                    <span className="pull-right">{doctorDetail.code}</span>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a>
                                                                                    <i className="fa fa-circle-o" style={{ color: "#00A65A" }}></i>
                                                                                    {DOCTOR_SPECIALTY}
                                                                                    <span className="pull-right">
                                                                                        {" "}
                                                                                        {doctorDetail.specialtyname}
                                                                                    </span>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                                <div style={{ float: "right" }}>
                                                                    {isLoading ? (
                                                                        <>
                                                                            <Button className="btn btn-info btn-lg m-r-1 mx-2" disabled>
                                                                                {ADD}
                                                                            </Button>
                                                                            <i className="fa fa-spinner fa-spin fa-2x loaderBtnSpinner"></i>
                                                                        </>
                                                                    ) : (
                                                                        <Button className="btn btn-info btn-lg m-r-1 mx-2" onClick={addByDoctorList}>
                                                                            {ADD}
                                                                        </Button>
                                                                    )}
                                                                    <Button className="btn btn-lg m-r-1" onClick={handleBack}>
                                                                        {BACK}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="doctorRow">
                                    <div className="form-group row">
                                        <label className="control-label col-md-3"></label>
                                        <div className="col-md-5">
                                            <div className="text-center">{OR}</div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div id="form_sample_1" className="form-horizontal">
                                            <div className="card-body" id="bar-parent">
                                                <div action="#" id="form_sample_1" className="form-horizontal">
                                                    <div className="form-body">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {FIRST_NAME}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    type="text"
                                                                    name="firstName"
                                                                    value={doctor && doctor.firstName}
                                                                    onChange={handleChange}
                                                                    data-required="1"
                                                                    placeholder="Enter first name"
                                                                    className="form-control input-height"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {LAST_NAME}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    type="text"
                                                                    name="lastName"
                                                                    value={doctor && doctor.lastName}
                                                                    onChange={handleChange}
                                                                    data-required="1"
                                                                    placeholder="Enter last name"
                                                                    className="form-control input-height"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {GENDER}
                                                                {/* <span className="required"> * </span> */}
                                                            </label>
                                                            <div className="col-md-5">
                                                                <select className="form-control input-height" name="gender" value={doctor && doctor.gender} onChange={handleChange}>
                                                                    <option value=""> {SELECT_GENDER}</option>
                                                                    <option value="male">{MALE}</option>
                                                                    <option value="female">{FEMALE}</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">{BIRTH_DATE}</label>
                                                            <div className="form-group col-5">
                                                                <DatePicker
                                                                    className="form-control input-height mr-sm-2"
                                                                    selected={doctor.dob}
                                                                    placeholderText={"Please Select Date"}
                                                                    maxDate={new Date(minYear)}
                                                                    scrollableMonthDropdown={true}
                                                                    showYearDropdown
                                                                    showMonthDropdown
                                                                    onChange={(date) => setDoctor({ ...doctor, dob: date })}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {PHONE_NO}
                                                                {/* <span className="required"> * </span> */}
                                                            </label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    name="phoneNo1"
                                                                    type="number"
                                                                    value={doctor && doctor.phoneNo1}
                                                                    minLength={10}
                                                                    maxLength={10}
                                                                    onChange={handleChange}
                                                                    placeholder="mobile number"
                                                                    className="form-control input-height"
                                                                />{" "}
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {NPI1}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    name="npi1"
                                                                    type="text"
                                                                    value={doctor && doctor.npi1}
                                                                    onChange={handleChange}
                                                                    placeholder="Enter NPI 1"
                                                                    className="form-control input-height"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {NPI2}
                                                                {/* <span className="required"> * </span> */}
                                                            </label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    name="npi2"
                                                                    type="text"
                                                                    value={doctor && doctor.npi2}
                                                                    onChange={handleChange}
                                                                    placeholder="Enter NPI 2"
                                                                    className="form-control input-height"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {SSN}
                                                                {/* <span className="required"> * </span> */}
                                                            </label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    name="ssn"
                                                                    type="text"
                                                                    value={doctor && doctor.ssn}
                                                                    onChange={handleChange}
                                                                    placeholder="Enter SSN"
                                                                    className="form-control input-height"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {TAXID}
                                                                {/* <span className="required"> * </span> */}
                                                            </label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    name="taxId"
                                                                    type="text"
                                                                    value={doctor && doctor.taxId}
                                                                    onChange={handleChange}
                                                                    placeholder="Enter TaxId"
                                                                    className="form-control input-height"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {LICENSE_NUMBER}
                                                                {/* <span className="required"> * </span> */}
                                                            </label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    name="licenseNumber"
                                                                    type="text"
                                                                    value={doctor && doctor.licenseNumber}
                                                                    onChange={handleChange}
                                                                    placeholder="Enter License Number"
                                                                    className="form-control input-height"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {PTAN_NUMBER}
                                                                {/* <span className="required"> * </span> */}
                                                            </label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    name="ptanNumber"
                                                                    type="text"
                                                                    value={doctor && doctor.ptanNumber}
                                                                    onChange={handleChange}
                                                                    placeholder="Enter PTAN Number"
                                                                    className="form-control input-height"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {DOCTOR_SPECIALTY}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <Select
                                                                    menuPlacement="top"
                                                                    name="specialty"
                                                                    options={specialtyOptions}
                                                                    onChange={handleSpecialtyChange}
                                                                    placeholder={SELECT_SPECIALTY}
                                                                />
                                                                {/* <select className="form-control input-height" name="speciality" value={doctor && doctor.speciality} onChange={handleChange}>
                                                                    <option value=""> {SELECT_SPECIALTY}</option>
                                                                    {Object.keys(CHOOSE_DOCTOR_TYPE).map((item, i) => {
                                                                        return (
                                                                            <option key={i} value={CHOOSE_DOCTOR_TYPE[item]}>
                                                                                {item}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select> */}
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {PAYER}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <Select
                                                                    menuPlacement="top"
                                                                    className="text-dark"
                                                                    name="payer"
                                                                    defaultValue={{ label: "CA Medicare South" }}
                                                                    options={payerOptions}
                                                                    onChange={handlePayerChange}
                                                                    placeholder={SELECT_PAYER}
                                                                ></Select>
                                                            </div>
                                                        </div>
                                                        <div className="form-actions">
                                                            <div className="row">
                                                                <div className="offset-md-3 col-md-9">
                                                                    {isLoading ? (
                                                                        <>
                                                                            <button className="btn btn-info m-r-20" disabled>
                                                                                {ADD}
                                                                            </button>
                                                                            <i className="fa fa-spinner fa-spin fa-2x loaderBtnSpinner"></i>
                                                                        </>
                                                                    ) : (
                                                                        <button className="btn btn-info m-r-20" onClick={handleSubmit}>
                                                                            {ADD}
                                                                        </button>
                                                                    )}

                                                                    <button type="reset" className="btn btn-default m-r-20" onClick={handleCancel}>
                                                                        {CANCEL}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DoctorAddDoctor;
