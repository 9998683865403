import axios from "axios";
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../../Config";
import { ADD_OP_NOTE_API } from "../../../Helper/ApiPath";
import { CLEAR, CANCEL, DASH, PROCEDURE_TYPE, SAVE, PREVIEW } from "../../../Helper/Constants";
import { dateFormateMonthDateYear, opNoteTime } from "../../../Helper/Helper";
import SignaturePad from "react-signature-canvas";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ENTER_SIGNATURE } from "../../../Helper/Messages";

function OpNotesText(props) {
    let navigate = useNavigate();
    let proc21026 =
        props.proc &&
        props.proc.filter(function (result) {
            return result.code === "21026" && result.type === PROCEDURE_TYPE.UPPER_JAW;
        });
    let proc21210 =
        props.proc &&
        props.proc.filter(function (result) {
            return result.code === "21210" && result.type === PROCEDURE_TYPE.UPPER_JAW;
        });
    let proc21210Left =
        props.proc &&
        props.proc.filter(function (result) {
            return result.code === "21210 Left" && result.type === PROCEDURE_TYPE.UPPER_JAW;
        });
    let proc21210Right =
        props.proc &&
        props.proc.filter(function (result) {
            return result.code === "21210 Right" && result.type === PROCEDURE_TYPE.UPPER_JAW;
        });
    let proc21025 =
        props.proc &&
        props.proc.filter(function (result) {
            return result.code === "21025" && result.type === PROCEDURE_TYPE.LOWER_JAW;
        });

    let proc21215 =
        props.proc &&
        props.proc.filter(function (result) {
            return result.code === "21215" && result.type === PROCEDURE_TYPE.LOWER_JAW;
        });
    let proc21215Left =
        props.proc &&
        props.proc.filter(function (result) {
            return result.code === "21215 Left" && result.type === PROCEDURE_TYPE.LOWER_JAW;
        });
    let proc21215Right =
        props.proc &&
        props.proc.filter(function (result) {
            return result.code === "21215 Right" && result.type === PROCEDURE_TYPE.LOWER_JAW;
        });
    let proc21209 =
        props.proc &&
        props.proc.filter(function (result) {
            return result.code === "21209" && result.type === PROCEDURE_TYPE.UPPER_JAW;
        });
    let procLJ21209 =
        props.proc &&
        props.proc.filter(function (result) {
            return result.code === "21209" && result.type === PROCEDURE_TYPE.LOWER_JAW;
        });
    let proc21248 =
        props.proc &&
        props.proc.filter(function (result) {
            return result.code === "21248" && result.type === PROCEDURE_TYPE.UPPER_JAW;
        });
    let procLJ21248 =
        props.proc &&
        props.proc.filter(function (result) {
            return result.code === "21248" && result.type === PROCEDURE_TYPE.LOWER_JAW;
        });
    let proc21249 =
        props.proc &&
        props.proc.filter(function (result) {
            return result.code === "21249" && result.type === PROCEDURE_TYPE.UPPER_JAW;
        });
    let procLJ21249 =
        props.proc &&
        props.proc.filter(function (result) {
            return result.code === "21249" && result.type === PROCEDURE_TYPE.LOWER_JAW;
        });
    let proc40840 =
        props.proc &&
        props.proc.filter(function (result) {
            return result.code === "40840" && result.type === PROCEDURE_TYPE.UPPER_JAW;
        });
    let procLJ40840 =
        props.proc &&
        props.proc.filter(function (result) {
            return result.code === "40840" && result.type === PROCEDURE_TYPE.LOWER_JAW;
        });
    let proc40842 =
        props.proc &&
        props.proc.filter(function (result) {
            return result.code === "40842" && result.type === PROCEDURE_TYPE.UPPER_JAW;
        });
    let procLJ40842 =
        props.proc &&
        props.proc.filter(function (result) {
            return result.code === "40842" && result.type === PROCEDURE_TYPE.LOWER_JAW;
        });
    let proc40843 =
        props.proc &&
        props.proc.filter(function (result) {
            return result.code === "40843" && result.type === PROCEDURE_TYPE.UPPER_JAW;
        });
    let procLJ40843 =
        props.proc &&
        props.proc.filter(function (result) {
            return result.code === "40843" && result.type === PROCEDURE_TYPE.LOWER_JAW;
        });
    let proc40844 =
        props.proc &&
        props.proc.filter(function (result) {
            return result.code === "40844" && result.type === PROCEDURE_TYPE.UPPER_JAW;
        });
    let procLJ40844 =
        props.proc &&
        props.proc.filter(function (result) {
            return result.code === "40844" && result.type === PROCEDURE_TYPE.LOWER_JAW;
        });
    let proc41830 =
        props.proc &&
        props.proc.filter(function (result) {
            return result.code === "41830" && result.type === PROCEDURE_TYPE.UPPER_JAW;
        });
    let procLJ41830 =
        props.proc &&
        props.proc.filter(function (result) {
            return result.code === "41830" && result.type === PROCEDURE_TYPE.LOWER_JAW;
        });

    let fullMouth =
        props.proc &&
        props.proc.filter(function (result) {
            return result.code === "70355 Full Mouth" || result.code === "70486 Full Mouth" || result.code === "76376 Full Mouth";
        });

    let maxilla;
    let mandible;
    let radiographicEvaluation;
    if (fullMouth.length === 0) {
        maxilla =
            props.proc &&
            props.proc.filter(function (result) {
                return result.code === "70355 Maxilla" || result.code === "70486 Maxilla" || result.code === "76376 Maxilla";
            });

        mandible =
            props.proc &&
            props.proc.filter(function (result) {
                return result.code === "70355 Mandible" || result.code === "70486 Mandible" || result.code === "76376 Mandible";
            });

        if (maxilla.length === 0 && mandible.length === 0) {
            radiographicEvaluation =
                props.proc &&
                props.proc.filter(function (result) {
                    return result.code === "70355" || result.code === "70486" || result.code === "76376";
                });
        }
    }

    let ProcedureStr = "";

    let token = JSON.parse(localStorage.getItem("logToken"));
    let claimId = localStorage.getItem("claimId");

    const sUpper21026_1 = props.excision.canine_eminende2 && props.excision.canine_eminende2 !== "" ? props.excision.canine_eminende2 + " mm distal to the Canine eminence " : " ";
    const sUpper21026_2 = props.excision.anterior_nasal_spine2 && props.excision.anterior_nasal_spine2 !== "" ? props.excision.anterior_nasal_spine2 + " mm distal the anterior nasal spine" : "";

    const sLower21025_1 = props.LJexcision.LJcanine_eminende2 && props.LJexcision.LJcanine_eminende2 !== "" ? props.LJexcision.LJcanine_eminende2 + " mm distal to the Canine eminence " : " ";
    const sLower21025_2 =
        props.LJexcision.LJanterior_nasal_spine2 && props.LJexcision.LJanterior_nasal_spine2 !== "" ? props.LJexcision.LJanterior_nasal_spine2 + " mm distal the anterior nasal spine" : "";

    // Removed Description of Procedure, Plan, and Indications for the Procedure section and P9020 Code as a request.
    const note = `
      <b> Insurance Company: </b>
                    ${props.claimDetail.payer.name ? props.claimDetail.payer.name : DASH}
                    <br />
                    <b> Patient Name:</b> ${props.claimDetail.patient.firstName} ${props.claimDetail.patient.lastName}
                    <br />
                    <b> DOB: </b> ${dateFormateMonthDateYear(props.claimDetail.patient.dob)}
                    <br />
                    <b> Insurance ID:</b> ${props.claimDetail.patient.MedicarePatientId ? props.claimDetail.patient.MedicarePatientId : DASH}
                    <br />
                    <b> Date of Service:</b> ${props.claimDetail.dateOfService ? dateFormateMonthDateYear(props.claimDetail.dateOfService) : DASH}
                    <br />
                    <b> Surgeon: </b> ${props.claimDetail.doctor.firstName} ${props.claimDetail.doctor.lastName}
                    <br />
                    <b> License Number:</b> ${props.claimDetail.doctor.licenseNumber ? props.claimDetail.doctor.licenseNumber : DASH}
                    <br />
                    <b> NPI Number:</b> ${props.claimDetail.doctor.npi1 ? props.claimDetail.doctor.npi1 : DASH} <br />
                    <br />

                    <b>Patient reported on (${props.claimDetail.patient.gender}) health history that (${props.claimDetail.patient.gender}) suffers from:</b> <br />
                    (In Consult/ROS). <br />
                    <br />

                    ${radiographicEvaluation && radiographicEvaluation.length > 0 ? `<b> Radiographic Evaluation:</b> <br />A review of the Orthopantogram reveals the following information: <br />Advanced bone resorption in the Maxilla with sinus expansion. Cyst and infection <br />is present along with significant bone loss in the Maxilla. <br /><br />` : ""}
                    
                    ${maxilla && maxilla.length > 0 ? `<b> Radiographic Evaluation:</b> <br />A review of the Orthopantogram reveals the following information: <br />Advanced bone resorption in the Maxilla with sinus expansion. Cyst and infection <br />is present along with significant bone loss in the Maxilla. <br /><br />` : ""}

                    ${mandible && mandible.length > 0 ? `<b> Radiographic Evaluation:</b> <br />A review of the Orthopantogram reveals the following information: <br />Advanced bone resorption in the Mandible with sinus expansion. Cyst and infection <br />is present along with significant bone loss in the Mandible. <br /><br />` : ""}
                        
                    ${fullMouth && fullMouth.length > 0 ? `<b> Radiographic Evaluation:</b> <br />A review of the Orthopantogram reveals the following information: <br />
                    Advanced bone resorption in the Maxilla and Mandible with sinus expansion. Cysts and infection <br />
                    are present along with significant bone loss in the Maxilla and Mandible.<br />
                    <br />` : ""}

                    <b> Preoperative Diagnoses:</b> <br />
                   ${props.code.length ? props.code : DASH} <br />
                    <br />

                    <b> Postoperative Diagnoses:</b> <br />
                    The patient was dismissed in stable condition with interim obturator prosthesis and post-op instructions given. Diagnosis is favorable with patient cooperation. <br />
                    <br />

                    <b> Procedure performed:</b>
                    <br />
                   ${ProcedureStr}
                    <br />

                    <b>Procedure in Detail:</b> <br />
                    The patient was brought to the operating room and placed supine on the table. (Quantity and type of Drugs administered). An Oxygen cannula was placed, with N2O. 
                    The patient was then draped and prepped in the usual manner. A timeout was completed with the surgical staff to review the proposed procedure and ascertain 
                    the appropriate x-rays and CT scans are ready for viewing for the correct patient. <br /> 
                    BP: (${props.bp}) HR: (${props.hr}) <br />
                    <br />
                    Local anesthesia was then administered.<br />
                    <br />

                    ${props.procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && (props.articaine.articaine || props.articaine.left || props.articaine.right || props.articaine.both)
            ? ` (${props.articaine.articaine}) carpules Septocaine 4% with 1:100,000 epi, throughout the entire procedure in the (${props.articaine.left ? "left" : props.articaine.right ? "right" : props.articaine.both ? "left/right" : ""
            }) (${props.articaine.asa ? "ASA" : props.articaine.psa ? "PSA" : props.articaine.msa ? "MSA" : ""})
                              and palatal foramen nerve block. <br />
                              `
            : ""
        }

                    ${props.procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && (props.bupivacaine.bupivacaine || props.bupivacaine.left || props.bupivacaine.right || props.bupivacaine.both)
            ? ` 
                            <br />
                            (${props.bupivacaine.bupivacaine}) carpules Marcaine 0.5% 1:200,000 epi were administered throughout the entire procedure in the (${props.bupivacaine.left ? "left" : props.bupivacaine.right ? "right" : props.bupivacaine.both ? "left/right" : ""
            }) (${props.bupivacaine.asa ? "ASA" : props.bupivacaine.psa ? "PSA" : props.bupivacaine.msa ? "MSA" : ""})
                              and palatal foramen nerve block. <br />
                              `
            : ""
        }

                    ${props.procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && (props.lidicaine.lidicaine || props.lidicaine.left || props.lidicaine.right || props.lidicaine.both)
            ? ` 
                            <br />
                            (${props.lidicaine.lidicaine}) carpules Lidocaine 2% with 1:100,000 epi, throughout the entire procedure in the (${props.lidicaine.left ? "left" : props.lidicaine.right ? "right" : props.lidicaine.both ? "left/right" : ""
            }) (${props.lidicaine.asa ? "ASA" : props.lidicaine.psa ? "PSA" : props.lidicaine.msa ? "MSA" : ""})
                              and palatal foramen nerve block. <br />
                              `
            : ""
        }

                    ${props.procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && (props.lidicaine2.lidicaine2 || props.lidicaine2.left || props.lidicaine2.right || props.lidicaine2.both)
            ? ` 
                            <br />
                            (${props.lidicaine2.lidicaine2}) carpules lidocaine 2% 1:50:000 throughout the entire procedure in the (${props.lidicaine2.left ? "left" : props.lidicaine2.right ? "right" : props.lidicaine2.both ? "left/right" : ""
            }) (${props.lidicaine2.asa ? "ASA" : props.lidicaine2.psa ? "PSA" : props.lidicaine2.msa ? "MSA" : ""})
                              and palatal foramen nerve block. <br />
                              `
            : ""
        }

                    ${props.procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && (props.mepivacaine.mepivacaine || props.mepivacaine.left || props.mepivacaine.right || props.mepivacaine.both)
            ? ` 
                            <br />
                            (${props.mepivacaine.mepivacaine}) carpules Carbocaine no epi,  throughout the entire procedure in the (${props.mepivacaine.left ? "left" : props.mepivacaine.right ? "right" : props.mepivacaine.both ? "left/right" : ""
            }) (${props.mepivacaine.asa ? "ASA" : props.mepivacaine.psa ? "PSA" : props.mepivacaine.msa ? "MSA" : ""})
                               and palatal foramen nerve block. <br />
                        <br /> `
            : ""
        }

                    ${props.procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && (props.LJarticaine.LJarticaine || props.LJarticaine.LJleft || props.LJarticaine.LJright || props.LJarticaine.LJboth)
            ? ` (${props.LJarticaine.LJarticaine}) carpules Septocaine 4% with 1:100,000 epi, (${props.LJarticaine.LJleft ? "left" : props.LJarticaine.LJright ? "right" : props.LJarticaine.LJboth ? "left/right" : ""
            }) side Inferior alveolar nerve block, Buccal, Mental and Lingual infiltrations, throughout the entire procedure. <br />
                              `
            : ""
        }

                    ${props.procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) &&
            (props.LJbupivacaine.LJbupivacaine || props.LJbupivacaine.LJleft || props.LJbupivacaine.LJright || props.LJbupivacaine.LJboth)
            ? `
                            <br />
                            (${props.LJbupivacaine.LJbupivacaine}) carpules Marcaine 0.5% 1:200,000 epi, (${props.LJbupivacaine.LJleft ? "left" : props.LJbupivacaine.LJright ? "right" : props.LJbupivacaine.LJboth ? "left/right" : ""
            }) side Inferior alveolar nerve block, Buccal, Mental and Lingual infiltrations, throughout the entire procedure. <br />
                             `
            : ""
        }

                    ${props.procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && (props.LJlidicaine.LJlidicaine || props.LJlidicaine.LJleft || props.LJlidicaine.LJright || props.LJlidicaine.LJboth)
            ? ` 
                            <br />
                             (${props.LJlidicaine.LJlidicaine}) carpules Lidocaine 2% with 1:100,000 epi, (${props.LJlidicaine.LJleft ? "left" : props.LJlidicaine.LJright ? "right" : props.LJlidicaine.LJboth ? "left/right" : ""
            }) side Inferior alveolar nerve block, Buccal, Mental and Lingual infiltrations, throughout the entire procedure. <br />
                              `
            : ""
        }

                    ${props.procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) &&
            (props.LJlidicaine2.LJlidicaine2 || props.LJlidicaine2.LJleft || props.LJlidicaine2.LJright || props.LJlidicaine2.LJboth)
            ? `
                            <br />
                            (${props.LJlidicaine2.LJlidicaine2}) carpules lidocaine 2% 1:50:000 epi, (${props.LJlidicaine2.LJleft ? "left" : props.LJlidicaine2.LJright ? "right" : props.LJlidicaine2.LJboth ? "left/right" : ""
            }) side Inferior alveolar nerve block, Buccal, Mental and Lingual infiltrations, throughout the entire procedure. <br />
                             `
            : ""
        }

                    ${props.procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) &&
            (props.LJmepivacaine.LJmepivacaine || props.LJmepivacaine.LJleft || props.LJmepivacaine.LJright || props.LJmepivacaine.LJboth)
            ? `
                            <br />
                            (${props.LJmepivacaine.LJmepivacaine}) carpules Carbocaine no epi, (${props.LJmepivacaine.LJleft ? "left" : props.LJmepivacaine.LJright ? "right" : props.LJmepivacaine.LJboth ? "left/right" : ""
            }) side Inferior alveolar nerve block, Buccal, Mental and Lingual infiltrations, throughout the entire procedure. <br />
                        <br /> `
            : ""
        }

                    <b>Procedures:</b> <br /><br />                                      
    ${props.procedure.includes("10120")
            ? `                              
        <b>10120</b> incision of a benign lesion in the oral cavity, such as a fibroma or a mucocele, 0.5 cm or smaller in size and located on the soft tissue of the oral cavity.
        <br /><br />`
            : ""
        }
    ${props.procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && props.procedure.includes("21198")
            ? `                              
        <b>21198</b> surgical procedure that involves cutting and repositioning a segment of the mandible (lower jaw bone) to correct jaw deformities misalignments that cannot be corrected with orthodontics alone.
        <br /><br />`
            : ""
        }
                                                 
    ${props.procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && props.procedure.includes("31030")
            ? `                              
        <b>31030</b> Maxillary Sinusotomy
        <br />During repair of the oroantral fistula, sinusotomy was completed on (${props.flap.left ? "left" : props.flap.right ? "right" : props.flap.both ? "left/right" : ""}) side. 
        Opened maxillary antrum and allowed for drainage of the sinus. Irrigated sinus with sterile saline solution.
        <br /><br />`
            : ""
        }
    ${props.procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && props.procedure.includes("21026")
            ? `   
            <b>21026</b> Maxilla Removal of the cyst with bone. Units (${proc21026[0] && proc21026[0].unit}), Pathology site maxillary (${props.excision.left ? "left" : props.excision.right ? "right" : props.excision.both ? "left/right" : ""
            })
            <br />In the body of the maxilla,
            (${props.excision.canine_eminende && props.excision.canine_eminende !== "" ? props.excision.canine_eminende + " mm distal to the Canine eminence " : " "} ${props.excision.anterior_nasal_spine && props.excision.anterior_nasal_spine !== "" ? props.excision.anterior_nasal_spine + " mm distal the anterior nasal spine" : ""
            }),
            there was a bony defect with a cyst noted. Using a molt curette, a small amount of soft tissue with bone consistent with granulated tissue was removed from the maxilla at the site of the defect.
            ${proc21026[0] && proc21026[0].unit === 2
                ? "<br />In the body of the maxilla, (" +
                sUpper21026_1 +
                sUpper21026_2 +
                "), there was a bony defect with a cyst noted. Using a molt curette, a small amount of soft tissue with bone consistent with granulated tissue was removed from the maxilla at the site of the defect."
                : ""
            }
            <br />(${proc21026[0] && proc21026[0].unit}) units removed ${props.excision.pathology === "yes" ? `Pathology sent to ${props.excision.pathologyHospital} for biopsy ` : " "} ${props.excision.oral_com_fis === "yes" ? "Oralantral communication with fistula noted." : ""
            }
            <br /> <br />`
            : ""
        }

    ${
        // props.procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && props.procedure.includes("40840")
        proc40840.length > 0
            ? `   
                    <b>40840</b> Maxilla Vestibuloplasty; Blade ${props.vestibuloplasty40840.blade40840}, Length ${props.vestibuloplasty40840.length40840} cm. Anterior vestibulopathy. 
                    Using tissue pickups the previously prepared full tissue flap was reflected to visualize the underlying bone and periosteum. 
                    Using a ${props.vestibuloplasty40840.blade40840} blade an incision was completed at the base of the trapezoid full-thickness flap only into the periosteum. 
                    This allows for the stretch of the flap to accommodate primary closure over the site of cyst removal and bone grafting. <br />
                    <br />`
            : ""
        }
    ${
        // props.procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && props.procedure.includes("40840")
        procLJ40840.length > 0
            ? `   
                    <b>40840</b> Mandible Vestibuloplasty; Blade ${props.LJvestibuloplasty40840.LJblade40840}, Length ${props.LJvestibuloplasty40840.LJlength40840} cm. Anterior vestibulopathy.
                    Using tissue pickups the previously prepared full tissue flap was reflected to visualize the underlying bone and periosteum. 
                    Using a ${props.LJvestibuloplasty40840.LJblade40840} blade an incision was completed at the base of the trapezoid full-thickness flap only into the periosteum. 
                    This allows for the stretch of the flap to accommodate primary closure over the site of cyst removal and bone grafting. <br />
                    <br />`
            : ""
        }
    ${
        // props.procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && props.procedure.includes("40842")
        proc40842.length > 0
            ? `   
                    <b>40842</b> Maxilla Vestibuloplasty; Blade ${props.vestibuloplasty40842.blade40842}, Length ${props.vestibuloplasty40842.length40842} cm. Unilateral (right or left) vestibulopathy. 
                    Using tissue pickups the previously prepared full tissue flap was reflected to visualize the underlying bone and periosteum. 
                    Using a ${props.vestibuloplasty40842.blade40842} blade an incision was completed at the base of the trapezoid full-thickness flap only into the periosteum. 
                    This allows for the stretch of the flap to accommodate primary closure over the site of cyst removal and bone grafting. <br />
                    <br />`
            : ""
        }
    
    ${
        // props.procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && props.procedure.includes("40842")
        procLJ40842.length > 0
            ? `   
                    <b>40842</b> Mandible Vestibuloplasty; Blade ${props.LJvestibuloplasty40842.LJblade40842}, Length ${props.LJvestibuloplasty40842.LJlength40842} cm. Unilateral (right or left) vestibulopathy. 
                    Using tissue pickups the previously prepared full tissue flap was reflected to visualize the underlying bone and periosteum. 
                    Using a ${props.LJvestibuloplasty40842.LJblade40842} blade an incision was completed at the base of the trapezoid full-thickness flap only into the periosteum. 
                    This allows for the stretch of the flap to accommodate primary closure over the site of cyst removal and bone grafting. <br />
                    <br />`
            : ""
        }
    ${
        // props.procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && props.procedure.includes("40843")
        proc40843.length > 0
            ? `   
                    <b>40843</b> Maxilla Vestibuloplasty; Blade ${props.vestibuloplasty40843.blade40843}, Length ${props.vestibuloplasty40843.length40843} cm. Bilateral posterior vestibulopathy. 
                    Using tissue pickups the previously prepared full tissue flap was reflected to visualize the underlying bone and periosteum. 
                    Using a ${props.vestibuloplasty40843.blade40843} blade an incision was completed at the base of the trapezoid full-thickness flap only into the periosteum. 
                    This allows for the stretch of the flap to accommodate primary closure over the site of cyst removal and bone grafting. <br />
                    <br />`
            : ""
        }
    ${
        // props.procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && props.procedure.includes("40843")
        procLJ40843.length > 0
            ? `   
                    <b>40843</b> Mandible Vestibuloplasty; Blade ${props.LJvestibuloplasty40843.LJblade40843}, Length ${props.LJvestibuloplasty40843.LJlength40843} cm. Bilateral posterior vestibulopathy. 
                    Using tissue pickups the previously prepared full tissue flap was reflected to visualize the underlying bone and periosteum. 
                    Using a ${props.LJvestibuloplasty40843.LJblade40843} blade an incision was completed at the base of the trapezoid full-thickness flap only into the periosteum. 
                    This allows for the stretch of the flap to accommodate primary closure over the site of cyst removal and bone grafting. <br />
                    <br />`
            : ""
        }
    ${
        // props.procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && props.procedure.includes("40844")
        proc40844.length > 0
            ? `   
                        <b>40844</b> Maxilla Vestibuloplasty; Blade ${props.vestibuloplasty.blade}, Length ${props.vestibuloplasty.length} cm. Entire arch vestibulopathy. 
                        Using tissue pickups the previously prepared full tissue flap was reflected to visualize the underlying bone and periosteum. 
                        Using a ${props.vestibuloplasty.blade} blade an incision was completed at the base of the trapezoid full-thickness flap only into the periosteum. 
                        This allows for the stretch of the flap to accommodate primary closure over the site of cyst removal and bone grafting. <br />
                        <br />`
            : ""
        }
    ${
        // props.procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && props.procedure.includes("40844")
        procLJ40844.length > 0
            ? `   
                        <b>40844</b> Mandible Vestibuloplasty; Blade ${props.LJvestibuloplasty.LJblade}, Length ${props.LJvestibuloplasty.LJlength} cm. Entire arch vestibulopathy. 
                        Using tissue pickups the previously prepared full tissue flap was reflected to visualize the underlying bone and periosteum. 
                        Using a ${props.LJvestibuloplasty.LJblade} blade an incision was completed at the base of the trapezoid full-thickness flap only into the periosteum. 
                        This allows for the stretch of the flap to accommodate primary closure over the site of cyst removal and bone grafting. <br />
                        <br />`
            : ""
        }
    ${props.procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && props.procedure.includes("40845")
            ? `   
                        <b>40845</b> Maxilla Vestibuloplasty; Blade ${props.vestibuloplasty40845.blade40845}, Length ${props.vestibuloplasty40845.length40845} cm. 
                        Entire arch vestibulopathy All muscle attachments were previously released and incised from the bone. 
                        Using tissue pickups the previously prepared full tissue flap was reflected to visualize the underlying bone and periosteum. 
                        Using a ${props.vestibuloplasty40845.blade40845} blade an incision was completed at the base of the trapezoid full-thickness flap only into the periosteum. 
                        This allows for the stretch of the flap to accommodate primary closure over the site of cyst removal and bone grafting. <br />
                        <br />`
            : ""
        }
    ${
        // props.procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && props.procedure.includes("41830")
        proc41830.length > 0
            ? `   
                    <b>41830</b> Maxilla Alveolectomy Maxillary (${props.alveolectomy.left ? "left" : props.alveolectomy.right ? "right" : props.alveolectomy.both ? "left/right" : ""} )
                    <br />Alveolectomy of the (${props.alveolectomy.left ? "left" : props.alveolectomy.right ? "right" : props.alveolectomy.both ? "left/right" : ""} ) 
                    maxillary ridge was performed to source bone for the bone graft as well as create a more uniform bony architecture for reconstruction.
                    Manual palpation of the maxilla was completed with direct visualization, and there was no mobility or signs of non-union. 
                    Peripheral osteotomy along the buccal border of the maxilla was performed using a round diamond bur with normal saline irrigation. 
                    Bleeding points were observed from the bone consistent with healthy bone. <br />
                        <br />`
            : ""
        }
            
    ${
        // props.procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && props.procedure.includes("41830")
        procLJ41830.length > 0
            ? `   
                    <b>41830</b> Mandible Alveolectomy Mandible (${props.LJalveolectomy.left ? "left" : props.LJalveolectomy.right ? "right" : props.LJalveolectomy.both ? "left/right" : ""} ) 
                    <br />Alveolectomy of the (${props.LJalveolectomy.left ? "left" : props.LJalveolectomy.right ? "right" : props.LJalveolectomy.both ? "left/right" : ""} ) 
                    maxillary ridge was performed to source bone for the bone graft as well as create a more uniform bony architecture for reconstruction. 
                    Manual palpation of the maxilla was completed with direct visualization, and there was no mobility or signs of non-union. 
                    Peripheral osteotomy along the buccal border of the maxilla was performed using a round diamond bur with normal saline irrigation. 
                    Bleeding points were observed from the bone consistent with healthy bone. <br />
                        <br />`
            : ""
        }
        
    ${props.procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && props.procedure.includes("30580")
            ? ` 
                        <b>30580</b> Maxilla 'Oroantral Fistula' Lateral Sinus Lift (${props.sinus.left ? "left" : props.sinus.right ? "right" : props.sinus.both ? "left/right" : ""
            }) Maxilla due to large oral antral fistula. 
                        Verified existing alveolar antral artery on the lateral border of the maxillary sinus.
                        Created ${props.sinus.lateralWindow} mm window into the right maxillary sinus using an ultrasonic piezoelectric handpiece ${props.sinus.alveolarRidge
            } mm superior to the alveolar ridge ${props.sinus.canineEminence} mm distal to the canine eminence.
                        Verified intact maxillary ${props.sinus.left ? "left" : props.sinus.right ? "right" : props.sinus.both ? "left/right" : ""} Schneiderian membrane.
                        Released Schneidarian membrane from anterior, inferior, and lateral walls of sinus. Reverified intact Schneidarian membrane.
                        Placed Creos Xenoprotect Collagen Membrane and LPRF membrane superiorly against the Schneidarian membrane.
                        Verified intact Schneidarian membrane. Grafted with patient previously harvested bone, OsteoGen Plug and Creos allo.gain Corticocancellous cadaver bone graft.
                        Sinus lifted ${props.sinus.liftMembrane} mm. Placed ${props.sinus.lateralSinus} mm Creos Xenoprotect Collagen Membrane over lateral sinus access.
                        <br /><br />`
            : ""
        }
    ${props.procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && props.procedure.includes("21085")
            ? ` 
                        <b>21085</b> Maxilla= Surgical guide Mandibular. At this time, a previously designed and fabricated CT Surgical placement guide is placed and secured on the maxilla. 
                        As a three-dimensional surgical guide, the surgical splint was utilized to aid in bone stabilizer placement. Use of the guide allowed for more exact placement of 
                        titanium bone stabilizers, protection of the hard structures, shorter treatment period, and surgery times. <br />
                        <br />`
            : ""
        }
    ${
        // props.procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && props.procedure.includes("21248")
        proc21248.length > 0
            ? ` 
                        <b>21248</b> Maxilla= Implants partial arch (${props.partial} titanium bone stabilizer) After the development of the site Osteotomies, the titanium stabilizers are placed 
                        in the Maxilla without any complications. There was no apparent Schneiderian membrane contact or damage. The torque strength of (${props.ImplantPartial.ImplantManufacture}) 
                        (${props.ImplantPartial.ImplantSize}), (${props.ImplantPartial.ImplantDiameter}), and (${props.ImplantPartial.ImplantposteriorCanine} mm posterior canine eminence ), was noted to be (${props.ImplantPartial.ImplantNcm} for ) at the time of placement. 
                        The titanium stabilizers are deemed to be stable and solid. A surgical cover screw was placed over each implanted device and hand tightened. 
                        (${props.ImplantPartial.ImplantManufacture}), (${props.ImplantPartial.ImplantSize}) and (${props.ImplantPartial.ImplantDiameter}) is recorded in the patient’s office chart. <br />
                        <br />`
            : ""
        }
    ${
        // props.procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && props.procedure.includes("21248")
        procLJ21248.length > 0
            ? ` 
                        <b>21248</b> Mandible= Implants partial arch (${props.LJpartial} titanium bone stabilizer) After the development of the site Osteotomies, the titanium stabilizers are placed 
                        in the Mandible without any complications. There was no apparent Schneiderian membrane contact or damage. The torque strength of (${props.LJImplantPartial.LJImplantManufacture}) 
                        (${props.LJImplantPartial.LJImplantSize}), (${props.LJImplantPartial.LJImplantDiameter}), and (${props.LJImplantPartial.LJImplantposteriorCanine} mm posterior canine eminence ), was noted to be (${props.LJImplantPartial.LJImplantNcm} for ) at the time of placement. 
                        The titanium stabilizers are deemed to be stable and solid. A surgical cover screw was placed over each implanted device and hand tightened. 
                        (${props.LJImplantPartial.LJImplantManufacture}), (${props.LJImplantPartial.LJImplantSize}) and (${props.LJImplantPartial.LJImplantDiameter}) is recorded in the patient’s office chart. <br />
                        <br />`
            : ""
        }
    ${
        // props.procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && props.procedure.includes("21249")        
        proc21249.length > 0
            ? ` 
                        <b>21249</b> Maxilla= Implants full arch (${props.fullArch} titanium bone stabilizer) After the development of the site Osteotomies, the titanium stabilizers are placed 
                        in the Maxilla without any complications. There was no apparent Schneiderian membrane contact or damage. The torque strength of (${props.ImplantFullArch.FullArchManufacture}) 
                        (${props.ImplantFullArch.FullArchSize}), (${props.ImplantFullArch.FullArchDiameter}), and (${props.ImplantFullArch.FullArchposteriorCanine} mm posterior canine eminence ), was noted to be (${props.ImplantFullArch.FullArchNcm} for ) at the time of placement. 
                        The titanium stabilizers are deemed to be stable and solid. A surgical cover screw was placed over each implanted device and hand tightened. 
                        (${props.ImplantFullArch.FullArchManufacture}), (${props.ImplantFullArch.FullArchSize}) and (${props.ImplantFullArch.FullArchDiameter}) is recorded in the patient’s office chart. <br />
                        <br />`
            : ""
        }
    ${
        // props.procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && props.procedure.includes("21249")
        procLJ21249.length > 0
            ? ` 
                        <b>21249</b> Mandible= Implants full arch (${props.LJfullArch} titanium bone stabilizer) After the development of the site Osteotomies, the titanium stabilizers are placed 
                        in the Mandible without any complications. There was no apparent Schneiderian membrane contact or damage. The torque strength of (${props.LJImplantFullArch.LJFullArchManufacture}) 
                        (${props.LJImplantFullArch.LJFullArchSize}), (${props.LJImplantFullArch.LJFullArchDiameter}), and (${props.LJImplantFullArch.LJFullArchposteriorCanine} mm posterior canine eminence ), was noted to be (${props.LJImplantFullArch.LJFullArchNcm} for ) at the time of placement. 
                        The titanium stabilizers are deemed to be stable and solid. A surgical cover screw was placed over each implanted device and hand tightened. 
                        (${props.LJImplantFullArch.LJFullArchManufacture}), (${props.LJImplantFullArch.LJFullArchSize}) and (${props.LJImplantFullArch.LJFullArchDiameter}) is recorded in the patient’s office chart. <br />
                        <br />`
            : ""
        }
    ${props.procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && props.procedure.includes("21210")
            ? ` 
            <b>21210</b> Maxilla Bone Graft ${props.bone.left ? "left" : props.bone.right ? "right" : props.bone.both ? "left/right" : ""} Maxillary jaw. 
            ${proc21210[0] && proc21210[0].unit} units, ${props.bone.patientHarvst} cc patient's harvested bone, ${props.bone.boneGraftQuantity} cc, 
            ${props.bone.membraneMaterial}, ${props.bone.membraneUnit}, ${props.bone.membraneType} membranes size ${props.bone.membraneSize} mm.<br />
            After placement of the tenting screw stabilizers, an Onlay graft was placed using ${props.bone.patientHarvst} cc of patients previously harvested bone 
            and ${props.bone.boneGraftQuantity} cc of ${props.bone.membraneMaterial} saturated with LPRP liquid.
            This graft was secured and condensed into the bone where deformities exist and around the titanium bone stabilizer fixtures.
            An LPRP bio fibrin clot membrane was placed over the entire grafted surface.
            The collagen membrane was secured using a tucking method under the layers of periosteum and kept in place by previously completed vestibulopathy 
            and subperiosteal ${props.bone.typeSiture} sutures. Verified primary closure of the full tissue flap. 
            At this point, the wound was sutured with ${props.bone.closeSuture} sutures in an interrupted fashion to secure the primary closure of the wound site.
            Hemostasis of the wound is noted. Inspection of the entire site was concluded and determined all was correctly closed and no additional complications were noted or expected.
            There were no complications encountered throughout the surgery, all sponges and needles were counted and accounted for. <br />
            <br />`
            : ""
        }
    ${props.procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && props.procedure.includes("21210 Left")
            ? ` 
            <b>21210 Left</b> Maxilla Bone Graft ${props.boneLeft.left ? "left" : props.boneLeft.right ? "right" : props.boneLeft.both ? "left/right" : ""} Maxillary jaw. 
            ${proc21210Left[0] && proc21210Left[0].unit} units, ${props.boneLeft.patientHarvst} cc patient's harvested bone, ${props.boneLeft.boneGraftQuantity} cc, 
            ${props.boneLeft.membraneMaterial}, ${props.boneLeft.membraneUnit}, ${props.boneLeft.membraneType} membranes size ${props.boneLeft.membraneSize} mm.<br />
            After placement of the tenting screw stabilizers, an Onlay graft was placed using ${props.boneLeft.patientHarvst} cc of patients previously harvested bone 
            and ${props.boneLeft.boneGraftQuantity} cc of ${props.boneLeft.membraneMaterial} saturated with LPRP liquid.
            This graft was secured and condensed into the bone where deformities exist and around the titanium bone stabilizer fixtures.
            An LPRP bio fibrin clot membrane was placed over the entire grafted surface.
            The collagen membrane was secured using a tucking method under the layers of periosteum and kept in place by previously completed vestibulopathy 
            and subperiosteal ${props.boneLeft.typeSiture} sutures. Verified primary closure of the full tissue flap. 
            At this point, the wound was sutured with ${props.boneLeft.closeSuture} sutures in an interrupted fashion to secure the primary closure of the wound site.
            Hemostasis of the wound is noted. Inspection of the entire site was concluded and determined all was correctly closed and no additional complications were noted or expected.
            There were no complications encountered throughout the surgery, all sponges and needles were counted and accounted for. <br />
            <br />`
            : ""
        }
    ${props.procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && props.procedure.includes("21210 Right")
            ? ` 
            <b>21210 Right</b> Maxilla Bone Graft ${props.boneRight.left ? "left" : props.boneRight.right ? "right" : props.boneRight.both ? "left/right" : ""} Maxillary jaw. 
            ${proc21210Right[0] && proc21210Right[0].unit} units, ${props.boneRight.patientHarvst} cc patient's harvested bone, ${props.boneRight.boneGraftQuantity} cc, 
            ${props.boneRight.membraneMaterial}, ${props.boneRight.membraneUnit}, ${props.boneRight.membraneType} membranes size ${props.boneRight.membraneSize} mm.<br />
            After placement of the tenting screw stabilizers, an Onlay graft was placed using ${props.boneRight.patientHarvst} cc of patients previously harvested bone 
            and ${props.boneRight.boneGraftQuantity} cc of ${props.boneRight.membraneMaterial} saturated with LPRP liquid.
            This graft was secured and condensed into the bone where deformities exist and around the titanium bone stabilizer fixtures.
            An LPRP bio fibrin clot membrane was placed over the entire grafted surface.
            The collagen membrane was secured using a tucking method under the layers of periosteum and kept in place by previously completed vestibulopathy 
            and subperiosteal ${props.boneRight.typeSiture} sutures. Verified primary closure of the full tissue flap. 
            At this point, the wound was sutured with ${props.boneRight.closeSuture} sutures in an interrupted fashion to secure the primary closure of the wound site.
            Hemostasis of the wound is noted. Inspection of the entire site was concluded and determined all was correctly closed and no additional complications were noted or expected.
            There were no complications encountered throughout the surgery, all sponges and needles were counted and accounted for. <br />
            <br />`
            : ""
        }
    ${props.procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && props.procedure.includes("21127")
            ? ` 
                        <b>21127</b> Mandible= Augmentation of the jaw. Augmentation of the mandibular jaw was performed to help recreate the function of the mandible due to the loss and 
                        deformities of the bone as a result of atrophy and infection. Medical necessity required Augmentation was completed to provide support for a mandibular prosthesis to 
                        return the patient to normal functionality. Mandible was sectioned directly along the anterior ridge # cm in length. 2 vertical bone incisions were then completed at the 
                        ends for the main sectioning of the ridge # mm in depth. Ridge was then expanded #mm and a bone graft was placed between the Buccal and lingual of the sectioned ridge. <br />
                        <br />`
            : ""
        }
    ${props.procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && props.procedure.includes("21080")
            ? `     
        <b>21080</b> Maxilla= Maxillary Prosthesis. Once this surgical procedure is completed and the flap is secured and stable, the maxillary temporary removable prosthesis is 
        delivered using traditional methods of adjustment to create an anchor point for calcium deposition, as well as facilitate seating and prevent unnecessary pressure spots 
        over the surgical site. Custom mold was used to design and fabricate interim prosthesis and finally prepared in the office. An interim prosthesis was placed after 
        reconstructive surgery on the bilateral sides of the Maxillary arch. The interim prosthesis was fixed the same day the bone stabilizers were placed. A follow-up 
        appointment is made for the evaluation and for suture removal. Explanation of soft food dietary guidelines and all post-operative rules are explained to the parties and 
        a written document is also given to the patient and escort. The patent was allowed to sit up and regain their composure before the appropriate post-operative instructions 
        were explained and a written set of those post-op rules was handed to them. The surgical room staff again rechecked the patient vitals, noted them as appropriate, and 
        explained the process of discharge to the patient and to their escort. Instructions were given to return for the follow-up and suture removals as scheduled. <br />
        <br />`
            : ""
        }
    ${props.procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && props.procedure.includes("21025")
            ? `   
            <b>21025</b> Mandible Removal of the cyst with bone. Units (${proc21025[0] && proc21025[0].unit}), Pathology site mandibular (${props.LJexcision.left ? "left" : props.LJexcision.right ? "right" : props.LJexcision.both ? "left/right" : ""
            })
            <br />In the body of the mandible,
            (${props.LJexcision.LJcanine_eminende && props.LJexcision.LJcanine_eminende !== "" ? props.LJexcision.LJcanine_eminende + " mm distal to the Canine eminence " : " "} ${props.LJexcision.LJanterior_nasal_spine && props.LJexcision.LJanterior_nasal_spine !== "" ? props.LJexcision.LJanterior_nasal_spine + " mm distal the anterior nasal spine" : ""
            }),
            there was a bony defect with a cyst noted. Using a molt curette, a small amount of soft tissue with bone consistent with granulated tissue was removed from the mandible at the site of the defect.
            ${proc21025[0] && proc21025[0].unit === 2
                ? "<br />In the body of the mandible, (" +
                sLower21025_1 +
                sLower21025_2 +
                "), there was a bony defect with a cyst noted. Using a molt curette, a small amount of soft tissue with bone consistent with granulated tissue was removed from the mandible at the site of the defect."
                : ""
            }
            <br />(${proc21025[0] && proc21025[0].unit}) units removed ${props.LJexcision.LJpathology === "yes" ? `Pathology sent to ${props.LJexcision.LJpathologyHospital} for biopsy ` : " "} 
            <br /> <br />`
            : ""
        }
    ${props.procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && props.procedure.includes("41845")
            ? `
        CPT <b>41845</b> Mandible= Vestibuloplasty; Blade ${props.vestibuloplasty.blade}, Length (${props.vestibuloplasty.length}) cm. <br />
        All muscle attachments within the area of full-thickness flaps were previously released and incised from the bone. Using tissue pickups the previously prepared 
        full tissue flap was reflected to show the underlying bone and the periosteum. Using a #15 blade an incision was completed at the base of the trapezoid full-thickness 
        flap only into the periosteum as muscle attachments were already prepared. This allows for the stretch of the flap to accommodate primary closure over the site of 
        cyst removal and bone grafting. <br />
        <br />`
            : ""
        }
    ${props.procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && props.procedure.includes("21215")
            ? `
            <b>21215</b> Mandible Bone Graft right and left Mandibular jaw. ${proc21215[0] && proc21215[0].unit} units. 
            ${props.bone21215.patientHarvst21215} cc patient's harvested bone, ${props.bone21215.boneGraftQuantity21215} cc ${props.bone21215.membraneMaterial21215}, 
            ${props.bone21215.membraneUnit21215} ${props.bone21215.membraneType21215} membranes size ${props.bone21215.membraneSize21215} mm.
            After placement of the tenting screw stabilizers, an Onlay graft was placed using ${props.bone21215.patientHarvst21215} cc of patients 
            previously harvested bone and ${props.bone21215.boneGraftQuantity21215} cc of ${props.bone21215.membraneMaterial21215} saturated with LPRP liquid.
            This graft was secured and condensed into the bone where deformities exist and around the titanium bone stabilizer fixtures.
            An LPRP bio fibrin clot membrane was placed over the entire grafted surface.
            The collagen membrane was secured using a tucking method under the layers of periosteum and kept in place by previously completed vestibulopathy 
            and subperiosteal ${props.bone21215.typeSiture21215} sutures.
            Verified primary closure of the full tissue flap. At this point, the wound was sutured with ${props.bone21215.closeSuture21215} sutures 
            in an interrupted fashion to secure the primary closure of the wound site. Hemostasis of the wound is noted. 
            Inspection of the entire site was concluded and determined all was correctly closed and no additional complications were noted or expected. 
            There were no complications encountered throughout the surgery, all sponges and needles were counted and accounted for.
            <br />
            <br />`
            : ""
        }
    ${props.procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && props.procedure.includes("21215 Left")
            ? `
            <b>21215 Left</b> Mandible Bone Graft right and left Mandibular jaw. ${proc21215Left[0] && proc21215Left[0].unit} units. 
            ${props.bone21215Left.patientHarvst21215} cc patient's harvested bone, ${props.bone21215Left.boneGraftQuantity21215} cc ${props.bone21215Left.membraneMaterial21215}, 
            ${props.bone21215Left.membraneUnit21215} ${props.bone21215Left.membraneType21215} membranes size ${props.bone21215Left.membraneSize21215} mm.
            After placement of the tenting screw stabilizers, an Onlay graft was placed using ${props.bone21215Left.patientHarvst21215} cc of patients 
            previously harvested bone and ${props.bone21215Left.boneGraftQuantity21215} cc of ${props.bone21215Left.membraneMaterial21215} saturated with LPRP liquid.
            This graft was secured and condensed into the bone where deformities exist and around the titanium bone stabilizer fixtures.
            An LPRP bio fibrin clot membrane was placed over the entire grafted surface.
            The collagen membrane was secured using a tucking method under the layers of periosteum and kept in place by previously completed vestibulopathy 
            and subperiosteal ${props.bone21215Left.typeSiture21215} sutures.
            Verified primary closure of the full tissue flap. At this point, the wound was sutured with ${props.bone21215Left.closeSuture21215} sutures 
            in an interrupted fashion to secure the primary closure of the wound site. Hemostasis of the wound is noted. 
            Inspection of the entire site was concluded and determined all was correctly closed and no additional complications were noted or expected. 
            There were no complications encountered throughout the surgery, all sponges and needles were counted and accounted for.
            <br />
            <br />`
            : ""
        }
    ${props.procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && props.procedure.includes("21215 Right")
            ? `
            <b>21215 Right</b> Mandible Bone Graft right and left Mandibular jaw. ${proc21215Right[0] && proc21215Right[0].unit} units. 
            ${props.bone21215Right.patientHarvst21215} cc patient's harvested bone, ${props.bone21215Right.boneGraftQuantity21215} cc ${props.bone21215Right.membraneMaterial21215}, 
            ${props.bone21215Right.membraneUnit21215} ${props.bone21215Right.membraneType21215} membranes size ${props.bone21215Right.membraneSize21215} mm.
            After placement of the tenting screw stabilizers, an Onlay graft was placed using ${props.bone21215Right.patientHarvst21215} cc of patients 
            previously harvested bone and ${props.bone21215Right.boneGraftQuantity21215} cc of ${props.bone21215Right.membraneMaterial21215} saturated with LPRP liquid.
            This graft was secured and condensed into the bone where deformities exist and around the titanium bone stabilizer fixtures.
            An LPRP bio fibrin clot membrane was placed over the entire grafted surface.
            The collagen membrane was secured using a tucking method under the layers of periosteum and kept in place by previously completed vestibulopathy 
            and subperiosteal ${props.bone21215Right.typeSiture21215} sutures.
            Verified primary closure of the full tissue flap. At this point, the wound was sutured with ${props.bone21215Right.closeSuture21215} sutures 
            in an interrupted fashion to secure the primary closure of the wound site. Hemostasis of the wound is noted. 
            Inspection of the entire site was concluded and determined all was correctly closed and no additional complications were noted or expected. 
            There were no complications encountered throughout the surgery, all sponges and needles were counted and accounted for.
            <br />
            <br />`
            : ""
        }
    ${props.procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && props.procedure.includes("21079")
            ? `
            <b>21079</b> Mandible= Mandibular Prosthesis. <br />
            Once this surgical procedure is completed and the flap is secured and stable, the temporary removable prosthesis is delivered using traditional methods of adjustment to 
            create an anchor point for calcium deposition, as well as facilitate seating and prevent unnecessary pressure spots over the surgical site. Custom mold was used to design 
            and fabricate interim prosthesis. The interim prosthesis was finally prepared in the office to prepare for surgery. The interim prosthesis was placed after reconstructive 
            surgery of the Mandibular arch. (The interim prosthesis was fixed the same day the bone stabilizers were placed.) A follow-up appointment is made for the evaluation and 
            for suture removal. Explanation of soft food dietary guidelines and all post-operative rules are explained to the parties and a written document is also given to the 
            patient and escort. The patient was allowed to sit up and regain their composure before the appropriate post-operative instructions were explained and a written set of 
            those post-op rules was handed to them. The surgical room staff again rechecked the patient vitals, noted them as appropriate, and explained the process of discharge 
            to the patient and to their escort. Instructions were given to return for the follow-up and suture removals as scheduled. Estimated blood loss: (__) cc. <br />
            `
            : ""
        }
    ${
        // props.procedureType.includes(PROCEDURE_TYPE.UPPER_JAW) && props.procedure.includes("21209")
        proc21209.length > 0
            ? ` 
            <b>21209</b> Osteoplasty Maxillary ${props.osteoplasty.left ? "left" : props.osteoplasty.right ? "right" : props.osteoplasty.both ? "left/right" : ""} <br />
            Osteoplasty was performed on ${props.osteoplasty.buccal ? "BUCCAL" : props.osteoplasty.palatal ? "PALATAL" : props.osteoplasty.bucpal ? "BUCCAL AND PALATAL" : ""} cortical bone, 
            as well as creating a more uniform bony architecture for reconstruction. 
            Manual palpation of the maxilla was completed with direct visualization, and there was no mobility or signs of non-union. 
            Peripheral osteotomy along the buccal border of the maxilla was performed using a round diamond bur with normal saline irrigation. 
            Bleeding points were observed from the bone consistent with healthy bone. 
            ${proc21209[0] && proc21209[0].unit} Units. <br />
            <br />`
            : ""
        }
    ${
        // props.procedureType.includes(PROCEDURE_TYPE.LOWER_JAW) && props.procedure.includes("21209")
        procLJ21209.length > 0
            ? ` 
            <b>21209</b> Osteoplasty Mandible ${props.LJosteoplasty.left ? "left" : props.LJosteoplasty.right ? "right" : props.LJosteoplasty.both ? "left/right" : ""} <br />
            Osteoplasty was performed on ${props.LJosteoplasty.buccal ? "BUCCAL" : props.LJosteoplasty.lingual ? "LINGUAL" : props.LJosteoplasty.buclin ? "BUCCAL AND LINGUAL" : ""} cortical bone, 
            to harvest bone and  to create a more uniform bony architecture for reconstruction. 
            Manual palpation of the mandible was completed with direct visualization, and there was no mobility or signs of non-union. 
            Peripheral osteotomy along the buccal border of the mandible was performed using a round diamond bur with normal saline irrigation. 
            Bleeding points were observed from the bone consistent with healthy bone. 
            ${procLJ21209[0] && procLJ21209[0].unit} Units. <br />
            <br />`
            : ""
        }
 ${props.nitrous.nitrousAdmin !== "" || props.nitrous.nitrousDuration !== ""
            ? `
                    Patient was administered nitrous oxide during the procedure. Patient began with 100% oxygen and slowly titrated to
                    (${props.nitrous.nitrousAdmin}%) oxygen/${props.nitrous.nitrousAdmin}% nitrous oxide. Patient was on 50/50 nitrous oxide for
                    (${props.nitrous.nitrousDuration} minutes)  during the procedure. After the procedure was completed the patient was administered 100% oxygen. <br />
                    `
            : ""
        }
    `;

    const [saved, setSaved] = useState(false);
    const [editorValue, setEditorValue] = useState(note);
    const [contentsEditValue, setContentsEditValue] = useState("");
    const [imageURL, setImageURl] = useState(props.signatureImage ? props.signatureImage : null);
    const [editSign, setEditSign] = useState(false);
    const [isBase64Srt, setIsBase64Srt] = useState(false);
    const handleEditorChange = (e) => {
        setEditorValue(e);
        setContentsEditValue(e);
    };

    const sigCanvas = useRef({});
    const clear = () => {
        sigCanvas.current.clear();
        setImageURl(null);
    };

    const save = () => {
        setImageURl(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
        setEditSign(true);
        setIsBase64Srt(true);
    };

    const handleSave = async () => {
        if (!imageURL || imageURL === "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC") {
            toast.error(ENTER_SIGNATURE);
        } else {
            await axios
                .post(`${baseURL}/${ADD_OP_NOTE_API}`, {
                    headers: { authorization: token.token },
                    note: editorValue,
                    startTime: props.time.startTime,
                    endTime: props.time.endTime,
                    claimId,
                    signatureImage: imageURL,
                    isBase64Srt: isBase64Srt,
                    editSign: editSign,
                })
                .then((res) => {
                    if (res.status === 200) {
                        toast.success(res.data.message);
                        navigate("/manageOpNotes");
                        setSaved(true);
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status === 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const handleCancel = () => {
        navigate("/manageOpNotes");
    };
    return (
        <div className="col-12 col-12">
            {props.isLoading ? (
                <div className="form-body" style={{ height: "100px" }}>
                    <div id="text">
                        <i className="fa fa-spinner fa-spin fa-2x"></i>
                    </div>
                </div>
            ) : (
                <div className="card card-box">
                    <CKEditor
                        config={{
                            height: "130",
                            removePlugins: ["CKFinderUploadAdapter", "CKFinder", "EasyImage", "Image", "ImageCaption", "ImageStyle", "ImageToolbar", "ImageUpload", "MediaEmbed"],
                        }}
                        editor={ClassicEditor}
                        id="editor1"
                        onReady={(editor) => {
                            editor.setData(editorValue);
                        }}
                        onChange={(event, editor) => {
                            handleEditorChange(editor.getData());
                        }}
                    />

                    <div className="card-body" id="bar-parent">
                        <br />
                        <br />
                        <br />
                        <b>Dr. Name:</b> {props.claimDetail.doctor.firstName} {props.claimDetail.doctor.lastName}
                        <br />
                        <b>License Number:</b> {props.claimDetail.doctor.licenseNumber ? props.claimDetail.doctor.licenseNumber : DASH}
                        <br />
                        <b>Date:</b> {props.claimDetail.dateOfService ? dateFormateMonthDateYear(props.claimDetail.dateOfService) : DASH}
                        <br />
                        <b>Start Time:</b> {opNoteTime(props.time.startTime)} <span>&nbsp; &nbsp;</span> <b>End Time:</b> {opNoteTime(props.time.endTime)} <br />
                        <div>
                            <div className="">
                                <b className={`${imageURL ? "my-5" : ""}`}>Sign :</b>

                                {imageURL || props.signatureImage ? (
                                    <img
                                        className="mx-2 my-2"
                                        src={imageURL ? imageURL : props.signatureImage}
                                        alt="Sign"
                                        style={{
                                            display: "block",
                                            height: "max-content",
                                            width: "max-content",
                                        }}
                                    />
                                ) : null}
                            </div>
                            <div className="mx-2" style={{ border: "1px solid", width: "fit-content" }}>
                                <SignaturePad ref={sigCanvas} canvasProps={{ width: 300, height: 150, className: "signatureCanvas" }} penColor="blue" />
                            </div>
                            <button className="btn btn-outline-danger my-2 mx-2" onClick={clear} style={{ border: "1px solid" }}>
                                {CLEAR}
                            </button>
                            <button className="btn btn-outline-success my-2 mx-2" onClick={save} style={{ border: "1px solid" }}>
                                {PREVIEW}
                            </button>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center my-3">
                        {saved ? (
                            <></>
                        ) : (
                            <button className="btn btn-success mx-2" onClick={handleSave}>
                                {SAVE}
                            </button>
                        )}
                        <button className="btn btn-default mx-2" onClick={handleCancel}>
                            {CANCEL}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default OpNotesText;
