import { useState } from "react";
import {
    CANCEL,
    HOME,
    RECEIPT_NO,
    RECEIPT_AMOUNT_USD,
    RECEIPT_DATE,
    OFFICE_NAME,
    SELECT_OFFICE,
    ADD_RECEIPT,
    RECEIPT_LIST,
    RECEIPT_INFORMATION,
    NO,
    FILE_NAME,
    THUMBNAIL,
    ACTION,
    RECEIPT_FILE,
    SAVE_NEXT,
    FINISH,
} from "../../../Helper/Constants";
import axios from "axios";
import { baseURL } from "../../../Config";
import { toast } from "react-toastify";
import { RECEIPT_ADD_API, RECEIPT_OFFICE_SELECT_LIST_API, RECEIPT_DOCUMENT_DELETE_API, RECEIPT_DOCUMENT_LIST_API } from "../../../Helper/ApiPath";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { CHOOSE_OFFICE, DELETE_RECEIPT_DOCUMENT_MESSAGE,
    ENTER_RECEIPT_NO,
    ENTER_RECEIPT_DATE,
    ENTER_RECEIPT_AMOUNT } from "../../../Helper/Messages";
import { dateAPIFormate } from "../../../Helper/Helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Zoom from "react-medium-image-zoom";
import NoDataFound from "../../CommonComponent/NoDataFound";
import ReceiptFileUpload from "./ReceiptFileUpload";
import Swal from "sweetalert2";
import ReceiptWebcamUpload from "./ReceiptWebcamUpload";

function AddReceipt() {
    const token = JSON.parse(localStorage.getItem("logToken"));
    const navigate = useNavigate();

    const [receiptId, setReceiptId] = useState("");
    const [officeSelect, setOfficeSelect] = useState([]);
    const [receiptFile, setReceiptFile] = useState([]);
    const [officeName, setOfficeName] = useState("");
    const [edit, setEdit] = useState(false);
    const [show, setShow] = useState(false);
    const [editId, setEditId] = useState("");
    const [receipt, setReceipt] = useState({
        officeId: "",
        officeLabel: "",
        receiptNo: "",
        receiptDate: "",
        receiptAmount: "",
    });

    const fetchOfficeList = async (e) => {
        await axios
            .post(`${baseURL}/${RECEIPT_OFFICE_SELECT_LIST_API}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                setOfficeSelect(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const officeSelectOptions =
        officeSelect &&
        officeSelect.map((item, i) => {
            return {
                value: item.uniqueId,
                label: item.name,
            };
        });

    const fetchThumbnailData = async () => {
        await axios
            .post(`${baseURL}/${RECEIPT_DOCUMENT_LIST_API}`, {
                headers: { authorization: token.token },
                receiptId,
            })
            .then((res) => {
                setReceiptFile(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const handleEdit = (id) => {
        setEdit(true);
        setShow(true);
        setEditId(id);
    };
    const closeDocumentEdit = () => {
        setShow(false);
        setEdit(false);
    };
    const handleDelete = async (id) => {
        Swal.fire({
            title: DELETE_RECEIPT_DOCUMENT_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/${RECEIPT_DOCUMENT_DELETE_API}/${id}`, {
                            headers: { authorization: token.token },
                        })
                        .then((res) => {
                            Swal.fire(res.data.message, " ", "success");
                            fetchThumbnailData();
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!receipt.officeId) {
            toast.error(CHOOSE_OFFICE);
        } else if (!receipt.receiptNo) {
            toast.error(ENTER_RECEIPT_NO);
        } else if (!receipt.receiptDate) {
            toast.error(ENTER_RECEIPT_DATE);
        } else if (!receipt.receiptAmount) {
            toast.error(ENTER_RECEIPT_AMOUNT);
        } else {
            await axios
                .post(`${baseURL}/${RECEIPT_ADD_API}`, {
                    headers: { authorization: token.token },
                    officeId: receipt.officeId,
                    receiptNo: receipt.receiptNo,
                    receiptDate: dateAPIFormate(receipt.receiptDate),
                    receiptAmount: receipt.receiptAmount,
                })
                .then((res) => {
                    toast.success(res.data.message);
                    setReceiptId(res.data.data.id);
                    localStorage.setItem("receiptId", res.data.data.id);
                    document.getElementById("receipt-image").classList.remove("d-none");
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setReceipt({ ...receipt, [name]: value });
    };
    const handleOfficeChange = async (e) => {
        setOfficeName(e.value);
        setReceipt({ ...receipt, officeId: e.value, officeLabel: e.label });
    };
    const handleCancel = (e) => {
        navigate("/manageReceipt");
    };

    useEffect(() => {
        fetchOfficeList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{ADD_RECEIPT}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li>
                                    <i className="fa fa-th-list" aria-hidden="true"></i>&nbsp;
                                    <NavLink className="parent-item" to="/manageReceipt">
                                        {RECEIPT_LIST}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{RECEIPT_LIST}</li>
                            </ol>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="card card-box">
                                <div className="card-head">
                                    <header>{RECEIPT_INFORMATION}</header>
                                </div>

                                <div>
                                    <div className="row">
                                        <div id="form_sample_1" className="form-horizontal">
                                            <div className="card-body" id="bar-parent">
                                                <div action="#" id="form_sample_1" className="form-horizontal">
                                                    <div className="form-body">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {OFFICE_NAME}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <Select
                                                                    name="officeSelect"
                                                                    value={officeSelectOptions && officeSelectOptions.filter(({ value }) => value == officeName)}
                                                                    options={officeSelectOptions}
                                                                    onChange={handleOfficeChange}
                                                                    placeholder={SELECT_OFFICE}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">{RECEIPT_NO}<span className="required"> * </span></label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    type="text"
                                                                    name="receiptNo"
                                                                    value={receipt && receipt.receiptNo}
                                                                    onChange={handleChange}
                                                                    maxLength={100}
                                                                    data-required="1"
                                                                    placeholder="Enter Receipt #"
                                                                    className="form-control input-height"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">{RECEIPT_DATE}<span className="required"> * </span></label>
                                                            <div className="form-group col-5">
                                                                <DatePicker
                                                                    className="form-control input-height mr-sm-2"
                                                                    selected={receipt.receiptDate}
                                                                    maxDate={new Date().setMonth(new Date().getMonth() + 1)}
                                                                    scrollableMonthDropdown={true}
                                                                    showYearDropdown
                                                                    showMonthDropdown
                                                                    placeholderText={"Please Select Date"}
                                                                    onChange={(date) => setReceipt({ ...receipt, receiptDate: date })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">{RECEIPT_AMOUNT_USD}<span className="required"> * </span></label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    type="number"
                                                                    name="receiptAmount"
                                                                    value={receipt && receipt.receiptAmount}
                                                                    onChange={handleChange}
                                                                    data-required="1"
                                                                    placeholder="Enter BWS FEE"
                                                                    className="form-control input-height"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-actions">
                                                            <div className="row">
                                                                <div className="offset-md-3 col-md-9">
                                                                    <button className="btn btn-info m-r-20" onClick={handleSubmit}>
                                                                        {SAVE_NEXT}
                                                                    </button>
                                                                    <button type="reset" className="btn btn-default  m-r-20" onClick={handleCancel}>
                                                                        {CANCEL}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-none" id="receipt-image">
                                    <div className="col-xl-3 col-lg-3"></div>
                                    <div className="col-xl-6 col-lg-6 col-md-11 col-sm-11 mx-md-2 mx-sm-2">
                                        <div className="d-flex justify-content-between my-2 mx-2">
                                            <div>
                                                <p style={{ fontSize: "Larger", fontWeight: "600" }}>{RECEIPT_FILE}</p>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <ReceiptFileUpload
                                                        fetchThumbnailData={fetchThumbnailData}
                                                        closeDocumentEdit={closeDocumentEdit}
                                                        edit={edit}
                                                        editId={editId}
                                                        show={show}
                                                        localReceiptId={receiptId}
                                                    />
                                                </div>
                                                <div style={{ marginTop: "10px" }}>
                                                    <ReceiptWebcamUpload fetchThumbnailData={fetchThumbnailData} localReceiptId={receiptId} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-scrollable">
                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                <thead>
                                                    <tr>
                                                        <th>{NO}</th>
                                                        <th>{FILE_NAME}</th>
                                                        <th>{THUMBNAIL}</th>
                                                        <th>{ACTION}</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {!receiptFile.length || !receiptFile ? (
                                                        <>{<NoDataFound />}</>
                                                    ) : (
                                                        receiptFile &&
                                                        receiptFile.map((item, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{(1 - 1) * 100 + i + 1}</td>
                                                                    <td>{item.fileName ? item.fileName : RECEIPT_FILE + ".png"}</td>

                                                                    <td>
                                                                        {item.document && item.document.split(".").at(-1).toLowerCase() == "pdf" ? (
                                                                            <div type="button" className="btn btn-outline-danger" onClick={() => window.open(item.document, "_blank")}>
                                                                                <i className="fa  fa-file-pdf-o fa-2x my-2"></i>
                                                                            </div>
                                                                        ) : (
                                                                            <Zoom>
                                                                                <img src={item.document} width="100" height="100" alt="Receipt Document" style={{ height: "50px", width: "50px" }} />
                                                                            </Zoom>
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        <button type="button" className="btn btn-primary btn-xs" onClick={() => handleEdit(item.id)}>
                                                                            <i className="fa  fa-pencil"></i>
                                                                        </button>
                                                                        <button type="button" className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
                                                                            <i className="fa  fa-trash"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="form-actions">
                                            <div className="row">
                                                <div className="offset-md-5 col-md-9">
                                                    <button className="btn btn-success m-r-20" onClick={handleCancel}>
                                                        {FINISH}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddReceipt;
