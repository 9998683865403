import React from "react";
import { Modal } from "react-bootstrap";
import { ADD, CANCEL, NAME, ROLE, ROLE_ADD, ROLE_SELECT, ROLE_STATUS, ROLE_UPDATE, STATUS, UPDATE } from "../../../Helper/Constants";

function AddRoles(props) {
    return (
        <>
            <Modal show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.edit ? ROLE_UPDATE : ROLE_ADD}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row" id="role">
                        <div className="form-group row">
                            <label className="control-label col-md-12">
                                {NAME} <span className="required"> * </span>
                            </label>

                            <div className="col-md-12">
                                <input type="text" className="form-control" name="name" value={props.role.name} onChange={props.handleChange} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-md-12">
                                {ROLE} <span className="required"> * </span>
                            </label>
                            <div className="col-md-12">
                                <select className="form-control col-md-3" name="parentId" value={props.role.parentId} onChange={props.handleChange} disabled={`${props.edit ? "disabled" : ""}`}>
                                    {Object.keys(ROLE_SELECT).map((item, i) => {
                                        return (
                                            <option key={i} value={ROLE_SELECT[item]}>
                                                {item}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="control-label col-md-12">
                                {STATUS} <span className="required"> * </span>
                            </label>
                            <div className="col-md-12">
                                <select className="form-control col-md-3" name="statusId" value={props.role.statusId} onChange={props.handleChange}>
                                    {Object.keys(ROLE_STATUS).map((item, i) => {
                                        return (
                                            <option key={i} value={ROLE_STATUS[item]}>
                                                {item}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        <div id="roleAdd" className="my-3 text-end">
                            <button className="btn btn-info mx-2" onClick={props.handleRoleAdd}>
                                {props.edit ? UPDATE : ADD}
                            </button>
                            <button className="btn btn-default" onClick={props.handleClose}>
                                {CANCEL}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddRoles;
