import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  ACTION,
  CANCEL,
  DOCUMENTS,
  DOCUMENT_TYPE,
  FILE_NAME,
  NO,
  PATIENT_DOCUMENTS,
  SELECT,
  THUMBNAIL,
  CREATED_DATE,
  PATIENT_DOCUMENT_TYPE,
} from "../../../Helper/Constants";
import Select from "react-select";
import NoDataFoundPop from "../../CommonComponent/NoDataFoundPop";
import Zoom from "react-medium-image-zoom";
import { dateFormateMonthDateYear } from "../../../Helper/Helper";
import UploadImage from "../Patients/UploadImage";
import { DELETE_IMAGE_MESSAGE } from "../../../Helper/Messages";
import { DELETE_PATIENT_DOCUMENT } from "../../../Helper/ApiPath";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../Config";
import { toast } from "react-toastify";
import WebcamUpload from "../Patients/WebcamUpload";

function DownloadPreAuthorization(props) {
  let navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("logToken"));
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [popUpTitle, setPopUpTitle] = useState("");
  const [documentId, setDocumentId] = useState("");
  const [imageType, setImageType] = useState("");
  let [image, setImage] = useState({ preview: "", data: "" });
  const [webcame, setWebcame] = useState(false);

  const handleClick = (title, type) => {
    setShow(true);
    setPopUpTitle(title);
    setImageType(type);
  };

  const handleEdit = (title, id, type) => {
    setShow(true);
    setEdit(true);
    setPopUpTitle(title);
    setDocumentId(id);
    setImageType(type);
  };

  const handleClose = () => {
    setShow(false);
    setEdit(false);
  };

  const handleWebcameClick = (title, type) => {
    setWebcame(true);
    setPopUpTitle(title);
    setImageType(type);
  };

  const handleWebcameClose = () => {
    setWebcame(false);
  };

  const handleImageChange = (e) => {
    const data = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
      name: e.target.files[0].name,
    };
    const fileExtension = data.data.name.split(".").at(-1).toLowerCase();
    let allowedFileTypes =
      imageType === PATIENT_DOCUMENT_TYPE.NOTE ||
      imageType === PATIENT_DOCUMENT_TYPE.INSURANCE_FRONT ||
      imageType === PATIENT_DOCUMENT_TYPE.INSURANCE_BACK ||
      imageType === PATIENT_DOCUMENT_TYPE.X_RAY ||
      imageType === PATIENT_DOCUMENT_TYPE.ID
        ? ["pdf", "jpg", "png", "gif", "jpeg", "pdf"]
        : ["jpg", "png", "gif", "jpeg", "pdf"];
    if (!allowedFileTypes.includes(fileExtension)) {
      e.target.value = null;
      alert(
        `File does not support. Files type must be ${allowedFileTypes.join(
          ", "
        )}`
      );
    } else {
      let element = document.getElementById("button");
      element.classList.remove("d-none");
      setImage(data);
    }
  };

  const handleDelete = (id, type, patientId) => {
    Swal.fire({
      title: DELETE_IMAGE_MESSAGE,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (result.isConfirmed) {
          await axios
            .delete(`${baseURL}/${DELETE_PATIENT_DOCUMENT}/${id}`, {
              headers: { authorization: token.token },
            })
            .then((res) => {
              Swal.fire(res.data.message, " ", "success");
              props.fetchThumbnailData(type, patientId);
            })
            .catch((err) => {
              if (err.response) {
                if (err.response.status == 500) {
                  localStorage.clear();
                  navigate("/login");
                } else {
                  toast.error(err.response.data.message);
                }
              }
            });
        }
      }
    });
  };

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{PATIENT_DOCUMENTS}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="row ">
            <label className="col-4 my-2">{DOCUMENT_TYPE}</label>
            <Select
              name="name"
              className="col-8"
              value={
                props.documentOptions &&
                props.documentOptions.filter(
                  ({ value }) => value == props.documentType
                )
              }
              options={props.documentOptions}
              onChange={props.handleDocumentChange}
              placeholder={SELECT}
            ></Select>
          </div>
          <div className="col-12 mt-5 d-none" id="downloadDocuments">
            <div className="d-flex justify-content-end">
              <div>
                <button
                  className="btn btn-info mx-2"
                  style={{ borderRadius: "50%", width: "35px", height: "35px" }}
                  onClick={() =>
                    handleClick(props.documentName, props.documentType)
                  }
                >
                  <i className="fa fa-plus" style={{ marginLeft: "-1px" }}></i>{" "}
                </button>
              </div>
              <div style={{ marginTop: "10px" }}>
                <button
                  onClick={() =>
                    handleWebcameClick(props.documentName, props.documentType)
                  }
                >
                  {" "}
                  <i className="fa fa-camera " style={{ fontSize: "30px" }}></i>
                </button>
              </div>
            </div>
            <div className="table-scrollable">
              <table
                className="table table-hover table-checkable order-column full-width"
                id="example4"
              >
                <thead>
                  <tr>
                    <th>{NO}</th>
                    <th>{FILE_NAME}</th>
                    <th>{THUMBNAIL}</th>
                    <th>{CREATED_DATE}</th>
                    <th>{ACTION}</th>
                  </tr>
                </thead>
                <UploadImage
                  fetchData={props.fetchData}
                  statusId={props.statusId}
                  preAuthId={props.preAuthId}
                  isPreAuthorization={true}
                  popUpTitle={popUpTitle}
                  show={show}
                  handleClose={handleClose}
                  patientId={props.patientId}
                  imageType={imageType}
                  edit={edit}
                  documentId={documentId}
                  handleImageChange={handleImageChange}
                  image={image}
                  fetchThumbnailData={() =>
                    props.fetchThumbnailData(imageType, props.patientId)
                  }
                />
                <WebcamUpload
                  fetchData={props.fetchData}
                  statusId={props.statusId}
                  preAuthId={props.preAuthId}
                  isPreAuthorization={true}
                  popUpTitle={popUpTitle}
                  webcame={webcame}
                  handleWebcameClose={handleWebcameClose}
                  patientId={props.patientId}
                  imageType={imageType}
                  fetchThumbnailData={() =>
                    props.fetchThumbnailData(imageType, props.patientId)
                  }
                />
                <tbody>
                  {!props.documents ? (
                    <>{<NoDataFoundPop />}</>
                  ) : (
                    props.documents &&
                    props.documents.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{(1 - 1) * 100 + i + 1}</td>
                          <td>
                            {item.fileName ? item.fileName : DOCUMENTS + ".png"}
                          </td>
                          <td>
                            {item.image.split(".").at(-1).toLowerCase() ==
                            "pdf" ? (
                              <button
                                type="button"
                                className="btn btn-outline-danger"
                                onClick={() =>
                                  window.open(item.image, "_blank")
                                }
                              >
                                <i className="fa  fa-file-pdf-o fa-2x"></i>
                              </button>
                            ) : (
                              <Zoom>
                                <img
                                  src={item.image}
                                  width="100"
                                  height="100"
                                  alt="Patient Document"
                                  style={{ height: "50px", width: "50px" }}
                                />
                              </Zoom>
                            )}{" "}
                          </td>
                          <td>
                            {item.created_at
                              ? dateFormateMonthDateYear(item.created_at)
                              : "-"}
                          </td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-primary btn-xs"
                              onClick={() =>
                                handleEdit(item.fileName, item.id, item.type)
                              }
                            >
                              <i className="fa  fa-pencil"></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-warning btn-xs"
                              onClick={() => window.open(item.image, "_blank")}
                            >
                              <i className="fa  fa-eye"></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-success btn-xs"
                              onClick={() =>
                                props.handleDownload(item.image, item.fileName)
                              }
                            >
                              <i className="fa  fa-download"></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger btn-xs"
                              onClick={() =>
                                handleDelete(
                                  item.id,
                                  item.type,
                                  props.patientId
                                )
                              }
                            >
                              <i className="fa  fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={props.handleClose}>
            {CANCEL}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DownloadPreAuthorization;
