import moment from "moment";

export const dateFormate = (date) => {
    if (date) {
        return moment(date).format("DD/MM/YYYY");
    } else {
        return "";
    }
};

export const dateFormateMonthDateYear = (date) => {
    if (date) {
        return moment(date).format("MM/DD/YYYY");
    } else {
        return "";
    }
};

export const dateAPIFormate = (date) => {
    if (date) {
        return moment(date).format("YYYY-MM-DD");
    } else {
        return "";
    }
};

export const dateTimeFormate = (date) => {
    if (date) {
        return moment(date).format("MM/DD/YYYY LT");
    } else {
        return "";
    }
};

export const min_date = moment().subtract(20, "years");
export const minYear = moment(min_date).format("YYYY-MM-DD");
export const now = moment().toDate();
export const nowYear = moment(now).format("MM-DD-YYYY");

export const claim24hrDate = (date) => {
    if (date) {
        return moment(date).add(24, "hours").format("YYYYMMDD");
    } else {
        return "";
    }
};

export const claim12hrDateTime = (date) => {
    if (date) {
        return moment(date).add(12, "hours").format("YYYYMMDDHHmmss");
    } else {
        return "";
    }
};

export const currentDateFormate = (date) => {
    if (date) {
        return moment(date).format("YYYYMMDDHHmmss");
    } else {
        return "";
    }
};

export const unix = (date) => {
    if (date) {
        return moment(date).unix();
    } else {
        return "";
    }
};

export const opNoteTime = (time) => {
    if (time) {
        return moment(time, "hh:mm").format("LT");
    } else {
        return "";
    }
};

export const defaultTimeZone = (time) => {
    if (time) {
        return moment(time, moment.defaultFormat).toDate();
    } else {
        return "";
    }
};
