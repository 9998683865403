import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CHECK_PASS_LINK, SET_RESET_PASS } from "../../Helper/ApiPath";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { baseURL } from "../../Config";
import { useParams } from "react-router-dom";

function PasswordVerification() {
    const navigate = useNavigate();
    const { link } = useParams();
    const [status, setStatus] = useState("");
    const [password, setPassword] = useState("");
    const [cfmpassword, setCfmPassword] = useState("");
    const [passwordShown, setPasswordShown] = useState(false);
    const [cfmpasswordShown, setCfmpasswordShown] = useState(false);
    const [resetPassLoader, setResetPassLoader] = useState(false);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    const toggleCfmPassword = () => {
        setCfmpasswordShown(!cfmpasswordShown);
    };
    useEffect(() => {
        function checkLink() {
            axios
                .post(`${baseURL}/${CHECK_PASS_LINK}`, {
                    link: link,
                })
                .then(async (res) => {
                    setStatus(res.data.data.status ? "1" : "0");
                })
                .catch((err) => {
                    console.log("err", err);
                    setStatus("0");
                });
        }
        checkLink();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const backtologin = () => {
        navigate("/login");
    };
    const handleResetPass = () => {
        if (!resetPassLoader) {
            if (password == "") {
                toast.error("Enter your new password");
                return false;
            }
            const validPassword = new RegExp("^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
            if (!validPassword.test(password)) {
                toast.error("Password contain At least 8 characters : Uppercase, Lowercase, Numeric, or Special Characters.");
                return false;
            }
            if (password != cfmpassword) {
                toast.error("Password and confirm password should have same value");
                return false;
            }
            setResetPassLoader(true);
            axios
                .post(`${baseURL}/${SET_RESET_PASS}`, { password: password, link: link })
                .then((res) => {
                    setResetPassLoader(false);
                    if (res.status == 200) {
                        toast.success(res.data.message);
                        setPassword("");
                        setCfmPassword("");
                        setTimeout(() => {
                            navigate("/login");
                        }, [2000]);
                    }
                })
                .catch((err) => {
                    setResetPassLoader(false);
                    toast.error(err.response.data.message);
                });
        }
    };

    return (
        <>
            <div className="limiter">
                <div className="container-login100 page-background">
                    <div className="wrap-login100">
                        <div className="login100-form validate-form">
                            <span className="login100-form-logo">
                                <img alt="" src="/assets/img/hospital.png" />
                            </span>
                            <span className="login100-form-title p-t-27 pb-3 font-22">Reset Password</span>
                            {status == "0" ? (
                                <div className="bg-white p-2 text-center b-radius">
                                    <span className="expire-link">
                                        <i className="fa fa-warning"></i>
                                        <span className="p-2">Your password verification link has been expired or invalid.</span>
                                    </span>
                                </div>
                            ) : (
                                <>
                                    <div className=" p-2">
                                        <div className="form-group">
                                            <i className="fa fa-lock font-left textColor" aria-hidden="true"></i>
                                            <span className="textColor">New Password</span>
                                            <span className="requireField"> * </span>

                                            <div style={{ display: "flex" }}>
                                                <input
                                                    type={passwordShown ? "text" : "password"}
                                                    className="form-control"
                                                    id="password"
                                                    name="password"
                                                    placeholder="Enter Password.."
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                                <i className={passwordShown ? "input-group-text fa fa-eye" : "input-group-text fa fa-eye-slash"} id="basic-addon1" onClick={togglePassword}></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" p-2">
                                        <div className="form-group">
                                            <i className="fa fa-lock font-left textColor" aria-hidden="true"></i>
                                            <span className="textColor">Confirm Password</span>
                                            <span className="requireField"> * </span>

                                            <div style={{ display: "flex" }}>
                                                <input
                                                    type={cfmpasswordShown ? "text" : "password"}
                                                    className="form-control"
                                                    id="cfmpassword"
                                                    name="cfmpassword"
                                                    placeholder="Enter Confirm Password.."
                                                    value={cfmpassword}
                                                    onChange={(e) => setCfmPassword(e.target.value)}
                                                />
                                                <i className={cfmpasswordShown ? "input-group-text fa fa-eye" : "input-group-text fa fa-eye-slash"} id="basic-addon1" onClick={toggleCfmPassword}></i>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="container-login100-form-btn">
                                {status == "1" ? (
                                    <button className="login100-form-btn" onClick={() => handleResetPass()}>
                                        {" "}
                                        {resetPassLoader ? <i className="fa fa-spin m-1 spinner-border spinner-border-sm"></i> : ""} Reset Password
                                    </button>
                                ) : (
                                    <button className="mt-4 login100-form-btn" onClick={() => backtologin()}>
                                        Back to Login
                                    </button>
                                )}
                            </div>
                            {status == "1" ? (
                                <div className="text-center p-t-30">
                                    <Link className="txt1" to="/login">
                                        Back to Login
                                    </Link>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PasswordVerification;
