import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { ROLES } from "../../Helper/Constants";

function ProtectedComponent() {
    const auth = localStorage.getItem("DoctorToken");
    const doctorPracticeId = localStorage.getItem("doctorPracticeId");
    let step = JSON.parse(localStorage.getItem("step"));

    const cookies = new Cookies();

    let role = cookies.get("role");
    if (role) {
        role = cookies.get("role").roleId;
    }

    if (!auth) {
        return (
            <>
                <Navigate to="/register" /> <Navigate to="/login" />
            </>
        );
    } else if (!doctorPracticeId && role === ROLES.DOCTOR && step == 3) {
        return (
            <>
                <Navigate to="/chooseDoctor" />
            </>
        );
    } else {
        return <Outlet />;
    }
}

export default ProtectedComponent;
