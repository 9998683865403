import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../../../Config";
import { ELIGIBILITY_HISTORY_CHECK_LIST_API } from "../../../../Helper/ApiPath";
import {
    CANCEL,
    CHECK_DATE,
    CHECK_PERSON,
    CO_INSURANCE,
    DASH,
    DATE_OF_SERVICE,
    DEDUCTIBLE,
    DEDUCTIBLE_REMAINING,
    EFFECTIVE_DATE,
    ELIGIBILITY_HISTORY,
    NAME,
    NO,
    RESULT,
    ROWS_PER_PAGE,
} from "../../../../Helper/Constants";
import { dateFormateMonthDateYear } from "../../../../Helper/Helper";
import Loader from "../../../CommonComponent/Loader";

function EligibilityHistory() {
    let token = JSON.parse(localStorage.getItem("logToken"));
    let patientId = localStorage.getItem("patientId");
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [history, setHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(1);
    const [rowCount, setRowCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const fetchHistoryData = async () => {
        await axios
            .post(`${baseURL}/${ELIGIBILITY_HISTORY_CHECK_LIST_API}?isPagination=true`, {
                headers: { authorization: token.token },
                patientId,
                page: offset,
                rowsPerPage: ROWS_PER_PAGE,
            })
            .then((res) => {
                setHistory(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    useEffect(() => {
        if (show) {
            fetchHistoryData();
        }
    }, [show, offset]);

    return (
        <>
            <div className="pull-right" onClick={handleShow}>
                <button className="btn btn-success">{ELIGIBILITY_HISTORY}</button>
            </div>

            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>{ELIGIBILITY_HISTORY}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className="table table-hover table-checkable order-column">
                        <thead>
                            <tr>
                                <th>{NO}</th>
                                <th>{CHECK_PERSON}</th>
                                <th>{CHECK_DATE}</th>
                                <th>{DATE_OF_SERVICE}</th>
                                <th>{RESULT}</th>
                                <th>{NAME}</th>
                                <th>{EFFECTIVE_DATE}</th>
                                <th>{CO_INSURANCE}</th>
                                <th>{DEDUCTIBLE}</th>
                                <th>{DEDUCTIBLE_REMAINING}</th>
                            </tr>
                        </thead>
                        <tbody style={isLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                            {isLoading ? (
                                <>
                                    <Loader />
                                </>
                            ) : (
                                <>
                                    {!history || (history && !history.length) ? (
                                        <>
                                            {isLoading ? (
                                                ""
                                            ) : (
                                                <tr colSpan={10} className="text-center mt-5" style={{ color: "red" }}>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <img alt="" src="assets/img/NoDataFound/no_data_found.png" style={{ height: "200px", width: "200px" }} />
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            )}
                                        </>
                                    ) : (
                                        history &&
                                        history.map((item, i) => {
                                            return (
                                                <tr className="odd gradeX" key={i}>
                                                    <td className="left">{(offset - 1) * ROWS_PER_PAGE + i + 1}</td>
                                                    <td className="left">{item.checkBy ? item.checkBy : DASH}</td>
                                                    <td className="left">{item.created_at ? dateFormateMonthDateYear(item.created_at) : DASH}</td>
                                                    <td>{item.dateOfService ? dateFormateMonthDateYear(item.dateOfService) : DASH}</td>
                                                    <td className="left">{item.result ? item.result : DASH}</td>
                                                    <td className="left">{item.name ? item.name : DASH}</td>
                                                    <td className="left">{item.effectiveDate ? dateFormateMonthDateYear(item.effectiveDate) : DASH}</td>
                                                    <td className="left">{item.coInsurance ? item.coInsurance : 0}%</td>
                                                    <td>${item.deductible ? item.deductible : 0}</td>
                                                    <td>${item.deductibleRemaining ? item.deductibleRemaining : 0}</td>
                                                </tr>
                                            );
                                        })
                                    )}
                                </>
                            )}
                        </tbody>
                    </table>
                    <div className="d-flex justify-content-between mx-4">
                        <div>Total {history == null ? "0" : rowCount.toLocaleString("en-US")} history</div>
                        <div className="pagination-center">
                            <ReactPaginate
                                initialPage={0}
                                pageCount={pageCount}
                                marginPagesDisplayed={0}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName="pagination"
                                activeClassName="active"
                                pageLinkClassName="page-link"
                                breakLinkClassName="page-link"
                                nextLinkClassName="page-link"
                                previousLinkClassName="page-link"
                                pageClassName="page-item"
                                breakClassName="page-item"
                                nextClassName="page-item"
                                previousClassName="page-item"
                                previousLabel="Previous"
                                nextLabel="Next"
                                disableInitialCallback={true}
                                forcePage={currentPage}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-default" onClick={handleClose}>
                        {CANCEL}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default EligibilityHistory;
