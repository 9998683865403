import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { NavLink, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { baseURL, downLoadDynamicExcel, frontURL } from "../../../Config";
import {
    ADMIN_ADD_QUESTION_API,
    ADMIN_CREATE_DEMO_EXCEL_FILE,
    ADMIN_GROUP_LIST_API,
    ADMIN_QUESTION_DETAIL_API,
    ADMIN_QUESTION_LIST_API,
    ADMIN_TAB_LIST_API,
    DIAGNOSES_API,
    DIAGNOSES_FROM_QUESTION_LIST_API,
    DOWNLOAD_EXCEL_FILE,
} from "../../../Helper/ApiPath";
import {
    ADD,
    ALL,
    TABS_STATUS,
    HOME,
    NAME,
    STATUS,
    MANAGE_QUESTION,
    ACTION,
    NO,
    QUESTION_STATUS,
    ROWS_PER_PAGE,
    TAB_NAME,
    DASH,
    SORT_PRIORITY,
    FORM_TYPE,
    FORMTYPE_DROPDOWN,
    QUESTION_NAME,
    SELECT_TAB,
    SELECT_GROUP,
    GROUP_NAME,
    SELECT_DIAGNOSES,
    DIAGNOSES,
} from "../../../Helper/Constants";
import { DELETE_MESSAGE, ENTER_PRIORITY_LESS_THAN_MAX_PRIORITY, ENTER_QUESTION_NAME, ENTER_TAB_NAME, MAX_PRIORITY } from "../../../Helper/Messages";
import Loader from "../../CommonComponent/Loader";
import NoDataFound from "../../CommonComponent/NoDataFound";
import Add_EditDiagnoses from "./Add_EditDiagnoses";
import Add_EditQuestions from "./Add_EditQuestions";
import UploadExcelFile from "./UploadExcelFile";

function ManageQuestions() {
    const [isLoading, setIsLoading] = useState(false);
    const [questionsList, setQuestionList] = useState([]);
    const [offset, setOffset] = useState(1);
    const [rowCount, setRowCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [filterValues, setfilterValues] = useState({
        search: "",
        status: "",
        formType: "",
    });
    const [edit, setEdit] = useState(false);
    const [show, setShow] = useState(false);
    const [questionId, setQuestionId] = useState("");
    const [tabId, setTabId] = useState("");
    const [tabSelect, setTabSelect] = useState([]);
    const [groupSelect, setGroupSelect] = useState([]);
    const [addTabId, setAddTabId] = useState("");
    const [groupId, setGroupId] = useState("");
    const [diagnoses, setDiagnoses] = useState([]);
    const [diagnosesId, setDiagnosesId] = useState("");
    const [showdiag, setShowDiag] = useState(false);
    const [diagoffset, setDiagOffset] = useState(1);
    const [diagrowCount, setDiagRowCount] = useState(0);
    const [diagpageCount, setDiagPageCount] = useState(0);
    const [diagcurrentPage, setDiagCurrentPage] = useState(0);
    const [diagList, setDiagList] = useState([]);
    const [questions, setQuestions] = useState({
        name: "",
        formType: "1",
        tabName: "",
        priorityList: MAX_PRIORITY,
        description: "",
        statusId: "1",
    });

    const navigate = useNavigate();
    const token = JSON.parse(localStorage.getItem("logToken"));

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleFilterValues = (e) => {
        const { name, value } = e.target;
        setfilterValues({ ...filterValues, [name]: value });
        setOffset(1);
        setCurrentPage(0);
    };

    let tabList = [
        {
            id: "",
            name: SELECT_TAB,
        },
    ];
    const fetchTabList = async () => {
        await axios
            .post(`${baseURL}/${ADMIN_TAB_LIST_API}`, {
                headers: { authorization: token.token },
                isPagination: true,
            })
            .then((res) => {
                setTabSelect(res.data.data ? tabList.concat(res.data.data) : tabList);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const tabSelectOptions =
        tabSelect &&
        tabSelect.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const handleAddTabChange = (e) => {
        setAddTabId(e.value);
        fetchGroupList(e.value);
    };

    let groupList = [
        {
            id: "",
            name: SELECT_GROUP,
        },
    ];
    const fetchGroupList = async (tabId) => {
        await axios
            .post(`${baseURL}/${ADMIN_GROUP_LIST_API}`, {
                headers: { authorization: token.token },
                isPagination: true,
                tabId: tabId,
            })
            .then((res) => {
                setGroupSelect(res.data.data ? groupList.concat(res.data.data) : groupList);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const groupSelectOptions =
        groupSelect &&
        groupSelect.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const handleGroupChange = (e) => {
        setGroupId(e.value);
    };

    let diagnosesList = [
        {
            id: "",
            name: SELECT_DIAGNOSES,
        },
    ];
    const fetchDiagnosesList = async () => {
        await axios
            .post(`${baseURL}/${DIAGNOSES_API}`, {
                headers: { authorization: token.token },
                isNote: "",
            })
            .then((res) => {
                setDiagnoses(res.data.data ? diagnosesList.concat(res.data.data) : diagnosesList);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const diagnosesSelectOptions =
        diagnoses &&
        diagnoses.map((item, i) => {
            return {
                value: item.id,
                label: item.name === SELECT_DIAGNOSES ? item.name : item.name + " (" + item.code + ")",
            };
        });

    const handlediagnosesChange = (e) => {
        setDiagnosesId(e.value);
    };

    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${ADMIN_QUESTION_LIST_API}`, {
                headers: { authorization: token.token },
                page: offset,
                rowsPerPage: ROWS_PER_PAGE,
                statusId: filterValues.status,
                search: filterValues.search,
                formTypeId: filterValues.formType,
                tabId,
            })
            .then((res) => {
                setIsLoading(false);
                setQuestionList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleTabChange = async (e) => {
        setTabId(e.value);
        setOffset(1);
        setCurrentPage(0);
    };

    const handleAdd = () => {
        setShow(true);
        setEdit(false);
        fetchDiagnosesList();
    };

    const handleEdit = async (id, tabId) => {
        setEdit(true);
        setShow(true);
        setQuestionId(id);
        detailQuestions(id);
        fetchGroupList(tabId);
        fetchDiagnosesList();
    };

    const handleClose = () => {
        setShow(false);
        setQuestions({
            name: "",
            formType: "1",
            tabName: "",
            priorityList: MAX_PRIORITY,
            description: "",
            statusId: "1",
        });
        setAddTabId("");
        setGroupId("");
        setDiagnosesId("");
    };

    const handlechange = (e) => {
        let { name, value } = e.target;
        setQuestions({ ...questions, [name]: value });
    };

    const detailQuestions = async (id) => {
        await axios
            .get(`${baseURL}/${ADMIN_QUESTION_DETAIL_API}/${id}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                if (res.status === 200) {
                    setQuestions(res.data.data);
                    setAddTabId(res.data.data.patientChartTabId);
                    setGroupId(res.data.data.groupId);
                    setDiagnosesId(res.data.data.diagnosesId);
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const addQuestionData = async () => {
        if (!questions.name) {
            toast.error(ENTER_QUESTION_NAME);
        } else if (!addTabId) {
            toast.error(ENTER_TAB_NAME);
        } else if (questions.priorityList && questions.priorityList > MAX_PRIORITY) {
            toast.error(ENTER_PRIORITY_LESS_THAN_MAX_PRIORITY);
        } else {
            await axios
                .post(`${baseURL}/${ADMIN_ADD_QUESTION_API}`, {
                    headers: { authorization: token.token },
                    name: questions.name,
                    priorityList: questions.priorityList ? Number(questions.priorityList) : "",
                    statusId: questions.statusId,
                    tabId: addTabId,
                    formType: questions.formType,
                    description: questions.description,
                    questionId: edit ? questionId : "",
                    diagnosesId: diagnosesId,
                    groupId,
                })
                .then((res) => {
                    if (res.status === 200) {
                        toast.success(res.data.message);
                        setQuestions({
                            name: "",
                            formType: "1",
                            tabName: "",
                            priorityList: MAX_PRIORITY,
                            statusId: "1",
                        });
                        handleClose();
                        fetchData();
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const handleDownload = async () => {
        await axios
            .post(`${baseURL}/${ADMIN_CREATE_DEMO_EXCEL_FILE}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                if (res.status === 200) {
                    axios({
                        url: `${frontURL}/DownloadExcel/dynamicDemo.xlsx`, //your url
                        method: "GET",
                        responseType: "blob", // important
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", "demo-excel" + ".xlsx"); //or any other extension
                        link.click();
                        link.remove();
                        window.URL.revokeObjectURL(url);
                    });
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleDemoDownload = async () => {
        axios({
            url: `${frontURL}/${DOWNLOAD_EXCEL_FILE}`, //your url
            method: "GET",
            responseType: "blob", // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "demo-excel" + ".xlsx"); //or any other extension
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        });
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: DELETE_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/${ADMIN_QUESTION_DETAIL_API}/${id}`, {
                            headers: { authorization: token.token },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire(res.data.message, " ", "success");
                                fetchData();
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    const handleDiagnosesAdd = (id) => {
        setQuestionId(id);
        fetchDiagnosesList();
        fetchDiagnosesData(id);
        setShowDiag(true);
    };

    const handleDiagPageClick = (event) => {
        const selectedPage = event.selected;
        setDiagOffset(selectedPage + 1);
        setDiagCurrentPage(selectedPage);
    };

    const fetchDiagnosesData = async (id) => {
        await axios
            .post(`${baseURL}/${DIAGNOSES_FROM_QUESTION_LIST_API}`, {
                headers: { authorization: token.token },
                questionId: id,
                page: diagoffset,
                rowsPerPage: ROWS_PER_PAGE,
            })
            .then((res) => {
                setDiagList(res.data.data);
                setDiagRowCount(res.data.extraData.rowCount);
                setDiagPageCount(res.data.extraData.pageCount);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        if (showdiag) {
            fetchDiagnosesData();
        }
    }, [diagoffset]);

    useEffect(() => {
        fetchData();
        fetchTabList(tabId);
    }, [offset, filterValues, tabId]);

    return (
        <>
            {" "}
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{MANAGE_QUESTION}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{MANAGE_QUESTION}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tabbable-line">
                                <div className="tab-content">
                                    <div className="tab-pane active fontawesome-demo" id="tab1">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card" style={isLoading ? { height: "35rem" } : {}}>
                                                    <div className="card-head">
                                                        <header>{MANAGE_QUESTION}</header>
                                                    </div>
                                                    <div className="card-body ">
                                                        <div className="row d-flex">
                                                            <div className="col-3">
                                                                <div className="row">
                                                                    <div className="col-3 d-flex">
                                                                        <div className="btn-group">
                                                                            <button id="addRow" className="btn btn-info" onClick={handleAdd}>
                                                                                {ADD}
                                                                                <i className="fa fa-plus"></i>
                                                                            </button>
                                                                            <Add_EditQuestions
                                                                                show={show}
                                                                                handleClose={handleClose}
                                                                                edit={edit}
                                                                                tabId={tabId}
                                                                                questions={questions}
                                                                                questionId={questionId}
                                                                                tabSelectOptions={tabSelectOptions}
                                                                                diagnosesSelectOptions={diagnosesSelectOptions}
                                                                                handlediagnosesChange={handlediagnosesChange}
                                                                                diagnosesId={diagnosesId}
                                                                                addTabId={addTabId}
                                                                                handleGroupChange={handleGroupChange}
                                                                                groupSelectOptions={groupSelectOptions}
                                                                                groupId={groupId}
                                                                                handleAddTabChange={handleAddTabChange}
                                                                                fetchData={fetchData}
                                                                                handlechange={handlechange}
                                                                                addQuestionData={addQuestionData}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4 d-flex">
                                                                        <div className="btn-group">
                                                                            <UploadExcelFile
                                                                                fetchData={fetchData}
                                                                                fetchTabList={fetchTabList}
                                                                                handleDownload={downLoadDynamicExcel ? handleDownload : handleDemoDownload}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4 mx-2 d-flex"></div>
                                                                </div>
                                                            </div>

                                                            <div className="col-5">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="row d-flex justify-content-end">
                                                                            <div className="col-5">
                                                                                <label className="mt-2">{QUESTION_NAME}</label>
                                                                            </div>
                                                                            <div className="col-7">
                                                                                <input
                                                                                    className="form-control"
                                                                                    name="search"
                                                                                    type="search"
                                                                                    placeholder="Search"
                                                                                    aria-label="Search"
                                                                                    value={filterValues.search}
                                                                                    onChange={handleFilterValues}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="row d-flex justify-content-end">
                                                                            <div className="col-4 d-flex justify-content-end mt-2">
                                                                                <label className="">{TAB_NAME}</label>
                                                                            </div>
                                                                            <div className="col-7">
                                                                                <Select
                                                                                    name="tabName"
                                                                                    value={tabSelectOptions && tabSelectOptions.filter(({ value }) => value == tabId)}
                                                                                    options={tabSelectOptions}
                                                                                    onChange={handleTabChange}
                                                                                    placeholder={SELECT_TAB}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="row d-flex justify-content-end">
                                                                            <div className="col-5 d-flex justify-content-end mt-2">
                                                                                <label className="">{FORM_TYPE}</label>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <select className="form-control col-md-3" name="formType" value={filterValues.formType} onChange={handleFilterValues}>
                                                                                    <option value="">{ALL}</option>
                                                                                    {Object.keys(FORMTYPE_DROPDOWN).map((item, i) => {
                                                                                        return (
                                                                                            <option key={i} value={FORMTYPE_DROPDOWN[item]}>
                                                                                                {item}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-6">
                                                                        <div className="row d-flex justify-content-end">
                                                                            <div className="col-5 d-flex justify-content-end mt-2">
                                                                                <label className="">{STATUS}</label>
                                                                            </div>
                                                                            <div className="col-5 ">
                                                                                <select className="form-control col-md-3" name="status" value={filterValues.status} onChange={handleFilterValues}>
                                                                                    <option value="">{ALL}</option>
                                                                                    {Object.keys(QUESTION_STATUS).map((item, i) => {
                                                                                        return (
                                                                                            <option key={i} value={QUESTION_STATUS[item]}>
                                                                                                {item}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-scrollable" style={isLoading ? { border: "none" } : {}}>
                                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{NO}</th>
                                                                        <th>{NAME}</th>
                                                                        <th>{TAB_NAME}</th>
                                                                        <th>{GROUP_NAME}</th>
                                                                        <th>{FORM_TYPE}</th>
                                                                        <th>{SORT_PRIORITY}</th>
                                                                        <th>{STATUS}</th>
                                                                        <th>{DIAGNOSES}</th>
                                                                        <th>{ACTION}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={isLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                                    <Add_EditDiagnoses
                                                                        setDiagOffset={setDiagOffset}
                                                                        setDiagCurrentPage={setDiagCurrentPage}
                                                                        diagoffset={diagoffset}
                                                                        fetchDiagnosesList={fetchDiagnosesList}
                                                                        setQuestionId={setQuestionId}
                                                                        diagList={diagList}
                                                                        diagrowCount={diagrowCount}
                                                                        diagpageCount={diagpageCount}
                                                                        diagcurrentPage={diagcurrentPage}
                                                                        handleDiagPageClick={handleDiagPageClick}
                                                                        fetchDiagnosesData={fetchDiagnosesData}
                                                                        showdiag={showdiag}
                                                                        setShowDiag={setShowDiag}
                                                                        diagnosesSelectOptions={diagnosesSelectOptions}
                                                                        handlediagnosesChange={handlediagnosesChange}
                                                                        diagnosesId={diagnosesId}
                                                                        questionId={questionId}
                                                                        setDiagnoses={setDiagnoses}
                                                                        setDiagnosesId={setDiagnosesId}
                                                                    />
                                                                    {isLoading ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        <>
                                                                            {!questionsList ? (
                                                                                <>{isLoading ? "" : <NoDataFound />}</>
                                                                            ) : (
                                                                                questionsList &&
                                                                                questionsList.map((item, i) => {
                                                                                    return (
                                                                                        <tr className="odd gradeX" key={i}>
                                                                                            <td> {(offset - 1) * ROWS_PER_PAGE + i + 1} </td>
                                                                                            <td className="left">{item.name}</td>
                                                                                            <td className="left">{item.tab ? item.tab.name : DASH}</td>
                                                                                            <td className="left">{item.group && item.group.name ? item.group.name : DASH}</td>
                                                                                            <td className="left">
                                                                                                {Object.keys(FORMTYPE_DROPDOWN).find((key) => FORMTYPE_DROPDOWN[key] === item.formType)}
                                                                                            </td>
                                                                                            <td className="left">{item.priorityList}</td>
                                                                                            <td className="left">
                                                                                                <span
                                                                                                    className={
                                                                                                        "badge " +
                                                                                                        (item.statusId == 2
                                                                                                            ? "deactiveStatus badge-warning"
                                                                                                            : item.statusId == 1
                                                                                                            ? "activeStatus badge-success"
                                                                                                            : "badge-danger")
                                                                                                    }
                                                                                                >
                                                                                                    {Object.keys(TABS_STATUS).find((key) => TABS_STATUS[key] === item.statusId)}
                                                                                                </span>
                                                                                            </td>
                                                                                            <td>
                                                                                                {" "}
                                                                                                <button className="btn btn-warning btn-xs" onClick={() => handleDiagnosesAdd(item.questionId)}>
                                                                                                    <i className="fa fa-plus mt-1"></i>
                                                                                                </button>
                                                                                            </td>
                                                                                            <td>
                                                                                                <button className="btn btn-primary btn-xs" onClick={() => handleEdit(item.id, item.patientChartTabId)}>
                                                                                                    <i className="fa fa-pencil"></i>
                                                                                                </button>
                                                                                                <button type="button" className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mx-4">
                                                        <div>Total {questionsList == null ? "0" : rowCount} Questions</div>
                                                        <div className="pagination-center">
                                                            <ReactPaginate
                                                                initialPage={0}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={0}
                                                                pageRangeDisplayed={3}
                                                                onPageChange={handlePageClick}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                pageLinkClassName="page-link"
                                                                breakLinkClassName="page-link"
                                                                nextLinkClassName="page-link"
                                                                previousLinkClassName="page-link"
                                                                pageClassName="page-item"
                                                                breakClassName="page-item"
                                                                nextClassName="page-item"
                                                                previousClassName="page-item"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"
                                                                disableInitialCallback={true}
                                                                forcePage={currentPage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManageQuestions;
