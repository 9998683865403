import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { baseURL } from "../../Config";
import { VERIFY_DOCUMENT } from "../../Helper/ApiPath";
import { DOCUMENTTYPE, DOCUMENT_TYPE, SELECT_DOCUMENT_TYPE, UPLOAD_DOCUMET, VERIFY, VERIFY_DOCUMET } from "../../Helper/Constants";
import { dateFormateMonthDateYear } from "../../Helper/Helper";
import { SELECT_DOCUMENT_TYPE_MESSAGE, VERIFY_DOCUMENT_MESSAGE, UPLOAD_IMAGE } from "../../Helper/Messages";

function VerifyDocument() {
    let navigate = useNavigate();
    const [type, setType] = useState("");
    let [image, setImage] = useState({ preview: "", data: "" });

    const handleTypeChange = (e) => {
        setType(e.target.value);
        if (document.getElementById("uploadDocument")) {
            document.getElementById("uploadDocument").value = "";
            document.getElementById("verify").classList.add("d-none");
        }
    };

    const handleFileChange = (e) => {
        let img = {
            preview: URL.createObjectURL(e.target.files[0]),
            data: e.target.files[0],
        };

        const fileExtension = img.data.name.split(".").at(-1).toLowerCase();
        const allowedFileTypes = ["pdf"];
        if (!allowedFileTypes.includes(fileExtension)) {
            alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
            document.getElementById("uploadDocument").value = "";
        } else {
            document.getElementById("verify").classList.remove("d-none");
            setImage(img);
        }
    };

    const handleVerify = (e) => {
        e.preventDefault();

        if (!type || type == "") {
            toast.error(SELECT_DOCUMENT_TYPE_MESSAGE);
        } else if (!image.data) {
            toast.error(UPLOAD_IMAGE);
        } else {
            let formData = new FormData();
            formData.append("image", image.data);
            formData.append("option", type);
            Swal.fire({
                title: VERIFY_DOCUMENT_MESSAGE,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, verify it!",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    if (result.isConfirmed) {
                        await axios
                            .post(`${baseURL}/${VERIFY_DOCUMENT}`, formData)
                            .then((res) => {
                                Swal.fire(
                                    res.data.message +
                                        `<div><h4 style="color:black;">` +
                                        "document created by" +
                                        `<h4 /><div /><div>` +
                                        `<h4 style="color:black;"><b>` +
                                        res.data.data.email +
                                        `<b/><h4 /><div /><div>` +
                                        `<h4 style="color:black;">` +
                                        "on" +
                                        `<h4 /><div /><div>` +
                                        `<h4 style="color:black;"><b>` +
                                        dateFormateMonthDateYear(res.data.data.created_at) +
                                        `<b/><h4 /><div />`,
                                    " ",
                                    "success"
                                );

                                setType("");
                                setImage({ preview: "", data: "" });
                                document.getElementById("uploadDocument").value = "";
                            })
                            .catch((err) => {
                                if (err.response) {
                                    if (err.response.status == 500) {
                                        localStorage.clear();
                                        navigate("/login");
                                    } else {
                                        Swal.fire({
                                            title: err.response.data.message,
                                            icon: "error",
                                        });
                                    }
                                }
                            });
                    }
                }
            });
        }
    };

    return (
        <div className="limiter">
            <div className="container-login100 page-background">
                <div className="wrap-login100">
                    <form className="login100-form validate-form">
                        <span className="login100-form-logo">
                            <img alt="" src="assets/img/logo/login-logo.png" />
                        </span>
                        <span className="login100-form-title p-b-34 p-t-27">{VERIFY_DOCUMET}</span>

                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="form-group">
                                    <i className="fa fa-file-text font-left textColor" aria-hidden="true"></i>
                                    <span className="textColor">{DOCUMENT_TYPE}</span>
                                    <span className="requireField"> * </span>
                                    <select className="form-control input-height" name="documentType" value={type} onChange={handleTypeChange}>
                                        <option value=""> {SELECT_DOCUMENT_TYPE}</option>
                                        {Object.keys(DOCUMENTTYPE).map((item, i) => {
                                            return (
                                                <option key={i} value={DOCUMENTTYPE[item]}>
                                                    {item}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>

                                {type !== "" ? (
                                    <>
                                        <div className="form-group">
                                            <i className="fa fa-upload font-left textColor" aria-hidden="true"></i>
                                            <span className="textColor">{UPLOAD_DOCUMET}</span>
                                            <span className="requireField"> * </span>
                                            <div className="row">
                                                <div className="col-12 d-flex justify-content-center">
                                                    <input className="form-control " type="file" name="document" id="uploadDocument" onChange={handleFileChange} accept="application/pdf"></input>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-center">
                                            <button type="button" className="btn btn-circle btn-lg btn-success d-none" id="verify" name="verify" onClick={handleVerify}>
                                                <i className="fa fa-check-circle font-left textColor" aria-hidden="true"></i>
                                                {VERIFY}
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default VerifyDocument;
