import axios from "axios";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../../Config";
import { ADD_DIAGNOSES_FROM_QUESTION_API, DELETE_DIAGNOSES_FROM_QUESTION_API } from "../../../Helper/ApiPath";
import { ACTION, ADD, CANCEL, CODE, DASH, DEFAULT_DIAGNOSES, DIAGNOSES, NO, ROWS_PER_PAGE, SELECT_DIAGNOSES } from "../../../Helper/Constants";
import Select from "react-select";
import Swal from "sweetalert2";
import { DELETE_MESSAGE } from "../../../Helper/Messages";
import ReactPaginate from "react-paginate";

function Add_EditDiagnoses(props) {
    const navigate = useNavigate();
    const token = JSON.parse(localStorage.getItem("logToken"));
    const handleClose = () => {
        props.setDiagOffset(1);
        props.setDiagCurrentPage(0);
        props.setDiagnoses([]);
        props.setDiagnosesId("");
        props.setQuestionId("");
        props.setShowDiag(false);
    };

    const handleAdddiagnoses = async (e) => {
        await axios
            .post(`${baseURL}/${ADD_DIAGNOSES_FROM_QUESTION_API}`, {
                headers: { authorization: token.token },
                diagnosesId: props.diagnosesId,
                questionId: props.questionId,
            })
            .then((res) => {
                if (res.data.success == true) {
                    props.setDiagnosesId("");
                    props.fetchDiagnosesData(props.questionId);
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: DELETE_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/${DELETE_DIAGNOSES_FROM_QUESTION_API}/${id}`, {
                            headers: { authorization: token.token },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire(res.data.message, " ", "success");
                                props.fetchDiagnosesData(props.questionId);
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    return (
        <>
            <Modal show={props.showdiag} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{DIAGNOSES}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-3">
                            <label className="control-label mt-1">
                                {DEFAULT_DIAGNOSES}
                                <span className="required"> * </span>
                            </label>
                        </div>
                        <div className="col-9">
                            <Select
                                name="diagnosesName"
                                value={props.diagnosesSelectOptions && props.diagnosesSelectOptions.filter(({ value }) => value == props.diagnosesId)}
                                options={props.diagnosesSelectOptions}
                                onChange={props.handlediagnosesChange}
                                placeholder={SELECT_DIAGNOSES}
                            />
                        </div>
                    </div>

                    {props.diagnosesId && props.questionId ? (
                        <div className="d-flex justify-content-center mt-2">
                            <button className="btn btn-info" onClick={handleAdddiagnoses}>
                                {ADD}
                            </button>
                        </div>
                    ) : (
                        ""
                    )}

                    <div className="table-scrollable">
                        <table className="table table-hover table-checkable order-column full-width" id="example4">
                            <thead>
                                <tr>
                                    <th>{NO}</th>
                                    <th>{DIAGNOSES}</th>
                                    <th>{CODE}</th>
                                    <th>{ACTION}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!props.diagList ? (
                                    <>
                                        <tr className="text-center" style={{ color: "red" }}>
                                            <td colSpan={15} className="text-center mt-5" style={{ color: "red" }}>
                                                {/* No data Found */}
                                                <img alt="" src="assets/img/NoDataFound/no_data_found.png" style={{ height: "200px", width: "auto" }} />
                                            </td>
                                        </tr>
                                    </>
                                ) : (
                                    props.diagList &&
                                    props.diagList.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{(props.diagoffset - 1) * ROWS_PER_PAGE + i + 1}</td>
                                                <td>{item.diagnoses ? item.diagnoses.name : DASH}</td>
                                                <td>{item.diagnoses ? item.diagnoses.code : DASH}</td>
                                                <td>
                                                    <button className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
                                                        <i className="fa fa-trash-o"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex justify-content-between ">
                        <div className="mt-3">Total {props.diagList == null ? "0" : props.diagrowCount} Diagnoses</div>
                        <div className="pagination-center">
                            <ReactPaginate
                                initialPage={0}
                                pageCount={props.diagpageCount}
                                marginPagesDisplayed={0}
                                pageRangeDisplayed={3}
                                onPageChange={props.handleDiagPageClick}
                                containerClassName="pagination"
                                activeClassName="active"
                                pageLinkClassName="page-link"
                                breakLinkClassName="page-link"
                                nextLinkClassName="page-link"
                                previousLinkClassName="page-link"
                                pageClassName="page-item"
                                breakClassName="page-item"
                                nextClassName="page-item"
                                previousClassName="page-item"
                                previousLabel="Previous"
                                nextLabel="Next"
                                disableInitialCallback={true}
                                forcePage={props.diagcurrentPage}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" onClick={handleClose}>
                        {CANCEL}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Add_EditDiagnoses;
