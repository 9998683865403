import React from "react";
import { HOME, PATIENT_DETAIL, PATIENT_INFO, PATIENT_LIST, PATIENT_TAB, DOCTOR_PAGES } from "../../../../Helper/Constants";
import { Tabs, Tab } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../../Config";
import { PATIENT_TABS_API, PATIENT_TABS_CONTENT_API } from "../../../../Helper/ApiPath";
import { useState } from "react";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Cookies from "universal-cookie";
import PatientTabDetails from "./PatientTabDetails";
import PatientProfile from "./PatientProfile";
import _ from "lodash";

function PatientTabs() {
    let token = JSON.parse(localStorage.getItem("logToken"));
    let patientId = localStorage.getItem("patientId");
    let navigate = useNavigate();
    const [tabs, setTabs] = useState([]);
    const [data, setData] = useState([]);
    const [group, setGroup] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const cookies = new Cookies();

    let isTrial = cookies.get("isTrial");
    if (isTrial) {
        isTrial = cookies.get("isTrial").isTrial;
    }
    let pageAccess = cookies.get("doctorPageAccess") ? (cookies.get("doctorPageAccess").length ? cookies.get("doctorPageAccess") : [14, 15, 16, 17, 18, 19, 20]) : "";
    const handleSelect = async (e) => {
        axios
            .post(`${baseURL}/${PATIENT_TABS_CONTENT_API}`, {
                headers: { authorization: token.token },
                patientChartTabId: Number(e),
                isList: true,
                patientId,
            })
            .then((res) => {
                setIsLoading(false);
                setData(res.data.data);
                const groupbydata = _.chain(
                    res.data.data.map((item, i) => {
                        return { id: item.id, formType: item.formType, groupName: item.groupName, name: item.name, value: item.value, groupId: item.groupId };
                    })
                )
                    .groupBy("groupName")
                    .map((value, key) => ({ groupName: key, data: value }))
                    .value();
                setGroup(groupbydata);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status === 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const fetchdata = async () => {
        await axios
            .post(`${baseURL}/${PATIENT_TABS_API}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                setTabs(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        fetchdata();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{PATIENT_DETAIL}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                {isTrial === 0 || pageAccess.includes(DOCTOR_PAGES.Patient) ? (
                                    <li>
                                        <i className="fa fa-home"></i>&nbsp;
                                        <NavLink className="parent-item" to="/dashboard">
                                            {HOME}
                                        </NavLink>
                                        &nbsp;<i className="fa fa-angle-right"></i>
                                    </li>
                                ) : (
                                    ""
                                )}

                                <li>
                                    <i className="fa fa-ambulance" aria-hidden="true"></i>&nbsp;
                                    <NavLink className="parent-item" to="/managePatient">
                                        {PATIENT_LIST}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{PATIENT_DETAIL}</li>
                            </ol>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="card card-box">
                                <>
                                    <div className="card-head">
                                        <header>{PATIENT_INFO}</header>
                                    </div>
                                    <div style={{ display: "flex" }} id="formData">
                                        <div className="card-body" id="bar-parent">
                                            {/* set default event key */}
                                            <Tabs defaultActiveKey={PATIENT_TAB} id="uncontrolled-tab-example" onSelect={(e) => handleSelect(e)}>
                                                <Tab eventKey={PATIENT_TAB} title={PATIENT_TAB}>
                                                    <PatientProfile />
                                                </Tab>

                                                {tabs &&
                                                    tabs.map((item, i) => {
                                                        return (
                                                            <Tab key={i} eventKey={item.id} title={item.name}>
                                                                <PatientTabDetails id={item.id} data={data} isLoading={isLoading} title={item.name} group={group} />
                                                            </Tab>
                                                        );
                                                    })}
                                            </Tabs>
                                        </div>
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PatientTabs;
