import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../Config";
import { ALL_DOCTOR_IN_SELECTED_OFFICE, FIND_DOCTOR_AFTER_LOGIN, LOGOUT_API, OFFICE_TABLE_LIST } from "../../Helper/ApiPath";
import { CHANGE_PASSWORD, EDIT_OFFICE, EDIT_PROFILE, LOGOUT, ROLES, STOP_IMPERSONATING, SWITCH, VIEW_PROFILE, RESET_PASSWORD } from "../../Helper/Constants";
import { CHOOSE_DOCTOR, CHOOSE_OFFICE, DOCTOR_SWITCH, DOCTOR_SWITCH_SUCCESSFULLY } from "../../Helper/Messages";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";

function DoctorHeader(props) {
    const logToken = JSON.parse(localStorage.getItem("logToken"));
    const cookies = new Cookies();
    let role = cookies.get("role");
    if (role) {
        role = cookies.get("role").roleId;
    }

    let pageAuthorityRoleId = cookies.get("role");
    if (pageAuthorityRoleId) {
        pageAuthorityRoleId = cookies.get("role").pageAuthorityRoleId;
    }

    let isTrial = cookies.get("isTrial");
    if (isTrial) {
        isTrial = cookies.get("isTrial").isTrial;
    }
    const [officeId, setOfficeId] = useState(props.officeId);
    const [doctorId, setDoctorId] = useState(props.doctorId);

    const navigate = useNavigate();

    const logout = () => {
        axios.get(`${baseURL}/${LOGOUT_API}`, { headers: { Authorization: logToken.token } });
        localStorage.clear();
        cookies.remove("role");
        cookies.remove("isTrial");
        navigate("/login");
    };

    const [officeList, setOfficeList] = useState([]);

    const fetchOfficeList = async () => {
        await axios
            .post(`${baseURL}/${OFFICE_TABLE_LIST}`, {
                headers: { authorization: logToken.token },
            })
            .then((res) => {
                setOfficeList(res.data.data);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };
    const [doctorList, setDoctorList] = useState([]);

    const handleOfficechange = async (e) => {
        setDoctorList("");
        setOfficeId(e.target.value);
        fetchDoctorList(e.target.value);
    };

    const fetchDoctorList = async (officeId) => {
        await axios
            .post(`${baseURL}/${ALL_DOCTOR_IN_SELECTED_OFFICE}`, {
                headers: { authorization: logToken.token },
                officeId: officeId,
                isDoctor: true,
            })
            .then((res) => {
                setDoctorList(res.data.data);
                if (res.data.data.length === 1) {
                    setDoctorId(res.data.data[0].id);
                } else {
                    setDoctorId(props.doctorId);
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const handleDoctorChange = (e) => {
        setDoctorId(e.target.value);
    };

    const handleSwitch = async () => {
        if (!officeId) {
            toast.error(CHOOSE_OFFICE);
        } else if (!doctorId) {
            toast.error(CHOOSE_DOCTOR);
        } else {
            Swal.fire({
                title: DOCTOR_SWITCH,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, switch!",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    if (result.isConfirmed) {
                        localStorage.setItem("officeId", officeId);
                        localStorage.setItem("doctorId", doctorId);

                        await axios
                            .post(`${baseURL}/${FIND_DOCTOR_AFTER_LOGIN}`, {
                                headers: { authorization: logToken.token },
                                officeId: officeId,
                                doctorId: doctorId,
                            })
                            .then((res) => {
                                if (res.status === 200) {
                                    Swal.fire(DOCTOR_SWITCH_SUCCESSFULLY, " ", "success").then((result) => {
                                        if (result.isConfirmed) {
                                            localStorage.setItem("doctorPracticeId", res.data.data.id);
                                            localStorage.setItem("payerName", res.data.data.payerName);
                                            localStorage.setItem("payerValue", res.data.data.payerValue);
                                            window.location.reload();
                                        }
                                    });
                                }
                            })
                            .catch((err) => {
                                if (err.response) {
                                    if (err.response.status == 500) {
                                        localStorage.clear();
                                        navigate("/login");
                                    } else {
                                        toast.error(err.response.data.message);
                                    }
                                }
                            });
                    }
                }
                if (result.dismiss == "cancel" || result.dismiss == "backdrop") {
                    window.location.reload();
                }
            });
        }
    };

    const handleStop = (e) => {
        if (role == ROLES.ADMIN) {
            localStorage.setItem("adminRole", 1);
        } else if (role == ROLES.EMPLOYEE_ADMIN) {
            localStorage.setItem("adminRole", 5);
        } else {
            localStorage.setItem("adminRole", 4);
        }
        sessionStorage.removeItem("claimPagination");
        sessionStorage.removeItem("opNotePagination");
        sessionStorage.removeItem("cnPagination");
        sessionStorage.removeItem("preAuthPagination");
        sessionStorage.removeItem("patientPagination");
        sessionStorage.removeItem("officePagination");
        sessionStorage.removeItem("assignDoctorPagination");
        sessionStorage.removeItem("eobPagination");
        sessionStorage.removeItem("doctorPagination");
        localStorage.removeItem("officeId");
        localStorage.removeItem("doctorId");
        localStorage.removeItem("doctorPracticeId");
        localStorage.setItem("payerName", null);
        localStorage.setItem("payerId", null);
        localStorage.setItem("payerValue", null);

        navigate(isTrial == 0 ? "/managePractice" : "/manageTrial");
        window.location.reload();
        cookies.remove("isTrial");
    };
    useEffect(() => {
        if (role == ROLES.DOCTOR) {
            fetchOfficeList();
            fetchDoctorList(officeId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="page-header navbar navbar-fixed-top">
                <div className="page-header-inner ">
                    <div className="page-logo">
                        {isTrial == 0 || isTrial == 2 ? (
                            <a href="/dashboard">
                                <span className="logo-icon ">
                                    <img className="img-circle mini-logo1" src="/assets/img/logo/mini_logo1.png" alt="Header-mini-logo" />
                                </span>
                                <span className="logo-default">
                                    <img className="header-logo1" src="/assets/img/logo/logo_header.png" alt="Header-logo" />
                                </span>
                            </a>
                        ) : (
                            <a href="/managePatient">
                                <span className="logo-icon ">
                                    <img className="img-circle mini-logo1" src="/assets/img/logo/mini_logo1.png" alt="Header-mini-logo" />
                                </span>
                                <span className="logo-default">
                                    <img className="header-logo1" src="/assets/img/logo/logo_header.png" alt="Header-logo" />
                                </span>
                            </a>
                        )}
                    </div>
                    <ul className="nav navbar-nav navbar-left in">
                        <li>
                            <a className="menu-toggler sidebar-toggler">
                                <i className="icon-menu"></i>
                            </a>
                        </li>
                    </ul>
                    <a className="menu-toggler responsive-toggler" data-bs-toggle="collapse" data-bs-target=".navbar-collapse">
                        <span></span>
                    </a>
                    <div className="top-menu">
                        <ul className="nav navbar-nav pull-right">
                            {role === ROLES.ADMIN || role === ROLES.EMPLOYEE_ADMIN || role === ROLES.EMPLOYEE ? (
                                <li className="mx-3 my-3">
                                    <button className="btn btn-danger" onClick={handleStop}>
                                        {STOP_IMPERSONATING}
                                    </button>
                                </li>
                            ) : (
                                ""
                            )}

                            {role == ROLES.DOCTOR && isTrial == 0 ? (
                                <>
                                    <li className="my-3 mx-3">
                                        <select className="form-control col-md-6" name="officeId" value={officeId} onChange={handleOfficechange}>
                                            {officeList &&
                                                officeList.map((item, i) => {
                                                    return (
                                                        <option className="deutsch" key={i} value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </li>
                                    <li className="my-3 mx-3">
                                        <select className="form-control col-md-6" name="doctorId" value={doctorId} onChange={handleDoctorChange}>
                                            <option value="">-- select doctor --</option>

                                            {doctorList &&
                                                doctorList.map((item, i) => {
                                                    return (
                                                        <option key={i} value={item.id}>
                                                            {item.firstName} {item.lastName}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </li>
                                    <li className="mx-3 my-3">
                                        <button className="btn btn-warning" onClick={handleSwitch}>
                                            {SWITCH}
                                        </button>
                                    </li>
                                </>
                            ) : (
                                ""
                            )}

                            <li className="dropdown dropdown-user">
                                <a className="dropdown-toggle" data-bs-toggle="dropdown" data-hover="dropdown" data-close-others="true">
                                    <img alt="" className="img-circle " src="img/dp.jpg" />
                                    {logToken ? (
                                        <span className="username username-hide-on-mobile">
                                            {props.doctorFirstName} {props.doctorLastName}
                                        </span>
                                    ) : (
                                        <span className="username username-hide-on-mobile"> Kiran </span>
                                    )}

                                    <i className="fa fa-angle-down"></i>
                                </a>
                                <ul className="dropdown-menu dropdown-menu-default">
                                    <li>
                                        <Link to="/Profile">
                                            <i className="icon-user"></i>
                                            {VIEW_PROFILE}{" "}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/EditProfile">
                                            <i className="icon-user"></i>
                                            {EDIT_PROFILE}{" "}
                                        </Link>
                                    </li>
                                    {isTrial == 0 ? (
                                        <li>
                                            <Link to="/editOfficeDetail">
                                                <i className="fa fa-briefcase" aria-hidden="true"></i>
                                                {EDIT_OFFICE}{" "}
                                            </Link>
                                        </li>
                                    ) : (
                                        ""
                                    )}

                                    <li className="divider"> </li>

                                        {role === ROLES.DOCTOR ? (
                                            <li>
                                                <Link to="/changePassword">
                                                    <i className="fa fa-key"></i>
                                                    {CHANGE_PASSWORD}{" "}
                                                </Link>
                                            </li>
                                        ) : (
                                            role === ROLES.EMPLOYEE_ADMIN && pageAuthorityRoleId === 5 ? (
                                                <li>
                                                    <Link to="/manualReset">
                                                        <i className="fa fa-key"></i>
                                                        {RESET_PASSWORD}{" "}
                                                    </Link>
                                                </li>
                                            ) : (
                                                ""
                                            )
                                        )}
                                    <li>
                                        <a onClick={() => logout()}>
                                            <i className="icon-logout"></i>
                                            {LOGOUT}{" "}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DoctorHeader;
