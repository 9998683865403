import React from "react";
import { EDIT_PATIENT, HOME, PATIENT_INFO, PATIENT_TAB, PATIENT_LIST } from "../../../../Helper/Constants";
import { Tabs, Tab } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../../Config";
import { PATIENT_TABS_API, PATIENT_TABS_CONTENT_API } from "../../../../Helper/ApiPath";
import { useState } from "react";
import { toast } from "react-toastify";
import { useEffect } from "react";
import DynamicTabs from "./DynamicTabs";
import EditPatient from "../../EditPatient";
import _ from "lodash";

function PatientChartTabs() {
    let token = JSON.parse(localStorage.getItem("logToken"));
    let patientId = localStorage.getItem("patientId");
    let navigate = useNavigate();
    const [tabs, setTabs] = useState([]);
    const [data, setData] = useState([]);
    const [group, setGroup] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleSelect = async (e) => {
        axios
            .post(`${baseURL}/${PATIENT_TABS_CONTENT_API}`, {
                headers: { authorization: token.token },
                patientChartTabId: Number(e),
                isList: true,
                patientId,
            })
            .then((res) => {
                setIsLoading(false);
                setData(res.data.data);
                const groupbydata = _.chain(
                    res.data.data.map((item, i) => {
                        return { id: item.id, formType: item.formType, groupName: item.groupName, name: item.name, value: item.value, groupId: item.groupId };
                    })
                )
                    .groupBy("groupName")
                    .map((value, key) => ({ groupName: key, data: value }))
                    .value();

                setGroup(groupbydata);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status === 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const fetchdata = async () => {
        await axios
            .post(`${baseURL}/${PATIENT_TABS_API}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                setTabs(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const [inputCheckObj, setInputcheckObj] = useState([]);
    let checkedArray = inputCheckObj;

    const handleCheckChange = async (e, fid, GName) => {
        var oldgropudata = group;
        var filterddata = await _.find(oldgropudata, { groupName: GName.toString() });
        var filterddata_index = oldgropudata.findIndex((p) => p.groupName === GName.toString());
        var objprocdata = filterddata.data;
        var proc_id = await _.find(objprocdata, { id: parseInt(fid) });
        var proc_id_index = objprocdata.findIndex((p) => p.id === parseInt(fid));
        var newobj = {
            formType: proc_id.formType,
            groupId: proc_id.groupId,
            groupName: proc_id.groupName,
            id: proc_id.id,
            name: proc_id.name,
            value: !e.target.checked ? 1 : 0,
        };
        objprocdata[proc_id_index] = newobj;
        oldgropudata[filterddata_index].data = objprocdata;
        setGroup([...oldgropudata]);
        var newCheckObj = { patientChartTabQuestionId: e.target.id, value: e.target.checked ? 1 : 0 };
        let index = checkedArray.findIndex((p) => p.patientChartTabQuestionId == e.target.id);
        if (index != -1) {
            checkedArray[index] = newCheckObj;
        } else {
            checkedArray.push(newCheckObj);
        }
        setInputcheckObj(checkedArray);
    };

    const [inputValueObj, setInputValueObj] = useState([]);
    let InputObj = inputValueObj;
    const handleChange = async (e, fid, GName) => {
        var value = e.target.value;
        var id = e.target.id;
        var oldgropudata = group;
        var filterddata = await _.find(oldgropudata, { groupName: GName.toString() });
        var filterddata_index = oldgropudata.findIndex((p) => p.groupName === GName.toString());
        var objprocdata = filterddata.data;
        var proc_id = await _.find(objprocdata, { id: parseInt(fid) });
        var proc_id_index = objprocdata.findIndex((p) => p.id === parseInt(fid));
        var newobj = {
            formType: proc_id.formType,
            groupId: proc_id.groupId,
            groupName: proc_id.groupName,
            id: proc_id.id,
            name: proc_id.name,
            value: value,
        };
        objprocdata[proc_id_index] = newobj;
        oldgropudata[filterddata_index].data = objprocdata;
        setGroup([...oldgropudata]);
        var newObj = { patientChartTabQuestionId: id, value: value };
        let index = InputObj.findIndex((p) => p.patientChartTabQuestionId == id);
        if (index != -1) {
            InputObj[index] = newObj;
        } else {
            InputObj.push(newObj);
        }
        setInputValueObj(InputObj);
    };

    useEffect(() => {
        fetchdata();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{EDIT_PATIENT}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li>
                                    <i className="fa fa-ambulance" aria-hidden="true"></i>&nbsp;
                                    <NavLink className="parent-item" to="/managePatient">
                                        {PATIENT_LIST}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{EDIT_PATIENT}</li>
                            </ol>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="card card-box">
                                <>
                                    <div className="card-head">
                                        <header>{PATIENT_INFO}</header>
                                    </div>
                                    <div style={{ display: "flex" }} id="formData">
                                        <div className="card-body" id="bar-parent">
                                            <Tabs defaultActiveKey={PATIENT_TAB} id="uncontrolled-tab-example" onSelect={(e) => handleSelect(e)}>
                                                <Tab eventKey={PATIENT_TAB} title={PATIENT_TAB}>
                                                    <EditPatient />
                                                </Tab>

                                                {tabs &&
                                                    tabs.map((item, i) => {
                                                        return (
                                                            <Tab key={i} eventKey={item.id} title={item.name}>
                                                                <DynamicTabs
                                                                    id={item.id}
                                                                    data={data}
                                                                    group={group}
                                                                    isLoading={isLoading}
                                                                    title={item.name}
                                                                    handleCheckChange={handleCheckChange}
                                                                    handleChange={handleChange}
                                                                    checkedArray={checkedArray}
                                                                    inputValueObj={InputObj}
                                                                />
                                                            </Tab>
                                                        );
                                                    })}
                                            </Tabs>
                                        </div>
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PatientChartTabs;
