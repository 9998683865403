import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../../Config";
import {
  ADD_COMPREHENSIVE_NOTE,
  ADD_PRE_AUTHORIZATION_NOTE,
  PATIENT_TABS_CONTENT_API,
  PROFILE_API,
  MEDICATION_VALUE_LIST_API,
  OFFICE_DETAIL_API,
} from "../../../Helper/ApiPath";
import {
  CLEAR,
  DASH,
  PREVIEW,
  NPI1,
  NPI2,
  LICENSE_NUMBER,
  PTAN_NUMBER,
  DATE_OF_SERVICE,
  TAXID,
  PATIENT_NAME,
  BIRTH_DATE,
  ADDRESS,
  PAYER,
  PRIMARY_INSURANCE_NAME,
  SAVE,
  CANCEL,
  DOCTOR_NAME,
  DATE,
  COMPREHENSIVE_INFORMATION,
  PREAUTHORIZATION_INFORMATION,
} from "../../../Helper/Constants";
import {
  dateAPIFormate,
  dateFormateMonthDateYear,
} from "../../../Helper/Helper";
import SignaturePad from "react-signature-canvas";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import _, { reduce } from "lodash";
import moment from "moment";
import { ENTER_SIGNATURE } from "../../../Helper/Messages";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function ComprehensiveResult(props) {
  const token = JSON.parse(localStorage.getItem("logToken"));
  console.log(props.checkedArray, " diagArray");
  const navigate = useNavigate();
  const [imageURL, setImageURl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [CNDate, setCNDate] = useState("");
  const [signatureImage, setSignatureImage] = useState("");
  const [editSign, setEditSign] = useState(false);
  const [isBase64Srt, setIsBase64Srt] = useState(false);

  const fetchDoctorData = async () => {
    await axios
      .get(`${baseURL}/${PROFILE_API}/${props.doctorId}`, {
        headers: { authorization: token.token },
      })
      .then((res) => {
        setSignatureImage(res.data.data.signatureImage);
        setImageURl(
          res.data.data.signatureImage ? res.data.data.signatureImage : null
        );
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  let ProcedureStr = "";
  console.log(props.procedureArray, " wo ");
  props.procedureArray &&
    Object.entries(
      reduce(
        props.procedureArray,
        (acc, curr) => {
          if (acc[curr.code]) {
            acc[curr.code].unit += 1;
          } else {
            acc[curr.code] = curr;
          }
          return acc;
        },
        {}
      )
    ).map(([code, item], i) => {
      console.log(item, i);
      const unit_display = item.unit == 1 ? "unit" : "units";
      ProcedureStr = ProcedureStr.concat(
        `<b> ${i + 1}. ${item.code}(${item.unit} ${unit_display})</b> - ${
          item.description
        }<br>`
      );
    });

  let diagnosesstr = "";
  props.checkedArray &&
    props.checkedArray.map((item, i) => {
      diagnosesstr = diagnosesstr.concat(
        `<b>${i + 1}. ${item.code}</b> - ${item.name}<br>`
      );
    });

  if (props.addComprehensive.diagnosesEtc) {
    diagnosesstr = diagnosesstr.concat(
      `<b>※ ETC Diagnoses - </b>${props.addComprehensive.diagnosesEtc}<br>`
    );
  }

  let diagnosesDescriptionstr = [];
  props.checkedArray &&
    props.checkedArray.map((item, i) => {
      diagnosesDescriptionstr.push(item.name);
    });

  let MedicationStr = "";
  let iNo = 0;
  props.medicationList &&
    props.medicationList.map((item, i) => {
      console.log(item, i, " medication list");
      if (item.name.includes("Medication")) {
        MedicationStr = MedicationStr.concat(
          `<br><b>${item.name}</b> : ${item.value}`
        );
      } else if (item.name.includes("Description")) {
        MedicationStr = MedicationStr.concat(` - ${item.value}`);
        iNo = iNo + 1;
      } else {
        if (i == 0) {
          MedicationStr = MedicationStr.concat(`<br> ${item.name}`);
        } else {
          MedicationStr = MedicationStr.concat(`, ${item.name}`);
        }
      }
      // MedicationStr = MedicationStr.concat(`<b> ${i + 1}. ${item.name}</b> : ${item.value}<br>`);
    });

  let AllergiesStr = "";
  let allergiesValue = "";
  props.allergiesList &&
    props.allergiesList.map((item, i) => {
      item.value == 1
        ? (allergiesValue = item.name)
        : (allergiesValue = item.value);
      if (i == 0) {
        AllergiesStr = AllergiesStr.concat(`<br> ${allergiesValue}`);
      } else {
        AllergiesStr = AllergiesStr.concat(`, ${allergiesValue}`);
      }
    });
  console.log(props.allergiesList);

  let comprehensiveNoteStr = "";

  props.comprehensiveData.map((group) => {
    comprehensiveNoteStr = comprehensiveNoteStr.concat(`<b> ${
      group.groupName
    } </b ><br />
          ${group.questions.map((que) =>
            que.description ? que.description.toString() + "<br/>" : ""
          )}<br />
        `);
  });

  let diff = moment(props.patientInfo.dob).diff(moment(), "milliseconds");
  let duration = moment.duration(diff);
  let birthdate = duration._data.years
    ? Math.abs(duration._data.years)
    : duration._data.months
    ? Math.abs(duration._data.months) + " months"
    : duration._data.days
    ? Math.abs(duration._data.days) + " days"
    : 0;

  let firstName = props.patientInfo.firstName
    ? props.patientInfo.firstName
    : "";
  let lastName = props.patientInfo.lastName ? props.patientInfo.lastName : "";
  let gender = props.patientInfo.gender ? props.patientInfo.gender : "";

  comprehensiveNoteStr = comprehensiveNoteStr.replaceAll(
    "#Patient#",
    firstName + " " + lastName
  );
  comprehensiveNoteStr = comprehensiveNoteStr.replaceAll("#Gender#", gender);
  comprehensiveNoteStr = comprehensiveNoteStr.replaceAll("#Age#", birthdate);
  comprehensiveNoteStr = comprehensiveNoteStr.replaceAll(
    "#Diagnosis#",
    diagnosesstr
  );
  comprehensiveNoteStr = comprehensiveNoteStr.replaceAll(
    "#Procedure#",
    ProcedureStr
  );
  comprehensiveNoteStr = comprehensiveNoteStr.replaceAll(
    "#MedicationList#",
    MedicationStr
  );
  comprehensiveNoteStr = comprehensiveNoteStr.replaceAll(
    "#AllergiesList#",
    AllergiesStr || "N/A"
  );

  var regex = /<br\s*[\/]?>,/gi;
  var replacedcomprehensiveNoteStr = comprehensiveNoteStr.replaceAll(
    regex,
    " "
  );

  const patientAddress = [
    props.patientInfo.address1,
    props.patientInfo.address2,
    props.patientInfo.city,
    props.patientInfo.state,
    props.patientInfo.zip,
  ]
    .filter(Boolean)
    .join(", ");
  const officeAddress = [
    props.office.address,
    props.office.city,
    props.office.state,
    props.office.zip,
  ]
    .filter(Boolean)
    .join(", ");

  const note = `
                    <b> ${DOCTOR_NAME}:</b> ${props.doctorInfo.firstName} ${
    props.doctorInfo.lastName
  }
                    <br />
                    <b> ${NPI1}: </b> ${
    props.doctorInfo.npi1 ? props.doctorInfo.npi1 : DASH
  }
                    <br />
                    <b> ${NPI2}: </b> ${
    props.doctorInfo.npi2 ? props.doctorInfo.npi2 : DASH
  }
                    <br />
                    <b> ${TAXID}: </b> ${
    props.doctorInfo.taxId || props.doctorInfo.ssn
      ? props.doctorInfo.taxId || props.doctorInfo.ssn
      : DASH
  }
                    <br />
                    <b> ${LICENSE_NUMBER}:</b> ${
    props.doctorInfo.licenseNumber ? props.doctorInfo.licenseNumber : DASH
  }
                    <br />
                    <b> ${PTAN_NUMBER}:</b> ${
    props.doctorInfo.ptanNumber ? props.doctorInfo.ptanNumber : DASH
  }
                    <br />
                    <b> Address:</b> ${officeAddress ? officeAddress : DASH}
                    <br />
                    <b> Fax: 866-366-0902 </b>
                    <br />
                    <b> Contact for this auth:</b>
                    <br />
                    <br /><br />
                    <b> ${PATIENT_NAME}: </b> ${props.patientInfo.firstName} ${
    props.patientInfo.lastName
  }
                    <br />
                    <b> ${BIRTH_DATE}:</b> ${
    props.patientInfo.dob
      ? dateFormateMonthDateYear(props.patientInfo.dob)
      : DASH
  }
                    <br />
                    <b> ${ADDRESS}:</b> ${
    patientAddress ? patientAddress : DASH
  } <br />
                    <br />
                            <b>${PRIMARY_INSURANCE_NAME}:</b> ${
    props.insurance ? props.insurance : DASH
  }
                            <br />
                            <b>Patient ID:</b>
                            <br />
                            <b>Case Pending #:</b>
                            <br /><br />
                            ${
                              props.isCN
                                ? ""
                                : `I am writing on behalf of my patient, ${
                                    firstName + " " + lastName
                                  }, to document the medical necessity to treat their ${
                                    diagnosesDescriptionstr &&
                                    diagnosesDescriptionstr.length <= 0
                                      ? "diagnoses"
                                      : diagnosesDescriptionstr
                                  }. This letter serves to document my patient’s medical history and diagnosis and to summarize my treatment
rationale. <strong>This pre-auth request needs to be reviewed under the  patient's medical plan, as it is a medically necessary oral  surgery procedure (NOT dental). Please submit this medically  necessary pre-authorization. A gap exception is being  requested, as there is no provider in your medical network  who is able to do this service.</strong><br /><br />`
                            }
                            
    ${replacedcomprehensiveNoteStr}`;

  const [editorValue, setEditorValue] = useState(note);

  const handleEditorChange = (e) => {
    setEditorValue(e);
  };

  const sigCanvas = useRef({});
  const clear = () => {
    sigCanvas.current.clear();
    setImageURl(null);
  };

  const save = () => {
    setImageURl(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    setEditSign(true);
    setIsBase64Srt(true);
  };

  const handleCNSave = async () => {
    if (
      !imageURL ||
      imageURL ===
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC"
    ) {
      toast.error(ENTER_SIGNATURE);
    } else {
      await axios
        .post(`${baseURL}/${ADD_COMPREHENSIVE_NOTE}`, {
          headers: { authorization: token.token },
          note: editorValue,
          patientId: props.patientId,
          doctorId: props.doctorId,
          dateOfService: dateAPIFormate(props.addComprehensive.serviceDate),
          officeId: props.officeId,
          signatureImage: imageURL,
          cnDate: dateAPIFormate(CNDate),
          isBase64Srt: isBase64Srt,
          editSign: editSign,
          diagnosesId: JSON.stringify(props.checkedValues),
          procedure: JSON.stringify(props.procedureArray),
        })
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data.message);
            navigate("/ManageComprehensive");
            // setSaved(true);
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 500) {
              localStorage.clear();
              navigate("/login");
            } else {
              toast.error(err.response.data.message);
            }
          }
        });
    }
  };

  const handlePreAuthorizeSave = async () => {
    if (
      !imageURL ||
      imageURL ===
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC"
    ) {
      toast.error(ENTER_SIGNATURE);
    } else {
      await axios
        .post(`${baseURL}/${ADD_PRE_AUTHORIZATION_NOTE}`, {
          headers: { authorization: token.token },
          note: editorValue,
          patientId: props.patientId,
          doctorId: props.doctorId,
          dateOfService: dateAPIFormate(props.addComprehensive.serviceDate),
          officeId: props.officeId,
          signatureImage: imageURL,
          preAuthorizationDate: dateAPIFormate(CNDate),
          isBase64Srt: isBase64Srt,
          editSign: editSign,
        })
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data.message);
            navigate("/manage-preAuthorization-notes");
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 500) {
              localStorage.clear();
              navigate("/login");
            } else {
              toast.error(err.response.data.message);
            }
          }
        });
    }
  };

  const handleCancel = () => {
    navigate(
      props.isPreAuthorize
        ? "/manage-preAuthorization-notes"
        : "/ManageComprehensive"
    );
  };

  useEffect(() => {
    fetchDoctorData();
  }, []);

  return (
    <div className="row">
      <div className="col-md-12 col-sm-12">
        <div className="card card-box">
          <div className="card-head">
            <header>
              {props.isPreAuthorize
                ? PREAUTHORIZATION_INFORMATION
                : COMPREHENSIVE_INFORMATION}
            </header>
          </div>

          <div className="card-body">
            {props.isLoading || props.isCNLoading ? (
              <div className="form-body" style={{ height: "100px" }}>
                <div id="text">
                  <i className="fa fa-spinner fa-spin fa-2x"></i>
                </div>
              </div>
            ) : (
              <CKEditor
                config={{
                  height: "130",
                  removePlugins: [
                    "CKFinderUploadAdapter",
                    "CKFinder",
                    "EasyImage",
                    "Image",
                    "ImageCaption",
                    "ImageStyle",
                    "ImageToolbar",
                    "ImageUpload",
                    "MediaEmbed",
                  ],
                }}
                editor={ClassicEditor}
                id="editor1"
                onReady={(editor) => {
                  editor.setData(editorValue);
                }}
                onChange={(event, editor) => {
                  handleEditorChange(editor.getData());
                }}
              />
            )}
            <div>
              <br />
              <b>{DOCTOR_NAME}:</b> {props.doctorInfo.firstName}{" "}
              {props.doctorInfo.lastName}
              <br />
              <div className="row">
                <div className="col-md-1">
                  <b>{DATE}:</b>{" "}
                </div>
                <div className="col-md-3">
                  <DatePicker
                    className="form-control input-height mr-sm-2"
                    selected={CNDate}
                    placeholderText={"Please Select Date"}
                    maxDate={new Date()}
                    scrollableMonthDropdown={true}
                    showYearDropdown
                    showMonthDropdown
                    onChange={(date) => setCNDate(date)}
                  />
                </div>
              </div>
              <br />
              <div className="">
                <b className={`${imageURL ? "my-5" : ""}`}>Sign :</b>
                {isLoading ? (
                  ""
                ) : imageURL || signatureImage ? (
                  <img
                    className="mx-2 my-2"
                    src={imageURL ? imageURL : signatureImage}
                    alt="Sign"
                    style={{
                      display: "block",
                      height: "max-content",
                      width: "max-content",
                    }}
                  />
                ) : null}
              </div>
              <div
                className="mx-2"
                style={{ border: "1px solid", width: "fit-content" }}
              >
                <SignaturePad
                  ref={sigCanvas}
                  canvasProps={{
                    width: 300,
                    height: 150,
                    className: "signatureCanvas",
                  }}
                  penColor="blue"
                />
              </div>
              <button
                className="btn btn-outline-danger my-2 mx-2"
                onClick={clear}
                style={{ border: "1px solid" }}
              >
                {CLEAR}
              </button>
              <button
                className="btn btn-outline-success my-2 mx-2"
                onClick={save}
                style={{ border: "1px solid" }}
              >
                {PREVIEW}
              </button>
            </div>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-success mx-2"
                onClick={
                  props.isPreAuthorize ? handlePreAuthorizeSave : handleCNSave
                }
              >
                {SAVE}
              </button>
              <button className="btn btn-default mx-2" onClick={handleCancel}>
                {CANCEL}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComprehensiveResult;
