import { useEffect, useState } from "react";
import {
    ACCESSLEVEL,
    ADDRESS,
    ADD_OFFICE_EMPLOYEE,
    CITY,
    EMAIL,
    OFFICE_EMPLOYEE_INFORMATION,
    FIRST_NAME,
    HOME,
    LAST_NAME,
    STATE,
    STATUS,
    ADD,
    OFFICE_STATUS,
    PASSWORD,
    CONFIRM_PASSWORD,
    SELECT,
    CANCEL,
    ROLES,
    OFFICE,
    SELECT_OFFICE,
    OFFICE_EMPLOYEE_LIST,
    EDIT_OFFICE_EMPLOYEE,
    UPDATE,
} from "../../../Helper/Constants";

import {
    ENTER_FNAME,
    ENTER_EMAIL,
    SELECT_ACCESSLEVEL,
    SELECT_STATUS,
    ENTER_PASSWORD,
    ENTER_CONFIRMPASSWORD,
    PASSWORDANDCONFIRMPASSWORD,
    PASSWORD_ERROR_TEXT,
    PASSWORD_DOES_NOT_MATCH,
    ENTER_OFFICE_NAME,
} from "../../../Helper/Messages";
import axios from "axios";
import { baseURL } from "../../../Config";
import { toast } from "react-toastify";
import {
    CITY_LIST_WITHOUT_PAGINATION,
    DETAIL_OFFICE_EMPLOYEE_API,
    EDIT_OFFICE_ROLE_LIST_API,
    OFFICE_EMPLOYEE_ADD,
    OFFICE_EMPLOYEE_EDIT_API,
    OFFICE_LIST_WITHOUT_PAGINATION,
    STATE_LIST_WITHOUT_PAGINATION,
} from "../../../Helper/ApiPath";
import { NavLink, useNavigate } from "react-router-dom";
import Select from "react-select";
import Cookies from "universal-cookie";

function AddOfficeEmployee() {
    let token = JSON.parse(localStorage.getItem("logToken"));
    let officeEmployee = localStorage.getItem("officeEmployee");

    const cookies = new Cookies();

    let role = cookies.get("role");
    if (role) {
        role = cookies.get("role").roleId;
    }
    let adminRole = Number(localStorage.getItem("adminRole"));

    const [emailErr, setEmailErr] = useState(false);
    const [pwdError1, setPwdError1] = useState(false);
    const [pwdError2, setPwdError2] = useState(false);

    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const [confirmPasswordShown, setConPasswordShown] = useState(false);
    const toggleConfirmPassword = () => {
        setConPasswordShown(!confirmPasswordShown);
    };

    const navigate = useNavigate();

    const [employee, setEmployee] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        address1: "",
        pageAuthorityRoleId: "",
        roleId: "",
        statusId: "",
        agreement: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmployee({ ...employee, [name]: value });
    };

    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async () => {
        await axios
            .get(`${baseURL}/${DETAIL_OFFICE_EMPLOYEE_API}/${officeEmployee}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                if (res.status === 200) {
                    setEmployee(res.data.data);
                    setStateId(res.data.data.stateId);
                    setCityId(res.data.data.cityId);
                    setOfficeId(res.data.data.officeId);
                    if (res.data.data.stateId) {
                        cityData(res.data.data.stateId);
                    }
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validEmail = new RegExp("^[a-z0-9._:$!%-]+@[a-z0-9.-]+.[a-z]$");
        const validPassword = new RegExp("^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
        if (!officeId) {
            toast.error(ENTER_OFFICE_NAME);
        } else if (!employee.email) {
            toast.error(ENTER_EMAIL);
        } else if (!validEmail.test(employee.email)) {
            setEmailErr(true);
        } else if (!employee.password) {
            toast.error(ENTER_PASSWORD);
        } else if (!validPassword.test(employee.password)) {
            setPwdError1(true);
        } else if (!employee.confirmPassword) {
            toast.error(ENTER_CONFIRMPASSWORD);
        } else if (!validPassword.test(employee.confirmPassword)) {
            setPwdError2(true);
        } else if (employee.password !== employee.confirmPassword) {
            toast.error(PASSWORDANDCONFIRMPASSWORD);
        } else if (!employee.firstName) {
            toast.error(ENTER_FNAME);
        } else if (!employee.roleId || !employee.pageAuthorityRoleId) {
            toast.error(SELECT_ACCESSLEVEL);
        } else if (role != ROLES.DOCTOR && adminRole != ROLES.DOCTOR && !employee.statusId) {
            toast.error(SELECT_STATUS);
        } else {
            setIsLoading(true);
            await axios
                .post(`${baseURL}/${OFFICE_EMPLOYEE_ADD}`, {
                    headers: { authorization: token.token },
                    officeId: role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? localStorage.getItem("officeId") : officeId,
                    roleId: employee.roleId,
                    pageAuthorityRoleId: employee.pageAuthorityRoleId,
                    email: employee.email,
                    password: employee.password,
                    firstName: employee.firstName,
                    lastName: employee.lastName,
                    address1: employee.address1,
                    state: stateId,
                    city: cityId,
                    statusId: employee.statusId,
                    isAdmin: (role == ROLES.ADMIN || role == ROLES.EMPLOYEE_ADMIN) && (adminRole == ROLES.ADMIN || adminRole == ROLES.EMPLOYEE_ADMIN) ? true : false,
                })
                .then((res) => {
                    if (res.status === 200) {
                        localStorage.removeItem("officeEmployee");
                        setEmployee("");
                        setIsLoading(false);
                        toast.success(res.data.message);
                        navigate("/manageOfficeEmployee");
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        if (!officeId) {
            toast.error(ENTER_OFFICE_NAME);
        } else if (!employee.firstName) {
            toast.error(ENTER_FNAME);
        } else if (!employee.pageAuthorityRoleId) {
            toast.error(SELECT_ACCESSLEVEL);
        } else if (role != ROLES.DOCTOR && adminRole != ROLES.DOCTOR && !employee.statusId) {
            toast.error(SELECT_STATUS);
        } else {
            setIsLoading(true);
            await axios
                .post(`${baseURL}/${OFFICE_EMPLOYEE_EDIT_API}`, {
                    headers: { authorization: token.token },
                    doctorId: officeEmployee,
                    officeId: role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? localStorage.getItem("officeId") : officeId,
                    roleId: employee.roleId,
                    pageAuthorityRoleId: employee.pageAuthorityRoleId,
                    firstName: employee.firstName,
                    lastName: employee.lastName,
                    address1: employee.address1,
                    state: stateId,
                    city: cityId,
                    statusId: employee.statusId,
                    isAdmin: (role == ROLES.ADMIN || role == ROLES.EMPLOYEE_ADMIN) && (adminRole == ROLES.ADMIN || adminRole == ROLES.EMPLOYEE_ADMIN) ? true : false,
                })
                .then((res) => {
                    if (res.status === 200) {
                        localStorage.removeItem("officeEmployee");
                        setEmployee("");
                        setIsLoading(false);
                        toast.success(res.data.message);
                        navigate("/manageOfficeEmployee");
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const handleCancel = () => {
        navigate("/manageOfficeEmployee");
    };

    const [accessLevel, setAccessLevel] = useState([]);

    const fetchAccessLevel = async () => {
        await axios
            .post(`${baseURL}/${EDIT_OFFICE_ROLE_LIST_API}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                setIsLoading(false);
                setAccessLevel(res.data.data);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const accessLevelOption =
        accessLevel &&
        accessLevel.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
                parentId: item.parentId,
            };
        });

    const handleAccessLevelChange = (e) => {
        setEmployee({ ...employee, roleId: e.parentId, pageAuthorityRoleId: e.value });
    };

    const [officeId, setOfficeId] = useState(role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? localStorage.getItem("officeId") : "");
    const [officeList, setOfficeList] = useState([]);

    const officeListData = () => {
        axios
            .post(`${baseURL}/${OFFICE_LIST_WITHOUT_PAGINATION}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                setOfficeList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const officeOption =
        officeList &&
        officeList.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const handleOfficeChange = (e) => {
        setOfficeId(e.value);
    };

    const [stateList, setStateList] = useState([]);
    const [stateId, setStateId] = useState("");

    const stateData = async () => {
        await axios
            .post(`${baseURL}/${STATE_LIST_WITHOUT_PAGINATION}`)
            .then((res) => {
                setStateList(res.data.data);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };
    const stateOptions =
        stateList &&
        stateList.map((item, i) => {
            return {
                id: item.id,
                value: item.id,
                label: item.name,
            };
        });
    const handleStateChange = (e) => {
        setStateId(e.value);
        cityData(e.value);
    };

    const [cityList, setCityList] = useState([]);
    const [cityId, setCityId] = useState("");

    const cityData = async (stateId) => {
        axios
            .post(`${baseURL}/${CITY_LIST_WITHOUT_PAGINATION}`, {
                stateId: stateId,
                search: "",
            })
            .then((res) => {
                setCityList(res.data.data);
            });
    };
    const cityOptions =
        cityList &&
        cityList.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });
    const handleCityChange = (e) => {
        setCityId(e.value);
    };

    useEffect(() => {
        if (role != ROLES.DOCTOR || adminRole != ROLES.DOCTOR) {
            officeListData();
        }
        fetchAccessLevel();
        stateData();
        if (officeEmployee) {
            fetchData();
        }
    }, []);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{officeEmployee ? EDIT_OFFICE_EMPLOYEE : ADD_OFFICE_EMPLOYEE}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li>
                                    <i className="fa fa-users"></i>&nbsp;
                                    <NavLink className="parent-item" to="/manageOfficeEmployee">
                                        {OFFICE_EMPLOYEE_LIST}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{officeEmployee ? EDIT_OFFICE_EMPLOYEE : ADD_OFFICE_EMPLOYEE}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="card card-box">
                                <div className="card-head">
                                    <header>{OFFICE_EMPLOYEE_INFORMATION}</header>
                                </div>
                                <div className="card-body" id="bar-parent">
                                    <div action="#" id="form_sample_1" className="form-horizontal">
                                        <div className="form-body">
                                            {role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? (
                                                ""
                                            ) : (
                                                <div className="form-group row">
                                                    <label className="control-label col-md-3">
                                                        {OFFICE}
                                                        <span className="required"> * </span>
                                                    </label>
                                                    <div className="col-md-5">
                                                        <Select
                                                            name="office"
                                                            options={officeOption}
                                                            onChange={handleOfficeChange}
                                                            value={officeOption && officeOption.filter(({ value }) => value == officeId)}
                                                            placeholder={SELECT_OFFICE}
                                                        ></Select>
                                                    </div>
                                                </div>
                                            )}
                                            {officeEmployee ? (
                                                ""
                                            ) : (
                                                <>
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-3">
                                                            {EMAIL}
                                                            <span className="required"> * </span>
                                                        </label>
                                                        <div className="col-md-5">
                                                            <input
                                                                type="email"
                                                                name="email"
                                                                value={employee && employee.email}
                                                                onChange={handleChange}
                                                                placeholder="Enter Email"
                                                                className="form-control input-height"
                                                            />
                                                            {emailErr ? (
                                                                <p className="txtError" style={{ fontSize: "12px" }}>
                                                                    Your Email is like example@gmail.com
                                                                </p>
                                                            ) : (
                                                                <p style={{ fontSize: "12px" }}>Your Email is like example@gmail.com</p>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="control-label col-md-3">
                                                            {PASSWORD}
                                                            <span className="required"> * </span>
                                                        </label>
                                                        <div className="col-md-5">
                                                            <div style={{ display: "flex" }}>
                                                                <input
                                                                    type={passwordShown ? "text" : "password"}
                                                                    name="password"
                                                                    className="form-control input-height"
                                                                    value={employee && employee.password}
                                                                    onChange={handleChange}
                                                                    placeholder="Enter Password"
                                                                />
                                                                <i
                                                                    className={passwordShown ? "input-group-text input-height fa fa-eye" : "input-group-text input-height fa fa-eye-slash"}
                                                                    id="basic-addon1"
                                                                    onClick={togglePassword}
                                                                ></i>
                                                            </div>
                                                            {pwdError1 === false ? <p style={{ fontSize: "12px" }}> {PASSWORD_ERROR_TEXT}</p> : <p className="txtError">{PASSWORD_ERROR_TEXT}</p>}
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="control-label col-md-3">
                                                            {CONFIRM_PASSWORD}
                                                            <span className="required"> * </span>
                                                        </label>
                                                        <div className="col-md-5">
                                                            <div style={{ display: "flex" }}>
                                                                <input
                                                                    type={confirmPasswordShown ? "text" : "password"}
                                                                    name="confirmPassword"
                                                                    className="form-control input-height"
                                                                    value={employee && employee.confirmPassword}
                                                                    onChange={handleChange}
                                                                    placeholder="Enter Password"
                                                                />

                                                                <i
                                                                    className={confirmPasswordShown ? "input-group-text input-height fa fa-eye" : "input-group-text input-height fa fa-eye-slash"}
                                                                    id="basic-addon1"
                                                                    onClick={toggleConfirmPassword}
                                                                ></i>
                                                            </div>
                                                            {pwdError2 && <p className="txtError">{PASSWORD_DOES_NOT_MATCH}</p>}
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {FIRST_NAME}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="firstName"
                                                        value={employee && employee.firstName}
                                                        onChange={handleChange}
                                                        data-required="1"
                                                        placeholder="Enter First Name"
                                                        className="form-control input-height"
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{LAST_NAME}</label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="lastName"
                                                        value={employee && employee.lastName}
                                                        onChange={handleChange}
                                                        placeholder="Enter Last Name"
                                                        className="form-control input-height"
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{ADDRESS}</label>
                                                <div className="col-md-5">
                                                    <textarea
                                                        name="address1"
                                                        value={employee && employee.address1}
                                                        onChange={handleChange}
                                                        placeholder="Address"
                                                        className="form-control form-control-textarea"
                                                        rows="3"
                                                    ></textarea>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{STATE}</label>
                                                <div className="col-md-5">
                                                    {stateId ? (
                                                        <Select
                                                            name="stateId"
                                                            value={stateOptions && stateOptions.filter(({ value }) => value == stateId)}
                                                            options={stateOptions}
                                                            onChange={handleStateChange}
                                                            placeholder={SELECT}
                                                        />
                                                    ) : (
                                                        <Select name="stateId" options={stateOptions} onChange={handleStateChange} placeholder={SELECT} />
                                                    )}
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{CITY}</label>
                                                <div className="col-md-5">
                                                    <Select
                                                        name="cityId"
                                                        value={cityOptions && cityOptions.filter(({ value }) => value == cityId)}
                                                        options={cityOptions}
                                                        onChange={handleCityChange}
                                                        placeholder={SELECT}
                                                    ></Select>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {ACCESSLEVEL}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    {employee.pageAuthorityRoleId ? (
                                                        <Select
                                                            name="pageAuthorityRoleId"
                                                            value={accessLevelOption && accessLevelOption.filter(({ value }) => value == employee.pageAuthorityRoleId)}
                                                            options={accessLevelOption}
                                                            onChange={handleAccessLevelChange}
                                                            placeholder={SELECT}
                                                        />
                                                    ) : (
                                                        <Select
                                                            name="roleId"
                                                            value={accessLevelOption && accessLevelOption.filter(({ parentId }) => parentId == employee.pageAuthorityRoleId)}
                                                            options={accessLevelOption}
                                                            onChange={handleAccessLevelChange}
                                                            placeholder={SELECT}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            {role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? (
                                                ""
                                            ) : (
                                                <div className="form-group row">
                                                    <label className="control-label col-md-3">
                                                        {STATUS}
                                                        <span className="required"> * </span>
                                                    </label>
                                                    <div className="col-md-5">
                                                        <select className="form-control input-height" name="statusId" value={employee && employee.statusId} onChange={handleChange}>
                                                            <option value=""> {SELECT} </option>
                                                            {Object.keys(OFFICE_STATUS).map((item, i) => {
                                                                return (
                                                                    <option key={i} value={OFFICE_STATUS[item]}>
                                                                        {item}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            )}

                                            <div className="form-actions">
                                                <div className="row">
                                                    <div className="offset-md-3 col-md-9">
                                                        {isLoading ? (
                                                            <>
                                                                <button type="submit" className="btn btn-info m-r-20" disabled>
                                                                    {ADD}
                                                                </button>
                                                                <i className="fa fa-spinner fa-spin fa-2x loaderBtnSpinner"></i>
                                                            </>
                                                        ) : (
                                                            <button type="submit" className="btn btn-info m-r-20" onClick={officeEmployee ? handleEditSubmit : handleSubmit}>
                                                                {officeEmployee ? UPDATE : ADD}
                                                            </button>
                                                        )}

                                                        <button type="button" className="btn btn-default  m-r-20" onClick={handleCancel}>
                                                            {CANCEL}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddOfficeEmployee;
