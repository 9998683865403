import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  ADD_DOCTOR,
  ADD_EOB,
  ADD_OFFICE,
  ADD_TRIAL,
  ALL_CLAIM,
  CLAIM,
  COMPREHENSIVE_NOTES,
  CONFIGURATION,
  DASHBOARD,
  DOCTOR,
  DOCTOR_LIST,
  ELIGIBILITY_CHECK,
  EMPLOYEE,
  EMPLOYEE_LIST,
  EOB,
  EOB_LIST,
  RECEIPT,
  RECEIPT_LIST,
  MANAGE_DIAGNOSES,
  MANAGE_NOTICE,
  MANAGE_PAYER,
  MANAGE_PROCEDURE,
  MANAGE_ROLES,
  MANAGE_STATE_CITY,
  SPECIALTY_CPTGROUP,
  NOTES,
  OFFICE,
  OFFICE_LIST,
  OP_NOTES,
  PATIENT,
  PATIENT_CHART,
  PRACTICE,
  PRACTICE_LIST,
  QUESTION_LIST,
  TAB_LIST,
  TRIAL,
  TRIAL_LIST,
  COMPREHENSIVE_DESCRIPTION,
  COMPREHENSIVE_GROUP,
  SUPPORT,
  DOCUMENT_LIST,
  ADD_DOCUMENT,
  PRE_AUTHORIZATION_NOTES,
  ALL_PATIENT,
  OFFICE_EMPLOYEE_LIST,
  OFFICE_EMPLOYEE,
} from "../../Helper/Constants";
import Cookies from "universal-cookie";

function AdminSideBar(props) {
  let navigate = useNavigate();
  const email = JSON.parse(localStorage.getItem("logToken")).email;
  const cookies = new Cookies();

  let role = cookies.get("role");
  if (role) {
    role = cookies.get("role").roleId;
  }

  const claimDoctorId = sessionStorage.getItem("claimDoctorId");
  const claimPatientId = sessionStorage.getItem("claimPatientId");
  const preAuthDoctorId = sessionStorage.getItem("preAuthDoctorId");
  const claimPagination = sessionStorage.getItem("claimPagination");
  const opNotePagination = sessionStorage.getItem("opNotePagination");
  const cnPagination = sessionStorage.getItem("cnPagination");
  const preAuthPagination = sessionStorage.getItem("preAuthPagination");
  const patientPagination = sessionStorage.getItem("patientPagination");
  const officePagination = sessionStorage.getItem("officePagination");
  const eobPagination = sessionStorage.getItem("eobPagination");
  const receiptPagination = sessionStorage.getItem("receiptPagination");
  const doctorPagination = sessionStorage.getItem("doctorPagination");
  const postOpPagination = sessionStorage.getItem("postOpPagination");

  const handleDoctorList = () => {
    if (doctorPagination) {
      sessionStorage.removeItem("doctorPagination");
      navigate("/manageDoctor");
      window.location.reload();
    }
  };

  const handleDoctorNotesList = () => {
    if (doctorPagination) {
      sessionStorage.removeItem("doctorPagination");
      navigate("/manageDoctor");
      window.location.reload();
    }
  };

  const handlePostOpNotesList = () => {
    if (postOpPagination) {
      sessionStorage.removeItem("postOpPagination");
      navigate("/managePostOpNotes");
      window.location.reload();
    }
  };

  const handleEOBList = () => {
    if (eobPagination) {
      sessionStorage.removeItem("eobPagination");
      navigate("/manageEOB");
      window.location.reload();
    }
  };

  const handleReceiptList = () => {
    if (receiptPagination) {
      sessionStorage.removeItem("receiptPagination");
      navigate("/manageReceipt");
      window.location.reload();
    }
  };

  const handleOfficeList = () => {
    if (officePagination) {
      sessionStorage.removeItem("officePagination");
      navigate("/manageOffice");
      window.location.reload();
    }
  };

  const handlePatientList = () => {
    if (patientPagination) {
      sessionStorage.removeItem("patientPagination");
      navigate("/managePatient");
      window.location.reload();
    }
  };

  const handleClaimList = () => {
    if (claimDoctorId || claimPatientId || claimPagination) {
      sessionStorage.removeItem("claimDoctorId");
      sessionStorage.removeItem("claimPatientId");
      sessionStorage.removeItem("claimPagination");
      navigate("/manageClaim");
      window.location.reload();
    }
  };

  const handleOpNotesList = () => {
    if (opNotePagination) {
      sessionStorage.removeItem("opNotePagination");
      navigate("/manageOpNotes");
      window.location.reload();
    }
  };

  const handlePreAuthorizationList = () => {
    if (preAuthDoctorId || preAuthPagination) {
      sessionStorage.removeItem("preAuthDoctorId");
      sessionStorage.removeItem("preAuthPagination");
      navigate("/manage-preAuthorization-notes");
      window.location.reload();
    }
  };

  const handleCnList = () => {
    if (cnPagination) {
      sessionStorage.removeItem("cnPagination");
      navigate("/ManageComprehensive");
      window.location.reload();
    }
  };

  return (
    <>
      <div className="sidebar-container">
        <div className="sidemenu-container navbar-collapse collapse fixed-menu">
          <div id="remove-scroll" className="left-sidemenu">
            <ul
              className="sidemenu  page-header-fixed slimscroll-style"
              data-keep-expanded="false"
              data-auto-scroll="true"
              data-slide-speed="200"
              style={{ paddingTop: "20px" }}
            >
              <li className="sidebar-toggler-wrapper hide">
                <div className="sidebar-toggler">
                  <span></span>
                </div>
              </li>

              <li className="sidebar-user-panel">
                <div className="user-panel d-flex justify-content-center">
                  <div className="text-center image">
                    <img
                      src="assets/img/logo/login-logo.png"
                      className="img-circle user-img-circle"
                      alt="User-img"
                    />
                  </div>
                </div>
                <div className="info">
                  <p className="text-center text-white">{email}</p>
                </div>
              </li>
              <li className="nav-item ">
                <NavLink to="/dashboard" className="nav-link">
                  <i className="material-icons">dashboard</i>
                  <span className="title">{DASHBOARD}</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-toggle ">
                  {" "}
                  <i className="fa fa-user-md"></i>
                  <span className="title">{DOCTOR}</span>{" "}
                  <span className="arrow"></span>
                </a>
                <ul className="sub-menu">
                  <li className="nav-item">
                    <NavLink
                      to="/manageDoctor"
                      className="nav-link "
                      onClick={handleDoctorList}
                    >
                      <span className="title">{DOCTOR_LIST}</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/registerDoctor" className="nav-link ">
                      <span className="title">{ADD_DOCTOR}a</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-toggle">
                  {" "}
                  <i className="fa fa-building"></i>
                  <span className="title">{OFFICE}</span>{" "}
                  <span className="arrow"></span>
                </a>
                <ul className="sub-menu">
                  <li className="nav-item">
                    <NavLink
                      to="/manageOffice"
                      className="nav-link nav-toggle"
                      onClick={handleOfficeList}
                    >
                      <span className="title">{OFFICE_LIST}</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/addOffice" className="nav-link nav-toggle">
                      <span className="title">{ADD_OFFICE}</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-toggle">
                  {" "}
                  <i className="material-icons">accessible</i>
                  <span className="title">{PATIENT}</span>{" "}
                  <span className="arrow"></span>
                </a>
                <ul className="sub-menu">
                  <li className="nav-item  ">
                    <NavLink
                      to="/managePatient"
                      className="nav-link "
                      onClick={handlePatientList}
                    >
                      {" "}
                      <span className="title">{ALL_PATIENT}</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/checkPatientEligibility"
                      className="nav-link nav-toggle"
                    >
                      <span className="title">{ELIGIBILITY_CHECK}</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-toggle">
                  {" "}
                  <i className="fa fa-users"></i>
                  <span className="title">{EMPLOYEE}</span>{" "}
                  <span className="arrow"></span>
                </a>
                <ul className="sub-menu">
                  <li className="nav-item">
                    <NavLink
                      to="/manageEmployee"
                      className="nav-link nav-toggle"
                    >
                      <span className="title">{EMPLOYEE_LIST}</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-toggle">
                  {" "}
                  <i className="fa fa-users"></i>
                  <span className="title">{OFFICE_EMPLOYEE}</span>{" "}
                  <span className="arrow"></span>
                </a>
                <ul className="sub-menu">
                  <li className="nav-item">
                    <NavLink
                      to="/manageOfficeEmployee"
                      className="nav-link nav-toggle"
                    >
                      <span className="title">{OFFICE_EMPLOYEE_LIST}</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-toggle">
                  <i className="fa fa-folder-open"></i>
                  <span className="title">Change Request</span>{" "}
                  <span className="arrow"></span>
                </a>
                <ul className="sub-menu">
                  <li className="nav-item">
                    <NavLink to="/requestData" className="nav-link nav-toggle">
                      <span className="title">Request List</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-toggle">
                  {" "}
                  <i className="fa fa-stethoscope"></i>
                  <span className="title">{PRACTICE}</span>{" "}
                  <span className="arrow"></span>
                </a>
                <ul className="sub-menu">
                  <li className="nav-item">
                    <NavLink
                      to="/managePractice"
                      className="nav-link nav-toggle"
                    >
                      <span className="title">{PRACTICE_LIST}</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-toggle">
                  <i className="fa fa-id-card"></i>
                  <span className="title">{CLAIM}</span>{" "}
                  <span className="arrow"></span>
                </a>
                <ul className="sub-menu">
                  <li className="nav-item">
                    <NavLink
                      to="/manageClaim"
                      className="nav-link nav-toggle"
                      onClick={handleClaimList}
                    >
                      <span className="title">{ALL_CLAIM}</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-toggle">
                  {" "}
                  <i className="fa fa-user-secret"></i>
                  <span className="title">{TRIAL}</span>{" "}
                  <span className="arrow"></span>
                </a>
                <ul className="sub-menu">
                  <li className="nav-item">
                    <NavLink to="/manageTrial" className="nav-link nav-toggle">
                      <span className="title">{TRIAL_LIST}</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/addTrial" className="nav-link nav-toggle">
                      <span className="title">{ADD_TRIAL}</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-toggle">
                  {" "}
                  <i className="fa fa-th-list" aria-hidden="true"></i>
                  <span className="title">{EOB}</span>{" "}
                  <span className="arrow"></span>
                </a>
                <ul className="sub-menu">
                  <li className="nav-item">
                    <NavLink
                      to="/manageEOB"
                      className="nav-link nav-toggle"
                      onClick={handleEOBList}
                    >
                      <span className="title">{EOB_LIST}</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/addEOB" className="nav-link nav-toggle">
                      <span className="title">{ADD_EOB}</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-toggle">
                  {" "}
                  <i className="fa fa-sticky-note" aria-hidden="true"></i>
                  <span className="title">{NOTES}</span>{" "}
                  <span className="arrow"></span>
                </a>
                <ul className="sub-menu">
                  <li className="nav-item">
                    <NavLink
                      to="/manage-preAuthorization-notes"
                      className="nav-link nav-toggle"
                      onClick={handlePreAuthorizationList}
                    >
                      <span className="title">{PRE_AUTHORIZATION_NOTES}</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/ManageComprehensive"
                      className="nav-link nav-toggle"
                      onClick={handleCnList}
                    >
                      <span className="title">{COMPREHENSIVE_NOTES}</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/manageOpNotes"
                      className="nav-link nav-toggle"
                      onClick={handleOpNotesList}
                    >
                      <span className="title">{OP_NOTES}</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/manageDoctorNotes"
                      className="nav-link nav-toggle"
                      onClick={handleDoctorNotesList}
                    >
                      <span className="title">{"Doctor's Note Count"}</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/managePostOpNotes"
                      className="nav-link nav-toggle"
                      onClick={handlePostOpNotesList}
                    >
                      <span className="title">{"Post Op/Progress Notes"}</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-toggle">
                  {" "}
                  <i className="fa fa-file-text" aria-hidden="true"></i>
                  <span className="title">{SUPPORT}</span>{" "}
                  <span className="arrow"></span>
                </a>
                <ul className="sub-menu">
                  <li className="nav-item">
                    <NavLink
                      to="/manageDocument"
                      className="nav-link nav-toggle"
                    >
                      <span className="title">{DOCUMENT_LIST}</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/addDocument" className="nav-link nav-toggle">
                      <span className="title">{ADD_DOCUMENT}</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-toggle">
                  {" "}
                  <i className="fa fa-th-list" aria-hidden="true"></i>
                  <span className="title">{RECEIPT}</span>{" "}
                  <span className="arrow"></span>
                </a>
                <ul className="sub-menu">
                  <li className="nav-item">
                    <NavLink
                      to="/manageReceipt"
                      className="nav-link nav-toggle"
                      onClick={handleReceiptList}
                    >
                      <span className="title">{RECEIPT_LIST}</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-toggle">
                  {" "}
                  <i className="fa fa-globe"></i>
                  <span className="title">{CONFIGURATION}</span>{" "}
                  <span className="arrow"></span>
                </a>
                <ul className="sub-menu">
                  <li className="nav-item">
                    <NavLink
                      to="/configuration"
                      className="nav-link nav-toggle"
                    >
                      <span className="title">{MANAGE_STATE_CITY}</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/manage-diagnoses"
                      className="nav-link nav-toggle"
                    >
                      <span className="title">{MANAGE_DIAGNOSES}</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/manage-payer" className="nav-link nav-toggle">
                      <span className="title">{MANAGE_PAYER}</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/manage-procedure"
                      className="nav-link nav-toggle"
                    >
                      <span className="title">{MANAGE_PROCEDURE}</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/manage-notice"
                      className="nav-link nav-toggle"
                    >
                      <span className="title">{MANAGE_NOTICE}</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/manage-spclty-cptgrp"
                      className="nav-link nav-toggle"
                    >
                      <span className="title">{SPECIALTY_CPTGROUP}</span>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/manage-roles" className="nav-link nav-toggle">
                      <span className="title">{MANAGE_ROLES}</span>
                    </NavLink>
                  </li>
                  {/* ////////////////////////////////  PATIENT CHART //////////////////////////////*/}

                  <li className="nav-item">
                    <a className="nav-link nav-toggle ">
                      <span className="title">{PATIENT_CHART}</span>{" "}
                      <span className="arrow"></span>
                    </a>
                    <ul className="sub-menu">
                      <li className="nav-item">
                        <NavLink to="/manage-tabs" className="nav-link ">
                          <span className="title">{TAB_LIST}</span>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/manage-questions" className="nav-link ">
                          <span className="title">{QUESTION_LIST}</span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  {/* ////////////////////////////////  Comprehensive Notes //////////////////////////////*/}
                  <li className="nav-item">
                    <a className="nav-link nav-toggle ">
                      <span className="title">{NOTES}</span>{" "}
                      <span className="arrow"></span>
                    </a>
                    <ul className="sub-menu">
                      <li className="nav-item">
                        <NavLink to="/manageJsonGroup" className="nav-link ">
                          <span className="title">{COMPREHENSIVE_GROUP}</span>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/ManageJsonQuestion" className="nav-link ">
                          <span className="title">
                            {COMPREHENSIVE_DESCRIPTION}
                          </span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminSideBar;
