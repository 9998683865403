import axios from "axios";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { baseURL, imageSize_file } from "../../../Config";
import { UPLOAD_PATIENT_DOCUMENT } from "../../../Helper/ApiPath";
import { ADD, CANCEL, ROLES, UPDATE } from "../../../Helper/Constants";
import { UPLOAD_IMAGE, EXCEED_UPLOAD_SIZE_MAX_FILE } from "../../../Helper/Messages";

function UploadImage(props) {
    let navigate = useNavigate();
    const cookies = new Cookies();
    let logToken = JSON.parse(localStorage.getItem("logToken"));
    let role = cookies.get("role");
    if (role) {
        role = cookies.get("role").roleId;
    }
    let adminRole = localStorage.getItem("adminRole");

    const handleUpload = async () => {
        let formData = new FormData();

        if (!props.image.data) {
            toast.error(UPLOAD_IMAGE);
        } else if (props.image.data.size > imageSize_file) {
            toast.error(EXCEED_UPLOAD_SIZE_MAX_FILE);
        } else {
            formData.append("image", props.image.data);
            formData.append("fileName", props.image.name);
            formData.append("patientId", props.patientId);
            formData.append("type", props.imageType);
            if (props.edit) {
                formData.append("patientDocumentId", props.documentId);
            }
            if ((props.isPreAuthorization || (props.edit && props.preAuthId)) && (role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR)) {
                formData.append("isDoctor", role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? true : false);
                formData.append("isPreAuthorization", props.isPreAuthorization);
                formData.append("preAuthId", props.preAuthId);
                formData.append("statusId", props.statusId);
            }
            await axios
                .post(`${baseURL}/${UPLOAD_PATIENT_DOCUMENT}`, formData, {
                    headers: { authorization: logToken.token, "Content-Type": "multipart/form-data" },
                })
                .then((res) => {
                    props.fetchThumbnailData(props.type, props.patientId);
                    toast.success(res.data.message);
                    if (props.isPreAuthorization || (props.edit && props.preAuthId)) {
                        props.fetchData();
                    }
                    props.handleClose();
                    let element = document.getElementById("insuranceF");
                    element.classList.add("d-none");
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };
    return (
        <>
            <Modal show={props.show} onHide={props.handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.popUpTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="offset-3 col-md-6">
                            <input type="file" className="form-control" name="image" onChange={props.handleImageChange} />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="primary d-none" id="button" onClick={handleUpload}>
                        {props.edit ? UPDATE : ADD}
                    </Button>
                    <Button variant="secondary" onClick={props.handleClose}>
                        {CANCEL}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default UploadImage;
