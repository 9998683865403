import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { dateAPIFormate, minYear } from "../../Helper/Helper";
import {
  FIRST_NAME,
  GENDER,
  LAST_NAME,
  ADDRESS,
  ADDRESS_COUNTINUED,
  STATE,
  ZIP,
  CITY,
  BIRTH_DATE,
  SOCIAL_SECURITY_NUMBER,
  IS_MEDICARE_ELIGIBLE,
  MEDICARE_ELIGIBILITY_STARTS_AT,
  MEDICARE_PATIENT_ID,
  PPO_ADVANTAGE_PLAN_PHONE,
  PPO_ADVANTAGE_PAYER_ID,
  PPO_ADVANTAGE_PAYER_ADDRESS,
  IS_MEDICARE_ELIGIBLE_OPTION,
  CANCEL,
  SAVE,
  PATIENT_INFO,
  CREATE_PATIENT,
  HOME,
  SELECT,
  SELECT_GENDER,
  PPO_ADVANTAGE_PLAN_NUMBER,
  PPO_ADVANTAGE_PLAN_SPONSOR,
  HMO_ADVANTAGE_PLAN_NUMBER,
  HMO_ADVANTAGE_PLAN_SPONSOR,
  HMO_ADVANTAGE_PAYER_ID,
  HMO_ADVANTAGE_PAYER_ADDRESS,
  HMO_ADVANTAGE_PLAN_PHONE,
  PATIENT_LIST,
  DATE_OF_LAST_EXAM,
  PHYSICIAN_NAME,
  WEIGHT,
  HEIGHT_IN_INCHIES,
  REASON_FOR_TOADAY_VISIT,
  GENERAL_HEALTH,
  GENERAL_HEALTH_OPTIONS,
  PATIENT_TAB,
} from "../../Helper/Constants";
import { baseURL } from "../../Config";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import CreatableSelect from "react-select/creatable";
import {
  CITY_LIST_WITHOUT_PAGINATION,
  PATIENT_ADD_WITHOUT_ELIGIBILITY,
  STATE_LIST_WITHOUT_PAGINATION,
} from "../../Helper/ApiPath";
import {
  ENTER_ADDRESS,
  ENTER_CITY,
  ENTER_DOB,
  ENTER_FNAME,
  ENTER_LNAME,
  ENTER_STATE,
  ENTER_ZIP,
} from "../../Helper/Messages";
import { NavLink, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Tab, Tabs } from "react-bootstrap";

function AddPatient() {
  const [isLoading, setIsLoading] = useState(false);
  const [city, setCity] = useState({
    stateId: "",
    cityId: "",
  });

  const handleCityChange = (e) => {
    setCity({ ...city, cityId: e.value });
  };

  const navigate = useNavigate();
  let doctorToken = JSON.parse(localStorage.getItem("DoctorToken"));
  let doctorPracticeId = localStorage.getItem("doctorPracticeId");

  const [patientList, setPatientList] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    address1: "",
    address2: "",
    zip: "",
    dob: "",
    gender: "",
    socialSecurityNo: "",
    height: "",
    weight: "",
    physicianName: "",
    dateOfLastExam: "",

    medicareEligible: "",
    madicareEligibilityStartAt: "",
    MedicarePatientId: "",

    PPOInsuranceName: "",
    PPOInsuranceNumber: "",
    PPOPayerId: "",
    PPOPayerAddress: "",
    PPOPhone: "",

    HMOInsuranceName: "",
    HMOInsuranceNumber: "",
    HMOPayerId: "",
    HMOPayerAddress: "",
    HMOPhone: "",

    ReasonForTodayVisit: "",
    GeneralHealth: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPatientList({ ...patientList, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!patientList.firstName) {
      toast.error(ENTER_FNAME);
    } else if (!patientList.lastName) {
      toast.error(ENTER_LNAME);
    } else if (!patientList.address1) {
      toast.error(ENTER_ADDRESS);
    } else if (!city.stateId) {
      toast.error(ENTER_STATE);
    } else if (!city.cityId) {
      toast.error(ENTER_CITY);
    } else if (!patientList.zip) {
      toast.error(ENTER_ZIP);
    } else if (!patientList.dob) {
      toast.error(ENTER_DOB);
    } else {
      setIsLoading(true);
      await axios
        .post(`${baseURL}/${PATIENT_ADD_WITHOUT_ELIGIBILITY}`, {
          headers: { authorization: doctorToken },
          practiceId: doctorPracticeId,
          firstName: patientList.firstName,
          middleName: patientList.middleName,
          lastName: patientList.lastName,
          gender: patientList.gender,
          dob: dateAPIFormate(patientList.dob),
          address1: patientList.address1,
          address2: patientList.address2,
          state: city.stateId,
          city: city.cityId,
          zip: patientList.zip,
          socialSecurityNo: patientList.socialSecurityNo,

          height: patientList.height,
          weight: patientList.weight,
          physicianName: patientList.physicianName,
          dateOfLastExam: dateAPIFormate(patientList.dateOfLastExam),

          madicareEligibilityStartAt: dateAPIFormate(
            patientList.madicareEligibilityStartAt
          ),
          MedicarePatientId: patientList.MedicarePatientId,

          PPOInsuranceName: patientList.PPOInsuranceName,
          PPOInsuranceNumber: patientList.PPOInsuranceNumber,
          PPOPayerId: patientList.PPOPayerId,
          PPOPayerAddress: patientList.PPOPayerAddress,
          PPOPhone: patientList.PPOPhone,

          HMOInsuranceName: patientList.PPOInsuranceName,
          HMOInsuranceNumber: patientList.PPOInsuranceNumber,
          HMOPayerId: patientList.PPOPayerId,
          HMOPayerAddress: patientList.PPOPayerAddress,
          HMOPhone: patientList.PPOPhone,

          ReasonForTodayVisit: patientList.ReasonForTodayVisit,
          GeneralHealth: patientList.GeneralHealth,
        })
        .then((res) => {
          setIsLoading(false);
          toast.success(res.data.message);
          setPatientList("");
          setCity("");
          setCityList("");

          localStorage.setItem("patientId", res.data.data.id);

          navigate("/patientChart");
          window.location.reload();
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response) {
            if (err.response.status === 500) {
              localStorage.clear();
              navigate("/login");
            } else {
              toast.error(err.response.data.message);
            }
          }
        });
    }
  };

  const btnCancel = () => {
    navigate("/managePatient");
  };

  const [stateList, setStateList] = useState([]);

  const stateData = async () => {
    await axios
      .post(`${baseURL}/${STATE_LIST_WITHOUT_PAGINATION}`)
      .then((res) => {
        setStateList(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const [cityList, setCityList] = useState([]);
  const [stateId, setStateId] = useState("");

  const handleStateChange = (e) => {
    setCity({ ...city, stateId: e.value, cityId: "" });

    setCityList("");
    cityData();
    setStateId(e.value);
  };

  const cityData = async () => {
    axios
      .post(`${baseURL}/${CITY_LIST_WITHOUT_PAGINATION}`, {
        stateId: stateId,
        search: "",
      })
      .then((res) => {
        setCityList(res.data.data);
      });
  };

  const stateOptions =
    stateList &&
    stateList.map((item, i) => {
      return {
        id: item.id,
        value: item.id,
        label: item.name,
      };
    });

  const cityOptions =
    cityList &&
    cityList.map((item, i) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

  useEffect(() => {
    stateData();
    cityData();
  }, [stateId]);

  return (
    <>
      <div className="page-content-wrapper">
        <div className="page-content">
          <div className="page-bar">
            <div className="page-title-breadcrumb">
              <div className=" pull-left">
                <div className="page-title">{CREATE_PATIENT}</div>
              </div>
              <ol className="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i className="fa fa-home"></i>&nbsp;
                  <NavLink className="parent-item" to="/dashboard">
                    {HOME}
                  </NavLink>
                  &nbsp;<i className="fa fa-angle-right"></i>
                </li>
                <li>
                  <i className="fa fa-ambulance" aria-hidden="true"></i>&nbsp;
                  <NavLink className="parent-item" to="/managePatient">
                    {PATIENT_LIST}
                  </NavLink>
                  &nbsp;<i className="fa fa-angle-right"></i>
                </li>
                <li className="active">{CREATE_PATIENT}</li>
              </ol>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="card card-box">
                <div className="card-head">
                  <header>{PATIENT_INFO}</header>
                </div>
                <div className="card-body" id="bar-parent">
                  <Tabs
                    defaultActiveKey={PATIENT_TAB}
                    id="uncontrolled-tab-example"
                  >
                    <Tab eventKey={PATIENT_TAB} title={PATIENT_TAB}>
                      <>
                        <div style={{ display: "flex" }} id="formData">
                          <div className="card-body" id="bar-parent">
                            <form
                              action="#"
                              id="form_sample_1"
                              className="form-horizontal"
                            >
                              <div className="form-body">
                                <div className="form-group row">
                                  <label className="control-label col-md-4">
                                    {FIRST_NAME}
                                    <span className="required"> * </span>
                                  </label>
                                  <div className="col-md-7">
                                    <input
                                      name="firstName"
                                      value={
                                        patientList && patientList.firstName
                                      }
                                      type="text"
                                      placeholder="Enter First Name"
                                      className="form-control input-height"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="control-label col-md-4">
                                    {LAST_NAME}
                                    <span className="required"> * </span>
                                  </label>
                                  <div className="col-md-7">
                                    <input
                                      name="lastName"
                                      value={
                                        patientList && patientList.lastName
                                      }
                                      type="text"
                                      placeholder="Enter Last Name"
                                      className="form-control input-height"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="control-label col-md-4">
                                    {ADDRESS}
                                    <span className="required"> * </span>
                                  </label>
                                  <div className="col-md-7">
                                    <input
                                      name="address1"
                                      value={
                                        patientList && patientList.address1
                                      }
                                      type="text"
                                      placeholder="Enter Address"
                                      className="form-control input-height"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="control-label col-md-4">
                                    {ADDRESS_COUNTINUED}{" "}
                                  </label>
                                  <div className="col-md-7">
                                    <input
                                      name="address2"
                                      value={
                                        patientList && patientList.address2
                                      }
                                      type="text"
                                      placeholder="Enter Address"
                                      className="form-control input-height"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="control-label col-md-4">
                                    {STATE}
                                    <span className="required"> * </span>
                                  </label>
                                  <div className="col-md-7">
                                    <Select
                                      name="stateId"
                                      options={stateOptions}
                                      onChange={handleStateChange}
                                      placeholder={SELECT}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="control-label col-md-4">
                                    {CITY}
                                    <span className="required"> * </span>
                                  </label>
                                  <div className="col-md-7">
                                    <CreatableSelect
                                      name="city"
                                      value={
                                        cityOptions &&
                                        cityOptions.filter(
                                          ({ value }) => value === city.cityId
                                        )
                                      }
                                      options={cityOptions}
                                      onChange={handleCityChange}
                                      placeholder="Enter city manually if not listed"
                                      onCreateOption={(newOption) => {
                                        setCityList([
                                          ...cityList,
                                          {
                                            name: newOption,
                                            stateId: stateId,
                                            id: newOption,
                                            cityId: newOption,
                                          },
                                        ]);
                                        setCity({
                                          name: newOption,
                                          stateId: stateId,
                                          id: newOption,
                                          cityId: newOption,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="control-label col-md-4">
                                    {ZIP}
                                    <span className="required"> * </span>
                                  </label>
                                  <div className="col-md-7">
                                    <input
                                      name="zip"
                                      value={patientList && patientList.zip}
                                      type="text"
                                      placeholder="Enter Zip Code"
                                      className="form-control input-height"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="control-label col-md-4">
                                    {BIRTH_DATE}
                                    <span className="required"> * </span>
                                  </label>
                                  <div className="col-md-7">
                                    <DatePicker
                                      className="form-control input-height mr-sm-2"
                                      selected={patientList.dob}
                                      placeholderText={"Please Select Date"}
                                      // onChange={onInputChanged} yearDropdownItemNumber={100}
                                      maxDate={new Date()}
                                      scrollableMonthDropdown={true}
                                      showYearDropdown
                                      showMonthDropdown
                                      // maxDate={new Date(minYear)}
                                      onChange={(date) =>
                                        setPatientList({
                                          ...patientList,
                                          dob: date,
                                        })
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="control-label col-md-4">
                                    {GENDER}
                                  </label>
                                  <div className="col-md-7">
                                    <select
                                      className="form-control input-height"
                                      name="gender"
                                      value={patientList && patientList.gender}
                                      onChange={handleChange}
                                    >
                                      <option value=""> {SELECT_GENDER}</option>
                                      <option value="male">Male</option>
                                      <option value="female">Female</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="control-label col-md-4">
                                    {SOCIAL_SECURITY_NUMBER}
                                  </label>
                                  <div className="col-md-7">
                                    <input
                                      name="socialSecurityNo"
                                      value={
                                        patientList &&
                                        patientList.socialSecurityNo
                                      }
                                      type="number"
                                      placeholder="Enter Social Security Number"
                                      className="form-control input-height"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="control-label col-md-4">
                                    {HEIGHT_IN_INCHIES}
                                  </label>
                                  <div className="col-md-7">
                                    <input
                                      name="height"
                                      value={patientList && patientList.height}
                                      type="number"
                                      placeholder="Enter Height In Inchies"
                                      className="form-control input-height"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="control-label col-md-4">
                                    {WEIGHT}
                                  </label>
                                  <div className="col-md-7">
                                    <input
                                      name="weight"
                                      value={patientList && patientList.weight}
                                      type="number"
                                      placeholder="Enter Weight"
                                      className="form-control input-height"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="control-label col-md-4">
                                    {PHYSICIAN_NAME}
                                  </label>
                                  <div className="col-md-7">
                                    <input
                                      name="physicianName"
                                      value={
                                        patientList && patientList.physicianName
                                      }
                                      type="text"
                                      placeholder="Enter Physician Name"
                                      className="form-control input-height"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="control-label col-md-4">
                                    {DATE_OF_LAST_EXAM}
                                  </label>
                                  <div className="col-md-7">
                                    <DatePicker
                                      className="form-control input-height mr-sm-2"
                                      selected={patientList.dateOfLastExam}
                                      placeholderText={"Please Select Date"}
                                      maxDate={new Date()}
                                      scrollableMonthDropdown={true}
                                      showYearDropdown
                                      showMonthDropdown
                                      onChange={(date) =>
                                        setPatientList({
                                          ...patientList,
                                          dateOfLastExam: date,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>

                          <div className="card-body" id="bar-parent">
                            <form
                              action="#"
                              id="form_sample_1"
                              className="form-horizontal"
                            >
                              <div className="form-body">
                                <div className="form-group row">
                                  <label className="control-label col-md-6">
                                    {IS_MEDICARE_ELIGIBLE}{" "}
                                  </label>
                                  <div className="col-md-6">
                                    <select
                                      className="form-control input-height"
                                      name="medicareEligible"
                                      value={
                                        patientList &&
                                        patientList.medicareEligible
                                      }
                                      onChange={handleChange}
                                    >
                                      <option value="">
                                        {" "}
                                        -- {IS_MEDICARE_ELIGIBLE} --{" "}
                                      </option>
                                      {Object.keys(
                                        IS_MEDICARE_ELIGIBLE_OPTION
                                      ).map((item, i) => {
                                        return (
                                          <option
                                            key={i}
                                            value={
                                              IS_MEDICARE_ELIGIBLE_OPTION[item]
                                            }
                                          >
                                            {item}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="control-label col-md-6">
                                    {MEDICARE_ELIGIBILITY_STARTS_AT}
                                  </label>
                                  <div className="col-md-6">
                                    <DatePicker
                                      className="form-control input-height mr-sm-2"
                                      selected={
                                        patientList.madicareEligibilityStartAt
                                      }
                                      placeholderText={"Please Select Date"}
                                      scrollableMonthDropdown={true}
                                      showYearDropdown
                                      showMonthDropdown
                                      // onChange={onInputChanged}
                                      maxDate={new Date(minYear)}
                                      onChange={(date) =>
                                        setPatientList({
                                          ...patientList,
                                          madicareEligibilityStartAt: date,
                                        })
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="control-label col-md-6">
                                    {MEDICARE_PATIENT_ID}
                                    {/* <span className="required"> * </span> */}
                                  </label>
                                  <div className="col-md-6">
                                    <input
                                      name="MedicarePatientId"
                                      value={
                                        patientList &&
                                        patientList.MedicarePatientId
                                      }
                                      type="text"
                                      placeholder="Enter Medicare Patient ID"
                                      className="form-control input-height"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="control-label col-md-6">
                                    {PPO_ADVANTAGE_PLAN_NUMBER}{" "}
                                  </label>
                                  <div className="col-md-6">
                                    <input
                                      name="PPOInsuranceNumber"
                                      value={
                                        patientList &&
                                        patientList.PPOInsuranceNumber
                                      }
                                      type="text"
                                      placeholder="Enter PPO/Advantage Plan Number"
                                      className="form-control input-height"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="control-label col-md-6">
                                    {PPO_ADVANTAGE_PLAN_SPONSOR}{" "}
                                  </label>
                                  <div className="col-md-6">
                                    <input
                                      name="PPOInsuranceName"
                                      value={
                                        patientList &&
                                        patientList.PPOInsuranceName
                                      }
                                      type="text"
                                      placeholder="Enter PPO/Advantage Plan Sponsor"
                                      className="form-control input-height"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="control-label col-md-6">
                                    {PPO_ADVANTAGE_PAYER_ID}{" "}
                                  </label>
                                  <div className="col-md-6">
                                    <input
                                      name="PPOPayerId"
                                      value={
                                        patientList && patientList.PPOPayerId
                                      }
                                      type="text"
                                      placeholder="Enter PPO/Advantage Plan Payer"
                                      className="form-control input-height"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="control-label col-md-6">
                                    {PPO_ADVANTAGE_PAYER_ADDRESS}
                                  </label>
                                  <div className="col-md-6">
                                    <input
                                      name="PPOPayerAddress"
                                      value={
                                        patientList &&
                                        patientList.PPOPayerAddress
                                      }
                                      type="text"
                                      placeholder="Enter PPO/Advantage Address"
                                      className="form-control input-height"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="control-label col-md-6">
                                    {PPO_ADVANTAGE_PLAN_PHONE}{" "}
                                  </label>
                                  <div className="col-md-6">
                                    <input
                                      name="PPOPhone"
                                      value={
                                        patientList && patientList.PPOPhone
                                      }
                                      type="text"
                                      placeholder="Enter PPO/Advantage Phone"
                                      className="form-control input-height"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="control-label col-md-6">
                                    {HMO_ADVANTAGE_PLAN_NUMBER}{" "}
                                  </label>
                                  <div className="col-md-6">
                                    <input
                                      name="HMOInsuranceNumber"
                                      value={
                                        patientList &&
                                        patientList.HMOInsuranceNumber
                                      }
                                      type="text"
                                      placeholder="Enter HMO Plan Number"
                                      className="form-control input-height"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="control-label col-md-6">
                                    {HMO_ADVANTAGE_PLAN_SPONSOR}{" "}
                                  </label>
                                  <div className="col-md-6">
                                    <input
                                      name="HMOInsuranceName"
                                      value={
                                        patientList &&
                                        patientList.HMOInsuranceName
                                      }
                                      type="text"
                                      placeholder="Enter HMO Plan Sponsor"
                                      className="form-control input-height"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="control-label col-md-6">
                                    {HMO_ADVANTAGE_PAYER_ID}
                                  </label>
                                  <div className="col-md-6">
                                    <input
                                      name="HMOPayerId"
                                      value={
                                        patientList && patientList.HMOPayerId
                                      }
                                      type="text"
                                      placeholder="Enter HMO Plan Payer"
                                      className="form-control input-height"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="control-label col-md-6">
                                    {HMO_ADVANTAGE_PAYER_ADDRESS}
                                  </label>
                                  <div className="col-md-6">
                                    <input
                                      name="HMOPayerAddress"
                                      value={
                                        patientList &&
                                        patientList.HMOPayerAddress
                                      }
                                      type="text"
                                      placeholder="Enter HMO Address"
                                      className="form-control input-height"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="control-label col-md-6">
                                    {HMO_ADVANTAGE_PLAN_PHONE}{" "}
                                  </label>
                                  <div className="col-md-6">
                                    <input
                                      name="HMOPhone"
                                      value={
                                        patientList && patientList.HMOPhone
                                      }
                                      type="text"
                                      placeholder="Enter HMO Phone"
                                      className="form-control input-height"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="control-label col-md-6">
                                    {REASON_FOR_TOADAY_VISIT}{" "}
                                  </label>
                                  <div className="col-md-6">
                                    <input
                                      name="ReasonForTodayVisit"
                                      value={
                                        patientList &&
                                        patientList.ReasonForTodayVisit
                                      }
                                      type="text"
                                      placeholder="Enter Reason For Today's Visit"
                                      className="form-control input-height"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="control-label col-md-6">
                                    {GENERAL_HEALTH}{" "}
                                  </label>
                                  <div className="col-md-6">
                                    <select
                                      className="form-control input-height"
                                      name="GeneralHealth"
                                      value={
                                        patientList && patientList.GeneralHealth
                                      }
                                      onChange={handleChange}
                                    >
                                      <option value=""> {SELECT} </option>
                                      {Object.keys(GENERAL_HEALTH_OPTIONS).map(
                                        (item, i) => {
                                          return (
                                            <option
                                              key={i}
                                              value={
                                                GENERAL_HEALTH_OPTIONS[item]
                                              }
                                            >
                                              {item}
                                            </option>
                                          );
                                        }
                                      )}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>

                        <div className="form-actions mt-5">
                          <div className="center">
                            {isLoading ? (
                              <>
                                <button
                                  type="submit"
                                  className="btn btn-info m-r-20"
                                  disabled
                                >
                                  {SAVE}
                                </button>
                                <i className="fa fa-spinner fa-spin fa-2x loaderBtnSpinner"></i>
                              </>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-info m-r-20"
                                onClick={handleFormSubmit}
                              >
                                {SAVE}
                              </button>
                            )}
                            <button
                              className="btn btn-default"
                              onClick={btnCancel}
                            >
                              {CANCEL}
                            </button>
                          </div>
                        </div>
                      </>
                      s{" "}
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddPatient;
