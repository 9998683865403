import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { baseURL } from "../../../Config";
import { EDIT_EMPLOYEE, UPDATE_EMPLOYEE, CITY_LIST_WITHOUT_PAGINATION, STATE_LIST_WITHOUT_PAGINATION, ROLE_LIST_API } from "../../../Helper/ApiPath";
import {
    CANCEL,
    FIRST_NAME,
    EMAIL,
    CITY,
    STATUS,
    STATE,
    ADDRESS,
    HOME,
    LAST_NAME,
    UPDATE,
    ACCESSLEVEL,
    OFFICE_STATUS,
    EMPLOYEE_LIST,
    EMPLOYEE_INFORMATION,
    EDIT_EMPLOYEES,
    SELECT,
} from "../../../Helper/Constants";
import { ENTER_FNAME, SELECT_ACCESSLEVEL, SELECT_STATUS } from "../../../Helper/Messages";
import Select from "react-select";

function EditEmployee() {
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const [employee, setEmployee] = useState({
        email: "",
        firstName: "",
        lastName: "",
        address: "",
        roleId: "",
        pageAuthorityRoleId: "",
        statusId: "",
    });
    const [city, setCity] = useState({
        stateId: "",
        cityId: "",
    });

    const handleCityChange = (e) => {
        setCity({ ...city, cityId: e.value });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmployee({ ...employee, [name]: value });
    };

    const employeeId = localStorage.getItem("employeeId");
    const token = JSON.parse(localStorage.getItem("logToken"));

    const fetchData = async () => {
        await axios
            .get(`${baseURL}/${EDIT_EMPLOYEE}/${employeeId}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                if (res.status === 200) {
                    setEmployee(res.data.data);
                    setCity(res.data.data);
                    setStateId(res.data.data.stateId);
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const [accessLevel, setAccessLevel] = useState([]);

    const fetchAccessLevel = async () => {
        await axios
            .post(`${baseURL}/${ROLE_LIST_API}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                setIsLoading(false);
                setAccessLevel(res.data.data);
                console.log(res.data.data);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const accessLevelOption =
        accessLevel &&
        accessLevel.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
                parentId: item.parentId,
            };
        });

    const handleAccessLevelChange = (e) => {
        setEmployee({ ...employee, roleId: e.parentId, pageAuthorityRoleId: e.value });
    };

    const updateEmployee = async () => {
        if (!employee.firstName) {
            toast.error(ENTER_FNAME);
        } else if (!employee.roleId) {
            toast.error(SELECT_ACCESSLEVEL);
        } else if (!employee.statusId) {
            toast.error(SELECT_STATUS);
        } else {
            setIsLoading(true);
            await axios
                .post(`${baseURL}/${UPDATE_EMPLOYEE}`, {
                    headers: { authorization: token.token },
                    employeeId: employeeId,
                    firstName: employee.firstName,
                    lastName: employee.lastName,
                    roleId: employee.roleId,
                    pageAuthorityRoleId: employee.pageAuthorityRoleId,
                    statusId: employee.statusId,
                    state: city.stateId,
                    city: city.cityId,
                    address: employee.address,
                })
                .then((res) => {
                    if (res.status === 200) {
                        toast.success(res.data.message);
                    }
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false);
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const handleCancel = () => {
        navigate("/manageEmployee");
    };

    useEffect(() => {
        fetchData();
        fetchAccessLevel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [stateList, setStateList] = useState([]);

    const stateData = async () => {
        await axios
            .post(`${baseURL}/${STATE_LIST_WITHOUT_PAGINATION}`)
            .then((res) => {
                setStateList(res.data.data);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };

    const [cityList, setCityList] = useState([]);
    const [stateId, setStateId] = useState([]);

    const handleStateChange = (e) => {
        setCity({ ...city, stateId: e.value, cityId: "" });
        setCityList("");
        cityData();
        setStateId(e.value);
    };

    const cityData = async () => {
        axios
            .post(`${baseURL}/${CITY_LIST_WITHOUT_PAGINATION}`, {
                stateId: stateId,
                search: "",
            })
            .then((res) => {
                setCityList(res.data.data);
            });
    };
    const stateOptions =
        stateList &&
        stateList.map((item, i) => {
            return {
                id: item.id,
                value: item.id,
                label: item.name,
            };
        });

    const cityOptions =
        cityList &&
        cityList.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });
    useEffect(() => {
        stateData();
        cityData();
    }, [stateId]);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content" style={{ minHeight: "600px" }}>
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{EDIT_EMPLOYEES}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li>
                                    <i className="fa fa-users"></i>&nbsp;
                                    <NavLink className="parent-item" to="/manageEmployee">
                                        {EMPLOYEE_LIST}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{EDIT_EMPLOYEES}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="card card-box">
                                <div className="card-head">
                                    <header>{EMPLOYEE_INFORMATION}</header>
                                </div>
                                <div className="card-body" id="bar-parent">
                                    <div action="#" id="form_sample_1" className="form-horizontal">
                                        <div className="form-body">
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{EMAIL}</label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        value={employee.email}
                                                        onChange={handleChange}
                                                        placeholder="Enter Email"
                                                        className="form-control input-height"
                                                        disabled
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {FIRST_NAME}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="firstName"
                                                        value={employee.firstName}
                                                        onChange={handleChange}
                                                        data-required="1"
                                                        placeholder="Enter First Name"
                                                        className="form-control input-height"
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{LAST_NAME}</label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="lastName"
                                                        value={employee.lastName}
                                                        onChange={handleChange}
                                                        placeholder="Enter Last Name"
                                                        className="form-control input-height"
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{ADDRESS}</label>
                                                <div className="col-md-5">
                                                    <textarea
                                                        name="address"
                                                        value={employee.address}
                                                        onChange={handleChange}
                                                        placeholder="Enter Address"
                                                        className="form-control form-control-textarea"
                                                        rows="3"
                                                    ></textarea>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{STATE}</label>
                                                <div className="col-md-5">
                                                    <Select
                                                        name="stateId"
                                                        value={stateOptions && stateOptions.filter(({ value }) => value == city.stateId)}
                                                        options={stateOptions}
                                                        onChange={handleStateChange}
                                                        placeholder={SELECT}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{CITY}</label>
                                                <div className="col-md-5">
                                                    <Select
                                                        name="cityId"
                                                        value={cityOptions && cityOptions.filter(({ value }) => value == city.cityId)}
                                                        options={cityOptions}
                                                        onChange={handleCityChange}
                                                        placeholder={SELECT}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {ACCESSLEVEL}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    {employee.pageAuthorityRoleId ? (
                                                        <Select
                                                            name="pageAuthorityRoleId"
                                                            value={accessLevelOption && accessLevelOption.filter(({ value }) => value == employee.pageAuthorityRoleId)}
                                                            options={accessLevelOption}
                                                            onChange={handleAccessLevelChange}
                                                            placeholder={SELECT}
                                                        />
                                                    ) : (
                                                        <Select
                                                            name="roleId"
                                                            value={accessLevelOption && accessLevelOption.filter(({ parentId }) => parentId == employee.pageAuthorityRoleId)}
                                                            options={accessLevelOption}
                                                            onChange={handleAccessLevelChange}
                                                            placeholder={SELECT}
                                                        />
                                                    )}
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {STATUS}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <select className="form-control input-height" name="statusId" value={employee && employee.statusId} onChange={handleChange}>
                                                        <option value=""> {SELECT} </option>
                                                        {Object.keys(OFFICE_STATUS).map((item, i) => {
                                                            return (
                                                                <option key={i} value={OFFICE_STATUS[item]}>
                                                                    {item}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="form-actions">
                                                <div className="row">
                                                    <div className="offset-md-3 col-md-9">
                                                        {isLoading ? (
                                                            <>
                                                                <button type="submit" className="btn btn-primary m-r-20" disabled>
                                                                    {UPDATE}
                                                                </button>

                                                                <i className="fa fa-spinner fa-spin fa-2x loaderUpdateBtnSpinner"></i>
                                                            </>
                                                        ) : (
                                                            <button type="submit" className="btn btn-primary m-r-20" onClick={updateEmployee}>
                                                                {UPDATE}
                                                            </button>
                                                        )}
                                                        <button type="reset" className="btn btn-default  m-r-20" onClick={handleCancel}>
                                                            {CANCEL}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditEmployee;
