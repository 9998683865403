import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../Config";
import { ALL_DOCTOR_IN_SELECTED_OFFICE, DOCTOR_NOTICE_API, FIND_DOCTOR_AFTER_LOGIN, OFFICE_TABLE_LIST } from "../../Helper/ApiPath";
import { CANCEL, CHOOSE_DOCTOR, CHOOSE_OFFICE, NEXT, POPUPSTATUS, ROLES } from "../../Helper/Constants";
import { CHOOSE_OFFICE_DOCTOR, SELECT_OFFICE_FORM_ABOVE_LIST, THERE_IS_NO_OFFICE_FOR_THIS_USER } from "../../Helper/Messages";
import Cookies from "universal-cookie";
import Footer from "../footer/Footer";
import { dateFormateMonthDateYear } from "../../Helper/Helper";
import _ from "lodash";

function ChooseOfficeDoctor() {
    let logToken = JSON.parse(localStorage.getItem("logToken"));
    const cookies = new Cookies();
    let role = cookies.get("role");
    if (role) {
        role = cookies.get("role").roleId;
    }

    const navigate = useNavigate();

    const [chooseOffice, setChooseOffice] = useState([]);
    const [chooseDoctor, setChooseDoctor] = useState([]);

    let popUpId = [];

    let checkedPopUp = cookies.get("checkedPopUP");

    let currentDate = new Date();

    const getPopUpId = async () => {
        await axios
            .post(`${baseURL}/${DOCTOR_NOTICE_API}?isPagination=true`, {
                headers: { authorization: logToken.token },
            })
            .then(async (res) => {
                let response = res.data.data;

                response.map((item, i) => {
                    return item.popUp == POPUPSTATUS.Yes ? popUpId.push({ date: dateFormateMonthDateYear(currentDate), noticeId: item.id, email: logToken.email }) : "";
                });

                if (checkedPopUp && !checkedPopUp.lenth) {
                    popUpId = popUpId.filter(
                        (item) => !checkedPopUp.some((checkedPopUp) => checkedPopUp.noticeId === item.noticeId && checkedPopUp.date === item.date && checkedPopUp.email === item.email)
                    );
                }

                localStorage.setItem("popUpId", JSON.stringify(popUpId));
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${OFFICE_TABLE_LIST}`, {
                headers: { authorization: logToken.token },
            })
            .then((res) => {
                setChooseOffice(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleOfficeChange = async (officeId) => {
        localStorage.setItem("officeId", officeId);
        localStorage.removeItem("doctorId");
        var radio = document.querySelector("input[type=radio][name=chooseDoctorid]:checked");
        if (radio) radio.checked = false;
        await axios
            .post(`${baseURL}/${ALL_DOCTOR_IN_SELECTED_OFFICE}`, {
                headers: { authorization: logToken.token },
                officeId: officeId,
                isDoctor: true,
            })
            .then((res) => {
                setChooseDoctor(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const handleDoctorChange = async (doctorId) => {
        localStorage.setItem("doctorId", doctorId);
    };

    const handleNext = async (e) => {
        let officeId = localStorage.getItem("officeId");
        let doctorId = localStorage.getItem("doctorId");

        e.preventDefault();
        if (!officeId) {
            toast.error("please Select Office");
        } else if (!doctorId) {
            toast.error("please Select Doctor");
        } else {
            await axios
                .post(`${baseURL}/${FIND_DOCTOR_AFTER_LOGIN}`, {
                    headers: { authorization: logToken.token },
                    officeId: officeId,
                    doctorId: doctorId,
                })
                .then(async (res) => {
                    if (res.status === 200) {
                        localStorage.setItem("doctorPracticeId", res.data.data.id);
                        localStorage.setItem("payerName", res.data.data.payerName);
                        localStorage.setItem("payerId", res.data.data.payerId);
                        localStorage.setItem("payerValue", res.data.data.payerValue);
                        await getPopUpId();
                        navigate("/dashboard");
                        window.location.reload();
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const handleCancel = (e) => {
        e.preventDefault();

        localStorage.removeItem("logToken");
        localStorage.removeItem("step");
        localStorage.removeItem("AdminRoleID");
        localStorage.removeItem("officeId");
        localStorage.removeItem("doctorId");
        localStorage.removeItem("doctorPracticeId");
        localStorage.removeItem("DoctorToken");
        localStorage.removeItem("AdminRoleID");
        localStorage.removeItem("doctorFirstName");
        localStorage.removeItem("doctorLastName");
        navigate("/login");
    };

    useEffect(() => {
        if (role == ROLES.DOCTOR) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="limiter">
                <div className="container-login100 page-background">
                    <div className="wrap-login100">
                        <div className="page-header-fixed sidemenu-closed-hidelogo page-content-white page-md header-white white-sidebar-color logo-indigo">
                            <div className="page-wrapper">
                                <div className="page-content-wrapper">
                                    <div className="mx-5">
                                        <div className="">
                                            <div className="page-title text-white">{CHOOSE_OFFICE_DOCTOR}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="card-box">
                                                    <div className="card-head">
                                                        <header> {CHOOSE_OFFICE}</header>
                                                    </div>
                                                    <div className="card-body">
                                                        {chooseOffice == "" ? (
                                                            <h3>{THERE_IS_NO_OFFICE_FOR_THIS_USER}</h3>
                                                        ) : (
                                                            chooseOffice &&
                                                            chooseOffice.map((item, index) => {
                                                                return (
                                                                    <div className="form-check" key={index}>
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="chooseOfficeid"
                                                                            id={item.id}
                                                                            value={item.id}
                                                                            onChange={() => handleOfficeChange(item.id)}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={item.id}>
                                                                            {item.name}
                                                                        </label>
                                                                    </div>
                                                                );
                                                            })
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="card-box">
                                                    <div className="card-head">
                                                        <header> {CHOOSE_DOCTOR}</header>
                                                    </div>
                                                    <div className="card-body ">
                                                        {chooseDoctor == "" ? (
                                                            <h3>{SELECT_OFFICE_FORM_ABOVE_LIST}</h3>
                                                        ) : (
                                                            chooseDoctor &&
                                                            chooseDoctor.map((item, index) => {
                                                                return (
                                                                    <div className="form-check" key={index}>
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="chooseDoctorid"
                                                                            id={item.id}
                                                                            value={item.id}
                                                                            onChange={() => handleDoctorChange(item.id)}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={item.id}>
                                                                            {item.firstName} {item.lastName}
                                                                        </label>
                                                                    </div>
                                                                );
                                                            })
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-actions">
                                            <div className="row">
                                                <div className="text-end">
                                                    <button type="reset" className="btn btn-default  m-r-20 btn-register btn-circle" onClick={handleCancel}>
                                                        {CANCEL}
                                                    </button>
                                                    <button type="submit" className="btn btn-info m-r-20  btn-register btn-circle" onClick={handleNext}>
                                                        {NEXT}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ChooseOfficeDoctor;
