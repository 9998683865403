import React from "react";
import { Link } from "react-router-dom";

function CongratulationPage() {
    localStorage.removeItem("logToken");
    localStorage.removeItem("step");
    localStorage.removeItem("DoctorToken");
    localStorage.removeItem("AdminRoleID");

    return (
        <>
            <div className="limiter">
                <div className="container-login100 page-background">
                    <div className="wrap-login100">
                        <form className="form-404">
                            <span className="login100-form-logo">
                                <img alt="" src="assets/img/hospital.png" />
                            </span>
                            <span className="form404-title p-b-34 p-t-27">Congratulations!!</span>
                            <p className="content-404">Your registration has been completed you can login now.</p>
                            <div className="container-login100-form-btn">
                                <Link to="/login">
                                    <button className="login100-form-btn">Log In</button>
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CongratulationPage;
