import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { NOTICE_DOCTOR_DETAIL } from "../../../Helper/ApiPath";
import { CLOSE, CONTENTS, SKIP_TODAY } from "../../../Helper/Constants";
import parse from "html-react-parser";
import { baseURL } from "../../../Config";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { dateFormateMonthDateYear } from "../../../Helper/Helper";
import _ from "lodash";
import Cookies from "universal-cookie";

function NoticeDetail(props) {
    let token = JSON.parse(localStorage.getItem("logToken"));

    const noticeId = props.noticeId ? props.noticeId : localStorage.getItem("noticeId");

    let currentDate = new Date();
    const cookies = new Cookies();

    const navigate = useNavigate();
    const [show, setShow] = useState(true);
    const [ischecked, setIsChecked] = useState(false);
    const handleClose = () => {
        let popUpId = JSON.parse(localStorage.getItem("popUpId"));
        _.remove(popUpId, function (e) {
            return e.noticeId == props.noticeId;
        });
        localStorage.setItem("popUpId", JSON.stringify(popUpId));
        setShow(false);
        if (ischecked) {
            props.checkedPopUP.push({ date: dateFormateMonthDateYear(currentDate), noticeId: props.noticeId, email: token.email });
            cookies.set("checkedPopUP", JSON.stringify(props.checkedPopUP));
        }
    };

    const handleCheck = (e) => {
        setIsChecked(e.target.checked);
    };

    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    const [notice, setNotice] = useState({
        title: "",
        statusId: "",
        popUp: "",
        attachment: "",
        createBy: "",
        createDate: "",
        updateBy: "",
        updateDate: "",
    });
    const [editorValue, setEditorValue] = useState("");

    const [noticeDateValues, setNoticeDateValues] = useState({
        postingPeriodStartDate: "",
        postingPeriodEndDate: "",
    });

    const fetchdata = async () => {
        await axios
            .get(`${baseURL}/${NOTICE_DOCTOR_DETAIL}/${noticeId}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                setNotice(res.data.data);
                setEditorValue(res.data.data.contents);
                setNoticeDateValues({ ...noticeDateValues, postingPeriodStartDate: res.data.data.postingPeriodStartDate, postingPeriodEndDate: res.data.data.postingPeriodEndDate });
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        fetchdata();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Modal show={props.showDetail ? props.show : show} onHide={props.showDetail ? props.handleClose : handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{notice.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="text-end">
                            {dateFormateMonthDateYear(noticeDateValues && noticeDateValues.postingPeriodStartDate)} - {dateFormateMonthDateYear(noticeDateValues.postingPeriodEndDate)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <label className="control-label col-md-3 fw-600">{CONTENTS}</label>
                            <div className="col-12 p-img">
                                {isReadMore ? <>{parse(editorValue.slice(0, 250))}</> : <> {parse(editorValue)} </>}
                                {editorValue.length < 250 ? (
                                    ""
                                ) : (
                                    <span onClick={toggleReadMore} className="read-or-hide" style={{ cursor: "pointer" }}>
                                        {isReadMore ? <div className="text-primary">...read more</div> : <div className="text-danger">show less</div>}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <button className="btn btn-default text-center" onClick={props.showDetail ? props.handleClose : handleClose}>
                        {CLOSE}
                    </button>
                    {props.check ? (
                        ""
                    ) : (
                        <div className="d-flex justify-content-center" id="checkBox">
                            <input type="checkbox" className="checkBox mx-1 m-l-20 my-1" data-id={props.noticeId} onClick={handleCheck} />
                            <h6 className="mx-2 ">{SKIP_TODAY}</h6>
                        </div>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default NoticeDetail;
