import React, { useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useNavigate, NavLink } from "react-router-dom";
import { OP_NOTES, HOME, CLAIM_OP_NOTES_LIST, SAVE, CANCEL, CLEAR, PREVIEW, DASH, START_TIME, END_TIME, DOWNLOAD } from "../../../Helper/Constants";
import axios from "axios";
import { baseURL, frontURL } from "../../../Config";
import { CLAIM_DETAIL, DETAIL_NOTE_API, EDIT_OP_NOTE_API, OP_NOTE_DOWNLOAD_API } from "../../../Helper/ApiPath";
import { toast } from "react-toastify";
import { dateFormateMonthDateYear } from "../../../Helper/Helper";
import SignaturePad from "react-signature-canvas";

function EditOpNote() {
    const sigCanvas = useRef({});
    let navigate = useNavigate();
    let token = JSON.parse(localStorage.getItem("logToken"));
    const OpNotesId = localStorage.getItem("opNoteId");
    let claimId = localStorage.getItem("claimId");
    let doctorId = localStorage.getItem("doctorId");

    const [isLoading, setIsLoading] = useState(true);
    const [claimDetail, setClaimDetail] = useState([]);
    const [editorValue, setEditorValue] = useState("");
    const [contentsEditValue, setContentsEditValue] = useState("");
    const [imageURL, setImageURl] = useState(null);
    const [isBase64Srt, setIsBase64Srt] = useState(false);
    const [editSign, setEditSign] = useState(false);

    const [time, setTime] = useState({
        startTime: "",
        endTime: "",
    });

    const handleTimeChange = (e) => {
        const { name, value } = e.target;
        setTime({ ...time, [name]: value });
    };

    const clear = () => {
        sigCanvas.current.clear();
        setImageURl(null);
    };

    const save = () => {
        setImageURl(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
        setIsBase64Srt(true);
        setEditSign(true);
    };

    const handlePdfDownload = async () => {
        axios
            .post(`${baseURL}/${OP_NOTE_DOWNLOAD_API}`, {
                headers: { authorization: token.token },
                OPNoteId: OpNotesId,
                doctorId: doctorId,
            })
            .then(async (res) => {
                axios({
                    url: `${frontURL}${res.data.data.path}`,
                    method: "GET",
                    responseType: "blob",
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `${res.data.data.patientName + "_op-note" + ".pdf"}`);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url);
                });
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const fetchClaimdata = async () => {
        await axios
            .get(`${baseURL}/${CLAIM_DETAIL}/${claimId}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                setIsLoading(false);
                setClaimDetail(res.data.data);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const fetchdata = async () => {
        await axios
            .get(`${baseURL}/${DETAIL_NOTE_API}/${OpNotesId}`, {
                headers: { authorization: token.token },
            })
            .then(async (res) => {
                setEditorValue(res.data.data.note);
                setImageURl(res.data.data.signatureImage);
                setTime({ ...time, startTime: res.data.data.startTime, endTime: res.data.data.endTime });
            })
            .catch((err) => {
                setIsLoading(false);

                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleSave = async () => {
        await axios
            .post(`${baseURL}/${EDIT_OP_NOTE_API}`, {
                headers: { authorization: token.token },
                note: editorValue,
                signatureImage: imageURL,
                startTime: time.startTime,
                endTime: time.endTime,
                OpNotesId,
                isBase64Srt: isBase64Srt,
                editSign: editSign,
            })
            .then((res) => {
                if (res.status === 200) {
                    toast.success(res.data.message);
                    navigate("/manageOpNotes");
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleCancel = () => {
        navigate("/manageOpNotes");
    };

    const handleEditorChange = (e) => {
        setEditorValue(e);
        setContentsEditValue(e);
    };

    useEffect(() => {
        fetchdata();
        fetchClaimdata();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{OP_NOTES}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li>
                                    <i className="fa fa-sticky-note" aria-hidden="true"></i>&nbsp;
                                    <NavLink className="parent-item" to="/manageOpNotes">
                                        {CLAIM_OP_NOTES_LIST}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{OP_NOTES}</li>
                            </ol>
                        </div>
                    </div>

                    <div className="card" style={isLoading ? { height: "35rem" } : {}}>
                        <div className="card-body ">
                            {isLoading ? (
                                <>
                                    <div className="form-body" style={{ height: "100px" }}>
                                        <div id="text">
                                            <i className="fa fa-spinner fa-spin fa-2x"></i>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="row">
                                        <CKEditor
                                            config={{
                                                height: "130",
                                                removePlugins: ["CKFinderUploadAdapter", "CKFinder", "EasyImage", "Image", "ImageCaption", "ImageStyle", "ImageToolbar", "ImageUpload", "MediaEmbed"],
                                            }}
                                            editor={ClassicEditor}
                                            id="editor1"
                                            onReady={(editor) => {
                                                editor.setData(editorValue);
                                            }}
                                            onChange={(event, editor) => {
                                                handleEditorChange(editor.getData());
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <div className="card-body" id="bar-parent">
                                            <br />
                                            <br />
                                            <br />
                                            <b>Dr. Name:</b> {claimDetail.doctor ? claimDetail.doctor.firstName : ""} {claimDetail.doctor ? claimDetail.doctor.lastName : ""}
                                            <br />
                                            <b>License Number:</b> {claimDetail.doctor && claimDetail.doctor.licenseNumber ? claimDetail.doctor.licenseNumber : DASH}
                                            <br />
                                            <b>Date:</b> {claimDetail.dateOfService ? dateFormateMonthDateYear(claimDetail.dateOfService) : DASH}
                                            <br />
                                            <div className="my-3  d-flex">
                                                <div className="col-1">
                                                    <b>{START_TIME}</b>
                                                </div>
                                                <div className="col-2 mx-2">
                                                    <input type="time" className="form-control" name="startTime" value={time.startTime} onChange={handleTimeChange} />
                                                </div>
                                                <div className="mx-4 my-4"></div>
                                                <div className="mx-4 my-4"></div>
                                                <div className="col-1">
                                                    <b>{END_TIME}</b>
                                                </div>
                                                <div className="col-2">
                                                    <input type="time" className="form-control" name="endTime" value={time.endTime} onChange={handleTimeChange} />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="">
                                                    <b className={`${imageURL ? "my-5" : ""}`}>Sign :</b>

                                                    {imageURL ? (
                                                        <img
                                                            className="mx-2 my-2"
                                                            src={imageURL}
                                                            alt="Sign"
                                                            style={{
                                                                display: "block",
                                                                height: "max-content",
                                                                width: "max-content",
                                                            }}
                                                        />
                                                    ) : null}
                                                </div>

                                                <div className="mx-2" style={{ border: "1px solid", width: "fit-content" }}>
                                                    <SignaturePad ref={sigCanvas} canvasProps={{ width: 300, height: 150, className: "signatureCanvas" }} penColor="blue" />
                                                </div>
                                                <button className="btn btn-outline-danger my-2 mx-2" onClick={clear} style={{ border: "1px solid" }}>
                                                    {CLEAR}
                                                </button>
                                                <button className="btn btn-outline-success my-2 mx-2" onClick={save} style={{ border: "1px solid" }}>
                                                    {PREVIEW}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className="d-flex justify-content-center my-3">
                                <button className="btn btn-success mx-2" onClick={handleSave}>
                                    {SAVE}
                                </button>
                                <button className="btn btn-success mx-2" onClick={handlePdfDownload}>
                                    {DOWNLOAD}
                                </button>
                                {/* <ReactToPrint trigger={() => <button className="btn btn-primary mx-2"> {PRINT}</button>} content={() => componentRef.current} />
                                <div style={{ display: "none" }}>
                                    <ComponentToPrint ref={componentRef} />
                                </div> */}
                                <button className="btn btn-default mx-2" onClick={handleCancel}>
                                    {CANCEL}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>{" "}
        </>
    );
}

export default EditOpNote;

// const ComponentToPrint = React.forwardRef((props, ref) => {
//     let navigate = useNavigate();
//     let token = JSON.parse(localStorage.getItem("logToken"));
//     let claimId = localStorage.getItem("claimId");
//     const OpNotesId = localStorage.getItem("opNoteId");
//     const [claimDetail, setClaimDetail] = useState([]);
//     const [time, setTime] = useState({
//         startTime: "",
//         endTime: "",
//     });
//     const [imageURL, setImageURL] = useState("");
//     const [isLoading, setIsLoading] = useState(true);
//     const [editorValue, setEditorValue] = useState("");
//     const { value } = props;

//     const fetchdata = async () => {
//         await axios
//             .get(`${baseURL}/${DETAIL_NOTE_API}/${OpNotesId}`, {
//                 headers: { authorization: token.token },
//             })
//             .then((res) => {
//                 setIsLoading(false);
//                 setEditorValue(res.data.data.note);
//                 setImageURL(res.data.data.signatureImage);
//                 setTime({ ...time, startTime: res.data.data.startTime, endTime: res.data.data.endTime });
//             })
//             .catch((err) => {
//                 setIsLoading(false);

//                 if (err.response) {
//                     if (err.response.status == 500) {
//                         localStorage.clear();
//                         navigate("/login");
//                     } else {
//                         toast.error(err.response.data.message);
//                     }
//                 }
//             });
//     };
//     const fetchClaimdata = async () => {
//         await axios
//             .get(`${baseURL}/${CLAIM_DETAIL}/${claimId}`, {
//                 headers: { authorization: token.token },
//             })
//             .then((res) => {
//                 setIsLoading(false);
//                 setClaimDetail(res.data.data);
//             })
//             .catch((err) => {
//                 setIsLoading(false);
//                 if (err.response) {
//                     if (err.response.status == 500) {
//                         localStorage.clear();
//                         navigate("/login");
//                     } else {
//                         toast.error(err.response.data.message);
//                     }
//                 }
//             });
//     };
//     useEffect(() => {
//         fetchClaimdata();
//         fetchdata();
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, []);

//     return (
//         <div className="print-source" ref={ref}>
//             {parse(editorValue)}
//             <div>
//                 <div className="">
//                     <br />
//                     <br />
//                     <br />
//                     <b>Dr. Name:</b> {claimDetail.doctor ? claimDetail.doctor.firstName : ""} {claimDetail.doctor ? claimDetail.doctor.lastName : ""}
//                     <br />
//                     <b>License Number:</b> {claimDetail.doctor ? claimDetail.doctor.licenseNumber : DASH}
//                     <br />
//                     <b>Date:</b> {claimDetail.dateOfService ? dateFormateMonthDateYear(claimDetail.dateOfService) : DASH}
//                     <br />
//                     <b>Start Time:</b> {opNoteTime(time.startTime)} <span>&nbsp; &nbsp;</span> <b>End Time:</b> {opNoteTime(time.endTime)} <br />
//                     <b className={`${imageURL ? "my-5" : ""}`}>Sign :</b>
//                     {imageURL ? (
//                         <img
//                             className="mx-2 my-2"
//                             src={imageURL}
//                             alt="Sign"
//                             style={{
//                                 display: "block",
//                             }}
//                         />
//                     ) : null}
//                 </div>
//             </div>
//         </div>
//     );
// });
