import React, { useEffect, useState } from "react";
import {
    CLAIM_,
    DOCTOR,
    PATIENT,
    DATE_OF_SERVICE,
    HOME,
    CREATED_DATE,
    BOOL_VALUES,
    NO,
    AMOUNT,
    SUBMITTED_DATE,
    OFFICE,
    YES,
    DASH,
    ROWS_PER_PAGE,
    CREATED_BY,
    ACTION,
    SELECT_DOCTOR,
    NOTE_SENT,
} from "../../Helper/Constants";
import { CLAIM_DETAIL, DOCTORS_WITH_CLAIM_LIST_API, MANAGE_ADMIN_CLAIM, UPDATE_ISSENTNOTE_CLAIM_API } from "../../Helper/ApiPath";
import { claim24hrDate, dateFormateMonthDateYear, currentDateFormate, claim12hrDateTime, dateAPIFormate, defaultTimeZone } from "../../Helper/Helper";
import axios from "axios";
import { baseURL } from "../../Config";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { NavLink, Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import NoDataFound from "../CommonComponent/NoDataFound";
import { DELETE_CLAIM_MESSAGE, NOTE_SENT_CLAIM_MESSAGE, NOTE_NOT_SENT_CLAIM_MESSAGE } from "../../Helper/Messages";
import Loader from "../CommonComponent/Loader";
import Select from "react-select";

function AdminManageClaim() {
    const [currentPage, setCurrentPage] = useState(0);
    const navigate = useNavigate();
    const claimPagination = JSON.parse(sessionStorage.getItem("claimPagination"));
    const [isLoading, setIsLoading] = useState(true);
    const [claimList, setClaimList] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [offset, setOffset] = useState(claimPagination && claimPagination.offset ? claimPagination.offset : 1);
    const [pageCount, setPageCount] = useState(0);

    let token = JSON.parse(localStorage.getItem("logToken"));

    var date = new Date();
    const [filterValues, setFilterValues] = useState({
        status: claimPagination && claimPagination.status ? claimPagination.status : "",
        officeSearch: claimPagination && claimPagination.officeSearch ? claimPagination.officeSearch : "",
        patientSearch: claimPagination && claimPagination.patientSearch ? claimPagination.patientSearch : "",
        claimNoSearch: claimPagination && claimPagination.claimNoSearch ? claimPagination.claimNoSearch : "",
        startDate: claimPagination && claimPagination.startDate ? defaultTimeZone(claimPagination.startDate) : new Date(date.setMonth(date.getMonth() - 12)),
        endDate: claimPagination && claimPagination.endDate ? defaultTimeZone(claimPagination.endDate) : new Date(),
    });

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilterValues({ ...filterValues, [name]: value });
        sessionStorage.setItem(
            "claimPagination",
            JSON.stringify({
                ...filterValues,
                [name]: value,
                offset: offset,
                doctorId: doctorId,
            })
        );
        setOffset(1);
        setCurrentPage(0);
    };

    const claimDoctorId = sessionStorage.getItem("claimDoctorId");
    const claimPatientId = sessionStorage.getItem("claimPatientId");
    const [doctorId, setDoctorId] = useState(claimDoctorId ? claimDoctorId : claimPagination && claimPagination.doctorId ? claimPagination.doctorId : "");

    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${MANAGE_ADMIN_CLAIM}`, {
                headers: { authorization: token.token },
                doctorId: doctorId,
                patientId: claimPatientId ? claimPatientId : null,
                officeSearch: filterValues.officeSearch,
                startDate: dateAPIFormate(filterValues.startDate),
                endDate: dateAPIFormate(filterValues.endDate),
                page: offset,
                rowsPerPage: ROWS_PER_PAGE,
                sortBy: "submitted_date",
                isSubmitted: filterValues.status,
                sortDirection: "",
                doctorSearch: filterValues.doctorSearch,
                patientSearch: filterValues.patientSearch,
                claimNoSearch: filterValues.claimNoSearch,
            })
            .then((res) => {
                setIsLoading(false);
                setClaimList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const [doctorSelect, setDoctorSelect] = useState([]);

    let doctorList = [
        {
            id: "",
            firstName: SELECT_DOCTOR,
        },
    ];
    const fetchDoctorList = async (e) => {
        await axios
            .post(`${baseURL}/${DOCTORS_WITH_CLAIM_LIST_API}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                setDoctorSelect(res.data.data ? doctorList.concat(res.data.data.totalClaimInDoctorList) : doctorList);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const doctorSelectOptions =
        doctorSelect &&
        doctorSelect.map((item, i) => {
            return {
                value: item.id,
                label: item.firstName + " " + (item.lastName ? item.lastName : ""),
            };
        });

    const handleDelete = (id) => {
        Swal.fire({
            title: DELETE_CLAIM_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/${CLAIM_DETAIL}/${id}`, {
                            headers: { authorization: token.token },
                        })
                        .then((res) => {
                            Swal.fire(res.data.message, " ", "success");
                            fetchData();
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };
    const handleDoctorChange = async (e) => {
        sessionStorage.removeItem("claimDoctorId");
        sessionStorage.removeItem("claimPatientId");
        sessionStorage.removeItem("claimPagination");

        setDoctorId(e.value);
        setOffset(1);
        setCurrentPage(0);
    };

    const handlePageClick = (event) => {
        sessionStorage.removeItem("claimPagination");
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleClick = (id) => {
        // sessionStorage.setItem("offset", claimPagination ? claimPagination : offset);
        sessionStorage.setItem(
            "claimPagination",
            claimPagination
                ? JSON.stringify(claimPagination)
                : JSON.stringify({
                      offset: offset,
                      startDate: defaultTimeZone(filterValues.startDate),
                      endDate: defaultTimeZone(filterValues.endDate),
                      claimNoSearch: filterValues.claimNoSearch,
                      doctorId: doctorId,
                      patientSearch: filterValues.patientSearch,
                      officeSearch: filterValues.officeSearch,
                      isSubmitted: filterValues.status,
                  })
        );
        localStorage.setItem("claimId", id);
        localStorage.removeItem("claimPath");
        localStorage.setItem("claimPath", "claim");
    };

    const handleId = (id, officeId) => {
        sessionStorage.setItem(
            "claimPagination",
            claimPagination
                ? JSON.stringify(claimPagination)
                : JSON.stringify({
                      offset: offset,
                      startDate: defaultTimeZone(filterValues.startDate),
                      endDate: defaultTimeZone(filterValues.endDate),
                      claimNoSearch: filterValues.claimNoSearch,
                      doctorId: doctorId,
                      patientSearch: filterValues.patientSearch,
                      officeSearch: filterValues.officeSearch,
                      isSubmitted: filterValues.status,
                  })
        );
        localStorage.setItem("claimId", id);
        localStorage.setItem("officeId", officeId);
        navigate("/editClaim");
    };
    
    const checkedChange = (id, isSentNote) => {
        Swal.fire({
            title: isSentNote === 1 ? NOTE_SENT_CLAIM_MESSAGE : NOTE_NOT_SENT_CLAIM_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .post(`${baseURL}/${UPDATE_ISSENTNOTE_CLAIM_API}`, {
                            headers: { authorization: token.token },
                            claimId: id,
                            isSentNote: isSentNote,
                        })
                        .then((res) => {
                            Swal.fire(res.data.message, " ", "success");
                            fetchData();
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    useEffect(() => {
        if (claimDoctorId) {
            setDoctorId(claimDoctorId);
        }
        fetchData();
        fetchDoctorList();
    }, [offset, doctorId, filterValues]);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">Claim List</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">Claim List</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tabbable-line">
                                <div className="tab-content">
                                    <div className="tab-pane active fontawesome-demo" id="tab1">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card" style={isLoading ? { height: "35rem" } : {}}>
                                                    <div className="card-body ">
                                                        <div className="row">
                                                            <div className="justify-content-between">
                                                                <div className="row">
                                                                    <div className="btn-group col-md-4 pr-0">
                                                                        <div className="col-md-4">
                                                                            <label className="mt-2">{CREATED_DATE}</label>
                                                                        </div>
                                                                        <DatePicker
                                                                            className="form-control mr-sm-2"
                                                                            selected={filterValues.startDate}
                                                                            // maxDate={new Date()}
                                                                            maxDate={filterValues.endDate}
                                                                            yearDropdownItemNumber={100}
                                                                            scrollableMonthDropdown={true}
                                                                            showYearDropdown
                                                                            showMonthDropdown
                                                                            onChange={(date) => {
                                                                                sessionStorage.setItem(
                                                                                    "claimPagination",
                                                                                    JSON.stringify({
                                                                                        ...filterValues,
                                                                                        startDate: date,
                                                                                    })
                                                                                );
                                                                                setFilterValues({ ...filterValues, startDate: date });
                                                                            }}
                                                                        />
                                                                        <span className="todate">To</span>
                                                                        <DatePicker
                                                                            className="form-control mr-sm-2"
                                                                            selected={filterValues.endDate}
                                                                            minDate={filterValues.startDate}
                                                                            maxDate={new Date()}
                                                                            yearDropdownItemNumber={100}
                                                                            scrollableMonthDropdown={true}
                                                                            showYearDropdown
                                                                            showMonthDropdown
                                                                            onChange={(date) => {
                                                                                sessionStorage.setItem(
                                                                                    "claimPagination",
                                                                                    JSON.stringify({
                                                                                        ...filterValues,
                                                                                        endDate: date,
                                                                                    })
                                                                                );
                                                                                setFilterValues({ ...filterValues, endDate: date });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div className="col-md-2 mr-sm-2">
                                                                        <input
                                                                            className="form-control mr-sm-2"
                                                                            type="search"
                                                                            name="claimNoSearch"
                                                                            value={filterValues.claimNoSearch}
                                                                            placeholder="Claim #"
                                                                            onChange={handleFilterChange}
                                                                        />
                                                                    </div>

                                                                    <div className="col-md-2 mr-sm-2">
                                                                        <Select
                                                                            name="doctorSelect"
                                                                            value={doctorSelectOptions && doctorSelectOptions.filter(({ value }) => value == doctorId)}
                                                                            options={doctorSelectOptions}
                                                                            onChange={handleDoctorChange}
                                                                            placeholder={SELECT_DOCTOR}
                                                                        />
                                                                    </div>

                                                                    <div className="col-md-3 mr-sm-2">
                                                                        <div className="row">
                                                                            {claimPatientId ? (
                                                                                ""
                                                                            ) : (
                                                                                <div className="btn-group col-md-6">
                                                                                    <input
                                                                                        className="form-control mr-sm-2"
                                                                                        type="search"
                                                                                        name="patientSearch"
                                                                                        value={filterValues.patientSearch}
                                                                                        placeholder="Patient"
                                                                                        onChange={handleFilterChange}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                            <div className="btn-group col-md-6">
                                                                                <input
                                                                                    className="form-control mr-sm-2"
                                                                                    type="search"
                                                                                    name="officeSearch"
                                                                                    value={filterValues.officeSearch}
                                                                                    placeholder="Office"
                                                                                    onChange={handleFilterChange}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="btn-group col-md-1">
                                                                        <select className="form-control" name="status" value={filterValues.status} onChange={handleFilterChange}>
                                                                            <option value="">Submitted </option>
                                                                            {Object.keys(BOOL_VALUES).map((item, i) => {
                                                                                return (
                                                                                    <option key={i} value={BOOL_VALUES[item]}>
                                                                                        {item}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-scrollable" style={isLoading ? { border: "none" } : {}}>
                                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{CLAIM_}</th>
                                                                        <th>{NOTE_SENT}</th>
                                                                        <th>{OFFICE}</th>
                                                                        <th>{DOCTOR}</th>
                                                                        <th>{PATIENT}</th>
                                                                        <th>{DATE_OF_SERVICE}</th>
                                                                        <th>{AMOUNT}</th>
                                                                        <th>{SUBMITTED_DATE}</th>
                                                                        <th>{CREATED_DATE}</th>
                                                                        <th>{CREATED_BY}</th>
                                                                        <th>{ACTION}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={isLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                                    {isLoading ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        <>
                                                                            {!claimList ? (
                                                                                <>{isLoading ? "" : <NoDataFound />}</>
                                                                            ) : (
                                                                                claimList &&
                                                                                claimList.map((item, index) => {
                                                                                    return (
                                                                                        <tr className="odd gradeX" key={index}>
                                                                                            <td className="left">
                                                                                                <Link
                                                                                                    to="/DetailClaim"
                                                                                                    style={{ textDecoration: "underline", color: "#2a32be" }}
                                                                                                    onClick={() => {
                                                                                                        handleClick(item.id);
                                                                                                    }}
                                                                                                >
                                                                                                    {item.code}
                                                                                                </Link>
                                                                                            </td>
                                                                                            <td className="center">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    className="checked-diagnoses checkBox mx-2"
                                                                                                    id={item.code}
                                                                                                    name={item.code}
                                                                                                    checked={item.isSentNote === 1 ? true : false}
                                                                                                    onChange={(e) => checkedChange(item.id, e.target.checked ? 1 : 0)}
                                                                                                    value={`${item.isSentNote}`}
                                                                                                />
                                                                                            </td>
                                                                                            <td className="left">{item.offices.name}</td>
                                                                                            <td className="left">
                                                                                                {item.doctor.firstName} {item.doctor.lastName}{" "}
                                                                                            </td>
                                                                                            <td className="left">
                                                                                                {item.patient.firstName} {item.patient.lastName}
                                                                                            </td>
                                                                                            <td className="left">{item.dateOfService ? dateFormateMonthDateYear(item.dateOfService) : DASH}</td>
                                                                                            <td className="left">${item.amount ? item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</td>
                                                                                            {/* <td className="left">
                                                                                                <span
                                                                                                    className={
                                                                                                        "badge " +
                                                                                                        (item.isSubmitted == 0
                                                                                                            ? "deactiveStatus badge-warning"
                                                                                                            : item.isSubmitted == 1
                                                                                                            ? "activeStatus badge-success"
                                                                                                            : "badge-danger")
                                                                                                    }
                                                                                                >
                                                                                                    {item.isSubmitted === 1 ? YES : NO}
                                                                                                </span>
                                                                                            </td> */}
                                                                                            <td className="left">{item.isSubmitted == 1 ? dateFormateMonthDateYear(item.submitted_date) : DASH}</td>
                                                                                            <td className="left">{dateFormateMonthDateYear(item.created_at)}</td>
                                                                                            <td>{item.create_by ? item.create_by : DASH}</td>
                                                                                            <td>
                                                                                                {/* {item.isSubmitted === 1 && claim24hrDate(item.submitted_date) < claim24hrDate(new Date()) ? ( */}
                                                                                                {item.isSubmitted === 1 && claim12hrDateTime(item.submitted_date) < currentDateFormate(new Date()) ? (
                                                                                                    ""
                                                                                                ) : (
                                                                                                    <div
                                                                                                        className="btn btn-primary btn-xs"
                                                                                                        onClick={() => handleId(item.id, item.offices.uniqueId, item.doctor.uniqueId)}
                                                                                                    >
                                                                                                        <i className="fa fa-pencil"></i>
                                                                                                    </div>
                                                                                                )}
                                                                                                {/* {item.isSubmitted === 1 && claim24hrDate(item.submitted_date) < claim24hrDate(new Date()) ? ( */}
                                                                                                {item.isSubmitted === 1 && claim12hrDateTime(item.submitted_date) < currentDateFormate(new Date()) ? (
                                                                                                    ""
                                                                                                ) : (
                                                                                                    <button className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
                                                                                                        <i className="fa fa-trash-o"></i>
                                                                                                    </button>
                                                                                                )}
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mx-4">
                                                        <div>Total {claimList == null ? "0" : rowCount.toLocaleString("en-US")} Claims</div>
                                                        <div className="pagination-center">
                                                            <ReactPaginate
                                                                initialPage={0}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={0}
                                                                pageRangeDisplayed={3}
                                                                onPageChange={handlePageClick}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                pageLinkClassName="page-link"
                                                                breakLinkClassName="page-link"
                                                                nextLinkClassName="page-link"
                                                                previousLinkClassName="page-link"
                                                                pageClassName="page-item"
                                                                breakClassName="page-item"
                                                                nextClassName="page-item"
                                                                previousClassName="page-item"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"
                                                                disableInitialCallback={true}
                                                                forcePage={claimPagination && claimPagination.offset ? claimPagination.offset - 1 : currentPage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminManageClaim;
