import axios from "axios";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../../Config";
import {
  CHANGED_FIELD_RESPONSE_API,
  UPDATE_PRE_AUTHORIZATION_STATUS,
  UPDATE_PRE_AUTHORIZATION_STATUS_DOCTOR,
} from "../../../Helper/ApiPath";
import {
  AUTH,
  CLOSE,
  COMMENT,
  PRE_AUTHORIZATION_STATUS,
  PRE_AUTH_STATUS,
  ROLES,
  SAVE,
  STATUS,
} from "../../../Helper/Constants";
import Cookies from "universal-cookie";

function UpdateStatus(props) {
  const token = JSON.parse(localStorage.getItem("logToken"));
  const navigate = useNavigate();

  const cookies = new Cookies();
  let adminRole = localStorage.getItem("adminRole");
  let role = cookies.get("role");
  if (role) {
    role = cookies.get("role").roleId;
  }

  const handleSave = async () => {
    const difference = (obj1, obj2) => {
      let keyFound = [];
      Object.keys(obj1).map((key) => {
        if (obj1[key] !== obj2[key]) {
          keyFound.push(key);
        }
      });
      return keyFound;
    };
    const apiUrl =
      role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR
        ? UPDATE_PRE_AUTHORIZATION_STATUS_DOCTOR
        : UPDATE_PRE_AUTHORIZATION_STATUS;
    await axios
      .post(`${baseURL}/${apiUrl}`, {
        headers: { authorization: token.token },
        statusId: props.preAuthorization.statusId,
        comment: props.preAuthorization.comment,
        auth: props.preAuthorization.auth,
        preAuthorizationNoteId: props.preAuthorizationId,
        changedFields:
          difference(props.preAuthorization, props.oldPreAuth) &&
          difference(props.preAuthorization, props.oldPreAuth).length > 0
            ? JSON.stringify(
                difference(props.preAuthorization, props.oldPreAuth)
              )
            : null,
      })
      .then((res) => {
        toast.success(res.data.message);
        props.handleClose();
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const handleCloseOk = async () => {
    if (
      (role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) &&
      props.changedFields
    ) {
      await axios
        .post(`${baseURL}/${CHANGED_FIELD_RESPONSE_API}`, {
          headers: { authorization: token.token },
          preAuthorizationNoteId: props.preAuthorizationId,
        })
        .then(() => {
          props.handleClose();
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status == 500) {
              localStorage.clear();
              navigate("/login");
            } else {
              toast.error(err.response.data.message);
            }
          }
        });
    } else {
      props.handleClose();
    }
  };

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{PRE_AUTHORIZATION_STATUS}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row">
            <label className="control-label col-md-3">
              {STATUS}
              {props.changedFields &&
              props.changedFields.includes("statusId") &&
              (role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) ? (
                <img
                  src="assets/img/new.gif"
                  style={{ height: "20px", width: "30px", paddingLeft: "5px" }}
                />
              ) : (
                ""
              )}
            </label>
            <div className="col-md-9">
              <select
                className="form-control"
                name="statusId"
                value={props.preAuthorization.statusId}
                onChange={props.handleChange}
                // disabled={`${
                //   role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR
                //     ? "disabled"
                //     : ""
                // }`}
              >
                {Object.keys(PRE_AUTH_STATUS)
                  .filter((status) => {
                    console.log(
                      "status",
                      status,
                      PRE_AUTH_STATUS[status],
                      props.preAuthorization.statusId,
                      role,
                      adminRole
                    );
                    if (
                      (role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) &&
                      PRE_AUTH_STATUS[status] !==
                        props.preAuthorization.statusId &&
                      status !== "Pending"
                    ) {
                      return false;
                    }

                    return true;
                  })
                  .map((item, i) => {
                    return (
                      <option key={i} value={PRE_AUTH_STATUS[item]}>
                        {item}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="form-group row">
            <label className="control-label col-md-3">
              <div className="row">
                <div className="col-8">{COMMENT}</div>
                <div className="col-4">
                  {props.changedFields &&
                  props.changedFields.includes("comment") &&
                  (role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) ? (
                    <img
                      src="assets/img/new.gif"
                      style={{
                        height: "20px",
                        width: "30px",
                        paddingLeft: "5px",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </label>
            <div className="col-md-9">
              <textarea
                type="text"
                rows="10"
                cols="50"
                name="comment"
                value={props.preAuthorization && props.preAuthorization.comment}
                onChange={props.handleChange}
                data-required="1"
                placeholder="Enter Comment"
                className="form-control"
                disabled={`${
                  role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR
                    ? "disabled"
                    : ""
                }`}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="control-label col-md-3">
              {AUTH}{" "}
              {props.changedFields &&
              props.changedFields.includes("auth") &&
              (role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) ? (
                <img
                  src="assets/img/new.gif"
                  style={{ height: "20px", width: "30px", paddingLeft: "5px" }}
                />
              ) : (
                ""
              )}
            </label>
            <div className="col-md-9">
              <input
                type="text"
                name="auth"
                value={props.preAuthorization && props.preAuthorization.auth}
                onChange={props.handleChange}
                data-required="1"
                placeholder="Enter Auth"
                className="form-control input-height"
                disabled={`${
                  role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR
                    ? "disabled"
                    : ""
                }`}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleSave}>
            {SAVE}
          </Button>
          {/* {role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? (
            <></>
          ) : (
            <Button variant="success" onClick={handleSave}>
              {SAVE}
            </Button>
          )} */}
          <Button variant="default" onClick={handleCloseOk}>
            {CLOSE}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateStatus;
