import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../Config";

import { CITY_LIST_WITHOUT_PAGINATION, EDIT_OFFICE_DETAIL_API, OFFICE_DETAIL_API, STATE_LIST_WITHOUT_PAGINATION } from "../../Helper/ApiPath";
import { ADDRESS, CANCEL, CITY, EDIT_OFFICE, EDIT_OFFICE_DETAILS, HOME, OFFICE_LIST, OFFICE_NAME, PHONE_LENGTH, PHONE_NO, SELECT, STATE, UPDATE, ZIP } from "../../Helper/Constants";
import { DOCTOR_ID_NOT_FOUND, ENTER_ADDRESS, ENTER_CITY, ENTER_OFFICE_NAME, ENTER_PHONE, ENTER_STATE, ENTER_ZIP, PHONE_NUMBER } from "../../Helper/Messages";

function DoctorEditOffice() {
    let officeId = localStorage.getItem("doctorOfficeId");
    let doctorId = localStorage.getItem("doctorId");
    let logToken = JSON.parse(localStorage.getItem("logToken")).token;
    let navigate = useNavigate();

    const [officeInfo, setOfficeInfo] = useState({
        name: "",
        phoneNo1: "",
        address: "",
        stateId: "",
        cityId: "",
        zip: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setOfficeInfo({ ...officeInfo, [name]: value });
    };

    const fetchData = async () => {
        await axios
            .get(`${baseURL}/${OFFICE_DETAIL_API}/${officeId}`, {
                headers: { authorization: logToken, "Content-Type": "multipart/form-data" },
            })
            .then((res) => {
                setOfficeInfo(res.data.data);
                setStateId(res.data.data.stateId);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleUpdate = async () => {
        if (!doctorId) {
            toast.error(DOCTOR_ID_NOT_FOUND);
        } else if (!officeInfo.name) {
            toast.error(ENTER_OFFICE_NAME);
        } else if (!officeInfo.phoneNo1) {
            toast.error(ENTER_PHONE);
        } else if (!officeInfo.address) {
            toast.error(ENTER_ADDRESS);
        } else if (!officeInfo.stateId) {
            toast.error(ENTER_STATE);
        } else if (!officeInfo.cityId) {
            toast.error(ENTER_CITY);
        } else if (!officeInfo.zip) {
            toast.error(ENTER_ZIP);
        } else if (officeInfo.phoneNo1.length > PHONE_LENGTH) {
            toast.error(PHONE_NUMBER);
        } else {
            await axios
                .post(`${baseURL}/${EDIT_OFFICE_DETAIL_API}`, {
                    headers: { authorization: logToken, "Content-Type": "multipart/form-data" },
                    doctorId: doctorId,
                    officeId: officeId,
                    name: officeInfo.name,
                    phoneNo1: officeInfo.phoneNo1,
                    address: officeInfo.address,
                    state: officeInfo.stateId,
                    city: officeInfo.cityId,
                    zip: officeInfo.zip,
                })

                .then((res) => {
                    if (res.status === 200) {
                        toast.success(res.data.message);
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const handleCancel = () => {
        navigate("/manageOffice");
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    ////////////////////////////////////////////////////// STATE-CITY LIST /////////////////////
    const [stateList, setStateList] = useState([]);

    const stateData = async () => {
        await axios
            .post(`${baseURL}/${STATE_LIST_WITHOUT_PAGINATION}`)
            .then((res) => {
                setStateList(res.data.data);
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const [cityList, setCityList] = useState([]);
    const [stateId, setStateId] = useState([]);

    const handleStateChange = (e) => {
        setOfficeInfo({ ...officeInfo, stateId: e.target.value, cityId: "" });
        setCityList("");
        cityData();
        setStateId(document.getElementsByName("stateId")[0].value);
    };

    const cityData = async () => {
        axios
            .post(`${baseURL}/${CITY_LIST_WITHOUT_PAGINATION}`, {
                stateId: stateId,
                search: "",
            })
            .then((res) => {
                setCityList(res.data.data);
            });
    };

    useEffect(() => {
        stateData();
        cityData();
    }, [stateId]);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className="pull-left">
                                <div className="page-title">{EDIT_OFFICE}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li>
                                    <i class="fa fa-briefcase"></i>&nbsp;
                                    <NavLink className="parent-item" to="/manageOffice">
                                        {OFFICE_LIST}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{EDIT_OFFICE}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="card card-box">
                                <div className="card-head">
                                    <header>{EDIT_OFFICE_DETAILS}</header>
                                </div>
                                <div className="card-body" id="bar-parent">
                                    {officeInfo && officeInfo.isPending == 1 ? (
                                        <div className="row">
                                            <div className="col-md-5 m-auto mt-4 text-center">
                                                <div className="badge-warningofc">
                                                    <span>
                                                        <i className="fa fa-warning"></i>Your update request for Office details is under review.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <form action="#" id="form_sample_1" className="form-horizontal">
                                        <div className="form-body">
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {OFFICE_NAME}
                                                    <span className="required"> * </span>
                                                </label>

                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        placeholder=" Enter office name"
                                                        value={officeInfo.name}
                                                        onChange={handleChange}
                                                        className="form-control input-height"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {PHONE_NO}
                                                    <span className="required"> * </span>
                                                </label>

                                                <div className="col-md-5">
                                                    <input
                                                        type="number"
                                                        name="phoneNo1"
                                                        placeholder=" Enter phone number"
                                                        value={officeInfo.phoneNo1}
                                                        onChange={handleChange}
                                                        className="form-control input-height"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {ADDRESS}
                                                    <span className="required"> * </span>
                                                </label>

                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="address"
                                                        placeholder=" Enter address"
                                                        value={officeInfo.address}
                                                        onChange={handleChange}
                                                        className="form-control input-height"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {STATE}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <select className="form-control input-height" name="stateId" onChange={handleStateChange} value={officeInfo && officeInfo.stateId}>
                                                        <option value="">{SELECT}</option>
                                                        {stateList &&
                                                            stateList.map((item, i) => {
                                                                return (
                                                                    <option key={i} value={item.id}>
                                                                        {item.name}
                                                                    </option>
                                                                );
                                                            })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {CITY}
                                                    <span className="required"> * </span>
                                                </label>

                                                <div className="col-md-5">
                                                    <select className="form-control input-height" name="cityId" value={officeInfo && officeInfo.cityId} onChange={handleChange}>
                                                        <option value=""> {SELECT}</option>
                                                        {cityList &&
                                                            cityList.map((item, i) => {
                                                                return (
                                                                    <option key={i} value={item.id}>
                                                                        {item.name}
                                                                    </option>
                                                                );
                                                            })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {ZIP}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <input name="zip" type="text" placeholder="Enter ZIP number" onChange={handleChange} value={officeInfo.zip} className="form-control input-height" />
                                                </div>
                                            </div>

                                            <div className="row mt-3 mb-0 d-flex justify-content-center">
                                                <div className="col-1 mx-2 my-3">
                                                    <button type="button" className="btn btn-primary" onClick={handleUpdate}>
                                                        {UPDATE}
                                                    </button>
                                                </div>
                                                <div className="col-1 mx-3 my-3">
                                                    <button type="button" className="btn btn-default" onClick={handleCancel}>
                                                        {CANCEL}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DoctorEditOffice;
