import React from "react";
import { ROLES } from "../../Helper/Constants";
import AdminSideBar from "./AdminSideBar";
import DoctorSidebar from "./DoctorSidebar";
import EmployeeSideBar from "./EmployeeAdminSideBar";
import Cookies from "universal-cookie";

export default function Sidebar(props) {
    let adminRole = localStorage.getItem("adminRole");
    const cookies = new Cookies();

    let role = cookies.get("role");
    if (role) {
        role = cookies.get("role").roleId;
    }
    return (
        <>
            {role == ROLES.ADMIN && adminRole == ROLES.ADMIN ? (
                <AdminSideBar image={props.image} doctorFirstName={props.doctorFirstName} doctorLastName={props.doctorLastName} />
            ) : role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? (
                <DoctorSidebar image={props.image} doctorFirstName={props.doctorFirstName} doctorLastName={props.doctorLastName} />
            ) : (role == ROLES.EMPLOYEE_ADMIN && adminRole == ROLES.EMPLOYEE_ADMIN) || role == ROLES.EMPLOYEE ? (
                <EmployeeSideBar props={props} />
            ) : (
                <DoctorSidebar image={props.image} doctorFirstName={props.doctorFirstName} doctorLastName={props.doctorLastName} />
            )}
        </>
    );
}
