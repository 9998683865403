import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { baseURL } from "../../Config";
import { DOCTOR_PAGES, EMAIL, LOGIN, PASSWORD, POPUPSTATUS, REGISTER, ROLES, VERIFY_DOCUMET } from "../../Helper/Constants";
import { ALL_DOCTOR_IN_SELECTED_OFFICE, DOCTOR_NOTICE_API, FIND_DOCTOR_AFTER_LOGIN, LOGIN_API, OFFICE_TABLE_LIST } from "../../Helper/ApiPath";
import { ENTER_EMAIL, ENTER_PASSWORD } from "../../Helper/Messages";
import Recaptcha from "react-recaptcha";
import Cookies from "universal-cookie";
import { dateFormateMonthDateYear } from "../../Helper/Helper";
import _ from "lodash";

function Login() {
    const [isLoading, setIsLoading] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [deviceType, setDeviceType] = useState("");
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const navigate = useNavigate();
    var arr = [];

    const [ip, setIP] = useState("");

    //creating function to load ip address from the API
    const getData = async () => {
        const res = await axios.get("https://geolocation-db.com/json/");
        setIP(res.data.IPv4);
    };

    const showPassword = () => {
        var pswd = document.getElementById("password");
        if (pswd.type === "password") {
            pswd.type = "text";
        } else {
            pswd.type = "password";
        }
    };
    const CryptoJS = require("crypto-js");
    let popUpId = [];
    const cookies = new Cookies();

    let checkedPopUp = cookies.get("checkedPopUP");

    let currentDate = new Date();

    const getPopUpId = async (token) => {
        await axios
            .post(`${baseURL}/${DOCTOR_NOTICE_API}?isPagination=true`, {
                headers: { authorization: token },
            })
            .then(async (res) => {
                let response = res.data.data;
                response.map((item, i) => {
                    return item.popUp == POPUPSTATUS.Yes ? popUpId.push({ date: dateFormateMonthDateYear(currentDate), noticeId: item.id, email: email }) : "";
                });
                if (checkedPopUp && !checkedPopUp.lenth) {
                    popUpId = popUpId.filter(
                        (item) => !checkedPopUp.some((checkedPopUp) => checkedPopUp.noticeId === item.noticeId && checkedPopUp.date === item.date && checkedPopUp.email === item.email)
                    );
                }
                localStorage.setItem("popUpId", JSON.stringify(popUpId));
            })
            .catch((err) => {
                console.log("err", err);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const LoginFormSubmit = async (e) => {
        e.preventDefault();

        // const deviceId = "a13d7901f8c53a08aec";
        // const clientIp = ip;
        // console.log("deviceID", deviceId);

        const encrptPass = CryptoJS.AES.encrypt(JSON.stringify(password), "adminP").toString();
        var pass = CryptoJS.AES.decrypt(encrptPass, "adminP");
        var decryptPass = JSON.parse(pass.toString(CryptoJS.enc.Utf8));
        if (!email) {
            toast.error(ENTER_EMAIL);
        } else if (!password) {
            toast.error(ENTER_PASSWORD);
        } else {
            setIsLoading(true);
            axios
                .post(`${baseURL}/${LOGIN_API}`, {
                    email: email,
                    password: decryptPass,
                    deviceType: deviceType,
                    // deviceId: deviceId,
                })
                .then(async (resonse) => {
                    setIsLoading(false);
                    localStorage.removeItem("officeId");
                    localStorage.removeItem("patientId");
                    localStorage.removeItem("doctorId");
                    localStorage.removeItem("doctorPracticeId");
                    localStorage.removeItem("doctorFirstName");
                    localStorage.removeItem("doctorLastName");
                    localStorage.removeItem("isPractice");
                    cookies.set("isTrial", { isTrial: resonse.data.data.isTrial, token: resonse.data.data.token });

                    const data = { email: email, roleId: resonse.data.data.roleId, token: resonse.data.data.token, pageAuthorityRoleId: resonse.data.data.pageAuthorityRoleId };
                    const cookieData = { email: email, roleId: resonse.data.data.roleId, token: resonse.data.data.token, pageAuthorityRoleId: resonse.data.data.pageAuthorityRoleId };
                    const token = resonse.data.data.token;
                    cookies.set("role", cookieData);
                    localStorage.setItem("DoctorToken", JSON.stringify(token));
                    localStorage.setItem("logToken", JSON.stringify(data));
                    localStorage.setItem("adminRole", resonse.data.data.roleId);
                    if (resonse.data.data.step !== 3) {
                        localStorage.setItem("step", resonse.data.data.step);
                    }

                    if (resonse.data.data.roleId == ROLES.ADMIN || resonse.data.data.roleId == ROLES.EMPLOYEE_ADMIN || resonse.data.data.roleId == ROLES.EMPLOYEE) {
                        cookies.set("pageAccess", resonse.data.data.pageAccess);
                        navigate("/dashboard");
                        toast.success(resonse.data.message);
                        window.location.reload();

                        // change //
                        localStorage.setItem("payerName", null);
                        localStorage.setItem("payerId", null);
                        localStorage.setItem("payerValue", null);
                    } else if (resonse.data.data.roleId === ROLES.DOCTOR) {
                        cookies.set("doctorPageAccess", resonse.data.data.pageAccess);
                        if (resonse.data.data.step === 1) {
                            navigate("/register");
                        } else if (resonse.data.data.step == ROLES.DOCTOR) {
                            navigate("/register");
                        } else {
                            await axios
                                .post(`${baseURL}/${OFFICE_TABLE_LIST}`, {
                                    headers: { authorization: token },
                                })
                                .then(async (res) => {
                                    if (res.data.data.length > 1 && resonse.data.data.isTrial !== 2) {
                                        navigate("/chooseDoctor");
                                    } else {
                                        let officeid = res.data.data[0].id;
                                        await axios
                                            .post(`${baseURL}/${ALL_DOCTOR_IN_SELECTED_OFFICE}`, {
                                                headers: { authorization: token },
                                                officeId: officeid,
                                                isTrial: resonse.data.data.isTrial == 1 ? true : false,
                                                isDoctor: resonse.data.data.isTrial == 2 || resonse.data.data.isTrial == 1 ? false : true,
                                            })
                                            .then(async (res) => {
                                                if (res.data.data.length > 1 && resonse.data.data.isTrial !== 2) {
                                                    navigate("/chooseDoctor");
                                                } else {
                                                    let doctorId = resonse.data.data.isTrial == 2 ? resonse.data.data.uniqueId : res.data.data[0].id;
                                                    if (resonse.data.data.isTrial == 2) {
                                                        localStorage.setItem("doctorId", resonse.data.data.uniqueId);
                                                    } else {
                                                        localStorage.setItem("doctorId", doctorId);
                                                    }
                                                    localStorage.setItem("officeId", officeid);
                                                    await axios
                                                        .post(`${baseURL}/${FIND_DOCTOR_AFTER_LOGIN}`, {
                                                            headers: { authorization: token },
                                                            officeId: officeid,
                                                            doctorId: doctorId,
                                                        })
                                                        .then(async (res) => {
                                                            if (res.status === 200) {
                                                                localStorage.setItem("doctorPracticeId", res.data.data.id);
                                                                localStorage.setItem("payerName", res.data.data.payerName);
                                                                localStorage.setItem("payerId", res.data.data.payerId);
                                                                localStorage.setItem("payerValue", res.data.data.payerValue);
                                                                await getPopUpId(token);
                                                                if (resonse.data.data.isTrial == 0 || (resonse.data.data.pageAccess && resonse.data.data.pageAccess.includes(DOCTOR_PAGES.Dashboard))) {
                                                                    navigate("/dashboard");
                                                                } else {
                                                                    navigate("/managePatient");
                                                                }
                                                                window.location.reload();
                                                            }
                                                        })
                                                        .catch((err) => {
                                                            toast.error(err.response.data.message);
                                                        });
                                                }
                                            })
                                            .catch((err) => {
                                                if (err.response) {
                                                    if (err.response.status == 500) {
                                                        localStorage.clear();
                                                        navigate("/login");
                                                    } else {
                                                        toast.error(err.response.data.message);
                                                    }
                                                }
                                            });
                                    }
                                })
                                .catch((err) => {
                                    if (err.response) {
                                        if (err.response.status == 500) {
                                            localStorage.clear();
                                            navigate("/login");
                                        } else {
                                            toast.error(err.response.data.message);
                                        }
                                    }
                                });
                        }
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const handleRegister = () => {
        localStorage.removeItem("logToken");
        localStorage.removeItem("step");
        localStorage.removeItem("DoctorToken");
        localStorage.removeItem("AdminRoleID");
        localStorage.removeItem("officeId");
        localStorage.removeItem("doctorId");
        localStorage.removeItem("doctorPracticeId");
        localStorage.removeItem("doctorFirstName");
        localStorage.removeItem("doctorLastName");
        localStorage.removeItem("isPractice");
        navigate("/register");
    };

    useEffect(async () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent)) {
            setDeviceType("Mobile");
        } else {
            setDeviceType("Desktop");
        }

        const token = JSON.parse(localStorage.getItem("DoctorToken"));

        let role = cookies.get("role");
        if (role) {
            role = cookies.get("role").roleId;
        }

        // const doctorPracticeId = localStorage.getItem("doctorPracticeId");
        // let registerStep = localStorage.getItem("step");
        // if (token && role == ROLES.DOCTOR && registerStep == 3) {
        //     if (!doctorPracticeId) {
        //         await axios
        //             .post(`${baseURL}/${OFFICE_TABLE_LIST}`, {
        //                 headers: { authorization: token },
        //             })
        //             .then(async (res) => {
        //                 console.log("res", res.data.data);
        //                 if (res.data.data.length > 1) {
        //                     navigate("/chooseDoctor");
        //                 } else {
        //                     let officeid = res.data.data[0].id;
        //                     await axios
        //                         .post(`${baseURL}/${ALL_DOCTOR_IN_SELECTED_OFFICE}`, {
        //                             headers: { authorization: token },
        //                             officeId: officeid,
        //                             isDoctor: true,
        //                         })
        //                         .then(async (res) => {
        //                             if (res.data.data.length > 1) {
        //                                 navigate("/chooseDoctor");
        //                             } else {
        //                                 let doctorId = res.data.data[0].id;
        //                                 localStorage.setItem("officeId", officeid);
        //                                 localStorage.setItem("doctorId", doctorId);
        //                                 await axios
        //                                     .post(`${baseURL}/${FIND_DOCTOR_AFTER_LOGIN}`, {
        //                                         headers: { authorization: token },
        //                                         officeId: officeid,
        //                                         doctorId: doctorId,
        //                                     })
        //                                     .then((res) => {
        //                                         if (res.status === 200) {
        //                                             localStorage.setItem("doctorPracticeId", res.data.data.id);
        //                                         }
        //                                     })
        //                                     .catch((err) => {
        //                                         if (err.response) {
        //                                             if (err.response.status == 500) {
        //                                                 localStorage.clear();
        //                                                 navigate("/login");
        //                                             } else {
        //                                                 toast.error(err.response.data.message);
        //                                             }
        //                                         }
        //                                     });
        //                             }
        //                         })
        //                         .catch((err) => {
        //                             if (err.response) {
        //                                 if (err.response.status == 500) {
        //                                     localStorage.clear();
        //                                     navigate("/login");
        //                                 } else {
        //                                     toast.error(err.response.data.message);
        //                                 }
        //                             }
        //                         });
        //                 }
        //             })
        //             .catch((err) => {
        //                 if (err.response) {
        //                     if (err.response.status == 500) {
        //                         localStorage.clear();
        //                         navigate("/login");
        //                     } else {
        //                         toast.error(err.response.data.message);
        //                     }
        //                 }
        //             });
        //     } else {
        //         navigate("/dashboard");
        //     }
        // }

        if (token && role == ROLES.ADMIN) {
            navigate("/dashboard");
            window.location.reload();
        }
    }, [deviceType]);

    return (
        <>
            <div className="limiter">
                <div className="container-login100 page-background">
                    <div className="wrap-login100">
                        <form className="login100-form validate-form" onSubmit={LoginFormSubmit} id="LoginForm">
                            <span className="login100-form-logo">
                                <img alt="" src="assets/img/logo/login-logo.png" />
                            </span>
                            <span className="login100-form-title p-b-34 p-t-27">{LOGIN}</span>

                            <div className="form-group">
                                <i className="fa fa-envelope font-left textColor" aria-hidden="true"></i>
                                <span className="textColor">{EMAIL}</span>
                                <span className="requireField"> * </span>
                                <input type="text" className="form-control input-height" id="txtemail" name="email" placeholder="Enter Email.." onChange={(e) => setEmail(e.target.value)} />
                            </div>

                            <div className="form-group">
                                <i className="fa fa-lock font-left textColor" aria-hidden="true"></i>
                                <span className="textColor">{PASSWORD}</span>
                                <span className="requireField"> * </span>

                                <div style={{ display: "flex" }}>
                                    <input
                                        type={passwordShown ? "text" : "password"}
                                        className="form-control input-height"
                                        id="password"
                                        name="password"
                                        placeholder="Enter Password.."
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <i
                                        className={passwordShown ? "input-group-text input-height fa fa-eye" : "input-group-text input-height fa fa-eye-slash"}
                                        id="basic-addon1"
                                        onClick={togglePassword}
                                    ></i>
                                </div>
                            </div>
                            {isLoading ? (
                                <>
                                    <i
                                        className="fa fa-spinner fa-spin fa-2x"
                                        style={{
                                            position: "relative",
                                            top: "44px",
                                            left: "47%",
                                            zIndex: "5",
                                        }}
                                    ></i>

                                    <div className="container-login100-form-btn">
                                        <button className="login100-form-btn" disabled>
                                            {LOGIN}
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="container-login100-form-btn">
                                        <button className="login100-form-btn">{LOGIN}</button>
                                    </div>
                                </>
                            )}
                            <div className="text-center p-t-30">
                                <Link className="txt1" to="/resetPassword">
                                    Forgot Password?
                                </Link>
                                <br />
                                <button onClick={handleRegister} className="txt1">
                                    {" "}
                                    {REGISTER}{" "}
                                </button>
                            </div>
                        </form>

                        <Recaptcha sitekey="6Lf9RssfAAAAAGgaNQSd6CL_MpDfa9rmgCxgHqMQ" size="invisible" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
