import React from "react";
import { Link } from "react-router-dom";

export default function ErrorPage() {
    return (
        <div className="limiter">
            <div className="container-login100">
                <div className="wrap-login100">
                    <form className="form-404">
                        <span className="login100-form-logo">
                            <img alt="" src="assets/img/logo/login-logo.png" />
                        </span>
                        <span className="form404-title p-b-34 p-t-27">Error 404</span>
                        <p className="content-404">The page you are looking for does't exist or an other error occurred.</p>
                        <div className="container-login100-form-btn">
                            <button className="login100-form-btn">
                                <Link to="/login">Go to home page</Link>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
