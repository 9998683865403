import axios from "axios";
import _, { forEach } from "lodash";
import React, { useEffect, useState } from "react";

import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../../Config";
import {
  ALL_DOCTOR_IN_SELECTED_OFFICE,
  DIAGNOSES_API,
  PATIENT_LIST_API,
  PROCEDURE_LIST_API,
  PATIENT_PROFILE,
  PRE_AUTHORIZATION_NOTE_DATA_API,
  CHECK_DUPLICATE_VALUE_IN_PRE_AUTHORIZATION_NOTE_API,
  CN_JSON_DATA,
  PROFILE_API,
  PATIENT_TABS_CONTENT_API,
  PATIENT_DOCUMENT_LIST,
  DELETE_PATIENT_DOCUMENT,
  MEDICATION_VALUE_LIST_API,
  ALLERGIES_VALUE_LIST_API,
  OFFICE_DETAIL_API,
  CHECK_DUPLICATE_VALUE_IN_POST_OP_NOTE_API,
} from "../../../Helper/ApiPath";
import {
  $FEE,
  ADD_PREAUTHORIZATION,
  PREAUTHORIZATION_INFORMATION,
  PREAUTHORIZATION_LIST,
  CODE,
  CPT,
  DATE_OF_SERVICE,
  DESCRIPTION,
  DIAGNOSES,
  PROCEDURE,
  HOME,
  MIN_PROCEDURE_LENGTH,
  NAME,
  NEXT,
  PATIENT_NAME,
  PROCEDURE_TYPE_WITH_VALUE,
  RENDERRING_PROVIDER,
  SELECT_DOCTOR,
  SELECT_PATIENT,
  UNIT,
  PREAUTHORIZATION_RESULT,
  FORMTYPE,
  PATIENT_DOCUMENTS,
  INSURANCE_FRONT,
  PATIENT_DOCUMENT_TYPE,
  INSURANCE_BACK,
  XRAY,
  OTHERS,
  NO,
  FILE_NAME,
  THUMBNAIL,
  ACTION,
  NOTE,
  ID,
  CREATED_DATE,
} from "../../../Helper/Constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import {
  DELETE_IMAGE_MESSAGE,
  ENTER_SERVICE_DATE,
  MAX_PRIORITY,
  SELECT_PATIENT_MESSAGE,
  SELECT_RENDERINGPROVIDER,
} from "../../../Helper/Messages";
import PostOpComprehensiveResult from "../ComprehensiveNotes/PostOpComprehensiveResult";
import Zoom from "react-medium-image-zoom";
import {
  dateAPIFormate,
  dateFormateMonthDateYear,
} from "../../../Helper/Helper";
import UploadImage from "../Patients/UploadImage";
import WebcamUpload from "../Patients/WebcamUpload";
import NoDataFound from "../../CommonComponent/NoDataFound";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";

function AddPostOpNote() {
  const cookies = new Cookies();
  const token = JSON.parse(localStorage.getItem("logToken"));
  const officeId = localStorage.getItem("officeId");
  const navigate = useNavigate();
  let checkedArray = [];
  let checkedValues = [];

  let pageAuthorityRoleId = cookies.get("role");
  if (pageAuthorityRoleId) {
    pageAuthorityRoleId = cookies.get("role").pageAuthorityRoleId;
  }

  var checkboxes = document.getElementsByClassName("checked-diagnoses");

  for (let i = 0; i < checkboxes.length; i++) {
    if (checkboxes[i].checked) {
      checkedValues.push(checkboxes[i].value);
      checkedArray.push({ code: checkboxes[i].id, name: checkboxes[i].name });
    }
  }
  const [step, setStep] = useState("1");
  const [patientName, setPatientName] = useState([]);
  const [patientId, setPatientId] = useState("");
  const [insuranceF, setInsuranceF] = useState([]);
  const [insuranceB, setInsuranceB] = useState([]);
  const [imageX, setImageX] = useState([]);
  const [imageN, setImageN] = useState([]);
  const [imageId, setImageId] = useState([]);
  const [imageOt, setImageOt] = useState([]);
  const [popUpTitle, setPopUpTitle] = useState("");
  const [imageType, setImageType] = useState("");
  const [documentId, setDocumentId] = useState("");
  const [show, setShow] = useState(false);
  const [office, setOffice] = useState({});
  const [webcame, setWebcame] = useState(false);
  const [edit, setEdit] = useState(false);
  let [image, setImage] = useState({ preview: "", data: "" });
  const [disabled, setDisabled] = useState(true);

  const [search, setSearch] = useState("");
  const [procedureSearch, setProcedureSearch] = useState("");

  const [procedureArray, setProcedureArray] = useState([]);
  const [medicationList, setMedicationList] = useState([]);
  const [allergiesList, setAllergiesList] = useState([]);
  const [questions, setQuestions] = useState([]);

  const [isPreAuthorize, setIsAuthorize] = useState(false);

  const handleImageChange = (e) => {
    const data = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
      name: e.target.files[0].name,
    };
    const fileExtension = data.data.name.split(".").at(-1).toLowerCase();
    let allowedFileTypes =
      imageType === PATIENT_DOCUMENT_TYPE.NOTE ||
      imageType === PATIENT_DOCUMENT_TYPE.INSURANCE_FRONT ||
      imageType === PATIENT_DOCUMENT_TYPE.INSURANCE_BACK ||
      imageType === PATIENT_DOCUMENT_TYPE.X_RAY ||
      imageType === PATIENT_DOCUMENT_TYPE.ID ||
      imageType === PATIENT_DOCUMENT_TYPE.OTHERS
        ? ["pdf", "jpg", "png", "gif", "jpeg"]
        : ["jpg", "png", "gif", "jpeg"];
    if (!allowedFileTypes.includes(fileExtension)) {
      e.target.value = null;
      alert(
        `File does not support. Files type must be ${allowedFileTypes.join(
          ", "
        )}`
      );
    } else {
      let element = document.getElementById("button");
      element.classList.remove("d-none");
      setImage(data);
    }
  };
  const fetchThumbnailData = async (type, id) => {
    axios
      .post(`${baseURL}/${PATIENT_DOCUMENT_LIST}`, {
        headers: { authorization: token.token },
        type: type,
        patientId: id,
      })
      .then((res) => {
        if (type === 1) {
          setInsuranceF(res.data.data);
        }
        if (type === PATIENT_DOCUMENT_TYPE.INSURANCE_BACK) {
          setInsuranceB(res.data.data);
        }
        if (type === PATIENT_DOCUMENT_TYPE.X_RAY) {
          setImageX(res.data.data);
        }
        if (type === PATIENT_DOCUMENT_TYPE.NOTE) {
          setImageN(res.data.data);
        }
        if (type === PATIENT_DOCUMENT_TYPE.ID) {
          setImageId(res.data.data);
        }
        if (type === PATIENT_DOCUMENT_TYPE.OTHERS) {
          setImageOt(res.data.data);
        }
      });
  };

  const handleDelete = (id, type) => {
    Swal.fire({
      title: DELETE_IMAGE_MESSAGE,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (result.isConfirmed) {
          await axios
            .delete(`${baseURL}/${DELETE_PATIENT_DOCUMENT}/${id}`, {
              headers: { authorization: token.token },
            })
            .then((res) => {
              Swal.fire(res.data.message, " ", "success");
              fetchThumbnailData(type, patientId);
            })
            .catch((err) => {
              if (err.response) {
                if (err.response.status === 500) {
                  localStorage.clear();
                  navigate("/login");
                } else {
                  toast.error(err.response.data.message);
                }
              }
            });
        }
      }
    });
  };

  const handleEdit = (title, id, type) => {
    setShow(true);
    setEdit(true);
    setPopUpTitle(title);
    setDocumentId(id);
    setImageType(type);
  };

  const handleClick = (title, type) => {
    setShow(true);
    setPopUpTitle(title);
    setImageType(type);
  };

  const handleClose = () => {
    setShow(false);
    setEdit(false);
  };

  const handleWebcameClick = (title, type) => {
    setWebcame(true);
    setPopUpTitle(title);
    setImageType(type);
  };

  const handleWebcameClose = () => {
    setWebcame(false);
  };
  const JsonData = async () => {
    await axios
      .post(`${baseURL}/${CN_JSON_DATA}`, {
        headers: { authorization: token.token },
        isCN: false,
      })
      .then((res) => {
        if (res.status === 200) {
          setQuestions(res.data.data);
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!addComprehensive.patientName) {
      toast.error(SELECT_PATIENT_MESSAGE);
    } else if (!addComprehensive.renderingProvider) {
      toast.error(SELECT_RENDERINGPROVIDER);
    } else if (!addComprehensive.serviceDate) {
      toast.error(ENTER_SERVICE_DATE);
    } else {
      let procedureArray = [];

      forEach(procedure || [], (item) => {
        forEach(item.data || [], (item) => {
          const parsedValue = parseFloat(item.value);
          if (item.value < 1) return;

          const index = procedureArray.findIndex((p) => p.code === item.code);
          console.log({
            parsedValue,
            itemValue: item.value,
            index,
          });
          if (index !== -1) {
            procedureArray[index].unit =
              parseFloat(procedureArray[index].unit) + parseFloat(item.value);
          } else {
            procedureArray.push({
              procedureId: item.id,
              type: item.type,
              code: item.code,
              description: item.description,
              unit: parsedValue,
            });
          }
        });
      });
      // procedure &&
      //     procedure.map((item) =>
      //         item.data.map((item) => (item.value > 0 ? procedureArray.push({ procedureId: item.id, type: item.type, code: item.code, description: item.description, unit: item.value }) : ""))
      //     );
      console.log(procedure, " procedure");
      console.log(procedureArray, " procedureArray");
      setProcedureArray(procedureArray);
      await axios
        .post(`${baseURL}/${CHECK_DUPLICATE_VALUE_IN_POST_OP_NOTE_API}`, {
          headers: { authorization: token.token },
          officeId: officeId,
          patientId: patientId,
          doctorId: doctorId,
          dateOfService: dateAPIFormate(addComprehensive.serviceDate),
        })
        .then(async (res) => {
          if (res.status == 200) {
            await axios
              .get(`${baseURL}/${MEDICATION_VALUE_LIST_API}/${patientId}`, {
                headers: { authorization: token.token },
              })
              .then(async (res) => {
                setMedicationList(res.data.data);

                await axios
                  .get(`${baseURL}/${ALLERGIES_VALUE_LIST_API}/${patientId}`, {
                    headers: { authorization: token.token },
                  })
                  .then((res) => {
                    setAllergiesList(res.data.data);
                  })
                  .catch((err) => {
                    if (err.response) {
                      if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                      } else {
                        toast.error(err.response.data.message);
                      }
                    }
                  });
              })
              .catch((err) => {
                if (err.response) {
                  if (err.response.status == 500) {
                    localStorage.clear();
                    navigate("/login");
                  } else {
                    toast.error(err.response.data.message);
                  }
                }
              });

            setIsAuthorize(true);
            setStep("2");
            document.getElementById("addPreauthorization").style.display =
              "none";
            document.getElementById("addPreauthorizationHeader").style.display =
              "none";
          }
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response) {
            if (err.response.status === 500) {
              localStorage.clear();
              navigate("/login");
            } else {
              toast.error(err.response.data.message);
            }
          }
        });
    }
  };

  const [procedure, setProcedure] = useState([]);

  const procedureList = async (id) => {
    axios
      .post(`${baseURL}/${PROCEDURE_LIST_API}`, {
        headers: { authorization: token.token },
        doctorId: id,
        statusCpt: "active",
        search: procedureSearch,
      })
      .then(async (res) => {
        let lstrocedure = res.data.data;
        const groupbydata = await _.chain(lstrocedure)
          .groupBy("cptgroupname")
          .map(function (value, key) {
            for (var i = 0; i < value.length; i++) {
              Object.assign(value[i], { value: 0 });
            }
            return {
              type: key,
              data: value,
            };
          })
          .value();

        setProcedure(groupbydata);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const handleProcedureChange = async (e, Fid) => {
    let value = e.target.value;

    // if (value && parseFloat(value) > MAX_PROCEDURE_LENGTH) {
    //     e.target.value = 0;
    //     value = 0;
    // } else
    if (value && parseFloat(value) < MIN_PROCEDURE_LENGTH) {
      e.target.value = 0;
      value = 0;
    } else {
      const id = e.target.name;
      const type = e.target.dataset.type;
      var oldprocedure = procedure;
      var filterddata = await _.find(oldprocedure, { type: type });
      var filterddata_index = oldprocedure.findIndex((p) => p.type === type);
      var objprocdata = filterddata.data;
      var proc_id = await _.find(objprocdata, { id: parseInt(id) });
      var proc_id_index = objprocdata.findIndex((p) => p.id === parseInt(id));
      var newobj = {
        id: proc_id.id,
        code: proc_id.code,
        type: proc_id.type,
        description: proc_id.description,
        fee: proc_id.fee,
        priorityList: proc_id.priorityList,
        value: parseFloat(value),
        cptgroupname: proc_id.cptgroupname,
      };

      objprocdata[proc_id_index] = newobj;
      oldprocedure[filterddata_index].data = objprocdata;
      console.log(oldprocedure, " oldprocedure");
      setProcedure([...oldprocedure]);
    }
  };
  const [addComprehensive, setAddClaim] = useState({
    patientLabel: "",
    providerLabel: "",
    patientName: "",
    renderingProvider: "",
    serviceDate: "",
    diagnosesEtc: "",
  });

  const [doctorInfo, setDoctorInfo] = useState([]);
  const [doctorId, setDoctorId] = useState("");
  const handleProviderChange = (e) => {
    let label = e.label;
    let value = e.value;
    setAddClaim({
      ...addComprehensive,
      providerLabel: label,
      renderingProvider: value,
    });
    setDoctorId(value);
    fetchDoctorData(value);
    setProcedure("");
    procedureList(value);
  };

  const fetchDoctorData = async (doctorId) => {
    await axios
      .get(`${baseURL}/${PROFILE_API}/${doctorId}`, {
        headers: { authorization: token.token },
      })
      .then((res) => {
        setDoctorInfo(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const [renderingProvider, setRenderingProvider] = useState([]);
  const renderProvidedList = () => {
    axios
      .post(`${baseURL}/${ALL_DOCTOR_IN_SELECTED_OFFICE}`, {
        headers: { authorization: token.token },
        officeId: officeId,
        isDoctor: true,
      })
      .then((res) => {
        setRenderingProvider(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const renderingProviderOptions =
    renderingProvider &&
    renderingProvider.map((item, i) => {
      return {
        value: item.id,
        label: item.firstName + " " + item.lastName,
      };
    });

  const patientNameList = () => {
    axios
      .post(`${baseURL}/${PATIENT_LIST_API}`, {
        headers: { authorization: token.token },
        officeId: officeId,
      })
      .then((res) => {
        setPatientName(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const patientNameOptions =
    patientName &&
    patientName.map((item, i) => {
      return {
        value: item.id,
        label: item.firstName + " " + item.lastName,
      };
    });

  const [diagnoses, setDiagnoses] = useState([]);
  const [filterDiagnoses, setfilterDiagnoses] = useState([]);

  const diagnosesList = () => {
    axios
      .post(`${baseURL}/${DIAGNOSES_API}`, {
        headers: { authorization: token.token },
        search,
        isNote: "",
      })
      .then((res) => {
        setDiagnoses(res.data.data);
        setfilterDiagnoses(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const checkedchange = (e, fid) => {
    const { value } = e.target;
    var checkedListData = checkedDiagnoses;
    const index1 = checkedListData.indexOf(value);
    if (index1 > -1) {
      checkedListData.splice(index1, 1);
      setCheckedDiagnoses([
        ...checkedDiagnoses,
        ...checkedDiagnoses.slice(index1, 1),
      ]);
    } else {
      setCheckedDiagnoses([...checkedDiagnoses, value]);
    }
  };

  const Diagnosis_Search_Change = async (e) => {
    const { value } = e.target;
    setSearch(e.target.value);
    var dialist = diagnoses;
    const filteredItemsdia = await dialist.filter(
      (item) =>
        JSON.stringify(item).toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    setfilterDiagnoses(filteredItemsdia);
  };
  const [isLoading, setIsLoading] = useState(true);
  const [patientInfo, setPatientInfo] = useState([]);

  const fetchPatientData = async (patientId) => {
    await axios
      .get(`${baseURL}/${PATIENT_PROFILE}/${patientId}`, {
        headers: { authorization: token.token },
      })
      .then((res) => {
        setPatientInfo(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const [comprehensiveNote, setComprhensiveNote] = useState([]);
  const [isCNLoading, setIsCNLoading] = useState(true);
  const [checkedDiagnoses, setCheckedDiagnoses] = useState([]);
  let diagArray = checkedDiagnoses;
  const [apiData, setApiData] = useState([]);

  const handlePatientChange = (e) => {
    let label = e.label;
    let value = e.value;
    setAddClaim({
      ...addComprehensive,
      patientLabel: label,
      patientName: value,
    });
    setPatientId(e.value);
    fetchPatientData(e.value);
    fetchComprehensiveNoteData(e.value);
    setCheckedDiagnoses([]);
    setApiData([]);
    fetchTabData(value);
    setDisabled(false);
    fetchThumbnailData(PATIENT_DOCUMENT_TYPE.INSURANCE_FRONT, e.value);
    fetchThumbnailData(PATIENT_DOCUMENT_TYPE.INSURANCE_BACK, e.value);
    fetchThumbnailData(PATIENT_DOCUMENT_TYPE.X_RAY, e.value);
    fetchThumbnailData(PATIENT_DOCUMENT_TYPE.NOTE, e.value);
    fetchThumbnailData(PATIENT_DOCUMENT_TYPE.ID, e.value);
    fetchThumbnailData(PATIENT_DOCUMENT_TYPE.OTHERS, e.value);
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setAddClaim({ ...addComprehensive, [name]: value });
  };

  const [insurance, setInsurance] = useState("");
  const [payer, setPayer] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");

  const fetchTabData = async (patientId) => {
    axios
      .post(`${baseURL}/${PATIENT_TABS_CONTENT_API}`, {
        headers: { authorization: token.token },
        patientChartTabId: 1,
        isList: true,
        patientId: patientId,
      })
      .then((res) => {
        res.data.data.map((item) =>
          item.id == 1 && item.name == "Insurance Name"
            ? setInsurance(item.value)
            : ""
        );
        res.data.data.map((item) =>
          item.id == 9 && item.name == "Payer" ? setPayer(item.value) : ""
        );
        res.data.data.map((item) =>
          item.id == 11 && item.name == "Insurance Address1"
            ? setAddress(item.value)
            : ""
        );
        res.data.data.map((item) =>
          item.id == 13 && item.name == "Insurance Address2"
            ? setAddress2(item.value)
            : ""
        );
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const fetchComprehensiveNoteData = async (patientId) => {
    await axios
      .post(`${baseURL}/${PRE_AUTHORIZATION_NOTE_DATA_API}`, {
        headers: { authorization: token.token },
        patientId: patientId,
      })
      .then((res) => {
        setIsCNLoading(false);
        setComprhensiveNote(res.data.data);
        let diagArr = [];
        if (res.data.data) {
          let diag = res.data.data.map((item) => item.diagnoses);
          diag.map((item) => diagArr.push(item));
          const str = diagArr.toString();
          const backToArr = str.split(",");
          setCheckedDiagnoses([...new Set(backToArr)]);
        }

        let queArr = [];
        let newObj = { id: 9999, value: "1", formType: FORMTYPE.CHECKBOX };
        queArr.push(newObj);
        if (res.data.data) {
          let que = res.data.data.map((item) => item.question);
          que.map((item) =>
            item.id
              ? queArr.push({
                  id: item.id,
                  value: item.value,
                  formType: item.formType,
                })
              : ""
          );
          queArr.push(newObj);
        }
        setApiData([...new Set(queArr)]);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const fetchOfficeData = async () => {
    await axios
      .get(`${baseURL}/${OFFICE_DETAIL_API}/${officeId}`, {
        headers: { authorization: token.token },
      })
      .then((res) => {
        setOffice(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  var comprehensiveData = [];
  let questionGroup = questions.map((item) => item.groupName);
  questionGroup.map((item) => {
    var gropwiseQuestion = [];
    if (item === "● Procedures already performed") return;

    let questionList = questions.filter(function (result) {
      return result.groupName == item;
    })[0].questions;
    questionList.map((que) => {
      var apidatafilter = apiData.filter(function (result) {
        return result.id == que.id;
      });

      apidatafilter.map((existitem) => {
        var replacedes =
          existitem.formType == FORMTYPE.INPUT
            ? que.description.replace("#value#", existitem.value)
            : que.description;
        var objquestion = { id: existitem.id, description: replacedes };
        const index = gropwiseQuestion.findIndex((object) => {
          return object.description == replacedes;
        });
        if (index < 0) {
          gropwiseQuestion.push(objquestion);
        }
      });
    });
    var objall = { groupName: item, questions: gropwiseQuestion };
    comprehensiveData.push(objall);
  });

  useEffect(async () => {
    localStorage.removeItem("patientId");
    JsonData();
    diagnosesList();
    renderProvidedList();
    patientNameList();
    fetchOfficeData();
  }, []);

  return (
    <>
      <div className="page-content-wrapper">
        <div className="page-content">
          <div className="page-bar">
            <div className="page-title-breadcrumb">
              <div className=" pull-left">
                {" "}
                <div className="page-title">
                  {step === "2" ? "Post Op/Progres Note" : "Add Post Op Note"}
                </div>
              </div>
            </div>
            <ol
              className="breadcrumb page-breadcrumb pull-right"
              id="addPreauthorizationHeader"
            >
              <li>
                <i className="fa fa-home"></i>&nbsp;
                <NavLink className="parent-item" to="/dashboard">
                  {HOME}
                </NavLink>
                &nbsp;
                <i className="fa fa-angle-right"></i>
              </li>
              <li>
                <NavLink className="parent-item" to="/manageOpProgresNotes">
                  Post Op/Progress Note
                </NavLink>
                &nbsp;<i className="fa fa-angle-right"></i>
              </li>
              <li className="active">Add Post Op Note</li>
            </ol>
          </div>
          <div className="row">
            {step === "1" && (
              <div id="addPreauthorization">
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="card card-box">
                      <div className="card-head">
                        <header>{"Post Op / Progress Information"}</header>
                      </div>

                      <div className="card-body" id="bar-parent">
                        <div id="form_sample_1" className="form-horizontal">
                          <div className="form-body">
                            <div className="form-row row">
                              <div className="form-group row">
                                <label className="control-label col-md-2">
                                  {PATIENT_NAME}
                                  <span className="required"> * </span>
                                </label>
                                <div className="col-md-3">
                                  <Select
                                    name="patientName"
                                    value={
                                      patientNameOptions &&
                                      patientNameOptions.filter(
                                        ({ value }) =>
                                          value === addComprehensive.patientName
                                      )
                                    }
                                    options={patientNameOptions}
                                    onChange={handlePatientChange}
                                    placeholder={SELECT_PATIENT}
                                  ></Select>
                                  <p style={{ fontSize: "11px" }}>
                                    Please Select the patient.
                                  </p>
                                </div>

                                <label className="control-label col-md-2">
                                  {RENDERRING_PROVIDER}
                                  <span className="required"> * </span>
                                </label>
                                <div className="col-md-3">
                                  <Select
                                    name="renderingProvider"
                                    value={
                                      renderingProviderOptions &&
                                      renderingProviderOptions.filter(
                                        ({ value }) =>
                                          value ===
                                          addComprehensive.renderingProvider
                                      )
                                    }
                                    options={renderingProviderOptions}
                                    onChange={handleProviderChange}
                                    placeholder={SELECT_DOCTOR}
                                  ></Select>
                                  <p style={{ fontSize: "11px" }}>
                                    Please Select a doctor in the Office of the
                                    Logged in user.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="form-row row">
                              <div className="form-group row">
                                <label className="control-label col-md-2">
                                  {DATE_OF_SERVICE}
                                  <span className="required"> * </span>
                                </label>
                                <div className="col-md-3">
                                  <DatePicker
                                    className="form-control input-height mr-sm-2"
                                    selected={addComprehensive.serviceDate}
                                    placeholderText={"Please Select Date"}
                                    maxDate={new Date()}
                                    scrollableMonthDropdown={true}
                                    showYearDropdown
                                    showMonthDropdown
                                    onChange={(date) =>
                                      setAddClaim({
                                        ...addComprehensive,
                                        serviceDate: date,
                                      })
                                    }
                                  />

                                  <p style={{ fontSize: "11px" }}>
                                    Please Select the date of service!!!.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-actions mt-5">
                  <div className="center">
                    <button
                      type="submit"
                      className="btn btn-primary m-r-20 col-1"
                      onClick={handleFormSubmit}
                    >
                      {NEXT}
                    </button>
                  </div>
                </div>
                {/*imageSection*/}
              </div>
            )}

            {/* //////////////////////////////////////////////////// Next Button Click To Confirmation //////////////////////////////////////////////////// */}

            {step === "2" && (
              <PostOpComprehensiveResult
                isCN={false}
                checkedValues={checkedValues}
                comprehensiveData={comprehensiveData}
                checkedArray={checkedArray}
                diagArray={diagArray}
                procedureArray={procedureArray}
                queArray={apiData}
                comprehensiveNote={comprehensiveNote}
                isCNLoading={isCNLoading}
                isLoading={isLoading}
                patientInfo={patientInfo}
                serviceDate={addComprehensive.serviceDate}
                addComprehensive={addComprehensive}
                diagnoses={diagnoses}
                procedure={procedure}
                patientId={patientId}
                doctorId={doctorId}
                office={office}
                officeId={officeId}
                doctorInfo={doctorInfo}
                insurance={insurance}
                payer={payer}
                address={address}
                address2={address2}
                isPreAuthorize={isPreAuthorize}
                medicationList={medicationList}
                allergiesList={allergiesList}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddPostOpNote;
