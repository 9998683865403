import React, { useEffect, useState } from "react";
import {
    ADDRESS,
    CITY,
    STATE,
    STATUS,
    ACTION,
    FIRST_NAME,
    EMPLOYEE_LIST,
    ADD,
    ACCESSLEVEL,
    AGREEMENT,
    HOME,
    LAST_NAME,
    EMAIL,
    CREATED_DATE,
    NAME,
    OFFICE_STATUS,
    DASH,
    ROWS_PER_PAGE,
    SELECT,
    ALL,
} from "../../Helper/Constants";
import { EMPLOYEE_DELETE_API, MANAGE_EMPLOYEE, ROLE_LIST_API } from "../../Helper/ApiPath";
import { dateFormateMonthDateYear } from "../../Helper/Helper";
import axios from "axios";
import { baseURL } from "../../Config";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { NavLink, useNavigate } from "react-router-dom";
import NoDataFound from "../CommonComponent/NoDataFound";
import EmployeeDocument from "../Forms/Employee/EmployeeDocument";
import Loader from "../CommonComponent/Loader";
import { DELETE_EMPLOYEE_MESSAGE } from "../../Helper/Messages";
import Swal from "sweetalert2";
import Select from "react-select";

function ManageEmployee() {
    const [currentPage, setCurrentPage] = useState(0);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [employeeList, setEmployeeList] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);

    let token = JSON.parse(localStorage.getItem("logToken"));

    const [filterValues, setFilterValues] = useState({
        search: "",
        status: "",
        sccessLvl: "",
    });

    const handleFilterValues = (e) => {
        const { name, value } = e.target;
        setFilterValues({ ...filterValues, [name]: value });
        setOffset(1);
        setCurrentPage(0);
    };

    const handleAddClick = (e) => {
        navigate("/addEmployee");
    };
    const fetchData = async (roleId) => {
        await axios
            .post(`${baseURL}/${MANAGE_EMPLOYEE}`, {
                headers: { authorization: token.token },
                page: offset,
                rowsPerPage: ROWS_PER_PAGE,
                search: filterValues.search,
                accessLvl: roleId,
                status: filterValues.status,
            })
            .then((res) => {
                setIsLoading(false);
                setEmployeeList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleEdit = (id) => {
        navigate("/editEmployee");
        localStorage.setItem("employeeId", id);
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: DELETE_EMPLOYEE_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/${EMPLOYEE_DELETE_API}/${id}`, {
                            headers: { authorization: token.token },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                fetchData();
                                Swal.fire(res.data.message, " ", "success");
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    const [accessLevel, setAccessLevel] = useState([]);
    const [roleId, setRoleId] = useState("");

    let EmpSelect = [
        {
            id: "",
            name: SELECT,
        },
    ];

    const fetchAccessLevel = async () => {
        await axios
            .post(`${baseURL}/${ROLE_LIST_API}`, {
                headers: { authorization: token.token },
                isList: true,
            })
            .then((res) => {
                setIsLoading(false);
                setAccessLevel(res.data.data ? EmpSelect.concat(res.data.data) : res.data.data);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const accessLevelOption =
        accessLevel &&
        accessLevel.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const handleAccessLevelChange = (e) => {
        setRoleId(e.value);
        fetchData(e.value);
    };

    useEffect(() => {
        fetchData(roleId);
        fetchAccessLevel();
    }, [filterValues, offset]);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{EMPLOYEE_LIST}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{EMPLOYEE_LIST}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tabbable-line">
                                <div className="tab-content">
                                    <div className="tab-pane active fontawesome-demo" id="tab1">
                                        <div className="card" style={isLoading ? { height: "35rem" } : {}}>
                                            <div className="card-body ">
                                                <div className="row justify-content-center">
                                                    <div className="col-1">
                                                        <button id="addRow" className="btn btn-info" onClick={handleAddClick}>
                                                            {ADD} <i className="fa fa-plus"></i>
                                                        </button>
                                                    </div>

                                                    <div className="col-3"></div>

                                                    <div className="col-8">
                                                        <div className="row">
                                                            <div className="col-4">
                                                                <div className="d-flex justify-content-end row">
                                                                    <div className="col-3 my-2">{NAME}</div>
                                                                    <div className="col-8">
                                                                        <input
                                                                            className="form-control"
                                                                            name="search"
                                                                            type="search"
                                                                            placeholder="Search"
                                                                            aria-label="Search"
                                                                            onChange={handleFilterValues}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="d-flex justify-content-end row">
                                                                    <div className="col-5 my-2">{ACCESSLEVEL}</div>
                                                                    <div className="col-7">
                                                                        <Select name="roleId" options={accessLevelOption} onChange={handleAccessLevelChange} placeholder={SELECT}></Select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="d-flex justify-content-end row">
                                                                    <div className="col-3 my-2">{STATUS}</div>
                                                                    <div className="col-7">
                                                                        <select className="form-control" name="status" value={filterValues.status} onChange={handleFilterValues}>
                                                                            <option value="">{ALL}</option>
                                                                            {Object.keys(OFFICE_STATUS).map((item, i) => {
                                                                                return (
                                                                                    <option key={i} value={OFFICE_STATUS[item]}>
                                                                                        {item}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="table-scrollable" style={isLoading ? { border: "none" } : {}}>
                                                    <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                        <thead>
                                                            <tr>
                                                                <th>{EMAIL}</th>
                                                                <th>{FIRST_NAME}</th>
                                                                <th>{LAST_NAME}</th>
                                                                <th>{ADDRESS}</th>
                                                                <th>{STATE}</th>
                                                                <th>{CITY}</th>
                                                                <th>{ACCESSLEVEL}</th>
                                                                <th>{STATUS}</th>
                                                                <th>{CREATED_DATE}</th>
                                                                <th>{AGREEMENT}</th>
                                                                <th>{ACTION}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={isLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                            {isLoading ? (
                                                                <Loader />
                                                            ) : (
                                                                <>
                                                                    {!employeeList ? (
                                                                        <>{isLoading ? "" : <NoDataFound />}</>
                                                                    ) : (
                                                                        employeeList &&
                                                                        employeeList.map((item, index) => {
                                                                            return (
                                                                                <tr className="odd gradeX" key={index}>
                                                                                    <td>{item.email ? item.email : DASH}</td>
                                                                                    <td>{item.firstName ? item.firstName : DASH}</td>
                                                                                    <td>{item.lastName ? item.lastName : DASH}</td>
                                                                                    <td>{item.address ? item.address : DASH}</td>
                                                                                    <td>{item.state ? item.state : DASH}</td>
                                                                                    <td>{item.city ? item.city : DASH}</td>
                                                                                    <td>{item.pageAuthorityRoleName ? item.pageAuthorityRoleName : item.roleName}</td>

                                                                                    <td className="left">
                                                                                        <span
                                                                                            className={
                                                                                                "badge " +
                                                                                                (item.statusId == 2
                                                                                                    ? "deactiveStatus badge-warning"
                                                                                                    : item.statusId == 1
                                                                                                    ? "activeStatus badge-success"
                                                                                                    : "badge-danger")
                                                                                            }
                                                                                        >
                                                                                            {item.statusId == 1 ? "Active" : item.statusId == 2 ? "Deactive" : DASH}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>{item.created_at ? dateFormateMonthDateYear(item.created_at) : DASH}</td>
                                                                                    <td>
                                                                                        <button type="button" className="btn btn-info btn-xs" onClick={() => window.open(item.agreement, "_blank")}>
                                                                                            <i className="fa  fa-list-alt"></i>
                                                                                        </button>
                                                                                        <EmployeeDocument employeeId={item.id} fetchData={fetchData} />
                                                                                    </td>
                                                                                    <td>
                                                                                        <button className="btn btn-primary btn-xs" onClick={() => handleEdit(item.id)}>
                                                                                            <i className="fa fa-pencil"></i>
                                                                                        </button>

                                                                                        <button type="button" className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
                                                                                            <i className="fa fa-trash"></i>
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })
                                                                    )}
                                                                </>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between mx-4">
                                                <div>Total {employeeList == null ? "0" : rowCount} Employees</div>
                                                <div className="pagination-center">
                                                    <ReactPaginate
                                                        initialPage={0}
                                                        pageCount={pageCount}
                                                        marginPagesDisplayed={0}
                                                        pageRangeDisplayed={3}
                                                        onPageChange={handlePageClick}
                                                        containerClassName="pagination"
                                                        activeClassName="active"
                                                        pageLinkClassName="page-link"
                                                        breakLinkClassName="page-link"
                                                        nextLinkClassName="page-link"
                                                        previousLinkClassName="page-link"
                                                        pageClassName="page-item"
                                                        breakClassName="page-item"
                                                        nextClassName="page-item"
                                                        previousClassName="page-item"
                                                        previousLabel="Previous"
                                                        nextLabel="Next"
                                                        disableInitialCallback={true}
                                                        forcePage={currentPage}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManageEmployee;
