import React from "react";
import { ROLES } from "../../Helper/Constants";
import AdminHeader from "./AdminHeader";
import DoctorHeader from "./DoctorHeader";
import EmployeeHeader from "./EmployeeHeader";
import Cookies from "universal-cookie";

export default function Header(props) {
    let adminRole = localStorage.getItem("adminRole");
    const cookies = new Cookies();

    let role = cookies.get("role");
    if (role) {
        role = cookies.get("role").roleId;
    }

    return (
        <>
            {role == ROLES.ADMIN && adminRole == ROLES.ADMIN ? (
                <AdminHeader />
            ) : role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? (
                <DoctorHeader doctorFirstName={props.doctorFirstName} doctorLastName={props.doctorLastName} officeName={props.officeName} doctorId={props.doctorId} officeId={props.officeId} />
            ) : (role == ROLES.EMPLOYEE_ADMIN && adminRole == ROLES.EMPLOYEE_ADMIN) || role == ROLES.EMPLOYEE ? (
                <EmployeeHeader firstName={props.employeeInfo.firstName} lastName={props.employeeInfo.lastName} />
            ) : (
                ""
            )}
        </>
    );
}
