import React, { useEffect, useState } from "react";
import {
    STATUS,
    NOTICE_LIST,
    ADD,
    HOME,
    TITLE,
    POPUP,
    POSTING_PERIOD,
    ATTACHMENT,
    CREATE_BY,
    CREATED_DATE,
    UPDATE_BY,
    UPDATE_DATE,
    ROWS_PER_PAGE,
    NOTICESTATUS,
    POPUPSTATUS,
    DASH,
    DELETE,
    SUPER_ADMIN,
    ACTION,
    ALL,
} from "../../Helper/Constants";
import ReactPaginate from "react-paginate";
import { NavLink, useNavigate } from "react-router-dom";
import NoDataFound from "../CommonComponent/NoDataFound";
import axios from "axios";
import { baseURL } from "../../Config";
import { DELETE_NOTICE, MANAGE_NOTICE } from "../../Helper/ApiPath";
import { toast } from "react-toastify";
import Loader from "../CommonComponent/Loader";
import { dateFormateMonthDateYear } from "../../Helper/Helper";
import Swal from "sweetalert2";
import { DELETE_NOTICE_MESSAGE, SELECT_NOTICE } from "../../Helper/Messages";
import NoticeDocument from "../Forms/Notice/EditNoticeDocument";

function ManageNotice() {
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [rowCount, setRowCount] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const navigate = useNavigate();
    const [noticeList, setNoticeList] = useState([]);
    let token = JSON.parse(localStorage.getItem("logToken"));

    const [checkValue, setCheckValue] = useState(false);
    const setChkdValue = (e) => {
        const checked = document.querySelector("#select_checkboxes:checked") !== null;
        if (checked) {
            setCheckValue(true);
        } else {
            setCheckValue(false);
        }
    };

    const checkedchange = () => {
        if (document.getElementById("select_all_checkboxes").checked) {
            document.getElementsByName("checkbox").forEach((item) => {
                item.checked = true;
                setCheckValue(true);
            });
        }
        if (!document.getElementById("select_all_checkboxes").checked) {
            document.getElementsByName("checkbox").forEach((item) => {
                item.checked = false;
                setCheckValue(false);
            });
        }
    };

    const [filterValues, setFilterValues] = useState({
        title: "",
        popUp: "",
        status: "",
    });

    const handleFilterValues = (e) => {
        const { name, value } = e.target;
        setFilterValues({ ...filterValues, [name]: value });
        setOffset(1);
        setCurrentPage(0);
    };

    const handleAddClick = (e) => {
        navigate("/addNotice");
    };

    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${MANAGE_NOTICE}`, {
                headers: { authorization: token.token },
                page: offset,
                rowsPerPage: ROWS_PER_PAGE,
                search: filterValues.title,
                popUp: filterValues.popUp,
                status: filterValues.status,
            })
            .then((res) => {
                setIsLoading(false);
                localStorage.removeItem("noticeId");
                setNoticeList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleEdit = (id) => {
        localStorage.setItem("noticeId", id);
        navigate("/addNotice");
    };
    const handleDelete = () => {
        let selectedValues = [];

        document.getElementsByName("checkbox").forEach((item) => {
            if (item.checked == true) {
                selectedValues.push(item.defaultValue);
            }
        });
        if (!selectedValues.length) {
            toast.error(SELECT_NOTICE);
        } else {
            Swal.fire({
                title: DELETE_NOTICE_MESSAGE,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    if (result.isConfirmed) {
                        await axios
                            .post(`${baseURL}/${DELETE_NOTICE}`, {
                                headers: { authorization: token.token },
                                deleteRow: selectedValues,
                            })
                            .then((res) => {
                                Swal.fire(res.data.message, " ", "success");
                                selectedValues = [];
                                document.getElementsByName("checkbox").forEach((item) => {
                                    item.checked = false;
                                });
                                document.getElementById("select_all_checkboxes").checked = false;
                                fetchData();
                            })
                            .catch((err) => {
                                if (err.response) {
                                    if (err.response.status == 500) {
                                        localStorage.clear();
                                        navigate("/login");
                                    } else {
                                        toast.error(err.response.data.message);
                                    }
                                }
                            });
                    }
                }
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, [offset, filterValues]);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{NOTICE_LIST}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{NOTICE_LIST}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tabbable-line">
                                <div className="tab-content">
                                    <div className="tab-pane active fontawesome-demo" id="tab1">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card" style={isLoading ? { height: "35rem" } : {}}>
                                                    <div className="card-body ">
                                                        <div className="row">
                                                            <div className="row d-flex justify-content-between">
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="btn-group">
                                                                        <button id="addRow" className="btn btn-info" onClick={handleAddClick}>
                                                                            {ADD}
                                                                            <i className="fa fa-plus"></i>
                                                                        </button>
                                                                    </div>
                                                                    <div>
                                                                        <div className="btn-group">
                                                                            <label className="mt-2 col-md-3">{TITLE}</label>
                                                                            <input
                                                                                className="form-control mr-sm-2"
                                                                                name="title"
                                                                                type="search"
                                                                                placeholder="Title"
                                                                                aria-label="Title"
                                                                                onChange={handleFilterValues}
                                                                            />
                                                                        </div>

                                                                        <div className="btn-group mx-5">
                                                                            <label className="mt-2 col-md-5">{POPUP}</label>
                                                                            <select className="form-control col-md-2" name="popUp" value={filterValues.popUp} onChange={handleFilterValues}>
                                                                                <option value="">{ALL}</option>
                                                                                {Object.keys(POPUPSTATUS).map((item, i) => {
                                                                                    return (
                                                                                        <option key={i} value={POPUPSTATUS[item]}>
                                                                                            {item}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </div>

                                                                        <div className="btn-group mx-5">
                                                                            <label className="mt-2 col-md-5">{STATUS}</label>
                                                                            <select className="form-control col-md-2" name="status" value={filterValues.status} onChange={handleFilterValues}>
                                                                                <option value="">{ALL}</option>
                                                                                {Object.keys(NOTICESTATUS).map((item, i) => {
                                                                                    return (
                                                                                        <option key={i} value={NOTICESTATUS[item]}>
                                                                                            {item}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </div>

                                                                        <div className="btn-group mx-3">
                                                                            <div className="btn-group mx-5">
                                                                                {checkValue ? (
                                                                                    <button id="deletebtn" className="btn btn-danger col-md-2" onClick={handleDelete}>
                                                                                        {DELETE}
                                                                                    </button>
                                                                                ) : (
                                                                                    <button id="deletebtn" className="btn btn-danger col-md-2" disabled>
                                                                                        {DELETE}
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-scrollable" style={isLoading ? { border: "none" } : {}}>
                                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            <input type="checkbox" className="checkBox mx-2" id="select_all_checkboxes" onChange={checkedchange} />
                                                                        </th>
                                                                        <th>{TITLE}</th>
                                                                        <th>{POPUP}</th>
                                                                        <th>{STATUS}</th>
                                                                        <th>{POSTING_PERIOD}</th>
                                                                        <th>{CREATE_BY}</th>
                                                                        <th>{CREATED_DATE}</th>
                                                                        <th>{UPDATE_BY}</th>
                                                                        <th>{UPDATE_DATE}</th>
                                                                        <th>{ATTACHMENT}</th>
                                                                        <th>{ACTION}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={isLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                                    {isLoading ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        <>
                                                                            {!noticeList ? (
                                                                                <>{isLoading ? "" : <NoDataFound />}</>
                                                                            ) : (
                                                                                noticeList &&
                                                                                noticeList.map((item, index) => {
                                                                                    return (
                                                                                        <tr className="odd gradeX" key={index}>
                                                                                            <td>
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    className="checkBox mx-2"
                                                                                                    id="select_checkboxes"
                                                                                                    // id={item.id}
                                                                                                    name="checkbox"
                                                                                                    value={item.id}
                                                                                                    onClick={(e) => {
                                                                                                        setChkdValue(e);
                                                                                                    }}
                                                                                                />
                                                                                            </td>
                                                                                            <td>{item.title ? item.title : DASH}</td>
                                                                                            <td>{Object.keys(POPUPSTATUS).find((key) => POPUPSTATUS[key] == item.popUp)}</td>
                                                                                            <td>{Object.keys(NOTICESTATUS).find((key) => NOTICESTATUS[key] == item.statusId)}</td>
                                                                                            <td>
                                                                                                {dateFormateMonthDateYear(item.postingPeriodStartDate)} -{" "}
                                                                                                {dateFormateMonthDateYear(item.postingPeriodEndDate)}
                                                                                            </td>
                                                                                            <td>
                                                                                                {item.create_by
                                                                                                    ? item.create_by == 1
                                                                                                        ? SUPER_ADMIN
                                                                                                        : item.createdFirstName + " " + item.createdLastName
                                                                                                    : DASH}
                                                                                            </td>
                                                                                            <td>{item.created_at ? dateFormateMonthDateYear(item.created_at) : DASH}</td>
                                                                                            <td>
                                                                                                {item.update_by
                                                                                                    ? item.update_by == 1
                                                                                                        ? SUPER_ADMIN
                                                                                                        : item.updatedFirstName + " " + item.updatedLastName
                                                                                                    : DASH}
                                                                                            </td>
                                                                                            <td>{item.updated_at ? dateFormateMonthDateYear(item.updated_at) : DASH}</td>

                                                                                            <td>
                                                                                                {item.attachment ? (
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btn-info btn-xs"
                                                                                                        onClick={() => window.open(item.attachment, "_blank")}
                                                                                                    >
                                                                                                        <i className="fa  fa-list-alt"></i>
                                                                                                    </button>
                                                                                                ) : (
                                                                                                    ""
                                                                                                )}
                                                                                                <NoticeDocument noticeId={item.id} fetchData={fetchData} />
                                                                                            </td>
                                                                                            <td>
                                                                                                <button className="btn btn-primary btn-xs" onClick={() => handleEdit(item.id)}>
                                                                                                    <i className="fa fa-pencil"></i>
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mx-4">
                                                        <div>Total {noticeList == null ? "0" : rowCount} Notice</div>
                                                        <div className="pagination-center">
                                                            <ReactPaginate
                                                                initialPage={0}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={0}
                                                                pageRangeDisplayed={3}
                                                                onPageChange={handlePageClick}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                pageLinkClassName="page-link"
                                                                breakLinkClassName="page-link"
                                                                nextLinkClassName="page-link"
                                                                previousLinkClassName="page-link"
                                                                pageClassName="page-item"
                                                                breakClassName="page-item"
                                                                nextClassName="page-item"
                                                                previousClassName="page-item"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"
                                                                disableInitialCallback={true}
                                                                forcePage={currentPage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManageNotice;
