import { useState } from "react";
import {
    ADD,
    ADDRESS,
    ADD_OFFICE,
    BACK,
    CANCEL,
    CITY,
    HOME,
    OFFICE_INFORMATION,
    OFFICE_LIST,
    OFFICE_NAME,
    OR,
    PHONE_LENGTH,
    PHONE_NO,
    SELECT,
    SELECT_OFFICE,
    STATE,
    ZIP,
} from "../../Helper/Constants";
import axios from "axios";
import { baseURL } from "../../Config";
import { toast } from "react-toastify";
import { CITY_LIST_WITHOUT_PAGINATION, OFFICE_ANOTHER_LIST, OFFICE_DETAIL_API, OFFICE_REQEST_DATA_API, STATE_LIST_WITHOUT_PAGINATION } from "../../Helper/ApiPath";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Button } from "reactstrap";
import { ENTER_ADDRESS, ENTER_CITY, ENTER_OFFICE_NAME, ENTER_PHONE, ENTER_STATE, ENTER_ZIP, PHONE_NUMBER } from "../../Helper/Messages";
import Select from "react-select";

function DoctorOfficeAdd() {
    const [isLoading, setIsLoading] = useState(false);
    const [city, setCity] = useState({
        state: "",
        city: "",
    });

    const handleCityChange = (e) => {
        setCity({ ...city, city: e.value });
    };

    const token = JSON.parse(localStorage.getItem("logToken")).token;
    const navigate = useNavigate();
    const [office, setOffice] = useState({
        officename: "",
        phone: "",
        address: "",
        zip: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setOffice({ ...office, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!office.officename) {
            toast.error(ENTER_OFFICE_NAME);
        } else if (!office.phone) {
            toast.error(ENTER_PHONE);
        } else if (!office.address) {
            toast.error(ENTER_ADDRESS);
        } else if (!city.state) {
            toast.error(ENTER_STATE);
        } else if (!city.city) {
            toast.error(ENTER_CITY);
        } else if (!office.zip) {
            toast.error(ENTER_ZIP);
        } else if (office.phone.length > PHONE_LENGTH) {
            toast.error(PHONE_NUMBER);
        } else {
            setIsLoading(true);
            await axios
                .post(`${baseURL}/${OFFICE_REQEST_DATA_API}`, {
                    headers: { authorization: token },
                    name: office.officename,
                    phoneNo1: office.phone,
                    address: office.address,
                    state: city.state,
                    city: city.city,
                    zip: office.zip,
                })
                .then((res) => {
                    setIsLoading(false);
                    if (res.status === 200) {
                        setOffice("");
                        setCity("");
                        setCityList("");

                        toast.success(res.data.message);
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const [officeSelect, setOfficeSelect] = useState([]);

    const fetchOfficeList = async (e) => {
        await axios
            .post(`${baseURL}/${OFFICE_ANOTHER_LIST}`, {
                headers: { authorization: token },
            })
            .then((res) => {
                setOfficeSelect(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const officeSelectOptions =
        officeSelect &&
        officeSelect.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });
    const [officeId, setOfficeId] = useState("");
    const [officeDetail, setOfficeDetail] = useState([]);

    const handleOfficeChange = async (e) => {
        setOfficeId(e.value);
        document.getElementById("officeRow").style.display = "none";
        if (e.value == 0) {
            setOfficeId("");
            document.getElementById("officeRow").style.display = "block";
        }

        await axios
            .get(`${baseURL}/${OFFICE_DETAIL_API}/${e.value}`, {
                headers: { authorization: token },
            })
            .then((res) => {
                setOfficeDetail(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });

        if (e.value == 0) {
            setOfficeId("");
            document.getElementById("doctorRow").style.display = "block";
        } else {
            document.getElementById("button").style.display = "block";
            document.getElementById("doctorRow").style.display = "none";
        }
    };

    const handleBack = (e) => {
        setOfficeSelect("");
        setOfficeId("");
        fetchOfficeList();
        document.getElementById("officeRow").style.display = "block";
        document.getElementById("button").style.display = "none";
    };

    const addByOfficeList = async (e) => {
        await axios
            .post(`${baseURL}/${OFFICE_REQEST_DATA_API}`, {
                headers: { authorization: token },
                officeId: officeId,
            })
            .then((res) => {
                if (res.status == 200) {
                    setOfficeSelect("");
                    setOfficeId("");
                    fetchOfficeList();
                    document.getElementById("officeRow").style.display = "block";
                    toast.success(res.data.message);
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        document.getElementById("button").style.display = "none";

                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleCancel = () => {
        navigate("/manageOffice");
    };

    useEffect(() => {
        fetchOfficeList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    ////////////////////////////////////////////////////// STATE-CITY LIST /////////////////////
    const [stateList, setStateList] = useState([]);

    const stateData = async () => {
        await axios
            .post(`${baseURL}/${STATE_LIST_WITHOUT_PAGINATION}`)
            .then((res) => {
                setStateList(res.data.data);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };

    const [cityList, setCityList] = useState([]);
    const [stateId, setStateId] = useState("");

    const handleStateChange = (e) => {
        setCity({ ...city, state: e.value, city: "" });
        setCityList("");
        cityData();
        setStateId(e.value);
    };

    const cityData = async () => {
        axios
            .post(`${baseURL}/${CITY_LIST_WITHOUT_PAGINATION}`, {
                stateId: stateId,
                search: "",
            })
            .then((res) => {
                setCityList(res.data.data);
            });
    };

    const stateOptions =
        stateList &&
        stateList.map((item, i) => {
            return {
                id: item.id,
                value: item.id,
                label: item.name,
            };
        });

    const cityOptions =
        cityList &&
        cityList.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    useEffect(() => {
        stateData();
        cityData();
    }, [stateId]);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{ADD_OFFICE}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/manageOffice">
                                        {OFFICE_LIST}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{ADD_OFFICE}</li>
                            </ol>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="card card-box">
                                <div className="card-head">
                                    <header>{OFFICE_INFORMATION}</header>
                                </div>
                                <div className="card-body" id="bar-parent">
                                    <div action="#" id="form_sample_1" className="form-horizontal">
                                        <div className="form-body">
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {OFFICE_NAME}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <Select
                                                        name="officeSelect"
                                                        value={officeSelectOptions && officeSelectOptions.filter(({ value }) => value == officeId)}
                                                        options={officeSelectOptions}
                                                        onChange={handleOfficeChange}
                                                        placeholder={SELECT_OFFICE}
                                                    ></Select>

                                                    {officeId == 0 ? (
                                                        ""
                                                    ) : (
                                                        <div className="form-group mt-5" id="button">
                                                            <div id="button">
                                                                <div className="card">
                                                                    <div className="card-head">
                                                                        <header>{OFFICE_INFORMATION}</header>
                                                                    </div>
                                                                    <div className="card-body no-padding height-9">
                                                                        <ul className="performance-list">
                                                                            <li>
                                                                                <a>
                                                                                    <i className="fa fa-circle-o" style={{ color: "#F39C12" }}></i>
                                                                                    {OFFICE_NAME}
                                                                                    <span className="pull-right">{officeDetail.name}</span>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a>
                                                                                    <i className="fa fa-circle-o" style={{ color: "#DD4B39" }}></i>
                                                                                    {PHONE_NO}
                                                                                    <span className="pull-right">{officeDetail.phoneNo1}</span>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a>
                                                                                    <i className="fa fa-circle-o" style={{ color: "#00A65A" }}></i>
                                                                                    {ADDRESS}
                                                                                    <span className="pull-right"> {officeDetail.address}</span>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a>
                                                                                    <i className="fa fa-circle-o" style={{ color: "#F39C12" }}></i>
                                                                                    {STATE}
                                                                                    <span className="pull-right"> {officeDetail.state}</span>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a>
                                                                                    <i className="fa fa-circle-o" style={{ color: "#DD4B39" }}></i>
                                                                                    {CITY}
                                                                                    <span className="pull-right"> {officeDetail.city}</span>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div style={{ float: "right" }}>
                                                                    <Button
                                                                        className="btn btn-info btn-lg m-r-1"
                                                                        style={{
                                                                            marginRight: "15px",
                                                                        }}
                                                                        onClick={addByOfficeList}
                                                                    >
                                                                        {ADD}
                                                                    </Button>
                                                                    <Button
                                                                        className="btn btn-lg m-r-1"
                                                                        style={{
                                                                            marginRight: "15px",
                                                                        }}
                                                                        onClick={handleBack}
                                                                    >
                                                                        {BACK}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="officeRow">
                                    <div className="form-group row">
                                        <label className="control-label col-md-3"></label>
                                        <div className="col-md-5">
                                            <div className="text-center">{OR}</div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div id="form_sample_1" className="form-horizontal">
                                            <div className="card-body" id="bar-parent">
                                                <div action="#" id="form_sample_1" className="form-horizontal">
                                                    <div className="form-body">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {OFFICE_NAME}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    type="text"
                                                                    name="officename"
                                                                    value={office && office.officename}
                                                                    onChange={handleChange}
                                                                    data-required="1"
                                                                    placeholder="Enter Office Name"
                                                                    className="form-control input-height"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {PHONE_NO}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    name="phone"
                                                                    value={office && office.phone}
                                                                    onChange={handleChange}
                                                                    type="number"
                                                                    placeholder="Mobile Number"
                                                                    className="form-control input-height"
                                                                />{" "}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {ADDRESS}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <textarea
                                                                    name="address"
                                                                    value={office && office.address}
                                                                    onChange={handleChange}
                                                                    placeholder="Address"
                                                                    className="form-control form-control-textarea"
                                                                    rows="3"
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {STATE}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <Select
                                                                    name="state"
                                                                    value={stateOptions && stateOptions.filter(({ value }) => value == city.state)}
                                                                    options={stateOptions}
                                                                    onChange={handleStateChange}
                                                                    placeholder={SELECT}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {CITY}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <Select
                                                                    value={cityOptions && cityOptions.filter(({ value }) => value == city.city)}
                                                                    name="city"
                                                                    options={cityOptions}
                                                                    onChange={handleCityChange}
                                                                    placeholder={SELECT}
                                                                ></Select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {ZIP}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    name="zip"
                                                                    value={office && office.zip}
                                                                    onChange={handleChange}
                                                                    type="text"
                                                                    placeholder="Zip"
                                                                    className="form-control input-height"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-actions">
                                                            <div className="row">
                                                                <div className="offset-md-3 col-md-9">
                                                                    {isLoading ? (
                                                                        <>
                                                                            <button type="submit" className="btn btn-info m-r-20" disabled>
                                                                                {ADD}
                                                                            </button>
                                                                            <i className="fa fa-spinner fa-spin fa-2x loaderBtnSpinner"></i>
                                                                        </>
                                                                    ) : (
                                                                        <button type="submit" className="btn btn-info m-r-20" onClick={handleSubmit}>
                                                                            {ADD}
                                                                        </button>
                                                                    )}
                                                                    <button type="button" className="btn btn-default  m-r-20" onClick={handleCancel}>
                                                                        {CANCEL}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DoctorOfficeAdd;
