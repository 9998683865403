import React from "react";
import { Modal } from "react-bootstrap";
import { ADD, CANCEL, DOCTOR_PAGES, PAGES, ROLES, ROLE_ADD, SELECT_PAGES } from "../../../Helper/Constants";

function EditRolePages(props) {
    return (
        <>
            <Modal show={props.pageShow} onHide={props.handlePageClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.title ? props.title : ROLE_ADD}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="row">
                            <label className="mt-2 col-12 ">{SELECT_PAGES}</label>
                        </div>
                        <div className="row">
                            {props.parentId == ROLES.DOCTOR
                                ? props.doctorPages.map((item, i) => {
                                      return (
                                          <>
                                              <div className="col-1">
                                                  <input
                                                      type="checkbox"
                                                      className="checkBox mt-2 mb-2"
                                                      key={i}
                                                      value={item.pageId}
                                                      onChange={props.handleCheck}
                                                      checked={item.isAuthority == 1 ? true : false}
                                                      disabled={`${
                                                          (item.pageId == DOCTOR_PAGES.Patient && props.id == 3) || (props.id != 3 && item.pageId == DOCTOR_PAGES.Dashboard) ? "disabled" : ""
                                                      }`}
                                                  />
                                              </div>
                                              <div className="offset-1 col-4">{item.Name}</div>
                                          </>
                                      );
                                  })
                                : props.pages.map((item, i) => {
                                      return (
                                          <>
                                              <div className="col-1">
                                                  <input
                                                      type="checkbox"
                                                      className="checkBox mt-2 mb-2"
                                                      key={i}
                                                      value={item.pageId}
                                                      onChange={props.handleCheck}
                                                      checked={item.isAuthority == 1 ? true : false}
                                                      disabled={`${item.pageId == PAGES.Dashboard ? "disabled" : ""}`}
                                                  />
                                              </div>
                                              <div className="offset-1 col-4">{item.Name}</div>
                                          </>
                                      );
                                  })}
                        </div>
                        <div id="checkAdd" className="my-3 text-end">
                            <button className="btn btn-info mx-2" onClick={props.handlePageAdd}>
                                {ADD}
                            </button>
                            <button className="btn btn-default" onClick={props.handlePageClose}>
                                {CANCEL}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default EditRolePages;
