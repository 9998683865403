import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseURL } from "../../Config";
import { useNavigate } from "react-router-dom";
import { OFFICE_DETAIL_API } from "../../Helper/ApiPath";
import { ADDRESS, CITY, DASH, NAME, OFFICE_INFORMATION, PHONE_NO, STATE, ZIP } from "../../Helper/Constants";
import { toast } from "react-toastify";

function OfficeDetail() {
    let officeId = localStorage.getItem("officeId");
    let logToken = JSON.parse(localStorage.getItem("logToken")).token;

    const [officeInfo, setOfficeInfo] = useState([]);
    const navigate = useNavigate();
    const fetchData = async () => {
        await axios
            .get(`${baseURL}/${OFFICE_DETAIL_API}/${officeId}`, {
                headers: { authorization: logToken },
            })
            .then((res) => {
                setOfficeInfo(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="col-md-4">
                <div className="profile-content">
                    <div className="row">
                        <div className="card">
                            <div className="card-head">
                                <header>{OFFICE_INFORMATION}</header>
                            </div>
                            <div className="card-body no-padding height-9">
                                <ul className="list-group list-group-unbordered">
                                    <li className="list-group-item">
                                        <div className="row">
                                            <div className="col-4">
                                                <b>{NAME}</b>
                                            </div>
                                            <div className="col-8">
                                                <a className="pull-right">{officeInfo.name ? officeInfo.name : DASH}</a>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="row">
                                            <div className="col-4">
                                                <b>{ADDRESS}</b>
                                            </div>
                                            <div className="col-8">
                                                <a className="pull-right">{officeInfo.address ? officeInfo.address : DASH}</a>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="row">
                                            <div className="col-4">
                                                <b>{CITY}</b>
                                            </div>
                                            <div className="col-8">
                                                <a className="pull-right">{officeInfo.city ? officeInfo.city : DASH}</a>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="row">
                                            <div className="col-4">
                                                <b>{STATE}</b>
                                            </div>
                                            <div className="col-8">
                                                <a className="pull-right">{officeInfo.state ? officeInfo.state : DASH}</a>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="row">
                                            <div className="col-4">
                                                <b>{ZIP}</b>
                                            </div>
                                            <div className="col-8">
                                                <a className="pull-right">{officeInfo.zip ? officeInfo.zip : DASH}</a>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="row">
                                            <div className="col-4">
                                                <b>{PHONE_NO}</b>
                                            </div>
                                            <div className="col-8">
                                                <a className="pull-right">{officeInfo.phoneNo1 ? officeInfo.phoneNo1 : DASH}</a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OfficeDetail;
