import axios from "axios";
import { unix } from "moment";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Webcam from "react-webcam";
import { baseURL } from "../../../Config";
import { UPLOAD_DOCUMENT_DOCUMENT } from "../../../Helper/ApiPath";
import { CANCEL, CAPTURE, DOCUMENTS, DOCUMENT_FILE, RETAKE_IMAGE, UPLOAD } from "../../../Helper/Constants";
import { UPLOAD_IMAGE } from "../../../Helper/Messages";

const videoConstraints = {
    width: 220,
    height: 200,
    facingMode: "user",
};
function DocumentWebcameUpload(props) {
    let navigate = useNavigate();

    const [img, setImg] = useState("");

    const webcamRef = React.useRef(null);

    const capture = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImg(imageSrc);
    });

    const handleRetake = () => {
        setImg("");
    };

    let logToken = JSON.parse(localStorage.getItem("logToken"));
    let DocumentId = localStorage.getItem("DocumentId");
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleUpload = async (e) => {
        if (!img) {
            toast.error(UPLOAD_IMAGE);
        } else {
            await axios
                .post(`${baseURL}/${UPLOAD_DOCUMENT_DOCUMENT}`, {
                    headers: { authorization: logToken.token, "Content-Type": "multipart/form-data" },
                    fileName: DOCUMENTS + "" + unix(new Date()) + ".png",
                    developId: DocumentId,
                    webCam: img,
                })
                .then((res) => {
                    props.fetchThumbnailData();
                    setShow(false);
                    setImg("");
                    toast.success(res.data.message);
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    return (
        <>
            <button onClick={handleShow}>
                <i className="fa fa-camera fa-2x"></i>
            </button>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{DOCUMENT_FILE}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="webcam-container">
                        <div className="webcam-img text-center">
                            {img == "" ? <Webcam audio={false} height={500} ref={webcamRef} screenshotFormat="image/jpeg" width={520} videoConstraints={videoConstraints} /> : <img src={img} />}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" onClick={handleClose}>
                        {CANCEL}
                    </Button>
                    <div>
                        {img != "" ? (
                            <>
                                <button onClick={handleRetake} className="webcam-btn btn btn-warning">
                                    {RETAKE_IMAGE}
                                </button>
                                <button className="btn btn-primary mx-2" onClick={handleUpload}>
                                    {UPLOAD}
                                </button>
                            </>
                        ) : (
                            <button onClick={capture} className="webcam-btn btn btn-warning">
                                {CAPTURE}
                            </button>
                        )}
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DocumentWebcameUpload;
