import React from "react";

function Iframe() {
  return (
    <>
      <style jsx>{`
                .iframe-container {
                    width: 1000;
                    overflow: hidden;
                    overflow-x: hidden;
                }
                .iframe {
                    overflow-y: scroll;
                    scrollbar-width: none;
                    -ms-overflow-style: none;
                }
                ::-webkit-scrollbar {
                    display: none;
                    width: 0px;
                    background: transparent;
                }
            `}
      </style>

      <div className="iframe-container">
        <iframe src="https://hana828.wixsite.com/bill-with-us" className="iframe" height={1000} width={1000}>
          {" "}
        </iframe>
      </div>
    </>
  );
}

export default Iframe;
