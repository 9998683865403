import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { EMAIL, LOGIN, SEND } from "../../Helper/Constants";
import { toast } from "react-toastify";
import axios from "axios";
import { baseURL } from "../../Config";
import { VERIFY_CHANGE_PASSWORD_EMAIL } from "../../Helper/ApiPath";
import Recaptcha from "react-recaptcha";

function ResetPassword() {
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");

    const handleSend = (e) => {
        e.preventDefault();
        if (email == "") {
            toast.error("Please enter email..");
            return false;
        } else {
            setIsLoading(true);
            axios
                .post(`${baseURL}/${VERIFY_CHANGE_PASSWORD_EMAIL}`, { email: email })
                .then((res) => {
                    setIsLoading(false);
                    if (res.status == 200) {
                        toast.success(res.data.message);
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    toast.error(err.response.data.message);
                });
        }
    };
    return (
        <>
            <div className="limiter">
                <div className="container-login100 page-background">
                    <div className="wrap-login100">
                        <form className="login100-form validate-form">
                            <span className="login100-form-logo">
                                <img alt="" src="assets/img/logo/login-logo.png" />
                            </span>

                            <p className="text-center txt-small-heading">
                                Forgot Your Password? <br />
                                Let Us Help You.
                            </p>

                            <div className="form-group">
                                <i className="fa fa-envelope font-left textColor" aria-hidden="true"></i>
                                <span className="textColor">{EMAIL}</span>
                                <span className="requireField"> * </span>
                                <input type="text" className="form-control" id="txtemail" name="email" placeholder="Enter Email.." value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>

                            {isLoading ? (
                                <>
                                    <i
                                        className="fa fa-spinner fa-spin fa-2x"
                                        style={{
                                            position: "relative",
                                            top: "44px",
                                            left: "47%",
                                            zIndex: "5",
                                        }}
                                    ></i>
                                    <div className="container-login100-form-btn">
                                        <button className="login100-form-btn" disabled>
                                            {SEND}
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <div className="container-login100-form-btn">
                                    <button className="login100-form-btn" onClick={handleSend}>
                                        {SEND}
                                    </button>
                                </div>
                            )}

                            <div className="text-center p-t-27">
                                <Link className="txt1" to="/login">
                                    {LOGIN}?
                                </Link>
                            </div>
                        </form>

                        <Recaptcha sitekey="6Lf9RssfAAAAAGgaNQSd6CL_MpDfa9rmgCxgHqMQ" size="invisible" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ResetPassword;
