import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { baseURL } from "../../../Config";
import { DETAIL_OFFICE_EMPLOYEE_API, OFFICE_EMPLOYEE_STATUS_CHANGE_API } from "../../../Helper/ApiPath";
import { DASH, OFFICE_EMPLOYEE_INFORMATION, CITY, EMAIL, HOME, LAST_NAME, ACCESSLEVEL, VIEW_OFFICE_EMPLOYEE, FIRST_NAME, ADDRESS, STATE, APPROVE, REJECT } from "../../../Helper/Constants";
import { NavLink, useNavigate } from "react-router-dom";
import { APPROVE_MESSAGE, REJECT_MESSAGE } from "../../../Helper/Messages";
import Cookies from "universal-cookie";
import OfficeDetail from "../../ProfilePage/OfficeDetail";
import Swal from "sweetalert2";

function ViewOfficeEmployee() {
    const cookies = new Cookies();

    let OfficeEmployeeId = localStorage.getItem("officeEmployeeId");
    let officeDoctorId = localStorage.getItem("officeDoctorId");
    let token = JSON.parse(localStorage.getItem("logToken"));
    let isTrial = cookies.get("isTrial");
    if (isTrial) {
        isTrial = cookies.get("isTrial").isTrial;
    }
    const [profileInfo, setProfileInfo] = useState([]);
    const navigate = useNavigate();
    const fetchData = async () => {
        await axios
            .get(`${baseURL}/${DETAIL_OFFICE_EMPLOYEE_API}/${OfficeEmployeeId}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                if (res.status === 200) {
                    setProfileInfo(res.data.data);
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleApprove = async (isApprove) => {
        Swal.fire({
            title: isApprove ? APPROVE_MESSAGE : REJECT_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, change it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .post(`${baseURL}/${OFFICE_EMPLOYEE_STATUS_CHANGE_API}`, {
                            headers: { authorization: token.token },
                            officeDoctorId: officeDoctorId,
                            isApprove: isApprove,
                        })
                        .then((res) => {
                            Swal.fire(res.data.message, " ", "success");

                            document.getElementById("Empreject").classList.add("d-none");
                            document.getElementById("Empapprove").classList.add("d-none");

                            localStorage.removeItem("officeEmployeeId");
                            localStorage.removeItem("officeId");
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{VIEW_OFFICE_EMPLOYEE}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{VIEW_OFFICE_EMPLOYEE}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className={`${isTrial == 1 ? "col-8 offset-2" : "col-md-8"} `}>
                            <div className="profile-content">
                                <div className="row">
                                    <div className="card">
                                        <div className="card-head">
                                            <header>{OFFICE_EMPLOYEE_INFORMATION}</header>
                                        </div>
                                        <div className="white-box p-0 mb-2">
                                            <div className="tab-content">
                                                <div className="tab-pane active fontawesome-demo" id="tab1">
                                                    <div id="biography">
                                                        <div className="row">
                                                            <ol className="list-group list-group-unbordered ">
                                                                <h3 className="my-3">
                                                                    <b>
                                                                        {profileInfo.firstName} {profileInfo.lastName}
                                                                    </b>
                                                                </h3>
                                                                {profileInfo.email ? (
                                                                    <li className="list-group-item">
                                                                        <b>{EMAIL}</b>
                                                                        <div className="profile-desc-item pull-right">{profileInfo.email ? profileInfo.email : DASH}</div>
                                                                    </li>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <li className="list-group-item">
                                                                    <b>{FIRST_NAME}</b>
                                                                    <div className="profile-desc-item pull-right">{profileInfo.firstName ? profileInfo.firstName : DASH}</div>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    <b>{LAST_NAME}</b>
                                                                    <div className="profile-desc-item pull-right">{profileInfo.lastName ? profileInfo.lastName : DASH}</div>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    <b>{ADDRESS} </b>
                                                                    <div className="profile-desc-item pull-right">{profileInfo.address1 ? profileInfo.address1 : DASH}</div>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    <b>{STATE}</b>
                                                                    <div className="profile-desc-item pull-right">{profileInfo.stateName ? profileInfo.stateName : DASH}</div>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    <b>{CITY}</b>
                                                                    <div className="profile-desc-item pull-right">{profileInfo.cityName ? profileInfo.cityName : DASH}</div>
                                                                </li>

                                                                <li className="list-group-item">
                                                                    <b>{ACCESSLEVEL}</b>
                                                                    <div className="profile-desc-item pull-right">{profileInfo.accessLvl ? profileInfo.accessLvl : DASH}</div>
                                                                </li>
                                                            </ol>
                                                        </div>
                                                        <br />
                                                        <div className="profile-userbuttons mt-3">
                                                            <button type="button" className="btn btn-circle green btn-sm" id="Empapprove" onClick={() => handleApprove(true)}>
                                                                {APPROVE}
                                                            </button>
                                                            <button type="button" className="btn btn-circle red btn-sm button" id="Empreject" onClick={() => handleApprove(false)}>
                                                                {REJECT}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <OfficeDetail />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ViewOfficeEmployee;
