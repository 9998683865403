module.exports = {
  //////////////////////// LOCAL ////////////////////////////////

  // baseURL: "http://192.168.1.174:5001",
  // baseURL: "http://localhost:5001",
  // frontURL: "http://localhost:5002",
  // baseURL: "http://localhost:5003",
  // frontURL: "http://localhost:5004",
  // showHomePage: true,
  // downLoadDynamicExcel: false,
  // imageSize_file: 5000000, //Normal File
  // imageSize_video: 200000000, // Video File
  // showAllQue: false,

  //////////////////////// LIVE ////////////////////////////////

  baseURL: "https://bwuapi.billwithus.com",
  frontURL: "https://billwithus.com",
  showHomePage: true,
  downLoadDynamicExcel: false,
  imageSize_file: 5000000, //Normal File
  imageSize_video: 200000000, // Video File
  showAllQue: false,

  //////////////////////// DEV ////////////////////////////////

  // baseURL: "https://onemedallapi.raininfotech.dev",
  // frontURL: "https://onemedall.raininfotech.dev",
  // showHomePage: true,

  //////////////////////// OP-NOTE-TEXT ////////////////////////////////
  // change this value with the exact value which you want to show first in op note diagnoses section
  // you can only enter one value here

  DIAGNOSE_CODE: "M26.4",
};