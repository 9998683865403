import axios from "axios";
import _ from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../../../Config";
import { PATIENT_DETAIL_ADD_API } from "../../../../Helper/ApiPath";
import { CANCEL, FORMTYPE, SAVE } from "../../../../Helper/Constants";
import NoDataFound from "../../../CommonComponent/NoDataFound";

function DynamicTabs(props) {
    let navigate = useNavigate();
    let token = JSON.parse(localStorage.getItem("logToken"));
    let patientId = localStorage.getItem("patientId");

    const handleSave = async () => {
        await axios
            .post(`${baseURL}/${PATIENT_DETAIL_ADD_API}`, {
                headers: { authorization: token.token },
                patientId,
                patientChartTabDetails: [...props.checkedArray, ...props.inputValueObj],
            })
            .then((res) => {
                toast.success(res.data.message);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleCancel = () => {
        navigate("/managePatient");
    };

    return (
        <>
            {!props.data ? (
                <div className="d-flex justify-content-center">
                    <NoDataFound />
                </div>
            ) : (
                <div className="row">
                    {props.group &&
                        props.group.map((item, i) => {
                            return (
                                <>
                                    <h3 className="mx-5 my-2">{item.groupName == "No Group Name" || item.groupName == undefined || item.groupName == "" ? "" : item.groupName.toUpperCase()}</h3>

                                    <hr style={{ border: "1px solid", height: "0.1rem" }} />
                                    {item.data.map((item1, i) => {
                                        return (
                                            <>
                                                {item1.formType == FORMTYPE.CHECKBOX ? (
                                                    <>
                                                        <div className="offset-1 col-4">{item1.name}</div>
                                                        <div className="col-1">
                                                            <input
                                                                type="checkbox"
                                                                className="checkBox mt-2 mb-2"
                                                                id={item1.id}
                                                                name={item1.id}
                                                                onChange={(e) => props.handleCheckChange(e, item1.id, item1.groupName)}
                                                                checked={item1.value == "1" || item1.value == 1 || item1.value == true ? true : false}
                                                            />
                                                        </div>
                                                    </>
                                                ) : item1.formType == FORMTYPE.INPUT ? (
                                                    <>
                                                        <div className="col-3 offset-1 my-2">{item1.name}</div>
                                                        <div className="col-2 d-flex justify-content-end">
                                                            <input
                                                                type="text"
                                                                className="form-control my-1"
                                                                id={item1.id}
                                                                name={item1.name}
                                                                value={item1.value}
                                                                onChange={(e) => props.handleChange(e, item1.id, item1.groupName)}
                                                                placeholder={item1.name}
                                                            />
                                                        </div>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </>
                                        );
                                    })}
                                </>
                            );
                        })}
                    <div className="form-actions mt-5">
                        <div className="center">
                            <button type="submit" className="btn btn-info m-r-20" onClick={handleSave}>
                                {SAVE} {props.title.toLowerCase()}
                            </button>

                            <button className="btn btn-default" onClick={handleCancel}>
                                {CANCEL}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default DynamicTabs;
