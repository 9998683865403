import axios from "axios";
import _, { set } from "lodash";
import React, { useEffect, useState } from "react";

import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../../Config";
import {
  ADD_CLAIM_API,
  ALL_DOCTOR_IN_SELECTED_OFFICE,
  DIAGNOSES_API,
  PATIENT_LIST_API,
  PAYER_LIST_API,
  PROCEDURE_LIST_API,
  SUBMIT_CLAIM_API,
  PATIENT_DOCUMENT_LIST,
  DELETE_PATIENT_DOCUMENT,
  COMPREHENSIVE_NOTE_DIAGNOSES_PROCEDURE_API,
  PRE_AUTHORIZATION_NOTE_DATA_API,
} from "../../../Helper/ApiPath";
import {
  $FEE,
  ACCELERATE_WAITING_PERIOD,
  ACCELERATE_WAITING_PERIOD_TITLE,
  ACTION,
  ADD_CLAIM,
  CLAIM_INFORMATION,
  CLAIM_LIST,
  CODE,
  CPT,
  DATE_OF_SERVICE,
  DESCRIPTION,
  DIAGNOSES,
  PROCEDURE,
  FILE_NAME,
  HOME,
  ID,
  INSURANCE_BACK,
  INSURANCE_FRONT,
  OTHERS,
  MAX_PROCEDURE_LENGTH,
  MIN_PROCEDURE_LENGTH,
  NAME,
  NEXT,
  NO,
  NOTE,
  PATIENT_DOCUMENTS,
  PATIENT_NAME,
  PAYER,
  PRE_AUTHORIZATION,
  PROCEDURE_TYPE_WITH_VALUE,
  RENDERRING_PROVIDER,
  SELECT_DOCTOR,
  SELECT_PATIENT,
  SELECT_PAYER,
  SELECT_SERVICE_LOCATION_TYPE,
  SELECT_WAITING_PERIOD,
  SERVICE_LOCATION_TYPE,
  SERVICE_LOCATION_TYPE_TITLE,
  THUMBNAIL,
  UNIT,
  XRAY,
  PATIENT_DOCUMENT_TYPE,
  CREATED_DATE,
  REFERRING_PHYSICIAN_NAME,
} from "../../../Helper/Constants";
import { DELETE_IMAGE_MESSAGE, MAX_PRIORITY } from "../../../Helper/Messages";
import ConfirmationClaim from "./ConfirmationClaim";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  dateAPIFormate,
  dateFormateMonthDateYear,
} from "../../../Helper/Helper";
import Select from "react-select";
import UploadImage from "../Patients/UploadImage";
import NoDataFound from "../../CommonComponent/NoDataFound";
import Swal from "sweetalert2";
import Zoom from "react-medium-image-zoom";
import WebcamUpload from "../Patients/WebcamUpload";
import Cookies from "universal-cookie";

function AddClaim() {
  const cookies = new Cookies();
  const claimId = localStorage.getItem("claimId");
  const [saveBtnIsLoading, setSaveBtnIsLoading] = useState(false);
  const [defSubmitBtnIsLoading, setDefSubmitBtnIsLoading] = useState(false);
  const [insuranceFront, setInsuranceFront] = useState({
    preview: "",
    data: "",
  });
  const [insuranceBack, setInsuranceBack] = useState({ preview: "", data: "" });
  const [imageXRay, setXRay] = useState({ preview: "", data: "" });
  const [imageNote, setImageNote] = useState({ preview: "", data: "" });
  const logToken = JSON.parse(localStorage.getItem("logToken")).token;
  const officeId = localStorage.getItem("officeId");

  let pageAuthorityRoleId = cookies.get("role");
  if (pageAuthorityRoleId) {
    pageAuthorityRoleId = cookies.get("role").pageAuthorityRoleId;
  }

  let checkedArray = [];
  const navigate = useNavigate();
  let checkboxes = document.querySelectorAll("input[type=checkbox]:checked");
  for (let i = 0; i < checkboxes.length; i++) {
    checkedArray.push(checkboxes[i].value);
  }
  let checkedValues = checkedArray.join("~");
  let splitByTilde = checkedValues.split("~");
  const [step, setStep] = useState("1");
  const [isAdded, setisAdded] = useState(false);
  const [ClaimAdedId, setClaimAdedId] = useState();
  const [checkedList, setCheckedList] = useState([]);
  const [error, setError] = useState(false);
  let payerName = localStorage.getItem("payerName");
  let payerId = localStorage.getItem("payerId");
  let payerValue = localStorage.getItem("payerValue");
  const [insuranceFrontDocumentId, setInsuranceFrontDocumentId] = useState();
  let doctorToken = JSON.parse(localStorage.getItem("DoctorToken"));
  const [patientName, setPatientName] = useState([]);
  const [patientId, setPatientId] = useState("");
  const [insuranceF, setInsuranceF] = useState([]);
  const [insuranceB, setInsuranceB] = useState([]);
  const [imageX, setImageX] = useState([]);
  const [imageN, setImageN] = useState([]);
  const [imageId, setImageId] = useState([]);
  const [imageOt, setImageOt] = useState([]);
  const [popUpTitle, setPopUpTitle] = useState("");
  const [imageType, setImageType] = useState("");
  const [documentId, setDocumentId] = useState("");
  const [show, setShow] = useState(false);
  const [webcame, setWebcame] = useState(false);
  const [edit, setEdit] = useState(false);
  let [image, setImage] = useState({ preview: "", data: "" });

  const [search, setSearch] = useState("");
  const [procedureSearch, setProcedureSearch] = useState("");

  const [doctorId, setDoctorId] = useState("");

  const handleImageChange = (e) => {
    const data = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
      name: e.target.files[0].name,
    };
    const fileExtension = data.data.name.split(".").at(-1).toLowerCase();
    let allowedFileTypes =
      imageType === PATIENT_DOCUMENT_TYPE.NOTE ||
      imageType === PATIENT_DOCUMENT_TYPE.INSURANCE_FRONT ||
      imageType === PATIENT_DOCUMENT_TYPE.INSURANCE_BACK ||
      imageType === PATIENT_DOCUMENT_TYPE.X_RAY ||
      imageType === PATIENT_DOCUMENT_TYPE.ID ||
      imageType === PATIENT_DOCUMENT_TYPE.OTHERS
        ? ["pdf", "jpg", "png", "gif", "jpeg"]
        : ["jpg", "png", "gif", "jpeg"];
    if (!allowedFileTypes.includes(fileExtension)) {
      e.target.value = null;
      alert(
        `File does not support. Files type must be ${allowedFileTypes.join(
          ", "
        )}`
      );
    } else {
      let element = document.getElementById("button");
      element.classList.remove("d-none");
      setImage(data);
    }
  };
  const fetchThumbnailData = async (type, id) => {
    axios
      .post(`${baseURL}/${PATIENT_DOCUMENT_LIST}`, {
        headers: { authorization: doctorToken },
        type: type,
        patientId: id,
      })
      .then((res) => {
        if (type === 1) {
          setInsuranceF(res.data.data);
        }
        if (type === PATIENT_DOCUMENT_TYPE.INSURANCE_BACK) {
          setInsuranceB(res.data.data);
        }
        if (type === PATIENT_DOCUMENT_TYPE.X_RAY) {
          setImageX(res.data.data);
        }
        if (type === PATIENT_DOCUMENT_TYPE.NOTE) {
          setImageN(res.data.data);
        }
        if (type === PATIENT_DOCUMENT_TYPE.ID) {
          setImageId(res.data.data);
        }
        if (type === PATIENT_DOCUMENT_TYPE.OTHERS) {
          setImageOt(res.data.data);
        }
      });
  };

  const handleEdit = (title, id, type) => {
    setShow(true);
    setEdit(true);
    setPopUpTitle(title);
    setDocumentId(id);
    setImageType(type);
  };

  const handleClick = (title, type) => {
    setShow(true);
    setPopUpTitle(title);
    setImageType(type);
  };

  const handleClose = () => {
    setShow(false);
    setEdit(false);
  };

  const handleWebcameClick = (title, type) => {
    setWebcame(true);
    setPopUpTitle(title);
    setImageType(type);
  };

  const handleWebcameClose = () => {
    setWebcame(false);
  };

  const handleDelete = (id, type) => {
    Swal.fire({
      title: DELETE_IMAGE_MESSAGE,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (result.isConfirmed) {
          await axios
            .delete(`${baseURL}/${DELETE_PATIENT_DOCUMENT}/${id}`, {
              headers: { authorization: doctorToken },
            })
            .then((res) => {
              Swal.fire(res.data.message, " ", "success");
              fetchThumbnailData(type, patientId);
            })
            .catch((err) => {
              if (err.response) {
                if (err.response.status === 500) {
                  localStorage.clear();
                  navigate("/login");
                } else {
                  toast.error(err.response.data.message);
                }
              }
            });
        }
      }
    });
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!addClaim.serviceLocationType) {
      toast.error("Please Select Service Location.");
    } else if (!addClaim.patientName) {
      toast.error("Please Select Patient.");
    } else if (!addClaim.renderingProvider && !doctorId) {
      toast.error("Please Select Rendering Provider.");
    } else if (
      addClaim.serviceLocationType == SERVICE_LOCATION_TYPE.Hospital &&
      !addClaim.referringPhysicianName.trim()
    ) {
      toast.error(
        "Please Input Referring Physician Name if Service Location Type is Hospital."
      );
    } else if (
      addClaim.serviceLocationType == SERVICE_LOCATION_TYPE.Hospital &&
      addClaim.referringPhysicianName.length > 100
    ) {
      toast.error("Please input Referring Physician Name within 100.");
    } else if (
      addClaim.preAuthorization &&
      addClaim.preAuthorization.length > 50
    ) {
      toast.error("Please input Pre-authorization # within 50.");
    } else {
      let checkedList = document.querySelectorAll(
        "input[type='checkbox']:checked"
      );
      if (checkedList.length === 0) {
        toast.error("Please Check at least one more Diagnoses.");
      } else {
        const diagno = [];
        for (let i = 0; i < checkedList.length; i++) {
          var filterddata = _.find(diagnoses, {
            id: parseInt(checkedList[i].name),
          });

          var newobj = {
            code: filterddata.code,
            name: filterddata.name,
          };
          diagno.push(newobj);
        }
        setconfirmdiagnoses(diagno);
        setStep("2");
        document.getElementById("addClaim").style.display = "none";
        document.getElementById("addClaimHeader").style.display = "none";
      }
    }
  };

  const [claimDoc, setClaimDoc] = useState([]);
  const data = {
    insuranceFront,
    insuranceBack,
    imageXRay,
    imageNote,
  };

  const [procedure, setProcedure] = useState([]);
  const [add_procedure, setadd_procedure] = useState([]);

  const procedureList = async (id) => {
    axios
      .post(`${baseURL}/${PROCEDURE_LIST_API}`, {
        headers: { authorization: logToken },
        doctorId: id,
        statusCpt: "active",
        search: procedureSearch,
      })
      .then(async (res) => {
        setSpeciality(res.data.data[0].specialityId);
        let lstrocedure = res.data.data;
        const groupbydata = await _.chain(lstrocedure)
          .groupBy("cptgroupname")
          .map(function (value, key) {
            for (var i = 0; i < value.length; i++) {
              Object.assign(value[i], { value: 0 });
            }
            return {
              type: key,
              data: value,
            };
          })
          .value();

        setProcedure(groupbydata);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const handleProcedureChange = async (e, Fid) => {
    let value = e.target.value;
    const Fee = e.target.dataset.fee;
    // if (value && parseFloat(value) > MAX_PROCEDURE_LENGTH) {
    //     setError(true);
    //     e.target.value = 0;
    //     value = 0;
    //     // return;
    // } else
    if (value && parseFloat(value) < MIN_PROCEDURE_LENGTH) {
      setError(true);
      e.target.value = 0;
      value = 0;
      //   return;
    }
    // else {
    setError(false);
    const id = e.target.name;
    const type = e.target.dataset.type;
    var oldprocedure = procedure;
    var filterddata = await _.find(oldprocedure, { type: type });
    var filterddata_index = oldprocedure.findIndex((p) => p.type === type);
    var objprocdata = filterddata.data;
    var proc_id = await _.find(objprocdata, { id: parseInt(id) });
    var proc_id_index = objprocdata.findIndex((p) => p.id === parseInt(id));
    var newobj = {
      id: proc_id.id,
      code: proc_id.code,
      type: proc_id.type,
      description: proc_id.description,
      fee: proc_id.fee,
      priorityList: proc_id.priorityList,
      value: parseFloat(value),
    };

    objprocdata[proc_id_index] = newobj;
    oldprocedure[filterddata_index].data = objprocdata;
    // setProcedure((value) => value?.map((procedure, index) => (index === Fid ? { ...procedure, value: value } : procedure)));
    setProcedure([...oldprocedure]);
    var proce_Add = add_procedure;
    var apiObj = {
      procedureId: proc_id.id,
      unit: parseFloat(value),
    };
    var finddata_index = proce_Add.findIndex(
      (p) => p.procedureId === parseInt(proc_id.id)
    );
    if (finddata_index === -1) {
      finddata_index = proce_Add.length;
    }
    proce_Add[finddata_index] = apiObj;
    setadd_procedure(proce_Add);
    // }
  };

  const [addClaim, setAddClaim] = useState({
    payerLabel: "",
    patientLabel: "",
    serviceLocationLabel: "",
    providerLabel: "",
    waitingPeriodLabel: "",
    payer:
      payerId == "null"
        ? "4b2ea166e5d146d5bc587d7e766166f80a2d1d7430f04edf97b34666e8719343"
        : payerId,
    serviceLocationType: "",
    patientName: "",
    renderingProvider: "",
    serviceDate: "",
    waitingPeriod: "",
    preAuthorization: "",
    images: claimDoc,
    referringPhysicianName: "",
    diagnosesEtc: "",
  });

  const handleSave = async () => {
    let checkedValues = checkedList.join("~");
    setSaveBtnIsLoading(true);
    await axios
      .post(`${baseURL}/${ADD_CLAIM_API}`, {
        headers: { authorization: logToken },
        officeId: officeId,
        doctorId: doctorId ? doctorId : addClaim.renderingProvider,
        patientId: addClaim.patientName,
        payerId: addClaim.payer,
        dateOfService: dateAPIFormate(addClaim.serviceDate),
        serviceLocationTypeId: addClaim.serviceLocationType,
        referringPhysicianName: addClaim.referringPhysicianName,
        accelerateWaitingPeriodId: addClaim.waitingPeriod,
        diagnosesId: checkedValues,
        diagnosesEtc: addClaim.diagnosesEtc,
        procedure: add_procedure,
        preAuthorization: addClaim.preAuthorization,
        images: claimDoc,
      })
      .then((res) => {
        setSaveBtnIsLoading(false);
        setisAdded(true);
        setClaimAdedId(res.data.data.claimId);
        document.getElementById("btnsubmit").style.display = "inline-block";
        let element = document.getElementById("btnsave");
        element.classList.add("d-none");
        let submit = document.getElementById("btnDefaultSubmit");
        submit.classList.add("d-none");
        let previous = document.getElementById("btnPrevious");
        previous.classList.add("d-none");
        toast.success(res.data.message);
        // console.log("procedure", add_procedure);
      })
      .catch((err) => {
        setSaveBtnIsLoading(false);
        if (err.response) {
          if (err.response.status === 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
    // }
  };

  const handleDefaultSubmit = async () => {
    setDefSubmitBtnIsLoading(true);
    await axios
      .post(`${baseURL}/${ADD_CLAIM_API}`, {
        headers: { authorization: logToken },
        claimId: ClaimAdedId,
        officeId: officeId,
        doctorId: doctorId ? doctorId : addClaim.renderingProvider,
        patientId: addClaim.patientName,
        payerId: addClaim.payer,
        dateOfService: dateAPIFormate(addClaim.serviceDate),
        serviceLocationTypeId: addClaim.serviceLocationType,
        referringPhysicianName: addClaim.referringPhysicianName,
        accelerateWaitingPeriodId: addClaim.waitingPeriod,
        diagnosesId: checkedValues,
        diagnosesEtc: addClaim.diagnosesEtc,
        procedure: add_procedure,
        preAuthorization: addClaim.preAuthorization,
        images: claimDoc,
        isSubmitted: 1,
      })
      .then((res) => {
        setDefSubmitBtnIsLoading(false);
        setisAdded(true);
        toast.success(res.data.message);
        let submit = document.getElementById("btnsubmit");
        submit.classList.add("d-none");
        let element = document.getElementById("btnsave");
        element.classList.add("d-none");
        let submitDefault = document.getElementById("btnDefaultSubmit");
        submitDefault.classList.add("d-none");
        let previous = document.getElementById("btnPrevious");
        previous.classList.add("d-none");
        add_procedure.map((item, i) => {
          // console.log("procedure unit", item.unit);
        });
      })
      .catch((err) => {
        setDefSubmitBtnIsLoading(false);
        // console.log("errorrrr", err);
        if (err.response) {
          if (err.response.status === 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setAddClaim({ ...addClaim, [name]: value });
  };

  const handlePayerChange = (e) => {
    let label = e.label;
    let value = e.id;
    setAddClaim({ ...addClaim, payerLabel: label, payer: value });
  };

  const [state, setState] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [doctorLabel, setDoctorLabel] = useState("");

  const fetchPreAuthNote = async (patientId) => {
    await axios
      .post(`${baseURL}/${PRE_AUTHORIZATION_NOTE_DATA_API}`, {
        headers: { authorization: logToken },
        patientId: patientId,
      })
      .then((res) => {
        // setIsCNLoading(false);
        // setComprhensiveNote(res.data.data);
        let diagArr = [];
        console.log("@!@!@!@!");

        if (res.data.data) {
          let diag = res.data.data.map((item) => item.diagnoses);
          diag.map((item) => diagArr.push(item));
          const str = diagArr.toString();
          const backToArr = str.split(",");

          setCheckedList([...new Set(backToArr)]);
          // setCheckedDiagnoses([...new Set(backToArr)]);
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };
  const fetchProcedreDiagnoses = async (patientId) => {
    axios
      .post(`${baseURL}/${COMPREHENSIVE_NOTE_DIAGNOSES_PROCEDURE_API}`, {
        headers: { authorization: logToken },
        officeId: officeId,
        patientId: patientId,
      })
      .then((res) => {
        let diagnosesId = JSON.parse(res.data.data.diagnosesId);

        // setCheckedList(diagnosesId); <-- this
        setDoctorLabel(
          res.data.data.doctor.firstName + " " + res.data.data.doctor.lastName
        );
        // setAddClaim({ ...addClaim, renderingProvider: res.data.data.doctor.uniqueId });
        // setDoctorId(res.data.data.doctor.uniqueId);
        axios
          .post(`${baseURL}/${PROCEDURE_LIST_API}`, {
            headers: { authorization: logToken },
            doctorId: res.data.data.doctor.uniqueId,
            // claimId,
            statusCpt: "all",
            search: procedureSearch,
          })
          .then((res1) => {
            setSpeciality(res1.data.data[0].specialityId);
            let oldprocedure = res1.data.data;
            let procedure = JSON.parse(res.data.data.procedure);
            // console.log("procedure", procedure);
            for (var j = 0; j < procedure.length; j++) {
              var procedureId = procedure[j].procedureId;
              var unit = procedure[j].unit;
              var filterddata = _.find(oldprocedure, { id: procedureId });
              if (filterddata && unit) {
                var filterddata_index = oldprocedure.findIndex(
                  (p) => p.id === procedureId
                );
                var newobj = {
                  id: filterddata.id,
                  code: filterddata.code,
                  type: filterddata.type,
                  description: filterddata.description,
                  fee: filterddata.fee,
                  priorityList: filterddata.priorityList,
                  value: parseFloat(unit),
                  cptgroupname: filterddata.cptgroupname,
                  cptgrouppriority: filterddata.cptgrouppriority,
                };
                oldprocedure[filterddata_index] = newobj;
              }
              var proce_Add = add_procedure;
              var apiObj = {
                procedureId: filterddata.id,
                unit: parseFloat(unit),
              };
              var finddata_index = proce_Add.findIndex(
                (p) => p.procedureId === parseInt(filterddata.id)
              );
              if (finddata_index === -1) {
                finddata_index = proce_Add.length;
              }
              proce_Add[finddata_index] = apiObj;
              setadd_procedure(proce_Add);
            }

            const groupbydata = _.chain(oldprocedure)
              .groupBy("cptgroupname")
              .map((value, key) => ({ type: key, data: value }))
              .value();
            setProcedure(groupbydata);
          })
          .catch((err) => {
            // console.log("errorrrr", err);
            if (err.response) {
              if (err.response.status === 500) {
                localStorage.clear();
                navigate("/login");
              } else {
                toast.error(err.response.data.message);
              }
            }
          });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  // console.log("checkedList", checkedList);
  const handlePatientChange = (e) => {
    let label = e.label;
    let value = e.value;

    setPatientId(value);
    setDoctorId("");
    setCheckedList([]);
    setProcedure([]);
    setAddClaim({
      ...addClaim,
      patientLabel: label,
      patientName: value,
      renderingProvider: "",
    });
    setDisabled(false);
    setDoctorLabel("");

    fetchProcedreDiagnoses(value);

    fetchPreAuthNote(value);
    // document.getElementById("button").classList.remove("disabled");
    fetchThumbnailData(PATIENT_DOCUMENT_TYPE.INSURANCE_FRONT, e.value);
    fetchThumbnailData(PATIENT_DOCUMENT_TYPE.INSURANCE_BACK, e.value);
    fetchThumbnailData(PATIENT_DOCUMENT_TYPE.X_RAY, e.value);
    fetchThumbnailData(PATIENT_DOCUMENT_TYPE.NOTE, e.value);
    fetchThumbnailData(PATIENT_DOCUMENT_TYPE.ID, e.value);
    fetchThumbnailData(PATIENT_DOCUMENT_TYPE.OTHERS, e.value);
  };

  let { selectedOption } = state;

  const [disableRPN, setDisableRPN] = useState(true);

  const handleServiceLocationChange = (e) => {
    let label = e.label;
    let value = e.id;
    setAddClaim({
      ...addClaim,
      serviceLocationLabel: label,
      serviceLocationType: value,
      referringPhysicianName: "",
    });
    if (value == SERVICE_LOCATION_TYPE.Hospital) {
      setDisableRPN(false);
    } else {
      setDisableRPN(true);
    }
  };

  const handleProviderChange = (e) => {
    let label = e.label;
    let value = e.value;
    setAddClaim({
      ...addClaim,
      providerLabel: label,
      renderingProvider: value,
    });
    setDoctorId(value);
    setDoctorLabel(label);
    setProcedure([]);
    setadd_procedure([]);
    procedureList(value);
  };

  const handlewaitingPeriodChange = (e) => {
    let label = e.label;
    let value = e.value;
    setAddClaim({
      ...addClaim,
      waitingPeriodLabel: label,
      waitingPeriod: value,
    });
  };

  const handleWaitingPeriodChangeOptions = Object.keys(
    ACCELERATE_WAITING_PERIOD
  ).map((item, i) => {
    return {
      id: ACCELERATE_WAITING_PERIOD[item],
      value: ACCELERATE_WAITING_PERIOD[item],
      label: item,
    };
  });

  const updatePreviousStep = () => {
    setStep("1");
  };

  const handleSubmit = async () => {
    if (!addClaim.serviceLocationType) {
      toast.error("Please Select Service Location.");
    } else if (!addClaim.patientName) {
      toast.error("Please Select Patient.");
    } else if (!addClaim.renderingProvider && !doctorId) {
      toast.error("Please Select Rendering Provider.");
    } else if (
      addClaim.serviceLocationType == SERVICE_LOCATION_TYPE.Hospital &&
      !addClaim.referringPhysicianName.trim()
    ) {
      toast.error(
        "Please Input Referring Physician Name if Service Location Type is Hospital."
      );
    } else if (
      addClaim.serviceLocationType == SERVICE_LOCATION_TYPE.Hospital &&
      addClaim.referringPhysicianName.length > 100
    ) {
      toast.error("Please input Referring Physician Name within 100.");
    } else if (
      addClaim.preAuthorization &&
      addClaim.preAuthorization.length > 50
    ) {
      toast.error("Please input Pre-authorization # within 50.");
    } else if (isAdded && ClaimAdedId) {
      localStorage.removeItem("claimId");
      localStorage.setItem("claimId", ClaimAdedId);
      await axios
        .post(`${baseURL}/${SUBMIT_CLAIM_API}`, {
          headers: { authorization: logToken },
          claimId: ClaimAdedId,
        })
        .then((res) => {
          toast.success(res.data.message);
          // let element = document.getElementById("button");
          // element.classList.add("d-none");
          let submit = document.getElementById("btnsubmit");
          submit.classList.add("d-none");
          let element = document.getElementById("btnsave");
          element.classList.add("d-none");
          let submitDefault = document.getElementById("btnDefaultSubmit");
          submitDefault.classList.add("d-none");
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 500) {
              localStorage.clear();
              navigate("/login");
            } else {
              toast.error(err.response.data.message);
            }
          }
        });
    } else {
      toast.error("Please save the claim .");
    }
  };

  const handleCancel = () => {};

  const [renderingProvider, setRenderingProvider] = useState([]);
  const renderProvidedList = () => {
    axios
      .post(`${baseURL}/${ALL_DOCTOR_IN_SELECTED_OFFICE}`, {
        headers: { authorization: logToken },
        officeId: officeId,
        isDoctor: true,
      })
      .then((res) => {
        setRenderingProvider(res.data.data);
      })
      .catch((err) => {
        // console.log("errorrrr", err);
        if (err.response) {
          if (err.response.status === 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const renderingProviderOptions =
    renderingProvider &&
    renderingProvider.map((item, i) => {
      return {
        value: item.id,
        label: item.firstName + " " + item.lastName,
      };
    });

  // let officeId = localStorage.getItem("officeId");

  const patientNameList = () => {
    axios
      .post(`${baseURL}/${PATIENT_LIST_API}`, {
        headers: { authorization: logToken },
        officeId: officeId,
      })
      .then((res) => {
        setPatientName(res.data.data);
      })
      .catch((err) => {
        // console.log("errorrrr", err);
        if (err.response) {
          if (err.response.status === 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const patientNameOptions =
    patientName &&
    patientName.map((item, i) => {
      return {
        value: item.id,
        label: item.firstName + " " + item.lastName,
      };
    });

  const [diagnoses, setDiagnoses] = useState([]);
  const [filterDiagnoses, setfilterDiagnoses] = useState([]);
  const [confirmdiagnoses, setconfirmdiagnoses] = useState([]);

  const diagnosesList = () => {
    axios
      .post(`${baseURL}/${DIAGNOSES_API}`, {
        headers: { authorization: logToken },
        search,
        isNote: "0",
      })
      .then((res) => {
        setDiagnoses(res.data.data);
        setfilterDiagnoses(res.data.data);
      })
      .catch((err) => {
        // console.log("errorrrr", err);
        if (err.response) {
          if (err.response.status === 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const [payer, setPayer] = useState([]);

  const payerList = () => {
    axios
      .get(`${baseURL}/${PAYER_LIST_API}`, {
        headers: { authorization: logToken },
      })
      .then((res) => {
        setPayer(res.data.data);
      })
      .catch((err) => {
        // console.log("errorrrr", err);
        if (err.response) {
          if (err.response.status === 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const payerOptions =
    payer &&
    payer.map((item, i) => {
      return {
        id: item.id,
        value: item.value,
        label: item.name,
      };
    });

  const serviceLocationOptions = Object.keys(SERVICE_LOCATION_TYPE).map(
    (item, i) => {
      return {
        id: SERVICE_LOCATION_TYPE[item],
        value: SERVICE_LOCATION_TYPE[item],
        label: item,
      };
    }
  );

  const [speciality, setSpeciality] = useState("");

  const checkedchange = (e) => {
    const { name, value } = e.target;
    var checkedListData = checkedList;
    const index1 = checkedListData.indexOf(value);
    if (index1 > -1) {
      // only splice array when item is found
      checkedListData.splice(index1, 1); // 2nd parameter means remove one item only
      setCheckedList([...checkedList, ...checkedList.slice(index1, 1)]);
    } else {
      setCheckedList([...checkedList, value]);
    }
  };
  const Diagnosis_Search_Change = async (e) => {
    const { name, value } = e.target;
    setSearch(e.target.value);
    var dialist = diagnoses;
    const filteredItemsdia = await dialist.filter(
      (item) =>
        JSON.stringify(item).toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    setfilterDiagnoses(filteredItemsdia);
  };

  useEffect(async () => {
    localStorage.removeItem("patientId");

    await diagnosesList();
    await renderProvidedList();
    await patientNameList();
    await payerList();
  }, []);

  return (
    <>
      <div className="page-content-wrapper">
        <div className="page-content">
          {/* <ToastContainer /> */}
          <div className="page-bar" id="addClaimHeader">
            <div className="page-title-breadcrumb">
              <div className=" pull-left">
                {" "}
                <div className="page-title">{ADD_CLAIM}</div>
              </div>
            </div>
            <ol className="breadcrumb page-breadcrumb pull-right">
              <li>
                <i className="fa fa-home"></i>&nbsp;
                <NavLink className="parent-item" to="/dashboard">
                  {HOME}
                </NavLink>
                &nbsp;
                <i className="fa fa-angle-right"></i>
              </li>
              <li>
                <NavLink className="parent-item" to="/manageClaim">
                  {CLAIM_LIST}
                </NavLink>
                &nbsp;<i className="fa fa-angle-right"></i>
              </li>
              <li className="active">{ADD_CLAIM}</li>
            </ol>
          </div>
          {step === "1" && (
            <div id="addClaim">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="card card-box">
                    <div className="card-head">
                      <header>{CLAIM_INFORMATION}</header>
                    </div>

                    <div className="card-body" id="bar-parent">
                      <div id="form_sample_1" className="form-horizontal">
                        <div className="form-body">
                          <div className="form-row row">
                            <div className="form-group row">
                              <label className="control-label col-md-2">
                                {PAYER}
                              </label>
                              <div className="col-md-3">
                                <Select
                                  name="payer"
                                  // value={payerOptions && payerOptions.filter(({ id }) => id == payerId)}
                                  defaultValue={{
                                    label:
                                      payerName == "null"
                                        ? "CA Medicare South"
                                        : payerName,
                                  }}
                                  options={payerOptions}
                                  onChange={handlePayerChange}
                                  placeholder={SELECT_PAYER}
                                ></Select>
                                {/* )} */}
                                <p style={{ fontSize: "11px" }}>
                                  Please Select the Payer for this patient.
                                </p>
                              </div>

                              <label className="control-label col-md-2">
                                {SERVICE_LOCATION_TYPE_TITLE}
                                <span className="required"> * </span>
                              </label>
                              <div className="col-md-3">
                                <Select
                                  name="serviceLocationType"
                                  // value={selectedOption}
                                  value={
                                    serviceLocationOptions &&
                                    serviceLocationOptions.filter(
                                      ({ id }) =>
                                        id === addClaim.serviceLocationType
                                    )
                                  }
                                  options={serviceLocationOptions}
                                  onChange={handleServiceLocationChange}
                                  placeholder={SELECT_SERVICE_LOCATION_TYPE}
                                ></Select>
                                {/* )} */}
                                <p style={{ fontSize: "11px" }}>
                                  Please Select the service location type(is not
                                  the same as practice).
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="form-row row">
                            <div className="form-group row">
                              <label className="control-label col-md-2">
                                {PATIENT_NAME}
                                <span className="required"> * </span>
                              </label>
                              <div className="col-md-3">
                                <Select
                                  name="patientName"
                                  value={
                                    patientNameOptions &&
                                    patientNameOptions.filter(
                                      ({ value }) =>
                                        value === addClaim.patientName
                                    )
                                  }
                                  options={patientNameOptions}
                                  onChange={handlePatientChange}
                                  // onChange={handlePatientId}
                                  placeholder={SELECT_PATIENT}
                                  // onClick={() => handleEdit(item.id)}
                                ></Select>
                                {/* )} */}
                                <p style={{ fontSize: "11px" }}>
                                  Please Select the patient.
                                </p>
                              </div>

                              {disableRPN ? (
                                <>
                                  <label className="control-label col-md-2">
                                    {REFERRING_PHYSICIAN_NAME}
                                  </label>
                                  <div className="col-md-3">
                                    <input
                                      name="referringPhysicianName"
                                      value={
                                        addClaim &&
                                        addClaim.referringPhysicianName
                                      }
                                      type="text"
                                      placeholder="Don't enter a referring Physician Name"
                                      className="form-control input-height"
                                      onChange={handleChange}
                                      disabled="disabled"
                                    />
                                    <p style={{ fontSize: "11px" }}>
                                      Please input a referring physician name
                                      and NPI# if this is the Hospital case.
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <label className="control-label col-md-2">
                                    {REFERRING_PHYSICIAN_NAME}
                                    <span className="required"> * </span>
                                  </label>
                                  <div className="col-md-3">
                                    <input
                                      name="referringPhysicianName"
                                      value={
                                        addClaim &&
                                        addClaim.referringPhysicianName
                                      }
                                      type="text"
                                      placeholder="Enter a referring Physician Name"
                                      className="form-control input-height"
                                      onChange={handleChange}
                                    />
                                    <p style={{ fontSize: "11px" }}>
                                      Please input a referring physician name
                                      and NPI# if this is the Hospital case.
                                    </p>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>

                          <div className="form-row row">
                            <div className="form-group row">
                              <label className="control-label col-md-2">
                                {DATE_OF_SERVICE}
                              </label>
                              <div className="col-md-3">
                                <DatePicker
                                  className="form-control input-height mr-sm-2"
                                  selected={addClaim.serviceDate}
                                  placeholderText={"Please Select Date"}
                                  maxDate={new Date()}
                                  scrollableMonthDropdown={true}
                                  showYearDropdown
                                  showMonthDropdown
                                  onChange={(date) =>
                                    setAddClaim({
                                      ...addClaim,
                                      serviceDate: date,
                                    })
                                  }
                                />

                                <p style={{ fontSize: "11px" }}>
                                  Please Select the date of service.
                                </p>
                              </div>

                              <label className="control-label col-md-2">
                                {RENDERRING_PROVIDER}
                                <span className="required"> * </span>
                              </label>
                              <div className="col-md-3">
                                <Select
                                  name="renderingProvider"
                                  value={
                                    renderingProviderOptions &&
                                    renderingProviderOptions.filter(
                                      ({ value }) => value == doctorId
                                    )
                                  }
                                  options={renderingProviderOptions}
                                  onChange={handleProviderChange}
                                  placeholder={SELECT_DOCTOR}
                                ></Select>
                                {/* )} */}
                                <p style={{ fontSize: "11px" }}>
                                  Please Select a doctor in the Office off the
                                  Logged in user.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="form-row row">
                            <div className="form-group row">
                              <label className="control-label col-md-2">
                                {PRE_AUTHORIZATION}
                              </label>
                              <div className="col-md-3">
                                <input
                                  name="preAuthorization"
                                  value={addClaim && addClaim.preAuthorization}
                                  type="text"
                                  placeholder="Enter pre-authorization number"
                                  className="form-control input-height"
                                  onChange={handleChange}
                                />
                                {/* )} */}
                                <p style={{ fontSize: "11px" }}>
                                  Please input the pre-authorization number.
                                </p>
                              </div>

                              <label className="control-label col-md-2">
                                {ACCELERATE_WAITING_PERIOD_TITLE}
                              </label>
                              <div className="col-md-3">
                                <Select
                                  name="waitingPeriod"
                                  value={
                                    handleWaitingPeriodChangeOptions &&
                                    handleWaitingPeriodChangeOptions.filter(
                                      ({ id }) => id === addClaim.waitingPeriod
                                    )
                                  }
                                  options={handleWaitingPeriodChangeOptions}
                                  onChange={handlewaitingPeriodChange}
                                  placeholder={SELECT_WAITING_PERIOD}
                                ></Select>
                                {/* )} */}
                                <p style={{ fontSize: "11px" }}>
                                  Please Select the accelerate waiting period.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Image Section */}
              <div id="imageSection">
                <UploadImage
                  popUpTitle={popUpTitle}
                  show={show}
                  handleClose={handleClose}
                  patientId={patientId}
                  imageType={imageType}
                  edit={edit}
                  documentId={documentId}
                  handleImageChange={handleImageChange}
                  image={image}
                  fetchThumbnailData={() =>
                    fetchThumbnailData(imageType, patientId)
                  }
                />
                <WebcamUpload
                  popUpTitle={popUpTitle}
                  webcame={webcame}
                  handleWebcameClose={handleWebcameClose}
                  patientId={patientId}
                  imageType={imageType}
                  fetchThumbnailData={() =>
                    fetchThumbnailData(imageType, patientId)
                  }
                />
                <div className="card">
                  <div className="card-head">
                    <header>{PATIENT_DOCUMENTS}</header>
                  </div>
                  <div className="card-body no-padding height-9">
                    <div className="row mx-2">
                      <div className="col-6">
                        <div className="d-flex justify-content-between my-2 mx-2">
                          <div>
                            <p
                              style={{ fontSize: "Larger", fontWeight: "600" }}
                            >
                              {INSURANCE_FRONT}
                            </p>
                          </div>
                          <div className="d-flex justify-content-center">
                            <div>
                              <button
                                className={`btn btn-info btn-xs mx-2 ${
                                  disabled ? "disabled" : ""
                                }`}
                                style={{
                                  borderRadius: "50%",
                                  width: "35px",
                                  height: "35px",
                                }}
                                onClick={() =>
                                  handleClick(
                                    INSURANCE_FRONT,
                                    PATIENT_DOCUMENT_TYPE.INSURANCE_FRONT
                                  )
                                }
                              >
                                <i
                                  className="fa fa-plus"
                                  style={{ marginLeft: "-1px" }}
                                ></i>{" "}
                              </button>
                              {/* <UploadImage /> */}
                            </div>
                            <div style={{ marginTop: "10px" }}>
                              <button
                                onClick={() =>
                                  handleWebcameClick(
                                    INSURANCE_FRONT,
                                    PATIENT_DOCUMENT_TYPE.INSURANCE_FRONT
                                  )
                                }
                              >
                                {" "}
                                <i
                                  className="fa fa-camera "
                                  style={{ fontSize: "30px" }}
                                ></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="table-scrollable">
                          <table
                            className="table table-hover table-checkable order-column full-width"
                            id="example4"
                          >
                            <thead>
                              <tr>
                                <th>{NO}</th>
                                <th>{FILE_NAME}</th>
                                <th>{THUMBNAIL}</th>
                                <th>{CREATED_DATE}</th>
                                <th>{ACTION}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(insuranceF && !insuranceF.length) ||
                              !insuranceF ? (
                                <>{<NoDataFound />}</>
                              ) : (
                                insuranceF &&
                                insuranceF.map((item, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{(1 - 1) * 100 + i + 1}</td>
                                      <td>
                                        <div
                                          data-toggle="tooltip"
                                          title={item.fileName}
                                        >
                                          {item.fileName
                                            ? item.fileName.length > 20
                                              ? item.fileName.slice(0, 20) +
                                                "..."
                                              : item.fileName
                                            : INSURANCE_FRONT + ".png"}
                                        </div>
                                      </td>
                                      <td>
                                        {item.image
                                          .split(".")
                                          .at(-1)
                                          .toLowerCase() === "pdf" ? (
                                          <button
                                            type="button"
                                            className="btn btn-outline-danger"
                                            onClick={() =>
                                              window.open(item.image, "_blank")
                                            }
                                          >
                                            <i className="fa  fa-file-pdf-o fa-2x"></i>
                                          </button>
                                        ) : (
                                          <Zoom>
                                            <img
                                              src={item.image}
                                              width="100"
                                              height="100"
                                              // className="img-circle user-img-circle my-5 "
                                              alt="Patient Document"
                                              style={{
                                                height: "50px",
                                                width: "50px",
                                              }}
                                            />
                                          </Zoom>
                                        )}{" "}
                                      </td>
                                      <td>
                                        {item.created_at
                                          ? dateFormateMonthDateYear(
                                              item.created_at
                                            )
                                          : "-"}
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          className="btn btn-primary btn-xs"
                                          onClick={() =>
                                            handleEdit(
                                              INSURANCE_FRONT,
                                              item.id,
                                              item.type
                                            )
                                          }
                                        >
                                          <i className="fa  fa-pencil"></i>
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-danger btn-xs"
                                          onClick={() =>
                                            handleDelete(
                                              item.id,
                                              item.type,
                                              patientId
                                            )
                                          }
                                        >
                                          <i className="fa  fa-trash"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="d-flex justify-content-between my-2 mx-2">
                          <div>
                            <p
                              style={{ fontSize: "Larger", fontWeight: "600" }}
                            >
                              {INSURANCE_BACK}
                            </p>
                          </div>
                          <div className="d-flex justify-content-center">
                            <div>
                              <button
                                className={`btn btn-info btn-xs  mx-2  ${
                                  disabled ? "disabled" : ""
                                }`}
                                style={{
                                  borderRadius: "50%",
                                  width: "35px",
                                  height: "35px",
                                }}
                                onClick={() =>
                                  handleClick(
                                    INSURANCE_BACK,
                                    PATIENT_DOCUMENT_TYPE.INSURANCE_BACK
                                  )
                                }
                              >
                                <i
                                  className="fa fa-plus"
                                  style={{ marginLeft: "-1px" }}
                                ></i>{" "}
                              </button>
                            </div>
                            <div style={{ marginTop: "10px" }}>
                              <button
                                onClick={() =>
                                  handleWebcameClick(
                                    INSURANCE_BACK,
                                    PATIENT_DOCUMENT_TYPE.INSURANCE_BACK
                                  )
                                }
                              >
                                {" "}
                                <i
                                  className="fa fa-camera "
                                  style={{ fontSize: "30px" }}
                                ></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="table-scrollable">
                          <table
                            className="table table-hover table-checkable order-column full-width"
                            id="example4"
                          >
                            <thead>
                              <tr>
                                <th>{NO}</th>
                                <th>{FILE_NAME}</th>
                                <th>{THUMBNAIL}</th>
                                <th>{CREATED_DATE}</th>
                                <th>{ACTION}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(insuranceB && !insuranceB.length) ||
                              !insuranceB ? (
                                <>{<NoDataFound />}</>
                              ) : (
                                insuranceB &&
                                insuranceB.map((item, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{(1 - 1) * 100 + i + 1}</td>
                                      <td>
                                        <div
                                          data-toggle="tooltip"
                                          title={item.fileName}
                                        >
                                          {item.fileName
                                            ? item.fileName.length > 20
                                              ? item.fileName.slice(0, 20) +
                                                "..."
                                              : item.fileName
                                            : INSURANCE_BACK + ".png"}
                                        </div>
                                      </td>
                                      <td>
                                        {item.image
                                          .split(".")
                                          .at(-1)
                                          .toLowerCase() === "pdf" ? (
                                          <button
                                            type="button"
                                            className="btn btn-outline-danger"
                                            onClick={() =>
                                              window.open(item.image, "_blank")
                                            }
                                          >
                                            <i className="fa  fa-file-pdf-o fa-2x"></i>
                                          </button>
                                        ) : (
                                          <Zoom>
                                            <img
                                              src={item.image}
                                              width="100"
                                              height="100"
                                              // className="img-circle user-img-circle my-5 "
                                              alt="Patient Document"
                                              style={{
                                                height: "50px",
                                                width: "50px",
                                              }}
                                            />
                                          </Zoom>
                                        )}{" "}
                                      </td>
                                      <td>
                                        {item.created_at
                                          ? dateFormateMonthDateYear(
                                              item.created_at
                                            )
                                          : "-"}
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          className="btn btn-primary btn-xs"
                                          onClick={() =>
                                            handleEdit(
                                              INSURANCE_BACK,
                                              item.id,
                                              item.type
                                            )
                                          }
                                        >
                                          <i className="fa  fa-pencil"></i>
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-danger btn-xs"
                                          onClick={() =>
                                            handleDelete(
                                              item.id,
                                              item.type,
                                              patientId
                                            )
                                          }
                                        >
                                          <i className="fa  fa-trash"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="d-flex justify-content-between my-2 mx-2">
                          <div>
                            <p
                              style={{ fontSize: "Larger", fontWeight: "600" }}
                            >
                              {XRAY}
                            </p>
                          </div>
                          <div className="d-flex justify-content-center">
                            <div>
                              <button
                                className={`btn btn-info btn-xs  mx-2  ${
                                  disabled ? "disabled" : ""
                                }`}
                                style={{
                                  borderRadius: "50%",
                                  width: "35px",
                                  height: "35px",
                                }}
                                onClick={() =>
                                  handleClick(XRAY, PATIENT_DOCUMENT_TYPE.X_RAY)
                                }
                              >
                                <i
                                  className="fa fa-plus"
                                  style={{ marginLeft: "-1px" }}
                                ></i>{" "}
                              </button>
                            </div>
                            <div style={{ marginTop: "10px" }}>
                              <button
                                onClick={() =>
                                  handleWebcameClick(
                                    XRAY,
                                    PATIENT_DOCUMENT_TYPE.X_RAY
                                  )
                                }
                              >
                                {" "}
                                <i
                                  className="fa fa-camera "
                                  style={{ fontSize: "30px" }}
                                ></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="table-scrollable">
                          <table
                            className="table table-hover table-checkable order-column full-width"
                            id="example4"
                          >
                            <thead>
                              <tr>
                                <th>{NO}</th>
                                <th>{FILE_NAME}</th>
                                <th>{THUMBNAIL}</th>
                                <th>{CREATED_DATE}</th>
                                <th>{ACTION}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(imageX && !imageX.length) || !imageX ? (
                                <>{<NoDataFound />}</>
                              ) : (
                                imageX &&
                                imageX.map((item, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{(1 - 1) * 100 + i + 1}</td>
                                      <td>
                                        <div
                                          data-toggle="tooltip"
                                          title={item.fileName}
                                        >
                                          {item.fileName
                                            ? item.fileName.length > 20
                                              ? item.fileName.slice(0, 20) +
                                                "..."
                                              : item.fileName
                                            : XRAY + ".png"}
                                        </div>
                                      </td>
                                      <td>
                                        {item.image
                                          .split(".")
                                          .at(-1)
                                          .toLowerCase() === "pdf" ? (
                                          <button
                                            type="button"
                                            className="btn btn-outline-danger"
                                            onClick={() =>
                                              window.open(item.image, "_blank")
                                            }
                                          >
                                            <i className="fa  fa-file-pdf-o fa-2x"></i>
                                          </button>
                                        ) : (
                                          <Zoom>
                                            <img
                                              src={item.image}
                                              width="100"
                                              height="100"
                                              // className="img-circle user-img-circle my-5 "
                                              alt="Patient Document"
                                              style={{
                                                height: "50px",
                                                width: "50px",
                                              }}
                                            />
                                          </Zoom>
                                        )}{" "}
                                      </td>
                                      <td>
                                        {item.created_at
                                          ? dateFormateMonthDateYear(
                                              item.created_at
                                            )
                                          : "-"}
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          className="btn btn-primary btn-xs"
                                          onClick={() =>
                                            handleEdit(XRAY, item.id, item.type)
                                          }
                                        >
                                          <i className="fa  fa-pencil"></i>
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-danger btn-xs"
                                          onClick={() =>
                                            handleDelete(
                                              item.id,
                                              item.type,
                                              patientId
                                            )
                                          }
                                        >
                                          <i className="fa  fa-trash"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="d-flex justify-content-between my-2 mx-2">
                          <div>
                            <p
                              style={{ fontSize: "Larger", fontWeight: "600" }}
                            >
                              {NOTE}
                            </p>
                          </div>
                          <div className="d-flex justify-content-center">
                            <div>
                              <button
                                className={`btn btn-info btn-xs  mx-2  ${
                                  disabled ? "disabled" : ""
                                }`}
                                style={{
                                  borderRadius: "50%",
                                  width: "35px",
                                  height: "35px",
                                }}
                                onClick={() =>
                                  handleClick(NOTE, PATIENT_DOCUMENT_TYPE.NOTE)
                                }
                              >
                                <i
                                  className="fa fa-plus"
                                  style={{ marginLeft: "-1px" }}
                                ></i>{" "}
                              </button>
                            </div>
                            <div style={{ marginTop: "10px" }}>
                              <button
                                onClick={() =>
                                  handleWebcameClick(
                                    NOTE,
                                    PATIENT_DOCUMENT_TYPE.NOTE
                                  )
                                }
                              >
                                {" "}
                                <i
                                  className="fa fa-camera "
                                  style={{ fontSize: "30px" }}
                                ></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="table-scrollable">
                          <table
                            className="table table-hover table-checkable order-column full-width"
                            id="example4"
                          >
                            <thead>
                              <tr>
                                <th>{NO}</th>
                                <th>{FILE_NAME}</th>
                                <th>{THUMBNAIL}</th>
                                <th>{CREATED_DATE}</th>
                                <th>{ACTION}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(imageN && !imageN.length) || !imageN ? (
                                <>{<NoDataFound />}</>
                              ) : (
                                imageN &&
                                imageN.map((item, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{(1 - 1) * 100 + i + 1}</td>
                                      <td>
                                        <div
                                          data-toggle="tooltip"
                                          title={item.fileName}
                                        >
                                          {item.fileName
                                            ? item.fileName.length > 20
                                              ? item.fileName.slice(0, 20) +
                                                "..."
                                              : item.fileName
                                            : NOTE + ".png"}
                                        </div>
                                      </td>
                                      <td>
                                        {item.image
                                          .split(".")
                                          .at(-1)
                                          .toLowerCase() === "pdf" ? (
                                          <button
                                            type="button"
                                            className="btn btn-outline-danger"
                                            onClick={() =>
                                              window.open(item.image, "_blank")
                                            }
                                          >
                                            <i className="fa  fa-file-pdf-o fa-2x"></i>
                                          </button>
                                        ) : (
                                          <Zoom>
                                            <img
                                              src={item.image}
                                              width="100"
                                              height="100"
                                              // className="img-circle user-img-circle my-5 "
                                              alt="Patient Document"
                                              style={{
                                                height: "50px",
                                                width: "50px",
                                              }}
                                            />
                                          </Zoom>
                                        )}{" "}
                                      </td>
                                      <td>
                                        {item.created_at
                                          ? dateFormateMonthDateYear(
                                              item.created_at
                                            )
                                          : "-"}
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          className="btn btn-primary btn-xs"
                                          onClick={() =>
                                            handleEdit(NOTE, item.id, item.type)
                                          }
                                        >
                                          <i className="fa  fa-pencil"></i>
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-danger btn-xs"
                                          onClick={() =>
                                            handleDelete(
                                              item.id,
                                              item.type,
                                              patientId
                                            )
                                          }
                                        >
                                          <i className="fa  fa-trash"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="d-flex justify-content-between my-2 mx-2">
                          <div>
                            <p
                              style={{ fontSize: "Larger", fontWeight: "600" }}
                            >
                              {ID}
                            </p>
                          </div>
                          <div className="d-flex justify-content-center">
                            <div>
                              <button
                                className={`btn btn-info btn-xs  mx-2  ${
                                  disabled ? "disabled" : ""
                                }`}
                                style={{
                                  borderRadius: "50%",
                                  width: "35px",
                                  height: "35px",
                                }}
                                onClick={() =>
                                  handleClick(ID, PATIENT_DOCUMENT_TYPE.ID)
                                }
                              >
                                <i
                                  className="fa fa-plus"
                                  style={{ marginLeft: "-1px" }}
                                ></i>{" "}
                              </button>
                            </div>
                            <div style={{ marginTop: "10px" }}>
                              <button
                                onClick={() =>
                                  handleWebcameClick(
                                    ID,
                                    PATIENT_DOCUMENT_TYPE.ID
                                  )
                                }
                              >
                                {" "}
                                <i
                                  className="fa fa-camera "
                                  style={{ fontSize: "30px" }}
                                ></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="table-scrollable">
                          <table
                            className="table table-hover table-checkable order-column full-width"
                            id="example4"
                          >
                            <thead>
                              <tr>
                                <th>{NO}</th>
                                <th>{FILE_NAME}</th>
                                <th>{THUMBNAIL}</th>
                                <th>{CREATED_DATE}</th>
                                <th>{ACTION}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(imageId && !imageId.length) || !imageId ? (
                                <>{<NoDataFound />}</>
                              ) : (
                                imageId &&
                                imageId.map((item, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{(1 - 1) * 100 + i + 1}</td>
                                      <td>
                                        <div
                                          data-toggle="tooltip"
                                          title={item.fileName}
                                        >
                                          {item.fileName
                                            ? item.fileName.length > 20
                                              ? item.fileName.slice(0, 20) +
                                                "..."
                                              : item.fileName
                                            : ID + ".png"}
                                        </div>
                                      </td>
                                      <td>
                                        {item.image
                                          .split(".")
                                          .at(-1)
                                          .toLowerCase() === "pdf" ? (
                                          <button
                                            type="button"
                                            className="btn btn-outline-danger"
                                            onClick={() =>
                                              window.open(item.image, "_blank")
                                            }
                                          >
                                            <i className="fa  fa-file-pdf-o fa-2x"></i>
                                          </button>
                                        ) : (
                                          <Zoom>
                                            <img
                                              src={item.image}
                                              width="100"
                                              height="100"
                                              // className="img-circle user-img-circle my-5 "
                                              alt="Patient Document"
                                              style={{
                                                height: "50px",
                                                width: "50px",
                                              }}
                                            />
                                          </Zoom>
                                        )}{" "}
                                      </td>
                                      <td>
                                        {item.created_at
                                          ? dateFormateMonthDateYear(
                                              item.created_at
                                            )
                                          : "-"}
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          className="btn btn-primary btn-xs"
                                          onClick={() =>
                                            handleEdit(ID, item.id, item.type)
                                          }
                                        >
                                          <i className="fa  fa-pencil"></i>
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-danger btn-xs"
                                          onClick={() =>
                                            handleDelete(
                                              item.id,
                                              item.type,
                                              patientId
                                            )
                                          }
                                        >
                                          <i className="fa  fa-trash"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="d-flex justify-content-between my-2 mx-2">
                          <div>
                            <p
                              style={{ fontSize: "Larger", fontWeight: "600" }}
                            >
                              {OTHERS}
                            </p>
                          </div>
                          <div className="d-flex justify-content-center">
                            <div>
                              <button
                                className={`btn btn-info btn-xs  mx-2  ${
                                  disabled ? "disabled" : ""
                                }`}
                                style={{
                                  borderRadius: "50%",
                                  width: "35px",
                                  height: "35px",
                                }}
                                onClick={() =>
                                  handleClick(
                                    OTHERS,
                                    PATIENT_DOCUMENT_TYPE.OTHERS
                                  )
                                }
                              >
                                <i
                                  className="fa fa-plus"
                                  style={{ marginLeft: "-1px" }}
                                ></i>{" "}
                              </button>
                            </div>
                            <div style={{ marginTop: "10px" }}>
                              <button
                                onClick={() =>
                                  handleWebcameClick(
                                    OTHERS,
                                    PATIENT_DOCUMENT_TYPE.OTHERS
                                  )
                                }
                              >
                                {" "}
                                <i
                                  className="fa fa-camera "
                                  style={{ fontSize: "30px" }}
                                ></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="table-scrollable">
                          <table
                            className="table table-hover table-checkable order-column full-width"
                            id="example4"
                          >
                            <thead>
                              <tr>
                                <th>{NO}</th>
                                <th>{FILE_NAME}</th>
                                <th>{THUMBNAIL}</th>
                                <th>{CREATED_DATE}</th>
                                <th>{ACTION}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(imageOt && !imageOt.length) || !imageOt ? (
                                <>{<NoDataFound />}</>
                              ) : (
                                imageOt &&
                                imageOt.map((item, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{(1 - 1) * 100 + i + 1}</td>
                                      <td>
                                        <div
                                          data-toggle="tooltip"
                                          title={item.fileName}
                                        >
                                          {item.fileName
                                            ? item.fileName.length > 20
                                              ? item.fileName.slice(0, 20) +
                                                "..."
                                              : item.fileName
                                            : OTHERS + ".png"}
                                        </div>
                                      </td>
                                      <td>
                                        {item.image
                                          .split(".")
                                          .at(-1)
                                          .toLowerCase() === "pdf" ? (
                                          <button
                                            type="button"
                                            className="btn btn-outline-danger"
                                            onClick={() =>
                                              window.open(item.image, "_blank")
                                            }
                                          >
                                            <i className="fa  fa-file-pdf-o fa-2x"></i>
                                          </button>
                                        ) : (
                                          <Zoom>
                                            <img
                                              src={item.image}
                                              width="100"
                                              height="100"
                                              // className="img-circle user-img-circle my-5 "
                                              alt="Patient Document"
                                              style={{
                                                height: "50px",
                                                width: "50px",
                                              }}
                                            />
                                          </Zoom>
                                        )}{" "}
                                      </td>
                                      <td>
                                        {item.created_at
                                          ? dateFormateMonthDateYear(
                                              item.created_at
                                            )
                                          : "-"}
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          className="btn btn-primary btn-xs"
                                          onClick={() =>
                                            handleEdit(
                                              OTHERS,
                                              item.id,
                                              item.type
                                            )
                                          }
                                        >
                                          <i className="fa  fa-pencil"></i>
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-danger btn-xs"
                                          onClick={() =>
                                            handleDelete(
                                              item.id,
                                              item.type,
                                              patientId
                                            )
                                          }
                                        >
                                          <i className="fa  fa-trash"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Diagnoses / procedures*/}
              <div className="row">
                <div className="col-xl-6 col-md-12 col-lg-12 col-sm-12">
                  <div className="card">
                    <div className="card-head ">
                      <header className="my-2">{DIAGNOSES}</header>
                      <header className="pull-right">
                        <input
                          type="text"
                          className="form-control"
                          name="search"
                          placeholder="search"
                          value={search}
                          onChange={Diagnosis_Search_Change}
                          // onChange={(e) => setSearch(e.target.value)}
                        />
                      </header>
                    </div>
                    <div className="card-body no-padding height-9">
                      <div className="performance-list list1">
                        <table className="table table-inbox table-hover">
                          <thead>
                            <tr>
                              <th>{NAME}</th>
                              <th>{CODE}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filterDiagnoses &&
                              filterDiagnoses.map((item, i) => {
                                return (
                                  <tr
                                    className="unread panel-heading-gray"
                                    key={i}
                                  >
                                    <td>
                                      <input
                                        type="checkbox"
                                        className="checkBox mx-2"
                                        id={item.id}
                                        name={item.id}
                                        checked={
                                          checkedList &&
                                          checkedList.findIndex(
                                            (a) => a == item.id
                                          ) >= 0
                                            ? true
                                            : false
                                        }
                                        onChange={checkedchange}
                                        value={`${item.id}`}
                                      />
                                      {item.name}
                                    </td>
                                    <td>{item.code}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="form-group row" style={{ padding: "10px" }}>
                      <label className="control-label col-md-3">
                        &nbsp;&nbsp;&nbsp;&nbsp;ETC Diagnoses
                      </label>
                      <div className="col-md-8">
                        <textarea
                          name="diagnosesEtc"
                          value={addClaim && addClaim.diagnosesEtc}
                          onChange={handleChange}
                          placeholder="Input ETC Diagnoses"
                          className="form-control form-control-textarea"
                          rows="5"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Procedure */}
                <div className="col-xl-6 col-md-12 col-lg-12 col-sm-12">
                  <div className="card">
                    <div className="card-head">
                      <header className="my-2">{PROCEDURE}</header>
                      <header className="pull-right d-flex">
                        <input
                          type="text"
                          className="form-control mx-3"
                          name="procedureSearch"
                          placeholder="search"
                          value={procedureSearch}
                          onChange={(e) => setProcedureSearch(e.target.value)}
                        />
                      </header>
                    </div>
                    {/* 1 */}

                    {procedure &&
                      Object.keys(procedure).map((item, i) => (
                        <div className="card-body" id="line-parent" key={i}>
                          <div
                            className="panel-group accordion"
                            id={"accordion" + i}
                          >
                            <div className="panel panel-default">
                              <div className="panel-heading panel-heading-gray">
                                <div style={{ display: "flex" }}>
                                  <div style={{ width: "97%" }}>
                                    <h4>{procedure[item].type}</h4>
                                  </div>
                                  <div>
                                    <a
                                      className="accordion-toggle accordion-toggle-styled collapsed"
                                      data-bs-toggle="collapse"
                                      data-parent={"accordion" + i}
                                      href={"#accodation_3_" + i}
                                    >
                                      <i
                                        className="fa fa-sort-desc"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div
                                id={"accodation_3_" + i}
                                className="panel-collapse in"
                              >
                                <div
                                  className="panel-body"
                                  style={{ height: "auto", overflowY: "auto" }}
                                >
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>{CPT}</th>
                                        <th>{DESCRIPTION}</th>
                                        {pageAuthorityRoleId === 7 ? (
                                          <> </>
                                        ) : (
                                          <th>{$FEE}</th>
                                        )}
                                        <th>{UNIT}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {procedure &&
                                        procedure[item].data
                                          .filter((items, keys) => {
                                            return (
                                              JSON.stringify(items)
                                                .toLowerCase()
                                                .indexOf(
                                                  procedureSearch.toLowerCase()
                                                ) !== -1
                                            );
                                          })
                                          .map((prod, j) => (
                                            <tr
                                              key={j}
                                              className={`${
                                                prod.priorityList !==
                                                MAX_PRIORITY
                                                  ? "bgPriorityRowColor"
                                                  : ""
                                              }`}
                                            >
                                              <td className="prodCode prodBorder col-1">
                                                {prod.code}
                                              </td>
                                              <td className="prodDesc prodBorder col-7">
                                                {prod.description}
                                              </td>
                                              {pageAuthorityRoleId === 7 ? (
                                                <> </>
                                              ) : (
                                                <td className="prodFee prodBorder col-1">
                                                  {prod.fee}
                                                </td>
                                              )}

                                              <td className="prodAddFee prodBorder col-2">
                                                {/* {prod.value ? prod.value : 0} */}
                                                <input
                                                  type="number"
                                                  id="procedure"
                                                  className="form-control procedure"
                                                  data-fee={prod.fee}
                                                  name={prod.id}
                                                  data-type={
                                                    procedure[item].type
                                                  }
                                                  value={prod && prod.value}
                                                  onChange={(e) => {
                                                    handleProcedureChange(e);
                                                    // setProcedure([
                                                    //     ...procedure.slice(0, i),
                                                    //     {
                                                    //         ...procedure[i],
                                                    //         data: [
                                                    //             ...procedure[i].data.slice(0, j),
                                                    //             { ...procedure[i].data[j], value: e.target.value <= 2 ? e.target.value : 0 },
                                                    //             ...procedure[i].data.slice(j + 1, procedure[i].data.length),
                                                    //         ],
                                                    //     },
                                                    //     ...procedure.slice(i + 1, procedure.length),
                                                    // ]);
                                                  }}
                                                />
                                              </td>
                                            </tr>
                                          ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                <div className="form-actions mt-5">
                  <div className="center">
                    <button
                      type="submit"
                      className="btn btn-primary m-r-20 col-1"
                      onClick={handleFormSubmit}
                    >
                      {NEXT}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* //////////////////////////////////////////////////// Next Button Click To Confirmation //////////////////////////////////////////////////// */}

          {step === "2" && (
            <ConfirmationClaim
              doctorLabel={doctorLabel}
              speciality={speciality}
              addClaim={addClaim}
              checkBoxDiagnoses={diagnoses}
              diagnoses={confirmdiagnoses}
              checkedchange={checkedchange}
              procedure={procedure}
              data={data}
              checkedValues={checkedValues}
              previousStep={updatePreviousStep}
              handleSave={handleSave}
              handleDefaultSubmit={handleDefaultSubmit}
              handleSubmit={handleSubmit}
              handleCancel={handleCancel}
              checkedList={checkedList}
              insuranceF={insuranceF}
              insuranceB={insuranceB}
              imageX={imageX}
              imageN={imageN}
              imageId={imageId}
              imageOt={imageOt}
              saveBtnIsLoading={saveBtnIsLoading}
              defSubmitBtnIsLoading={defSubmitBtnIsLoading}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default AddClaim;
