import React, { useEffect, useState } from "react";
import { ADDRESS, ASSIGN, CITY, OFFICE_NAME, PHONE_NO, STATE, ZIP, STATUS, OFFICE_LIST, ADD, ASSIGN_DOCTOR, HOME, ROLES, OFFICE_STATUS, ROWS_PER_PAGE } from "../../Helper/Constants";
import axios from "axios";
import { baseURL } from "../../Config";
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import { MANAGE_OFFICE_LIST_API } from "../../Helper/ApiPath";
import NoDataFound from "../CommonComponent/NoDataFound";
import Loader from "../CommonComponent/Loader";
import Cookies from "universal-cookie";
import ReactPaginate from "react-paginate";

function DoctorManageOffice() {
    const navigate = useNavigate();
    const officePagination = JSON.parse(sessionStorage.getItem("officePagination"));
    const [isLoading, setIsLoading] = useState(true);
    const [officeList, setOfficeList] = useState([]);

    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(officePagination && officePagination.offset ? officePagination.offset : 1);
    const [rowCount, setRowCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const token = JSON.parse(localStorage.getItem("logToken"));
    const cookies = new Cookies();

    let role = cookies.get("role");
    if (role) {
        role = cookies.get("role").roleId;
    }

    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${MANAGE_OFFICE_LIST_API}`, {
                headers: { authorization: token.token },
                page: offset,
                rowsPerPage: ROWS_PER_PAGE,
                isPagination: true,
            })
            .then((res) => {
                setIsLoading(false);
                setOfficeList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        sessionStorage.removeItem("officePagination");
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleClick = (e) => {
        e.preventDefault();
        navigate("/addOffice");
    };

    const handleAssign = (id, name) => {
        sessionStorage.removeItem("assignDoctorPagination");

        sessionStorage.setItem(
            "officePagination",
            officePagination
                ? JSON.stringify(officePagination)
                : JSON.stringify({
                      offset: offset,
                  })
        );
        navigate("/assignDoctor");
        localStorage.setItem("doctorOfficeId", id);
    };

    useEffect(() => {
        if (role == ROLES.DOCTOR) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset]);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{OFFICE_LIST}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{OFFICE_LIST}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tabbable-line">
                                <div className="tab-content">
                                    <div className="tab-pane active fontawesome-demo" id="tab1">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card">
                                                    <div className="card-body ">
                                                        <div className="row">
                                                            <div className="col-md-6 col-sm-6 col-xs-6">
                                                                <div className="btn-group">
                                                                    <button id="addRow" className="btn btn-info" onClick={handleClick}>
                                                                        {ADD}
                                                                        <i className="fa fa-plus"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-scrollable" style={isLoading ? { border: "none" } : {}}>
                                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{OFFICE_NAME}</th>
                                                                        <th>{PHONE_NO}</th>
                                                                        <th>{ADDRESS}</th>
                                                                        <th>{STATE}</th>
                                                                        <th>{CITY}</th>
                                                                        <th>{ZIP}</th>
                                                                        <th>{STATUS}</th>
                                                                        <th>{ASSIGN}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={isLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                                    {isLoading ? (
                                                                        <>
                                                                            <Loader />
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {!officeList ? (
                                                                                <>{isLoading ? "" : <NoDataFound />}</>
                                                                            ) : (
                                                                                officeList &&
                                                                                officeList.map((item, index) => {
                                                                                    return (
                                                                                        <tr className="odd gradeX" key={index}>
                                                                                            <td className="left">{item.name}</td>
                                                                                            <td className="left">{item.phoneNo1}</td>
                                                                                            <td className="left">{item.address}</td>
                                                                                            <td className="left">{item.state}</td>
                                                                                            <td className="left">{item.city}</td>
                                                                                            <td className="left">{item.zip}</td>
                                                                                            <td className="left">
                                                                                                <span
                                                                                                    className={
                                                                                                        "badge " +
                                                                                                        (item.statusId == 2
                                                                                                            ? "deactiveStatus badge-warning"
                                                                                                            : item.statusId == 1
                                                                                                            ? "activeStatus badge-success"
                                                                                                            : "badge-danger")
                                                                                                    }
                                                                                                >
                                                                                                    {Object.keys(OFFICE_STATUS).find((key) => OFFICE_STATUS[key] === item.statusId)}
                                                                                                </span>
                                                                                            </td>

                                                                                            <td>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-circle btn-info"
                                                                                                    onClick={() => handleAssign(item.id, item.name)}
                                                                                                >
                                                                                                    {ASSIGN_DOCTOR}
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mx-4">
                                                        <div>Total {officeList == null ? "0" : rowCount} Office</div>
                                                        <div className="pagination-center">
                                                            <ReactPaginate
                                                                initialPage={0}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={0}
                                                                pageRangeDisplayed={3}
                                                                onPageChange={handlePageClick}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                pageLinkClassName="page-link"
                                                                breakLinkClassName="page-link"
                                                                nextLinkClassName="page-link"
                                                                previousLinkClassName="page-link"
                                                                pageClassName="page-item"
                                                                breakClassName="page-item"
                                                                nextClassName="page-item"
                                                                previousClassName="page-item"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"
                                                                disableInitialCallback={true}
                                                                forcePage={officePagination && officePagination.offset ? officePagination.offset - 1 : currentPage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DoctorManageOffice;
