import { useState } from "react";
import {
    CANCEL,
    HOME,
    PAYER,
    SELECT_PAYER,
    EFT_CHECK,
    PAID_AMOUNT_USD,
    REMIT_DATE,
    OFFICE_NAME,
    SELECT_OFFICE,
    ADD_EOB,
    EOB_LIST,
    EOB_INFORMATION,
    NO,
    FILE_NAME,
    THUMBNAIL,
    ACTION,
    EOB_FILE,
    SAVE_NEXT,
    FINISH,
} from "../../../Helper/Constants";
import axios from "axios";
import { baseURL } from "../../../Config";
import { toast } from "react-toastify";
import { EOB_ADD_API, EOB_OFFICE_SELECT_LIST_API, EOB_DOCUMENT_DELETE_API, EOB_DOCUMENT_LIST_API, PAYER_LIST_WITHOUT_MIDDLEWARE_API } from "../../../Helper/ApiPath";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { ENTER_PAYER, CHOOSE_OFFICE, DELETE_EOB_DOCUMENT_MESSAGE } from "../../../Helper/Messages";
import { dateAPIFormate } from "../../../Helper/Helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Zoom from "react-medium-image-zoom";
import NoDataFound from "../../CommonComponent/NoDataFound";
import EOBFileUpload from "./EOBFileUpload";
import Swal from "sweetalert2";
import EOBWebcamUpload from "./EOBWebcamUpload";

function AddEob() {
    const [payer, setPayer] = useState([]);
    const token = JSON.parse(localStorage.getItem("logToken"));
    const navigate = useNavigate();

    const [EOBId, setEOBId] = useState("");
    const [officeSelect, setOfficeSelect] = useState([]);
    const [EOBFile, setEOBFile] = useState([]);
    const [officeName, setOfficeName] = useState("");
    const [payerName, setPayerName] = useState("");
    const [edit, setEdit] = useState(false);
    const [show, setShow] = useState(false);
    const [editId, setEditId] = useState("");
    const [EOB, setEOB] = useState({
        payerId: "4b2ea166e5d146d5bc587d7e766166f80a2d1d7430f04edf97b34666e8719343",
        officeId: "",
        officeLabel: "",
        EFTCheck: "",
        remitDate: "",
        paidAmount: "",
    });

    const fetchOfficeList = async (e) => {
        await axios
            .post(`${baseURL}/${EOB_OFFICE_SELECT_LIST_API}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                setOfficeSelect(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const officeSelectOptions =
        officeSelect &&
        officeSelect.map((item, i) => {
            return {
                value: item.uniqueId,
                label: item.name,
            };
        });

    const payerList = () => {
        axios
            .get(`${baseURL}/${PAYER_LIST_WITHOUT_MIDDLEWARE_API}`)
            .then((res) => {
                setPayer(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const fetchThumbnailData = async () => {
        await axios
            .post(`${baseURL}/${EOB_DOCUMENT_LIST_API}`, {
                headers: { authorization: token.token },
                EOBId,
            })
            .then((res) => {
                setEOBFile(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const handleEdit = (id) => {
        setEdit(true);
        setShow(true);
        setEditId(id);
    };
    const closeDocumentEdit = () => {
        setShow(false);
        setEdit(false);
    };
    const handleDelete = async (id) => {
        Swal.fire({
            title: DELETE_EOB_DOCUMENT_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/${EOB_DOCUMENT_DELETE_API}/${id}`, {
                            headers: { authorization: token.token },
                        })
                        .then((res) => {
                            Swal.fire(res.data.message, " ", "success");
                            fetchThumbnailData();
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };
    const payerOptions =
        payer &&
        payer.map((item, i) => {
            return {
                id: item.id,
                value: item.value,
                label: item.name,
            };
        });
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!EOB.payerId) {
            toast.error(ENTER_PAYER);
        } else if (!EOB.officeId) {
            toast.error(CHOOSE_OFFICE);
        } else {
            await axios
                .post(`${baseURL}/${EOB_ADD_API}`, {
                    headers: { authorization: token.token },
                    payerId: EOB.payerId,
                    officeId: EOB.officeId,
                    EFTCheck: EOB.EFTCheck,
                    remitDate: dateAPIFormate(EOB.remitDate),
                    paidAmount: EOB.paidAmount,
                })
                .then((res) => {
                    toast.success(res.data.message);
                    setEOBId(res.data.data.id);
                    localStorage.setItem("EOBId", res.data.data.id);
                    document.getElementById("EOB-image").classList.remove("d-none");
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setEOB({ ...EOB, [name]: value });
    };
    const handlePayerChange = (e) => {
        let value = e.id;
        setPayerName(value);
        setEOB({ ...EOB, payerId: value });
    };
    const handleOfficeChange = async (e) => {
        setOfficeName(e.value);
        setEOB({ ...EOB, officeId: e.value, officeLabel: e.label });
    };
    const handleCancel = (e) => {
        navigate("/manageEOB");
    };

    useEffect(() => {
        fetchOfficeList();
        payerList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{ADD_EOB}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li>
                                    <i className="fa fa-th-list" aria-hidden="true"></i>&nbsp;
                                    <NavLink className="parent-item" to="/manageEOB">
                                        {EOB_LIST}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{EOB_LIST}</li>
                            </ol>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="card card-box">
                                <div className="card-head">
                                    <header>{EOB_INFORMATION}</header>
                                </div>

                                <div>
                                    <div className="row">
                                        <div id="form_sample_1" className="form-horizontal">
                                            <div className="card-body" id="bar-parent">
                                                <div action="#" id="form_sample_1" className="form-horizontal">
                                                    <div className="form-body">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {OFFICE_NAME}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <Select
                                                                    name="officeSelect"
                                                                    value={officeSelectOptions && officeSelectOptions.filter(({ value }) => value == officeName)}
                                                                    options={officeSelectOptions}
                                                                    onChange={handleOfficeChange}
                                                                    placeholder={SELECT_OFFICE}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {PAYER}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <Select
                                                                    name="payer"
                                                                    defaultValue={{ label: "CA Medicare South" }}
                                                                    options={payerOptions}
                                                                    onChange={handlePayerChange}
                                                                    placeholder={SELECT_PAYER}
                                                                ></Select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">{EFT_CHECK}</label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    type="text"
                                                                    name="EFTCheck"
                                                                    value={EOB && EOB.EFTCheck}
                                                                    onChange={handleChange}
                                                                    maxLength={50}
                                                                    data-required="1"
                                                                    placeholder="Enter EFT/Check #"
                                                                    className="form-control input-height"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">{REMIT_DATE}</label>
                                                            <div className="form-group col-5">
                                                                <DatePicker
                                                                    className="form-control input-height mr-sm-2"
                                                                    selected={EOB.remitDate}
                                                                    maxDate={new Date().setMonth(new Date().getMonth() + 1)}
                                                                    scrollableMonthDropdown={true}
                                                                    showYearDropdown
                                                                    showMonthDropdown
                                                                    placeholderText={"Please Select Date"}
                                                                    onChange={(date) => setEOB({ ...EOB, remitDate: date })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">{PAID_AMOUNT_USD}</label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    type="number"
                                                                    name="paidAmount"
                                                                    value={EOB && EOB.paidAmount}
                                                                    onChange={handleChange}
                                                                    data-required="1"
                                                                    placeholder="Enter Paid Amount"
                                                                    className="form-control input-height"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-actions">
                                                            <div className="row">
                                                                <div className="offset-md-3 col-md-9">
                                                                    <button className="btn btn-info m-r-20" onClick={handleSubmit}>
                                                                        {SAVE_NEXT}
                                                                    </button>
                                                                    <button type="reset" className="btn btn-default  m-r-20" onClick={handleCancel}>
                                                                        {CANCEL}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-none" id="EOB-image">
                                    <div className="col-xl-3 col-lg-3"></div>
                                    <div className="col-xl-6 col-lg-6 col-md-11 col-sm-11 mx-md-2 mx-sm-2">
                                        <div className="d-flex justify-content-between my-2 mx-2">
                                            <div>
                                                <p style={{ fontSize: "Larger", fontWeight: "600" }}>{EOB_FILE}</p>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <div>
                                                    <EOBFileUpload
                                                        fetchThumbnailData={fetchThumbnailData}
                                                        closeDocumentEdit={closeDocumentEdit}
                                                        edit={edit}
                                                        editId={editId}
                                                        show={show}
                                                        localEOBId={EOBId}
                                                    />
                                                </div>
                                                <div style={{ marginTop: "10px" }}>
                                                    <EOBWebcamUpload fetchThumbnailData={fetchThumbnailData} localEOBId={EOBId} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-scrollable">
                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                <thead>
                                                    <tr>
                                                        <th>{NO}</th>
                                                        <th>{FILE_NAME}</th>
                                                        <th>{THUMBNAIL}</th>
                                                        <th>{ACTION}</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {!EOBFile.length || !EOBFile ? (
                                                        <>{<NoDataFound />}</>
                                                    ) : (
                                                        EOBFile &&
                                                        EOBFile.map((item, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{(1 - 1) * 100 + i + 1}</td>
                                                                    <td>{item.fileName ? item.fileName : EOB_FILE + ".png"}</td>

                                                                    <td>
                                                                        {item.document && item.document.split(".").at(-1).toLowerCase() == "pdf" ? (
                                                                            <div type="button" className="btn btn-outline-danger" onClick={() => window.open(item.document, "_blank")}>
                                                                                <i className="fa  fa-file-pdf-o fa-2x my-2"></i>
                                                                            </div>
                                                                        ) : (
                                                                            <Zoom>
                                                                                <img src={item.document} width="100" height="100" alt="EOB Document" style={{ height: "50px", width: "50px" }} />
                                                                            </Zoom>
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        <button type="button" className="btn btn-primary btn-xs" onClick={() => handleEdit(item.id)}>
                                                                            <i className="fa  fa-pencil"></i>
                                                                        </button>
                                                                        <button type="button" className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
                                                                            <i className="fa  fa-trash"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="form-actions">
                                            <div className="row">
                                                <div className="offset-md-5 col-md-9">
                                                    <button className="btn btn-success m-r-20" onClick={handleCancel}>
                                                        {FINISH}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddEob;
