import { useState } from "react";
import {
    ADD,
    BIRTH_DATE,
    CANCEL,
    CHOOSE_DOCTOR_TYPE,
    DOCTOR_SPECIALTY,
    FEE,
    FEMALE,
    FIRST_NAME,
    GENDER,
    HOME,
    LAST_NAME,
    MALE,
    NPI1,
    NPI2,
    PHONE_LENGTH,
    PHONE_NO,
    SELECT_GENDER,
    SELECT_SPECIALTY,
    SSN,
    TAXID,
    PAYER,
    SELECT_PAYER,
    ADD_TRIAL,
    TRIAL_LIST,
    TRIAL_INFORMATION,
    EMAIL,
    PASSWORD,
    CONFIRM_PASSWORD,
    DOCTOR_STATUS,
    LICENSE_NUMBER,
    PTAN_NUMBER,
} from "../../../Helper/Constants";
import axios from "axios";
import { baseURL } from "../../../Config";
import { toast } from "react-toastify";
import { ADMIN_DOCTOR_REGISTER, PAYER_LIST_WITHOUT_MIDDLEWARE_API, SPECIALTY_LIST_WITHOUT_PAGINATION } from "../../../Helper/ApiPath";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
    AGE_MORE_THAN_18,
    ENTER_FEE_LESS_THAN_10,
    ENTER_FNAME,
    ENTER_LNAME,
    ENTER_NPI_LESS_THAN_10,
    ENTER_PTAN_LESS_THAN_10,
    ENTER_SPECIALTY,
    ENTER_SSN_LESS_THAN_10,
    ENTER_TAX_ID_LESS_THAN_10,
    MAX_LENGTH,
    PASSWORD_ERROR_TEXT,
    PHONE_NUMBER,
} from "../../../Helper/Messages";
import { dateAPIFormate, minYear } from "../../../Helper/Helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

function AddTrial() {
    const [isLoading, setIsLoading] = useState(false);
    const [payer, setPayer] = useState([]);
    const token = JSON.parse(localStorage.getItem("logToken")).token;
    const navigate = useNavigate();
    const [emailErr, setEmailErr] = useState(false);
    const [pwdError1, setPwdError1] = useState(false);
    const [pwdError2, setPwdError2] = useState(false);

    const [Trial, setTrial] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        middleName: "",
        lastName: "",
        phoneNo1: "",
        dob: "",
        npi1: "",
        npi2: "",
        ssn: "",
        taxId: "",
        licenseNumber: "",
        fee: "",
        gender: "",
        specialty: "",
        ptanNumber: "",
        payer: "4b2ea166e5d146d5bc587d7e766166f80a2d1d7430f04edf97b34666e8719343",
    });
    const [passwordShown, setPasswordShown] = useState(false);
    const [conPasswordShown, setConPasswordShown] = useState(false);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const toggleConPassword = () => {
        setConPasswordShown(!conPasswordShown);
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "ssn" || name === "taxId" || name === "npi1" || name === "npi2") {
            const onlyNumber = e.target.value.replace(/[^0-9,-]/g, "");
            setTrial({ ...Trial, [name]: onlyNumber });
        } else {
            setTrial({ ...Trial, [name]: value });
        }
    };
    const handlePayerChange = (e) => {
        let label = e.label;
        let value = e.id;
        setTrial({ ...Trial, payerLabel: label, payer: value });
    };
    const payerList = () => {
        axios
            .get(`${baseURL}/${PAYER_LIST_WITHOUT_MIDDLEWARE_API}`)
            .then((res) => {
                setPayer(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const payerOptions =
        payer &&
        payer.map((item, i) => {
            return {
                id: item.id,
                value: item.value,
                label: item.name,
            };
        });
        
    const [specialtyList, setSpecialtyList] = useState([]);

    const specialtyData = async () => {
        await axios
            .post(`${baseURL}/${SPECIALTY_LIST_WITHOUT_PAGINATION}`)
            .then((res) => {
                setSpecialtyList(res.data.data);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };
    
    const specialtyOptions =
        specialtyList &&
        specialtyList.map((item, i) => {
            return {
                id: item.id,
                value: item.id,
                label: item.name,
            };
        });

    const handleSpecialtyChange = (e) => {
        setTrial({ ...Trial, specialty: e.value });
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const validEmail = new RegExp("^[a-z0-9._:$!%-]+@[a-z0-9.-]+.[a-z]$");
        const validPassword = new RegExp("^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");

        const dob = Trial.dob;
        var today = new Date();
        var birthDate = new Date(dob);
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--;
        }
        if (!validEmail.test(Trial.email)) {
            setEmailErr(true);
        } else if (!validPassword.test(Trial.password)) {
            setPwdError1(true);
        } else if (!validPassword.test(Trial.confirmPassword)) {
            setPwdError2(true);
        } else if (!Trial.firstName) {
            toast.error(ENTER_FNAME);
        } else if (!Trial.lastName) {
            toast.error(ENTER_LNAME);
        } else if (Trial.npi1 && Trial.npi1.length > MAX_LENGTH) {
            toast.error(ENTER_NPI_LESS_THAN_10);
        } else if (Trial.npi2 && Trial.npi2.length > MAX_LENGTH) {
            toast.error(ENTER_NPI_LESS_THAN_10);
        } else if (Trial.ssn && Trial.ssn.length > MAX_LENGTH) {
            toast.error(ENTER_SSN_LESS_THAN_10);
        } else if (Trial.taxId && Trial.taxId.length > MAX_LENGTH) {
            toast.error(ENTER_TAX_ID_LESS_THAN_10);
        } else if (Trial.ptanNumber && Trial.ptanNumber.length > MAX_LENGTH) {
            toast.error(ENTER_PTAN_LESS_THAN_10);
        } else if (Trial.fee && Trial.fee.length > MAX_LENGTH) {
            toast.error(ENTER_FEE_LESS_THAN_10);
        } else if (!Trial.specialty) {
            toast.error(ENTER_SPECIALTY);
        } else if (Trial.dob && age_now < 18) {
            toast.error(AGE_MORE_THAN_18);
        } else if (Trial.phoneNo1 && Trial.phoneNo1.length > PHONE_LENGTH) {
            toast.error(PHONE_NUMBER);
        } else {
            setIsLoading(true);
            await axios
                .post(`${baseURL}/${ADMIN_DOCTOR_REGISTER}`, {
                    headers: { authorization: token },
                    email: Trial.email,
                    password: Trial.password,
                    firstName: Trial.firstName,
                    middleName: Trial.middleName,
                    lastName: Trial.lastName,
                    phoneNo1: Trial.phoneNo1,
                    dob: dateAPIFormate(Trial.dob),
                    profilePic: "",
                    npi1: Trial.npi1,
                    npi2: Trial.npi2,
                    ssn: Trial.ssn,
                    taxId: Trial.taxId,
                    licenseNumber: Trial.licenseNumber,
                    fee: Trial.fee,
                    gender: Trial.gender,
                    speciality: Trial.specialty,
                    ptanNumber: Trial.ptanNumber,
                    payerId: Trial.payer,
                    isTrial: true,
                    statusId: DOCTOR_STATUS.Active,
                })
                .then((res) => {
                    setIsLoading(false);
                    if (res.status === 200) {
                        setTrial("");
                        setEmailErr(false);
                        toast.success(res.data.message);
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const handleCancel = (e) => {
        // e.prevetnDefault();
        navigate("/manageTrial");
    };

    useEffect(() => {
        payerList();
        specialtyData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{ADD_TRIAL}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li>
                                    <i className="fa fa-user-secret"></i>&nbsp;
                                    <NavLink className="parent-item" to="/manageTrial">
                                        {TRIAL_LIST}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{TRIAL_LIST}</li>
                            </ol>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="card card-box">
                                <div className="card-head">
                                    <header>{TRIAL_INFORMATION}</header>
                                </div>

                                <div>
                                    <div className="row">
                                        <div id="form_sample_1" className="form-horizontal">
                                            <div className="card-body" id="bar-parent">
                                                <div action="#" id="form_sample_1" className="form-horizontal">
                                                    <div className="form-body">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {EMAIL}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    type="email"
                                                                    name="email"
                                                                    value={Trial && Trial.email}
                                                                    onChange={handleChange}
                                                                    data-required="1"
                                                                    placeholder="Enter email"
                                                                    className="form-control input-height"
                                                                />
                                                            </div>
                                                            {emailErr && <p className="txtError">Your Email is like example@gmail.com</p>}
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {PASSWORD}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <div style={{ display: "flex" }}>
                                                                    <input
                                                                        type={passwordShown ? "text" : "password"}
                                                                        name="password"
                                                                        value={Trial && Trial.password}
                                                                        onChange={handleChange}
                                                                        data-required="1"
                                                                        placeholder="Enter password"
                                                                        className="form-control input-height"
                                                                    />
                                                                    <i
                                                                        className={passwordShown ? "input-group-text input-height fa fa-eye" : "input-group-text input-height fa fa-eye-slash"}
                                                                        id="basic-addon1"
                                                                        onClick={togglePassword}
                                                                    ></i>
                                                                </div>
                                                                <p>{PASSWORD_ERROR_TEXT}</p>
                                                                {pwdError1 && <p className="txtError">{PASSWORD_ERROR_TEXT}</p>}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {CONFIRM_PASSWORD}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <div style={{ display: "flex" }}>
                                                                    <input
                                                                        type={conPasswordShown ? "text" : "password"}
                                                                        name="confirmPassword"
                                                                        value={Trial && Trial.confirmPassword}
                                                                        onChange={handleChange}
                                                                        data-required="1"
                                                                        placeholder="Confirm password"
                                                                        className="form-control input-height"
                                                                    />
                                                                    <i
                                                                        className={conPasswordShown ? "input-group-text input-height fa fa-eye" : "input-group-text input-height fa fa-eye-slash"}
                                                                        id="basic-addon1"
                                                                        onClick={toggleConPassword}
                                                                    ></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {FIRST_NAME}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    type="text"
                                                                    name="firstName"
                                                                    value={Trial && Trial.firstName}
                                                                    onChange={handleChange}
                                                                    data-required="1"
                                                                    placeholder="Enter first name"
                                                                    className="form-control input-height"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {LAST_NAME}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    type="text"
                                                                    name="lastName"
                                                                    value={Trial && Trial.lastName}
                                                                    onChange={handleChange}
                                                                    data-required="1"
                                                                    placeholder="Enter last name"
                                                                    className="form-control input-height"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">{GENDER}</label>
                                                            <div className="col-md-5">
                                                                <select className="form-control input-height" name="gender" value={Trial && Trial.gender} onChange={handleChange}>
                                                                    <option value=""> {SELECT_GENDER}</option>
                                                                    <option value="male">{MALE}</option>
                                                                    <option value="female">{FEMALE}</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">{BIRTH_DATE}</label>
                                                            <div className="form-group col-5">
                                                                <DatePicker
                                                                    className="form-control input-height mr-sm-2"
                                                                    selected={Trial.dob}
                                                                    maxDate={new Date(minYear)}
                                                                    scrollableMonthDropdown={true}
                                                                    showYearDropdown
                                                                    showMonthDropdown
                                                                    placeholderText={"Please Select Date"}
                                                                    onChange={(date) => setTrial({ ...Trial, dob: date })}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">{PHONE_NO}</label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    name="phoneNo1"
                                                                    type="number"
                                                                    value={Trial && Trial.phoneNo1}
                                                                    minLength={10}
                                                                    maxLength={10}
                                                                    onChange={handleChange}
                                                                    placeholder="mobile number"
                                                                    className="form-control input-height"
                                                                />{" "}
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">{NPI1}</label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    name="npi1"
                                                                    type="text"
                                                                    value={Trial && Trial.npi1}
                                                                    onChange={handleChange}
                                                                    placeholder="Enter NPI 1"
                                                                    className="form-control input-height"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">{NPI2}</label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    name="npi2"
                                                                    type="text"
                                                                    value={Trial && Trial.npi2}
                                                                    onChange={handleChange}
                                                                    placeholder="Enter NPI 2"
                                                                    className="form-control input-height"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">{SSN}</label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    name="ssn"
                                                                    type="text"
                                                                    value={Trial && Trial.ssn}
                                                                    onChange={handleChange}
                                                                    placeholder="Enter SSN"
                                                                    className="form-control input-height"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">{TAXID}</label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    name="taxId"
                                                                    type="text"
                                                                    value={Trial && Trial.taxId}
                                                                    onChange={handleChange}
                                                                    placeholder="Enter TaxId"
                                                                    className="form-control input-height"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">{LICENSE_NUMBER}</label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    name="licenseNumber"
                                                                    type="text"
                                                                    value={Trial && Trial.licenseNumber}
                                                                    onChange={handleChange}
                                                                    placeholder="Enter License Number"
                                                                    className="form-control input-height"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">{FEE}</label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    name="fee"
                                                                    type="number"
                                                                    value={Trial && Trial.fee}
                                                                    onChange={handleChange}
                                                                    placeholder="Enter Fee"
                                                                    className="form-control input-height"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {DOCTOR_SPECIALTY}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <Select
                                                                    menuPlacement="top"
                                                                    name="specialty"
                                                                    value={specialtyOptions && specialtyOptions.filter(({ value }) => value == (Trial && Trial.specialty))}
                                                                    options={specialtyOptions}
                                                                    onChange={handleSpecialtyChange}
                                                                    placeholder={SELECT_SPECIALTY}
                                                                />
                                                                {/* <select className="form-control input-height" name="speciality" value={Trial && Trial.speciality} onChange={handleChange}>
                                                                    <option value=""> {SELECT_SPECIALTY}</option>
                                                                    {Object.keys(CHOOSE_DOCTOR_TYPE).map((item, i) => {
                                                                        return (
                                                                            <option key={i} value={CHOOSE_DOCTOR_TYPE[item]}>
                                                                                {item}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select> */}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">{PTAN_NUMBER}</label>
                                                            <div className="col-md-5">
                                                                <input
                                                                    name="ptanNumber"
                                                                    type="text"
                                                                    value={Trial && Trial.ptanNumber}
                                                                    onChange={handleChange}
                                                                    placeholder="Enter PTAN Number"
                                                                    className="form-control input-height"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-3">
                                                                {PAYER}
                                                                <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-5">
                                                                <Select
                                                                    menuPlacement="top"
                                                                    className="text-dark"
                                                                    name="payer"
                                                                    defaultValue={{ label: "CA Medicare South" }}
                                                                    options={payerOptions}
                                                                    onChange={handlePayerChange}
                                                                    placeholder={SELECT_PAYER}
                                                                ></Select>
                                                            </div>
                                                        </div>
                                                        <div className="form-actions">
                                                            <div className="row">
                                                                <div className="offset-md-3 col-md-9">
                                                                    {isLoading ? (
                                                                        <>
                                                                            <button className="btn btn-info m-r-20" disabled>
                                                                                {ADD}
                                                                            </button>
                                                                            <i className="fa fa-spinner fa-spin fa-2x loaderBtnSpinner"></i>
                                                                        </>
                                                                    ) : (
                                                                        <button className="btn btn-info m-r-20" onClick={handleSubmit}>
                                                                            {ADD}
                                                                        </button>
                                                                    )}
                                                                    <button type="reset" className="btn btn-default  m-r-20" onClick={handleCancel}>
                                                                        {CANCEL}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddTrial;
