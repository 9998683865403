import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ADD, ADD_GROUP, CANCEL, NAME, PRIORITY, STATUS, GROUP_STATUS, UPDATE, UPDATE_GROUP } from "../../../Helper/Constants";

function Add_EditGroup(props) {
    return (
        <>
            <Modal show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.edit ? UPDATE_GROUP : ADD_GROUP}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <label className="control-label col-md-12">
                            {NAME}
                            <span className="required"> * </span>
                        </label>
                        <div className="col-md-12">
                            <input type="text" value={props.group.name} name="name" onChange={props.handlechange} placeholder="Enter Name" className="form-control input-height" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="control-label col-md-12">{PRIORITY}</label>
                        <div className="col-md-12">
                            <input type="text" value={props.group.priorityList} name="priorityList" onChange={props.handlechange} placeholder="9999" className="form-control input-height" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-12">
                            <label className="mt-2 col-md-6">
                                {STATUS}
                                <span className="required"> * </span>
                            </label>
                            <select className="form-control col-md-3" name="statusId" value={props.group.statusId} onChange={props.handlechange}>
                                {Object.keys(GROUP_STATUS).map((item, i) => {
                                    return (
                                        <option key={i} value={GROUP_STATUS[item]}>
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.addGroupdata}>
                        {props.edit ? UPDATE : ADD}
                    </Button>
                    <Button variant="secondary" onClick={props.handleClose}>
                        {CANCEL}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Add_EditGroup;
