import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { CANCEL, CHECK, MB, MB_HMO, MB_PPO, NO_MB, QMB, QMB_HMO, QMB_PPO, RAILROAD, RAILROAD_HMO, RAILROAD_PPO } from "../../Helper/Constants";

function EligibilityImage(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <>
                <span variant="default" onClick={handleShow}>
                    {CHECK}
                </span>

                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {
                                /////////////////////////// MEDICARE PART B //////////////////////////////////
                                props.check == 1 && props.name == 1
                                    ? MB
                                    : props.check == 2 && props.name == 1
                                    ? MB_HMO
                                    : props.check == 3 && props.name == 1
                                    ? MB_PPO
                                    : /////////////////////////// QMB //////////////////////////////////

                                    props.check == 1 && props.name == 2
                                    ? QMB
                                    : props.check == 2 && props.name == 2
                                    ? QMB_HMO
                                    : props.check == 3 && props.name == 2
                                    ? QMB_PPO
                                    : /////////////////////////// RAILROAD //////////////////////////////////

                                    props.check == 1 && props.name == 3
                                    ? RAILROAD
                                    : props.check == 2 && props.name == 3
                                    ? RAILROAD_HMO
                                    : props.check == 3 && props.name == 3
                                    ? RAILROAD_PPO
                                    : NO_MB
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            /////////////////////////// MEDICARE PART B //////////////////////////////////
                            props.check == 1 && props.name == 1 ? (
                                <img src="assets/img/eligibilitySampleImage/MB_ONLY.png" alt={MB} />
                            ) : props.check == 2 && props.name == 1 ? (
                                <img src="assets/img/eligibilitySampleImage/MB_HMO.png" alt={MB_HMO} />
                            ) : props.check == 3 && props.name == 1 ? (
                                <img src="assets/img/eligibilitySampleImage/MB_PPO.png" alt={MB_PPO} />
                            ) : /////////////////////////// QMB //////////////////////////////////

                            props.check == 1 && props.name == 2 ? (
                                <img src="assets/img/eligibilitySampleImage/QMB_ONLY.png" alt={QMB} />
                            ) : props.check == 2 && props.name == 2 ? (
                                <img src="assets/img/eligibilitySampleImage/QMB_HMO.png" alt={QMB_HMO} />
                            ) : props.check == 3 && props.name == 2 ? (
                                <img src="assets/img/eligibilitySampleImage/QMB_PPO.png" alt={QMB_PPO} />
                            ) : /////////////////////////// RAILROAD //////////////////////////////////

                            props.check == 1 && props.name == 3 ? (
                                <img src="assets/img/eligibilitySampleImage/RAILROAD_ONLY.png" alt={RAILROAD} />
                            ) : props.check == 2 && props.name == 3 ? (
                                <img src="assets/img/eligibilitySampleImage/RAILROAD_HMO.png" alt={RAILROAD_HMO} />
                            ) : props.check == 3 && props.name == 3 ? (
                                <img src="assets/img/eligibilitySampleImage/RAILROAD_PPO.png" alt={RAILROAD_PPO} />
                            ) : (
                                <img src="assets/img/eligibilitySampleImage/NO_MB.png" alt={NO_MB} />
                            )
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" onClick={handleClose}>
                            {CANCEL}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        </>
    );
}

export default EligibilityImage;
