import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../Config";
import { DOCTOR_DASHBOARD_API, DOCTOR_NOTICE_API, WEEKLY_PRE_AUTHORIZATION_DOCTOR_LIST_API, DETAIL_PRE_AUTHORIZATION_STATUS_API } from "../../Helper/ApiPath";
import {
    ATTACHMENT,
    CONTACTS,
    CONTENTS,
    DASH,
    DOCTOR_DASHBOARD,
    ENROLLMENT,
    NO,
    NOTICE,
    SUPPORT,
    TITLE,
    TOTAL_CLAIM,
    TOTAL_DOCTOR,
    NEW_PRE_AUTHORIZATION_REQUEST,
    DOCTOR,
    PATIENT,
    DATE_OF_SERVICE,
    CREATED_DATE,
    CREATE_BY,
    STATUS,
    PRE_AUTH_STATUS,
    TOTAL_PATIENT,
    DOCTOR_PAGES,
    ROLES,
} from "../../Helper/Constants";
import NoDataFound from "../CommonComponent/NoDataFound";
import parse from "html-react-parser";
import NoticeDetail from "../Forms/Notice/NoticeDetail";
import Cookies from "universal-cookie";
import $ from "jquery";
import { dateFormateMonthDateYear } from "../../Helper/Helper";
import UpdateStatus from "../Forms/PreAuthorization/UpdateStatus";

export default function DashboardDoctor() {
    const [isLoading, setIsLoading] = useState(true);

    const token = JSON.parse(localStorage.getItem("logToken"));
    const officeId = localStorage.getItem("officeId");
    const cookies = new Cookies();

    let isTrial = cookies.get("isTrial");
    if (isTrial) {
        isTrial = cookies.get("isTrial").isTrial;
    }

    let pageAccess = cookies.get("doctorPageAccess") ? (cookies.get("doctorPageAccess").length ? cookies.get("doctorPageAccess") : [14, 15, 16, 17, 18, 19, 20]) : "";

    const [dashoard, setDashboard] = useState([]);

    const [notificationList, setNotificationList] = useState([]);
    const [offset, setOffset] = useState(1);
    const [rowCount, setRowCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [popUpList, setPopUpList] = useState([]);
    let checkedPopUP = cookies.get("checkedPopUP") ? cookies.get("checkedPopUP") : [];
    const rowsPerPage = 5;
    let navigate = useNavigate();
    let adminRole = localStorage.getItem("adminRole");
    let role = cookies.get("role");
    if (role) {
        role = cookies.get("role").roleId;
    }

    //Notice popup image zoom.
    $(document).on("click", ".modal-content img", function () {
        var url = $(this).attr("src");
        window.open(url, "Image", "resizable=1");
    });

    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${DOCTOR_DASHBOARD_API}`, {
                headers: { authorization: token.token },
                officeId: officeId,
            })
            .then((res) => {
                setIsLoading(false);
                setDashboard(res.data.data);
            })
            .catch((err) => {
                setIsLoading(false);

                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
    };

    const fetchNotificationData = async () => {
        await axios
            .post(`${baseURL}/${DOCTOR_NOTICE_API}`, {
                headers: { authorization: token.token },
                page: offset,
                rowsPerPage: rowsPerPage,
            })
            .then((res) => {
                setIsLoading(false);
                setNotificationList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const [preAuthorizationId, setPreAuthorizationId] = useState("");
    const [preAuthorization, setPreAuthorization] = useState({
        statusId: "",
        comment: "",
        auth: "",
    });
    const [showStatus, setShowStatus] = useState(false);

    const [weeklyPreAuthorizationList, setWeeklyPreAuthorizationList] = useState([]);
    const [PreAuthorizationOffset, setPreAuthorizationOffset] = useState(1);
    const [PreAuthorizationRowCount, setPreAuthorizationRowCount] = useState(0);
    const [PreAuthorizationPageCount, setPreAuthorizationPageCount] = useState(0);

    const handlePreAuthorizationPageClick = (event) => {
        const selectedPage = event.selected;
        setPreAuthorizationOffset(selectedPage + 1);
    };

    const handlePreAuthorizationNote = (id) => {
        sessionStorage.setItem("preAuthDoctorId", id);
    };

    const fetchWeeklyPreAuthorizationNoteDataDoctor = async () => {
        await axios
            .post(`${baseURL}/${WEEKLY_PRE_AUTHORIZATION_DOCTOR_LIST_API}`, {
                headers: { authorization: token.token },
                page: PreAuthorizationOffset,
                rowsPerPage: rowsPerPage,
                officeId: officeId,
            })
            .then((res) => {
                setWeeklyPreAuthorizationList(res.data.data);
                setPreAuthorizationPageCount(res.data.extraData.pageCount);
                setPreAuthorizationRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const [changedFields, setChangedFields] = useState([]);

    const handleStatus = (id, changedFields) => {
        setChangedFields(changedFields);
        setPreAuthorization("");
        setPreAuthorizationId(id);
        statusDetail(id);
        setShowStatus(true);
    };

    const handleCloseStatus = () => {
        setShowStatus(false);
        fetchWeeklyPreAuthorizationNoteDataDoctor();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPreAuthorization({ ...preAuthorization, [name]: value });
    };

    const statusDetail = async (preAuthorizationId) => {
        await axios
            .get(`${baseURL}/${DETAIL_PRE_AUTHORIZATION_STATUS_API}/${preAuthorizationId}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                setPreAuthorization(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const [show, setShow] = useState(false);
    const [check, setCheck] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [ispopupid, setIspopupid] = useState(false);
    let popUpId = JSON.parse(localStorage.getItem("popUpId") || "[]");
    const handleShow = async (id) => {
        localStorage.setItem("noticeId", id);
        setShowDetail(true);
        setCheck(true);
        setShow(true);
    };
    const handleClose = () => {
        setShow(false);
    };

    useEffect(async () => {
        fetchData();
        fetchNotificationData();
        fetchWeeklyPreAuthorizationNoteDataDoctor();

        if (popUpId) {
            let popUpList = popUpId.map((item, i) => {
                return item.noticeId;
            });
            if (popUpList) {
                setIspopupid(true);
                setPopUpList(popUpList);
            }
        }
    }, [offset, PreAuthorizationOffset]);
    const totalClaimList = () => {
        if (pageAccess.includes(DOCTOR_PAGES.Claim)) {
            sessionStorage.removeItem("claimDoctorId");
            navigate("/manageClaim");
        }
    };
    const totalPatientList = () => {
        if (pageAccess.includes(DOCTOR_PAGES.Patient)) {
            navigate("/managePatient");
        }
    };
    const totalOfficeEmployeeList = () => {
        if (isTrial != 2) {
            navigate("/manageOfficeEmployee");
        }
    };
    return (
        <>
            {isLoading ? (
                <i
                    className="fa fa-spinner fa-spin fa-2x"
                    style={{
                        position: "absolute",
                        top: "30rem",
                        left: "70rem",
                        fontSize: "55px",
                    }}
                ></i>
            ) : (
                <div className="page-content-wrapper">
                    <div className="page-content" style={{ minHeight: "600px" }}>
                        <div className="page-bar">
                            <div className="page-title-breadcrumb">
                                <div className=" pull-left">
                                    <div className="page-title">{DOCTOR_DASHBOARD}</div>
                                </div>
                            </div>
                        </div>
                        <div className="state-overview">
                            <div className="row">
                                <div className="col-xl-4 col-md-4 col-12">
                                    <div onClick={totalClaimList} style={{ cursor: `${pageAccess.includes(DOCTOR_PAGES.Claim) ? "pointer" : "Arrow"} ` }}>
                                        <div className="info-box bg-blue">
                                            <span className="info-box-icon mt-2">
                                                <i className="fa fa-drivers-license-o"></i>
                                            </span>
                                            <div className="info-box-content">
                                                <span className="info-box-text">{TOTAL_CLAIM}</span>
                                                <span className="info-box-number">{dashoard.totalClaims}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-4 col-12">
                                    <div className="info-box bg-orange">
                                        <span className="info-box-icon mt-2">
                                            <i className="fa fa-user-md"></i>
                                        </span>
                                        <div className="info-box-content">
                                            <span className="info-box-text">{TOTAL_DOCTOR}</span>
                                            <span className="info-box-number">{dashoard.totalDoctors}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-4 col-12">
                                    <div onClick={totalPatientList} style={{ cursor: `${pageAccess.includes(DOCTOR_PAGES.Patient) ? "pointer" : "Arrow"} ` }}>
                                        <div className="info-box bg-success">
                                            <span className="info-box-icon mt-2">
                                                <i className="fa fa-wheelchair"></i>
                                            </span>
                                            <div className="info-box-content">
                                                <span className="info-box-text">{TOTAL_PATIENT}</span>
                                                <span className="info-box-number">{dashoard.totalPatient}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-xl-3 col-md-3 col-12">
                                    <div onClick={totalOfficeEmployeeList} style={{ cursor: `${isTrial != 2 ? "pointer" : "Arrow"} ` }}>
                                        <div className="info-box bg-purple">
                                            <span className="info-box-icon mt-2">
                                                <i className="fa fa-users"></i>
                                            </span>
                                            <div className="info-box-content">
                                                <span className="info-box-text">{TOTAL_OFFICE_EMPLOYEE}</span>
                                                <span className="info-box-number">{dashoard.totalOfficeEmp}</span>
                                            </div> */}
                                {/* </div>
                                    </div> */}
                                {/* </div> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <div className="card">
                                    <div className="card-head">
                                        <header>{CONTACTS}</header>
                                    </div>
                                    <div className="card-body no-padding height-9">
                                        <ul className="list-group list-group-unbordered">
                                            {/* <li className="list-group-item">
                                                <b data-toggle="tooltip" title="Eligibility Check, Claim Status, Billing Question, Request Training">
                                                    {BILLING}
                                                </b>

                                                <div className="profile-desc-item pull-right">billing@billwithus.com</div>
                                            </li> */}
                                            <li className="list-group-item">
                                                <b data-toggle="tooltip" title="Event, Account info">
                                                    {SUPPORT}
                                                </b>
                                                <div className="profile-desc-item pull-right">support@billwithus.com</div>
                                            </li>
                                            <li className="list-group-item">
                                                <b data-toggle="tooltip" title="Add/Remove office, doctor, application status">
                                                    {ENROLLMENT}{" "}
                                                </b>
                                                <div className="profile-desc-item pull-right">enrollment@billwithus.com</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-7 col-md-12 col-lg-12 col-sm-12">
                                {/* ///////////////////////////////////////////////////////////////// Notice LIST //////////////////////////////////////////////////// */}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-head">
                                                <header>{NOTICE}</header>
                                            </div>
                                            <div className="card-body pb-0">
                                                <div className="table-scrollable">
                                                    <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                        <thead>
                                                            <tr>
                                                                <th>{NO}</th>
                                                                <th>{TITLE}</th>
                                                                <th>{CONTENTS}</th>
                                                                <th>{ATTACHMENT}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!notificationList ? (
                                                                <NoDataFound />
                                                            ) : (
                                                                notificationList &&
                                                                notificationList.map((item, i) => {
                                                                    return (
                                                                        <>
                                                                            <tr className="odd gradeX" key={i}>
                                                                                <td> {(offset - 1) * rowsPerPage + i + 1} </td>
                                                                                <td>
                                                                                    <button className="btn btn-link" onClick={() => handleShow(item.id)}>
                                                                                        {item.title}
                                                                                    </button>
                                                                                </td>
                                                                                <td className="p-0">
                                                                                    {" "}
                                                                                    {item.contents.length < 20 ? (
                                                                                        <pre>{parse(item.contents.slice(0, 20))}</pre>
                                                                                    ) : (
                                                                                        <pre>{parse(item.contents.slice(0, 20) + "...")}</pre>
                                                                                    )}
                                                                                </td>

                                                                                <td>
                                                                                    {item.attachment ? (
                                                                                        <button type="button" className="btn btn-info btn-xs" onClick={() => window.open(item.attachment, "_blank")}>
                                                                                            <i className="fa  fa-list-alt"></i>
                                                                                        </button>
                                                                                    ) : (
                                                                                        DASH
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    );
                                                                })
                                                            )}
                                                            {show ? <NoticeDetail showDetail={showDetail} handleClose={handleClose} show={show} check={check} /> : ""}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <div className="mt-3">Total {notificationList == null ? "0" : rowCount} Notification</div>
                                                    <div className="pagination-center">
                                                        <ReactPaginate
                                                            initialPage={0}
                                                            pageCount={pageCount}
                                                            marginPagesDisplayed={0}
                                                            pageRangeDisplayed={3}
                                                            onPageChange={handlePageClick}
                                                            containerClassName="pagination"
                                                            activeClassName="active"
                                                            pageLinkClassName="page-link"
                                                            breakLinkClassName="page-link"
                                                            nextLinkClassName="page-link"
                                                            previousLinkClassName="page-link"
                                                            pageClassName="page-item"
                                                            breakClassName="page-item"
                                                            nextClassName="page-item"
                                                            previousClassName="page-item"
                                                            previousLabel="Previous"
                                                            nextLabel="Next"
                                                            disableInitialCallback={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ///////////////////////////////////////////////////////////////// RECENT PRE AUTHORIZATION LIST //////////////////////////////////////////////////// */}
                                <UpdateStatus
                                    changedFields={changedFields}
                                    show={showStatus}
                                    handleClose={handleCloseStatus}
                                    handleChange={handleChange}
                                    preAuthorizationId={preAuthorizationId}
                                    preAuthorization={preAuthorization}
                                />
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-head">
                                                <header>{NEW_PRE_AUTHORIZATION_REQUEST}</header>
                                            </div>
                                            <div className="card-body pb-0">
                                                <div className="table-scrollable">
                                                    <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                        <thead>
                                                            <tr>
                                                                <th>{DOCTOR}</th>
                                                                <th>{PATIENT}</th>
                                                                <th>{DATE_OF_SERVICE}</th>
                                                                <th>{CREATED_DATE}</th>
                                                                <th>{CREATE_BY}</th>
                                                                <th></th>
                                                                <th>{STATUS}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!weeklyPreAuthorizationList ? (
                                                                <NoDataFound />
                                                            ) : (
                                                                weeklyPreAuthorizationList &&
                                                                weeklyPreAuthorizationList.map((item, i) => {
                                                                    return (
                                                                        <tr
                                                                            className="odd gradeX"
                                                                            key={i}
                                                                            style={{
                                                                                background: `${(role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) && item.isNew == 1 ? "#cce8ff" : ""}`,
                                                                            }}
                                                                        >
                                                                            <td className="left">
                                                                                <Link
                                                                                    to="/manage-preAuthorization-notes"
                                                                                    style={{ textDecoration: "underline", color: "#2a32be" }}
                                                                                    onClick={() => handlePreAuthorizationNote(item.doctor.uniqueId)}
                                                                                >
                                                                                    {item.doctor.firstName} {item.doctor.lastName}
                                                                                </Link>{" "}
                                                                            </td>
                                                                            <td className="left">
                                                                                {" "}
                                                                                {item.patient.firstName} {item.patient.lastName}
                                                                            </td>
                                                                            <td className="left">{item.dateOfService ? dateFormateMonthDateYear(item.dateOfService) : DASH}</td>

                                                                            <td className="left">{dateFormateMonthDateYear(item.created_at)}</td>
                                                                            <td className="left">
                                                                                <div data-toggle="tooltip" title={item.createBy.email}>
                                                                                    {item.createBy && item.createBy.email
                                                                                        ? item.createBy.email.length > 10
                                                                                            ? item.createBy.email.slice(0, 10) + "..."
                                                                                            : item.createBy.email
                                                                                        : DASH}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                {" "}
                                                                                {item.isNew == 1 && (role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) ? (
                                                                                    <img src="assets/img/new.gif" style={{ height: "20px", width: "30px", paddingright: "10px" }} />
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </td>
                                                                            <td>
                                                                                <button
                                                                                    className={
                                                                                        "btn btn-xs " +
                                                                                        (item.statusId == 2
                                                                                            ? " btn-dark"
                                                                                            : item.statusId == 1
                                                                                            ? "btn-success"
                                                                                            : item.statusId == 5
                                                                                            ? "btn-warning"
                                                                                            : item.statusId == 7
                                                                                            ? "btn-info"
                                                                                            : item.statusId == 8
                                                                                            ? "btn-primary"
                                                                                            : "btn-danger")
                                                                                    }
                                                                                    onClick={() => handleStatus(item.id, item.changedFields && JSON.parse(item.changedFields))}
                                                                                >
                                                                                    {Object.keys(PRE_AUTH_STATUS).find((key) => PRE_AUTH_STATUS[key] === item.statusId)}
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <div className="mt-3">Total {weeklyPreAuthorizationList == null ? "0" : PreAuthorizationRowCount} Pre-authorization notes</div>
                                                    <div className="pagination-center">
                                                        <ReactPaginate
                                                            initialPage={0}
                                                            pageCount={PreAuthorizationPageCount}
                                                            marginPagesDisplayed={0}
                                                            pageRangeDisplayed={3}
                                                            onPageChange={handlePreAuthorizationPageClick}
                                                            containerClassName="pagination"
                                                            activeClassName="active"
                                                            pageLinkClassName="page-link"
                                                            breakLinkClassName="page-link"
                                                            nextLinkClassName="page-link"
                                                            previousLinkClassName="page-link"
                                                            pageClassName="page-item"
                                                            breakClassName="page-item"
                                                            nextClassName="page-item"
                                                            previousClassName="page-item"
                                                            previousLabel="Previous"
                                                            nextLabel="Next"
                                                            disableInitialCallback={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {ispopupid ? (
                            popUpList.map((item, key) => {
                                return <NoticeDetail noticeId={item} checkedPopUP={checkedPopUP} />;
                            })
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
