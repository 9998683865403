import React from "react";
import { BILL_WITH_US, ROLES, BWS_PHONE } from "../../Helper/Constants";
import Cookies from "universal-cookie";

export default function Footer() {
    const date = new Date().getFullYear();

    let adminRole = localStorage.getItem("adminRole");
    const cookies = new Cookies();

    let role = cookies.get("role");
    let pageAuthorityRoleId;
    if (role) {
        role = cookies.get("role").roleId;
        pageAuthorityRoleId = cookies.get("role").pageAuthorityRoleId;
    }

    return (
        <>
            <div
                className={`page-footer ${
                    role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR
                        ? "doctor-footer"
                        : pageAuthorityRoleId
                        ? pageAuthorityRoleId == ROLES.EMPLOYEE_ADMIN || pageAuthorityRoleId == ROLES.ADMIN
                            ? "admin-footer"
                            : "employee-footer"
                        : "admin-footer"
                }`}
            >
                <div className="page-footer-inner text-white">
                    {" "}
                    {date} &copy;
                    <a className="makerCss mx-2 text-white">
                        {BILL_WITH_US} : {BWS_PHONE}
                    </a>
                </div>
                <div className="scroll-to-top">
                    <i className="icon-arrow-up"></i>
                </div>
            </div>
        </>
    );
}
