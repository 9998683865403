import React, { useEffect, useState } from "react";
import {
    PRACTICE_LIST,
    HOME,
    ADD,
    EMAIL,
    DOCTOR,
    OFFICE,
    PHONE_NO,
    DOCTOR_SPECIALTY,
    STATE,
    CITY,
    CREATED_DATE,
    STATUS,
    CHOOSE_DOCTOR_TYPE,
    OFFICE_STATUS,
    DASH,
    ACTIVE,
    DEACTIVE,
    ROLES,
    IMPERSONATING,
    ROWS_PER_PAGE,
    ALL,
    SELECT,
} from "../../Helper/Constants";
import ReactPaginate from "react-paginate";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { OFFICE_PRACTICE_LIST_API, PAGE_DETAIL_API, SPECIALTY_LIST_WITHOUT_PAGINATION } from "../../Helper/ApiPath";
import { baseURL } from "../../Config";
import { dateFormateMonthDateYear } from "../../Helper/Helper";
import NoDataFound from "../CommonComponent/NoDataFound";
import Loader from "../CommonComponent/Loader";
import Cookies from "universal-cookie";
import Select from "react-select";

function ManagePractice() {
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [rowCount, setRowCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [offset, setOffset] = useState(1);
    let navigate = useNavigate();

    const token = JSON.parse(localStorage.getItem("logToken"));
    const cookies = new Cookies();

    let role = cookies.get("role");
    if (role) {
        role = cookies.get("role").roleId;
    }

    let specialityArray = [];

    var checkboxes = document.getElementsByClassName("practice-speciality");

    for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].checked) {
            specialityArray.push(checkboxes[i].value);
        }
    }

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const [filterValues, setFilterValues] = useState({
        email: "",
        Dentist: "",
        Chiro: "",
        Accu: "",
        status: "",
        officeSearch: "",
        doctorSearch: "",
    });

    const handleFilterValues = (e) => {
        const { name, value } = e.target;
        setFilterValues({ ...filterValues, [name]: value });
        setOffset(1);
        setCurrentPage(0);
    };

    const [filterspecialityValue, setFilterspecialityValues] = useState();
    const handleFilterSpecialityValue = (e) => {
        setFilterspecialityValues(e.value);
        setOffset(1);
        setCurrentPage(0);
    };

    const handleAdd = () => {
        navigate("/registerDoctor");
    };

    const [officePracticeList, setOfficePracticeList] = useState([]);

    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${OFFICE_PRACTICE_LIST_API}`, {
                headers: { authorization: token.token },
                page: offset,
                rowsPerPage: ROWS_PER_PAGE,
                sortBy: "",
                sortDirection: "",
                specialitySearch: filterspecialityValue,
                status: filterValues.status,
                doctorSearch: filterValues.doctorSearch,
                officeSearch: filterValues.officeSearch,
                emailSearch: filterValues.email,
            })
            .then((res) => {
                setIsLoading(false);
                setOfficePracticeList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    let addSelectText = [
        {
            id: "",
            name: SELECT,
        },
    ];
    const [specialtyList, setSpecialtyList] = useState([]);

    const specialtyData = async () => {
        await axios
            .post(`${baseURL}/${SPECIALTY_LIST_WITHOUT_PAGINATION}`)
            .then((res) => {
                setSpecialtyList(res.data.data ? addSelectText.concat(res.data.data) : addSelectText);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };
    
    const specialtyOptions =
        specialtyList &&
        specialtyList.map((item, i) => {
            return {
                id: item.id,
                value: item.id,
                label: item.name,
            };
        });

    const handleLogIn = async (practiceId, doctorId, officeId, payerId, payerName, payerValue, isTrial) => {
        localStorage.setItem("adminRole", ROLES.DOCTOR);
        localStorage.setItem("officeId", officeId);
        localStorage.setItem("doctorId", doctorId);
        localStorage.setItem("doctorPracticeId", practiceId);
        localStorage.setItem("payerId", payerId);
        localStorage.setItem("payerName", payerName);
        localStorage.setItem("payerValue", payerValue);
        sessionStorage.removeItem("claimPagination");
        sessionStorage.removeItem("opNotePagination");
        sessionStorage.removeItem("cnPagination");
        sessionStorage.removeItem("preAuthPagination");
        sessionStorage.removeItem("patientPagination");
        sessionStorage.removeItem("officePagination");
        sessionStorage.removeItem("assignDoctorPagination");
        sessionStorage.removeItem("eobPagination");
        sessionStorage.removeItem("doctorPagination");
        cookies.set("isTrial", { isTrial: isTrial, practiceId: practiceId });
        let pageAccess = [];
        await axios
            .post(`${baseURL}/${PAGE_DETAIL_API}`, {
                headers: { authorization: token.token },
                roleId: ROLES.DOCTOR,
                privateKey: 1,
            })
            .then((res) => {
                res.data.data &&
                    res.data.data.map((item) => {
                        if (item.isAuthority == 1) {
                            pageAccess.push(item.pageId);
                        }
                    });
                cookies.set("doctorPageAccess", pageAccess);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
        navigate("/dashboard");
        window.location.reload();
    };

    useEffect(() => {
        fetchData();
        specialtyData();
    }, [filterValues, offset, filterspecialityValue]);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{PRACTICE_LIST}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{PRACTICE_LIST}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tabbable-line">
                                <div className="tab-content">
                                    <div className="tab-pane active fontawesome-demo" id="tab1">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card" style={isLoading ? { height: "35rem" } : {}}>
                                                    <div className="card-body">
                                                        {/* <div className="d-flex justify-content-between mb-4">
                                                            <div className="col-md-10 col-sm-6 col-xs-6 ">
                                                                <div className="btn-group">
                                                                    <label>{DOCTOR_SPECIALTY}</label>
                                                                </div>

                                                                {Object.keys(CHOOSE_DOCTOR_TYPE).map((item, i) => {
                                                                    return (
                                                                        <div className="btn-group mx-4" key={i}>
                                                                            <input
                                                                                type="checkbox"
                                                                                className="mt-2 mx-2 practice-speciality"
                                                                                value={CHOOSE_DOCTOR_TYPE[item]}
                                                                                onChange={handleFilterValues}
                                                                            ></input>
                                                                            <label>{item}</label>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div> */}

                                                        <div className="row">
                                                            <div className="justify-content-between">
                                                                <div className="row ">
                                                                    <div className="col-1">
                                                                        <button id="addRow" className="btn btn-info" onClick={handleAdd}>
                                                                            {ADD}
                                                                            <i className="fa fa-plus"></i>
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-11">
                                                                        <div className="row">
                                                                            <div className="col-1"></div>
                                                                            <div className="col-3">
                                                                                <div className="row">
                                                                                    <div className="btn-group mx-7" style={{ width: "270px" }}>
                                                                                        <label className="mt-2 col-md-5">{DOCTOR_SPECIALTY}</label>
                                                                                        <div className="col-9">
                                                                                            <Select
                                                                                                name="specialty"
                                                                                                value={specialtyOptions && specialtyOptions.filter(({ value }) => value == filterspecialityValue)}
                                                                                                options={specialtyOptions}
                                                                                                onChange={handleFilterSpecialityValue}
                                                                                                placeholder={SELECT}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <div className="col-3">
                                                                                        <label className="mt-2">{EMAIL}</label>{" "}
                                                                                    </div>
                                                                                    <div className="col-9">
                                                                                        <input
                                                                                            className="form-control mr-sm-2"
                                                                                            name="email"
                                                                                            type="search"
                                                                                            placeholder="Search"
                                                                                            aria-label="Search"
                                                                                            onChange={handleFilterValues}
                                                                                        />
                                                                                    </div> */}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-3">
                                                                                <div className="row">
                                                                                    <div className="col-3">
                                                                                        <label className="mt-2">{OFFICE}</label>
                                                                                    </div>
                                                                                    <div className="col-9">
                                                                                        <input
                                                                                            className="form-control mr-sm-2"
                                                                                            name="officeSearch"
                                                                                            type="search"
                                                                                            placeholder="Search"
                                                                                            aria-label="Search"
                                                                                            value={filterValues.officeSearch}
                                                                                            onChange={handleFilterValues}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-3">
                                                                                <div className="row">
                                                                                    <div className="col-3">
                                                                                        <label className="mt-2">{DOCTOR}</label>
                                                                                    </div>
                                                                                    <div className="col-9">
                                                                                        <input
                                                                                            className="form-control mr-sm-2"
                                                                                            name="doctorSearch"
                                                                                            type="search"
                                                                                            placeholder="Search"
                                                                                            aria-label="Search"
                                                                                            value={filterValues.doctorSearch}
                                                                                            onChange={handleFilterValues}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-2">
                                                                                <div className="row">
                                                                                    <div className="col-4">
                                                                                        <label className="mt-2">{STATUS}</label>
                                                                                    </div>
                                                                                    <div className="col-8">
                                                                                        <select className="form-control" name="status" value={filterValues.status} onChange={handleFilterValues}>
                                                                                            <option value="">{ALL}</option>
                                                                                            {Object.keys(OFFICE_STATUS).map((item, i) => {
                                                                                                return (
                                                                                                    <option key={i} value={OFFICE_STATUS[item]}>
                                                                                                        {item}
                                                                                                    </option>
                                                                                                );
                                                                                            })}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-scrollable" style={isLoading ? { border: "none" } : {}}>
                                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{OFFICE}</th>
                                                                        <th>{DOCTOR}</th>
                                                                        <th>{EMAIL}</th>
                                                                        <th>{PHONE_NO}</th>
                                                                        <th>{STATE}</th>
                                                                        <th>{CITY}</th>
                                                                        <th>{DOCTOR_SPECIALTY}</th>
                                                                        <th>{CREATED_DATE}</th>
                                                                        <th>{STATUS}</th>
                                                                        <th>{IMPERSONATING}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={isLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                                    {isLoading ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        <>
                                                                            {!officePracticeList ? (
                                                                                <>{isLoading ? "" : <NoDataFound />}</>
                                                                            ) : (
                                                                                officePracticeList &&
                                                                                officePracticeList.map((item, i) => {
                                                                                    return (
                                                                                        <tr key={i}>
                                                                                            <td>{item.office.name}</td>
                                                                                            <td>
                                                                                                {item.doctor.firstName} {item.doctor.lastName}
                                                                                            </td>
                                                                                            <td>{item.user.email ? item.user.email : DASH}</td>
                                                                                            <td>{item.office.phoneNo1 ? item.office.phoneNo1 : DASH}</td>
                                                                                            <td>{item.office.state ? item.office.state : DASH}</td>
                                                                                            <td>{item.office.city ? item.office.city : DASH}</td>
                                                                                            <td>
                                                                                                {item.office.specialty ? item.office.specialty : DASH}
                                                                                            </td>
                                                                                            <td>{item.created_by ? dateFormateMonthDateYear(item.created_by) : DASH}</td>

                                                                                            <td className="left">
                                                                                                <span
                                                                                                    className={
                                                                                                        "badge " +
                                                                                                        (item.status == 2
                                                                                                            ? "deactiveStatus badge-warning"
                                                                                                            : item.status == 1
                                                                                                            ? "activeStatus badge-success"
                                                                                                            : "badge-danger")
                                                                                                    }
                                                                                                >
                                                                                                    {item.status == 1 ? ACTIVE : DEACTIVE}
                                                                                                </span>
                                                                                            </td>
                                                                                            <td>
                                                                                                {item.status === 1 ? (
                                                                                                    <button
                                                                                                        className="btn btn-primary"
                                                                                                        onClick={() =>
                                                                                                            handleLogIn(
                                                                                                                item.id,
                                                                                                                item.doctor.uniqueId,
                                                                                                                item.office.uniqueId,
                                                                                                                item.payerId,
                                                                                                                item.payerName,
                                                                                                                item.payerValue,
                                                                                                                item.isTrial
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        {IMPERSONATING}
                                                                                                    </button>
                                                                                                ) : (
                                                                                                    ""
                                                                                                )}
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mx-4">
                                                        <div>Total {officePracticeList == null ? "0" : rowCount} Practice</div>
                                                        <div className="pagination-center">
                                                            <ReactPaginate
                                                                initialPage={0}
                                                                pageCount={officePracticeList == null ? "0" : pageCount}
                                                                marginPagesDisplayed={0}
                                                                pageRangeDisplayed={3}
                                                                onPageChange={handlePageClick}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                pageLinkClassName="page-link"
                                                                breakLinkClassName="page-link"
                                                                nextLinkClassName="page-link"
                                                                previousLinkClassName="page-link"
                                                                pageClassName="page-item"
                                                                breakClassName="page-item"
                                                                nextClassName="page-item"
                                                                previousClassName="page-item"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"
                                                                disableInitialCallback={true}
                                                                forcePage={currentPage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManagePractice;
