import React, { useEffect, useState } from "react";
import {
    DOCTOR,
    PATIENT,
    DATE_OF_SERVICE,
    HOME,
    CREATED_DATE,
    DASH,
    ROWS_PER_PAGE,
    CREATED_BY,
    ACTION,
    COMPREHENSIVE_LIST,
    BIRTH_DATE,
    ADD,
    ROLES,
    SELECT_OFFICE,
    OFFICE,
} from "../../Helper/Constants";
import { COMPREHENSIVE_NOTE_DETAIL_API, COMPREHENSIVE_NOTE_LIST_API, OFFICE_LIST_WITHOUT_PAGINATION, CN_DOWNLOAD_API, PATIENT_TABS_CONTENT_API } from "../../Helper/ApiPath";
import { dateAPIFormate, dateFormateMonthDateYear, defaultTimeZone } from "../../Helper/Helper";
import axios from "axios";
import { baseURL, frontURL } from "../../Config";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { NavLink, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import NoDataFound from "../CommonComponent/NoDataFound";
import { DELETE_COMPREHENSIVE_NOTE_MESSAGE } from "../../Helper/Messages";
import Loader from "../CommonComponent/Loader";
import Cookies from "universal-cookie";
import Select from "react-select";
// import ReactToPrint from "react-to-print";
// import parse from "html-react-parser";

function ManageComprehensive() {
    const cookies = new Cookies();
    let adminRole = localStorage.getItem("adminRole");
    const cnPagination = JSON.parse(sessionStorage.getItem("cnPagination"));
    const [currentPage, setCurrentPage] = useState(0);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [comprehensiveList, setComprehensiveList] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [offset, setOffset] = useState(cnPagination && cnPagination.offset ? cnPagination.offset : 1);
    const [pageCount, setPageCount] = useState(0);
    let role = cookies.get("role");
    if (role) {
        role = cookies.get("role").roleId;
    }
    let token = JSON.parse(localStorage.getItem("logToken"));
    let officeId = localStorage.getItem("officeId");

    var date = new Date();
    const [filterValues, setFilterValues] = useState({
        doctorSearch: cnPagination && cnPagination.doctorSearch ? cnPagination.doctorSearch : "",
        patientSearch: cnPagination && cnPagination.patientSearch ? cnPagination.patientSearch : "",
        startDate: cnPagination && cnPagination.startDate ? defaultTimeZone(cnPagination.startDate) : new Date(date.setMonth(date.getMonth() - 6)),
        endDate: cnPagination && cnPagination.endDate ? defaultTimeZone(cnPagination.endDate) : new Date(date.setMonth(date.getMonth() + 7)),
    });

    const handlePdfDownload = async (cnId) => {
        axios
            .post(`${baseURL}/${CN_DOWNLOAD_API}`, {
                headers: { authorization: token.token },
                cnId: cnId,
            })
            .then(async (res) => {
                axios({
                    url: `${frontURL}${res.data.data.path}`,
                    method: "GET",
                    responseType: "blob",
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `${res.data.data.patientName + "_comrehensive-note" + ".pdf"}`);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url);
                });
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleFilterValues = (e) => {
        const { name, value } = e.target;
        setFilterValues({ ...filterValues, [name]: value });
        sessionStorage.setItem(
            "cnPagination",
            JSON.stringify({
                ...filterValues,
                [name]: value,
                offset: offset,
                filterOfficeId: filterOfficeId,
            })
        );
        setOffset(1);
        setCurrentPage(0);
    };
    let [filterOfficeId, setFilterOfficeId] = useState(cnPagination && cnPagination.filterOfficeId ? cnPagination.filterOfficeId : "");

    const [officeList, setOfficeList] = useState([]);
    let officeSelect = [
        {
            id: "",
            name: SELECT_OFFICE,
        },
    ];

    const officeListData = () => {
        axios
            .post(`${baseURL}/${OFFICE_LIST_WITHOUT_PAGINATION}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                setOfficeList(res.data.data ? officeSelect.concat(res.data.data) : res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const officeOption =
        officeList &&
        officeList.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });
    const handleOfficeChange = (e) => {
        sessionStorage.removeItem("cnPagination");
        setFilterOfficeId(e.value);
        setOffset(1);
        setCurrentPage(0);
    };

    const claimDoctorId = sessionStorage.getItem("claimDoctorId");
    const [doctorId, setDoctorId] = useState(claimDoctorId ? claimDoctorId : "");

    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${COMPREHENSIVE_NOTE_LIST_API}`, {
                headers: { authorization: token.token },
                officeId: role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? officeId : filterOfficeId,
                startDate: dateAPIFormate(filterValues.startDate),
                endDate: dateAPIFormate(filterValues.endDate),
                page: offset,
                rowsPerPage: ROWS_PER_PAGE,
                doctorSearch: filterValues.doctorSearch,
                patientSearch: filterValues.patientSearch,
            })
            .then((res) => {
                setIsLoading(false);
                setComprehensiveList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: DELETE_COMPREHENSIVE_NOTE_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/${COMPREHENSIVE_NOTE_DETAIL_API}/${id}`, {
                            headers: { authorization: token.token },
                        })
                        .then((res) => {
                            Swal.fire(res.data.message, " ", "success");
                            fetchData();
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    const handlePageClick = (event) => {
        sessionStorage.removeItem("cnPagination");
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleAdd = () => {
        navigate("/AddComprehensive");
    };

    const handleEdit = (id, doctorId, patientId, officeId) => {
        sessionStorage.setItem(
            "cnPagination",
            cnPagination
                ? JSON.stringify(cnPagination)
                : JSON.stringify({
                      offset: offset,
                      startDate: defaultTimeZone(filterValues.startDate),
                      endDate: defaultTimeZone(filterValues.endDate),
                      filterOfficeId: filterOfficeId,
                      patientSearch: filterValues.patientSearch,
                      doctorSearch: filterValues.doctorSearch,
                  })
        );
        localStorage.setItem("comprehensiveId", id);
        localStorage.setItem("CNDoctorId", doctorId);
        localStorage.setItem("CNPatientId", patientId);
        localStorage.setItem("officeId", officeId);
        navigate("/EditComprehensive");
    };
    useEffect(() => {
        localStorage.removeItem("isPreAuthorization");
        localStorage.removeItem("preAuthorization");

        if (claimDoctorId) {
            setDoctorId(claimDoctorId);
        }
        fetchData();
        if (role != ROLES.DOCTOR && adminRole != ROLES.DOCTOR) {
            officeListData();
        }
    }, [offset, doctorId, filterValues, filterOfficeId]);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{COMPREHENSIVE_LIST}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{COMPREHENSIVE_LIST}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tabbable-line">
                                <div className="tab-content">
                                    <div className="tab-pane active fontawesome-demo" id="tab1">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card" style={isLoading ? { height: "35rem" } : {}}>
                                                    <div className="card-body ">
                                                        <div className="row">
                                                            <div className="justify-content-between">
                                                                <div className="row">
                                                                    <div className="col-md-1 mr-sm-2">
                                                                        <button id="addRow" className="btn btn-info" onClick={handleAdd}>
                                                                            {ADD} <i className="fa fa-plus"></i>
                                                                        </button>
                                                                    </div>
                                                                    <div className="btn-group col-md-3 pr-0">
                                                                        <div className="col-md-4">
                                                                            <label className="mt-2">{DATE_OF_SERVICE}</label>
                                                                        </div>
                                                                        <DatePicker
                                                                            className="form-control mr-sm-2"
                                                                            selected={filterValues.startDate}
                                                                            maxDate={new Date()}
                                                                            yearDropdownItemNumber={100}
                                                                            scrollableMonthDropdown={true}
                                                                            showYearDropdown
                                                                            showMonthDropdown
                                                                            onChange={(date) => {
                                                                                sessionStorage.setItem(
                                                                                    "cnPagination",
                                                                                    JSON.stringify({
                                                                                        ...filterValues,
                                                                                        startDate: date,
                                                                                    })
                                                                                );
                                                                                setFilterValues({ ...filterValues, startDate: date });
                                                                            }}
                                                                        />
                                                                        <span className="todate">To</span>
                                                                    </div>
                                                                    <div className="btn-group col-md-2">
                                                                        <DatePicker
                                                                            className="form-control mr-sm-2"
                                                                            selected={filterValues.endDate}
                                                                            minDate={filterValues.startDate}
                                                                            maxDate={new Date().setMonth(new Date().getMonth() + 1)}
                                                                            yearDropdownItemNumber={100}
                                                                            scrollableMonthDropdown={true}
                                                                            showYearDropdown
                                                                            showMonthDropdown
                                                                            onChange={(date) => {
                                                                                sessionStorage.setItem(
                                                                                    "cnPagination",
                                                                                    JSON.stringify({
                                                                                        ...filterValues,
                                                                                        endDate: date,
                                                                                    })
                                                                                );
                                                                                setFilterValues({ ...filterValues, endDate: date });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    {role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? (
                                                                        <div className="col-md-3"></div>
                                                                    ) : (
                                                                        <div className="col-md-3 mr-sm-2">
                                                                            <div className="row">
                                                                                <div className="offset-4 col-md-8">
                                                                                    <Select
                                                                                        name="officeName"
                                                                                        value={officeOption && officeOption.filter(({ value }) => value == filterOfficeId)}
                                                                                        options={officeOption}
                                                                                        onChange={handleOfficeChange}
                                                                                        placeholder={SELECT_OFFICE}
                                                                                    ></Select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}

                                                                    <div className="col-md-3">
                                                                        <div className="row">
                                                                            <div className="col-md-6 mr-sm-2">
                                                                                <div className="btn-group col-md-12">
                                                                                    <input
                                                                                        className="form-control mr-sm-2"
                                                                                        value={filterValues.doctorSearch}
                                                                                        name="doctorSearch"
                                                                                        placeholder="Doctor"
                                                                                        onChange={handleFilterValues}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-6 mr-sm-2">
                                                                                <div className="row">
                                                                                    <div className="btn-group col-md-12">
                                                                                        <input
                                                                                            className="form-control mr-sm-2"
                                                                                            value={filterValues.patientSearch}
                                                                                            name="patientSearch"
                                                                                            placeholder="Patient"
                                                                                            onChange={handleFilterValues}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-scrollable" style={isLoading ? { border: "none" } : {}}>
                                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{PATIENT}</th>
                                                                        <th>{BIRTH_DATE}</th>
                                                                        <th>{DATE_OF_SERVICE}</th>
                                                                        <th>{DOCTOR}</th>
                                                                        {role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? "" : <th>{OFFICE}</th>}
                                                                        <th>{CREATED_DATE}</th>
                                                                        <th>{CREATED_BY}</th>
                                                                        <th>{ACTION}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={isLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                                    {isLoading ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        <>
                                                                            {!comprehensiveList ? (
                                                                                <>{isLoading ? "" : <NoDataFound />}</>
                                                                            ) : (
                                                                                comprehensiveList &&
                                                                                comprehensiveList.map((item, index) => {
                                                                                    return (
                                                                                        <tr className="odd gradeX" key={index}>
                                                                                            <td className="left">
                                                                                                {" "}
                                                                                                {item.patient.firstName} {item.patient.lastName}
                                                                                            </td>
                                                                                            <td className="left">
                                                                                                {item.patient && item.patient.dob ? dateFormateMonthDateYear(item.patient.dob) : DASH}
                                                                                            </td>
                                                                                            <td className="left">{item.dateOfService ? dateFormateMonthDateYear(item.dateOfService) : DASH}</td>
                                                                                            <td className="left">
                                                                                                {item.doctor.firstName} {item.doctor.lastName}
                                                                                            </td>
                                                                                            {role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td className="left">{item.offices ? item.offices.name : DASH}</td>
                                                                                            )}
                                                                                            <td className="left">{dateFormateMonthDateYear(item.created_at)}</td>
                                                                                            <td>{item.createBy && item.createBy.email ? item.createBy.email : DASH}</td>
                                                                                            <td>
                                                                                                <div
                                                                                                    className="btn btn-primary btn-xs"
                                                                                                    onClick={() =>
                                                                                                        handleEdit(
                                                                                                            item.id,
                                                                                                            item.doctor.uniqueId,
                                                                                                            item.patient.uniqueId,
                                                                                                            item.offices ? item.offices.uniqueId : null
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <i className="fa fa-pencil"></i>
                                                                                                </div>

                                                                                                <button className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                </button>
                                                                                                <div className="btn btn-success btn-xs" onClick={() => handlePdfDownload(item.id)}>
                                                                                                    <i className="fa fa-download mt-1"></i>
                                                                                                </div>
                                                                                                {/* <ComponentToPrintWrapper key={index} comprehensiveId={item.id} /> */}
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mx-4">
                                                        <div>Total {comprehensiveList == null ? "0" : rowCount} Comprehensive notes</div>
                                                        <div className="pagination-center">
                                                            <ReactPaginate
                                                                initialPage={0}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={0}
                                                                pageRangeDisplayed={3}
                                                                onPageChange={handlePageClick}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                pageLinkClassName="page-link"
                                                                breakLinkClassName="page-link"
                                                                nextLinkClassName="page-link"
                                                                previousLinkClassName="page-link"
                                                                pageClassName="page-item"
                                                                breakClassName="page-item"
                                                                nextClassName="page-item"
                                                                previousClassName="page-item"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"
                                                                disableInitialCallback={true}
                                                                forcePage={cnPagination && cnPagination.offset ? cnPagination.offset - 1 : currentPage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManageComprehensive;

// const ComponentToPrint = React.forwardRef((props, ref) => {
//     let navigate = useNavigate();

//     let token = JSON.parse(localStorage.getItem("logToken"));
//     const [imageURL, setImageURl] = useState(null);
//     const [editorValue, setEditorValue] = useState("");
//     const [doctorInfo, setDoctorInfo] = useState([]);
//     const [patientInfo, setPatientInfo] = useState([]);
//     const [serviceDate, setServiceDate] = useState("");
//     const [insurance, setInsurance] = useState("");
//     const [payer, setPayer] = useState("");
//     const [CNDate, setCNDate] = useState("");
//     const [address, setAddress] = useState("");
//     const [address2, setAddress2] = useState("");
//     const { comprehensiveId } = props;

//     const fetchdata = async () => {
//         await axios
//             .get(`${baseURL}/${COMPREHENSIVE_NOTE_DETAIL_API}/${comprehensiveId}`, {
//                 headers: { authorization: token.token },
//             })
//             .then((res) => {
//                 setEditorValue(res.data.data.note);
//                 setDoctorInfo(res.data.data.doctor);
//                 setPatientInfo(res.data.data.patient);
//                 setServiceDate(res.data.data.dateOfService);
//                 fetchTabData(res.data.data.patient.uniqueId);
//                 setImageURl(res.data.data.signatureImage);
//                 setCNDate(res.data.data.cnDate);
//             })
//             .catch((err) => {
//                 if (err.response) {
//                     if (err.response.status == 500) {
//                         localStorage.clear();
//                         navigate("/login");
//                     } else {
//                         toast.error(err.response.data.message);
//                     }
//                 }
//             });
//     };
//     const fetchTabData = async (patientId) => {
//         axios
//             .post(`${baseURL}/${PATIENT_TABS_CONTENT_API}`, {
//                 headers: { authorization: token.token },
//                 patientChartTabId: 1,
//                 isList: true,
//                 patientId: patientId,
//             })
//             .then((res) => {
//                 res.data.data.map((item) => (item.id == 1 && item.name == "Insurance Name" ? setInsurance(item.value) : ""));
//                 res.data.data.map((item) => (item.id == 9 && item.name == "Payer" ? setPayer(item.value) : ""));
//                 res.data.data.map((item) => (item.id == 11 && item.name == "Insurance Address1" ? setAddress(item.value) : ""));
//                 res.data.data.map((item) => (item.id == 13 && item.name == "Insurance Address2" ? setAddress2(item.value) : ""));
//             })
//             .catch((err) => {
//                 if (err.response) {
//                     if (err.response.status === 500) {
//                         localStorage.clear();
//                         navigate("/login");
//                     } else {
//                         toast.error(err.response.data.message);
//                     }
//                 }
//             });
//     };

//     useEffect(() => {
//         fetchdata();
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, []);

//     return (
//         <div className="print-source" ref={ref}>
//             <div className="">
//                 <br />
//                 <br />
//                 <br />
//                 {/* <div className="card-body" id="bar-parent">
//                     <b>{DATE_OF_SERVICE}:</b> {serviceDate ? dateFormateMonthDateYear(serviceDate) : DASH}
//                     <br />
//                     <br />
//                     <b>{DOCTOR_NAME}:</b> {doctorInfo.firstName} {doctorInfo.lastName}
//                     <br />
//                     <b>{NPI1}: </b> {doctorInfo.npi1 ? doctorInfo.npi1 : DASH}
//                     <br />
//                     <b>{LICENSE_NUMBER}:</b> {doctorInfo.licenseNumber ? doctorInfo.licenseNumber : DASH}
//                     <br />
//                     <b>{PTAN_NUMBER}:</b> {doctorInfo.ptanNumber ? doctorInfo.ptanNumber : DASH}
//                     <br />
//                     <br />
//                     <b>{PATIENT_NAME}:</b> {patientInfo.firstName} {patientInfo.lastName}
//                     <br />
//                     <b>{BIRTH_DATE}: </b> {patientInfo.dob ? dateFormateMonthDateYear(patientInfo.dob) : DASH}
//                     <br />
//                     <b>{ADDRESS}:</b> {patientInfo.address1 ? patientInfo.address1 : DASH}
//                     <br />
//                     <br />
//                     <b>{PRIMARY_INSURANCE_NAME}:</b> {insurance ? insurance : DASH}
//                     <br />
//                     <b>{PAYER}: </b> {payer ? payer : DASH}
//                     <br />
//                     <b>{ADDRESS}:</b> {address ? (address2 ? address + " " + address2 : address) : DASH}
//                     <br />
//                 </div> */}
//                 {parse(editorValue)}
//                 <br />
//                 <b>{DOCTOR_NAME}:</b> {doctorInfo.firstName} {doctorInfo.lastName}
//                 <br />
//                 <div className="row d-flex">
//                     <div className="col-md-1">
//                         <b>{DATE}:</b>{" "}
//                     </div>
//                     <div className="col-md-3">{dateFormateMonthDateYear(CNDate)}</div>
//                 </div>
//                 <br />
//                 <div className="">
//                     <b className={`${imageURL ? "my-5" : ""}`}>Sign :</b>

//                     {imageURL ? (
//                         <img
//                             className="mx-2 my-2"
//                             src={imageURL}
//                             alt="Sign"
//                             style={{
//                                 display: "block",
//                             }}
//                         />
//                     ) : null}
//                 </div>
//             </div>
//         </div>
//     );
// });

// const ComponentToPrintWrapper = ({ comprehensiveId }) => {
//     const componentRef = useRef();

//     return (
//         <>
//             <ReactToPrint
//                 trigger={() => (
//                     <div className="btn btn-default btn-xs">
//                         <i className="fa fa-print mt-1" style={{ fontSize: "1.1rem" }}></i>
//                     </div>
//                 )}
//                 content={() => componentRef.current}
//             />
//             <div style={{ display: "none" }}>
//                 <ComponentToPrint ref={componentRef} comprehensiveId={comprehensiveId} />
//             </div>
//         </>
//     );
// };
