import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { NavLink, useNavigate } from "react-router-dom";
import { ACTION, ADD, CANCEL, HOME, NAME, UPDATE, MANAGE_SPECIALTY_CPTGROUP, SPECIALTY_CPTGROUP, SPECIALTY_INFORMATION, CPTGROUP_INFORMATION, ADD_SPECIALTY, ROWS_PER_PAGE, SELECT, NORMAL_STATUS, STATUS, SPECIALTY, CPTGROUP, SORT_PRIORITY } from "../../Helper/Constants";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { baseURL } from "../../Config";
import { SPECIALTY_ADD, SPECIALTY_LIST, CPTGROUP_ADD, CPTGROUP_LIST, SPECIALTY_EDIT, SPECIALTY_UPDATE, CPTGROUP_EDIT, CPTGROUP_UPDATE, SPECIALTY_LIST_WITHOUT_PAGINATION } from "../../Helper/ApiPath";
import { DELETE_MESSAGE, ENTER_CPTGROUP_NAME, ENTER_SPECIALTY } from "../../Helper/Messages";
import NoDataFound from "../CommonComponent/NoDataFound";
import Swal from "sweetalert2";
import Select from "react-select";
import Loader from "../CommonComponent/Loader";

function ManageSpecialtyCptgroup() {
    const [currentPage, setCurrentPage] = useState(0);
    const [currentCptgroupPage, setCurrentCptgroupPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [specialtyIsLoading, setSpecialtyIsLoading] = useState(true);
    const [cptgroupIsLoading, setCptgroupIsLoading] = useState(true);
    const [offset, setOffset] = useState(1);
    const [rowCount, setRowCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const navigate = useNavigate();

    const [filterSpecialtyValues, setFilterSpecialtyValues] = useState({
        search: "",
    });

    const handleSpecialtyFilterValues = (e) => {
        const { name, value } = e.target;
        setFilterSpecialtyValues({ ...filterSpecialtyValues, [name]: value });
        setOffset(1);
        setCurrentPage(0);
    };

    const handlePageClick = (event) => {
        let selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
        // postsPerPage(event.selected + 1);
    };

    const [showSpecialty, setShowSpecialty] = useState(false);
    const handleCloseSpecialty = () => setShowSpecialty(false);

    const handleShowSpecialty = (type) => {
        if (type === "add") {
            setShowSpecialty(true);
            setSpecialtyValue("");
            setEditSpecialtyStatus(false);
        } else {
            setShowSpecialty(true);
        }
    };

    let [specialtyValue, setSpecialtyValue] = useState({
        name: "",
        statusId: "",
        priorityList: "",
    });

    const handleSpecialty = (e) => {
        const { name, value } = e.target;
        setSpecialtyValue({ ...specialtyValue, [name]: value });
    };

    let adminToken = JSON.parse(localStorage.getItem("DoctorToken"));

    const [specialtyShowList, setSpecialtyShowList] = useState();

    const specialtyList = async () => {
        await axios
            .post(`${baseURL}/${SPECIALTY_LIST}`, {
                headers: { authorization: adminToken },
                page: offset,
                rowsPerPage: ROWS_PER_PAGE,
                search: filterSpecialtyValues.search,
            })
            .then((res) => {
                setSpecialtyIsLoading(false);
                setSpecialtyShowList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                setSpecialtyIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const [cptgroupSpecialtyList, setCptgroupSpecialtyList] = useState([]);
    const cptgroupspecialtyListData = async () => {
        await axios
            .post(`${baseURL}/${SPECIALTY_LIST_WITHOUT_PAGINATION}`)
            .then((res) => {
                setCptgroupSpecialtyList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const cptgroupSpecialtyListOptions =
        cptgroupSpecialtyList &&
        cptgroupSpecialtyList.map((item, i) => {
            return {
                id: item.id,
                value: item.id,
                label: item.name,
            };
        });

    const handleAddSpecialty = async (id) => {
        if (!specialtyValue.name) {
            toast.error(ENTER_SPECIALTY);
        } else {
            setIsLoading(true);
            await axios
                .post(`${baseURL}/${SPECIALTY_ADD}`, {
                    headers: { authorization: adminToken },
                    name: specialtyValue.name,
                    statusId: specialtyValue.statusId,
                    priorityList: specialtyValue.priorityList,
                })
                .then((res) => {
                    handleCloseSpecialty(false);
                    toast.success(res.data.message);
                    specialtyList();
                    navigate("/manage-spclty-cptgrp");
                    setSpecialtyValue("");
                    cptgroupspecialtyListData();
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false);
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const [editSpecialtyStatus, setEditSpecialtyStatus] = useState(false);

    const handleEditSpecialty = async (id) => {
        await axios
            .get(`${baseURL}/${SPECIALTY_EDIT}/${id}`, {
                headers: {
                    authorization: adminToken,
                },
            })
            .then((res) => {
                handleShowSpecialty(true);
                if (res.status === 200) {
                    setSpecialtyValue(res.data.data);
                    setEditSpecialtyStatus(true);
                    cptgroupspecialtyListData();
                }
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: DELETE_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/${SPECIALTY_EDIT}/${id}`, {
                            headers: { authorization: adminToken },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                specialtyList();
                                Swal.fire(res.data.message, " ", "success");
                            }
                        })
                        .catch((err) => {
                            toast.error(err.response.data.message);
                        });
                }
            }
        });
    };

    const handleUpdateSpecialty = async () => {
        setIsLoading(true);
        await axios
            .post(`${baseURL}/${SPECIALTY_UPDATE}`, {
                headers: { authorization: adminToken },
                id: specialtyValue.id,
                name: specialtyValue.name,
                statusId: specialtyValue.statusId,
                priorityList: specialtyValue.priorityList,
            })
            .then((res) => {
                if (res.status === 200) {
                    specialtyList();
                    handleCloseSpecialty(false);
                    cptgroupList();
                    cptgroupspecialtyListData();
                    toast.success(res.data.message);
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    ////////////////////////////////////////////////////////////////////// CPT GROUP //////////////////////////////////////////////////////////////////////

    const [cptgroupOffset, setCptgroupOffset] = useState(1);
    const [cptgroupRowCount, setCptgroupRowCount] = useState(0);
    const [cptgroupPageCount, setCptgroupPageCount] = useState(0);

    const [filterCptgroupValues, setFilterCptgroupValues] = useState({
        search: "",
    });
    const [specialtySearch, setSpecialtySearch] = useState("");
    const handleCptgroupFilterValues = (e) => {
        const { name, value } = e.target;
        setFilterCptgroupValues({ ...filterCptgroupValues, [name]: value });
        setCptgroupOffset(1);
        setCurrentCptgroupPage(0);
    };

    const handleCptgroupSpecialtyFilterValues = (e) => {
        setSpecialtySearch(e.target.value);
        setCptgroupOffset(1);
        setCurrentPage(0);
    };

    const handleCptgroupPageClick = (event) => {
        let selectedPage = event.selected;
        setCptgroupOffset(selectedPage + 1);
        setCurrentCptgroupPage(selectedPage);
        // postsPerPage(event.selected + 1);
    };

    const [showCptgroup, setShowCptgroup] = useState(false);
    const handleCloseCptgroup = () => {
        setShowCptgroup(false);
        setShow(false);
    };

    const handleShowCptgroup = (type) => {
        if (type === "add") {
            setShowCptgroup(true);
            setCptgroupValue("");
        }
    };

    const [cptgroupShowList, setCptgroupShowList] = useState([]);

    const [cptgroupValue, setCptgroupValue] = useState({
        name: "",
        specialtyId: "",
        statusId: "",
        priorityList: "",
    });

    const handleSpecialtyChange = (e) => {
        setCptgroupValue({ ...cptgroupValue, specialtyId: e.value });
    };

    const handleCptgroup = (e) => {
        const { name, value } = e.target;
        setCptgroupValue({ ...cptgroupValue, [name]: value });
    };
    const onCptgroupChanged = (e) => {
        const { name, value } = e.target;
        setCptgroupInfo({ ...cptgroupInfo, [name]: value });
    };
    const cptgroupList = async () => {
        await axios
            .post(`${baseURL}/${CPTGROUP_LIST}`, {
                headers: { authorization: adminToken },
                page: cptgroupOffset,
                rowsPerPage: ROWS_PER_PAGE,
                search: filterCptgroupValues.search,
                specialtySearch: specialtySearch,
            })
            .then((res) => {
                setCptgroupIsLoading(false);
                setCptgroupShowList(res.data.data);
                setCptgroupPageCount(res.data.extraData.pageCount);
                setCptgroupRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                setCptgroupIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleAddCptgroup = async (id) => {
        if (!cptgroupValue.specialtyId) {
            toast.error(ENTER_SPECIALTY);
        } else if (!cptgroupValue.name) {
            toast.error(ENTER_CPTGROUP_NAME);
        } else {
            setIsLoading(true);
            await axios
                .post(`${baseURL}/${CPTGROUP_ADD}`, {
                    headers: { authorization: adminToken },
                    name: cptgroupValue.name,
                    specialtyId: cptgroupValue.specialtyId,
                    priorityList: cptgroupValue.priorityList,
                })
                .then((res) => {
                    handleCloseCptgroup(false);
                    toast.success(res.data.message);
                    cptgroupList();
                    navigate("/manage-spclty-cptgrp");
                    setCptgroupValue("");
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false);
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };
    const [show, setShow] = useState(false);
    const [cptgroupInfo, setCptgroupInfo] = useState({
        name: "",
        specialtyId: "",
        statusId: "",
        priorityList: "",
    });
    const [id, setId] = useState("");
    const handleUpdateCptgroup = async () => {
        if (!cptgroupInfo.specialtyId) {
            toast.error(ENTER_SPECIALTY);
        } else if (!cptgroupInfo.name) {
            toast.error(ENTER_CPTGROUP_NAME);
        } else {
            setIsLoading(true);
            await axios
                .post(`${baseURL}/${CPTGROUP_UPDATE}`, {
                    headers: { authorization: adminToken },
                    id: id,
                    name: cptgroupInfo.name,
                    specialtyId: Number(cptgroupInfo.specialtyId),
                    statusId: cptgroupInfo.statusId,
                    priorityList: cptgroupInfo.priorityList,
                })
                .then((res) => {
                    if (res.status === 200) {
                        setShow(false);
                        setShowCptgroup(false);
                        cptgroupList();
                        toast.success(res.data.message);
                        setIsLoading(false);
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    toast.error(err.response.data.message);
                });
        }
    };

    const handleEditCptgroup = async (cptgroupId) => {
        setShow(true);
        setShowCptgroup(true);
        await axios
            .get(`${baseURL}/${CPTGROUP_EDIT}/${cptgroupId}`, {
                headers: {
                    authorization: adminToken,
                },
            })
            .then((res) => {
                if (res.status == 200) {
                    setCptgroupInfo(res.data.data.cptgroup);
                    setId(res.data.data.id);
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const handleCancel = () => {
        setShowCptgroup(false);

        setShow(false);
    };
    const handleCptgroupDelete = async (id) => {
        Swal.fire({
            title: DELETE_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/${CPTGROUP_EDIT}/${id}`, {
                            headers: { authorization: adminToken },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                cptgroupList();
                                Swal.fire(res.data.message, " ", "success");
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    useEffect(() => {
        cptgroupspecialtyListData();
        specialtyList();
        cptgroupList();
    }, [offset, cptgroupOffset, filterSpecialtyValues, filterCptgroupValues, specialtySearch]);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{MANAGE_SPECIALTY_CPTGROUP}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{SPECIALTY_CPTGROUP}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tabbable-line">
                                <div className="tab-content">
                                    <div className="tab-pane active fontawesome-demo" id="tab1">
                                        <div className="row">
                                            {/* Specialty Information */}
                                            <div className="col-md-6">
                                                <div className="card" style={specialtyIsLoading ? { height: "35rem" } : {}}>
                                                    <div className="card-head">
                                                        <header>{SPECIALTY_INFORMATION}</header>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="row d-flex justify-content-between">
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="btn-group">
                                                                        <button id="addRow" className="btn btn-info" onClick={() => handleShowSpecialty("add")}>
                                                                            {ADD}
                                                                            <i className="fa fa-plus"></i>
                                                                        </button>
                                                                    </div>
                                                                    <div className="btn-group" style={{ marginRight: "-20px", width: "230px" }}>
                                                                        <label className="mt-2 col-md-5">{SPECIALTY}</label>
                                                                        <input
                                                                            className="form-control"
                                                                            name="search"
                                                                            type="search"
                                                                            placeholder="Search"
                                                                            aria-label="Search"
                                                                            value={filterSpecialtyValues.search}
                                                                            onChange={handleSpecialtyFilterValues}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="btn-group">
                                                                <Modal show={showSpecialty} onHide={handleCloseSpecialty}>
                                                                    <Modal.Header closeButton>
                                                                        {editSpecialtyStatus ? <Modal.Title>Update Specialty</Modal.Title> : <Modal.Title>{ADD_SPECIALTY}</Modal.Title>}
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <label>
                                                                            {NAME}
                                                                            <span className="required text-danger"> * </span>
                                                                        </label>
                                                                        <input type="text" className="form-control" name="name" value={specialtyValue && specialtyValue.name} onChange={handleSpecialty} />
                                                                        <label>
                                                                            {SORT_PRIORITY}
                                                                        </label>
                                                                        <input type="text" className="form-control" name="priorityList" value={specialtyValue && specialtyValue.priorityList} onChange={handleSpecialty} />
                                                                        <label>{STATUS}</label>
                                                                        <select className="form-control col-md-3" name="statusId" value={specialtyValue.statusId} onChange={(e) => setSpecialtyValue({ ...specialtyValue, statusId: e.target.value })}>
                                                                            {Object.keys(NORMAL_STATUS).map((item, i) => {
                                                                                return (
                                                                                    <option key={i} value={NORMAL_STATUS[item]}>
                                                                                        {item}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                    </Modal.Body>
                                                                    <div className="d-flex justify-content-center mb-3">
                                                                        <div className="center">
                                                                            {editSpecialtyStatus ? (
                                                                                <>
                                                                                    <Button variant="primary" onClick={handleUpdateSpecialty}>
                                                                                        {UPDATE}
                                                                                    </Button>
                                                                                    {isLoading ? (
                                                                                        <i
                                                                                            className="fa fa-spinner fa-spin fa-2x"
                                                                                            style={{
                                                                                                position: "relative",
                                                                                                top: "8px",
                                                                                                left: "-50px",
                                                                                                color: "#fff",
                                                                                            }}
                                                                                        ></i>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <Button variant="primary" onClick={handleAddSpecialty}>
                                                                                        {ADD}
                                                                                    </Button>

                                                                                    {isLoading ? (
                                                                                        <i
                                                                                            className="fa fa-spinner fa-spin fa-2x"
                                                                                            style={{
                                                                                                position: "relative",
                                                                                                top: "8px",
                                                                                                left: "-43px",
                                                                                                color: "#fff",
                                                                                            }}
                                                                                        ></i>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </>
                                                                            )}

                                                                            <Button className="mx-3" variant="default" onClick={handleCloseSpecialty}>
                                                                                {CANCEL}
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </Modal>
                                                            </div>
                                                        </div>
                                                        <div className="table-scrollable" style={specialtyIsLoading ? { border: "none" } : {}}>
                                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{NAME}</th>
                                                                        <th>{STATUS}</th>
                                                                        <th>{SORT_PRIORITY}</th>
                                                                        <th>{ACTION}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={specialtyIsLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                                    {specialtyIsLoading ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        <>
                                                                            {!specialtyShowList ? (
                                                                                <>{specialtyIsLoading ? "" : <NoDataFound />}</>
                                                                            ) : (
                                                                                specialtyShowList &&
                                                                                specialtyShowList.map((item, index) => {
                                                                                    return (
                                                                                        <tr className="odd gradeX" key={index}>
                                                                                            <td className="left">{item.name}</td>
                                                                                            <td>{Object.keys(NORMAL_STATUS).find((key) => NORMAL_STATUS[key] === item.statusId)}</td>
                                                                                            <td className="center">{item.priorityList}</td>
                                                                                            <td>
                                                                                                <button className="btn btn-primary btn-xs" onClick={() => handleEditSpecialty(item.id)}>
                                                                                                    <i className="fa fa-pencil"></i>
                                                                                                </button>
                                                                                                <button className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mx-4">
                                                        <div>Total {specialtyShowList == null ? "0" : rowCount} Specialty</div>
                                                        <div className="pagination-center">
                                                            <ReactPaginate
                                                                initialPage={0}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={0}
                                                                pageRangeDisplayed={3}
                                                                onPageChange={handlePageClick}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                pageLinkClassName="page-link"
                                                                breakLinkClassName="page-link"
                                                                nextLinkClassName="page-link"
                                                                previousLinkClassName="page-link"
                                                                pageClassName="page-item"
                                                                breakClassName="page-item"
                                                                nextClassName="page-item"
                                                                previousClassName="page-item"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"
                                                                disableInitialCallback={true}
                                                                forcePage={currentPage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* CPT Group Information */}
                                            <div className="col-md-6">
                                                <div className="card" style={cptgroupIsLoading ? { height: "35rem" } : {}}>
                                                    <div className="card-head">
                                                        <header>{CPTGROUP_INFORMATION}</header>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="row d-flex justify-content-between">
                                                                <div className="d-flex  justify-content-between">
                                                                    <div className="btn-group">
                                                                        <button id="addRow" className="btn btn-info" onClick={() => handleShowCptgroup("add")}>
                                                                            {ADD}
                                                                            <i className="fa fa-plus"></i>
                                                                        </button>
                                                                    </div>

                                                                    <div>
                                                                        <div className="btn-group" style={{ width: "230px" }}>
                                                                            <label className="mt-2 col-md-5">{CPTGROUP}</label>
                                                                            <div style={{ width: "100px" }}>
                                                                                <input
                                                                                    className="form-control mr-sm-2"
                                                                                    name="search"
                                                                                    type="search"
                                                                                    placeholder="Search"
                                                                                    aria-label="Search"
                                                                                    value={filterCptgroupValues.search}
                                                                                    onChange={handleCptgroupFilterValues}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="btn-group" style={{ marginLeft: "10px", width: "230px" }}>
                                                                            <label className="mt-2 col-md-4">{SPECIALTY}</label>
                                                                            <div style={{ width: "100px" }}>
                                                                                <input
                                                                                    className="form-control"
                                                                                    name="SpecialtySearch"
                                                                                    type="search"
                                                                                    placeholder="Search"
                                                                                    aria-label="Search"
                                                                                    value={specialtySearch}
                                                                                    onChange={handleCptgroupSpecialtyFilterValues}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="btn-group">
                                                                <Modal show={showCptgroup} onHide={handleCloseCptgroup}>
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>{show ? "Update CPT Group" : "Add CPT Group"}</Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <label>
                                                                            Specialty
                                                                            <span className="required text-danger"> * </span>
                                                                        </label>
                                                                        {show ? (
                                                                            <Select
                                                                                name="specialtyId"
                                                                                value={cptgroupSpecialtyListOptions && cptgroupSpecialtyListOptions.filter(({ value }) => value == cptgroupInfo.specialtyId)}
                                                                                options={cptgroupSpecialtyListOptions}
                                                                                onChange={handleSpecialtyChange}
                                                                                placeholder={SELECT}
                                                                            ></Select>
                                                                        ) : (
                                                                            <Select name="specialtyId" options={cptgroupSpecialtyListOptions} onChange={handleSpecialtyChange} placeholder={SELECT} />
                                                                        )}

                                                                        <div className="mt-2"></div>

                                                                        <label>
                                                                            Name
                                                                            <span className="required text-danger"> * </span>
                                                                        </label>
                                                                        {show ? (
                                                                            <input type="text" className="form-control" name="name" value={cptgroupInfo && cptgroupInfo.name} onChange={onCptgroupChanged} />
                                                                        ) : (
                                                                            <input type="text" className="form-control" name="name" value={cptgroupValue && cptgroupValue.name} onChange={handleCptgroup} />
                                                                        )}
                                                                        <label>
                                                                            {SORT_PRIORITY}
                                                                        </label>
                                                                        {show ? (
                                                                            <input type="text" className="form-control" name="priorityList" value={cptgroupInfo && cptgroupInfo.priorityList} onChange={onCptgroupChanged} />
                                                                        ) : (
                                                                            <input type="text" className="form-control" name="priorityList" value={cptgroupValue && cptgroupValue.priorityList} onChange={handleCptgroup} />
                                                                        )}
                                                                        <label>{STATUS}</label>
                                                                        {show ? (
                                                                            <select className="form-control col-md-3" name="statusId" value={cptgroupInfo.statusId} onChange={(e) => setCptgroupInfo({ ...cptgroupInfo, statusId: e.target.value })}>
                                                                                {Object.keys(NORMAL_STATUS).map((item, i) => {
                                                                                    return (
                                                                                        <option key={i} value={NORMAL_STATUS[item]}>
                                                                                            {item}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        ) : (
                                                                            <select className="form-control col-md-3" name="statusId" value={cptgroupValue.statusId} onChange={(e) => setCptgroupValue({ ...cptgroupValue, statusId: e.target.value })}>
                                                                                {Object.keys(NORMAL_STATUS).map((item, i) => {
                                                                                    return (
                                                                                        <option key={i} value={NORMAL_STATUS[item]}>
                                                                                            {item}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        )}
                                                                    </Modal.Body>
                                                                    <div className="d-flex justify-content-center mb-3">
                                                                        <div className="center">
                                                                            <Button variant="primary" onClick={show ? handleUpdateCptgroup : handleAddCptgroup}>
                                                                                {show ? UPDATE : ADD}
                                                                            </Button>
                                                                            {isLoading ? (
                                                                                <i
                                                                                    className="fa fa-spinner fa-spin fa-2x"
                                                                                    style={{
                                                                                        position: "relative",
                                                                                        top: "8px",
                                                                                        left: "-43px",
                                                                                        color: "#fff",
                                                                                    }}
                                                                                ></i>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            <Button className="mx-3" variant="default" onClick={show ? handleCancel : handleCloseCptgroup}>
                                                                                {CANCEL}
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </Modal>
                                                            </div>
                                                        </div>
                                                        <div className="table-scrollable" style={cptgroupIsLoading ? { border: "none" } : {}}>
                                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{NAME}</th>
                                                                        <th>{SPECIALTY}</th>
                                                                        <th>{STATUS}</th>
                                                                        <th>{SORT_PRIORITY}</th>
                                                                        <th>{ACTION}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={cptgroupIsLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                                    {cptgroupIsLoading ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        <>
                                                                            {!cptgroupShowList ? (
                                                                                <>{cptgroupIsLoading ? "" : <NoDataFound />}</>
                                                                            ) : (
                                                                                cptgroupShowList &&
                                                                                cptgroupShowList.map((item, index) => {
                                                                                    return (
                                                                                        <tr className="odd gradeX" key={index}>
                                                                                            <td className="left">{item.name}</td>
                                                                                            <td className="left">{item.specialty.name}</td>
                                                                                            <td>{Object.keys(NORMAL_STATUS).find((key) => NORMAL_STATUS[key] === item.statusId)}</td>
                                                                                            <td className="center">{item.priorityList}</td>
                                                                                            <td>
                                                                                                <button className="btn btn-primary btn-xs" onClick={() => handleEditCptgroup(item.id)}>
                                                                                                    <i className="fa fa-pencil"></i>
                                                                                                </button>
                                                                                                <button className="btn btn-danger btn-xs" onClick={() => handleCptgroupDelete(item.id)}>
                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mx-4">
                                                        <div>Total {cptgroupShowList == null ? "0" : cptgroupRowCount} CPT Group </div>
                                                        <div className="pagination-center">
                                                            <ReactPaginate
                                                                initialPage={0}
                                                                pageCount={cptgroupPageCount}
                                                                marginPagesDisplayed={0}
                                                                pageRangeDisplayed={3}
                                                                onPageChange={handleCptgroupPageClick}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                pageLinkClassName="page-link"
                                                                breakLinkClassName="page-link"
                                                                nextLinkClassName="page-link"
                                                                previousLinkClassName="page-link"
                                                                pageClassName="page-item"
                                                                breakClassName="page-item"
                                                                nextClassName="page-item"
                                                                previousClassName="page-item"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"
                                                                disableInitialCallback={true}
                                                                forcePage={currentCptgroupPage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManageSpecialtyCptgroup;
