import axios from "axios";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { baseURL } from "../../Config";
import { LOGOUT_API } from "../../Helper/ApiPath";
import { CHANGE_PASSWORD, EDIT_PROFILE, LOGOUT, ROLES, VIEW_PROFILE } from "../../Helper/Constants";
import Cookies from "universal-cookie";

function EmployeeHeader(props) {
    const logToken = JSON.parse(localStorage.getItem("logToken"));
    const cookies = new Cookies();

    let role = cookies.get("role");
    if (role) {
        role = cookies.get("role").roleId;
    }

    const navigate = useNavigate();

    const logout = () => {
        axios.get(`${baseURL}/${LOGOUT_API}`, { headers: { Authorization: logToken.token } });
        localStorage.clear();
        navigate("/login");
    };

    return (
        <>
            <div className="page-header navbar navbar-fixed-top">
                <div className="page-header-inner ">
                    <div className="page-logo">
                        <a href="/dashboard">
                            <span className="logo-icon ">
                                <img className="img-circle mini-logo1" src="/assets/img/logo/mini_logo1.png" alt="Header-mini-logo" />
                            </span>
                            <span className="logo-default">
                                <img className="header-logo1" src="/assets/img/logo/logo_header.png" alt="Header-logo" />
                            </span>
                        </a>
                    </div>
                    <ul className="nav navbar-nav navbar-left in">
                        <li>
                            <a className="menu-toggler sidebar-toggler">
                                <i className="icon-menu"></i>
                            </a>
                        </li>
                    </ul>
                    <a className="menu-toggler responsive-toggler" data-bs-toggle="collapse" data-bs-target=".navbar-collapse">
                        <span></span>
                    </a>
                    <div className="top-menu">
                        <ul className="nav navbar-nav pull-right">
                            <li className="dropdown dropdown-user">
                                <a className="dropdown-toggle" data-bs-toggle="dropdown" data-hover="dropdown" data-close-others="true">
                                    <img alt="" className="img-circle " src="img/dp.jpg" />
                                    {logToken ? (
                                        <span className="username username-hide-on-mobile">
                                            {" "}
                                            {props.firstName} {props.lastName}
                                        </span>
                                    ) : (
                                        <span className="username username-hide-on-mobile"> Kiran </span>
                                    )}

                                    <i className="fa fa-angle-down"></i>
                                </a>
                                <ul className="dropdown-menu dropdown-menu-default">
                                    <li>
                                        <Link to="/Profile">
                                            <i className="icon-user"></i>
                                            {VIEW_PROFILE}{" "}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/EditProfile">
                                            <i className="icon-user"></i>
                                            {EDIT_PROFILE}{" "}
                                        </Link>
                                    </li>

                                    <li className="divider"> </li>

                                    <li>
                                        <Link to="/changePassword">
                                            <i className="fa fa-key"></i>
                                            {CHANGE_PASSWORD}{" "}
                                        </Link>
                                    </li>

                                    <li>
                                        <a onClick={() => logout()}>
                                            <i className="icon-logout"></i>
                                            {LOGOUT}{" "}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EmployeeHeader;
