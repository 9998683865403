import React from "react";
import { Button, Modal } from "react-bootstrap";
import {
    ADD,
    ADD_QUESTION,
    CANCEL,
    DESCRIPTION,
    DESCRIPTION2,
    DESCRIPTION3,
    GROUP_NAME,
    PRIORITY,
    QUESTION_NAME,
    QUESTION_STATUS,
    SELECT_GROUP,
    SELECT_QUESTION,
    STATUS,
    UPDATE,
    UPDATE_QUESTION,
} from "../../../../Helper/Constants";
import Select from "react-select";

function AddQueAndDescription(props) {
    return (
        <>
            <Modal show={props.show} onHide={props.handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{props.edit ? UPDATE_QUESTION : ADD_QUESTION}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <label className="mt-2 col-md-6">
                            {QUESTION_NAME}
                            <span className="required"> * </span>
                        </label>
                        <Select
                            name="questionName"
                            value={props.QuestionSelectOptions && props.QuestionSelectOptions.filter(({ value }) => value == props.questionId)}
                            options={props.QuestionSelectOptions}
                            onChange={props.handleQuestionChange}
                            placeholder={SELECT_QUESTION}
                        />
                    </div>
                    <div className="form-group row">
                        <label className="mt-2 col-md-6">
                            {GROUP_NAME}
                            <span className="required"> * </span>
                        </label>
                        <Select
                            name="groupName"
                            value={props.groupSelectOptions && props.groupSelectOptions.filter(({ value }) => value == props.groupId)}
                            options={props.groupSelectOptions}
                            onChange={props.handleGroupChange}
                            placeholder={SELECT_GROUP}
                        />
                    </div>
                    <div className="row form-group">
                        <div className="col-md-6">
                            <label className="control-label col-md-6">{PRIORITY}</label>
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    value={props.questionAdd.priorityList}
                                    name="priorityList"
                                    onChange={props.handleQuestionchange}
                                    placeholder="9999"
                                    className="form-control input-height"
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className="mt-2 col-md-6">
                                {STATUS}
                                <span className="required"> * </span>
                            </label>
                            <select className="form-control col-md-3" name="statusId" value={props.questionAdd.statusId} onChange={props.handleQuestionchange}>
                                {Object.keys(QUESTION_STATUS).map((item, i) => {
                                    return (
                                        <option key={i} value={QUESTION_STATUS[item]}>
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-12">
                            <label className="mt-2 col-md-6">
                                {DESCRIPTION}
                                <span className="required"> * </span>
                            </label>
                            <div className="col-md-12">
                                <textarea
                                    type="text"
                                    value={props.questionAdd.description}
                                    name="description"
                                    onChange={props.handleQuestionchange}
                                    placeholder="Enter Description"
                                    rows={4}
                                    cols={16}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-12">
                            <label className="mt-2 col-md-6">
                                {DESCRIPTION2}
                            </label>
                            <div className="col-md-12">
                                <textarea
                                    type="text"
                                    value={props.questionAdd.description2}
                                    name="description2"
                                    onChange={props.handleQuestionchange}
                                    placeholder="Enter Description2"
                                    rows={4}
                                    cols={16}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-12">
                            <label className="mt-2 col-md-6">
                                {DESCRIPTION3}
                            </label>
                            <div className="col-md-12">
                                <textarea
                                    type="text"
                                    value={props.questionAdd.description3}
                                    name="description3"
                                    onChange={props.handleQuestionchange}
                                    placeholder="Enter Description3"
                                    rows={4}
                                    cols={16}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.handleSaveQuestion}>
                        {props.edit ? UPDATE : ADD}
                    </Button>
                    <Button variant="secondary" onClick={props.handleClose}>
                        {CANCEL}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddQueAndDescription;
