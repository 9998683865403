import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { baseURL } from "../../Config";
import {
  DASH,
  DOCTOR_LIST_NOTES,
  DOCTOR_STATUS,
  EMAIL,
  FIRST_NAME,
  HOME,
  IS_TRIAL,
  LAST_NAME,
  NPI1,
  NPI2,
  PHONE_NO,
  STATUS,
  TYPE,
  CURRENT_MONTH_NOTES_COUNT,
  TOTAL_NOTES_COUNT,
  LAST_MONTH_NOTES_COUNT,
  SUBMITTED_DATE,
  SELECT_DOCTOR,
  CREATED_DATE,
} from "../../Helper/Constants";
import { NavLink, useNavigate } from "react-router-dom";
import NoDataFound from "../CommonComponent/NoDataFound";
import Loader from "../CommonComponent/Loader";
import {
  MANAGE_DOCTOR_NOTES_API,
  DOCTOR_DROPDOWN_LIST,
} from "../../Helper/ApiPath";
import DatePicker from "react-datepicker";
import {
  dateAPIFormate,
  dateFormateMonthDateYear,
  defaultTimeZone,
} from "../../Helper/Helper";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import { set } from "lodash";

function ManageDoctorNotes() {
  const navigate = useNavigate();
  const doctorPagination = JSON.parse(
    sessionStorage.getItem("doctorPagination")
  );
  console.log("@doctorNotesList - pagination", doctorPagination);
  const [offset, setOffset] = useState(
    doctorPagination && doctorPagination.offset ? doctorPagination.offset : 1
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [doctorNoteList, setDoctorNoteList] = useState([]);
  const [opNoteCheckBox, setOpNoteCheckBox] = useState(true);
  const [comprehensiveCheckBox, setComprehensiveCheckBox] = useState(false);
  const [preAuthCheckBox, setPreAuthCheckBox] = useState(false);

  const token = JSON.parse(localStorage.getItem("logToken"));
  var date = new Date();
  const [filterValues, setFilterValues] = useState({
    email:
      doctorPagination && doctorPagination.email ? doctorPagination.email : "",
    search:
      doctorPagination && doctorPagination.search
        ? doctorPagination.search
        : "",
    startDate:
      doctorPagination && doctorPagination.startDate
        ? defaultTimeZone(doctorPagination.startDate)
        : new Date(date.setMonth(date.getMonth() - 12)),
    endDate:
      doctorPagination && doctorPagination.endDate
        ? defaultTimeZone(doctorPagination.endDate)
        : new Date(date.setMonth(date.getMonth() + 13)),
  });

  const fetchData = async () => {
    await axios
      .post(`${baseURL}/${MANAGE_DOCTOR_NOTES_API}`, {
        headers: { authorization: token.token },
        page: offset,
        rowsPerPage: 10,
        search: filterValues.search,
        emailSearch: filterValues.email,
        startDate: dateAPIFormate(filterValues.startDate),
        endDate: dateAPIFormate(filterValues.endDate),
        opNoteCheckBox: opNoteCheckBox,
        comprehensiveCheckBox: comprehensiveCheckBox,
        preAuthCheckBox: preAuthCheckBox,
      })
      .then((res) => {
        setIsLoading(false);
        setDoctorNoteList(res.data.data);
        setPageCount(res.data.extraData.pageCount);
        setRowCount(res.data.extraData.rowCount);
        console.log("@doctor notes list", res.data.data);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          if (err.response.status == 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const handleFilterValues = (e) => {
    const { name, value } = e.target;
    setFilterValues({ ...filterValues, [name]: value });
    sessionStorage.setItem(
      "doctorPagination",
      JSON.stringify({
        ...filterValues,
        [name]: value,
        offset: offset,
      })
    );
    setOffset(1);
    setCurrentPage(0);
    sessionStorage.setItem(
      "doctorPagination",
      JSON.stringify({
        ...filterValues,
        email: filterValues.email,
        search: filterValues.search,
        offset: offset,
      })
    );
  };

  const handlePageClick = (event) => {
    sessionStorage.removeItem("doctorPagination");
    const selectedPage = event.selected;
    setOffset(selectedPage + 1);
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    fetchData();
  }, [
    offset,
    filterValues,
    opNoteCheckBox,
    comprehensiveCheckBox,
    preAuthCheckBox,
  ]);

  return (
    <>
      <div className="page-content-wrapper">
        <div className="page-content">
          <div className="page-bar">
            <div className="page-title-breadcrumb">
              <div className=" pull-left">
                <div className="page-title">{DOCTOR_LIST_NOTES}</div>
              </div>
              <ol className="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i className="fa fa-home"></i>&nbsp;
                  <NavLink className="parent-item" to="/dashboard">
                    {HOME}
                  </NavLink>
                  &nbsp;<i className="fa fa-angle-right"></i>
                </li>
                <li className="active">{DOCTOR_LIST_NOTES}</li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="tabbable-line">
                <div className="tab-content">
                  <div className="tab-pane active fontawesome-demo" id="tab1">
                    <div className="row">
                      <div className="col-md-12 ">
                        <div
                          className="card"
                          style={isLoading ? { height: "35rem" } : {}}
                        >
                          <div className="card-body">
                            <div className="row">
                              <div className="justify-content-between">
                                <div className="row">
                                  <div className="col-md-2">
                                    <label className="mt-2">
                                      {CREATED_DATE}
                                    </label>
                                  </div>
                                  <div className="btn-group col-md-2 pr-0">
                                    <DatePicker
                                      className="form-control mr-sm-2"
                                      selected={filterValues.startDate}
                                      maxDate={new Date()}
                                      yearDropdownItemNumber={100}
                                      scrollableMonthDropdown={true}
                                      showYearDropdown
                                      showMonthDropdown
                                      onChange={(date) => {
                                        sessionStorage.setItem(
                                          "doctorPagination",
                                          JSON.stringify({
                                            ...filterValues,
                                            startDate: date,
                                          })
                                        );
                                        setFilterValues({
                                          ...filterValues,
                                          startDate: date,
                                        });
                                      }}
                                    />
                                    <span className="todate">To</span>
                                  </div>
                                  <div className="btn-group col-md-2">
                                    <DatePicker
                                      className="form-control mr-sm-2"
                                      selected={filterValues.endDate}
                                      minDate={filterValues.startDate}
                                      maxDate={new Date().setMonth(
                                        new Date().getMonth() + 1
                                      )}
                                      yearDropdownItemNumber={100}
                                      scrollableMonthDropdown={true}
                                      showYearDropdown
                                      showMonthDropdown
                                      onChange={(date) => {
                                        sessionStorage.setItem(
                                          "doctorPagination",
                                          JSON.stringify({
                                            ...filterValues,
                                            endDate: date,
                                          })
                                        );
                                        setFilterValues({
                                          ...filterValues,
                                          endDate: date,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="col-3">
                                    <div className="row">
                                      <div className="col-3">
                                        <label className="mt-2">Email</label>{" "}
                                      </div>
                                      <div className="col-9">
                                        <input
                                          className="form-control mr-sm-2"
                                          name="email"
                                          value={filterValues.email}
                                          type="search"
                                          placeholder="Search"
                                          aria-label="Search"
                                          onChange={handleFilterValues}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    <div className="row">
                                      <div className="col-3">
                                        <label className="mt-2">Name</label>{" "}
                                      </div>
                                      <div className="col-9">
                                        <input
                                          className="form-control mr-sm-2"
                                          name="search"
                                          value={filterValues.search}
                                          type="search"
                                          placeholder="Search"
                                          aria-label="Search"
                                          onChange={handleFilterValues}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="form-check col-2 mx-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="op_note_checkboxes"
                                  name="checkbox"
                                  label="Op Note"
                                  checked={opNoteCheckBox}
                                  value={opNoteCheckBox}
                                  onClick={(e) => {
                                    setIsLoading(true);
                                    setOpNoteCheckBox(true);
                                    setComprehensiveCheckBox(false);
                                    setPreAuthCheckBox(false);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  for="op_note_checkboxes"
                                >
                                  Op Note
                                </label>
                              </div>
                              <div className="form-check col-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="compregensive_checkboxes"
                                  name="checkbox"
                                  label="Op Note"
                                  checked={comprehensiveCheckBox}
                                  value={comprehensiveCheckBox}
                                  onClick={(e) => {
                                    setIsLoading(true);
                                    setComprehensiveCheckBox(true);
                                    setOpNoteCheckBox(false);
                                    setPreAuthCheckBox(false);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  for="compregensive_checkboxes"
                                >
                                  Comprehensive Note
                                </label>
                              </div>
                              <div className="form-check col-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="preauth_checkboxes"
                                  name="checkbox"
                                  label="Op Note"
                                  checked={preAuthCheckBox}
                                  value={preAuthCheckBox}
                                  onClick={(e) => {
                                    setIsLoading(true);
                                    setPreAuthCheckBox(true);
                                    setOpNoteCheckBox(false);
                                    setComprehensiveCheckBox(false);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  for="preauth_checkboxes"
                                >
                                  PreAuthorization Note
                                </label>
                              </div>
                            </div>

                            <div
                              className="table-scrollable"
                              style={isLoading ? { border: "none" } : {}}
                            >
                              <table
                                className="table table-hover table-checkable order-column full-width"
                                id="example4"
                              >
                                <thead>
                                  <tr>
                                    <th>{EMAIL}</th>
                                    <th>{FIRST_NAME}</th>
                                    <th>{LAST_NAME}</th>
                                    <th>{PHONE_NO}</th>
                                    <th>{NPI1}</th>
                                    <th>{NPI2}</th>
                                    <th>{TYPE}</th>
                                    <th>{STATUS}</th>
                                    {opNoteCheckBox && <th>{"OP Notes"}</th>}
                                    {comprehensiveCheckBox && (
                                      <th>{"Comprehensive Notes"}</th>
                                    )}
                                    {preAuthCheckBox && (
                                      <th>{"PreAuthorization Notes"}</th>
                                    )}
                                  </tr>
                                </thead>
                                <tbody
                                  style={
                                    isLoading
                                      ? {
                                          borderStyle: "none",
                                          borderColor: "#fff",
                                        }
                                      : {}
                                  }
                                >
                                  {isLoading ? (
                                    <>
                                      <Loader />
                                    </>
                                  ) : (
                                    <>
                                      {!doctorNoteList ? (
                                        <>{isLoading ? "" : <NoDataFound />}</>
                                      ) : (
                                        doctorNoteList &&
                                        doctorNoteList.map((item, index) => {
                                          return (
                                            <tr
                                              className="odd gradeX"
                                              key={index}
                                            >
                                              <td className="left">
                                                {item.user && item.user.email
                                                  ? item.user.email
                                                  : item.createUser &&
                                                    item.createUser.email
                                                  ? item.createUser.email
                                                  : DASH}
                                              </td>
                                              <td className="left">
                                                {item.firstName}
                                              </td>
                                              <td className="left">
                                                {item.lastName}
                                              </td>
                                              <td className="left">
                                                {item.phoneNo1
                                                  ? item.phoneNo1
                                                  : DASH}
                                              </td>
                                              <td className="left">
                                                {item.npi1 ? item.npi1 : DASH}
                                              </td>
                                              <td className="left">
                                                {item.npi2 ? item.npi2 : DASH}
                                              </td>
                                              <td className="left">
                                                <span
                                                  className={
                                                    "badge " +
                                                    (item.isTrial == 1
                                                      ? "deactiveStatus badge-warning"
                                                      : item.isTrial == 0
                                                      ? "activeStatus badge-success"
                                                      : "badge-dangerdeactiveStatus badge-warning")
                                                  }
                                                >
                                                  {Object.keys(IS_TRIAL).find(
                                                    (key) =>
                                                      IS_TRIAL[key] ==
                                                      item.isTrial
                                                  )}
                                                </span>
                                              </td>
                                              <td className="left">
                                                <span
                                                  className={
                                                    "badge " +
                                                    (item.statusId == 2
                                                      ? "deactiveStatus badge-warning"
                                                      : item.statusId == 1
                                                      ? "activeStatus badge-success"
                                                      : "badge-danger")
                                                  }
                                                >
                                                  {Object.keys(
                                                    DOCTOR_STATUS
                                                  ).find(
                                                    (key) =>
                                                      DOCTOR_STATUS[key] ===
                                                      item.statusId
                                                  )}
                                                </span>
                                              </td>
                                              {opNoteCheckBox && (
                                                <td>
                                                  <div>{item.opNotes}</div>
                                                </td>
                                              )}
                                              {comprehensiveCheckBox && (
                                                <td>
                                                  <div>
                                                    {item.comprehensiveNotes}
                                                  </div>
                                                </td>
                                              )}
                                              {preAuthCheckBox && (
                                                <td>
                                                  <div>
                                                    {item.preAuthorizationNotes}
                                                  </div>
                                                </td>
                                              )}
                                            </tr>
                                          );
                                        })
                                      )}
                                    </>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between mx-4">
                            <div>
                              Total {doctorNoteList == null ? "0" : rowCount}{" "}
                              Doctors
                            </div>
                            <div className="pagination-center">
                              <ReactPaginate
                                initialPage={0}
                                pageCount={pageCount}
                                marginPagesDisplayed={0}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName="pagination"
                                activeClassName="active"
                                pageLinkClassName="page-link"
                                breakLinkClassName="page-link"
                                nextLinkClassName="page-link"
                                previousLinkClassName="page-link"
                                pageClassName="page-item"
                                breakClassName="page-item"
                                nextClassName="page-item"
                                previousClassName="page-item"
                                previousLabel="Previous"
                                nextLabel="Next"
                                disableInitialCallback={true}
                                forcePage={
                                  doctorPagination && doctorPagination.offset
                                    ? doctorPagination.offset - 1
                                    : currentPage
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ManageDoctorNotes;
