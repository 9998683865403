import { useEffect, useState } from "react";
import {
    ADDRESS,
    CANCEL,
    CITY,
    EDIT_OFFICE,
    HOME,
    OFFICE_INFORMATION,
    OFFICE_LIST,
    OFFICE_NAME,
    OFFICE_STATUS,
    PHONE_LENGTH,
    PHONE_NO,
    SELECT,
    STATE,
    STATUS,
    UPDATE,
    ZIP,
} from "../../Helper/Constants";
import axios from "axios";
import { baseURL } from "../../Config";
import { toast } from "react-toastify";
import { ADMIN_DETAIL_OFFICE, ADMIN_EDIT_OFFICE, CITY_LIST_WITHOUT_PAGINATION, STATE_LIST_WITHOUT_PAGINATION } from "../../Helper/ApiPath";
import { ENTER_ADDRESS, ENTER_CITY, ENTER_OFFICE_NAME, ENTER_PHONE, ENTER_STATE, ENTER_ZIP, PHONE_NUMBER } from "../../Helper/Messages";
import { NavLink, useNavigate } from "react-router-dom";
import Select from "react-select";

function AdminEditOffice() {
    const [isLoading, setIsLoading] = useState(false);

    const [city, setCity] = useState({
        stateId: "",
        cityId: "",
    });

    const handleCityChange = (e) => {
        setCity({ ...city, cityId: e.value });
    };

    const [office, setOffice] = useState({
        name: "",
        phoneNo1: "",
        address: "",
        zip: "",
        statusId: "",
    });

    const officeId = localStorage.getItem("officeId");
    const token = JSON.parse(localStorage.getItem("logToken"));
    let navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setOffice({ ...office, [name]: value });
    };

    const fetchData = async () => {
        await axios
            .get(`${baseURL}/${ADMIN_DETAIL_OFFICE}/${officeId}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                if (res.status === 200) {
                    setOffice(res.data.data);
                    setCity(res.data.data);
                    setStateId(res.data.data.stateId);
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const handleUpdate = async (e) => {
        e.preventDefault();
        const token = JSON.parse(localStorage.getItem("logToken"));

        if (!office.name) {
            toast.error(ENTER_OFFICE_NAME);
        } else if (!office.phoneNo1) {
            toast.error(ENTER_PHONE);
        } else if (!office.address) {
            toast.error(ENTER_ADDRESS);
        } else if (!city.stateId) {
            toast.error(ENTER_STATE);
        } else if (!city.cityId) {
            toast.error(ENTER_CITY);
        } else if (!office.zip) {
            toast.error(ENTER_ZIP);
        } else if (office.phoneNo1.length > PHONE_LENGTH) {
            toast.error(PHONE_NUMBER);
        } else {
            setIsLoading(true);
            await axios
                .post(`${baseURL}/${ADMIN_EDIT_OFFICE}`, {
                    headers: { authorization: token.token },
                    officeId: officeId,
                    name: office.name,
                    phoneNo1: office.phoneNo1,
                    address: office.address,
                    statusId: office.statusId,
                    state: city.stateId,
                    city: city.cityId,
                    zip: office.zip,
                })
                .then((res) => {
                    if (res.status === 200) {
                        toast.success(res.data.message);
                    }
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false);
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const handleCancel = () => {
        navigate("/manageOffice");
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    ////////////////////////////////////////////////////// STATE-CITY LIST /////////////////////
    const [stateList, setStateList] = useState([]);

    const stateData = async () => {
        await axios
            .post(`${baseURL}/${STATE_LIST_WITHOUT_PAGINATION}`)
            .then((res) => {
                setStateList(res.data.data);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };

    const [cityList, setCityList] = useState([]);
    const [stateId, setStateId] = useState([]);

    const handleStateChange = (e) => {
        setCity({ ...city, stateId: e.value, cityId: "" });
        setCityList("");
        cityData();
        setStateId(e.value);
    };

    const cityData = async () => {
        axios
            .post(`${baseURL}/${CITY_LIST_WITHOUT_PAGINATION}`, {
                stateId: stateId,
                search: "",
            })
            .then((res) => {
                setCityList(res.data.data);
            });
    };

    const stateOptions =
        stateList &&
        stateList.map((item, i) => {
            return {
                id: item.id,
                value: item.id,
                label: item.name,
            };
        });

    const cityOptions =
        cityList &&
        cityList.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    useEffect(() => {
        stateData();
        cityData();
    }, [stateId]);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{EDIT_OFFICE}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li>
                                    <i className="fa fa-briefcase"></i>&nbsp;
                                    <NavLink className="parent-item" to="/manageOffice">
                                        {OFFICE_LIST}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{EDIT_OFFICE}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="card card-box">
                                <div className="card-head">
                                    <header>{OFFICE_INFORMATION}</header>
                                </div>
                                <div className="card-body" id="bar-parent">
                                    <form action="#" id="form_sample_1" className="form-horizontal">
                                        <div className="form-body">
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {OFFICE_NAME}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        value={office && office.name}
                                                        onChange={handleChange}
                                                        data-required="1"
                                                        placeholder="Enter Office Name"
                                                        className="form-control input-height"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {PHONE_NO}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <input
                                                        name="phoneNo1"
                                                        value={office && office.phoneNo1}
                                                        onChange={handleChange}
                                                        type="number"
                                                        placeholder="Mobile Number"
                                                        className="form-control input-height"
                                                    />{" "}
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {STATUS}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <select className="form-control input-height" name="statusId" value={office && office.statusId} onChange={handleChange}>
                                                        <option value=""> {SELECT}</option>
                                                        {Object.keys(OFFICE_STATUS).map((item, i) => {
                                                            return (
                                                                <option key={i} value={OFFICE_STATUS[item]}>
                                                                    {item}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {ADDRESS}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <input
                                                        name="address"
                                                        value={office && office.address}
                                                        onChange={handleChange}
                                                        placeholder="Address"
                                                        className="form-control input-height"
                                                        // rows="5"
                                                    ></input>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {STATE}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <Select
                                                        name="stateId"
                                                        value={stateOptions && stateOptions.filter(({ value }) => value == city.stateId)}
                                                        options={stateOptions}
                                                        onChange={handleStateChange}
                                                        placeholder={SELECT}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {CITY}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <Select
                                                        name="cityId"
                                                        value={cityOptions && cityOptions.filter(({ value }) => value == city.cityId)}
                                                        options={cityOptions}
                                                        onChange={handleCityChange}
                                                        placeholder={SELECT}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {ZIP}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <input name="zip" value={office && office.zip} onChange={handleChange} type="text" placeholder="Zip" className="form-control input-height" />
                                                </div>
                                            </div>
                                            <div className="form-actions">
                                                <div className="row">
                                                    <div className="offset-md-3 col-md-9">
                                                        {isLoading ? (
                                                            <>
                                                                <button type="submit" className="btn btn-primary m-r-20" disabled>
                                                                    {UPDATE}
                                                                </button>
                                                                <i className="fa fa-spinner fa-spin fa-2x loaderUpdateBtnSpinner"></i>
                                                            </>
                                                        ) : (
                                                            <button type="submit" className="btn btn-primary m-r-20" onClick={handleUpdate}>
                                                                {UPDATE}
                                                            </button>
                                                        )}
                                                        <button type="button" className="btn btn-default m-r-20" onClick={handleCancel}>
                                                            {CANCEL}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminEditOffice;
