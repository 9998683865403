import React, { useEffect, useState } from "react";
import { HOME, CREATED_DATE, DASH, ROWS_PER_PAGE, ACTION, ADD, DOWNLOAD, NO, TITLE, DESCRIPTION, STATUS, DOCUMENT_STATUS, DOCUMENT_LIST, ALL, ROLES } from "../../Helper/Constants";
import { ADMIN_DEVELOP_DOCUMENT_DETAIL_API, UPLOAD_DOCUMENT_LIST_API, DOCUMENT_LIST_API, DEVELOP_DOCUMENT_DETAIL_API } from "../../Helper/ApiPath";
import { dateFormateMonthDateYear } from "../../Helper/Helper";
import axios from "axios";
import { baseURL } from "../../Config";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { NavLink, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import NoDataFound from "../CommonComponent/NoDataFound";
import { DELETE_DOCUMENT_MESSAGE } from "../../Helper/Messages";
import Loader from "../CommonComponent/Loader";
import DownloadDocumentFiles from "../Forms/Documents/DownloadDocumentFiles";
import Cookies from "universal-cookie";
import ViewDocument from "../Forms/Documents/ViewDocument";

function ManageDocuments() {
    const cookies = new Cookies();
    let role = cookies.get("role");
    if (role) {
        role = cookies.get("role").roleId;
    }
    let adminRole = localStorage.getItem("adminRole");

    const [currentPage, setCurrentPage] = useState(0);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [DocumentList, setDocumentList] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [show, setShow] = useState(false);
    let token = JSON.parse(localStorage.getItem("logToken"));
    const [filterValues, setfilterValues] = useState({
        title: "",
        statusId: "",
    });

    const handleFilterValues = (e) => {
        const { name, value } = e.target;
        setfilterValues({ ...filterValues, [name]: value });
        setOffset(1);
        setCurrentPage(0);
    };

    const handleShow = (id) => {
        setShow(true);
        fetchThumbnailData(id);
    };

    const [view, setView] = useState(false);
    const [Document, setDocument] = useState({
        title: "",
        description: "",
    });

    const detailDocument = async (DocumentId) => {
        await axios
            .get(`${baseURL}/${DEVELOP_DOCUMENT_DETAIL_API}/${DocumentId}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                setDocument(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleView = (id) => {
        setView(true);
        detailDocument(id);
    };

    const handleClose = () => {
        setView(false);
        setShow(false);
    };

    const [DocumentFile, setDocumentFile] = useState([]);

    const fetchThumbnailData = async (developId) => {
        await axios
            .post(`${baseURL}/${UPLOAD_DOCUMENT_LIST_API}`, {
                headers: { authorization: token.token },
                developId: developId,
            })
            .then((res) => {
                setDocumentFile(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleClick = () => {
        navigate("/addDocument");
    };

    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${DOCUMENT_LIST_API}`, {
                headers: { authorization: token.token },
                statusId: role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? 1 : filterValues.statusId,
                title: filterValues.title,
                page: offset,
                rowsPerPage: ROWS_PER_PAGE,
            })
            .then((res) => {
                setIsLoading(false);
                setDocumentList(res.data.data);
                setRowCount(res.data.extraData.rowCount);
                setPageCount(res.data.extraData.pageCount);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status === 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleEdit = (id) => {
        localStorage.setItem("DocumentId", id);
        navigate("/editDocument");
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: DELETE_DOCUMENT_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/${ADMIN_DEVELOP_DOCUMENT_DETAIL_API}/${id}`, {
                            headers: { authorization: token.token },
                        })
                        .then((res) => {
                            Swal.fire(res.data.message, " ", "success");
                            fetchData();
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status === 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    useEffect(() => {
        fetchData();
    }, [offset, filterValues]);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{DOCUMENT_LIST}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{DOCUMENT_LIST}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tabbable-line">
                                <div className="tab-content">
                                    <div className="tab-pane active fontawesome-demo" id="tab1">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card" style={isLoading ? { height: "35rem" } : {}}>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-1 mr-sm-2">
                                                                {role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? (
                                                                    ""
                                                                ) : (
                                                                    <button id="addRow" className="btn btn-info" onClick={handleClick}>
                                                                        {ADD} <i className="fa fa-plus"></i>
                                                                    </button>
                                                                )}
                                                            </div>
                                                            <div className="btn-group col-7 pr-0"></div>

                                                            <div className="col-4">
                                                                <div className="row">
                                                                    <div className={`${role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? "offset-6" : ""} col-6`}>
                                                                        <input
                                                                            className="form-control"
                                                                            name="title"
                                                                            type="search"
                                                                            placeholder="Search"
                                                                            aria-label="Search"
                                                                            value={filterValues.title}
                                                                            onChange={handleFilterValues}
                                                                        />
                                                                    </div>
                                                                    {role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? (
                                                                        ""
                                                                    ) : (
                                                                        <div className="col-6">
                                                                            <div className="row d-flex justify-content-end">
                                                                                <div className="col-4 d-flex justify-content-end mt-2">
                                                                                    <label className="">{STATUS}</label>
                                                                                </div>
                                                                                <div className="col-8">
                                                                                    <select
                                                                                        className="form-control col-md-3"
                                                                                        name="statusId"
                                                                                        value={filterValues.statusId}
                                                                                        onChange={handleFilterValues}
                                                                                    >
                                                                                        <option value="">{ALL}</option>
                                                                                        {Object.keys(DOCUMENT_STATUS).map((item, i) => {
                                                                                            return (
                                                                                                <option key={i} value={DOCUMENT_STATUS[item]}>
                                                                                                    {item}
                                                                                                </option>
                                                                                            );
                                                                                        })}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="table-scrollable" style={isLoading ? { border: "none" } : {}}>
                                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{NO}</th>
                                                                        <th>{TITLE}</th>
                                                                        <th>{DESCRIPTION}</th>
                                                                        <th>{CREATED_DATE}</th>
                                                                        {role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? "" : <th>{STATUS}</th>}
                                                                        <th>{DOWNLOAD}</th>
                                                                        {role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? "" : <th>{ACTION}</th>}
                                                                    </tr>
                                                                </thead>
                                                                <DownloadDocumentFiles show={show} handleShow={handleShow} handleClose={handleClose} DocumentFile={DocumentFile} />
                                                                <ViewDocument view={view} Document={Document} handleClose={handleClose} />
                                                                <tbody style={isLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                                    {isLoading ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        <>
                                                                            {!DocumentList ? (
                                                                                <NoDataFound />
                                                                            ) : (
                                                                                DocumentList &&
                                                                                DocumentList.map((item, index) => {
                                                                                    return (
                                                                                        <tr className="odd gradeX">
                                                                                            <td> {(offset - 1) * ROWS_PER_PAGE + index + 1} </td>
                                                                                            <td>{item.title ? item.title : DASH}</td>
                                                                                            <td>
                                                                                                <div data-toggle="tooltip" title={item.description}>
                                                                                                    {item.description
                                                                                                        ? item.description.length > 70
                                                                                                            ? item.description.slice(0, 70) + "..."
                                                                                                            : item.description
                                                                                                        : DASH}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>{item.created_at ? dateFormateMonthDateYear(item.created_at) : DASH}</td>
                                                                                            {role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td className="left">
                                                                                                    <span
                                                                                                        className={
                                                                                                            "badge " +
                                                                                                            (item.statusId == 2
                                                                                                                ? "deactiveStatus badge-warning"
                                                                                                                : item.statusId == 1
                                                                                                                ? "activeStatus badge-success"
                                                                                                                : "badge-danger")
                                                                                                        }
                                                                                                    >
                                                                                                        {Object.keys(DOCUMENT_STATUS).find((key) => DOCUMENT_STATUS[key] === item.statusId)}
                                                                                                    </span>
                                                                                                </td>
                                                                                            )}
                                                                                            <td>
                                                                                                <button
                                                                                                    className={`btn btn-success ${role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? "btn-xs" : ""}`}
                                                                                                    onClick={() => handleShow(item.id)}
                                                                                                >
                                                                                                    {role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? "" : DOWNLOAD}{" "}
                                                                                                    <i className="fa fa-download"></i>
                                                                                                </button>
                                                                                                {role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? (
                                                                                                    <button className="btn btn-warning btn-xs" onClick={() => handleView(item.id)}>
                                                                                                        <i className="fa fa-eye"></i>
                                                                                                    </button>
                                                                                                ) : (
                                                                                                    ""
                                                                                                )}
                                                                                            </td>

                                                                                            {role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td>
                                                                                                    <div className="btn btn-primary btn-xs" onClick={() => handleEdit(item.id)}>
                                                                                                        <i className="fa fa-pencil"></i>
                                                                                                    </div>

                                                                                                    <button className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
                                                                                                        <i className="fa fa-trash-o"></i>
                                                                                                    </button>
                                                                                                </td>
                                                                                            )}
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mx-4">
                                                        <div>Total {DocumentList == null ? "0" : rowCount} Documents</div>
                                                        <div className="pagination-center">
                                                            <ReactPaginate
                                                                initialPage={0}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={0}
                                                                pageRangeDisplayed={3}
                                                                onPageChange={handlePageClick}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                pageLinkClassName="page-link"
                                                                breakLinkClassName="page-link"
                                                                nextLinkClassName="page-link"
                                                                previousLinkClassName="page-link"
                                                                pageClassName="page-item"
                                                                breakClassName="page-item"
                                                                nextClassName="page-item"
                                                                previousClassName="page-item"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"
                                                                disableInitialCallback={true}
                                                                forcePage={currentPage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManageDocuments;
