import axios from "axios";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../Config";
import { CHANGE_ADMIN_PASSWORD_API } from "../../Helper/ApiPath";
import { CHANGE_PASSWORD, CONFIRM, CONFIRM_PASSWORD, HOME, NEW_PASSWORD, OLD_PASSWORD } from "../../Helper/Constants";
import { ENTER_NEW_PASSWORD, ENTER_OLD_PASSWORD, PASSWORD_DOES_NOT_MATCH, PASSWORD_ERROR_TEXT } from "../../Helper/Messages";

function AdminChangePassword() {
    const token = JSON.parse(localStorage.getItem("logToken"));
    const [oldPasswordShown, setOldPasswordShown] = useState(false);
    const [newPasswordShown, setNewPasswordShown] = useState(false);
    const [conPasswordShown, setConPasswordShown] = useState(false);
    const [pwdError, setPwdError] = useState(false);
    const [password, setPassword] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    });
    const navigate = useNavigate();
    const toggleOldPassword = () => {
        setOldPasswordShown(!oldPasswordShown);
    };

    const toggleNewPassword = () => {
        setNewPasswordShown(!newPasswordShown);
    };

    const toggleConPassword = () => {
        setConPasswordShown(!conPasswordShown);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPassword({ ...password, [name]: value });
    };

    const handleClick = () => {
        const validPassword = new RegExp("^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");

        if (!password.oldPassword) {
            toast.error(ENTER_OLD_PASSWORD);
        } else if (!password.newPassword) {
            toast.error(ENTER_NEW_PASSWORD);
        } else if (!validPassword.test(password.newPassword)) {
            setPwdError(true);
        } else if (password.newPassword !== password.confirmPassword) {
            toast.error(PASSWORD_DOES_NOT_MATCH);
        } else {
            axios
                .post(`${baseURL}/${CHANGE_ADMIN_PASSWORD_API}`, {
                    headers: { authorization: token.token },
                    oldPassword: password.oldPassword,
                    newPassword: password.newPassword,
                })
                .then((res) => {
                    toast.success(res.data.message);
                    setPassword({
                        oldPassword: "",
                        newPassword: "",
                        confirmPassword: "",
                    });
                    setPwdError(false);
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };
    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">Admin {CHANGE_PASSWORD}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{CHANGE_PASSWORD}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-6 col-sm-6">
                            <div className="card card-box">
                                <div className="card-head">
                                    <header>{CHANGE_PASSWORD}</header>
                                </div>
                                <div className="card-body " id="bar-parent">
                                    <form>
                                        <div className="form-group">
                                            <i className="fa fa-lock font-left " aria-hidden="true"></i>
                                            <span>{OLD_PASSWORD}</span>
                                            <span className="requireField"> * </span>
                                            <div style={{ display: "flex" }}>
                                                <input
                                                    type={oldPasswordShown ? "text" : "password"}
                                                    className="form-control"
                                                    id="txtemail"
                                                    name="oldPassword"
                                                    placeholder="Enter Old Password"
                                                    value={password.oldPassword}
                                                    onChange={handleChange}
                                                />
                                                <i className={oldPasswordShown ? "input-group-text fa fa-eye" : "input-group-text fa fa-eye-slash"} id="basic-addon1" onClick={toggleOldPassword}></i>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <i className="fa fa-lock font-left " aria-hidden="true"></i>
                                            <span>{NEW_PASSWORD}</span>
                                            <span className="requireField"> * </span>
                                            <div style={{ display: "flex" }}>
                                                <input
                                                    type={newPasswordShown ? "text" : "password"}
                                                    className="form-control"
                                                    id="password"
                                                    name="newPassword"
                                                    placeholder="Enter Password"
                                                    value={password.newPassword}
                                                    onChange={handleChange}
                                                />
                                                <i className={newPasswordShown ? "input-group-text fa fa-eye" : "input-group-text fa fa-eye-slash"} id="basic-addon1" onClick={toggleNewPassword}></i>
                                            </div>

                                            {pwdError === false ? <p style={{ fontSize: "10px", color: "grey" }}>{PASSWORD_ERROR_TEXT}</p> : <p className="txtError">{PASSWORD_ERROR_TEXT}</p>}
                                        </div>

                                        <div className="form-group">
                                            <i className="fa fa-lock font-left " aria-hidden="true"></i>
                                            <span>{CONFIRM_PASSWORD}</span>
                                            <span className="requireField"> * </span>

                                            <div style={{ display: "flex" }}>
                                                <input
                                                    type={conPasswordShown ? "text" : "password"}
                                                    className="form-control"
                                                    id="password"
                                                    name="confirmPassword"
                                                    placeholder="Confirm Password"
                                                    value={password.confirmPassword}
                                                    onChange={handleChange}
                                                />
                                                <i className={conPasswordShown ? "input-group-text fa fa-eye" : "input-group-text fa fa-eye-slash"} id="basic-addon1" onClick={toggleConPassword}></i>
                                            </div>
                                        </div>

                                        <div className="container-login100-form-btn">
                                            <button type="button" className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-success" onClick={handleClick}>
                                                {CONFIRM}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminChangePassword;
