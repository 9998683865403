import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../Config";
import {
    ADD,
    ACTION,
    HOME,
    CANCEL,
    UPDATE,
    PROCEDURE_STATUS,
    CODE,
    FEE,
    DESCRIPTION,
    STATUS,
    TYPE,
    DOCTOR_SPECIALTY,
    MANAGE_PROCEDURE,
    ROWS_PER_PAGE,
    SORT_PRIORITY,
    DASH,
    ALL,
    PROCEDURE_CODE,
    SELECT,
} from "../../Helper/Constants";
import { Modal, Button } from "react-bootstrap";

import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { NavLink, useNavigate } from "react-router-dom";
import { PROCEDURE_LIST_ADMIN_API, ADMIN_DETAIL_PROCEDURE, ADD_PROCEDURE, ADMIN_EDIT_PROCEDURE, SPECIALTY_LIST_WITHOUT_PAGINATION, CPTGROUP_LIST_WITHOUT_PAGINATION } from "../../Helper/ApiPath";
import { DELETE_MESSAGE, ENTER_PRIORITY_LESS_THAN_MAX_PRIORITY, MAX_PRIORITY } from "../../Helper/Messages";
import NoDataFound from "../CommonComponent/NoDataFound";
import Swal from "sweetalert2";
import Loader from "../CommonComponent/Loader";
import Select from "react-select";

function ManageProcedure() {
    const [currentPage, setCurrentPage] = useState(0);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [procedureList, setProcedureList] = useState([]);
    const [offset, setOffset] = useState(1);
    const [rowCount, setRowCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [filterPriorityValue, setFilterPriorityValues] = useState();
    const [filterstatusValue, setFilterstatusValues] = useState();
    const [filterspecialityValue, setFilterspecialityValues] = useState();
    const [filterTypeValue, setFilterTypeValue] = useState();
    const [filterCodeValues, setFilterCodeValues] = useState();

    const handleFilterCodeValues = (e) => {
        setFilterCodeValues(e.target.value);
        setOffset(1);
        setCurrentPage(0);
    };

    const handleFilterStatusValue = (e) => {
        setFilterstatusValues(e.target.value);
        setOffset(1);
        setCurrentPage(0);
    };
    const handleFilterSpecialityValue = (e) => {
        setFilterspecialityValues(e.value);
        setFilterTypeValue("");
        cptgroupData(e.value);
        setOffset(1);
        setCurrentPage(0);
    };

    const handleFilterPriorityValues = (e) => {
        setFilterPriorityValues(e.target.value);
    };

    const handleFilterTypeValue = (e) => {
        setFilterTypeValue(e.value);
        setOffset(1);
        setCurrentPage(0);
    };
    const token = JSON.parse(localStorage.getItem("logToken"));

    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${PROCEDURE_LIST_ADMIN_API}`, {
                headers: { authorization: token.token },
                page: offset,
                rowsPerPage: ROWS_PER_PAGE,
                statusId: filterstatusValue,
                specialityId: filterspecialityValue,
                type: filterTypeValue,
                search: filterCodeValues,
            })
            .then((res) => {
                setIsLoading(false);
                setProcedureList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    let addSelectText = [
        {
            id: "",
            name: SELECT,
        },
    ];
    const [specialtyId, setSpecialtyId] = useState([]);
    const [specialtyList, setSpecialtyList] = useState([]);

    const specialtyData = async () => {
        await axios
            .post(`${baseURL}/${SPECIALTY_LIST_WITHOUT_PAGINATION}`)
            .then((res) => {
                setSpecialtyList(res.data.data ? addSelectText.concat(res.data.data) : addSelectText);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };
    
    const [cptgroup, setCptgroup] = useState({
        specialtyId: "",
        cptgroupId: "",
    });
    const [cptgroupList, setCptgroupList] = useState([]);
    const handleCptgroupChange = (e) => {
        setCptgroup({ ...cptgroup, cptgroupId: e.value });
    };

    const specialtyOptions =
        specialtyList &&
        specialtyList.map((item, i) => {
            return {
                id: item.id,
                value: item.id,
                label: item.name,
            };
        });

    const handleSpecialtyChange = (e) => {
        setCptgroup({ ...cptgroup, specialtyId: e.value, cptgroupId: "" });
        setCptgroupList("");
        cptgroupData(e.value);
        setSpecialtyId(e.value);
    };
    
    const cptgroupData = async (specialtyId) => {
        axios
            .post(`${baseURL}/${CPTGROUP_LIST_WITHOUT_PAGINATION}`, {
                specialtyId: specialtyId,
                search: "",
            })
            .then((res) => {
                setCptgroupList(res.data.data ? addSelectText.concat(res.data.data) : addSelectText);
            });
    };

    const cptgroupOptions =
        cptgroupList &&
        cptgroupList.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    useEffect(() => {
        fetchData();
        specialtyData();
        // cptgroupData();
    }, [offset, filterstatusValue, filterspecialityValue, filterTypeValue, filterCodeValues, specialtyId]);

    const [showProcedure, setShowProcedure] = useState(false);
    const handleCloseProMdl = () => setShowProcedure(false);
    const [editProStatus, setEditProStatus] = useState(false);
    const [procedure, setProcedure] = useState({
        // type: "",
        code: "",
        statusId: "",
        // specialityId: "",
        description: "",
        fee: "",
        priorityList: MAX_PRIORITY,
    });
    const [proLoader, setProLoader] = useState(false);
    const handleShowProcedure = (type) => {
        if (type === "add") {
            setShowProcedure(true);
            setCptgroup("");
            setSpecialtyId("");
            setProcedure({
                statusId: "",
                code: "",
                description: "",
                fee: "",
                priorityList: MAX_PRIORITY,
            });
            setEditProStatus(false);
        } else {
            setShowProcedure(true);
        }
        setProLoader(false);
    };
    const handleAddSubmit = async () => {
        if (!proLoader) {
            if (procedure.fee == "" || procedure.fee <= 0) {
                toast.error("Enter procedure fee");
                return false;
            } else if (!cptgroup || cptgroup.specialtyId == "") {
                toast.error("Select speciality type");
                return false;
            } else if (!cptgroup || cptgroup.cptgroupId == "") {
                toast.error("Select procedure type");
                return false;
            } else if (procedure.code == "") {
                toast.error("Enter procedure code");
                return false;
            } else if (procedure.description == "") {
                toast.error("Enter procedure description");
                return false;
            } else if (procedure.priorityList && procedure.priorityList > MAX_PRIORITY) {
                toast.error(ENTER_PRIORITY_LESS_THAN_MAX_PRIORITY);
            } else {
                setProLoader(true);
                await axios
                    .post(`${baseURL}/${ADD_PROCEDURE}`, {
                        headers: { authorization: token.token },
                        type: cptgroup.cptgroupId,
                        specialityId: cptgroup.specialtyId,
                        code: procedure.code,
                        description: procedure.description,
                        fee: procedure.fee,
                        priorityList: procedure.priorityList,
                    })
                    .then((res) => {
                        setProLoader(false);
                        if (res.status === 200) {
                            setProcedure({
                                type: "",
                                specialityId: "",
                                code: "",
                                description: "",
                                fee: "",
                                priorityList: MAX_PRIORITY,
                            });
                            toast.success("Procedure has been added successfully..");
                            handleCloseProMdl(false);
                            fetchData();
                        }
                    })
                    .catch((err) => {
                        setProLoader(false);
                        if (err.response) {
                            if (err.response.status == 500) {
                                localStorage.clear();
                                navigate("/login");
                            } else {
                                toast.error(err.response.data.message);
                            }
                        }
                    });
            }
        }
    };

    const handleEditPro = async (id, specialtyId) => {
        handleShowProcedure(true);
        setEditProStatus(true);
        detailProcedure(id);
        cptgroupData(specialtyId);
    };
    const detailProcedure = async (id) => {
        await axios
            .get(`${baseURL}/${ADMIN_DETAIL_PROCEDURE}/${id}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                if (res.status === 200) {
                    setProcedure(res.data.data);
                    setSpecialtyId(res.data.data.specialtyId);
                    setCptgroup({specialtyId: res.data.data.specialityId, cptgroupId: res.data.data.type});
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const handleUpdate = async () => {
        if (!proLoader) {
            const token = JSON.parse(localStorage.getItem("logToken"));
            if (procedure.fee == "" || procedure.fee <= 0) {
                toast.error("Enter procedure fee");
                return false;
            }
            if (cptgroup.specialtyId == "") {
                toast.error("Select speciality type");
                return false;
            }
            if (cptgroup.cptgroupId == "") {
                toast.error("Select procedure type");
                return false;
            }
            if (procedure.code == "") {
                toast.error("Enter procedure code");
                return false;
            }
            if (procedure.description == "") {
                toast.error("Enter procedure description");
                return false;
            }
            if (procedure.priorityList && procedure.priorityList > MAX_PRIORITY) {
                toast.error(ENTER_PRIORITY_LESS_THAN_MAX_PRIORITY);
            } else {
                setProLoader(true);
                await axios
                    .post(`${baseURL}/${ADMIN_EDIT_PROCEDURE}`, {
                        headers: { authorization: token.token },
                        procedureId: procedure.id,
                        fee: procedure.fee,
                        code: procedure.code,
                        statusId: procedure.statusId,
                        description: procedure.description,
                        type: cptgroup.cptgroupId,
                        specialityId: cptgroup.specialtyId,
                        priorityList: procedure.priorityList,
                    })
                    .then((res) => {
                        setProLoader(false);
                        if (res.status === 200) {
                            setProcedure({
                                type: "",
                                code: "",
                                description: "",
                                fee: "",
                                priorityList: MAX_PRIORITY,
                            });
                            toast.success(res.data.message);
                            handleCloseProMdl(false);
                            fetchData();
                        }
                    })
                    .catch((err) => {
                        setProLoader(false);
                        if (err.response) {
                            if (err.response.status == 500) {
                                localStorage.clear();
                                navigate("/login");
                            } else {
                                toast.error(err.response.data.message);
                            }
                        }
                    });
            }
        }
    };

    const handleDeletePro = async (id) => {
        Swal.fire({
            title: DELETE_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/${ADMIN_DETAIL_PROCEDURE}/${id}`, {
                            headers: {
                                authorization: token.token,
                            },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire(res.data.message, " ", "success");

                                fetchData();
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{MANAGE_PROCEDURE}</div>
                                {/* <ToastContainer /> */}
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">Procedure List</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tabbable-line">
                                <div className="tab-content">
                                    <div className="tab-pane active fontawesome-demo" id="tab1">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card" style={isLoading ? { height: "35rem" } : {}}>
                                                    <div className="card-head">
                                                        <header>{MANAGE_PROCEDURE}</header>
                                                    </div>
                                                    <div className="card-body ">
                                                        <div className="row">
                                                            <div className="row d-flex justify-content-between">
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="btn-group">
                                                                        <button id="addRow" className="btn btn-info" onClick={() => handleShowProcedure("add")}>
                                                                            {ADD}
                                                                            <i className="fa fa-plus"></i>
                                                                        </button>
                                                                    </div>
                                                                    <div>
                                                                        <div className="btn-group mx-3" style={{ width: "130px" }}>
                                                                            <input
                                                                                className="form-control procedure-code"
                                                                                name="search"
                                                                                type="search"
                                                                                placeholder="Search"
                                                                                aria-label="Search"
                                                                                onChange={handleFilterCodeValues}
                                                                            />
                                                                        </div>

                                                                        <div className="btn-group mx-3"></div>

                                                                        <div className="btn-group mx-7" style={{ width: "270px" }}>
                                                                            <label className="mt-2 col-md-5">{DOCTOR_SPECIALTY}</label>
                                                                            <div className="col-9">
                                                                                <Select
                                                                                    name="specialty"
                                                                                    value={specialtyOptions && specialtyOptions.filter(({ value }) => value == filterspecialityValue)}
                                                                                    options={specialtyOptions}
                                                                                    onChange={handleFilterSpecialityValue}
                                                                                    placeholder={SELECT}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="btn-group mx-5"></div>

                                                                        <div className="btn-group mx-7" style={{ width: "270px" }}>
                                                                            <label className="mt-2 col-md-3">{TYPE}</label>
                                                                            <div className="col-md-11">
                                                                                <Select
                                                                                    name="cptgroup"
                                                                                    value={cptgroupOptions && cptgroupOptions.filter(({ value }) => value == filterTypeValue)}
                                                                                    options={cptgroupOptions}
                                                                                    onChange={handleFilterTypeValue}
                                                                                    placeholder={SELECT}
                                                                                    isDisabled={filterspecialityValue == "" ? true : false}
                                                                                ></Select>
                                                                            </div>
                                                                        </div>

                                                                        <div className="btn-group mx-3"></div>

                                                                        <div className="btn-group mx-5">
                                                                            <div className="btn-group mx-1">
                                                                                <label className="mt-2 col-md-5">{STATUS}</label>
                                                                                <select className="form-control col-md-3" name="status" value={filterstatusValue} onChange={handleFilterStatusValue}>
                                                                                    <option value="">{ALL}</option>
                                                                                    {Object.keys(PROCEDURE_STATUS).map((item, i) => {
                                                                                        return (
                                                                                            <option key={i} value={PROCEDURE_STATUS[item]}>
                                                                                                {item}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-scrollable" style={isLoading ? { border: "none" } : {}}>
                                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="col-1">{DOCTOR_SPECIALTY}</th>
                                                                        <th className="col-1">{TYPE}</th>
                                                                        <th className="col-1">{PROCEDURE_CODE}</th>
                                                                        <th className="col-1">{FEE}</th>
                                                                        <th className="col-5">{DESCRIPTION}</th>
                                                                        <th className="col-1">{SORT_PRIORITY}</th>
                                                                        <th className="col-1">{STATUS}</th>
                                                                        <th className="col-1">{ACTION}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={isLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                                    {isLoading ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        <>
                                                                            {!procedureList ? (
                                                                                <>{isLoading ? "" : <NoDataFound />}</>
                                                                            ) : (
                                                                                procedureList &&
                                                                                procedureList.map((item, index) => {
                                                                                    return (
                                                                                        <tr key={index} className={`odd gradeX ${item.priorityList !== MAX_PRIORITY ? "bgPriorityRowColor" : ""}`}>
                                                                                            <td>
                                                                                                {item.specialtyname}
                                                                                            </td>
                                                                                            <td className="left">
                                                                                                {item.cptgroupname}
                                                                                            </td>
                                                                                            <td className="left">{item.code ? item.code : DASH}</td>

                                                                                            <td className="left">{item.fee ? item.fee : DASH}</td>
                                                                                            <td className="left">{item.description ? item.description : DASH}</td>
                                                                                            <td>{item.priorityList ? item.priorityList : DASH}</td>
                                                                                            <td className="left">
                                                                                                <span
                                                                                                    className={
                                                                                                        "badge " +
                                                                                                        (item.statusId == 2
                                                                                                            ? "deactiveStatus badge-warning"
                                                                                                            : item.statusId == 1
                                                                                                            ? "activeStatus badge-success"
                                                                                                            : "badge-danger")
                                                                                                    }
                                                                                                >
                                                                                                    {Object.keys(PROCEDURE_STATUS).find((key) => PROCEDURE_STATUS[key] === item.statusId)}
                                                                                                </span>
                                                                                            </td>
                                                                                            <td className="left">
                                                                                                <button className="btn btn-primary btn-xs" onClick={() => handleEditPro(item.id, item.specialityId)}>
                                                                                                    <i className="fa fa-pencil"></i>
                                                                                                </button>
                                                                                                <button type="button" className="btn btn-danger btn-xs" onClick={() => handleDeletePro(item.id)}>
                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mx-4">
                                                        <div>Total {procedureList == null ? "0" : rowCount} Procedure</div>
                                                        <div className="pagination-center">
                                                            <ReactPaginate
                                                                initialPage={0}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={0}
                                                                pageRangeDisplayed={3}
                                                                onPageChange={handlePageClick}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                pageLinkClassName="page-link"
                                                                breakLinkClassName="page-link"
                                                                nextLinkClassName="page-link"
                                                                previousLinkClassName="page-link"
                                                                pageClassName="page-item"
                                                                breakClassName="page-item"
                                                                nextClassName="page-item"
                                                                previousClassName="page-item"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"
                                                                disableInitialCallback={true}
                                                                forcePage={currentPage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showProcedure} onHide={handleCloseProMdl}>
                <Modal.Header closeButton>{editProStatus ? <Modal.Title>Update Procedure </Modal.Title> : <Modal.Title>Add Procedure </Modal.Title>}</Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <div className="row">
                            <div className="col-6">
                                <label className="control-label col-md-12">
                                    {FEE}
                                    <span className="required"> * </span>
                                </label>
                                <div className="col-md-12">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">
                                                $
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={procedure.fee}
                                            onChange={(e) => setProcedure({ ...procedure, fee: e.target.value.replace(/[^\d.]/, "") })}
                                            placeholder="Fee"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <label className="control-label col-md-12">{SORT_PRIORITY}</label>
                                <div className="col-md-12">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={procedure.priorityList}
                                            onChange={(e) => setProcedure({ ...procedure, priorityList: e.target.value.replace(/[^\d.]/, "") })}
                                            placeholder="priority"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="row">
                            <div className="col-6">
                                <label className="control-label col-md-12">
                                    {DOCTOR_SPECIALTY}
                                    <span className="required"> * </span>
                                </label>
                                <Select
                                    name="specialtyId"
                                    value={specialtyOptions && specialtyOptions.filter(({ value }) => value == cptgroup.specialtyId)}
                                    options={specialtyOptions}
                                    onChange={handleSpecialtyChange}
                                    placeholder={SELECT}
                                />
                            </div>
                            <div className="col-6">
                                <label className="control-label col-md-12">
                                    {TYPE}
                                    <span className="required"> * </span>
                                </label>
                                <Select
                                    name="cptgroupId"
                                    value={cptgroupOptions && cptgroupOptions.filter(({ value }) => value == cptgroup.cptgroupId)}
                                    options={cptgroupOptions}
                                    onChange={handleCptgroupChange}
                                    placeholder={SELECT}
                                    isDisabled={specialtyId == "" ? true : false}
                                ></Select>
                            </div>
                        </div>
                    </div>

                    {editProStatus ? (
                        <div className="form-group row">
                            <div className="col-6">
                                <label className="control-label ">
                                    {CODE}
                                    <span className="required"> * </span>
                                </label>
                                <div>
                                    <input
                                        type="text"
                                        value={procedure.code}
                                        onChange={(e) => setProcedure({ ...procedure, code: e.target.value })}
                                        placeholder="Enter Code"
                                        className="form-control input-height"
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <label className="control-label ">
                                    {STATUS}
                                    <span className="required"> * </span>
                                </label>

                                <select value={procedure.statusId} className="form-control input-height" onChange={(e) => setProcedure({ ...procedure, statusId: e.target.value })}>
                                    {Object.keys(PROCEDURE_STATUS).map((item, i) => {
                                        return (
                                            <option key={i} value={PROCEDURE_STATUS[item]}>
                                                {item}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                    ) : (
                        <div className="form-group row">
                            <label className="control-label col-md-12">
                                {CODE}
                                <span className="required"> * </span>
                            </label>
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    value={procedure.code}
                                    onChange={(e) => setProcedure({ ...procedure, code: e.target.value })}
                                    placeholder="Enter Code"
                                    className="form-control input-height"
                                />
                            </div>
                        </div>
                    )}

                    <div className="form-group row">
                        <label className="control-label col-md-12">
                            {DESCRIPTION}
                            <span className="required"> * </span>
                        </label>
                        <div className="col-md-12">
                            <textarea
                                value={procedure.description}
                                onChange={(e) => setProcedure({ ...procedure, description: e.target.value })}
                                placeholder="Enter Description"
                                className="form-control"
                                rows="5"
                            ></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <div className="d-flex justify-content-center mb-3">
                    <div className="center">
                        {editProStatus ? (
                            <Button variant="primary" onClick={handleUpdate}>
                                {proLoader ? <i className="spinner-border"></i> : ""} {UPDATE}
                            </Button>
                        ) : (
                            <Button variant="primary" onClick={handleAddSubmit}>
                                {proLoader ? <i className="spinner-border"></i> : ""} {ADD}
                            </Button>
                        )}

                        <Button className="mx-3" variant="secondary" onClick={handleCloseProMdl}>
                            {CANCEL}
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default ManageProcedure;
