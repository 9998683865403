import React, { useEffect, useState } from "react";
import {
  DOCTOR,
  PATIENT,
  DATE_OF_SERVICE,
  HOME,
  CREATED_DATE,
  DASH,
  ROWS_PER_PAGE,
  CREATED_BY,
  ACTION,
  PREAUTHORIZATION_LIST,
  BIRTH_DATE,
  ADD,
  ROLES,
  SELECT_OFFICE,
  OFFICE,
  STATUS,
  COMMENT,
  AUTH,
  FILE,
  PRE_AUTH_STATUS,
  DOWNLOAD,
  SELECT_DOCTOR,
  PATIENT_DOCUMENT_DRODOWN,
  ALL,
} from "../../Helper/Constants";
import {
  PRE_AUTHORIZATION_NOTE_DETAIL_API,
  PRE_AUTHORIZATION_NOTE_LIST_API,
  OFFICE_LIST_WITHOUT_PAGINATION,
  ALL_DOCTOR_IN_SELECTED_OFFICE,
  PRE_AUTHORIZATION_DOCTOR_LIST_API,
  PATIENT_DOCUMENT_LIST,
  DETAIL_PRE_AUTHORIZATION_STATUS_API,
  PRE_AUTHORIZATION_DOWNLOAD_API,
  POST_OP_NOTE_LIST_API,
} from "../../Helper/ApiPath";
import {
  dateAPIFormate,
  dateFormateMonthDateYear,
  defaultTimeZone,
} from "../../Helper/Helper";
import axios from "axios";
import { baseURL, frontURL } from "../../Config";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { NavLink, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import NoDataFound from "../CommonComponent/NoDataFound";
import { DELETE_PRE_AUTHORIZATION_NOTE_MESSAGE } from "../../Helper/Messages";
import Loader from "../CommonComponent/Loader";
import Cookies from "universal-cookie";
import Select from "react-select";
import DownloadPreAuthorization from "../Forms/PreAuthorization/DownloadPreAuthorization";
import UpdateStatus from "../Forms/PreAuthorization/UpdateStatus";

function ManagePostOpNotes() {
  const cookies = new Cookies();
  let adminRole = localStorage.getItem("adminRole");
  const postOpPagination = JSON.parse(
    sessionStorage.getItem("postOpPagination")
  );
  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [preAuthorizationList, setPreAhothorizationList] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [offset, setOffset] = useState(
    postOpPagination && postOpPagination.offset ? postOpPagination.offset : 1
  );
  const [pageCount, setPageCount] = useState(0);
  let role = cookies.get("role");
  if (role) {
    role = cookies.get("role").roleId;
  }
  let token = JSON.parse(localStorage.getItem("logToken"));
  let officeId = localStorage.getItem("officeId");
  let preAuthDoctorId = sessionStorage.getItem("preAuthDoctorId");

  var date = new Date();
  const [filterValues, setFilterValues] = useState({
    statusId:
      postOpPagination && postOpPagination.statusId
        ? postOpPagination.statusId
        : "",
    patientSearch:
      postOpPagination && postOpPagination.patientSearch
        ? postOpPagination.patientSearch
        : "",
    startDate:
      postOpPagination && postOpPagination.startDate
        ? defaultTimeZone(postOpPagination.startDate)
        : new Date(date.setMonth(date.getMonth() - 6)),
    endDate:
      postOpPagination && postOpPagination.endDate
        ? defaultTimeZone(postOpPagination.endDate)
        : new Date(date.setMonth(date.getMonth() + 7)),
  });
  const [show, setShow] = useState(false);
  const [preAuthorizationId, setPreAuthorizationId] = useState("");
  const [patientId, setPatientId] = useState("");
  const [documents, setDocuments] = useState([]);
  const [documentType, setDocumentType] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [showStatus, setShowStatus] = useState(false);
  const [preAuthId, setPreAuthId] = useState("");
  const [statusId, setStatusId] = useState("");

  const handleImageDownload = (patientId, preAuthId, statusId) => {
    setPatientId(patientId);
    setPreAuthId(preAuthId);
    setStatusId(statusId);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setDocumentType("");
    setDocumentName("");
  };

  const documentOptions = Object.keys(PATIENT_DOCUMENT_DRODOWN).map(
    (item, i) => {
      return {
        value: PATIENT_DOCUMENT_DRODOWN[item],
        label: item,
      };
    }
  );

  const handleDocumentChange = (e) => {
    if (e.value) {
      document.getElementById("downloadDocuments").classList.remove("d-none");
    } else {
      document.getElementById("downloadDocuments").classList.add("d-none");
    }
    setDocumentType(e.value);
    setDocumentName(e.label);
    fetchThumbnailData(e.value);
  };

  const handleDownload = (image, fileName) => {
    axios({
      url: `${frontURL}${image}`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    });
  };

  const fetchThumbnailData = async (type) => {
    axios
      .post(`${baseURL}/${PATIENT_DOCUMENT_LIST}`, {
        headers: { authorization: token.token },
        type: type,
        patientId: patientId,
      })
      .then((res) => {
        setDocuments(res.data.data);
      });
  };

  const handlePdfDownload = async (preAuthorizationId) => {
    axios
      .post(`${baseURL}/${PRE_AUTHORIZATION_DOWNLOAD_API}`, {
        headers: { authorization: token.token },
        preAuthId: preAuthorizationId,
      })
      .then(async (res) => {
        axios({
          url: `${frontURL}${res.data.data.path}`,
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${res.data.data.patientName + "_pre-authorization-note" + ".pdf"}`
          );
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const handleFilterValues = (e) => {
    const { name, value } = e.target;
    setFilterValues({ ...filterValues, [name]: value });
    sessionStorage.setItem(
      "postOpPagination",
      JSON.stringify({
        ...filterValues,
        [name]: value,
        offset: offset,
        filterDoctorId: filterDoctorId,
        filterOfficeId: filterOfficeId,
      })
    );
    setOffset(1);
    setCurrentPage(0);
  };
  let [filterOfficeId, setFilterOfficeId] = useState(
    postOpPagination && postOpPagination.filterOfficeId
      ? postOpPagination.filterOfficeId
      : ""
  );

  const [officeList, setOfficeList] = useState([]);
  let officeSelect = [
    {
      id: "",
      name: SELECT_OFFICE,
    },
  ];

  const officeListData = () => {
    axios
      .post(`${baseURL}/${OFFICE_LIST_WITHOUT_PAGINATION}`, {
        headers: { authorization: token.token },
      })
      .then((res) => {
        setOfficeList(
          res.data.data ? officeSelect.concat(res.data.data) : res.data.data
        );
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };
  const officeOption =
    officeList &&
    officeList.map((item, i) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
  const handleOfficeChange = (e) => {
    sessionStorage.removeItem("postOpPagination");
    setFilterOfficeId(e.value);
    setOffset(1);
    setCurrentPage(0);
  };

  let [filterDoctorId, setFilterDoctorId] = useState(
    preAuthDoctorId
      ? preAuthDoctorId
      : postOpPagination && postOpPagination.filterDoctorId
      ? postOpPagination.filterDoctorId
      : ""
  );
  const [doctorList, setDoctorList] = useState([]);
  let doctorSelect = [
    {
      id: "",
      firstName: SELECT_DOCTOR,
    },
  ];

  const doctorListData = () => {
    if (role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR) {
      axios
        .post(`${baseURL}/${ALL_DOCTOR_IN_SELECTED_OFFICE}`, {
          headers: { authorization: token.token },
          officeId: officeId,
          isDoctor: true,
        })
        .then((res) => {
          setDoctorList(
            res.data.data ? doctorSelect.concat(res.data.data) : res.data.data
          );
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status == 500) {
              localStorage.clear();
              navigate("/login");
            } else {
              toast.error(err.response.data.message);
            }
          }
        });
    } else {
      axios
        .post(`${baseURL}/${PRE_AUTHORIZATION_DOCTOR_LIST_API}`, {
          headers: { authorization: token.token },
        })
        .then((res) => {
          setDoctorList(
            res.data.data.totalPreAuthorizationInDoctorList
              ? doctorSelect.concat(
                  res.data.data.totalPreAuthorizationInDoctorList
                )
              : res.data.data.totalPreAuthorizationInDoctorList
          );
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status == 500) {
              localStorage.clear();
              navigate("/login");
            } else {
              toast.error(err.response.data.message);
            }
          }
        });
    }
  };
  const doctorOption =
    doctorList &&
    doctorList.map((item, i) => {
      return {
        value: item.id,
        label: item.firstName + " " + (item.lastName ? item.lastName : ""),
      };
    });
  const handleDoctorChange = (e) => {
    sessionStorage.removeItem("postOpPagination");
    sessionStorage.removeItem("preAuthDoctorId");
    setFilterDoctorId(e.value);
    setOffset(1);
    setCurrentPage(0);
  };

  const claimDoctorId = sessionStorage.getItem("claimDoctorId");
  const [doctorId, setDoctorId] = useState(claimDoctorId ? claimDoctorId : "");

  const fetchData = async () => {
    await axios
      .post(`${baseURL}/${POST_OP_NOTE_LIST_API}`, {
        headers: { authorization: token.token },
        officeId:
          role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR
            ? officeId
            : filterOfficeId,
        startDate: dateAPIFormate(filterValues.startDate),
        endDate: dateAPIFormate(filterValues.endDate),
        page: offset,
        rowsPerPage: ROWS_PER_PAGE,
        doctorId: filterDoctorId,
        patientSearch: filterValues.patientSearch,
        statusId: filterValues.statusId,
      })
      .then((res) => {
        setIsLoading(false);
        setPreAhothorizationList(res.data.data);
        // setChangedFields(JSON.parse(res.data.data.changedFields));
        // console.log("changedFields", res.data.data.changedFields);

        setPageCount(res.data.extraData.pageCount);
        setRowCount(res.data.extraData.rowCount);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          if (err.response.status == 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };
  const handleDelete = (id) => {
    Swal.fire({
      title: DELETE_PRE_AUTHORIZATION_NOTE_MESSAGE,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (result.isConfirmed) {
          await axios
            .delete(`${baseURL}/${PRE_AUTHORIZATION_NOTE_DETAIL_API}/${id}`, {
              headers: { authorization: token.token },
            })
            .then((res) => {
              Swal.fire(res.data.message, " ", "success");
              fetchData();
            })
            .catch((err) => {
              if (err.response) {
                if (err.response.status == 500) {
                  localStorage.clear();
                  navigate("/login");
                } else {
                  toast.error(err.response.data.message);
                }
              }
            });
        }
      }
    });
  };

  const handlePageClick = (event) => {
    sessionStorage.removeItem("postOpPagination");
    const selectedPage = event.selected;
    setOffset(selectedPage + 1);
    setCurrentPage(selectedPage);
  };

  const handleAdd = () => {
    navigate("/add-post-op-note");
  };

  const handleEdit = (id, doctorId, patientId, officeId) => {
    sessionStorage.setItem(
      "postOpPagination",
      postOpPagination
        ? JSON.stringify(postOpPagination)
        : JSON.stringify({
            offset: offset,
            startDate: defaultTimeZone(filterValues.startDate),
            endDate: defaultTimeZone(filterValues.endDate),
            filterDoctorId: filterDoctorId,
            filterOfficeId: filterOfficeId,
            patientSearch: filterValues.patientSearch,
            statusId: filterValues.statusId,
          })
    );
    localStorage.setItem("preAuthorization", id);
    localStorage.setItem("isPreAuthorization", true);
    localStorage.setItem("CNDoctorId", doctorId);
    localStorage.setItem("CNPatientId", patientId);
    localStorage.setItem("officeId", officeId);
    navigate("/EditPostOp");
  };

  const [changedFields, setChangedFields] = useState([]);

  const handleStatus = (id, changedFields) => {
    setChangedFields(changedFields);
    setPreAuthorization("");
    setPreAuthorizationId(id);
    statusDetail(id);
    setShowStatus(true);
  };

  const handleCloseStatus = () => {
    fetchData();
    setChangedFields([]);
    setShowStatus(false);
  };

  const [preAuthorization, setPreAuthorization] = useState({
    statusId: "",
    comment: "",
    auth: "",
  });

  const [oldPreAuth, setOldPreAuth] = useState({
    statusId: "",
    comment: "",
    auth: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPreAuthorization({ ...preAuthorization, [name]: value });
  };

  const statusDetail = async (preAuthorizationId) => {
    await axios
      .get(
        `${baseURL}/${DETAIL_PRE_AUTHORIZATION_STATUS_API}/${preAuthorizationId}`,
        {
          headers: { authorization: token.token },
        }
      )
      .then((res) => {
        setPreAuthorization(res.data.data);
        setOldPreAuth(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  useEffect(() => {
    localStorage.removeItem("comprehensiveId");
    doctorListData();
    if (claimDoctorId) {
      setDoctorId(claimDoctorId);
    }
    fetchData();
    if (role != ROLES.DOCTOR && adminRole != ROLES.DOCTOR) {
      officeListData();
    }
  }, [offset, doctorId, filterValues, filterOfficeId, filterDoctorId]);

  return (
    <>
      <div className="page-content-wrapper">
        <div className="page-content">
          <div className="page-bar">
            <div className="page-title-breadcrumb">
              <div className=" pull-left">
                <div className="page-title">Post Op/Progres Notes</div>
              </div>
              <ol className="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i className="fa fa-home"></i>&nbsp;
                  <NavLink className="parent-item" to="/dashboard">
                    {HOME}
                  </NavLink>
                  &nbsp;<i className="fa fa-angle-right"></i>
                </li>
                <li className="active">Post Op/Progres Notes</li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="tabbable-line">
                <div className="tab-content">
                  <div className="tab-pane active fontawesome-demo" id="tab1">
                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="card"
                          style={isLoading ? { height: "35rem" } : {}}
                        >
                          <div className="card-body ">
                            <div className="row">
                              <div className="justify-content-between">
                                <div className="row">
                                  <div className="col-md-5">
                                    <div className="row">
                                      <div className="col-md-2 mr-sm-2">
                                        <button
                                          id="addRow"
                                          className="btn btn-info"
                                          onClick={handleAdd}
                                        >
                                          {ADD} <i className="fa fa-plus"></i>
                                        </button>
                                      </div>
                                      <div className="btn-group col-md-6 pr-0">
                                        <div className="col-md-5">
                                          <label className="mt-2">
                                            {DATE_OF_SERVICE}
                                          </label>
                                        </div>
                                        <DatePicker
                                          className="form-control mr-sm-2"
                                          selected={filterValues.startDate}
                                          maxDate={new Date()}
                                          yearDropdownItemNumber={100}
                                          scrollableMonthDropdown={true}
                                          showYearDropdown
                                          showMonthDropdown
                                          onChange={(date) => {
                                            sessionStorage.setItem(
                                              "postOpPagination",
                                              JSON.stringify({
                                                ...filterValues,
                                                startDate: date,
                                                offset: offset,
                                                filterDoctorId: filterDoctorId,
                                                filterOfficeId: filterOfficeId,
                                              })
                                            );
                                            setFilterValues({
                                              ...filterValues,
                                              startDate: date,
                                            });
                                          }}
                                        />
                                        <span className="todate">To</span>
                                      </div>
                                      <div className="btn-group col-md-3">
                                        <DatePicker
                                          className="form-control mr-sm-2"
                                          selected={filterValues.endDate}
                                          minDate={filterValues.startDate}
                                          maxDate={new Date().setMonth(
                                            new Date().getMonth() + 1
                                          )}
                                          yearDropdownItemNumber={100}
                                          scrollableMonthDropdown={true}
                                          showYearDropdown
                                          showMonthDropdown
                                          onChange={(date) => {
                                            sessionStorage.setItem(
                                              "postOpPagination",
                                              JSON.stringify({
                                                ...filterValues,
                                                endDate: date,
                                                offset: offset,
                                                filterDoctorId: filterDoctorId,
                                                filterOfficeId: filterOfficeId,
                                              })
                                            );
                                            setFilterValues({
                                              ...filterValues,
                                              endDate: date,
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {role == ROLES.DOCTOR ||
                                  adminRole == ROLES.DOCTOR ? (
                                    <div className="col-md-2"></div>
                                  ) : (
                                    <div className="col-md-2 mr-sm-2">
                                      <div className="row">
                                        <Select
                                          name="officeName"
                                          value={
                                            officeOption &&
                                            officeOption.filter(
                                              ({ value }) =>
                                                value == filterOfficeId
                                            )
                                          }
                                          options={officeOption}
                                          onChange={handleOfficeChange}
                                          placeholder={SELECT_OFFICE}
                                        ></Select>
                                      </div>
                                    </div>
                                  )}

                                  <div className="col-md-5">
                                    <div className="row">
                                      <div className="col-md-4 mr-sm-2">
                                        <Select
                                          name="doctorSearch"
                                          value={
                                            doctorOption &&
                                            doctorOption.filter(
                                              ({ value }) =>
                                                value == filterDoctorId
                                            )
                                          }
                                          options={doctorOption}
                                          onChange={handleDoctorChange}
                                          placeholder={SELECT_DOCTOR}
                                        ></Select>
                                      </div>

                                      <div className="col-md-4">
                                        <div className="row">
                                          <div className="btn-group col-md-12">
                                            <input
                                              className="form-control mr-sm-2"
                                              value={filterValues.patientSearch}
                                              name="patientSearch"
                                              placeholder="Patient"
                                              onChange={handleFilterValues}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <div className="row d-flex justify-content-end">
                                          <div className="col-3 d-flex justify-content-end mt-2">
                                            <label className="">{STATUS}</label>
                                          </div>
                                          <div className="col-8">
                                            <select
                                              className="form-control col-md-3"
                                              name="statusId"
                                              value={filterValues.statusId}
                                              onChange={handleFilterValues}
                                            >
                                              <option value="">{ALL}</option>
                                              {Object.keys(PRE_AUTH_STATUS).map(
                                                (item, i) => {
                                                  return (
                                                    <option
                                                      key={i}
                                                      value={
                                                        PRE_AUTH_STATUS[item]
                                                      }
                                                    >
                                                      {item}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <DownloadPreAuthorization
                              fetchData={fetchData}
                              statusId={statusId}
                              preAuthId={preAuthId}
                              show={show}
                              handleDownload={handleDownload}
                              handleDocumentChange={handleDocumentChange}
                              documentOptions={documentOptions}
                              documentType={documentType}
                              documentName={documentName}
                              documents={documents}
                              patientId={patientId}
                              handleClose={handleClose}
                              fetchThumbnailData={fetchThumbnailData}
                            />

                            <UpdateStatus
                              changedFields={changedFields}
                              show={showStatus}
                              handleClose={handleCloseStatus}
                              handleChange={handleChange}
                              preAuthorizationId={preAuthorizationId}
                              preAuthorization={preAuthorization}
                              oldPreAuth={oldPreAuth}
                            />
                            <div
                              className="table-scrollable"
                              style={isLoading ? { border: "none" } : {}}
                            >
                              <table
                                className="table table-hover table-checkable order-column full-width"
                                id="example4"
                              >
                                <thead>
                                  <tr>
                                    {role == ROLES.DOCTOR ||
                                    adminRole == ROLES.DOCTOR ? (
                                      ""
                                    ) : (
                                      <th>{OFFICE}</th>
                                    )}
                                    <th>{DOCTOR}</th>
                                    <th>{PATIENT}</th>
                                    <th>{BIRTH_DATE}</th>
                                    <th>{DATE_OF_SERVICE}</th>
                                    <th>{CREATED_DATE}</th>
                                    <th>{CREATED_BY}</th>
                                    <th></th>
                                    {/* <th>{STATUS}</th> */}
                                    {/* <th>{COMMENT}</th> */}
                                    {/* <th>{AUTH}</th> */}
                                    {/* <th>{FILE}</th> */}
                                    <th>{ACTION}</th>
                                  </tr>
                                </thead>
                                <tbody
                                  style={
                                    isLoading
                                      ? {
                                          borderStyle: "none",
                                          borderColor: "#fff",
                                        }
                                      : {}
                                  }
                                >
                                  {isLoading ? (
                                    <Loader />
                                  ) : (
                                    <>
                                      {!preAuthorizationList ? (
                                        <>{isLoading ? "" : <NoDataFound />}</>
                                      ) : (
                                        preAuthorizationList &&
                                        preAuthorizationList.map(
                                          (item, index) => {
                                            return (
                                              <tr
                                                className="odd gradeX"
                                                style={{
                                                  background: `${
                                                    (role == ROLES.DOCTOR ||
                                                      adminRole ==
                                                        ROLES.DOCTOR) &&
                                                    item.isNew == 1
                                                      ? "#cce8ff"
                                                      : ""
                                                  }`,
                                                }}
                                                key={index}
                                              >
                                                {role == ROLES.DOCTOR ||
                                                adminRole == ROLES.DOCTOR ? (
                                                  ""
                                                ) : (
                                                  <td className="left">
                                                    {item.offices
                                                      ? item.offices.name
                                                      : DASH}
                                                  </td>
                                                )}
                                                <td className="left">
                                                  {item.doctor.firstName}{" "}
                                                  {item.doctor.lastName}
                                                </td>
                                                <td className="left">
                                                  {" "}
                                                  {item.patient.firstName}{" "}
                                                  {item.patient.lastName}
                                                </td>
                                                <td className="left">
                                                  {item.patient &&
                                                  item.patient.dob
                                                    ? dateFormateMonthDateYear(
                                                        item.patient.dob
                                                      )
                                                    : DASH}
                                                </td>
                                                <td className="left">
                                                  {item.dateOfService
                                                    ? dateFormateMonthDateYear(
                                                        item.dateOfService
                                                      )
                                                    : DASH}
                                                </td>
                                                <td className="left">
                                                  {dateFormateMonthDateYear(
                                                    item.created_at
                                                  )}
                                                </td>
                                                <td>
                                                  {item.createBy &&
                                                  item.createBy.email
                                                    ? item.createBy.email
                                                    : DASH}
                                                </td>
                                                <td>
                                                  {item.isNew == 1 &&
                                                  (role == ROLES.DOCTOR ||
                                                    adminRole ==
                                                      ROLES.DOCTOR) ? (
                                                    <img
                                                      src="assets/img/new.gif"
                                                      style={{
                                                        height: "20px",
                                                        width: "30px",
                                                        paddingright: "10px",
                                                      }}
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                </td>
                                                {/* <td>
                                                  <button
                                                    className={
                                                      "btn btn-xs " +
                                                      (item.statusId ==
                                                      PRE_AUTH_STATUS.Pending
                                                        ? "btn-dark"
                                                        : item.statusId ==
                                                          PRE_AUTH_STATUS.Approved
                                                        ? "btn-success"
                                                        : item.statusId ==
                                                          PRE_AUTH_STATUS[
                                                            "Requesting info/Correction"
                                                          ]
                                                        ? "btn-warning"
                                                        : item.statusId ==
                                                          PRE_AUTH_STATUS[
                                                            "Submitted-Pending approval"
                                                          ]
                                                        ? "btn-info"
                                                        : item.statusId ==
                                                          PRE_AUTH_STATUS.Other
                                                        ? "btn-primary"
                                                        : "btn-danger")
                                                    }
                                                    onClick={() =>
                                                      handleStatus(
                                                        item.id,
                                                        item.changedFields &&
                                                          JSON.parse(
                                                            item.changedFields
                                                          )
                                                      )
                                                    }
                                                  >
                                                    {Object.keys(
                                                      PRE_AUTH_STATUS
                                                    ).find(
                                                      (key) =>
                                                        PRE_AUTH_STATUS[key] ===
                                                        item.statusId
                                                    )}
                                                  </button>
                                                </td>
                                                <td>
                                                  <div
                                                    data-toggle="tooltip"
                                                    title={item.comment}
                                                  >
                                                    {item.comment
                                                      ? item.comment.length > 10
                                                        ? item.comment.slice(
                                                            0,
                                                            10
                                                          ) + "..."
                                                        : item.comment
                                                      : DASH}
                                                  </div>
                                                </td>
                                                <td>
                                                  <div
                                                    data-toggle="tooltip"
                                                    title={item.auth}
                                                  >
                                                    {item.auth
                                                      ? item.auth.length > 10
                                                        ? item.auth.slice(
                                                            0,
                                                            10
                                                          ) + "..."
                                                        : item.auth
                                                      : DASH}
                                                  </div>
                                                </td>
                                                <td>
                                                  {" "}
                                                  <div
                                                    className="btn btn-success"
                                                    onClick={() =>
                                                      handleImageDownload(
                                                        item.patient.uniqueId,
                                                        item.id,
                                                        item.statusId
                                                      )
                                                    }
                                                  >
                                                    {DOWNLOAD}{" "}
                                                    <i className="fa fa-download mt-1"></i>
                                                  </div>
                                                </td> */}
                                                <td>
                                                  <div
                                                    className="btn btn-primary btn-xs"
                                                    onClick={() =>
                                                      handleEdit(
                                                        item.id,
                                                        item.doctor.uniqueId,
                                                        item.patient.uniqueId,
                                                        item.offices
                                                          ? item.offices
                                                              .uniqueId
                                                          : null
                                                      )
                                                    }
                                                  >
                                                    <i className="fa fa-pencil"></i>
                                                  </div>

                                                  <button
                                                    className="btn btn-danger btn-xs"
                                                    onClick={() =>
                                                      handleDelete(item.id)
                                                    }
                                                  >
                                                    <i className="fa fa-trash-o"></i>
                                                  </button>
                                                  <div
                                                    className="btn btn-success btn-xs"
                                                    onClick={() =>
                                                      handlePdfDownload(item.id)
                                                    }
                                                  >
                                                    <i className="fa fa-download mt-1"></i>
                                                  </div>
                                                  {/* <ComponentToPrintWrapper key={index} preAuthorization={item.id} /> */}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      )}
                                    </>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between mx-4">
                            <div>
                              Total{" "}
                              {preAuthorizationList == null ? "0" : rowCount}{" "}
                              Post Op/Progres Notes
                            </div>
                            <div className="pagination-center">
                              <ReactPaginate
                                initialPage={0}
                                pageCount={pageCount}
                                marginPagesDisplayed={0}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName="pagination"
                                activeClassName="active"
                                pageLinkClassName="page-link"
                                breakLinkClassName="page-link"
                                nextLinkClassName="page-link"
                                previousLinkClassName="page-link"
                                pageClassName="page-item"
                                breakClassName="page-item"
                                nextClassName="page-item"
                                previousClassName="page-item"
                                previousLabel="Previous"
                                nextLabel="Next"
                                disableInitialCallback={true}
                                forcePage={
                                  postOpPagination && postOpPagination.offset
                                    ? postOpPagination.offset - 1
                                    : currentPage
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManagePostOpNotes;
