import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { CANCEL } from "../../../Helper/Constants";

function ViewDocument(props) {
    return (
        <>
            <Modal show={props.view} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.Document ? props.Document.title : ""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{props.Document ? props.Document.description : ""}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        {CANCEL}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ViewDocument;
