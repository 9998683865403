import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { CANCEL, ELIGIBILITY_NAME, ELIGIBILITY_SAMPLE, MB, MB_HMO, MB_PPO, NAME, NO_MB, QMB, QMB_HMO, QMB_PPO, RAILROAD, RAILROAD_HMO, RAILROAD_PPO, SELECT } from "../../Helper/Constants";
import Select from "react-select";
import EligibilityImage from "./EligibilityImage";

function Eligibility_sample() {
    const [show, setShow] = useState(false);
    const [name, setName] = useState("");
    const handleClose = () => setShow(false);

    const handleSample = (e) => {
        e.preventDefault();
        setShow(true);
        setName("");
    };

    const handleNameChange = (e) => {
        setName(e.value);
    };

    const nameOptions = Object.keys(ELIGIBILITY_NAME).map((item, i) => {
        return {
            value: ELIGIBILITY_NAME[item],
            label: item,
        };
    });

    return (
        <>
            <>
                <button className="btn btn-warning" onClick={handleSample}>
                    {ELIGIBILITY_SAMPLE}
                </button>

                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title> {ELIGIBILITY_SAMPLE}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row ">
                            <label className="col-2 my-2">{NAME}</label>
                            <Select
                                name="name"
                                className="col-10"
                                value={nameOptions && nameOptions.filter(({ value }) => value == name)}
                                options={nameOptions}
                                onChange={handleNameChange}
                                placeholder={SELECT}
                            ></Select>
                        </div>

                        {name ? (
                            <div className="card my-5">
                                <div className="card-head">
                                    <header>{Object.keys(ELIGIBILITY_NAME).find((key) => ELIGIBILITY_NAME[key] == name)}</header>
                                </div>
                                {name <= 3 ? (
                                    <div className="card-body no-padding height-9 ">
                                        <ul className="performance-list">
                                            <li>
                                                <a>
                                                    <i className="fa fa-circle-o" style={{ color: "#F39C12" }}></i>
                                                    {name == 1 ? MB : name == 2 ? QMB : name == 3 ? RAILROAD : ""}

                                                    <span class="mytooltip tooltip-effect-1 pull-right">
                                                        <EligibilityImage name={name} check={1} />
                                                    </span>
                                                </a>
                                            </li>
                                            <li>
                                                <a>
                                                    <i className="fa fa-circle-o" style={{ color: "#00A65A" }}></i>
                                                    {name == 1 ? MB_HMO : name == 2 ? QMB_HMO : name == 3 ? RAILROAD_HMO : ""}

                                                    <span class="mytooltip tooltip-effect-1 pull-right">
                                                        <EligibilityImage name={name} check={2} />
                                                    </span>
                                                </a>
                                            </li>
                                            <li>
                                                <a>
                                                    <i className="fa fa-circle-o" style={{ color: "#DD4B39" }}></i>
                                                    {name == 1 ? MB_PPO : name == 2 ? QMB_PPO : name == 3 ? RAILROAD_PPO : ""}
                                                    <span class="mytooltip tooltip-effect-1 pull-right">
                                                        <EligibilityImage name={name} check={3} />
                                                    </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                ) : (
                                    <div className="card-body no-padding height-9 ">
                                        <ul className="performance-list">
                                            <li>
                                                <a>
                                                    <i className="fa fa-circle-o" style={{ color: "#F39C12" }}></i>
                                                    {NO_MB}

                                                    <span class="mytooltip tooltip-effect-1 pull-right">
                                                        <EligibilityImage name={name} />
                                                    </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                        ) : (
                            ""
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" onClick={handleClose}>
                            {CANCEL}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        </>
    );
}

export default Eligibility_sample;
