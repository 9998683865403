import React, { useEffect, useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../Config";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { claim24hrDate, dateFormateMonthDateYear, currentDateFormate, claim12hrDateTime } from "../../Helper/Helper";
import {
    CLAIM_LIST,
    CLAIM_,
    DOCTOR,
    PATIENT,
    SUBMITTED,
    SUBMITTED_DATE,
    DATE_OF_SERVICE,
    AMOUNT,
    CREATED_DATE,
    ACTION,
    HOME,
    BOOL_VALUES,
    YES,
    NO,
    ADD,
    DASH,
    ROWS_PER_PAGE,
    CREATED_BY,
    ALL,
    SELECT_DOCTOR,
    NOTE_SENT,
} from "../../Helper/Constants";
import { ALL_DOCTOR_IN_SELECTED_OFFICE, CLAIM_DETAIL, CLAIM_LIST_API, UPDATE_ISSENTNOTE_CLAIM_API } from "../../Helper/ApiPath";
import NoDataFound from "../CommonComponent/NoDataFound";
import Swal from "sweetalert2";
import { DELETE_CLAIM_MESSAGE, NOTE_SENT_CLAIM_MESSAGE, NOTE_NOT_SENT_CLAIM_MESSAGE } from "../../Helper/Messages";
import Loader from "../CommonComponent/Loader";
import Select from "react-select";
import Cookies from "universal-cookie";

function ManageClaim() {
    const cookies = new Cookies();
    const claimPagination = JSON.parse(sessionStorage.getItem("claimPagination"));
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [doctorId, setDoctorId] = useState(claimPagination && claimPagination.doctorId ? claimPagination.doctorId : "");
    const [offset, setOffset] = useState(claimPagination && claimPagination.offset ? claimPagination.offset : 1);
    const [rowCount, setRowCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [claimList, setClaimList] = useState([]);
    const [doctorSelect, setDoctorSelect] = useState([]);

    const token = JSON.parse(localStorage.getItem("logToken"));
    let officeId = localStorage.getItem("officeId");
    let navigate = useNavigate();

    let pageAuthorityRoleId = cookies.get("role");
    if (pageAuthorityRoleId) {
        pageAuthorityRoleId = cookies.get("role").pageAuthorityRoleId;
    }

    const handleId = (id) => {
        // sessionStorage.setItem("offset", claimPagination && claimPagination.offset ? claimPagination.offset : offset);
        sessionStorage.setItem(
            "claimPagination",
            claimPagination
                ? JSON.stringify(claimPagination)
                : JSON.stringify({
                      offset: offset,
                      officeId: officeId,
                      isSubmitted: filterValues.status,
                      doctorId: doctorId,
                      patientSearch: filterValues.patientSearch,
                  })
        );
        localStorage.setItem("claimId", id);
        localStorage.removeItem("claimPath");
        localStorage.setItem("claimPath", "claim");
    };

    const handlePageClick = (event) => {
        sessionStorage.removeItem("claimPagination");
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const [filterValues, setFilterValues] = useState({
        status: claimPagination && claimPagination.status ? claimPagination.status : "",
        patientSearch: claimPagination && claimPagination.patientSearch ? claimPagination.patientSearch : "",
    });

    const handleFilterValues = (e) => {
        sessionStorage.removeItem("claimPagination");
        const { name, value } = e.target;
        setFilterValues({ ...filterValues, [name]: value });
        sessionStorage.setItem(
            "claimPagination",
            JSON.stringify({
                ...filterValues,
                [name]: value,
                offset: offset,
                doctorId: doctorId,
            })
        );
        setOffset(1);
        setCurrentPage(0);
    };

    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${CLAIM_LIST_API}`, {
                headers: { authorization: token.token },
                officeId: officeId,
                page: offset,
                rowsPerPage: ROWS_PER_PAGE,
                sortBy: "created_at",
                isSubmitted: filterValues.status,
                sortDirection: "DESC",
                doctorId: doctorId,
                patientSearch: filterValues.patientSearch,
            })
            .then((res) => {
                setIsLoading(false);
                setClaimList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: DELETE_CLAIM_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/${CLAIM_DETAIL}/${id}`, {
                            headers: { authorization: token.token },
                        })
                        .then((res) => {
                            Swal.fire(res.data.message, " ", "success");
                            fetchData();
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    let doctorList = [
        {
            id: "",
            firstName: SELECT_DOCTOR,
        },
    ];
    const fetchDoctorList = async (e) => {
        await axios
            .post(`${baseURL}/${ALL_DOCTOR_IN_SELECTED_OFFICE}`, {
                headers: { authorization: token.token },
                officeId: officeId,
                isDoctor: true,
            })
            .then((res) => {
                setDoctorSelect(res.data.data ? doctorList.concat(res.data.data) : doctorList);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const doctorSelectOptions =
        doctorSelect &&
        doctorSelect.map((item, i) => {
            return {
                value: item.id,
                label: item.firstName + " " + (item.lastName ? item.lastName : ""),
            };
        });

    const handleDoctorChange = async (e) => {
        sessionStorage.removeItem("claimPagination");
        setDoctorId(e.value);
        setOffset(1);
        setCurrentPage(0);
    };

    const handleAdd = () => {
        localStorage.removeItem("claimId");
        navigate("/addClaim");
    };

    const checkedChange = (id, isSentNote) => {
        Swal.fire({
            title: isSentNote === 1 ? NOTE_SENT_CLAIM_MESSAGE : NOTE_NOT_SENT_CLAIM_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .post(`${baseURL}/${UPDATE_ISSENTNOTE_CLAIM_API}`, {
                            headers: { authorization: token.token },
                            claimId: id,
                            isSentNote: isSentNote,
                        })
                        .then((res) => {
                            Swal.fire(res.data.message, " ", "success");
                            fetchData();
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    useEffect(() => {
        localStorage.removeItem("claimId");
        fetchDoctorList();
        fetchData();
    }, [offset, filterValues, doctorId]);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{CLAIM_LIST}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{CLAIM_LIST}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tabbable-line">
                                <div className="tab-content">
                                    <div className="tab-pane active fontawesome-demo" id="tab1">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card">
                                                    <div className="card-body ">
                                                        <div className="row">
                                                            <div>
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="btn-group">
                                                                            <button id="addRow" onClick={handleAdd} className="btn btn-info m-r-20">
                                                                                {ADD}
                                                                                <i className="fa fa-plus"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <Select
                                                                                    name="doctorSelect"
                                                                                    value={doctorSelectOptions && doctorSelectOptions.filter(({ value }) => value == doctorId)}
                                                                                    options={doctorSelectOptions}
                                                                                    onChange={handleDoctorChange}
                                                                                    placeholder={SELECT_DOCTOR}
                                                                                />
                                                                            </div>
                                                                            <div className="col-4">
                                                                                <input
                                                                                    className="form-control mr-sm-2"
                                                                                    name="patientSearch"
                                                                                    value={filterValues.patientSearch}
                                                                                    type="search"
                                                                                    placeholder="patient"
                                                                                    aria-label="Search"
                                                                                    onChange={handleFilterValues}
                                                                                />
                                                                            </div>
                                                                            <div className="col-4">
                                                                                <div className="row">
                                                                                    <div className="col-6">
                                                                                        <label className="mt-2">{SUBMITTED}</label>
                                                                                    </div>
                                                                                    <div className="col-6">
                                                                                        <select
                                                                                            className="form-control col-md-1"
                                                                                            name="status"
                                                                                            value={filterValues.status}
                                                                                            onChange={handleFilterValues}
                                                                                        >
                                                                                            <option value=""> {ALL} </option>
                                                                                            {Object.keys(BOOL_VALUES).map((item, i) => {
                                                                                                return (
                                                                                                    <option key={i} value={BOOL_VALUES[item]}>
                                                                                                        {item}
                                                                                                    </option>
                                                                                                );
                                                                                            })}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="table-scrollable" style={isLoading ? { border: "none" } : {}}>
                                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{CLAIM_}</th>
                                                                        <th>{NOTE_SENT}</th>
                                                                        <th>{DOCTOR}</th>
                                                                        <th>{PATIENT}</th>
                                                                        <th>{DATE_OF_SERVICE}</th>
                                                                        {pageAuthorityRoleId === 7 ? (<> </>) :
                                                                            <th>{AMOUNT}</th>
                                                                        }
                                                                        <th>{SUBMITTED_DATE}</th>
                                                                        <th>{CREATED_DATE}</th>
                                                                        <th>{CREATED_BY}</th>
                                                                        <th>{ACTION}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={isLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                                    {isLoading ? (
                                                                        <>
                                                                            <Loader />
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {!claimList ? (
                                                                                <>{isLoading ? "" : <NoDataFound />}</>
                                                                            ) : (
                                                                                claimList &&
                                                                                claimList.map((item, index) => {
                                                                                    return (
                                                                                        <tr className="odd gradeX" key={index}>
                                                                                            <td className="left">
                                                                                                <Link
                                                                                                    to="/DetailClaim"
                                                                                                    style={{ textDecoration: "underline", color: "#2a32be" }}
                                                                                                    onClick={() => handleId(item.id)}
                                                                                                >
                                                                                                    {item.code}
                                                                                                </Link>
                                                                                            </td>
                                                                                            <td className="center">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    className="checked-diagnoses checkBox mx-2"
                                                                                                    id={item.code}
                                                                                                    name={item.code}
                                                                                                    checked={item.isSentNote === 1 ? true : false}
                                                                                                    onChange={(e) => checkedChange(item.id, e.target.checked ? 1 : 0)}
                                                                                                    value={`${item.isSentNote}`}
                                                                                                />
                                                                                            </td>
                                                                                            <td className="left">
                                                                                                {item.doctor.firstName} {item.doctor.lastName}{" "}
                                                                                            </td>
                                                                                            <td className="left">
                                                                                                {item.patient.firstName} {item.patient.lastName}
                                                                                            </td>
                                                                                            <td className="left">{item.dateOfService ? dateFormateMonthDateYear(item.dateOfService) : DASH}</td>
                                                                                            {pageAuthorityRoleId === 7 ? (<> </>) :
                                                                                                <td className="left">${item.amount ? item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</td>
                                                                                            }
                                                                                            {/* <td className="left">
                                                                                                <span
                                                                                                    className={
                                                                                                        "badge " +
                                                                                                        (item.isSubmitted == 0
                                                                                                            ? "deactiveStatus badge-warning"
                                                                                                            : item.isSubmitted == 1
                                                                                                            ? "activeStatus badge-success"
                                                                                                            : "badge-danger")
                                                                                                    }
                                                                                                >
                                                                                                    {item.isSubmitted === 1 ? YES : NO}
                                                                                                </span>
                                                                                            </td> */}
                                                                                            <td className="left">{item.isSubmitted == 1 ? dateFormateMonthDateYear(item.submitted_date) : DASH}</td>
                                                                                            <td className="left">{dateFormateMonthDateYear(item.created_at)}</td>

                                                                                            <td>{item.create_by ? item.create_by : DASH}</td>
                                                                                            <td>
                                                                                                {/* {item.isSubmitted === 1 && claim24hrDate(item.submitted_date) < claim24hrDate(new Date()) ? ( */}
                                                                                                {item.isSubmitted === 1 && claim12hrDateTime(item.submitted_date) < currentDateFormate(new Date()) ? (
                                                                                                    ""
                                                                                                ) : (
                                                                                                    <NavLink to="/editClaim" className="btn btn-primary btn-xs" onClick={() => handleId(item.id)}>
                                                                                                        <i className="fa fa-pencil"></i>
                                                                                                    </NavLink>
                                                                                                )}
                                                                                                {/* {item.isSubmitted === 1 && claim24hrDate(item.submitted_date) < claim24hrDate(new Date()) ? ( */}
                                                                                                {item.isSubmitted === 1 && claim12hrDateTime(item.submitted_date) < currentDateFormate(new Date()) ? (
                                                                                                    ""
                                                                                                ) : (
                                                                                                    <button className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
                                                                                                        <i className="fa fa-trash-o"></i>
                                                                                                    </button>
                                                                                                )}
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex justify-content-between mx-4">
                                                        <div>Total {claimList == null ? "0" : rowCount} Claim</div>
                                                        <div className="pagination-center">
                                                            <ReactPaginate
                                                                initialPage={0}
                                                                pageCount={claimList == null ? "0" : pageCount}
                                                                marginPagesDisplayed={0}
                                                                pageRangeDisplayed={3}
                                                                onPageChange={handlePageClick}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                pageLinkClassName="page-link"
                                                                breakLinkClassName="page-link"
                                                                nextLinkClassName="page-link"
                                                                previousLinkClassName="page-link"
                                                                pageClassName="page-item"
                                                                breakClassName="page-item"
                                                                nextClassName="page-item"
                                                                previousClassName="page-item"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"
                                                                disableInitialCallback={true}
                                                                forcePage={claimPagination && claimPagination.offset ? claimPagination.offset - 1 : currentPage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManageClaim;
