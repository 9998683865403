import { useEffect, useRef, useState } from "react";
import {
    ACCESSLEVEL,
    ADDRESS,
    ADD_EMPLOYEE,
    CITY,
    EMAIL,
    EMPLOYEE_INFORMATION,
    FIRST_NAME,
    HOME,
    LAST_NAME,
    STATE,
    STATUS,
    ADD,
    AGREEMENT,
    OFFICE_STATUS,
    PASSWORD,
    CONFIRM_PASSWORD,
    SELECT,
    CANCEL,
    EMPLOYEE_LIST,
} from "../../../Helper/Constants";

import {
    ENTER_FNAME,
    ENTER_EMAIL,
    SELECT_ACCESSLEVEL,
    SELECT_STATUS,
    ENTER_PASSWORD,
    ENTER_CONFIRMPASSWORD,
    PASSWORDANDCONFIRMPASSWORD,
    PASSWORD_ERROR_TEXT,
    PASSWORD_DOES_NOT_MATCH,
    SELECT_AGREEMENT,
} from "../../../Helper/Messages";
import axios from "axios";
import { baseURL } from "../../../Config";
import { toast } from "react-toastify";
import { CITY_LIST_WITHOUT_PAGINATION, EMPLOYEE_ADD, ROLE_LIST_API, STATE_LIST_WITHOUT_PAGINATION } from "../../../Helper/ApiPath";
import { NavLink, useNavigate } from "react-router-dom";
import Select from "react-select";

function AddEmployee() {
    let token = JSON.parse(localStorage.getItem("logToken"));

    const [emailErr, setEmailErr] = useState(false);
    const [pwdError1, setPwdError1] = useState(false);
    const [pwdError2, setPwdError2] = useState(false);

    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const [confirmPasswordShown, setConPasswordShown] = useState(false);
    const toggleConfirmPassword = () => {
        setConPasswordShown(!confirmPasswordShown);
    };

    const navigate = useNavigate();
    const ref = useRef();

    const [employee, setEmployee] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        address: "",
        pageAuthorityRoleId: "",
        roleId: "",
        statusId: "",
        agreement: "",
    });
    const [city, setCity] = useState({
        stateId: "",
        cityId: "",
    });
    const [agreementInfo, setAgreementInfo] = useState({ preview: "", data: "" });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmployee({ ...employee, [name]: value });

        if (e.target.name === "agreement") {
            const agreement = {
                preview: URL.createObjectURL(e.target.files[0]),
                data: e.target.files[0],
            };

            const fileExtension = agreement.data.name.split(".").at(-1).toLowerCase();
            const allowedFileTypes = ["pdf", "jpg", "png", "gif"];
            if (!allowedFileTypes.includes(fileExtension)) {
                e.target.value = null;
                alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
            } else {
                setAgreementInfo(agreement);
            }
        }
    };

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validEmail = new RegExp("^[a-z0-9._:$!%-]+@[a-z0-9.-]+.[a-z]$");
        const validPassword = new RegExp("^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");

        if (!employee.email) {
            toast.error(ENTER_EMAIL);
        } else if (!validEmail.test(employee.email)) {
            setEmailErr(true);
        } else if (!employee.password) {
            toast.error(ENTER_PASSWORD);
        } else if (!validPassword.test(employee.password)) {
            setPwdError1(true);
        } else if (!employee.confirmPassword) {
            toast.error(ENTER_CONFIRMPASSWORD);
        } else if (!validPassword.test(employee.confirmPassword)) {
            setPwdError2(true);
        } else if (employee.password !== employee.confirmPassword) {
            toast.error(PASSWORDANDCONFIRMPASSWORD);
        } else if (!employee.firstName) {
            toast.error(ENTER_FNAME);
        } else if (!employee.roleId) {
            toast.error(SELECT_ACCESSLEVEL);
        } else if (!employee.statusId) {
            toast.error(SELECT_STATUS);
        } else if (!employee.agreement) {
            toast.error(SELECT_AGREEMENT);
        } else {
            setIsLoading(true);

            let formData = new FormData();
            formData.append("agreement", agreementInfo.data);
            formData.append("roleId", employee.roleId);
            formData.append("pageAuthorityRoleId", employee.pageAuthorityRoleId);
            formData.append("email", employee.email);
            formData.append("password", employee.password);
            formData.append("firstName", employee.firstName);
            formData.append("lastName", employee.lastName);
            formData.append("address", employee.address);
            formData.append("state", city.stateId);
            formData.append("city", city.cityId);
            formData.append("statusId", employee.statusId);

            await axios
                .post(`${baseURL}/${EMPLOYEE_ADD}`, formData, {
                    headers: { authorization: token.token, "Content-Type": "multipart/form-data" },
                })
                .then((res) => {
                    setCity("");
                    setCityList("");
                    if (res.status === 200) {
                        setEmployee("");
                        setIsLoading(false);
                        ref.current.value = "";
                        toast.success(res.data.message);
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const handleCancel = () => {
        navigate("/manageEmployee");
    };

    const [stateList, setStateList] = useState([]);

    const stateData = async () => {
        await axios
            .post(`${baseURL}/${STATE_LIST_WITHOUT_PAGINATION}`)
            .then((res) => {
                setStateList(res.data.data);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };

    const [cityList, setCityList] = useState([]);
    const [stateId, setStateId] = useState("");
    const handleCityChange = (e) => {
        setCity({ ...city, cityId: e.value });
    };
    const handleStateChange = (e) => {
        setCity({ ...city, stateId: e.value, cityId: "" });
        setCityList("");
        cityData();
        setStateId(e.value);
    };

    const cityData = async () => {
        axios
            .post(`${baseURL}/${CITY_LIST_WITHOUT_PAGINATION}`, {
                stateId: stateId,
                search: "",
            })
            .then((res) => {
                setCityList(res.data.data);
            });
    };
    const stateOptions =
        stateList &&
        stateList.map((item, i) => {
            return {
                id: item.id,
                value: item.id,
                label: item.name,
            };
        });

    const cityOptions =
        cityList &&
        cityList.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const [accessLevel, setAccessLevel] = useState([]);

    const fetchAccessLevel = async () => {
        await axios
            .post(`${baseURL}/${ROLE_LIST_API}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                setIsLoading(false);
                setAccessLevel(res.data.data);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const accessLevelOption =
        accessLevel &&
        accessLevel.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
                parentId: item.parentId,
            };
        });

    const handleAccessLevelChange = (e) => {
        setEmployee({ ...employee, roleId: e.parentId, pageAuthorityRoleId: e.value });
    };
    useEffect(() => {
        fetchAccessLevel();
        stateData();
        cityData();
    }, [stateId]);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{ADD_EMPLOYEE}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li>
                                    <i className="fa fa-users"></i>&nbsp;
                                    <NavLink className="parent-item" to="/manageEmployee">
                                        {EMPLOYEE_LIST}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{ADD_EMPLOYEE}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="card card-box">
                                <div className="card-head">
                                    <header>{EMPLOYEE_INFORMATION}</header>
                                </div>
                                <div className="card-body" id="bar-parent">
                                    <div action="#" id="form_sample_1" className="form-horizontal">
                                        <div className="form-body">
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {EMAIL}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        value={employee && employee.email}
                                                        onChange={handleChange}
                                                        placeholder="Enter Email"
                                                        className="form-control input-height"
                                                    />
                                                    {emailErr ? (
                                                        <p className="txtError" style={{ fontSize: "12px" }}>
                                                            Your Email is like example@gmail.com
                                                        </p>
                                                    ) : (
                                                        <p style={{ fontSize: "12px" }}>Your Email is like example@gmail.com</p>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {PASSWORD}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <div style={{ display: "flex" }}>
                                                        <input
                                                            type={passwordShown ? "text" : "password"}
                                                            name="password"
                                                            className="form-control input-height"
                                                            value={employee && employee.password}
                                                            onChange={handleChange}
                                                            placeholder="Enter Password"
                                                        />
                                                        <i
                                                            className={passwordShown ? "input-group-text input-height fa fa-eye" : "input-group-text input-height fa fa-eye-slash"}
                                                            id="basic-addon1"
                                                            onClick={togglePassword}
                                                        ></i>
                                                    </div>
                                                    {pwdError1 === false ? <p style={{ fontSize: "12px" }}> {PASSWORD_ERROR_TEXT}</p> : <p className="txtError">{PASSWORD_ERROR_TEXT}</p>}
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {CONFIRM_PASSWORD}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <div style={{ display: "flex" }}>
                                                        <input
                                                            type={confirmPasswordShown ? "text" : "password"}
                                                            name="confirmPassword"
                                                            className="form-control input-height"
                                                            value={employee && employee.confirmPassword}
                                                            onChange={handleChange}
                                                            placeholder="Enter Password"
                                                        />

                                                        <i
                                                            className={confirmPasswordShown ? "input-group-text input-height fa fa-eye" : "input-group-text input-height fa fa-eye-slash"}
                                                            id="basic-addon1"
                                                            onClick={toggleConfirmPassword}
                                                        ></i>
                                                    </div>
                                                    {pwdError2 && <p className="txtError">{PASSWORD_DOES_NOT_MATCH}</p>}
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {FIRST_NAME}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="firstName"
                                                        value={employee && employee.firstName}
                                                        onChange={handleChange}
                                                        data-required="1"
                                                        placeholder="Enter First Name"
                                                        className="form-control input-height"
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{LAST_NAME}</label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="lastName"
                                                        value={employee && employee.lastName}
                                                        onChange={handleChange}
                                                        placeholder="Enter Last Name"
                                                        className="form-control input-height"
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{ADDRESS}</label>
                                                <div className="col-md-5">
                                                    <textarea
                                                        name="address"
                                                        value={employee && employee.address}
                                                        onChange={handleChange}
                                                        placeholder="Address"
                                                        className="form-control form-control-textarea"
                                                        rows="3"
                                                    ></textarea>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{STATE}</label>
                                                <div className="col-md-5">
                                                    <Select
                                                        name="stateId"
                                                        value={stateOptions && stateOptions.filter(({ value }) => value == city.stateId)}
                                                        options={stateOptions}
                                                        onChange={handleStateChange}
                                                        placeholder={SELECT}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">{CITY}</label>
                                                <div className="col-md-5">
                                                    <Select
                                                        name="city"
                                                        value={cityOptions && cityOptions.filter(({ value }) => value == city.cityId)}
                                                        options={cityOptions}
                                                        onChange={handleCityChange}
                                                        placeholder={SELECT}
                                                    ></Select>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {ACCESSLEVEL}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <Select name="roleId" options={accessLevelOption} onChange={handleAccessLevelChange} placeholder={SELECT}></Select>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {STATUS}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <select className="form-control input-height" name="statusId" value={employee && employee.statusId} onChange={handleChange}>
                                                        <option value=""> {SELECT} </option>
                                                        {Object.keys(OFFICE_STATUS).map((item, i) => {
                                                            return (
                                                                <option key={i} value={OFFICE_STATUS[item]}>
                                                                    {item}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {AGREEMENT}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <input type="file" className="form-control" ref={ref} name="agreement" onChange={handleChange} />
                                                </div>
                                            </div>

                                            <div className="form-actions">
                                                <div className="row">
                                                    <div className="offset-md-3 col-md-9">
                                                        {isLoading ? (
                                                            <>
                                                                <button type="submit" className="btn btn-info m-r-20" disabled>
                                                                    {ADD}
                                                                </button>
                                                                <i className="fa fa-spinner fa-spin fa-2x loaderBtnSpinner"></i>
                                                            </>
                                                        ) : (
                                                            <button type="submit" className="btn btn-info m-r-20" onClick={handleSubmit}>
                                                                {ADD}
                                                            </button>
                                                        )}

                                                        <button type="button" className="btn btn-default  m-r-20" onClick={handleCancel}>
                                                            {CANCEL}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddEmployee;
