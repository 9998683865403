import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ACTION, ADD, COPY_TO_OTHER_OFFICE, DOCTOR, OFFICE, SELECT_DOCTOR, SELECT_OFFICE, DASH, NO, COPY, CANCEL } from "../../../../Helper/Constants";
import Select from "react-select";
import axios from "axios";
import { baseURL } from "../../../../Config";
import { ADD_OFFICE_DOCTOR_IN_COPY_PATIENT_API, COPY_OFFICE_DOCTOR_API } from "../../../../Helper/ApiPath";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { DELETE_MESSAGE, OFFICE_ALREADY_EXISTS, CHOOSE_OFFICE_DOCTOR } from "../../../../Helper/Messages";
import Swal from "sweetalert2";

function CopyToOffice(props) {
    const token = JSON.parse(localStorage.getItem("logToken"));
    let navigate = useNavigate();

    const [officeDoctorList, setOfficeDoctorList] = useState([]);
    let officeList = [...officeDoctorList];

    const handleDelete = async (index) => {
        Swal.fire({
            title: DELETE_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    officeList.splice(index, 1);
                    setOfficeDoctorList(officeList);
                }
            }
        });
    };

    const handleAddOfficeDoctor = async (e) => {
        await axios
            .post(`${baseURL}/${ADD_OFFICE_DOCTOR_IN_COPY_PATIENT_API}`, {
                headers: { authorization: token.token },
                officeId: props.office,
                patientFirstName: props.firstName,
                patientLastName: props.lastName,
                patientDob: props.dob,
            })
            .then((res) => {
                if (res.data.success == true) {
                    const index = officeList.findIndex((object) => {
                        return object.officeId === props.office;
                    });

                    if (index < 0) {
                        officeList.push({ doctorId: props.doctorId, doctorName: props.doctorName, officeId: props.office, officeName: props.officeName });
                        setOfficeDoctorList(officeList);
                        props.setOffice("");
                        props.setDoctorId("");
                        props.setDoctorList([]);
                    } else {
                        toast.error(OFFICE_ALREADY_EXISTS);
                    }
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleClose = () => {
        props.setShow(false);
        props.setOffice("");
        props.setDoctorId("");
        props.setDoctorList([]);
        setOfficeDoctorList([]);
    };

    const handleCopyOfficeDoctor = async () => {
        if (!officeList.length) {
            toast.error(CHOOSE_OFFICE_DOCTOR);
        } else {
            await axios
                .post(`${baseURL}/${COPY_OFFICE_DOCTOR_API}`, {
                    headers: { authorization: token.token },
                    patientCopy: officeList,
                    patientId: localStorage.getItem("patientId"),
                })
                .then((res) => {
                    toast.success(res.data.data.message);
                    Swal.fire(`<h3>Copied to</h3><h4 style="color:black;"><b>` + res.data.data.copyPatientIntoOffice.map((item) => item) + `<b/><h4 />`, " ", "success");
                    handleClose();
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    return (
        <>
            <Modal show={props.show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{COPY_TO_OTHER_OFFICE}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-6">
                                <label className="control-label col-md-2">
                                    {OFFICE}
                                    <span className="required"> * </span>
                                </label>
                                <div className="col-md-12">
                                    <Select
                                        name="office"
                                        options={props.officeOption}
                                        value={props.officeOption && props.officeOption.filter(({ value }) => value == props.office)}
                                        onChange={props.handleOfficeChange}
                                        placeholder={SELECT_OFFICE}
                                    ></Select>
                                </div>
                            </div>
                            <div className="col-6">
                                <label className="control-label col-md-2">
                                    {DOCTOR}
                                    <span className="required"> * </span>
                                </label>
                                <div className="col-md-12">
                                    <Select
                                        name="doctor"
                                        options={props.doctorOption}
                                        value={props.doctorOption && props.doctorOption.filter(({ value }) => value == props.doctorId)}
                                        onChange={props.handleDoctorChange}
                                        placeholder={SELECT_DOCTOR}
                                    ></Select>
                                </div>
                            </div>
                        </div>
                    </div>

                    {props.doctorId && props.office ? (
                        <div className="d-flex justify-content-center mt-2">
                            <button className="btn btn-info" onClick={handleAddOfficeDoctor}>
                                {ADD}
                            </button>
                        </div>
                    ) : (
                        ""
                    )}

                    <div className="table-scrollable">
                        <table className="table table-hover table-checkable order-column full-width" id="example4">
                            <thead>
                                <tr>
                                    <th>{NO}</th>
                                    <th>{OFFICE}</th>
                                    <th>{DOCTOR}</th>
                                    <th>{ACTION}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!officeList.length ? (
                                    <>
                                        {
                                            <tr className="text-center" style={{ color: "red" }}>
                                                <td colSpan={15} className="text-center mt-5" style={{ color: "red" }}>
                                                    <img alt="" src="assets/img/NoDataFound/no_data_found.png" style={{ height: "200px", width: "auto" }} />
                                                </td>
                                            </tr>
                                        }
                                    </>
                                ) : (
                                    officeList &&
                                    officeList.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{item.officeName ? item.officeName : DASH}</td>
                                                <td>{item.doctorName ? item.doctorName : DASH}</td>
                                                <td>
                                                    <button className="btn btn-danger btn-xs" onClick={() => handleDelete(i)}>
                                                        <i className="fa fa-trash-o"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })
                                )}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleCopyOfficeDoctor}>
                        {COPY}
                    </Button>
                    <Button variant="default" onClick={handleClose}>
                        {CANCEL}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CopyToOffice;
