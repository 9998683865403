import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../Config";
import { ADMIN_PROFILE } from "../../Helper/ApiPath";
import { ADMIN_INFORMATION, BIRTH_DATE, GENDER, HOME, PHONE_NO } from "../../Helper/Constants";

import { dateFormate } from "../../Helper/Helper";

function AdminProfilePage() {
    let logToken = JSON.parse(localStorage.getItem("logToken")).token;
    const navigate = useNavigate();
    const [profileInfo, setProfileInfo] = useState([]);

    const fetchData = async () => {
        await axios
            .get(`${baseURL}/${ADMIN_PROFILE}`, {
                headers: { authorization: logToken },
            })
            .then((res) => {
                setProfileInfo(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                {" "}
                                <div className="page-title">{ADMIN_INFORMATION}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{ADMIN_INFORMATION}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="profile-content">
                                <div className="row mx-5">
                                    <div className="card">
                                        <div className="white-box">
                                            <div className="tab-content">
                                                <div className="tab-pane active fontawesome-demo" id="tab1">
                                                    <div id="biography">
                                                        <>
                                                            <div className="row">
                                                                <div className="col-4 d-flex justify-content-center align-items-center" style={{ marginRight: "65px" }}>
                                                                    <img src="assets/img/dp.jpg" className="img-circle user-img-circle" alt="User Image" style={{ height: "190px" }} />
                                                                </div>
                                                                <ol className="list-group list-group-unbordered col-6">
                                                                    <h3 className="my-3">
                                                                        <b>
                                                                            {profileInfo.firstName} {profileInfo.lastName}
                                                                        </b>
                                                                    </h3>
                                                                    <h3 className="my-3">
                                                                        <b>{profileInfo.email}</b>
                                                                    </h3>
                                                                    <li className="list-group-item">
                                                                        <b>{GENDER} </b>
                                                                        <div className="profile-desc-item pull-right">{profileInfo.gender}</div>
                                                                    </li>
                                                                    <li className="list-group-item">
                                                                        <b>{BIRTH_DATE}</b>
                                                                        <div className="profile-desc-item pull-right">{dateFormate(profileInfo.dob)}</div>
                                                                    </li>

                                                                    <li className="list-group-item">
                                                                        <b>{PHONE_NO}</b>
                                                                        <div className="profile-desc-item pull-right">{profileInfo.phoneNo1}</div>
                                                                    </li>
                                                                </ol>
                                                            </div>
                                                        </>
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminProfilePage;
