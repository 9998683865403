import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../Config";
import { EDIT_EMPLOYEE_PROFILE_PIC, EMPLOYEE_PROFILE_API } from "../../Helper/ApiPath";
import { ADMIN_INFORMATION, EMPLOYEE_INFORMATION, HOME, DASH, UPLOAD, EMAIL, FIRST_NAME, LAST_NAME, ADDRESS, STATE, CITY, VIEW_PROFILE } from "../../Helper/Constants";

import { UPLOAD_IMAGE } from "../../Helper/Messages";

function EmployeeProfilePage() {
    let logToken = JSON.parse(localStorage.getItem("logToken")).token;
    let adminRole = JSON.parse(localStorage.getItem("adminRole"));
    const navigate = useNavigate();
    let [image, setImage] = useState({ preview: "", data: "" });

    const [employeeInfo, setEmployeeInfo] = useState([]);

    const employeeData = async () => {
        await axios
            .get(`${baseURL}/${EMPLOYEE_PROFILE_API}`, {
                headers: { authorization: logToken },
            })
            .then((res) => {
                setEmployeeInfo(res.data.data);
                if (res.data.data.profilePic && res.data.data.profilePic != "NULL") {
                    const img = {
                        preview: res.data.data.profilePic,
                        data: "",
                    };
                    setImage(img);
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleFileChange = (e) => {
        const img = {
            preview: URL.createObjectURL(e.target.files[0]),
            data: e.target.files[0],
        };

        const fileExtension = img.data.name.split(".").at(-1);
        const allowedFileTypes = ["jpg", "png", "jpeg"];
        if (!allowedFileTypes.includes(fileExtension)) {
            alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
        } else {
            let element = document.getElementById("button");
            element.classList.remove("d-none");
            setImage(img);
        }
    };

    const handleUpload = async () => {
        let formData = new FormData();
        if (!image.data) {
            toast.error(UPLOAD_IMAGE);
        } else {
            formData.append("profilePic", image.data);
            await axios
                .post(`${baseURL}/${EDIT_EMPLOYEE_PROFILE_PIC}`, formData, {
                    headers: { authorization: logToken, "Content-Type": "multipart/form-data", accept: "application/json" },
                })
                .then((res) => {
                    if (res.status === 200) {
                        toast.success(res.data.message);
                        let element = document.getElementById("button");
                        element.classList.add("d-none");
                        window.location.reload();
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    useEffect(() => {
        employeeData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">{adminRole == 5 ? <div className="page-title">{EMPLOYEE_INFORMATION}</div> : <div className="page-title">{ADMIN_INFORMATION}</div>}</div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                {adminRole == 5 ? <li className="active">{VIEW_PROFILE}</li> : <li className="active">{ADMIN_INFORMATION}</li>}
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="profile-content">
                                <div className="row mx-5">
                                    <div className="card">
                                        <div className="white-box">
                                            <div className="tab-content">
                                                <div className="tab-pane active fontawesome-demo" id="tab1">
                                                    <div id="biography">
                                                        <div className="row">
                                                            <div className="col-5">
                                                                <form action="" encType="multipart/form-data">
                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                        {image.preview ? (
                                                                            <img
                                                                                src={image.preview}
                                                                                width="100"
                                                                                height="100"
                                                                                className="img-circle user-img-circle my-5 "
                                                                                alt="User Image"
                                                                                style={{ height: "190px", width: "190px" }}
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                src="assets/img/logo/login-logo.png"
                                                                                className="img-circle user-img-circle my-5"
                                                                                alt="User Image"
                                                                                style={{ height: "190px" }}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </form>
                                                                <div className="row">
                                                                    <div className="offset-1 col-11 d-flex justify-content-center">
                                                                        <input type="file" name="file" id="uploadFile" onChange={handleFileChange} accept="image/png, image/jpg, image/jpeg"></input>
                                                                    </div>
                                                                    <div className="offset-5 col-7">
                                                                        <button type="button" className="btn btn-primary mt-2  d-none" onClick={handleUpload} id="button" name="profileUpload">
                                                                            {UPLOAD}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ol className="list-group list-group-unbordered col-7">
                                                                <h3 className="my-3">
                                                                    <b>
                                                                        {employeeInfo.firstName} {employeeInfo.lastName}
                                                                    </b>
                                                                </h3>
                                                                {employeeInfo.email ? (
                                                                    <li className="list-group-item">
                                                                        <b>{EMAIL}</b>
                                                                        <div className="profile-desc-item pull-right">{employeeInfo.email ? employeeInfo.email : DASH}</div>
                                                                    </li>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <li className="list-group-item">
                                                                    <b>{FIRST_NAME} </b>
                                                                    <div className="profile-desc-item pull-right">{employeeInfo.firstName ? employeeInfo.firstName : DASH}</div>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    <b>{LAST_NAME}</b>
                                                                    <div className="profile-desc-item pull-right">{employeeInfo.lastName ? employeeInfo.lastName : DASH}</div>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    <b>{ADDRESS} </b>
                                                                    <div className="profile-desc-item pull-right">{employeeInfo.address ? employeeInfo.address : DASH}</div>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    <b>{STATE} </b>
                                                                    <div className="profile-desc-item pull-right">{employeeInfo.state ? employeeInfo.state : DASH}</div>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    <b>{CITY}</b>
                                                                    <div className="profile-desc-item pull-right">{employeeInfo.city ? employeeInfo.city : DASH}</div>
                                                                </li>
                                                            </ol>
                                                        </div>

                                                        <br />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EmployeeProfilePage;
