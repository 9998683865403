import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL, frontURL } from "../../../../Config";

import { PATIENT_PROFILE, PATIENT_DOCUMENT_LIST, CHECK_CLAIM_LIST_API, ALL_DOCTOR_IN_SELECTED_OFFICE, OFFICE_LIST_WITHOUT_PAGINATION } from "../../../../Helper/ApiPath";
import {
    ACTION,
    ADDRESS,
    ADDRESS_COUNTINUED,
    BIRTH_DATE,
    CITY,
    CLAIM_CHECKLIST,
    DASH,
    DATE_OF_SERVICE,
    DOCTOR,
    FIRST_NAME,
    GENDER,
    INSURANCE_BACK,
    INSURANCE_FRONT,
    OTHERS,
    IS_MEDICARE_ELIGIBLE,
    IS_MEDICARE_ELIGIBLE_OPTION,
    LAST_NAME,
    MEDICARE_ELIGIBILITY_STARTS_AT,
    NOTE,
    PATIENT_INFO,
    SOCIAL_SECURITY_NUMBER,
    STATE,
    XRAY,
    ZIP,
    INSURANCE_COVERAGE_SUMMARY,
    DEDUCTIBLE_REMAINING,
    CO_ORDINATION_OF_BENEFITS,
    PLAN_NUMBER,
    PLAN_SPONSER,
    PLAN_NETWORK_ID,
    PRIMARY_PAYER,
    POLICY_NUMBER,
    PHONE_NO,
    WEBSITE,
    HMO,
    PPO,
    MWB,
    NAME,
    EFFECTIVE_DATE,
    CO_INSURANCE,
    DEDUCTIBLE,
    NO,
    FILE_NAME,
    THUMBNAIL,
    ID,
    PATIENT_DOCUMENTS,
    PATIENT_DOCUMENT_TYPE,
    HEIGHT_IN_INCHIES,
    DATE_OF_LAST_EXAM,
    PHYSICIAN_NAME,
    REASON_FOR_TOADAY_VISIT,
    GENERAL_HEALTH,
    GENERAL_HEALTH_OPTIONS,
    WEIGHT,
    CREATED_DATE,
    ELIGIBILITY_CHECK,
    COPY_TO_OTHER_OFFICE,
    ELIGIBILITY_HISTORY,
} from "../../../../Helper/Constants";
import { dateFormateMonthDateYear } from "../../../../Helper/Helper";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import NoDataFound from "../../../CommonComponent/NoDataFound";
import _ from "lodash";
import Cookies from "universal-cookie";
import CopyToOffice from "./CopyToOffice";
import EligibilityHistory from "./EligibilityHistory";

function PatientProfile() {
    const cookies = new Cookies();

    let isTrial = cookies.get("isTrial");
    if (isTrial) {
        isTrial = cookies.get("isTrial").isTrial;
    }
    const [benefits, setBenefits] = useState([]);
    const [status, setStatus] = useState(false);

    const [patientProfile, setPatientProfile] = useState([]);
    let patientId = localStorage.getItem("patientId");
    let token = JSON.parse(localStorage.getItem("logToken"));

    const [deductibleAmount, setdeductibleAmount] = useState("");
    const [deductibleRemaining, setdeductibleRemaining] = useState("");
    const [coinsurancePercent, setcoinsurancePercent] = useState("");
    const [plan_begin_date, setplan_begin_date] = useState("");
    const [group_name, setgroup_name] = useState("");
    const [Hmo, setHmo] = useState({});
    const [Ppo, setPpo] = useState({});
    const [Mwb, setMwb] = useState({});

    const [insuranceF, setInsuranceF] = useState([]);
    const [insuranceB, setInsuranceB] = useState([]);
    const [imageX, setImageX] = useState([]);
    const [imageN, setImageN] = useState([]);
    const [imageId, setImageId] = useState([]);
    const [imageOt, setImageOt] = useState([]);

    const navigate = useNavigate();
    const fetchdata = async () => {
        await axios
            .get(`${baseURL}/${PATIENT_PROFILE}/${patientId}`, {
                headers: { authorization: token.token },
            })
            .then(async (res) => {
                setPatientProfile(res.data.data);
                if (res.data.data.benefits) {
                    setStatus(true);
                    var elig = JSON.parse(res.data.data.benefits);

                    var allBenefits = elig.benefit;
                    var groupobj = _.find(allBenefits, { insurance_type_description: "Qualified Medicare Beneficiary" });
                    var railroad = _.find(allBenefits, { benefit_notes: "0-Beneficiary insured due to age OASI Railroad Retirement Medicare Beneficiary." });
                    var noMedicare = allBenefits.find(function (result) {
                        return (
                            result.insurance_type_description === "Medicare Part B" &&
                            result.benefit_description === "Health Benefit Plan Coverage" &&
                            result.benefit_coverage_description === "Inactive"
                        );
                    });
                    if (groupobj) {
                        setgroup_name("Qualified Medicare Beneficiary");
                    } else if (railroad) {
                        setgroup_name("Medicare Railroad");
                    } else if (noMedicare) {
                        setgroup_name("No Medicare Part B");
                    } else {
                        setgroup_name("Medicare Part B");
                    }

                    var objHMO = _.find(allBenefits, { insurance_type_description: "Health Maintenance Organization (HMO)" });

                    if (objHMO) {
                        setHmo(objHMO);
                    } else {
                        objHMO = _.find(allBenefits, { insurance_type_description: "Health Maintenance Organization (HMO) - Medicare Risk" });
                        if (objHMO) {
                            setHmo(objHMO);
                        }
                    }
                    var objPPO = _.find(allBenefits, { insurance_type_description: "Preferred Provider Organization (PPO)" });

                    if (objPPO) {
                        setPpo(objPPO);
                    }

                    var objMWB = _.find(allBenefits, { insurance_type_description: "Medicare Secondary Working Aged Beneficiary or Spouse with Employer Group Health Plan" });

                    if (objMWB) {
                        setMwb(objMWB);
                    }

                    var objdeductAmt = allBenefits.filter(function (result) {
                        return (
                            (result.insurance_type_description === "Medicare Part B" || result.benefit_notes == "0-Beneficiary insured due to age OASI Railroad Retirement Medicare Beneficiary.") &&
                            result.benefit_description === "Health Benefit Plan Coverage" &&
                            result.benefit_coverage_description === "Deductible" &&
                            result.benefit_period_description === "Calendar Year"
                        );
                    });
                    if (objdeductAmt.length > 0) {
                        setdeductibleAmount(objdeductAmt[0].benefit_amount);
                    }

                    var objdeductAmt1 = await allBenefits.filter(function (result) {
                        return (
                            result.insurance_type_description === "Qualified Medicare Beneficiary" &&
                            result.benefit_description === "Health Benefit Plan Coverage" &&
                            result.benefit_coverage_description === "Deductible" &&
                            result.benefit_period_description === "Calendar Year"
                        );
                    });
                    if (objdeductAmt1.length > 0) {
                        setdeductibleAmount(objdeductAmt1[0].benefit_amount);
                    }

                    var objdeductibleRemaining = await allBenefits.filter(function (result) {
                        return (
                            (result.insurance_type_description === "Medicare Part B" || result.benefit_notes == "0-Beneficiary insured due to age OASI Railroad Retirement Medicare Beneficiary.") &&
                            result.benefit_description === "Health Benefit Plan Coverage" &&
                            result.benefit_coverage_description === "Deductible" &&
                            result.benefit_period_description === "Remaining"
                        );
                    });

                    if (objdeductibleRemaining.length > 0) {
                        setdeductibleRemaining(objdeductibleRemaining[0].benefit_amount);
                    }

                    var objdeductibleRemainingQMO = await allBenefits.filter(function (result) {
                        return (
                            result.insurance_type_description === "Medicare Part B" && result.benefit_description === "Health Benefit Plan Coverage",
                            result.benefit_coverage_description === "Deductible" && result.benefit_period_description === "RemainingQMO Case"
                        );
                    });

                    if (objdeductibleRemainingQMO.length > 0) {
                        setdeductibleRemaining(objdeductibleRemainingQMO[0].benefit_amount);
                    }

                    var objcoinsurance = await allBenefits.filter(function (result) {
                        return (
                            (result.insurance_type_description === "Medicare Part B" || result.benefit_notes == "0-Beneficiary insured due to age OASI Railroad Retirement Medicare Beneficiary.") &&
                            result.benefit_description === "Health Benefit Plan Coverage" &&
                            result.benefit_coverage_description === "Co-Insurance"
                        );
                    });
                    if (objcoinsurance.length > 0) {
                        setcoinsurancePercent(objcoinsurance[0].benefit_percent);
                    }

                    var objcoinsurance1 = await allBenefits.filter(function (result) {
                        return (
                            result.insurance_type_description === "Qualified Medicare Beneficiary" &&
                            result.benefit_description === "Health Benefit Plan Coverage" &&
                            result.benefit_coverage_description === "Co-Insurance"
                        );
                    });

                    if (objcoinsurance1.length > 0) {
                        setcoinsurancePercent(objcoinsurance1[0].benefit_percent);
                    }

                    var objplan_begin_date = await allBenefits.filter(function (result) {
                        return (
                            (result.insurance_type_description === "Medicare Part B" || result.benefit_notes == "0-Beneficiary insured due to age OASI Railroad Retirement Medicare Beneficiary.") &&
                            result.benefit_description === "Health Benefit Plan Coverage" &&
                            result.benefit_coverage_description === "Active Coverage"
                        );
                    });

                    if (objplan_begin_date.length > 0) {
                        setplan_begin_date(objplan_begin_date[0].plan_date);
                    }

                    var objplan_begin_date1 = await allBenefits.filter(function (result) {
                        return (
                            result.insurance_type_description === "Medicare Part B" &&
                            result.benefit_description === "Health Benefit Plan Coverage" &&
                            result.benefit_coverage_description === "Active Coverage"
                        );
                    });

                    if (objplan_begin_date1.length > 0) {
                        setplan_begin_date(objplan_begin_date1[0].plan_date);
                    }
                }
            })

            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const fetchThumbnailData = async (type) => {
        axios
            .post(`${baseURL}/${PATIENT_DOCUMENT_LIST}`, {
                headers: { authorization: token.token },
                type: type,
                patientId: patientId,
            })
            .then((res) => {
                if (type == PATIENT_DOCUMENT_TYPE.INSURANCE_FRONT) {
                    setInsuranceF(res.data.data);
                }
                if (type == PATIENT_DOCUMENT_TYPE.INSURANCE_BACK) {
                    setInsuranceB(res.data.data);
                }
                if (type == PATIENT_DOCUMENT_TYPE.X_RAY) {
                    setImageX(res.data.data);
                }
                if (type == PATIENT_DOCUMENT_TYPE.NOTE) {
                    setImageN(res.data.data);
                }
                if (type == PATIENT_DOCUMENT_TYPE.ID) {
                    setImageId(res.data.data);
                }
                if (type == PATIENT_DOCUMENT_TYPE.OTHERS) {
                    setImageOt(res.data.data);
                }
            });
    };

    const handleDownload = (image, fileName, fixName) => {
        axios({
            url: `${frontURL}${image}`, //your url
            method: "GET",
            responseType: "blob", // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${fileName ? fileName : fixName + ".png"}`); //or any other extension
            //or any other extension
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        });
    };

    const [claimList, setClaimList] = useState([]);
    const officeId = localStorage.getItem("officeId");
    const [rowCount, setRowCount] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);

    const fetchClaimListData = async () => {
        await axios
            .post(`${baseURL}/${CHECK_CLAIM_LIST_API}`, {
                headers: { authorization: token.token },
                officeId: officeId,
                patientId: patientId,
                page: offset,
                rowsPerPage: 3,
            })
            .then((res) => {
                setClaimList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
    };

    const handledview = (id) => {
        localStorage.setItem("claimId", id);
        localStorage.removeItem("claimPath");
        localStorage.setItem("claimPath", "patientDetail");
        navigate("/DetailClaim");
    };

    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);

    const handleCopyOffice = () => {
        setShow(true);
        officeListData();
    };

    const [doctorId, setDoctorId] = useState("");
    const [doctorName, setDoctorName] = useState("");
    const [doctorList, setDoctorList] = useState([]);

    const doctorListData = (officeId) => {
        axios
            .post(`${baseURL}/${ALL_DOCTOR_IN_SELECTED_OFFICE}`, {
                headers: { authorization: token.token },
                officeId: officeId,
                isDoctor: true,
            })
            .then((res) => {
                setDoctorList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const [office, setOffice] = useState("");
    const [officeName, setOfficeName] = useState("");
    const [officeList, setOfficeList] = useState([]);

    const officeListData = () => {
        axios
            .post(`${baseURL}/${OFFICE_LIST_WITHOUT_PAGINATION}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                setOfficeList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const handleDoctorChange = (e) => {
        setDoctorName(e.label);
        setDoctorId(e.value);
    };

    const doctorOption =
        doctorList &&
        doctorList.map((item, i) => {
            return {
                value: item.id,
                label: item.firstName + " " + item.lastName,
            };
        });

    const officeOption =
        officeList &&
        officeList.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });
    const handleOfficeChange = (e) => {
        setOfficeName(e.label);
        setOffice(e.value);
        if (doctorListData.length >= 0) {
            setDoctorId("");
            setDoctorName("");
        }
        doctorListData(e.value);
    };

    useEffect(() => {
        fetchClaimListData();
        fetchdata();
        fetchThumbnailData(1);
        fetchThumbnailData(2);
        fetchThumbnailData(3);
        fetchThumbnailData(4);
        fetchThumbnailData(5);
        fetchThumbnailData(6);
    }, [offset]);

    const eligibilityCheck = () => {
        navigate("/eligiblePatient");
    };

    return (
        <>
            <div className="row">
                {/* /////////////////////////////////////////////////////////////////  PATIENT INFO /////////////////////////////////////////////////////////////// */}
                <CopyToOffice
                    firstName={patientProfile.firstName}
                    lastName={patientProfile.lastName}
                    dob={patientProfile.dob}
                    doctorName={doctorName}
                    officeName={officeName}
                    setDoctorId={setDoctorId}
                    setOffice={setOffice}
                    setDoctorList={setDoctorList}
                    setOfficeList={setOfficeList}
                    setShow={setShow}
                    show={show}
                    handleShow={handleShow}
                    officeOption={officeOption}
                    handleOfficeChange={handleOfficeChange}
                    doctorOption={doctorOption}
                    handleDoctorChange={handleDoctorChange}
                    doctorId={doctorId}
                    office={office}
                />
                <div className="col-12 mb-2">
                    <div className="row">
                        <div className="d-flex justify-content-end">
                            <div className="pull-right">
                                <button className="btn btn-warning" onClick={handleCopyOffice}>
                                    {COPY_TO_OTHER_OFFICE}
                                </button>
                            </div>
                            <div className="pull-right mx-3">
                                <button className="btn btn-info" onClick={eligibilityCheck}>
                                    {ELIGIBILITY_CHECK}
                                </button>
                            </div>
                            <EligibilityHistory />
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="card">
                        <div className="card-head">
                            <header>{PATIENT_INFO}</header>
                        </div>
                        <div className="card-body no-padding height-9">
                            <ul className="performance-list">
                                <li>
                                    <a className="d-flex justify-content-between" style={{ fontSize: "large", color: "#6c757d" }}>
                                        <div>
                                            <i className="fa fa-circle-o" style={{ color: "#F39C12" }}></i>
                                            {FIRST_NAME}
                                        </div>
                                        <div className="col-4">
                                            <span
                                                className={`${patientProfile.firstName ? "pull-right" : ""}`}
                                                style={{
                                                    marginRight: `${!patientProfile.firstName ? "11px" : ""}`,
                                                }}
                                            >
                                                {patientProfile.firstName ? patientProfile.firstName : DASH}
                                            </span>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a className="d-flex justify-content-between" style={{ fontSize: "large", color: "#6c757d" }}>
                                        <div>
                                            <i className="fa fa-circle-o" style={{ color: "#00A65A" }}></i>
                                            {LAST_NAME}
                                        </div>
                                        <div>
                                            <span
                                                className={`${patientProfile.lastName ? "pull-right" : ""}`}
                                                style={{
                                                    marginRight: `${!patientProfile.lastName ? "11px" : ""}`,
                                                }}
                                            >
                                                {patientProfile.lastName ? patientProfile.lastName : DASH}
                                            </span>
                                        </div>
                                    </a>
                                </li>

                                <li>
                                    <a className="d-flex justify-content-between" style={{ fontSize: "large", color: "#6c757d" }}>
                                        <div>
                                            <i className="fa fa-circle-o" style={{ color: "#F39C12" }}></i>
                                            {BIRTH_DATE}
                                        </div>
                                        <div>
                                            <span className={`${patientProfile.dob ? "pull-right" : ""}`} style={{ marginRight: `${!patientProfile.dob ? "11px" : ""}` }}>
                                                {patientProfile.dob ? dateFormateMonthDateYear(patientProfile.dob) : DASH}
                                            </span>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a className="d-flex justify-content-between" style={{ fontSize: "large", color: "#6c757d" }}>
                                        <div>
                                            <i className="fa fa-circle-o" style={{ color: "#DD4B39" }}></i>
                                            {GENDER}
                                        </div>
                                        <div>
                                            <span
                                                className={`${patientProfile.gender ? "pull-right" : ""}`}
                                                style={{
                                                    marginRight: `${!patientProfile.gender ? "11px" : ""}`,
                                                }}
                                            >
                                                {patientProfile.gender ? patientProfile.gender : DASH}
                                            </span>
                                        </div>
                                    </a>
                                </li>
                                <hr style={{ color: "black", height: "2px" }} />
                                <li>
                                    <a className="d-flex justify-content-between" style={{ fontSize: "large", color: "#6c757d" }}>
                                        <div>
                                            <i className="fa fa-circle-o" style={{ color: "#00A65A" }}></i>
                                            {ADDRESS}
                                        </div>
                                        <div>
                                            <span
                                                className={`${patientProfile.address1 ? "pull-right" : ""}`}
                                                style={{
                                                    marginRight: `${!patientProfile.address1 ? "11px" : ""}`,
                                                }}
                                            >
                                                {patientProfile.address1 ? patientProfile.address1 : DASH}
                                            </span>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a className="d-flex justify-content-between" style={{ fontSize: "large", color: "#6c757d" }}>
                                        <div>
                                            <i className="fa fa-circle-o" style={{ color: "#F39C12" }}></i>
                                            {ADDRESS_COUNTINUED}
                                        </div>
                                        <div>
                                            <span
                                                className={`${patientProfile.address2 ? "pull-right" : ""}`}
                                                style={{
                                                    marginRight: `${!patientProfile.address2 ? "11px" : ""}`,
                                                }}
                                            >
                                                {patientProfile.address2 ? patientProfile.address2 : DASH}
                                            </span>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a className="d-flex justify-content-between" style={{ fontSize: "large", color: "#6c757d" }}>
                                        <div>
                                            <i className="fa fa-circle-o" style={{ color: "#00A65A" }}></i>
                                            {STATE}
                                        </div>
                                        <div>
                                            <span
                                                className={`${patientProfile.state ? "pull-right" : ""}`}
                                                style={{
                                                    marginRight: `${!patientProfile.state ? "11px" : ""}`,
                                                }}
                                            >
                                                {patientProfile.state ? patientProfile.state : DASH}
                                            </span>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a className="d-flex justify-content-between" style={{ fontSize: "large", color: "#6c757d" }}>
                                        <div>
                                            <i className="fa fa-circle-o" style={{ color: "#DD4B39" }}></i>
                                            {CITY}
                                        </div>
                                        <div>
                                            <span className={`${patientProfile.city ? "pull-right" : ""}`} style={{ marginRight: `${!patientProfile.city ? "11px" : ""}` }}>
                                                {patientProfile.city ? patientProfile.city : DASH}
                                            </span>
                                        </div>
                                    </a>
                                </li>

                                <li>
                                    <a className="d-flex justify-content-between" style={{ fontSize: "large", color: "#6c757d" }}>
                                        <div>
                                            <i className="fa fa-circle-o" style={{ color: "#F39C12" }}></i>
                                            {ZIP}
                                        </div>
                                        <div>
                                            <span className={`${patientProfile.zip ? "pull-right" : ""}`} style={{ marginRight: `${!patientProfile.zip ? "11px" : ""}` }}>
                                                {patientProfile.zip ? patientProfile.zip : DASH}
                                            </span>
                                        </div>
                                    </a>
                                </li>
                                <hr style={{ color: "black", height: "2px" }} />
                                <li>
                                    <a className="d-flex justify-content-between" style={{ fontSize: "large", color: "#6c757d" }}>
                                        <div>
                                            <i className="fa fa-circle-o" style={{ color: "#DD4B39" }}></i>
                                            {SOCIAL_SECURITY_NUMBER}
                                        </div>
                                        <div>
                                            <span
                                                className={`${patientProfile.socialSecurityNo ? "pull-right" : ""}`}
                                                style={{
                                                    marginRight: `${!patientProfile.socialSecurityNo ? "11px" : ""}`,
                                                }}
                                            >
                                                {patientProfile.socialSecurityNo ? patientProfile.socialSecurityNo : DASH}
                                            </span>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a className="d-flex justify-content-between" style={{ fontSize: "large", color: "#6c757d" }}>
                                        <div>
                                            <i className="fa fa-circle-o" style={{ color: "#00A65A" }}></i>
                                            {IS_MEDICARE_ELIGIBLE}
                                        </div>
                                        <div>
                                            <span
                                                className={`${patientProfile.isMedicareEligible ? "pull-right" : ""}`}
                                                style={{
                                                    marginRight: `${!patientProfile.isMedicareEligible ? "11px" : ""}`,
                                                }}
                                            >
                                                {patientProfile.isMedicareEligible == IS_MEDICARE_ELIGIBLE_OPTION.YES ? "YES" : "NO"}
                                            </span>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a className="d-flex justify-content-between" style={{ fontSize: "large", color: "#6c757d" }}>
                                        <div>
                                            <i className="fa fa-circle-o" style={{ color: "#F39C12" }}></i>
                                            {MEDICARE_ELIGIBILITY_STARTS_AT}
                                        </div>
                                        <div>
                                            <span
                                                className={`${patientProfile.madicareEligibilityStartAt ? "pull-right" : ""}`}
                                                style={{
                                                    marginRight: `${!patientProfile.madicareEligibilityStartAt ? "11px" : ""}`,
                                                }}
                                            >
                                                {plan_begin_date
                                                    ? dateFormateMonthDateYear(plan_begin_date)
                                                    : patientProfile.madicareEligibilityStartAt
                                                    ? dateFormateMonthDateYear(patientProfile.madicareEligibilityStartAt)
                                                    : DASH}
                                            </span>
                                        </div>
                                    </a>
                                </li>

                                <hr style={{ color: "black", height: "2px" }} />
                                <li>
                                    <a className="d-flex justify-content-between" style={{ fontSize: "large", color: "#6c757d" }}>
                                        <div>
                                            <i className="fa fa-circle-o" style={{ color: "#DD4B39" }}></i>
                                            {HEIGHT_IN_INCHIES}
                                        </div>
                                        <div>
                                            <span
                                                className={`${patientProfile.heightInInchies ? "pull-right" : ""}`}
                                                style={{
                                                    marginRight: `${!patientProfile.heightInInchies ? "11px" : ""}`,
                                                }}
                                            >
                                                {patientProfile.heightInInchies ? patientProfile.heightInInchies : DASH}
                                            </span>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a className="d-flex justify-content-between" style={{ fontSize: "large", color: "#6c757d" }}>
                                        <div>
                                            <i className="fa fa-circle-o" style={{ color: "#F39C12" }}></i>
                                            {WEIGHT}
                                        </div>
                                        <div>
                                            <span
                                                className={`${patientProfile.weight ? "pull-right" : ""}`}
                                                style={{
                                                    marginRight: `${!patientProfile.weight ? "11px" : ""}`,
                                                }}
                                            >
                                                {patientProfile.weight ? patientProfile.weight : DASH}
                                            </span>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a className="d-flex justify-content-between" style={{ fontSize: "large", color: "#6c757d" }}>
                                        <div>
                                            <i className="fa fa-circle-o" style={{ color: "#00A65A" }}></i>
                                            {PHYSICIAN_NAME}
                                        </div>
                                        <div>
                                            <span
                                                className={`${patientProfile.physicianName ? "pull-right" : ""}`}
                                                style={{
                                                    marginRight: `${!patientProfile.physicianName ? "11px" : ""}`,
                                                }}
                                            >
                                                {patientProfile.physicianName ? patientProfile.physicianName : DASH}
                                            </span>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a className="d-flex justify-content-between" style={{ fontSize: "large", color: "#6c757d" }}>
                                        <div>
                                            <i className="fa fa-circle-o" style={{ color: "#DD4B39" }}></i>
                                            {DATE_OF_LAST_EXAM}
                                        </div>
                                        <div>
                                            <span
                                                className={`${patientProfile.dateOfLastExam ? "pull-right" : ""}`}
                                                style={{
                                                    marginRight: `${!patientProfile.dateOfLastExam ? "11px" : ""}`,
                                                }}
                                            >
                                                {patientProfile.dateOfLastExam ? dateFormateMonthDateYear(patientProfile.dateOfLastExam) : DASH}
                                            </span>
                                        </div>
                                    </a>
                                </li>

                                <li>
                                    <a className="d-flex justify-content-between" style={{ fontSize: "large", color: "#6c757d" }}>
                                        <div>
                                            <i className="fa fa-circle-o" style={{ color: "#F39C12" }}></i>
                                            {REASON_FOR_TOADAY_VISIT}
                                        </div>
                                        <div>
                                            <span
                                                className={`${patientProfile.ReasonForTodayVisit ? "pull-right" : ""}`}
                                                style={{
                                                    marginRight: `${!patientProfile.ReasonForTodayVisit ? "11px" : ""}`,
                                                }}
                                            >
                                                {patientProfile.ReasonForTodayVisit ? patientProfile.ReasonForTodayVisit : DASH}
                                            </span>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a className="d-flex justify-content-between" style={{ fontSize: "large", color: "#6c757d" }}>
                                        <div>
                                            <i className="fa fa-circle-o" style={{ color: "#00A65A" }}></i>
                                            {GENERAL_HEALTH}
                                        </div>
                                        <div>
                                            <span
                                                className={`${patientProfile.GeneralHealth ? "pull-right" : ""}`}
                                                style={{
                                                    marginRight: `${!patientProfile.GeneralHealth ? "11px" : ""}`,
                                                }}
                                            >
                                                {patientProfile.GeneralHealth ? Object.keys(GENERAL_HEALTH_OPTIONS).find((key) => GENERAL_HEALTH_OPTIONS[key] === patientProfile.GeneralHealth) : DASH}
                                            </span>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-6">
                    {/* /////////////////////////////////////////////////////////////////  ELIGIBILITY INFO  /////////////////////////////////////////////////////////////// */}

                    {status ? (
                        <div className="card">
                            <div className="card-head">
                                <header>{INSURANCE_COVERAGE_SUMMARY}</header>
                            </div>
                            <div className="card-body no-padding height-9">
                                <ul className="list-group list-group-unbordered">
                                    <li className="list-group-item">
                                        <b>{NAME} : </b> <a className="pull-right">{group_name}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>{EFFECTIVE_DATE} : </b> <a className="pull-right">{plan_begin_date ? dateFormateMonthDateYear(plan_begin_date) : DASH}</a>
                                    </li>
                                    {group_name == "No Medicare Part B" ? (
                                        <li className="list-group-item">
                                            <b>{CO_INSURANCE}: </b> <a className="pull-right">{DASH}</a>
                                        </li>
                                    ) : (
                                        <li className="list-group-item">
                                            <b>{CO_INSURANCE} : </b> <a className="pull-right">{coinsurancePercent ? coinsurancePercent + "%" : "0%"}</a>
                                        </li>
                                    )}
                                    <li className="list-group-item">
                                        <b>{DEDUCTIBLE} : </b> <a className="pull-right">{deductibleAmount ? "$" + deductibleAmount : DASH}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>{DEDUCTIBLE_REMAINING} : </b> <a className="pull-right">{deductibleRemaining ? "$" + deductibleRemaining : DASH}</a>
                                    </li>
                                    {Hmo.coordination_of_benefits ? (
                                        <>
                                            <h3 className="profile-usertitle-name text-center">
                                                <b>{HMO}</b>
                                            </h3>

                                            <li className="list-group-item">
                                                <b>{CO_ORDINATION_OF_BENEFITS} : </b> <a className="pull-right">{dateFormateMonthDateYear(Hmo.coordination_of_benefits)}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>{PLAN_NUMBER} : </b> <a className="pull-right">{Hmo.plan_number ? Hmo.plan_number : DASH}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>{PLAN_SPONSER} : </b> <a className="pull-right">{Hmo.mco_name ? Hmo.mco_name : DASH}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>{PLAN_NETWORK_ID} : </b> <a className="pull-right">{Hmo.mco_number ? Hmo.mco_number : DASH}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>{PRIMARY_PAYER} : </b> <a className="pull-right">{Hmo.entity_name ? Hmo.entity_name : DASH}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>{ADDRESS} : </b>{" "}
                                                <a className="pull-right">{Hmo.entity_addr_1 ? Hmo.entity_addr_1 + ", " + Hmo.entity_city + ", " + Hmo.entity_state + " " + Hmo.entity_zip : ""}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>{PHONE_NO} : </b> <a className="pull-right">{Hmo.entity_phone ? Hmo.entity_phone : DASH}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>{WEBSITE} : </b>{" "}
                                                <a href={Hmo.entity_website} target="_blank" className="pull-right">
                                                    {Hmo.entity_website ? Hmo.entity_website : DASH}
                                                </a>
                                            </li>
                                        </>
                                    ) : Ppo.coordination_of_benefits ? (
                                        <>
                                            <h3 className="profile-usertitle-name text-center">
                                                <b>{PPO}</b>
                                            </h3>
                                            <li className="list-group-item">
                                                <b>{CO_ORDINATION_OF_BENEFITS} : </b> <a className="pull-right">{dateFormateMonthDateYear(Ppo.coordination_of_benefits)}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>{PLAN_NUMBER} : </b> <a className="pull-right">{Ppo.plan_number}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>{PLAN_SPONSER} : </b> <a className="pull-right">{Ppo.mco_name}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>{PLAN_NETWORK_ID} : </b> <a className="pull-right">{Ppo.mco_number}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>{PRIMARY_PAYER} : </b> <a className="pull-right">{Ppo.entity_name[0]}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>{ADDRESS} : </b>{" "}
                                                <a className="pull-right">{Ppo.entity_addr_1[0] + ", " + Ppo.entity_city[0] + ", " + Ppo.entity_state[0] + " " + Ppo.entity_zip[0]}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>{PHONE_NO} : </b> <a className="pull-right">{Ppo.entity_phone[0]}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>{WEBSITE} : </b>{" "}
                                                <a href={Ppo.entity_website[0]} target="_blank" className="pull-right">
                                                    {Ppo.entity_website[0]}
                                                </a>
                                            </li>
                                        </>
                                    ) : Mwb.coordination_of_benefits ? (
                                        <>
                                            <h3 className="profile-usertitle-name text-center">
                                                <b>{MWB}</b>
                                            </h3>
                                            <li className="list-group-item">
                                                <b>{CO_ORDINATION_OF_BENEFITS} : </b> <a className="pull-right">{dateFormateMonthDateYear(Mwb.coordination_of_benefits)}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>{Mwb.insurance_type_description + " " + Mwb.benefit_coverage_description}</b>
                                            </li>
                                            <li className="list-group-item">
                                                <b>{POLICY_NUMBER} : </b> <a className="pull-right">{Mwb.policy_number}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>{PRIMARY_PAYER} : </b> <a className="pull-right">{Mwb.entity_name}</a>
                                            </li>
                                            <li className="list-group-item">
                                                <b>{Mwb.entity_addr_1}</b>
                                            </li>
                                            <li className="list-group-item">
                                                <b>{ADDRESS} : </b> <a className="pull-right">{Mwb.entity_city[0] + ", " + Mwb.entity_state[0] + " " + Mwb.entity_zip[0]}</a>
                                            </li>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </ul>
                            </div>
                        </div>
                    ) : (
                        <div className="card">
                            <div className="card-head">
                                <header>{INSURANCE_COVERAGE_SUMMARY}</header>
                            </div>
                            <div className="card-body no-padding height-9">
                                <ul className="list-group list-group-unbordered">
                                    {/* ///////////////////////////////////// HMO ///////////////////////////////////// */}
                                    <>
                                        <h3 className="profile-usertitle-name text-center">
                                            <b>{HMO}</b>
                                        </h3>
                                        <li className="list-group-item">
                                            <b>{PLAN_NUMBER} : </b> <a className="pull-right">{patientProfile.HMOInsuranceNumber ? patientProfile.HMOInsuranceNumber : DASH}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>{PLAN_SPONSER} : </b> <a className="pull-right">{patientProfile.HMOInsuranceName ? patientProfile.HMOInsuranceName : DASH}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>{PRIMARY_PAYER} : </b> <a className="pull-right">{patientProfile.HMOPayerId ? patientProfile.HMOPayerId : DASH}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>{ADDRESS} : </b> <a className="pull-right">{patientProfile.HMOPayerAddress ? patientProfile.HMOPayerAddress : DASH}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>{PHONE_NO} : </b> <a className="pull-right">{patientProfile.HMOPhone ? patientProfile.HMOPhone : DASH}</a>
                                        </li>
                                    </>
                                    {/* ///////////////////////////////////// PPO ///////////////////////////////////// */}

                                    <>
                                        <h3 className="profile-usertitle-name text-center">
                                            <b>{PPO}</b>
                                        </h3>
                                        <li className="list-group-item">
                                            <b>{PLAN_NUMBER} : </b> <a className="pull-right">{patientProfile.PPOInsuranceNumber ? patientProfile.PPOInsuranceNumber : DASH}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>{PLAN_SPONSER} : </b> <a className="pull-right">{patientProfile.PPOInsuranceName ? patientProfile.PPOInsuranceName : DASH}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>{PRIMARY_PAYER} : </b> <a className="pull-right">{patientProfile.PPOPayerId ? patientProfile.PPOPayerId : DASH}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>{ADDRESS} : </b> <a className="pull-right">{patientProfile.PPOPayerAddress ? patientProfile.PPOPayerAddress : DASH}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>{PHONE_NO} : </b> <a className="pull-right">{patientProfile.PPOPhone ? patientProfile.PPOPhone : DASH}</a>
                                        </li>
                                    </>
                                </ul>
                            </div>
                        </div>
                    )}

                    {/* /////////////////////////////////////////////////////////////////  CLAIM CHECKLIST /////////////////////////////////////////////////////////////// */}

                    <div className="row">
                        <div className="col-12">
                            <div className="card  card-box">
                                <div className="card-head">
                                    <header>{CLAIM_CHECKLIST}</header>
                                </div>
                                <div className="card-body pb-0">
                                    <div className="table-responsive mt-2">
                                        <table className="table table-striped table-bordered table-hover table-checkable order-column" id="example4">
                                            <thead>
                                                <tr>
                                                    <th>{DATE_OF_SERVICE}</th>
                                                    <th>{DOCTOR}</th>
                                                    <th>{ACTION} </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!claimList ? (
                                                    <NoDataFound />
                                                ) : (
                                                    claimList &&
                                                    claimList.map((item, i) => {
                                                        return (
                                                            <tr className="odd gradeX" key={i}>
                                                                <td>{item.dateOfService ? dateFormateMonthDateYear(item.dateOfService) : DASH}</td>
                                                                <td>
                                                                    <a>
                                                                        {" "}
                                                                        {item.doctor.firstName} {item.doctor.lastName}{" "}
                                                                    </a>
                                                                </td>
                                                                <td className="center">
                                                                    <div className="btn-group">
                                                                        <button type="button" className="btn btn-warning btn-xs" onClick={() => handledview(item.id)}>
                                                                            <i className="fa fa-eye"></i>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mx-4">
                                    <div>Total {claimList == null ? "0" : rowCount} Claims</div>
                                    <div className="pagination-center">
                                        <ReactPaginate
                                            initialPage={0}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={0}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClick}
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            pageLinkClassName="page-link"
                                            breakLinkClassName="page-link"
                                            nextLinkClassName="page-link"
                                            previousLinkClassName="page-link"
                                            pageClassName="page-item"
                                            breakClassName="page-item"
                                            nextClassName="page-item"
                                            previousClassName="page-item"
                                            previousLabel="Previous"
                                            nextLabel="Next"
                                            disableInitialCallback={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* /////////////////////////////////////////////////////////////////  PHOTOS /////////////////////////////////////////////////////////////// */}

            <div className="card">
                <div className="card-head">
                    <header>{PATIENT_DOCUMENTS}</header>
                </div>
                <div className="card-body no-padding height-9">
                    <div className="row mx-2">
                        <div className="col-6">
                            <div className="d-flex justify-content-between my-2 mx-2">
                                <div>
                                    <p style={{ fontSize: "Larger", fontWeight: "600" }}>{INSURANCE_FRONT}</p>
                                </div>
                            </div>
                            <div className="table-scrollable">
                                <table className="table table-hover table-checkable order-column full-width" id="example4">
                                    <thead>
                                        <tr>
                                            <th>{NO}</th>
                                            <th>{FILE_NAME}</th>
                                            <th>{THUMBNAIL}</th>
                                            <th>{CREATED_DATE}</th>
                                            <th>{ACTION}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!insuranceF ? (
                                            <>{<NoDataFound />}</>
                                        ) : (
                                            insuranceF &&
                                            insuranceF.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{(1 - 1) * 100 + i + 1}</td>
                                                        <td>
                                                            <div data-toggle="tooltip" title={item.fileName}>
                                                                {item.fileName ? (item.fileName.length > 20 ? item.fileName.slice(0, 20) + "..." : item.fileName) : INSURANCE_FRONT + ".png"}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {item.image.split(".").at(-1).toLowerCase() == "pdf" ? (
                                                                <button type="button" className="btn btn-outline-danger" onClick={() => window.open(item.image, "_blank")}>
                                                                    <i className="fa  fa-file-pdf-o fa-2x"></i>
                                                                </button>
                                                            ) : (
                                                                <Zoom>
                                                                    <img src={item.image} width="100" height="100" alt="Patient Document" style={{ height: "50px", width: "50px" }} />
                                                                </Zoom>
                                                            )}{" "}
                                                        </td>
                                                        <td>{item.created_at ? dateFormateMonthDateYear(item.created_at) : "-"}</td>
                                                        <td>
                                                            <button type="button" className="btn btn-warning btn-xs" onClick={() => window.open(item.image, "_blank")}>
                                                                <i className="fa  fa-eye"></i>
                                                            </button>
                                                            <button type="button" className="btn btn-success btn-xs" onClick={() => handleDownload(item.image, item.fileName, INSURANCE_FRONT)}>
                                                                <i className="fa  fa-download"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="d-flex justify-content-between my-2 mx-2">
                                <div>
                                    <p style={{ fontSize: "Larger", fontWeight: "600" }}>{INSURANCE_BACK}</p>
                                </div>
                            </div>
                            <div className="table-scrollable">
                                <table className="table table-hover table-checkable order-column full-width" id="example4">
                                    <thead>
                                        <tr>
                                            <th>{NO}</th>
                                            <th>{FILE_NAME}</th>
                                            <th>{THUMBNAIL}</th>
                                            <th>{CREATED_DATE}</th>
                                            <th>{ACTION}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!insuranceB ? (
                                            <>{<NoDataFound />}</>
                                        ) : (
                                            insuranceB &&
                                            insuranceB.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{(1 - 1) * 100 + i + 1}</td>
                                                        <td>
                                                            <div data-toggle="tooltip" title={item.fileName}>
                                                                {item.fileName ? (item.fileName.length > 20 ? item.fileName.slice(0, 20) + "..." : item.fileName) : INSURANCE_BACK + ".png"}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {item.image.split(".").at(-1).toLowerCase() == "pdf" ? (
                                                                <button type="button" className="btn btn-outline-danger" onClick={() => window.open(item.image, "_blank")}>
                                                                    <i className="fa  fa-file-pdf-o fa-2x"></i>
                                                                </button>
                                                            ) : (
                                                                <Zoom>
                                                                    <img src={item.image} width="100" height="100" alt="Patient Document" style={{ height: "50px", width: "50px" }} />
                                                                </Zoom>
                                                            )}{" "}
                                                        </td>
                                                        <td>{item.created_at ? dateFormateMonthDateYear(item.created_at) : "-"}</td>
                                                        <td>
                                                            <button type="button" className="btn btn-warning btn-xs" onClick={() => window.open(item.image, "_blank")}>
                                                                <i className="fa  fa-eye"></i>
                                                            </button>
                                                            <button type="button" className="btn btn-success btn-xs" onClick={() => handleDownload(item.image, item.fileName, INSURANCE_BACK)}>
                                                                <i className="fa  fa-download"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="d-flex justify-content-between my-2 mx-2">
                                <div>
                                    <p style={{ fontSize: "Larger", fontWeight: "600" }}>{XRAY}</p>
                                </div>
                            </div>
                            <div className="table-scrollable">
                                <table className="table table-hover table-checkable order-column full-width" id="example4">
                                    <thead>
                                        <tr>
                                            <th>{NO}</th>
                                            <th>{FILE_NAME}</th>
                                            <th>{THUMBNAIL}</th>
                                            <th>{CREATED_DATE}</th>
                                            <th>{ACTION}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!imageX ? (
                                            <>{<NoDataFound />}</>
                                        ) : (
                                            imageX &&
                                            imageX.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{(1 - 1) * 100 + i + 1}</td>
                                                        <td>
                                                            <div data-toggle="tooltip" title={item.fileName}>
                                                                {item.fileName ? (item.fileName.length > 20 ? item.fileName.slice(0, 20) + "..." : item.fileName) : XRAY + ".png"}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {item.image.split(".").at(-1).toLowerCase() == "pdf" ? (
                                                                <button type="button" className="btn btn-outline-danger" onClick={() => window.open(item.image, "_blank")}>
                                                                    <i className="fa  fa-file-pdf-o fa-2x"></i>
                                                                </button>
                                                            ) : (
                                                                <Zoom>
                                                                    <img src={item.image} width="100" height="100" alt="Patient Document" style={{ height: "50px", width: "50px" }} />
                                                                </Zoom>
                                                            )}{" "}
                                                        </td>
                                                        <td>{item.created_at ? dateFormateMonthDateYear(item.created_at) : "-"}</td>
                                                        <td>
                                                            <button type="button" className="btn btn-warning btn-xs" onClick={() => window.open(item.image, "_blank")}>
                                                                <i className="fa  fa-eye"></i>
                                                            </button>
                                                            <button type="button" className="btn btn-success btn-xs" onClick={() => handleDownload(item.image, item.fileName, XRAY)}>
                                                                <i className="fa  fa-download"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="d-flex justify-content-between my-2 mx-2">
                                <div>
                                    <p style={{ fontSize: "Larger", fontWeight: "600" }}>{NOTE}</p>
                                </div>
                            </div>
                            <div className="table-scrollable">
                                <table className="table table-hover table-checkable order-column full-width" id="example4">
                                    <thead>
                                        <tr>
                                            <th>{NO}</th>
                                            <th>{FILE_NAME}</th>
                                            <th>{THUMBNAIL}</th>
                                            <th>{CREATED_DATE}</th>
                                            <th>{ACTION}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!imageN ? (
                                            <>{<NoDataFound />}</>
                                        ) : (
                                            imageN &&
                                            imageN.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{(1 - 1) * 100 + i + 1}</td>
                                                        <td>
                                                            <div data-toggle="tooltip" title={item.fileName}>
                                                                {item.fileName ? (item.fileName.length > 20 ? item.fileName.slice(0, 20) + "..." : item.fileName) : NOTE + ".png"}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {item.image.split(".").at(-1).toLowerCase() == "pdf" ? (
                                                                <button type="button" className="btn btn-outline-danger" onClick={() => window.open(item.image, "_blank")}>
                                                                    <i className="fa  fa-file-pdf-o fa-2x"></i>
                                                                </button>
                                                            ) : (
                                                                <Zoom>
                                                                    <img src={item.image} width="100" height="100" alt="Patient Document" style={{ height: "50px", width: "50px" }} />
                                                                </Zoom>
                                                            )}{" "}
                                                        </td>
                                                        <td>{item.created_at ? dateFormateMonthDateYear(item.created_at) : "-"}</td>
                                                        <td>
                                                            <button type="button" className="btn btn-warning btn-xs" onClick={() => window.open(item.image, "_blank")}>
                                                                <i className="fa  fa-eye"></i>
                                                            </button>
                                                            <button type="button" className="btn btn-success btn-xs" onClick={() => handleDownload(item.image, item.fileName, NOTE)}>
                                                                <i className="fa  fa-download"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="d-flex justify-content-between my-2 mx-2">
                                <div>
                                    <p style={{ fontSize: "Larger", fontWeight: "600" }}>{ID}</p>
                                </div>
                            </div>
                            <div className="table-scrollable">
                                <table className="table table-hover table-checkable order-column full-width" id="example4">
                                    <thead>
                                        <tr>
                                            <th>{NO}</th>
                                            <th>{FILE_NAME}</th>
                                            <th>{THUMBNAIL}</th>
                                            <th>{CREATED_DATE}</th>
                                            <th>{ACTION}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!imageId ? (
                                            <>{<NoDataFound />}</>
                                        ) : (
                                            imageId &&
                                            imageId.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{(1 - 1) * 100 + i + 1}</td>
                                                        <td>
                                                            <div data-toggle="tooltip" title={item.fileName}>
                                                                {item.fileName ? (item.fileName.length > 20 ? item.fileName.slice(0, 20) + "..." : item.fileName) : ID + ".png"}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {item.image.split(".").at(-1).toLowerCase() == "pdf" ? (
                                                                <button type="button" className="btn btn-outline-danger" onClick={() => window.open(item.image, "_blank")}>
                                                                    <i className="fa  fa-file-pdf-o fa-2x"></i>
                                                                </button>
                                                            ) : (
                                                                <Zoom>
                                                                    <img src={item.image} width="100" height="100" alt="Patient Document" style={{ height: "50px", width: "50px" }} />
                                                                </Zoom>
                                                            )}{" "}
                                                        </td>
                                                        <td>{item.created_at ? dateFormateMonthDateYear(item.created_at) : "-"}</td>
                                                        <td>
                                                            <button type="button" className="btn btn-warning btn-xs" onClick={() => window.open(item.image, "_blank")}>
                                                                <i className="fa  fa-eye"></i>
                                                            </button>
                                                            <button type="button" className="btn btn-success btn-xs" onClick={() => handleDownload(item.image, item.fileName, ID)}>
                                                                <i className="fa  fa-download"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="d-flex justify-content-between my-2 mx-2">
                                <div>
                                    <p style={{ fontSize: "Larger", fontWeight: "600" }}>{OTHERS}</p>
                                </div>
                            </div>
                            <div className="table-scrollable">
                                <table className="table table-hover table-checkable order-column full-width" id="example4">
                                    <thead>
                                        <tr>
                                            <th>{NO}</th>
                                            <th>{FILE_NAME}</th>
                                            <th>{THUMBNAIL}</th>
                                            <th>{CREATED_DATE}</th>
                                            <th>{ACTION}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!imageOt ? (
                                            <>{<NoDataFound />}</>
                                        ) : (
                                            imageOt &&
                                            imageOt.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{(1 - 1) * 100 + i + 1}</td>
                                                        <td>
                                                            <div data-toggle="tooltip" title={item.fileName}>
                                                                {item.fileName ? (item.fileName.length > 20 ? item.fileName.slice(0, 20) + "..." : item.fileName) : OTHERS + ".png"}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {item.image.split(".").at(-1).toLowerCase() == "pdf" ? (
                                                                <button type="button" className="btn btn-outline-danger" onClick={() => window.open(item.image, "_blank")}>
                                                                    <i className="fa  fa-file-pdf-o fa-2x"></i>
                                                                </button>
                                                            ) : (
                                                                <Zoom>
                                                                    <img src={item.image} width="100" height="100" alt="Patient Document" style={{ height: "50px", width: "50px" }} />
                                                                </Zoom>
                                                            )}{" "}
                                                        </td>
                                                        <td>{item.created_at ? dateFormateMonthDateYear(item.created_at) : "-"}</td>
                                                        <td>
                                                            <button type="button" className="btn btn-warning btn-xs" onClick={() => window.open(item.image, "_blank")}>
                                                                <i className="fa  fa-eye"></i>
                                                            </button>
                                                            <button type="button" className="btn btn-success btn-xs" onClick={() => handleDownload(item.image, item.fileName, OTHERS)}>
                                                                <i className="fa  fa-download"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PatientProfile;
