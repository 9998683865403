export const ROLES = {
  ADMIN: 1,
  DOCTOR: 2,
  PRACTICE: 3,
  EMPLOYEE: 4,
  EMPLOYEE_ADMIN: 5,
};

export const ROLE_SELECT = {
  Admin: 5,
  Doctor: 2,
};

export const FORMTYPE = {
  CHECKBOX: 1,
  INPUT: 2,
  // DROPDOWN: 3,
};
export const FORMTYPE_DROPDOWN = {
  "Check Box": 1,
  "Input Box": 2,
  // "Drop-Down": 3,
};

export const PAGES = {
  Dashboard: 1,
  Doctor: 2,
  Office: 3,
  Patient: 4,
  Employee: 5,
  ChangeRequest: 6,
  Practice: 7,
  Trial: 8,
  Claim: 9,
  EOB: 10,
  Configuration: 11,
  Notes: 12,
  Documents: 13,
  OfficeEmployee: 14,
  Receipt: 21,
  SpecialtyCptgroup: 23,
};

export const DOCTOR_PAGES = {
  Dashboard: 14,
  OfficeEmployee: 15,
  Patient: 16,
  Claim: 17,
  EOB: 18,
  Documents: 19,
  Notes: 20,
  Receipt: 22,
};

export const PATIENT_DOCUMENT_TYPE = {
  INSURANCE_FRONT: 1,
  INSURANCE_BACK: 2,
  X_RAY: 3,
  NOTE: 4,
  ID: 5,
  OTHERS: 6,
};

export const PATIENT_DOCUMENT_DRODOWN = {
  "-- SELECT --": 0,
  "INSURANCE FRONT": 1,
  "INSURANCE BACK": 2,
  "X RAY": 3,
  NOTE: 4,
  ID: 5,
  OTHERS: 6,
};

export const PROCEDURE_TYPE = {
  NEW_PATIENT: 1,
  UPPER_JAW: 2,
  LOWER_JAW: 3,
};
export const PROCEDURE_TYPE_WITH_VALUE = {
  "Evaluation and Management": 1,
  "Upper Jaw": 2,
  "Lower Jaw": 3,
};

export const BLOOD_DRAWN_FROM_VALUE = {
  "Anticubital Fossa": "Anticubital Fossa",
  Hand: "Hand",
  Wrist: "Wrist",
  Forearm: "Forearm",
  Foot: "Foot",
};

export const DOCUMENTTYPE = {
  "Pre-Authorization-Note": 3,
  "Comprehensive-Note": 2,
  "OP-Note": 1,
};

export const OFFICE_STATUS = {
  Active: 1,
  Deactive: 2,
};
export const DIAGNOSES_STATUS = {
  Active: 1,
  Deactive: 2,
};
export const TABS_STATUS = {
  Active: 1,
  Deactive: 2,
};
export const DOCUMENT_STATUS = {
  Active: 1,
  Deactive: 2,
};
export const GROUP_STATUS = {
  Active: 1,
  Deactive: 2,
};
export const ROLE_STATUS = {
  Active: 1,
  Deactive: 2,
};
export const QUESTION_STATUS = {
  Active: 1,
  Deactive: 2,
};
export const PROCEDURE_STATUS = {
  Active: 1,
  Deactive: 2,
};
export const DOCTOR_STATUS = {
  Active: 1,
  Deactive: 2,
};
export const NORMAL_STATUS = {
  Active: 1,
  Deactive: 2,
};
export const IS_TRIAL = {
  Doctor: 0,
  Trial: 1,
};
export const REQUEST_DATA_STATUS = {
  Approve: 1,
  Reject: 2,
  Pending: 0,
};
export const IS_MEDICARE_ELIGIBLE_OPTION = {
  YES: 1,
  NO: 0,
};

export const BOOL_VALUES = {
  YES: 1,
  NO: 0,
};

export const CHOOSE_DOCTOR_TYPE = {
  Dentist: 1,
  Chiro: 2,
  Accu: 3,
};

export const PROCEDURE_DOCTOR_TYPE = {
  Dentist: 1,
  Chiro: 2,
  Accu: 3,
};
export const TEST = {
  Test1: 1,
  Test2: 2,
  Test3: 3,
};

export const GENERAL_HEALTH_OPTIONS = {
  Excellent: 1,
  Good: 2,
  Fair: 3,
  Bad: 4,
};

export const PRE_AUTH_STATUS = {
  Pending: 2,
  "Requesting info/Correction": 5,
  "Submitted-Pending approval": 7,
  Approved: 1,
  Denied: 6,
  Other: 8,
};
export const OFFICE_EMPLOYEE_STATUS = {
  Active: 1,
  Deactive: 2,
  Pending: 7,
};
//////////////////////////////////////////////////////////// ADD EMPLOYEE

export const ACCESSLEVELSTATUS = {
  Admin: 5,
  Level1: 4,
};

////////////////////////////////////////////////////////////  CLAIM LISTS

export const ACCELERATE_WAITING_PERIOD = {
  "Established Patient Level 1": 1,
  "Established Patient Level 2": 2,
  "New Patient Level 1": 3,
  "New Patient Level 2": 4,
};
export const SERVICE_LOCATION_TYPE = {
  Office: 1,
  RV: 2,
  // Hospital: 3,
  "Hospital - Inpatient": 3,
  "Hospital - Outpatient": 4,
  "ASC - Ambulatory Surgical Center": 5,
};

////////////////////////////////////////////////////////////  CLAIM LISTS

export const ELIGIBILITY_NAME = {
  "Medicare Part B": 1,
  "Qualified Medicare Beneficiary": 2,
  Railroad: 3,
  "No Medicare Part B": 4,
};

/////////////////////////////////////////////////////////// NOTICE STATUS
export const NOTICESTATUS = {
  Active: 1,
  Hold: 2,
  Inactive: 3,
};

export const POPUPSTATUS = {
  Yes: 1,
  No: 0,
};

/////////////////////////////////////////////////////

export const RELATIONSHIP_SHIP_OPTIONS = [
  { value: "01", label: "Spouse" },
  { value: "04", label: "Grandfather or Grandmother" },
  { value: "05", label: "Grandson or Granddaughter" },
  { value: "07", label: "Nephew or Niece" },
  { value: "09", label: "Adopted Child" },
  { value: "10", label: "Foster Child" },
  { value: "15", label: "Ward" },
  { value: "17", label: "Stepson or Stepdaughter" },
  { value: "18", label: "Self" },
  { value: "19", label: "Child" },
  { value: "20", label: "Employee" },
  { value: "21", label: "Unknown" },
  { value: "22", label: "Handicapped Dependent" },
  { value: "23", label: "Sponsored Dependent" },
  { value: "24", label: "Dependent of a Minor Dependent" },
  { value: "29", label: "Significant Other" },
  { value: "32", label: "Mother" },
  { value: "33", label: "Father" },
  { value: "34", label: "Other Adult" },
  { value: "36", label: "Emancipated Minor" },
  { value: "39", label: "Organ Donor" },
  { value: "40", label: "Cadaver Donor" },
  { value: "41", label: "Injured Plaintiff" },
  { value: "43", label: "Child Where Insured Has No Financial Responsibility" },
  { value: "53", label: "Life Partner" },
  { value: "G8", label: "Other Relationship" },
];

//////////////////////////////////////////////////////////// BUTTON
export const PHONE_LENGTH = 20;
export const ROWS_PER_PAGE = 10;
export const MAX_PROCEDURE_LENGTH = 2;
export const MIN_PROCEDURE_LENGTH = 0;

export const ALL = "-- ALL --";
export const OR = "---------- OR ----------";
export const SELECT = "-- Select --";
export const SELECT_SPECIALTY = "-- Select Speciality--";
export const SELECT_GENDER = "-- Select Gender --";
export const SELECT_DOCUMENT_TYPE = "-- Select Document Type --";
export const SELECT_OFFICE = "-- Select Office --";
export const SELECT_DOCTOR = "-- Select Doctor --";
export const SELECT_TAB = "-- Select Tab --";
export const SELECT_GROUP = "-- Select Group --";
export const SELECT_DIAGNOSES = "-- Select Diagnoses --";
export const SELECT_PATIENT = "-- Select Patient --";
export const SELECT_PAYER = "-- Select Payer --";
export const SELECT_RELATIONSHIP = "-- Select Relationship --";
export const SELECT_WAITING_PERIOD = " -- Select Waiting Period --";
export const SELECT_SERVICE_LOCATION_TYPE =
  "-- Select Service Location Type --";
export const SELECT_QUESTION = "-- Select Question --";

export const TOTAL_CLAIM = "Total Claim";
export const TOTAL_DOCTOR = "Total Doctor";
export const TOTAL_OFFICE_EMPLOYEE = "Total Office Employee";
export const NOTICE = "Notice";
export const PENDING_REQUEST = "Pending Request";
export const TOTAL_PATIENT = "Total Patient";
export const CLAIM_COUNT_PER_DOCTOR = "Claim Count Per Doctor";
export const LAST_1_WEEK_CLAIM_LIST = "Last 1 Week Claim List";
export const NEW_REGISTRATION_REQUEST = "New Registration Request";
export const NEW_OFFICE_EMPLOYEE_REQUEST = "New Office Employee Request";
export const BILLING = "Billing";
export const GENERAL = "General";
export const SUPPORT = "Support";
export const ENROLLMENT = "Enrollment";
export const CONTACTS = "Contacts";
export const CA_MEDICARE_SOUTH = "CA Medicare South";

export const YES = "Yes";
export const NO = "No";
export const ACTIVE = "Active";
export const DEACTIVE = "Deactive";
export const VIEW = "View";
export const COUNT = "Count";

export const STOP_IMPERSONATING = "Stop Impersonating";
export const IMPERSONATING = "Impersonating";
export const SWITCH = "Switch";
export const LOGIN = "Log In";
export const LOGOUT = "Log out";
export const REGISTER = "Register";
export const VERIFY_DOCUMET = "verify Document";
export const UPLOAD_DOCUMET = "Upload Document";
export const DOCUMENT_TYPE = "Document Type";
export const SHOW_PASSWORD = "Show Password";
export const OLD_PASSWORD = "Old Password";
export const NEW_PASSWORD = "New Password";
export const CHANGE_PASSWORD = "Change Password";

export const ACCESSIBLE = "accessible";
export const ADMIN_INFORMATION = "Admin Information";
export const CONFIRMATION = "Confirmation";

export const DOCTOR = "Doctor";
export const DOCTOR_DETAIL = "Doctor Detail";
export const ADD_DOCTOR = "Add Doctor";
export const MANAGE_DOCTOR = "Manage Doctor";
export const DOCTOR_LIST = "Doctor List";
export const DOCTOR_LIST_NOTES = "Doctor List Notes";
export const DOCTOR_INFORMATION = "Doctor Information";
export const DOCTOR_PROFILE = "Doctor Profile";
export const OFFICE_EMPLOYEE_PROFILE = "Office Employee Profile";
export const EDIT_DOCTOR_TITLE = "Edit doctor";

export const OFFICE = "Office";
export const ADD_OFFICE = "Add Office";
export const MANAGE_OFFICE = "Manage Office";
export const OFFICE_LIST = "Office List";
export const OFFICE_INFORMATION = "Office Information";

export const EMPLOYEE = "Employee";
export const ADD_EMPLOYEE = "Add Employee";
export const ADD_OFFICE_EMPLOYEE = "Add Office Employee";
export const EDIT_OFFICE_EMPLOYEE = "Edit Office Employee";
export const MANAGE_EMPLOYEE = "Manage Employee";
export const OFFICE_EMPLOYEE = "Office Employee";
export const EMPLOYEE_LIST = "Employee List";
export const OFFICE_EMPLOYEE_LIST = "Office Employee List";
export const PRACTICE_LIST = "Practice List";
export const ACCESSLEVEL = "Access Level";
export const AGREEMENT = "Agreement";
export const AGREEMENT_FILE = "Agreement File";
export const EMPLOYEE_INFORMATION = "Employee Information";
export const OFFICE_EMPLOYEE_INFORMATION = "Office Employee Information";
export const EDIT_EMPLOYEES = "Edit Employee";

export const SUPER_ADMIN = "Super Admin";

export const EDIT_PROFILE_INFORMATION = "Edit Profile Information";
export const VIEW_PROFILE_INFORMATION = "View Profile Information";
export const EDIT_PROFILE = "Edit Profile";
export const VIEW_PROFILE = "View Profile";
export const VIEW_OFFICE_EMPLOYEE = "View Office Employee";
export const EDIT_OFFICE = "Edit Office";

export const SUBMIT = "Submit";
export const PRINT = "Print";
export const UPLOAD = "Upload";
export const REMOVE_PROFILE = "Remove";
export const UPDATE = "Update";
export const PREVIOUS = "Previous";
export const SAVE = "Save";
export const CLEAR = "Clear";
export const PREVIEW = "Save & Preview";
export const NEXT = "Next";
export const SEND = "Send";
export const VERIFY = "Verify";
export const CONFIRM = "Confirm";
export const CANCEL = "Cancel";
export const CLOSE = "Close";
export const RESET = "Reset";
export const EDIT = "Edit";
export const SAVE_NEXT = "Save & Next";
export const DELETE = "Delete";
export const ASSIGN = "Assign";
export const ADD = "Add";
export const COPY = "Copy";
export const RESET_PASSWORD = "Reset Password";
export const MANUAL_RESET_PASSWORD = "Manual Reset Password";
export const DASH = "-";
export const DOWNLOAD = "Download";
export const BACK = "Back";
export const FINISH = "Finish";
export const APPLY = "Apply";
export const RESULT = "Result";
export const CHECK = "check";
export const SEARCH = "Search";
export const ELIGIBILITY_SAMPLE = "Eligibility Sample";
export const SKIP_TODAY = "Skip Today";
export const CAPTURE = "Capture";
export const RETAKE_IMAGE = "Retake Image";

//////////////////////////////////////////////////////////// Add Patient
export const ADDRESS_COUNTINUED = "Address Countinued";
export const SOCIAL_SECURITY_NUMBER = "Social Security Number";
export const IS_MEDICARE_ELIGIBLE = "Is Medicare Eligible";
export const MEDICARE_ELIGIBILITY_STARTS_AT = "Medicare Eligibility Starts At";
export const MEDICARE_PATIENT_ID = "Medicare Patient ID";

export const PPO_ADVANTAGE_PLAN_NUMBER = "PPO/Advantage Plan Number";
export const PPO_ADVANTAGE_PLAN_SPONSOR = "PPO/Advantage Plan Sponsor";
export const PPO_ADVANTAGE_PAYER_ID = "PPO/Advantage Plan Payer";
export const PPO_ADVANTAGE_PAYER_ADDRESS = "PPO/Advantage Address";
export const PPO_ADVANTAGE_PLAN_PHONE = "PPO/Advantage Phone";

export const HMO_ADVANTAGE_PLAN_NUMBER = "HMO Plan Number";
export const HMO_ADVANTAGE_PLAN_SPONSOR = "HMO Plan Sponsor";
export const HMO_ADVANTAGE_PAYER_ID = "HMO Plan Payer";
export const HMO_ADVANTAGE_PAYER_ADDRESS = "HMO Address";
export const HMO_ADVANTAGE_PLAN_PHONE = "HMO Phone";
//////////////////////////////////////////////////////////// PATIENT CHART
export const PATIENT_CHART = "Patient Chart";
export const TAB_LIST = "Tab List";
export const GROUP_LIST = "Group List";
export const MANAGE_GROUP = "Manage Group";
export const MANAGE_TAB = "Manage Tab";
export const QUESTION_LIST = "Question List";
export const MANAGE_QUESTION = "Manage Question";
export const ADD_TAB = "Add Tab";
export const ADD_QUESTION = "Add Question";
export const UPDATE_TAB = "Update Tab";
export const UPDATE_QUESTION = "Update Question";
export const ADD_GROUP = "Add Group";
export const UPDATE_GROUP = "Update Group";
export const QUESTION_NAME = "Question Name";
export const TAB_NAME = "Tab Name";
export const GROUP_NAME = "Group Name";
export const FORM_TYPE = "Form Type";
export const ASSIGN_GROUP = "Assign Group";
export const UPLOAD_EXCEL = "Upload Excel";
export const DOWNLOAD_EXCEL = "Download Excel";

//////////////////////////////////////////////////////////// TAB 1
export const PATIENT_TAB = "PATIENT";
export const HEIGHT_IN_INCHIES = "Height In Inchies";
export const WEIGHT = "Weight";
export const PHYSICIAN_NAME = "Physician Name";
export const DATE_OF_LAST_EXAM = "Date Of Last Exam";
export const REASON_FOR_TOADAY_VISIT = "Reason For Today's Visit";
export const GENERAL_HEALTH = "General Health";

//////////////////////////////////////////////////////////// TAB 2
export const PRIVATE_INSURANCE = "PRIVATE INSURANCE";
export const PRIMARY_INSURANCE_NAME = "Primary Insurance Name";
export const INSURANCE_ID = "Insurance Id";
export const SUBSCRIBER_NUMBER = "Subscriber Number";
export const GROUP_NUMBER = "Group Number";
export const INSURANCE_ADDRESS1 = "Insurance Address1";
export const INSURANCE_ADDRESS2 = "Insurance Address2";
export const INSURANCE_CITY = "Insurance City";
export const INSURANCE_STATE = "Insurance State";
export const INSURANCE_ZIP = "Insurance Zip";
export const REFERRING_PHYSICIAN_NAME = "Referring Physician Name";
export const REFERRING_PHYSICIAN_NAME1 = "Referring Physician Name 1";
export const REFERRING_PHYSICIAN_NAME2 = "Referring Physician Name 2";
export const REFERRING_PHYSICIAN_NAME3 = "Referring Physician Name 3";
export const REFERRING_PHYSICIAN_PHONE1 = "Referring Physician Phone 1";
export const REFERRING_PHYSICIAN_PHONE2 = "Referring Physician Phone 2";
export const REFERRING_PHYSICIAN_PHONE3 = "Referring Physician Phone 3";

//////////////////////////////////////////////////////////// TAB 3
export const ORAL_HEALTH = "ORAL HEALTH";

//////////////////////////////////////////////////////////// TAB 4
export const ALLERGIES = "ALLERGIES";

//////////////////////////////////////////////////////////// TAB 5
export const MEDICAL_HISTORY = "MEDICAL HISTORY";

//////////////////////////////////////////////////////////// TAB 6
export const TREATMENT = "TREATMENT";

//////////////////////////////////////////////////////////// TAB 7
export const MEDICATION = "MEDICATION";

//////////////////////////////////////////////////////////// ELIGIBILITY DETAILS

export const ELIGIBILITY_DETAILS = "Eligibility Details";
export const INSURANCE_COVERAGE_SUMMARY = "Insurance Coverage Summary";
export const CO_INSURANCE = "Co Insurance";
export const DEDUCTIBLE = "Deductible";
export const DEDUCTIBLE_REMAINING = "Deductible Remaining";
export const POLICY = "policy";
export const PLAN_COVERAGE = "Plans";
export const MEDICARE_PART = "Medicare Part";
export const BENEFITS = "Benefits";
export const COVERAGE = "Coverage";
export const NOTES = "Notes";

export const MB = "Medicare Part B";
export const MB_HMO = "Medicare Part B + HMO";
export const MB_PPO = "Medicare Part B + PPO";
export const QMB = "Qualified Medicare Beneficiary";
export const QMB_HMO = "Qualified Medicare Beneficiary + HMO";
export const QMB_PPO = "Qualified Medicare Beneficiary + PPO";
export const RAILROAD = "Railroad";
export const RAILROAD_HMO = "Railroad + HMO";
export const RAILROAD_PPO = "Railroad + PPO";
export const NO_MB = "No Medicare Part B";

//////////////////////////////////////////////////////////// ELIGIBILITY TITLE

export const BENEFIT_AMOUNT = "benefit_amount";
export const BENEFIT_CODE = "benefit_code";
export const BENEFIT_COVERAGE_CODE = "benefit_coverage_code";
export const BENEFIT_COVERAGE_DESCRIPTION = "benefit_coverage_description";
export const BENEFIT_DESCRIPTION = "benefit_description";
export const BENEFIT_PERIOD_CODE = "benefit_period_code";
export const BENEFIT_PERIOD_DESCRIPTION = "benefit_period_description";
export const INSURANCE_TYPE_CODE = "insurance_type_code";
export const INSURANCE_TYPE_DESCRIPTION = "insurance_type_description";
export const PLAN_DATE = "plan_date";
export const BANEFIT_NOTES = "benefit_notes";
export const SAVE_PATIENT = "Save Patient";

//////////////////// HMO /////////

export const CO_ORDINATION_OF_BENEFITS = "Co-Ordination of benefits";
export const PLAN_NUMBER = "Plan number";
export const PLAN_SPONSER = "Plan sponser";
export const PLAN_NETWORK_ID = "Plan network ID";
export const PRIMARY_PAYER = "Primary payer";
export const WEBSITE = "Website";
export const HMO = " Health Maintenance Organization (HMO)";
export const PPO = "Preferred Provider Organization (PPO)";
export const MWB = "Medicare Working Beneficiary";
//////////////////////////////////////////////////////////// PATIENT IMAGES

export const INSURANCE_FRONT = "Insurance(Front)";
export const INSURANCE_BACK = "Insurance(Back)";
export const XRAY = "X-Ray";
export const NOTE = "NOTE";
export const ID = "ID";
export const OTHERS = "Others";

//////////////////////////////////////////////////////////// PATIENT TABLE TITLE
export const FILE_NAME = "File Name";
export const THUMBNAIL = "Thumbnail";
export const CREATED_BY = "Created By";
export const ADD_PATIENT = "Add Patient";
export const EDIT_PATIENT = "Edit Patient";
export const ELIGIBLE_PATIENT = "Eligible Patient";
export const ALL_PATIENT = "All Patient";
export const CREATE_PATIENT = "Create Patient";
export const PATIENT_LIST = "Patient List";
export const PATIENT_DOCUMENTS = "Patient Documents";
export const PATIENT_DETAIL = "Patient Detail";
export const PATIENT_INFO = "Patient Information";
export const CLAIM_CHECKLIST = "Claim Checklists";
export const REGISTER_DATE = "Registered Date";
export const MEDICARE = "Medicare #";
export const OFCLAIMS = "# of Claims";
export const SUBMITTED_DATE = "Submitted Date";
export const DATE_OF_SERVICE = "Date Of Service";
export const CHECK_PERSON = "Check Person";
export const CHECK_DATE = "Check Date";
export const PRACTICE = "Practice";
export const DATE_OF_THIS_ELIGIBILITY_CHECK = "Date Of This Eligibility Check";
export const ELIGIBILITY = "Eligibility";
export const EFFECTIVE_DATE = "Effective Date";
export const TERMINATION_DATE = "Termination Date";
export const BENEFICIARY_DETAILS = "PART-B : Benificiary Details";
export const HMO_MCO = "HMO/MCO";
export const INFORMATION = "Information";
export const INSURANCE_NAME = "Insurance Name";
export const POLICY_NUMBER = "Policy Number";
export const MCO_PBP_PLAN_NUMBER = "MCO PBP Plan Number";
export const MCO_PBP_PLAN_NAME = "MCO PBP Plan Name";
export const MCO_PLAN_TYPE = "MCO Plan Type";
export const MCO_BILL_OPTION_CODE = "MCO Bill Option Code";
export const CONTACT_WEBSITE = "Contact Website";
export const ADD_ELIGIBLE_PATIENT = "Eligibile Patient";
export const ELIGIBILITY_CHECK = "Eligibility Check";
export const ELIGIBILITY_HISTORY = "Eligibility History";
export const COPY_TO_OTHER_OFFICE = "Copy To Other Office";
//////////////////////////////////////////////////////////// OFFICE/DOCTOR TABLE TITLE

export const APPROVE = "Approve";
export const REJECT = "Reject";
export const DATE = "Date";
export const OFFICE_NAME = "Office Name";
export const EMPLOYEE_NAME = "Employee Name";
export const PHONE_NO = "Phone";
export const ADDRESS = "Address";
export const ADDRESS2 = "Address 2";
export const CITY = "City";
export const STATE = "State";
export const ZIP = "Zip";
export const DOCTOR_SPECIALTY = "Specialty";
export const DOCTOR_NAME = "Doctor Name";
export const EDIT_DELETE = "Action";
export const ACTION = "Action";
export const FIRST_NAME = "First Name";
export const LAST_NAME = "Last Name";
export const EMAIL = "Email";
export const PASSWORD = "Password";
export const CONFIRM_PASSWORD = "Confirm Password";
export const BIRTH_DATE = "Birth Date";
export const GENDER = "Gender";
export const MALE = "Male";
export const FEMALE = "Female";
export const PROFILE_PICTURE = "Profile Picture";
export const FEE = "Fee(%)";
export const $FEE = "Fee";
export const UNIT = "Unit";
export const SORT_PRIORITY = "Sort Priority";
export const NOTES_ONLY = "Note Only";
export const PRIORITY = "Priority";
export const COMPREHENSIVE_PRIORITY = "Comprehensive Priority";
export const PRE_AUTH_PRIORITY = "Pre Authorization Priority";
export const STATUS = "Status";
export const COMMENT = "Comment";
export const AUTH = "Auth#";
export const FILE = "File";
export const LOCATION = "Location";
export const CODE = "Code";
export const RESET_TYPE = "Reset Type";
export const NAME = "Name";
export const SHORT_NAME = "Short Name";
export const VALUE = "Value";
export const MIDDLE_NAME = "Middle Name";
export const NPI1 = "NPI 1";
export const NPI2 = "NPI 2";
export const SSN = "SSN";
export const TAXID = "Tax ID";
export const PTAN_NUMBER = "PTAN Number";
export const DOCTOR_CODE = "Doctor Code";
export const TYPE = "Type";
export const DESCRIPTION = "Description";
export const DESCRIPTION2 = "Description2";
export const DESCRIPTION3 = "Description3";
export const CPT = "CPT";
export const LICENSE_NUMBER = "License Number";
export const PROCEDURE_CODE = "Code";
export const LAST_MONTH_NOTES_COUNT = "Last Month";
export const CURRENT_MONTH_NOTES_COUNT = "Current Month";
export const TOTAL_NOTES_COUNT = "Total Notes";

////////////////////////////////////////////////////////////  TITLE

export const ASSIGN_DOCTOR = "Assign Doctor";
export const ASSIGN_NEW_DOCTOR = "Assign New Doctor";
export const ASSIGNED_DOCTOR = "Assigned Doctor";
export const NO_DATA_FOUND = "No Data Found!";
export const DASHBOARD = "Dashboard";
export const DOCTOR_DASHBOARD = "Doctor Dashboard";
export const ONEMEDALL = "OneMedAll";
export const BILL_WITH_US = "Bill With Us";
export const FORGOT_PASSWORD = "Forgot Password?";
export const CHOOSE_OFFICE = "Choose Office";
export const CHOOSE_DOCTOR = "Choose Doctor";
export const EDIT_DOCTOR_DETAILS = "Edit Doctor Detail";
export const EDIT_OFFICE_DETAILS = "Edit Office Detail";
export const EDIT_PROFILE_DETAILS = "Edit Profile Detail";

////////////////////////////////////////////////////////////  Claim List
export const CLAIM_ = "Claim #";
export const CLAIM = "Claim";
export const CLAIM_LIST = "Claim List";
export const CREATE_CLAIM = "Create Claim";
export const PATIENT = "Patient";
export const SUBMITTED = "Submitted";
export const AMOUNT = "Amount";
export const CREATED_DATE = "Created Date";
export const ALL_CLAIM = "All Claim";
export const NOTE_SENT = "Notes Sent";

///////////////////////////////////////////////////////// OP NOTES
export const CLAIM_OP_NOTES_LIST = "Claim OP Notes List";
export const OP_NOTES = "OP Notes";
export const OP_NOTES_SAMPLE = "OP Notes Sample";
export const ADDITIONAL_QUESTION = "Additional Question about OP Notes";
export const OPERATION_TYPE = "Operation Type";
export const MAXILLA = "Maxilla";
export const MANDIBLE = "Mandible";
export const MAXILLA_MANDIBLE = "Maxilla/Mandible";
export const BLOOD_DRAW = "Blood Draw";
export const SURGICAL_EXTRACTION_OF_TEETH = "Surgical Extraction of teeth";
export const REMOVAL_OF_CYSTS_BY_ENCLUEA = "Removal of cysts by enuclea";
export const EXCISION_OF_INFECTION = "Excision of infection";
export const RED_VIAL = "Red Vial";
export const WHITE_VIAL = "White Vial";
export const Fibrin_clot_removed = "Fibrin clot removed";
export const Blood_drawn_From = "Blood drawn from";
export const LEFT = "Left";
export const RIGHT = "Right";
export const BOTH = "Both";
export const TOOTH = "Tooth";
export const PATHOLOGY = "Pathology";
export const ORAL_COM_FIS = "Oralantral communication with fistula";
export const CANINE_EMINENCE = "Distance distal to the Canine eminence";
export const ANTERIOR_NAS_SPINE = "Distance distal to the Anterior nasal spine";
export const FROM_CANINE_EMINENCE = "Distance from Canine eminence";
export const LATERAL_SINUS_LIFT = "Lateral sinus lift membrane cover";
export const SITE = "Site";
export const VESTIBULOPLASTY40840 = "Vestibuloplasty; anterior";
export const VESTIBULOPLASTY40842 = "Vestibuloplasty; posterior, unilateral";
export const VESTIBULOPLASTY40843 = "Vestibuloplasty; posterior, bilateral";
export const VESTIBULOPLASTY = "Vestibuloplasty; entire arch";
export const VESTIBULOPLASTY40845 = "Vestibuloplasty; complex";
export const BLADE = "Blade";
export const LENGTH = "Length";
export const MAIN_INCISION_LENGTH = "Main Incision Length";
export const VERTICAL_INCISION_LENGTH = "Vertical Incision Length";
export const SINUS_LIFT_REPAIR_OF_WINDOW = "Sinus Lift/Repair of Window";
export const WINDOW = "Window";
export const MEMBRANE_CHECK = "Membrane check";
export const LIFT_MEMBRANE = "Lift Membrane";
export const LATERAL_WINDOW = "Lateral Window";
export const ALVEOLAR_RIDGE = "Distance from Alveolar ridge";
export const GRAFT = "Graft";
export const IMPLANT_PARTIAL = "Implants Partial(1-3)";
export const IMPLANT_FULL_ARCH = "Implants Full Arch(4-7)";
export const BONE_GRAFT_UPPER_JAW = "Bone Graft Upper Jaw";
export const BONE_GRAFT_LOWER_JAW = "Bone Graft Lower Jaw";
export const OTHER_BONE_MATERIAL = "Other Bone grafting Material Used";
export const NOT_PATIENT_BONE = "(not Patient bone)";
export const MEMBRANE = "Membrane";
export const SINUSOTOMY = "Sinusotomy";
export const OSTEOPLASTY = "Osteoplasty, facial bones; reduction";
export const FLAP = "Flap";
export const CM = "cm";
export const MM = "mm";
export const VERTICAL_INCISION = "Vertical Incision";
export const ARTICAINE_HCL_4_EPINEPHRINE =
  "Articaine HCL 4% and Epinephrine 1:100,000";
export const BUPIVACAINE_HCL_05_EPINEPHRINE =
  "Bupivacaine HCL 0.5% and Epinephrine 1:200,000";
export const LIDICAINE_HCL_2_EPINEPHRINE =
  "Lidicaine HCL 2% and Epinephrine 1:100,000";
export const LIDICAINE_HCL_2_EPINEPHRINE_50 =
  "Lidicaine HCL 2% and Epinephrine 1:50,000";
export const MEPIVACAINE_HCL = "Mepivacaine HCL 3%";
export const NITROUS_ADMINISTERED = "Nitrous Administered ?";
export const NITROUS_QSTN1 = "% nitrous administered";
export const NITROUS_QSTN2 = "Min. Duration";
export const BLOOD_PRESSURE = "Blood Pressure";
export const HEART_RATE = "Heart Rate";
export const ASA = "ASA";
export const PSA = "PSA";
export const MSA = "MSA";
export const IAN = "IAN";
export const BUCCAL = "Buccal";
export const PALATAL = "Palatal";
export const MENTAL = "Mental";
export const LINGUAL = "Lingual";
export const ESTIMATED_BLOOD_LOSS = "Estimated blood loss";
export const CONSULTATION_TIME = "Consultation time";
export const START_TIME = "Start Time";
export const END_TIME = "End Time";
export const CC = "cc";
export const MINUTES = "Minutes";
export const ALVEOLECTOMY = "Alveolectomy";
export const PATIENT_HARVEST_BONE =
  "Patient harvested bone from osteplasty amount";
export const MEMBRANE_UNIT = "Number of Membranes";
export const MEMEBRANE_TYPE = "Membrane Type";
export const MEMBRANE_SIZE = "Membrane Size";
export const BONE_GRAFT_TYPE = "Bone graft Type";
export const BONE_GRAFT_QUANTITY = "Bone Graft Quantity";
export const TYPE_OF_SUTURE = "Type of subperiosteal Sutures";
export const TYPE_OF_CLOSE_SUTURE =
  "Type of final closure Sutures(PGA, Gut, Chromic Gut, PTFE, Silk)";
export const POSTERIOR_CANINE_EMINENCE = "Posterior Canine Eminence";
export const IMPLANT_MANUFACTURE = "Implant Manufacture";
export const IMPLANT_SIZE = "Implant Size";
export const DIAMETER = "Diameter";
export const NCM = "NcM";
export const OST_PLG_SIZE = "OsteoGen Plug Size";
export const MM_EXM_4 = "mm (Example:4 10*20 mm)";
export const ONL_OST_PLG = "Onlay OsteoGen Plug";
export const MM_EXM_2 = "mm (Example:2 10*20 mm)";

////////////////////////////////////////////////////////////  editOfficeAdmin
export const BASIC_INFORMATION = "Basic Information";
export const HOME = "Home";

////////////////////////////////////////////////////////////  CLAIM TITLES

export const GENERAL_INFORMATION = "General Information";
export const DETAIL_CLAIM = "Detail Claim";
export const VIEW_CLAIM = "View Claim";
export const ADD_CLAIM = "Add Claim";
export const EDIT_CLAIM = "Edit Claim";
export const CLAIM_INFORMATION = "Claim Information";
export const PAYER = "Payer";
export const DEFAULT_PAYER = "Default Payer";
export const PRE_AUTHORIZATION = "Pre-authorization #";
export const SERVICE_LOCATION_TYPE_TITLE = "Service Location Type";
export const PATIENT_NAME = "Patient Name";
export const RENDERRING_PROVIDER = "Rendering Provider";
export const ACCELERATE_WAITING_PERIOD_TITLE = "Accelerate Waiting Period";
export const CHRONIC_MAXILLARY =
  "Chronic Maxillary Sinusitis Oral Antral Opening";
export const JAW_INFORMATION = "Jaw Inflammation";
export const JAW_PAIN = "Jaw Pain";
export const CELLULITIES_AND_ABSCESS = "Cellulitis and Abscess";
export const ENLARGEMENT_LYMPH_NODES = "Enlargement Lymph Nodes";
export const MODERATE_ATROPHY =
  "Moderate Atrophy of Edentulous Alveolus, Mandible";
export const SEVERE_ATROPHY = "Severe Atrophy of Edentulous Alveolus, Maxilla";
export const PROCEDURE = "Procedure";
export const NEW_PATIENT = "Evaluation and Management";
export const UPPER_JAW = "Upper Jaw";
export const LOWER_JAW = "Lower Jaw";
export const DIAGNOSES = "Diagnoses";
export const DEFAULT_DIAGNOSES = "Default Diagnoses";
export const CLAIM_CODE = "Code";
export const MALIGNANCY = "Malignancy";
export const PROVIDER_INFORMATION = "Provider Information";
export const SUBSCRIBER_INFORMATION = "Subscriber Information";

////////////////////////////////////////////////////////////  STATE-CITY TITLES

export const EDIT_CITY = "Edit City";
export const EDIT_STATE = "Edit State";
export const ADD_CITY = "Add City";
export const ADD_STATE = "Add State";

////////////////////////////////////////////////////////////  SPECIALTY-CPTGROUP TITLES

export const EDIT_CPTGROUP = "Edit CPT Group";
export const EDIT_SPECIALTY = "Edit Specialty";
export const ADD_CPTGROUP = "Add CPT Group";
export const ADD_SPECIALTY = "Add Specialty";

////////////////////////////////////////////////////// Eligiblity

export const WHAT_TO_DO_NEXT = "WHAT TO DO NEXT?";
export const Control_Number = "Control Number";
export const tradingPartnerServiceId = "Trading PartnerServiceId";
export const ENTITY_TYPE = "Entity Type";
export const ENTITY_IDENTIFIER = "Entity Identifier";
export const INSURED_INDICATOR = "Insured Indicator";
export const MAINTENANCE_REASON_CODE = "Maintenance Reason Code";
export const MAINTENANCE_TYPE_CODE = "Maintenance Type Code";
export const MEMBER_ID = "Member Id";
export const RELATION_TO_SUBSCRIBER = "Relation To Subscriber";
export const DATE_OF_BIRTH = "Date of Birth";
export const POSTAL_CODE = "Postal Code";

////////////////////////////////////////////////////// CONFIGURATION
export const CONFIGURATION = "Configuration";
export const STATE_INFORMATION = "State Information";
export const MANAGE_STATE_CITY = "Manage State & City";
export const STATE_AND_CITY = "State & City";
export const MANAGE_SPECIALTY_CPTGROUP = "Manage Specialty & CPT Group";
export const SPECIALTY_CPTGROUP = "Specialty & CPT Group";
export const SPECIALTY_INFORMATION = "Specialty Information";
export const CPTGROUP_INFORMATION = "CPT Group Information";
export const MANAGE_STATE = "Manage State";
export const MANAGE_DIAGNOSES = "Manage Diagnoses";
export const MANAGE_PROCEDURE = "Manage Procedure";
export const SPECIALTY = "Specialty";
export const CPTGROUP = "CPT Group";

////////////////////////////////////////////////////// PAYER
export const MANAGE_PAYER = "Manage Payer";
export const PAYER_LIST = "Payer List";
export const PAYER_INFORMATION = "Payer Information";
export const ADD_PAYER = "Add Payer";
export const UPDATE_PAYER = "Update Payer";

//////////////////////////////////////////////////////////// TRIAL
export const TRIAL = "Trial";
export const TRIAL_LIST = "Trial List";
export const ADD_TRIAL = "Add Trial";
export const EDIT_TRIAL = "Edit Trial";
export const TRIAL_INFORMATION = "Trial Information";
export const IS_TRIAL_LABEL = "Is Trial";

//////////////////////////////////////////////////////////// MANAGE NOTICE
export const NOTICE_LIST = "Notice List";
export const MANAGE_NOTICE = "Manage Notice";
export const ADD_NOTICE = "Add Notice";
export const EDIT_NOTICE = "Edit Notice";
export const TITLE = "Title";
export const POPUP = "PopUp";
export const POSTING_PERIOD = "Posting Period";
export const CONTENTS = "Contents";
export const ATTACHMENT = "Attachment";
export const CREATE_BY = "Create By";
export const CREATE_DATE = "Create Date";
export const UPDATE_BY = "Update By";
export const UPDATE_DATE = "Update Date";
export const NOTICE_INFORMATION = "Notice Information";

///////////////////////////////////////////////////////// ROLES
export const ROLES_LIST = "Roles List";
export const MANAGE_ROLES = "Manage Roles";
export const ROLE = "Role";
export const ROLE_ADD = "Add Role";
export const ROLE_UPDATE = "Update Role";
export const SELECT_PAGES = "Select Pages";

///////////////////////////////////////////////////////// EOB
export const EOB = "E.O.B";
export const EOB_LIST = "E.O.B List";
export const EOB_FILE = "E.O.B File";
export const EDIT_EOB_FILE = "Edit E.O.B File";
export const EOB_INFORMATION = "E.O.B Information";
export const ADD_EOB = "Add E.O.B";
export const EDIT_EOB = "Edit E.O.B";
export const DOWNLOAD_EOB = "Download E.O.B";
export const EFT_CHECK = "EFT/Check #";
export const REMIT_DATE = "Remit Date";
export const PAID_AMOUNT = "Paid Amount";
export const PAID_AMOUNT_USD = "Paid Amount($)";

///////////////////////////////////////////////////////// BWS Fee
export const RECEIPT = "BWS FEE";
export const RECEIPT_LIST = "BWS Receipt";
export const RECEIPT_NO = "Receipt #";
export const RECEIPT_DATE = "Receipt Date";
export const RECEIPT_AMOUNT = "BWS FEE";
export const RECEIPT_AMOUNT_USD = "BWS FEE($)";
export const DOWNLOAD_RECEIPT = "Download Receipt";
export const RECEIPT_FILE = "Receipt File";
export const EDIT_RECEIPT_FILE = "Edit Receipt File";
export const EDIT_RECEIPT = "Edit Receipt";
export const RECEIPT_INFORMATION = "Receipt Information";
export const ADD_RECEIPT = "Add Receipt";

///////////////////////////////////////////////////////// COMPREHENSIVE
export const COMPREHENSIVE_LIST = "Comprehensive List";
export const COMPREHENSIVE_GROUP_LIST = "Group List";
export const COMPREHENSIVE_DESCRIPTION_LIST = "Description List";
export const COMPREHENSIVE_GROUP = "Manage Group";
export const COMPREHENSIVE_DESCRIPTION = "Manage Descritption";
export const COMPREHENSIVE_INFORMATION = "Comprehensive Information";
export const ADD_COMPREHENSIVE = "Add Comprehensive";
export const COMPREHENSIVE_NOTES = "Comprehensive Notes";
export const EDIT_COMPREHENSIVE = "Edit Comprehensive";
export const COMPREHENSIVE_RESULT = "Comprehensive Result";

///////////////////////////////////////////////////////// DOCUMENT
export const DOCUMENTS = "Documents";
export const ADD_DOCUMENT = "Add Document";
export const EDIT_DOCUMENT = "Edit Document";
export const DOCUMENT_LIST = "Document List";
export const DOCUMENT_INFORMATION = "Document Information";
export const DOCUMENT_FILE = "Document File";
export const EDIT_DOCUMENT_FILE = "Edit Document File";

///////////////////////////////////////////////////////// BWS
export const BWS_PHONE = "1-800-719-4234";

///////////////////////////////////////////////////////// PRE-AUTHORIZATION
export const PREAUTHORIZATION_LIST = "Pre-Authorization List";
export const PREAUTHORIZATION_GROUP_LIST = "Pre-Authorization Group List";
export const PREAUTHORIZATION_DESCRIPTION_LIST =
  "Pre-Authorization Descritption List";
export const PREAUTHORIZATION_GROUP = "Pre-Authorization Group";
export const PREAUTHORIZATION_DESCRIPTION = "Pre-Authorization Descritption";
export const PREAUTHORIZATION_INFORMATION = "Pre-Authorization Information";
export const ADD_PREAUTHORIZATION = "Add Pre-Authorization";
export const PRE_AUTHORIZATION_NOTES = "Pre-authorization";
export const EDIT_PREAUTHORIZATION = "Edit Pre-Authorization";
export const PREAUTHORIZATION_RESULT = "Pre-Authorization Result";
export const NEW_PRE_AUTHORIZATION_REQUEST = "New Pre-authorization Request";
export const PRE_AUTHORIZATION_STATUS = "Pre-authorization Status";
