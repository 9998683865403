import { useEffect, useState } from "react";
import { ADDRESS, ADD_OFFICE, CANCEL, CITY, DOCTOR_NAME, HOME, OFFICE_INFORMATION, OFFICE_NAME, PHONE_LENGTH, PHONE_NO, SELECT, SELECT_DOCTOR, STATE, SUBMIT, ZIP } from "../../Helper/Constants";
import axios from "axios";
import { baseURL } from "../../Config";
import { toast } from "react-toastify";
import { ADMIN_OFFICE_ADD, CITY_LIST_WITHOUT_PAGINATION, MAIN_DOCTOR_LIST_API, STATE_LIST_WITHOUT_PAGINATION } from "../../Helper/ApiPath";
import { NavLink, useNavigate } from "react-router-dom";
import { CHOOSE_DOCTOR, ENTER_ADDRESS, ENTER_CITY, ENTER_OFFICE_NAME, ENTER_PHONE, ENTER_STATE, ENTER_ZIP, PHONE_NUMBER } from "../../Helper/Messages";
import Select from "react-select";

function AdminAddOffice() {
    const [isLoading, setIsLoading] = useState(false);
    const [city, setCity] = useState({
        state: "",
        city: "",
    });

    const handleCityChange = (e) => {
        setCity({ ...city, city: e.value });
    };
    const [doctorId, setDoctorId] = useState("");

    const token = JSON.parse(localStorage.getItem("logToken"));
    const navigate = useNavigate();
    const [office, setOffice] = useState({
        officename: "",
        phone: "",
        address: "",
        zip: "",
    });
    const [doctorSelect, setDoctorSelect] = useState([]);

    const fetchDoctorList = async (e) => {
        await axios
            .post(`${baseURL}/${MAIN_DOCTOR_LIST_API}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                setDoctorSelect(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const doctorSelectOptions =
        doctorSelect &&
        doctorSelect.map((item, i) => {
            return {
                value: item.id,
                label: item.firstName + " " + (item.lastName ? item.lastName : ""),
            };
        });

    const handleDoctorChange = async (e) => {
        sessionStorage.removeItem("claimDoctorId");
        sessionStorage.removeItem("claimPatientId");
        setDoctorId(e.value);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setOffice({ ...office, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!doctorId) {
            toast.error(CHOOSE_DOCTOR);
        } else if (!office.officename) {
            toast.error(ENTER_OFFICE_NAME);
        } else if (!office.phone) {
            toast.error(ENTER_PHONE);
        } else if (!office.address) {
            toast.error(ENTER_ADDRESS);
        } else if (!city.state) {
            toast.error(ENTER_STATE);
        } else if (!city.city) {
            toast.error(ENTER_CITY);
        } else if (!office.zip) {
            toast.error(ENTER_ZIP);
        } else if (office.phone.length > PHONE_LENGTH) {
            toast.error(PHONE_NUMBER);
        } else {
            setIsLoading(true);
            await axios
                .post(`${baseURL}/${ADMIN_OFFICE_ADD}`, {
                    headers: { authorization: token.token },
                    doctorId: doctorId,
                    name: office.officename,
                    phoneNo1: office.phone,
                    address: office.address,
                    state: city.state,
                    city: city.city,
                    zip: office.zip,
                    mainDoctorOfficeAddStep: true,
                })
                .then((res) => {
                    setIsLoading(false);

                    if (res.status === 200) {
                        setOffice("");
                        setCity("");
                        setCityList("");
                        setDoctorId("");
                        toast.success(res.data.message);
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const handleCancel = () => {
        navigate("/manageOffice");
    };
    ////////////////////////////////////////////////////// STATE-CITY LIST /////////////////////

    const [stateList, setStateList] = useState([]);

    const stateData = async () => {
        await axios
            .post(`${baseURL}/${STATE_LIST_WITHOUT_PAGINATION}`)
            .then((res) => {
                setStateList(res.data.data);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };

    const [cityList, setCityList] = useState([]);
    const [stateId, setStateId] = useState("");

    const handleStateChange = (e) => {
        setCity({ ...city, state: e.value, city: "" });
        setCityList("");
        cityData();
        setStateId(e.value);
    };

    const cityData = async () => {
        axios
            .post(`${baseURL}/${CITY_LIST_WITHOUT_PAGINATION}`, {
                stateId: stateId,
                search: "",
            })
            .then((res) => {
                setCityList(res.data.data);
            });
    };

    const stateOptions =
        stateList &&
        stateList.map((item, i) => {
            return {
                id: item.id,
                value: item.id,
                label: item.name,
            };
        });

    const cityOptions =
        cityList &&
        cityList.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    useEffect(() => {
        stateData();
        cityData();
        fetchDoctorList();
    }, [stateId]);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{ADD_OFFICE}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{ADD_OFFICE}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="card card-box">
                                <div className="card-head">
                                    <header>{OFFICE_INFORMATION}</header>
                                </div>

                                <div className="card-body" id="bar-parent">
                                    <form action="#" id="form_sample_1" className="form-horizontal">
                                        <div className="form-body">
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {DOCTOR_NAME}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5 mr-sm-2">
                                                    <Select
                                                        name="doctorSelect"
                                                        value={doctorSelectOptions && doctorSelectOptions.filter(({ value }) => value == doctorId)}
                                                        options={doctorSelectOptions}
                                                        onChange={handleDoctorChange}
                                                        placeholder={SELECT_DOCTOR}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {OFFICE_NAME}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="officename"
                                                        value={office && office.officename}
                                                        onChange={handleChange}
                                                        data-required="1"
                                                        placeholder="Enter Office Name"
                                                        className="form-control input-height"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {PHONE_NO}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <input
                                                        name="phone"
                                                        value={office && office.phone}
                                                        onChange={handleChange}
                                                        type="number"
                                                        placeholder="Mobile Number"
                                                        className="form-control input-height"
                                                    />{" "}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {ADDRESS}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <textarea
                                                        name="address"
                                                        value={office && office.address}
                                                        onChange={handleChange}
                                                        placeholder="Address"
                                                        className="form-control form-control-textarea"
                                                        rows="3"
                                                    ></textarea>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {STATE}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <Select
                                                        name="state"
                                                        value={stateOptions && stateOptions.filter(({ value }) => value == city.state)}
                                                        options={stateOptions}
                                                        onChange={handleStateChange}
                                                        placeholder={SELECT}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {CITY}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <Select
                                                        name="city"
                                                        value={cityOptions && cityOptions.filter(({ value }) => value == city.city)}
                                                        options={cityOptions}
                                                        onChange={handleCityChange}
                                                        placeholder={SELECT}
                                                    ></Select>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="control-label col-md-3">
                                                    {ZIP}
                                                    <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-5">
                                                    <input name="zip" value={office && office.zip} onChange={handleChange} type="text" placeholder="Zip" className="form-control input-height" />
                                                </div>
                                            </div>
                                            <div className="form-actions">
                                                <div className="row">
                                                    <div className="offset-md-3 col-md-9">
                                                        {isLoading ? (
                                                            <>
                                                                <button type="submit" className="btn btn-info m-r-20" disabled>
                                                                    {SUBMIT}
                                                                </button>
                                                                <i className="fa fa-spinner fa-spin fa-2x loaderUpdateBtnSpinner"></i>
                                                            </>
                                                        ) : (
                                                            <button type="submit" className="btn btn-info m-r-20" onClick={handleSubmit}>
                                                                {SUBMIT}
                                                            </button>
                                                        )}

                                                        <button type="reset" className="btn btn-default  m-r-20" onClick={handleCancel}>
                                                            {CANCEL}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminAddOffice;
