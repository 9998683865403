import { useState } from "react";
import {
    CANCEL,
    HOME,
    NO,
    FILE_NAME,
    THUMBNAIL,
    ACTION,
    EDIT_DOCUMENT,
    DOCUMENT_LIST,
    DOCUMENT_INFORMATION,
    TITLE,
    DESCRIPTION,
    STATUS,
    DOCUMENT_STATUS,
    SAVE,
    DOCUMENT_FILE,
} from "../../../Helper/Constants";
import axios from "axios";
import { baseURL } from "../../../Config";
import { toast } from "react-toastify";
import { DEVELOP_DOCUMENT_DETAIL_API, DOCUMENT_DELETE_API, UPLOAD_DOCUMENT_LIST_API, DOCUMENT_ADD_API } from "../../../Helper/ApiPath";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { DELETE_DOCUMENT_MESSAGE } from "../../../Helper/Messages";
import "react-datepicker/dist/react-datepicker.css";
import Zoom from "react-medium-image-zoom";
import NoDataFound from "../../CommonComponent/NoDataFound";
import Swal from "sweetalert2";
import DocumentFileUpload from "./DocumentFileUpload";
import DocumentWebcameUpload from "./DocumentWebcameUpload";

function EditDocuments() {
    const token = JSON.parse(localStorage.getItem("logToken"));
    const localDocumentId = localStorage.getItem("DocumentId");
    const navigate = useNavigate();

    const [DocumentFile, setDocumentFile] = useState([]);
    const [Document, setDocument] = useState({
        title: "",
        description: "",
        statusId: "1",
    });

    const [edit, setEdit] = useState(false);
    const [show, setShow] = useState(false);
    const [editId, setEditId] = useState("");
    const detailDocument = async (DocumentId) => {
        await axios
            .get(`${baseURL}/${DEVELOP_DOCUMENT_DETAIL_API}/${localDocumentId}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                setDocument(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const fetchThumbnailData = async () => {
        await axios
            .post(`${baseURL}/${UPLOAD_DOCUMENT_LIST_API}`, {
                headers: { authorization: token.token },
                developId: localDocumentId,
            })
            .then((res) => {
                setDocumentFile(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const handleEdit = (id) => {
        setEdit(true);
        setShow(true);
        setEditId(id);
    };
    const closeDocumentEdit = () => {
        setShow(false);
        setEdit(false);
    };
    const handleDelete = async (id) => {
        Swal.fire({
            title: DELETE_DOCUMENT_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/${DOCUMENT_DELETE_API}/${id}`, {
                            headers: { authorization: token.token },
                        })
                        .then((res) => {
                            Swal.fire(res.data.message, " ", "success");
                            fetchThumbnailData();
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios
            .post(`${baseURL}/${DOCUMENT_ADD_API}`, {
                headers: { authorization: token.token },
                developId: localDocumentId ? localDocumentId : "",
                title: Document.title,
                description: Document.description,
                statusId: Document.statusId,
            })
            .then((res) => {
                toast.success(res.data.message);
                localStorage.setItem("DocumentId", res.data.data.id);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setDocument({ ...Document, [name]: value });
    };

    const handleCancel = (e) => {
        navigate("/manageDocument");
    };
    useEffect(() => {
        fetchThumbnailData();
        detailDocument();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="page-content-wrapper">
            <div className="page-content">
                <div className="page-bar">
                    <div className="page-title-breadcrumb">
                        <div className=" pull-left">
                            <div className="page-title">{EDIT_DOCUMENT}</div>
                        </div>
                        <ol className="breadcrumb page-breadcrumb pull-right">
                            <li>
                                <i className="fa fa-home"></i>&nbsp;
                                <NavLink className="parent-item" to="/dashboard">
                                    {HOME}
                                </NavLink>
                                &nbsp;<i className="fa fa-angle-right"></i>
                            </li>
                            <li>
                                <i className="fa fa-file-text" aria-hidden="true"></i>&nbsp;
                                <NavLink className="parent-item" to="/manageDocument">
                                    {DOCUMENT_LIST}
                                </NavLink>
                                &nbsp;<i className="fa fa-angle-right"></i>
                            </li>
                            <li className="active">{DOCUMENT_LIST}</li>
                        </ol>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="card card-box">
                            <div className="card-head">
                                <header>{DOCUMENT_INFORMATION}</header>
                            </div>

                            <div>
                                <div className="row">
                                    <div id="form_sample_1" className="form-horizontal">
                                        <div className="card-body" id="bar-parent">
                                            <div action="#" id="form_sample_1" className="form-horizontal">
                                                <div className="form-body">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-3">{TITLE}</label>
                                                        <div className="col-md-5">
                                                            <input
                                                                type="text"
                                                                name="title"
                                                                value={Document && Document.title}
                                                                onChange={handleChange}
                                                                data-required="1"
                                                                placeholder="Enter Title"
                                                                className="form-control input-height"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-3">{DESCRIPTION}</label>
                                                        <div className="col-md-5">
                                                            <textarea
                                                                type="text"
                                                                rows="10"
                                                                cols="50"
                                                                name="description"
                                                                value={Document && Document.description}
                                                                onChange={handleChange}
                                                                data-required="1"
                                                                placeholder="Enter Description"
                                                                className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-3">{STATUS}</label>
                                                        <div className="col-md-5">
                                                            <select className="form-control" name="statusId" value={Document.statusId} onChange={handleChange}>
                                                                {Object.keys(DOCUMENT_STATUS).map((item, i) => {
                                                                    return (
                                                                        <option key={i} value={DOCUMENT_STATUS[item]}>
                                                                            {item}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" id="Document-image">
                                <div className="col-xl-3 col-lg-3"></div>
                                <div className="col-xl-6 col-lg-6 col-md-11 col-sm-11 mx-md-2 mx-sm-2">
                                    <div className="d-flex justify-content-between my-2 mx-2">
                                        <div>
                                            <p style={{ fontSize: "Larger", fontWeight: "600" }}>{DOCUMENT_FILE}</p>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div>
                                                <DocumentFileUpload
                                                    fetchThumbnailData={fetchThumbnailData}
                                                    closeDocumentEdit={closeDocumentEdit}
                                                    edit={edit}
                                                    editId={editId}
                                                    show={show}
                                                    localDocumentId={localDocumentId}
                                                />
                                            </div>
                                            <div style={{ marginTop: "10px" }}>
                                                <DocumentWebcameUpload fetchThumbnailData={fetchThumbnailData} localDocumentId={localDocumentId} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-scrollable">
                                        <table className="table table-hover table-checkable order-column full-width" id="example4">
                                            <thead>
                                                <tr>
                                                    <th>{NO}</th>
                                                    <th>{FILE_NAME}</th>
                                                    <th>{THUMBNAIL}</th>
                                                    <th>{ACTION}</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {!DocumentFile ? (
                                                    <>{<NoDataFound />}</>
                                                ) : (
                                                    DocumentFile &&
                                                    DocumentFile.map((item, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{(1 - 1) * 100 + i + 1}</td>
                                                                <td>{item.fileName ? item.fileName : DOCUMENT_FILE + ".png"}</td>

                                                                <td>
                                                                    {item.document && item.document.split(".").at(-1).toLowerCase() == "pdf" ? (
                                                                        <div type="button" className="btn btn-outline-danger" onClick={() => window.open(item.document, "_blank")}>
                                                                            <i className="fa  fa-file-pdf-o fa-2x my-2"></i>
                                                                        </div>
                                                                    ) : (item.document && item.document.split(".").at(-1).toLowerCase() == "jpg") ||
                                                                      item.document.split(".").at(-1).toLowerCase() == "jpeg" ||
                                                                      item.document.split(".").at(-1).toLowerCase() == "png" ? (
                                                                        <Zoom>
                                                                            <img src={item.document} width="100" height="100" alt="Document" style={{ height: "50px", width: "50px" }} />
                                                                        </Zoom>
                                                                    ) : (
                                                                        <div type="button" className="btn btn-outline-primary" onClick={() => window.open(item.document, "_blank")}>
                                                                            <i className="fa fa-file-video-o fa-2x my-2"></i>
                                                                        </div>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    <button type="button" className="btn btn-primary btn-xs" onClick={() => handleEdit(item.id)}>
                                                                        <i className="fa  fa-pencil"></i>
                                                                    </button>
                                                                    <button type="button" className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
                                                                        <i className="fa  fa-trash"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="form-actions">
                                        <div className="row">
                                            <div className="offset-md-3 col-md-9">
                                                <button className="btn btn-info m-r-20" onClick={handleSubmit}>
                                                    {SAVE}
                                                </button>
                                                <button type="reset" className="btn btn-default m-r-20" onClick={handleCancel}>
                                                    {CANCEL}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditDocuments;
