import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../Config";
import { ALREADY_ASSIGN_DOCTOR, DOCTOR_DELETE, OFFICE_DETAIL_API } from "../../Helper/ApiPath";
import { NavLink } from "react-router-dom";
import {
    ACTION,
    ADD,
    ADDRESS,
    ASSIGNED_DOCTOR,
    CHOOSE_DOCTOR_TYPE,
    CITY,
    DASH,
    DOCTOR_SPECIALTY,
    DOCTOR_STATUS,
    FIRST_NAME,
    HOME,
    LAST_NAME,
    LICENSE_NUMBER,
    NAME,
    NPI1,
    NPI2,
    OFFICE_INFORMATION,
    OFFICE_LIST,
    PHONE_NO,
    ROWS_PER_PAGE,
    SSN,
    STATE,
    STATUS,
    TAXID,
    ZIP,
} from "../../Helper/Constants";
import NoDataFound from "../CommonComponent/NoDataFound";
import { DELETE_DOCTOR_MESSAGE } from "../../Helper/Messages";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";

function AdminAssignDoctor() {
    const assignDoctorPagination = JSON.parse(sessionStorage.getItem("assignDoctorPagination"));

    const [isLoading, setIsLoading] = useState(true);
    const [doctorList, setDoctorList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(assignDoctorPagination && assignDoctorPagination.offset ? assignDoctorPagination.offset : 1);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);

    const token = JSON.parse(localStorage.getItem("logToken"));
    const officeId = localStorage.getItem("officeId");

    const navigate = useNavigate();
    const [officeDetail, SetOfficeDetail] = useState([]);
    
    const MaxDoctor = 20;

    const handlePageClick = (event) => {
        sessionStorage.removeItem("assignDoctorPagination");
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const fetchOfficeData = async () => {
        await axios
            .get(`${baseURL}/${OFFICE_DETAIL_API}/${officeId}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                SetOfficeDetail(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${ALREADY_ASSIGN_DOCTOR}`, {
                headers: { authorization: token.token },
                officeId: officeId,
                page: offset,
                rowsPerPage: ROWS_PER_PAGE,
                isPagination: true,
            })
            .then((res) => {
                setIsLoading(false);
                setDoctorList(res.data.data);
                fetchOfficeData();
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleEdit = (id) => {
        sessionStorage.setItem(
            "assignDoctorPagination",
            JSON.stringify({
                offset: offset,
            })
        );
        localStorage.setItem("doctorId", id);
        navigate("/editAssignedDoctor");
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: DELETE_DOCTOR_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/${DOCTOR_DELETE}/${officeId}/${id}`, {
                            headers: { authorization: token.token },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                fetchData();
                                Swal.fire(res.data.message, " ", "success");
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };
    const handleClick = (e) => {
        e.preventDefault();
        navigate("/addDoctor");
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset]);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{ASSIGNED_DOCTOR}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li>
                                    <i className="fa fa-briefcase"></i>&nbsp;
                                    <NavLink className="parent-item" to="/manageOffice">
                                        {OFFICE_LIST}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{ASSIGNED_DOCTOR}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        {/* //////////////////////////////////////////////////////////////// already assigned doctor //////////////////////////////////////////////// */}
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="tabbable-line">
                                        <div className="tab-content">
                                            <div className="tab-pane active fontawesome-demo" id="tab1">
                                                <div className="row">
                                                    <div className="col-md-9">
                                                        <div className="card" style={isLoading ? { height: "22rem" } : {}}>
                                                            <div className="card-body ">
                                                                <div className="row">
                                                                    <div className="col-md-6 col-sm-6 col-xs-6">
                                                                        <div className="btn-group">
                                                                            <div className="btn-group">
                                                                                <button
                                                                                    id="addRow"
                                                                                    className={`btn btn-info ${doctorList && doctorList.length >= MaxDoctor ? "disabled" : ""}`}
                                                                                    onClick={handleClick}
                                                                                >
                                                                                    {ADD}
                                                                                    <i className="fa fa-plus"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="table-scrollable" style={isLoading ? { border: "none" } : {}}>
                                                                    <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>{FIRST_NAME}</th>
                                                                                <th>{LAST_NAME}</th>
                                                                                <th>{PHONE_NO}</th>
                                                                                <th>{NPI1}</th>
                                                                                <th>{NPI2}</th>
                                                                                <th>{SSN}</th>
                                                                                <th>{TAXID}</th>
                                                                                <th>{LICENSE_NUMBER}</th>
                                                                                <th>{DOCTOR_SPECIALTY}</th>
                                                                                <th>{STATUS}</th>
                                                                                <th>{ACTION}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody style={isLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                                            {isLoading ? (
                                                                                <tr className="text-center" style={{ height: "12rem" }}>
                                                                                    <td colSpan={15} className="text-center mt-5">
                                                                                        <i
                                                                                            className="fa fa-spinner fa-spin fa-2x"
                                                                                            style={{
                                                                                                position: "relative",
                                                                                                fontSize: "55px",
                                                                                                zIndex: 2,
                                                                                            }}
                                                                                        ></i>
                                                                                    </td>
                                                                                </tr>
                                                                            ) : (
                                                                                <>
                                                                                    {!doctorList ? (
                                                                                        <>{isLoading ? "" : <NoDataFound />}</>
                                                                                    ) : (
                                                                                        doctorList &&
                                                                                        doctorList.map((item, index) => {
                                                                                            return (
                                                                                                <tr className="odd gradeX" key={index}>
                                                                                                    <td className="left">{item.firstName}</td>
                                                                                                    <td className="left">{item.lastName}</td>
                                                                                                    <td className="left">{item.phoneNo1 ? item.phoneNo1 : DASH}</td>
                                                                                                    <td className="left">{item.npi1 ? item.npi1 : DASH}</td>
                                                                                                    <td className="left">{item.npi2 ? item.npi2 : DASH}</td>
                                                                                                    <td className="left">{item.ssn ? item.ssn : DASH}</td>
                                                                                                    <td className="left">{item.taxId ? item.taxId : DASH}</td>
                                                                                                    <td className="left">{item.licenseNumber ? item.licenseNumber : DASH}</td>
                                                                                                    <td className="left">
                                                                                                        {" "}
                                                                                                        {item.office.specialty ? item.office.specialty : DASH}
                                                                                                    </td>
                                                                                                    <td className="left">
                                                                                                        <span
                                                                                                            className={
                                                                                                                "badge " +
                                                                                                                (item.statusId == 2
                                                                                                                    ? "deactiveStatus badge-warning"
                                                                                                                    : item.statusId == 1
                                                                                                                    ? "activeStatus badge-success"
                                                                                                                    : "badge-danger")
                                                                                                            }
                                                                                                        >
                                                                                                            {Object.keys(DOCTOR_STATUS).find((key) => DOCTOR_STATUS[key] === item.statusId)}
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <button className="btn btn-primary btn-xs" onClick={() => handleEdit(item.id)}>
                                                                                                            <i className="fa fa-pencil"></i>
                                                                                                        </button>
                                                                                                        <button className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
                                                                                                            <i className="fa fa-trash-o"></i>
                                                                                                        </button>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            );
                                                                                        })
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>

                                                            <div className="d-flex justify-content-between mx-4">
                                                                <div>Total {doctorList == null ? "0" : rowCount} Doctors</div>
                                                                <div className="pagination-center">
                                                                    <ReactPaginate
                                                                        initialPage={0}
                                                                        pageCount={pageCount}
                                                                        marginPagesDisplayed={0}
                                                                        pageRangeDisplayed={3}
                                                                        onPageChange={handlePageClick}
                                                                        containerClassName="pagination"
                                                                        activeClassName="active"
                                                                        pageLinkClassName="page-link"
                                                                        breakLinkClassName="page-link"
                                                                        nextLinkClassName="page-link"
                                                                        previousLinkClassName="page-link"
                                                                        pageClassName="page-item"
                                                                        breakClassName="page-item"
                                                                        nextClassName="page-item"
                                                                        previousClassName="page-item"
                                                                        previousLabel="Previous"
                                                                        nextLabel="Next"
                                                                        disableInitialCallback={true}
                                                                        forcePage={assignDoctorPagination && assignDoctorPagination.offset ? assignDoctorPagination.offset - 1 : currentPage}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className="card">
                                                            <div className="card-head">
                                                                <header>{OFFICE_INFORMATION}</header>
                                                            </div>
                                                            <div className="card-body no-padding height-9">
                                                                <div className="row"></div>
                                                                <ul className="list-group list-group-unbordered">
                                                                    <li className="list-group-item">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <b>{NAME}</b>
                                                                            </div>
                                                                            <div className="col-8">
                                                                                <a className="pull-right">{officeDetail.name ? officeDetail.name : DASH}</a>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className="list-group-item">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <b>{ADDRESS}</b>
                                                                            </div>
                                                                            <div className="col-8">
                                                                                <a className="pull-right">{officeDetail.address ? officeDetail.address : DASH}</a>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className="list-group-item">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <b>{CITY}</b>
                                                                            </div>
                                                                            <div className="col-8">
                                                                                <a className="pull-right">{officeDetail.city ? officeDetail.city : DASH}</a>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className="list-group-item">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <b>{STATE}</b>
                                                                            </div>
                                                                            <div className="col-8">
                                                                                <a className="pull-right">{officeDetail.state ? officeDetail.state : DASH}</a>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className="list-group-item">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <b>{ZIP}</b>
                                                                            </div>
                                                                            <div className="col-8">
                                                                                <a className="pull-right">{officeDetail.zip ? officeDetail.zip : DASH}</a>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li className="list-group-item">
                                                                        <div className="row">
                                                                            <div className="col-4">
                                                                                <b>{PHONE_NO}</b>
                                                                            </div>
                                                                            <div className="col-8">
                                                                                <a className="pull-right">{officeDetail.phoneNo1 ? officeDetail.phoneNo1 : DASH}</a>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminAssignDoctor;
