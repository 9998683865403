import React from "react";
import { useNavigate } from "react-router-dom";
import { CANCEL, DASH, FORMTYPE } from "../../../../Helper/Constants";
import NoDataFound from "../../../CommonComponent/NoDataFound";

function PatientTabDetails(props) {
    let navigate = useNavigate();
    const handleCancel = () => {
        navigate("/managePatient");
    };
    return (
        <>
            {!props.data ? (
                <div className="d-flex justify-content-center">
                    <NoDataFound />
                </div>
            ) : (
                <div className="row">
                    {props.group &&
                        props.group.map((item, i) => {
                            return (
                                <>
                                    <h3 className="mx-5 my-2">{item.groupName == "No Group Name" || item.groupName == undefined || item.groupName == "" ? "" : item.groupName.toUpperCase()}</h3>
                                    <hr style={{ border: "1px solid", height: "0.1rem" }} />
                                    {item.data.map((item, i) => {
                                        return (
                                            <>
                                                {item.formType == FORMTYPE.CHECKBOX ? (
                                                    <>
                                                        <div className="offset-1 col-4">
                                                            {" "}
                                                            <i className="fa fa-circle-o mx-2 " style={{ color: "#F39C12" }}></i>
                                                            {item.name}
                                                        </div>
                                                        <div className="col-1 d-flex justify-content-start my-2">
                                                            <i className={`${item.value ? "fa fa-check-square-o" : "fa fa-square-o"}`} aria-hidden="true"></i>
                                                        </div>
                                                    </>
                                                ) : item.formType == FORMTYPE.INPUT ? (
                                                    <>
                                                        <div className="col-3 offset-1 my-2">
                                                            <i className="fa fa-circle-o mx-2 " style={{ color: "#F39C12" }}></i>
                                                            {item.name}
                                                        </div>
                                                        <label className="col-2 d-flex justify-content-center my-2">{item.value ? item.value : DASH}</label>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </>
                                        );
                                    })}
                                </>
                            );
                        })}
                    <div className="form-actions mt-5">
                        <div className="center">
                            <button className="btn btn-default" onClick={handleCancel}>
                                {CANCEL}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default PatientTabDetails;
