import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { baseURL } from "../../../Config";
import { ADMIN_ADD_GROUP_API, ADMIN_DETAIL_PATIENT_GROUP_API, ADMIN_GROUP_LIST_API } from "../../../Helper/ApiPath";
import { ADD, ALL, TABS_STATUS, HOME, NAME, STATUS, TAB_LIST, ACTION, ROWS_PER_PAGE, NO, SORT_PRIORITY, GROUP_LIST, MANAGE_GROUP } from "../../../Helper/Constants";
import { DELETE_MESSAGE, ENTER_PRIORITY_LESS_THAN_MAX_PRIORITY, ENTER_GROUP_NAME, MAX_PRIORITY } from "../../../Helper/Messages";
import Loader from "../../CommonComponent/Loader";
import NoDataFound from "../../CommonComponent/NoDataFound";
import Add_EditGroup from "./Add_EditGroup";

function ManageTabgroup() {
    const [isLoading, setIsLoading] = useState(false);
    const [groupList, setGroupList] = useState([]);
    const [offset, setOffset] = useState(1);
    const [rowCount, setRowCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [show, setshow] = useState(false);
    const [edit, setEdit] = useState(false);
    const [groupId, setGroupId] = useState("");

    const [filterValues, setfilterValues] = useState({
        search: "",
        status: "",
    });
    const navigate = useNavigate();
    const token = JSON.parse(localStorage.getItem("logToken"));
    const tabId = localStorage.getItem("tabId");

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
        // postsPerPage(event.selected + 1);
    };

    const handleFilterValues = (e) => {
        const { name, value } = e.target;
        setfilterValues({ ...filterValues, [name]: value });
        setOffset(1);
        setCurrentPage(0);
    };

    const handleAdd = () => {
        setshow(true);
        setEdit(false);
    };

    const handleClose = () => {
        setGroup({
            name: "",
            priorityList: MAX_PRIORITY,
            statusId: "1",
        });
        setshow(false);
    };

    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${ADMIN_GROUP_LIST_API}`, {
                headers: { authorization: token.token },
                page: offset,
                rowsPerPage: ROWS_PER_PAGE,
                statusId: filterValues.status,
                search: filterValues.search,
                tabId,
            })
            .then((res) => {
                setIsLoading(false);
                setGroupList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
                // console.log(res);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleEdit = async (id) => {
        setEdit(true);
        setshow(true);
        setGroupId(id);
        detailGroup(id);
    };

    const [group, setGroup] = useState({
        name: "",
        priorityList: MAX_PRIORITY,
        statusId: "1",
    });

    const handlechange = (e) => {
        let { name, value } = e.target;
        setGroup({ ...group, [name]: value });
    };

    const detailGroup = async (id) => {
        await axios
            .get(`${baseURL}/${ADMIN_DETAIL_PATIENT_GROUP_API}/${id}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                if (res.status === 200) {
                    setGroup(res.data.data);
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const addGroupdata = async () => {
        if (!group.name) {
            toast.error(ENTER_GROUP_NAME);
        } else if (group.priorityList && group.priorityList > MAX_PRIORITY) {
            toast.error(ENTER_PRIORITY_LESS_THAN_MAX_PRIORITY);
        } else {
            await axios
                .post(`${baseURL}/${ADMIN_ADD_GROUP_API}`, {
                    headers: { authorization: token.token },
                    name: group.name,
                    priorityList: group.priorityList ? Number(group.priorityList) : "",
                    statusId: group.statusId,
                    groupId: edit ? groupId : "",
                    tabId,
                })
                .then((res) => {
                    if (res.status === 200) {
                        toast.success(res.data.message);
                        setGroup({
                            name: "",
                            priorityList: "",
                            statusId: "1",
                        });
                        handleClose();
                        fetchData();
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: DELETE_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/${ADMIN_DETAIL_PATIENT_GROUP_API}/${id}`, {
                            headers: { authorization: token.token },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire(res.data.message, " ", "success");
                                fetchData();
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    useEffect(() => {
        fetchData();
    }, [offset, filterValues]);

    return (
        <>
            {" "}
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{GROUP_LIST}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li>
                                    <i className="fa fa-tasks" aria-hidden="true"></i>&nbsp;
                                    <NavLink className="parent-item" to="/manage-tabs">
                                        {TAB_LIST}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{GROUP_LIST}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tabbable-line">
                                <div className="tab-content">
                                    <div className="tab-pane active fontawesome-demo" id="tab1">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card" style={isLoading ? { height: "35rem" } : {}}>
                                                    <div className="card-head">
                                                        <header>{MANAGE_GROUP}</header>
                                                    </div>
                                                    <div className="card-body ">
                                                        <div className="row">
                                                            <div className="row d-flex justify-content-between">
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="btn-group">
                                                                        <button id="addRow" className="btn btn-info" onClick={handleAdd}>
                                                                            {ADD}
                                                                            <i className="fa fa-plus"></i>
                                                                        </button>
                                                                        <Add_EditGroup
                                                                            show={show}
                                                                            handleClose={handleClose}
                                                                            edit={edit}
                                                                            groupId={groupId}
                                                                            fetchData={fetchData}
                                                                            group={group}
                                                                            handlechange={handlechange}
                                                                            addGroupdata={addGroupdata}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <div className="btn-group mx-3">
                                                                            <label className="mt-2 col-md-3">{NAME}</label>
                                                                            <input
                                                                                className="form-control"
                                                                                name="search"
                                                                                type="search"
                                                                                placeholder="Search"
                                                                                aria-label="Search"
                                                                                value={filterValues.search}
                                                                                onChange={handleFilterValues}
                                                                            />
                                                                        </div>
                                                                        <div className="btn-group mx-5">
                                                                            <div className="btn-group mx-1">
                                                                                <label className="mt-2 col-md-5">{STATUS}</label>
                                                                                <select className="form-control col-md-3" name="status" value={filterValues.status} onChange={handleFilterValues}>
                                                                                    <option value="">{ALL}</option>
                                                                                    {Object.keys(TABS_STATUS).map((item, i) => {
                                                                                        return (
                                                                                            <option key={i} value={TABS_STATUS[item]}>
                                                                                                {item}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-scrollable" style={isLoading ? { border: "none" } : {}}>
                                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{NO}</th>
                                                                        <th>{NAME}</th>
                                                                        <th>{SORT_PRIORITY}</th>
                                                                        <th>{STATUS}</th>
                                                                        <th>{ACTION}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={isLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                                    {isLoading ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        <>
                                                                            {!groupList ? (
                                                                                <>{isLoading ? "" : <NoDataFound />}</>
                                                                            ) : (
                                                                                groupList &&
                                                                                groupList.map((item, i) => {
                                                                                    return (
                                                                                        <tr className="odd gradeX" key={i}>
                                                                                            <td> {(offset - 1) * ROWS_PER_PAGE + i + 1} </td>
                                                                                            <td className="left">{item.name}</td>
                                                                                            <td className="left">{item.priorityList}</td>
                                                                                            <td className="left">
                                                                                                <span
                                                                                                    className={
                                                                                                        "badge " +
                                                                                                        (item.statusId == 2
                                                                                                            ? "deactiveStatus badge-warning"
                                                                                                            : item.statusId == 1
                                                                                                            ? "activeStatus badge-success"
                                                                                                            : "badge-danger")
                                                                                                    }
                                                                                                >
                                                                                                    {Object.keys(TABS_STATUS).find((key) => TABS_STATUS[key] === item.statusId)}
                                                                                                </span>
                                                                                            </td>
                                                                                            <td>
                                                                                                <button className="btn btn-primary btn-xs" onClick={() => handleEdit(item.id)}>
                                                                                                    <i className="fa fa-pencil"></i>
                                                                                                </button>
                                                                                                <button type="button" className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mx-4">
                                                        <div>Total {groupList == null ? "0" : rowCount} Groups</div>
                                                        <div className="pagination-center">
                                                            <ReactPaginate
                                                                initialPage={0}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={0}
                                                                pageRangeDisplayed={3}
                                                                onPageChange={handlePageClick}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                pageLinkClassName="page-link"
                                                                breakLinkClassName="page-link"
                                                                nextLinkClassName="page-link"
                                                                previousLinkClassName="page-link"
                                                                pageClassName="page-item"
                                                                breakClassName="page-item"
                                                                nextClassName="page-item"
                                                                previousClassName="page-item"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"
                                                                disableInitialCallback={true}
                                                                forcePage={currentPage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManageTabgroup;
