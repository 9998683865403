import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { baseURL } from "../../../../Config";
import { CN_GROUP_ADD_API, CN_GROUP_LIST_API, GROUP_DETAIL_API } from "../../../../Helper/ApiPath";
import { ACTION, ADD, ALL, COMPREHENSIVE_GROUP_LIST, COMPREHENSIVE_PRIORITY, DASH, GROUP_NAME, GROUP_STATUS, HOME, NO, PRE_AUTH_PRIORITY, ROWS_PER_PAGE, STATUS } from "../../../../Helper/Constants";
import { DELETE_MESSAGE, ENTER_GROUP_NAME, ENTER_PRIORITY_LESS_THAN_MAX_PRIORITY, MAX_PRIORITY, MIN_PRIORITY } from "../../../../Helper/Messages";
import Loader from "../../../CommonComponent/Loader";
import NoDataFound from "../../../CommonComponent/NoDataFound";
import AddGroup from "./AddGroup";

function ManageJsonGroup() {
    const navigate = useNavigate();
    let token = JSON.parse(localStorage.getItem("logToken"));
    const [isLoading, setIsLoading] = useState(false);
    const [offset, setOffset] = useState(1);
    const [rowCount, setRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    const [pageCount, setPageCount] = useState(0);
    const [jsonData, setJsonData] = useState([]);
    const [edit, setEdit] = useState(false);
    const [addGroupShow, setAddGroupShow] = useState(false);
    const [groupAdd, setGroupAdd] = useState({
        name: "",
        statusId: "1",
        priorityList: MAX_PRIORITY,
        preAuthPriority: MAX_PRIORITY,
    });
    const [groupId, setGroupId] = useState("");
    const [filterValues, setfilterValues] = useState({
        search: "",
        status: "",
    });
    const [disableCN, setDisableCN] = useState(false);
    const [sortCN, setSortCN] = useState(1);

    const handleCNCheck = (e) => {
        if (e.target.checked) {
            setDisableCN(true);
            setGroupAdd({ ...groupAdd, priorityList: MIN_PRIORITY });
        } else {
            setDisableCN(false);
            setGroupAdd({ ...groupAdd, priorityList: MAX_PRIORITY });
        }
    };

    const [disablePRA, setDisablePRA] = useState(false);

    const handlePRACheck = (e) => {
        if (e.target.checked) {
            setDisablePRA(true);
            setGroupAdd({ ...groupAdd, preAuthPriority: MIN_PRIORITY });
        } else {
            setDisablePRA(false);
            setGroupAdd({ ...groupAdd, preAuthPriority: MAX_PRIORITY });
        }
    };

    const handleFilterValues = (e) => {
        const { name, value } = e.target;
        setfilterValues({ ...filterValues, [name]: value });
        setOffset(1);
        setCurrentPage(0);
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleGroupAddChange = (e) => {
        let { name, value } = e.target;
        setGroupAdd({ ...groupAdd, [name]: value });
    };

    const handleEditGroup = (groupId) => {
        setEdit(true);
        setGroupId(groupId);
        setAddGroupShow(true);
        detailGroup(groupId);
    };

    const detailGroup = async (id) => {
        await axios
            .get(`${baseURL}/${GROUP_DETAIL_API}/${id}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                if (res.status === 200) {
                    setGroupAdd(res.data.data);
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleDeleteGroup = async (id) => {
        Swal.fire({
            title: DELETE_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/${GROUP_DETAIL_API}/${id}`, {
                            headers: { authorization: token.token },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire(res.data.message, " ", "success");
                                fetchData();
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    const handleGroupsave = async () => {
        if (!groupAdd.name) {
            toast.error(ENTER_GROUP_NAME);
        } else if (groupAdd.priorityList && groupAdd.priorityList > MAX_PRIORITY) {
            toast.error(ENTER_PRIORITY_LESS_THAN_MAX_PRIORITY);
        } else if (groupAdd.preAuthPriority && groupAdd.preAuthPriority > MAX_PRIORITY) {
            toast.error(ENTER_PRIORITY_LESS_THAN_MAX_PRIORITY);
        } else {
            await axios
                .post(`${baseURL}/${CN_GROUP_ADD_API}`, {
                    headers: { authorization: token.token },
                    name: groupAdd.name,
                    priorityList: groupAdd.priorityList ? Number(groupAdd.priorityList) : "",
                    preAuthPriority: groupAdd.preAuthPriority ? Number(groupAdd.preAuthPriority) : "",
                    statusId: groupAdd.statusId,
                    cnGroupId: edit ? groupId : "",
                })
                .then((res) => {
                    if (res.status === 200) {
                        toast.success(res.data.message);
                        handleClose();
                        fetchData();
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${CN_GROUP_LIST_API}`, {
                headers: { authorization: token.token },
                statusId: filterValues.status,
                search: filterValues.search,
                page: offset,
                rowsPerPage: ROWS_PER_PAGE,
                sortValue: sortCN,
            })
            .then((res) => {
                setIsLoading(false);
                setJsonData(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleAddGroup = () => {
        setAddGroupShow(true);
    };

    const handleClose = () => {
        setEdit(false);
        setAddGroupShow(false);
        setGroupAdd({
            name: "",
            priorityList: MAX_PRIORITY,
            preAuthPriority: MAX_PRIORITY,
            statusId: "1",
        });
        setDisableCN(false);
    };

    useEffect(() => {
        fetchData();
    }, [offset, filterValues, sortCN]);

    return (
        <>
            {" "}
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{COMPREHENSIVE_GROUP_LIST}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{COMPREHENSIVE_GROUP_LIST}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tabbable-line">
                                <div className="tab-content">
                                    <div className="tab-pane active fontawesome-demo" id="tab1">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card" style={isLoading ? { height: "35rem" } : {}}>
                                                    <div className="card-body ">
                                                        <div className="row">
                                                            <div className="col-1">
                                                                <button id="addRow" className="btn btn-info" onClick={handleAddGroup}>
                                                                    {ADD} <i className="fa fa-plus"></i>
                                                                </button>
                                                                <AddGroup
                                                                    disablePRA={disablePRA}
                                                                    handlePRACheck={handlePRACheck}
                                                                    disableCN={disableCN}
                                                                    handleCNCheck={handleCNCheck}
                                                                    edit={edit}
                                                                    handleGroupsave={handleGroupsave}
                                                                    groupAdd={groupAdd}
                                                                    handleGroupAddChange={handleGroupAddChange}
                                                                    addGroupShow={addGroupShow}
                                                                    handleClose={handleClose}
                                                                />
                                                            </div>
                                                            <div className="col-7"></div>
                                                            <div className="col-2">
                                                                <input
                                                                    className="form-control"
                                                                    name="search"
                                                                    type="search"
                                                                    placeholder="Search"
                                                                    aria-label="Search"
                                                                    value={filterValues.search}
                                                                    onChange={handleFilterValues}
                                                                />
                                                            </div>
                                                            <div className="col-2">
                                                                <div className="row d-flex justify-content-end">
                                                                    <div className="col-3 d-flex justify-content-end mt-2">
                                                                        <label className="">{STATUS}</label>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <select className="form-control col-md-3" name="status" value={filterValues.status} onChange={handleFilterValues}>
                                                                            <option value="">{ALL}</option>
                                                                            {Object.keys(GROUP_STATUS).map((item, i) => {
                                                                                return (
                                                                                    <option key={i} value={GROUP_STATUS[item]}>
                                                                                        {item}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-scrollable" style={isLoading ? { border: "none" } : {}}>
                                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{NO}</th>
                                                                        <th>{GROUP_NAME}</th>
                                                                        <th>
                                                                            {COMPREHENSIVE_PRIORITY}
                                                                            {sortCN == 1 ? (
                                                                            <>
                                                                            <i
                                                                                className={`fa fa-sort-amount-desc mx-2 text-${sortCN == 1 ? "danger" : "info"}`}
                                                                                aria-hidden="true"
                                                                                onClick={() => setSortCN(2)}
                                                                                style={{ cursor: "pointer" }}
                                                                            ></i>
                                                                            </>
                                                                            ) : (
                                                                            <>
                                                                            <i
                                                                                className={`fa fa-sort-amount-asc mx-2 text-${sortCN == 2 ? "danger" : "info"}`}
                                                                                aria-hidden="true"
                                                                                onClick={() => setSortCN(1)}
                                                                                style={{ cursor: "pointer" }}
                                                                            ></i>
                                                                            </>
                                                                            )}
                                                                        </th>
                                                                        <th>
                                                                            {PRE_AUTH_PRIORITY}
                                                                            {sortCN == 3 ? (
                                                                            <>
                                                                            <i
                                                                                className={`fa fa-sort-amount-desc mx-2 text-${sortCN == 3 ? "danger" : "info"}`}
                                                                                aria-hidden="true"
                                                                                onClick={() => setSortCN(4)}
                                                                                style={{ cursor: "pointer" }}
                                                                            ></i>
                                                                            </>
                                                                            ) : (
                                                                            <>
                                                                            <i
                                                                                className={`fa fa-sort-amount-asc mx-2 text-${sortCN == 4 ? "danger" : "info"}`}
                                                                                aria-hidden="true"
                                                                                onClick={() => setSortCN(3)}
                                                                                style={{ cursor: "pointer" }}
                                                                            ></i>
                                                                            </>
                                                                            )}
                                                                        </th>
                                                                        <th>{STATUS}</th>
                                                                        <th>{ACTION}</th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody style={isLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                                    {isLoading ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        <>
                                                                            {!jsonData ? (
                                                                                <>{isLoading ? "" : <NoDataFound />}</>
                                                                            ) : (
                                                                                jsonData &&
                                                                                jsonData.map((item, index) => {
                                                                                    return (
                                                                                        <tr className="odd gradeX" key={index}>
                                                                                            <td className="left">{(offset - 1) * ROWS_PER_PAGE + index + 1}</td>
                                                                                            <td className="left"> {item.name ? item.name : DASH}</td>
                                                                                            <td className="left"> {item.priorityList ? item.priorityList : DASH}</td>
                                                                                            <td className="left"> {item.preAuthPriority ? item.preAuthPriority : DASH}</td>
                                                                                            <td className="left">
                                                                                                <span
                                                                                                    className={
                                                                                                        "badge " +
                                                                                                        (item.statusId == 2
                                                                                                            ? "deactiveStatus badge-warning"
                                                                                                            : item.statusId == 1
                                                                                                            ? "activeStatus badge-success"
                                                                                                            : "badge-danger")
                                                                                                    }
                                                                                                >
                                                                                                    {Object.keys(GROUP_STATUS).find((key) => GROUP_STATUS[key] === item.statusId)}
                                                                                                </span>
                                                                                            </td>
                                                                                            <td>
                                                                                                <button className="btn btn-primary btn-xs" onClick={() => handleEditGroup(item.id)}>
                                                                                                    <i className="fa fa-pencil"></i>
                                                                                                </button>
                                                                                                <button className="btn btn-danger btn-xs" onClick={() => handleDeleteGroup(item.id)}>
                                                                                                    <i className="fa fa-trash"></i>
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mx-4">
                                                        <div>Total {jsonData == null ? "0" : rowCount} Groups</div>
                                                        <div className="pagination-center">
                                                            <ReactPaginate
                                                                initialPage={0}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={0}
                                                                pageRangeDisplayed={3}
                                                                onPageChange={handlePageClick}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                pageLinkClassName="page-link"
                                                                breakLinkClassName="page-link"
                                                                nextLinkClassName="page-link"
                                                                previousLinkClassName="page-link"
                                                                pageClassName="page-item"
                                                                breakClassName="page-item"
                                                                nextClassName="page-item"
                                                                previousClassName="page-item"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"
                                                                disableInitialCallback={true}
                                                                forcePage={currentPage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManageJsonGroup;
