import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../Config";
import { EMPLOYEE_PROFILE_API, OFFICE_DETAIL_API, PROFILE_API } from "../../Helper/ApiPath";
import { ROLES } from "../../Helper/Constants";
import Footer from "../footer/Footer";
import Header from "../Header/Header";
import Sidebar from "../sidebar/Sidebar";
import Cookies from "universal-cookie";

function Lay() {
    const logToken = JSON.parse(localStorage.getItem("logToken")).token;
    const cookies = new Cookies();

    let role = cookies.get("role");
    let pageAuthorityRoleId;
    if (role) {
        role = cookies.get("role").roleId;
        pageAuthorityRoleId = cookies.get("role").pageAuthorityRoleId;
    }

    let doctorId = localStorage.getItem("doctorId");
    let officeId = localStorage.getItem("officeId");
    const navigate = useNavigate();
    const [image, setImage] = useState("");
    const [doctorFirstName, setDoctorFirstName] = useState("");
    const [doctorLastName, setDoctorLastName] = useState("");

    const [officeName, setOfficeName] = useState("");
    let adminRole = localStorage.getItem("adminRole");
    const fetchOfficeName = async () => {
        await axios
            .get(`${baseURL}/${OFFICE_DETAIL_API}/${officeId}`, {
                headers: { authorization: logToken },
            })
            .then((res) => {
                setOfficeName(res.data.data.name);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const fetchData = async () => {
        await axios
            .get(`${baseURL}/${PROFILE_API}/${doctorId}`, {
                headers: { authorization: logToken },
            })
            .then((res) => {
                setImage(res.data.data.profilePic);
                setDoctorFirstName(res.data.data.firstName);
                setDoctorLastName(res.data.data.lastName);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const [employeeInfo, setEmployeeInfo] = useState([]);

    const employeeData = async () => {
        await axios
            .get(`${baseURL}/${EMPLOYEE_PROFILE_API}`, {
                headers: { authorization: logToken },
            })
            .then((res) => {
                setEmployeeInfo(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        if (role === ROLES.DOCTOR || adminRole == ROLES.DOCTOR) {
            // if (role == ROLES.DOCTOR && adminRole == ROLES.DOCTOR) {
            fetchData();
            fetchOfficeName();
        }
        if (role === ROLES.EMPLOYEE_ADMIN || role === ROLES.EMPLOYEE) {
            employeeData();
        }

        if (role === ROLES.DOCTOR || adminRole == ROLES.DOCTOR) {
            document.body.className = "page-header-fixed sidemenu-closed-hidelogo page-content-white page-md blue-sidebar-color logo-white header-blue";
        } else if (pageAuthorityRoleId) {
            if (pageAuthorityRoleId === ROLES.ADMIN || pageAuthorityRoleId === ROLES.EMPLOYEE_ADMIN) {
                document.body.className = "page-header-fixed sidemenu-closed-hidelogo page-content-white page-md logo-white dark-sidebar-color header-dark";
            } else {
                document.body.className = "page-header-fixed sidemenu-closed-hidelogo page-content-white page-md cyan-sidebar-color logo-white header-cyan";
            }
        } else if (role === ROLES.ADMIN || role === ROLES.EMPLOYEE_ADMIN) {
            document.body.className = "page-header-fixed sidemenu-closed-hidelogo page-content-white page-md logo-white dark-sidebar-color header-dark";
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="page-wrapper">
                <Header doctorFirstName={doctorFirstName} doctorLastName={doctorLastName} officeName={officeName} doctorId={doctorId} officeId={officeId} employeeInfo={employeeInfo} />
                <div className="page-container">
                    <Sidebar image={image} doctorFirstName={doctorFirstName} doctorLastName={doctorLastName} employeeInfo={employeeInfo} />
                    <Outlet />
                </div>
                <Footer />
            </div>
        </>
    );
}

export default Lay;
