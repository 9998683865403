import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { NavLink, useNavigate } from "react-router-dom";
import { ACTION, ADD, CANCEL, HOME, NAME, STATE, UPDATE, MANAGE_STATE_CITY, STATE_AND_CITY, STATE_INFORMATION, ADD_STATE, ROWS_PER_PAGE, SELECT, SHORT_NAME } from "../../Helper/Constants";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { baseURL } from "../../Config";
import { STATE_ADD, STATE_LIST, CITY_ADD, CITY_LIST, STATE_EDIT, STATE_UPDATE, CITY_EDIT, CITY_UPDATE, STATE_LIST_WITHOUT_PAGINATION } from "../../Helper/ApiPath";
import { DELETE_MESSAGE, ENTER_CITY_NAME, ENTER_STATE } from "../../Helper/Messages";
import NoDataFound from "../CommonComponent/NoDataFound";
import Swal from "sweetalert2";
import Select from "react-select";
import Loader from "../CommonComponent/Loader";

function Configuration() {
    const [currentPage, setCurrentPage] = useState(0);
    const [currentCityPage, setCurrentCityPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [stateIsLoading, setStateIsLoading] = useState(true);
    const [cityIsLoading, setCityIsLoading] = useState(true);
    const [offset, setOffset] = useState(1);
    const [rowCount, setRowCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const navigate = useNavigate();

    const [filterStateValues, setFilterStateValues] = useState({
        search: "",
    });

    const handleStateFilterValues = (e) => {
        const { name, value } = e.target;
        setFilterStateValues({ ...filterStateValues, [name]: value });
        setOffset(1);
        setCurrentPage(0);
    };

    const handlePageClick = (event) => {
        let selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
        // postsPerPage(event.selected + 1);
    };

    const [showState, setShowState] = useState(false);
    const handleCloseState = () => setShowState(false);
    // const handleShowState = () => setShowState(true);

    const handleShowState = (type) => {
        if (type === "add") {
            setShowState(true);
            setStateValue("");
            setEditStateStatus(false);
        } else {
            setShowState(true);
        }
    };

    let [stateValue, setStateValue] = useState({
        name: "",
        shortname: "",
    });

    const handleState = (e) => {
        const { name, value } = e.target;
        setStateValue({ ...stateValue, [name]: value });
    };

    let adminToken = JSON.parse(localStorage.getItem("DoctorToken"));

    const [stateShowList, setStateShowList] = useState();

    const stateList = async () => {
        await axios
            .post(`${baseURL}/${STATE_LIST}`, {
                headers: { authorization: adminToken },
                page: offset,
                rowsPerPage: ROWS_PER_PAGE,
                search: filterStateValues.search,
            })
            .then((res) => {
                setStateIsLoading(false);
                setStateShowList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                setStateIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const [cityStateList, setCityStateList] = useState([]);
    const citystateListData = async () => {
        await axios
            .post(`${baseURL}/${STATE_LIST_WITHOUT_PAGINATION}`)
            .then((res) => {
                setCityStateList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const cityStateListOptions =
        cityStateList &&
        cityStateList.map((item, i) => {
            return {
                id: item.id,
                value: item.id,
                label: item.name,
            };
        });

    const handleAddState = async (id) => {
        if (!stateValue.name) {
            toast.error(ENTER_STATE);
        } else {
            setIsLoading(true);
            await axios
                .post(`${baseURL}/${STATE_ADD}`, {
                    headers: { authorization: adminToken },
                    name: stateValue.name,
                    shortname: stateValue.shortname,
                })
                .then((res) => {
                    handleCloseState(false);
                    toast.success(res.data.message);
                    stateList();
                    navigate("/configuration");
                    setStateValue("");
                    citystateListData();
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false);
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const [editStateStatus, setEditStateStatus] = useState(false);

    const handleEditState = async (id) => {
        await axios
            .get(`${baseURL}/${STATE_EDIT}/${id}`, {
                headers: {
                    authorization: adminToken,
                },
            })
            .then((res) => {
                handleShowState(true);
                if (res.status === 200) {
                    setStateValue(res.data.data);
                    setEditStateStatus(true);
                    citystateListData();
                }
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: DELETE_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/${STATE_EDIT}/${id}`, {
                            headers: { authorization: adminToken },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                stateList();
                                Swal.fire(res.data.message, " ", "success");
                            }
                        })
                        .catch((err) => {
                            toast.error(err.response.data.message);
                        });
                }
            }
        });
    };

    const handleUpdateState = async () => {
        setIsLoading(true);
        await axios
            .post(`${baseURL}/${STATE_UPDATE}`, {
                headers: { authorization: adminToken },
                id: stateValue.id,
                name: stateValue.name,
                shortname: stateValue.shortname,
                statusId: stateValue.statusId,
            })
            .then((res) => {
                if (res.status === 200) {
                    stateList();
                    handleCloseState(false);
                    cityList();
                    citystateListData();
                    toast.success(res.data.message);
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    ////////////////////////////////////////////////////////////////////// CITY //////////////////////////////////////////////////////////////////////

    const [cityOffset, setCityOffset] = useState(1);
    const [cityRowCount, setCityRowCount] = useState(0);
    const [cityPageCount, setCityPageCount] = useState(0);

    const [filterCityValues, setFilterCityValues] = useState({
        search: "",
    });
    const [stateSearch, setStateSearch] = useState("");
    const handleCityFilterValues = (e) => {
        const { name, value } = e.target;
        setFilterCityValues({ ...filterCityValues, [name]: value });
        setCityOffset(1);
        setCurrentCityPage(0);
    };

    const handleCityStateFilterValues = (e) => {
        setStateSearch(e.target.value);
        setCityOffset(1);
        setCurrentPage(0);
    };

    const handleCityPageClick = (event) => {
        let selectedPage = event.selected;
        setCityOffset(selectedPage + 1);
        setCurrentCityPage(selectedPage);
        // postsPerPage(event.selected + 1);
    };

    const [showCity, setShowCity] = useState(false);
    const handleCloseCity = () => {
        setShowCity(false);
        setShow(false);
    };

    const handleShowCity = (type) => {
        if (type === "add") {
            setShowCity(true);
            setCityValue("");
        }
    };

    const [cityShowList, setCityShowList] = useState([]);

    const [cityValue, setCityValue] = useState({
        name: "",
    });

    const handleStateChange = (e) => {
        setCityValue({ ...cityValue, stateId: e.value });
    };

    const handleCity = (e) => {
        const { name, value } = e.target;
        setCityValue({ ...cityValue, [name]: value });
    };
    const onCityChanged = (e) => {
        const { name, value } = e.target;
        setCityInfo({ ...cityInfo, [name]: value });
    };
    const cityList = async () => {
        await axios
            .post(`${baseURL}/${CITY_LIST}`, {
                headers: { authorization: adminToken },
                page: cityOffset,
                rowsPerPage: ROWS_PER_PAGE,
                search: filterCityValues.search,
                stateSearch: stateSearch,
            })
            .then((res) => {
                setCityIsLoading(false);
                setCityShowList(res.data.data);
                setCityPageCount(res.data.extraData.pageCount);
                setCityRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                setCityIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleAddCity = async (id) => {
        if (!cityValue.stateId) {
            toast.error(ENTER_STATE);
        } else if (!cityValue.name) {
            toast.error(ENTER_CITY_NAME);
        } else {
            setIsLoading(true);
            await axios
                .post(`${baseURL}/${CITY_ADD}`, {
                    headers: { authorization: adminToken },
                    name: cityValue.name,
                    stateId: cityValue.stateId,
                })
                .then((res) => {
                    handleCloseCity(false);
                    toast.success(res.data.message);
                    cityList();
                    navigate("/configuration");
                    setCityValue("");
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false);
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };
    const [show, setShow] = useState(false);
    const [cityInfo, setCityInfo] = useState({
        name: "",
        stateId: "",
        statusId: "",
    });
    const [id, setId] = useState("");
    const handleUpdateCity = async () => {
        if (!cityInfo.stateId) {
            toast.error(ENTER_STATE);
        } else if (!cityInfo.name) {
            toast.error(ENTER_CITY_NAME);
        } else {
            setIsLoading(true);
            await axios
                .post(`${baseURL}/${CITY_UPDATE}`, {
                    headers: { authorization: adminToken },
                    id: id,
                    name: cityInfo.name,
                    stateId: Number(cityInfo.stateId),
                    statusId: cityInfo.statusId,
                })
                .then((res) => {
                    if (res.status === 200) {
                        setShow(false);
                        setShowCity(false);
                        cityList();
                        toast.success(res.data.message);
                        setIsLoading(false);
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    toast.error(err.response.data.message);
                });
        }
    };

    const handleEditCity = async (cityId) => {
        setShow(true);
        setShowCity(true);
        await axios
            .get(`${baseURL}/${CITY_EDIT}/${cityId}`, {
                headers: {
                    authorization: adminToken,
                },
            })
            .then((res) => {
                if (res.status == 200) {
                    setCityInfo(res.data.data.city);
                    setId(res.data.data.id);
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const handleCancel = () => {
        setShowCity(false);

        setShow(false);
    };
    const handleCityDelete = async (id) => {
        Swal.fire({
            title: DELETE_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/${CITY_EDIT}/${id}`, {
                            headers: { authorization: adminToken },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                cityList();
                                Swal.fire(res.data.message, " ", "success");
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    useEffect(() => {
        citystateListData();
        stateList();
        cityList();
    }, [offset, cityOffset, filterStateValues, filterCityValues, stateSearch]);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{MANAGE_STATE_CITY}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{STATE_AND_CITY}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tabbable-line">
                                <div className="tab-content">
                                    <div className="tab-pane active fontawesome-demo" id="tab1">
                                        <div className="row">
                                            {/* State Information */}
                                            <div className="col-md-6">
                                                <div className="card" style={stateIsLoading ? { height: "35rem" } : {}}>
                                                    <div className="card-head">
                                                        <header>{STATE_INFORMATION}</header>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="row d-flex justify-content-between">
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="btn-group">
                                                                        <button id="addRow" className="btn btn-info" onClick={() => handleShowState("add")}>
                                                                            {ADD}
                                                                            <i className="fa fa-plus"></i>
                                                                        </button>
                                                                    </div>
                                                                    <div className="btn-group" style={{ marginRight: "-20px" }}>
                                                                        <label className="mt-2 col-md-3">State</label>
                                                                        <input
                                                                            className="form-control"
                                                                            name="search"
                                                                            type="search"
                                                                            placeholder="Search"
                                                                            aria-label="Search"
                                                                            value={filterStateValues.search}
                                                                            onChange={handleStateFilterValues}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="btn-group">
                                                                <Modal show={showState} onHide={handleCloseState}>
                                                                    <Modal.Header closeButton>
                                                                        {editStateStatus ? <Modal.Title>Update State</Modal.Title> : <Modal.Title>{ADD_STATE}</Modal.Title>}
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <label>
                                                                            {NAME}
                                                                            <span className="required text-danger"> * </span>
                                                                        </label>
                                                                        <input type="text" className="form-control" name="name" value={stateValue && stateValue.name} onChange={handleState} />
                                                                        <label>{SHORT_NAME}</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="shortname"
                                                                            value={stateValue && stateValue.shortname}
                                                                            onChange={handleState}
                                                                        />
                                                                    </Modal.Body>
                                                                    <div className="d-flex justify-content-center mb-3">
                                                                        <div className="center">
                                                                            {editStateStatus ? (
                                                                                <>
                                                                                    <Button variant="primary" onClick={handleUpdateState}>
                                                                                        {UPDATE}
                                                                                    </Button>
                                                                                    {isLoading ? (
                                                                                        <i
                                                                                            className="fa fa-spinner fa-spin fa-2x"
                                                                                            style={{
                                                                                                position: "relative",
                                                                                                top: "8px",
                                                                                                left: "-50px",
                                                                                                color: "#fff",
                                                                                            }}
                                                                                        ></i>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <Button variant="primary" onClick={handleAddState}>
                                                                                        {ADD}
                                                                                    </Button>

                                                                                    {isLoading ? (
                                                                                        <i
                                                                                            className="fa fa-spinner fa-spin fa-2x"
                                                                                            style={{
                                                                                                position: "relative",
                                                                                                top: "8px",
                                                                                                left: "-43px",
                                                                                                color: "#fff",
                                                                                            }}
                                                                                        ></i>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </>
                                                                            )}

                                                                            <Button className="mx-3" variant="default" onClick={handleCloseState}>
                                                                                {CANCEL}
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </Modal>
                                                            </div>
                                                        </div>
                                                        <div className="table-scrollable" style={stateIsLoading ? { border: "none" } : {}}>
                                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{NAME}</th>
                                                                        <th>{SHORT_NAME}</th>
                                                                        <th>{ACTION}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={stateIsLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                                    {stateIsLoading ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        <>
                                                                            {!stateShowList ? (
                                                                                <>{stateIsLoading ? "" : <NoDataFound />}</>
                                                                            ) : (
                                                                                stateShowList &&
                                                                                stateShowList.map((item, index) => {
                                                                                    return (
                                                                                        <tr className="odd gradeX" key={index}>
                                                                                            <td className="left">{item.name}</td>
                                                                                            <td>{item.shortname}</td>
                                                                                            <td>
                                                                                                <button className="btn btn-primary btn-xs" onClick={() => handleEditState(item.id)}>
                                                                                                    <i className="fa fa-pencil"></i>
                                                                                                </button>
                                                                                                <button className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mx-4">
                                                        <div>Total {stateShowList == null ? "0" : rowCount} State</div>
                                                        <div className="pagination-center">
                                                            <ReactPaginate
                                                                initialPage={0}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={0}
                                                                pageRangeDisplayed={3}
                                                                onPageChange={handlePageClick}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                pageLinkClassName="page-link"
                                                                breakLinkClassName="page-link"
                                                                nextLinkClassName="page-link"
                                                                previousLinkClassName="page-link"
                                                                pageClassName="page-item"
                                                                breakClassName="page-item"
                                                                nextClassName="page-item"
                                                                previousClassName="page-item"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"
                                                                disableInitialCallback={true}
                                                                forcePage={currentPage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* City Information */}
                                            <div className="col-md-6">
                                                <div className="card" style={cityIsLoading ? { height: "35rem" } : {}}>
                                                    <div className="card-head">
                                                        <header>City Information</header>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="row d-flex justify-content-between">
                                                                <div className="d-flex  justify-content-between">
                                                                    <div className="btn-group">
                                                                        <button id="addRow" className="btn btn-info" onClick={() => handleShowCity("add")}>
                                                                            {ADD}
                                                                            <i className="fa fa-plus"></i>
                                                                        </button>
                                                                    </div>

                                                                    <div>
                                                                        <div className="btn-group">
                                                                            <label className="mt-2 col-md-3">City</label>
                                                                            <input
                                                                                className="form-control mr-sm-2"
                                                                                name="search"
                                                                                type="search"
                                                                                placeholder="Search"
                                                                                aria-label="Search"
                                                                                value={filterCityValues.search}
                                                                                onChange={handleCityFilterValues}
                                                                            />
                                                                        </div>
                                                                        <div className="btn-group" style={{ marginLeft: "10px" }}>
                                                                            <label className="mt-2 col-md-3">State</label>
                                                                            <input
                                                                                className="form-control"
                                                                                name="StateSearch"
                                                                                type="search"
                                                                                placeholder="Search"
                                                                                aria-label="Search"
                                                                                value={stateSearch}
                                                                                onChange={handleCityStateFilterValues}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="btn-group">
                                                                <Modal show={showCity} onHide={handleCloseCity}>
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>{show ? "Update City" : "Add City"}</Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <label>
                                                                            State
                                                                            <span className="required text-danger"> * </span>
                                                                        </label>
                                                                        {show ? (
                                                                            <Select
                                                                                name="stateId"
                                                                                value={cityStateListOptions && cityStateListOptions.filter(({ value }) => value == cityInfo.stateId)}
                                                                                options={cityStateListOptions}
                                                                                onChange={handleStateChange}
                                                                                placeholder={SELECT}
                                                                            ></Select>
                                                                        ) : (
                                                                            <Select name="stateId" options={cityStateListOptions} onChange={handleStateChange} placeholder={SELECT} />
                                                                        )}

                                                                        <div className="mt-2"></div>

                                                                        <label>
                                                                            Name
                                                                            <span className="required text-danger"> * </span>
                                                                        </label>
                                                                        {show ? (
                                                                            <input type="text" className="form-control" name="name" value={cityInfo && cityInfo.name} onChange={onCityChanged} />
                                                                        ) : (
                                                                            <input type="text" className="form-control" name="name" value={cityValue && cityValue.name} onChange={handleCity} />
                                                                        )}
                                                                    </Modal.Body>
                                                                    <div className="d-flex justify-content-center mb-3">
                                                                        <div className="center">
                                                                            <Button variant="primary" onClick={show ? handleUpdateCity : handleAddCity}>
                                                                                {show ? UPDATE : ADD}
                                                                            </Button>
                                                                            {isLoading ? (
                                                                                <i
                                                                                    className="fa fa-spinner fa-spin fa-2x"
                                                                                    style={{
                                                                                        position: "relative",
                                                                                        top: "8px",
                                                                                        left: "-43px",
                                                                                        color: "#fff",
                                                                                    }}
                                                                                ></i>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            <Button className="mx-3" variant="default" onClick={show ? handleCancel : handleCloseCity}>
                                                                                {CANCEL}
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </Modal>
                                                            </div>
                                                        </div>
                                                        <div className="table-scrollable" style={cityIsLoading ? { border: "none" } : {}}>
                                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{NAME}</th>
                                                                        <th>{STATE}</th>
                                                                        <th>{ACTION}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={cityIsLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                                    {cityIsLoading ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        <>
                                                                            {!cityShowList ? (
                                                                                <>{cityIsLoading ? "" : <NoDataFound />}</>
                                                                            ) : (
                                                                                cityShowList &&
                                                                                cityShowList.map((item, index) => {
                                                                                    return (
                                                                                        <tr className="odd gradeX" key={index}>
                                                                                            <td className="left">{item.name}</td>
                                                                                            <td className="left">{item.state.name}</td>

                                                                                            <td>
                                                                                                <button className="btn btn-primary btn-xs" onClick={() => handleEditCity(item.id)}>
                                                                                                    <i className="fa fa-pencil"></i>
                                                                                                </button>
                                                                                                <button className="btn btn-danger btn-xs" onClick={() => handleCityDelete(item.id)}>
                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mx-4">
                                                        <div>Total {cityShowList == null ? "0" : cityRowCount} City </div>
                                                        <div className="pagination-center">
                                                            <ReactPaginate
                                                                initialPage={0}
                                                                pageCount={cityPageCount}
                                                                marginPagesDisplayed={0}
                                                                pageRangeDisplayed={3}
                                                                onPageChange={handleCityPageClick}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                pageLinkClassName="page-link"
                                                                breakLinkClassName="page-link"
                                                                nextLinkClassName="page-link"
                                                                previousLinkClassName="page-link"
                                                                pageClassName="page-item"
                                                                breakClassName="page-item"
                                                                nextClassName="page-item"
                                                                previousClassName="page-item"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"
                                                                disableInitialCallback={true}
                                                                forcePage={currentCityPage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Configuration;
