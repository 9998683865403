import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { baseURL } from "../../../../Config";
import { CN_GROUP_LIST_API, CN_QUESTION_ADD_API, CN_QUESTION_DETAIL_API, CN_QUESTION_LIST_API, COMPREHENSIVE_QUESTION_LIST_API } from "../../../../Helper/ApiPath";
import {
    ACTION,
    ADD,
    DASH,
    DESCRIPTION,
    GROUP_NAME,
    HOME,
    COMPREHENSIVE_DESCRIPTION_LIST,
    NO,
    QUESTION_NAME,
    QUESTION_STATUS,
    ROWS_PER_PAGE,
    SORT_PRIORITY,
    STATUS,
    ALL,
    SELECT_GROUP,
    SELECT_QUESTION,
} from "../../../../Helper/Constants";
import { DELETE_MESSAGE, ENTER_DESCRIPTION, ENTER_GROUP_NAME, ENTER_PRIORITY_LESS_THAN_MAX_PRIORITY, ENTER_QUESTION_NAME, MAX_PRIORITY } from "../../../../Helper/Messages";
import Loader from "../../../CommonComponent/Loader";
import NoDataFound from "../../../CommonComponent/NoDataFound";
import AddQueAndDescription from "./AddQueAndDescription";
import Select from "react-select";

function ManageJsonQuestion() {
    const navigate = useNavigate();
    let token = JSON.parse(localStorage.getItem("logToken"));
    const [isLoading, setIsLoading] = useState(false);
    const [offset, setOffset] = useState(1);
    const [rowCount, setRowCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [show, setShow] = useState(false);
    const [edit, setEdit] = useState(false);
    const [questionId, setQuestionId] = useState("");
    const [questionFilterId, setQuestionFilterId] = useState("");
    const [questionList, setQuestionList] = useState([]);
    const [questionFilterList, setQuestionFilterList] = useState([]);
    const [questiondata, setQuestionData] = useState([]);
    const [groupId, setGroupId] = useState("");
    const [groupFilterId, setGroupFilterId] = useState("");
    const [groupList, setGroupList] = useState([]);
    const [groupFilterList, setGroupFilterList] = useState([]);
    const [questionAdd, setQuestionAdd] = useState({
        priorityList: MAX_PRIORITY,
        preAuthPriority: MAX_PRIORITY,
        statusId: "1",
        description: "",
        description2: "",
        description3: "",
    });
    const [descriptionId, setDescriptionId] = useState("");

    const [filterValues, setfilterValues] = useState({
        search: "",
        status: "",
    });

    const handleFilterValues = (e) => {
        const { name, value } = e.target;
        setfilterValues({ ...filterValues, [name]: value });
        setOffset(1);
        setCurrentPage(0);
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleQuestionchange = (e) => {
        let { name, value } = e.target;
        setQuestionAdd({ ...questionAdd, [name]: value });
    };

    let selectQuestion = [
        {
            uniqueId: "81881596d99d4173abcb9269fdc616953ee9a30da5674fa6bbe31a5ffaad08d0",
            name: "No Question",
        },
    ];

    let selectFilterQuestion = [
        {
            uniqueId: "",
            name: SELECT_QUESTION,
        },
        {
            uniqueId: "81881596d99d4173abcb9269fdc616953ee9a30da5674fa6bbe31a5ffaad08d0",
            name: "No Question",
        },
    ];
    const fetchQuestionList = async () => {
        await axios
            .post(`${baseURL}/${COMPREHENSIVE_QUESTION_LIST_API}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                setQuestionList(res.data.data ? selectQuestion.concat(res.data.data) : selectQuestion);
                setQuestionFilterList(res.data.data ? selectFilterQuestion.concat(res.data.data) : selectFilterQuestion);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const QuestionSelectOptions =
        questionList &&
        questionList.map((item, i) => {
            return {
                value: item.uniqueId,
                label: item.name,
            };
        });
    const QuestionFilterOptions =
        questionFilterList &&
        questionFilterList.map((item, i) => {
            return {
                value: item.uniqueId,
                label: item.name,
            };
        });

    const handleQuestionChange = (e) => {
        setQuestionId(e.value);
    };
    const handleQuestionFilterChange = (e) => {
        setQuestionFilterId(e.value);
    };

    let selectFiltergroup = [
        {
            id: "",
            name: SELECT_GROUP,
        },
    ];

    const fetchGroupList = async () => {
        await axios
            .post(`${baseURL}/${CN_GROUP_LIST_API}`, {
                headers: { authorization: token.token },
                isPagination: false,
            })
            .then((res) => {
                setGroupList(res.data.data);
                setGroupFilterList(res.data.data ? selectFiltergroup.concat(res.data.data) : selectFiltergroup);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const groupSelectOptions =
        groupList &&
        groupList.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });
    const groupFilterOptions =
        groupFilterList &&
        groupFilterList.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const handleGroupFilterChange = (e) => {
        setGroupFilterId(e.value);
    };

    const handleGroupChange = (e) => {
        setGroupId(e.value);
    };

    const handleAddQuetion = () => {
        setDescriptionId("");
        setShow(true);
    };

    const handleSaveQuestion = async () => {
        if (!questionId) {
            toast.error(ENTER_QUESTION_NAME);
        } else if (!groupId) {
            toast.error(ENTER_GROUP_NAME);
        } else if (!questionAdd.description) {
            toast.error(ENTER_DESCRIPTION);
        } else if (questionAdd.priorityList && questionAdd.priorityList > MAX_PRIORITY) {
            toast.error(ENTER_PRIORITY_LESS_THAN_MAX_PRIORITY);
        } else {
            await axios
                .post(`${baseURL}/${CN_QUESTION_ADD_API}`, {
                    headers: { authorization: token.token },
                    priorityList: questionAdd.priorityList ? Number(questionAdd.priorityList) : "",
                    preAuthPriority: questionAdd.preAuthPriority ? Number(questionAdd.preAuthPriority) : "",
                    statusId: questionAdd.statusId,
                    description: questionAdd.description,
                    description2: questionAdd.description2,
                    description3: questionAdd.description3,
                    cnQuestionId: questionId,
                    cnGroupId: groupId,
                    cnDescriptionId: descriptionId ? descriptionId : "",
                })
                .then((res) => {
                    if (res.status === 200) {
                        toast.success(res.data.message);
                        handleClose();
                        fetchData();
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const handleClose = () => {
        setShow(false);
        setEdit(false);
        setQuestionId("");
        setGroupId("");
        setQuestionAdd({
            priorityList: MAX_PRIORITY,
            statusId: "1",
            description: "",
            description2: "",
            description3: "",
        });
    };

    const handleEdit = (descriptionId, questionId, groupId) => {
        setEdit(true);
        handleAddQuetion();
        setQuestionId(questionId);
        setGroupId(groupId);
        detailQuestions(descriptionId);
        setDescriptionId(descriptionId);
    };

    const detailQuestions = async (descriptionId) => {
        await axios
            .get(`${baseURL}/${CN_QUESTION_DETAIL_API}/${descriptionId}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                if (res.status === 200) {
                    setQuestionAdd({
                        priorityList: res.data.data.priorityList,
                        preAuthPriority: res.data.data.preAuthPriority,
                        statusId: res.data.data.statusId,
                        description: res.data.data.description,
                        description2: res.data.data.description2,
                        description3: res.data.data.description3,
                    });
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleDelete = async (descriptionId) => {
        Swal.fire({
            title: DELETE_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/${CN_QUESTION_DETAIL_API}/${descriptionId}`, {
                            headers: { authorization: token.token },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire(res.data.message, " ", "success");
                                fetchData();
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${CN_QUESTION_LIST_API}`, {
                headers: { authorization: token.token },
                cnGroupId: groupFilterId,
                questionId: questionFilterId,
                statusId: filterValues.status,
                search: filterValues.search,
                page: offset,
                rowsPerPage: ROWS_PER_PAGE,
            })
            .then((res) => {
                setIsLoading(false);
                setQuestionData(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        fetchData();
        fetchQuestionList();
        fetchGroupList();
    }, [offset, filterValues, questionFilterId, groupFilterId]);

    return (
        <>
            {" "}
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{COMPREHENSIVE_DESCRIPTION_LIST}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{COMPREHENSIVE_DESCRIPTION_LIST}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tabbable-line">
                                <div className="tab-content">
                                    <div className="tab-pane active fontawesome-demo" id="tab1">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card" style={isLoading ? { height: "35rem" } : {}}>
                                                    <div className="card-body ">
                                                        <div className="row">
                                                            <div className="col-1">
                                                                <button id="addRow" className="btn btn-info" onClick={handleAddQuetion}>
                                                                    {ADD} <i className="fa fa-plus"></i>
                                                                </button>
                                                                <AddQueAndDescription
                                                                    handleSaveQuestion={handleSaveQuestion}
                                                                    questionAdd={questionAdd}
                                                                    handleQuestionchange={handleQuestionchange}
                                                                    groupSelectOptions={groupSelectOptions}
                                                                    handleGroupChange={handleGroupChange}
                                                                    groupId={groupId}
                                                                    handleQuestionChange={handleQuestionChange}
                                                                    QuestionSelectOptions={QuestionSelectOptions}
                                                                    questionId={questionId}
                                                                    edit={edit}
                                                                    show={show}
                                                                    handleClose={handleClose}
                                                                />
                                                            </div>
                                                            <div className="col-3"></div>
                                                            <div className="col-8">
                                                                <div className="row">
                                                                    <div className="col-3">
                                                                        <Select
                                                                            name="groupName"
                                                                            value={groupFilterOptions && groupFilterOptions.filter(({ value }) => value == groupFilterId)}
                                                                            options={groupFilterOptions}
                                                                            onChange={handleGroupFilterChange}
                                                                            placeholder={SELECT_GROUP}
                                                                        />
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <Select
                                                                            name="questionName"
                                                                            value={QuestionFilterOptions && QuestionFilterOptions.filter(({ value }) => value == questionFilterId)}
                                                                            options={QuestionFilterOptions}
                                                                            onChange={handleQuestionFilterChange}
                                                                            placeholder={SELECT_QUESTION}
                                                                        />
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <input
                                                                            className="form-control"
                                                                            name="search"
                                                                            type="search"
                                                                            placeholder="Search"
                                                                            aria-label="Search"
                                                                            value={filterValues.search}
                                                                            onChange={handleFilterValues}
                                                                        />
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <div className="row d-flex justify-content-end">
                                                                            <div className="col-3 d-flex justify-content-end mt-2">
                                                                                <label className="">{STATUS}</label>
                                                                            </div>
                                                                            <div className="col-8">
                                                                                <select className="form-control col-md-3" name="status" value={filterValues.status} onChange={handleFilterValues}>
                                                                                    <option value="">{ALL}</option>
                                                                                    {Object.keys(QUESTION_STATUS).map((item, i) => {
                                                                                        return (
                                                                                            <option key={i} value={QUESTION_STATUS[item]}>
                                                                                                {item}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-scrollable" style={isLoading ? { border: "none" } : {}}>
                                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{NO}</th>
                                                                        <th>{GROUP_NAME}</th>
                                                                        <th>{QUESTION_NAME}</th>
                                                                        <th>{DESCRIPTION}</th>
                                                                        <th>{SORT_PRIORITY}</th>
                                                                        <th>{STATUS}</th>
                                                                        <th>{ACTION}</th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody style={isLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                                    {isLoading ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        <>
                                                                            {!questiondata ? (
                                                                                <>{isLoading ? "" : <NoDataFound />}</>
                                                                            ) : (
                                                                                questiondata &&
                                                                                questiondata.map((item, index) => {
                                                                                    return (
                                                                                        <tr className="odd gradeX" key={index}>
                                                                                            <td className="left">{(offset - 1) * ROWS_PER_PAGE + index + 1}</td>
                                                                                            <td className="left"> {item.group ? item.group.name : DASH}</td>
                                                                                            <td className="left">
                                                                                                <div data-toggle="tooltip" title={item.question.name}>
                                                                                                    {" "}
                                                                                                    {item.question
                                                                                                        ? item.question.name.length > 30
                                                                                                            ? item.question.name.slice(0, 30) + "..."
                                                                                                            : item.question.name
                                                                                                        : DASH}
                                                                                                </div>
                                                                                            </td>

                                                                                            <td className="left">
                                                                                                <div data-toggle="tooltip" title={item.description.replaceAll(/<br>/g, "")}>
                                                                                                    {" "}
                                                                                                    {item.description
                                                                                                        ? item.description.length > 30
                                                                                                            ? item.description.slice(0, 30) + "..."
                                                                                                            : item.description
                                                                                                        : DASH}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td className="left"> {item.priorityList ? item.priorityList : DASH}</td>
                                                                                            <td className="left">
                                                                                                <span
                                                                                                    className={
                                                                                                        "badge " +
                                                                                                        (item.statusId == 2
                                                                                                            ? "deactiveStatus badge-warning"
                                                                                                            : item.statusId == 1
                                                                                                            ? "activeStatus badge-success"
                                                                                                            : "badge-danger")
                                                                                                    }
                                                                                                >
                                                                                                    {Object.keys(QUESTION_STATUS).find((key) => QUESTION_STATUS[key] === item.statusId)}
                                                                                                </span>
                                                                                            </td>
                                                                                            <td>
                                                                                                <button
                                                                                                    className="btn btn-primary btn-xs"
                                                                                                    onClick={() => handleEdit(item.id, item.question.id, item.group.id)}
                                                                                                >
                                                                                                    <i className="fa fa-pencil"></i>
                                                                                                </button>
                                                                                                <button className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
                                                                                                    <i className="fa fa-trash"></i>
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mx-4">
                                                        <div>Total {questiondata == null ? "0" : rowCount} Descriptions</div>
                                                        <div className="pagination-center">
                                                            <ReactPaginate
                                                                initialPage={0}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={0}
                                                                pageRangeDisplayed={3}
                                                                onPageChange={handlePageClick}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                pageLinkClassName="page-link"
                                                                breakLinkClassName="page-link"
                                                                nextLinkClassName="page-link"
                                                                previousLinkClassName="page-link"
                                                                pageClassName="page-item"
                                                                breakClassName="page-item"
                                                                nextClassName="page-item"
                                                                previousClassName="page-item"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"
                                                                disableInitialCallback={true}
                                                                forcePage={currentPage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManageJsonQuestion;
