import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL, frontURL } from "../../../Config";
import {
  ADD_COMPREHENSIVE_NOTE,
  ADD_POST_OP_NOTE,
  ADD_PRE_AUTHORIZATION_NOTE,
  CN_DOWNLOAD_API,
  COMPREHENSIVE_NOTE_DETAIL_API,
  POST_OP_DOWNLOAD_API,
  POST_OPNOTE_DETAIL_API,
  PRE_AUTHORIZATION_DOWNLOAD_API,
  PRE_AUTHORIZATION_NOTE_DETAIL_API,
} from "../../../Helper/ApiPath";
import {
  CLEAR,
  PREVIEW,
  SAVE,
  CANCEL,
  DOCTOR_NAME,
  DATE,
  HOME,
  COMPREHENSIVE_LIST,
  EDIT_COMPREHENSIVE,
  COMPREHENSIVE_INFORMATION,
  EDIT_PREAUTHORIZATION,
  PREAUTHORIZATION_LIST,
  PREAUTHORIZATION_INFORMATION,
  DOWNLOAD,
  ROLES,
} from "../../../Helper/Constants";
import {
  dateAPIFormate,
  dateFormateMonthDateYear,
} from "../../../Helper/Helper";
import SignaturePad from "react-signature-canvas";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import _ from "lodash";
import { ENTER_SIGNATURE } from "../../../Helper/Messages";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Cookies from "universal-cookie";

function EditPostOp(props) {
  const token = JSON.parse(localStorage.getItem("logToken"));
  const officeId = localStorage.getItem("officeId");
  const comprehensiveId = localStorage.getItem("comprehensiveId");
  const isPreAuthorization = localStorage.getItem("isPreAuthorization");
  const preAuthorization = localStorage.getItem("preAuthorization");
  const doctorId = localStorage.getItem("CNDoctorId");
  const patientId = localStorage.getItem("CNPatientId");
  const cookies = new Cookies();

  let role = cookies.get("role");
  if (role) {
    role = cookies.get("role").roleId;
  }
  let adminRole = localStorage.getItem("adminRole");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [imageURL, setImageURl] = useState(null);
  const [doctorInfo, setDoctorInfo] = useState([]);
  const [serviceDate, setServiceDate] = useState("");
  const [editorValue, setEditorValue] = useState("");
  const [CNDate, setCNDate] = useState("");
  const [editSign, setEditSign] = useState(false);
  const [isBase64Srt, setIsBase64Srt] = useState(false);
  const [statusId, setStatusId] = useState("");

  const fetchComprehensiveData = async () => {
    await axios
      .get(`${baseURL}/${POST_OPNOTE_DETAIL_API}/${preAuthorization}`, {
        headers: { authorization: token.token },
      })
      .then(async (res) => {
        setEditorValue(res.data.data.note);
        setDoctorInfo(res.data.data.doctor);
        setServiceDate(res.data.data.dateOfService);
        setImageURl(res.data.data.doctor.signatureImage);
        setCNDate(res.data.data.preAuthorizationDate);
        setStatusId(res.data.data.statusId);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);

        if (err.response) {
          if (err.response.status == 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const handlePdfDownload = async () => {
    axios
      .post(`${baseURL}/${POST_OP_DOWNLOAD_API}`, {
        headers: { authorization: token.token },
        preAuthId: preAuthorization,
      })
      .then(async (res) => {
        axios({
          url: `${frontURL}${res.data.data.path}`,
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${res.data.data.patientName + "_post-op-progress-note" + ".pdf"}`
          );
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const handleEditorChange = (e) => {
    setEditorValue(e);
  };

  const sigCanvas = useRef({});
  const clear = () => {
    sigCanvas.current.clear();
    setImageURl(null);
  };

  const save = () => {
    setImageURl(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    setEditSign(true);
    setIsBase64Srt(true);
  };

  const handleSave = async () => {
    await axios
      .post(`${baseURL}/${ADD_POST_OP_NOTE}`, {
        headers: { authorization: token.token },
        preAuthorizationNoteId: preAuthorization,
        note: editorValue,
        patientId: patientId,
        doctorId: doctorId,
        dateOfService: dateAPIFormate(serviceDate),
        officeId: officeId,
        signatureImage: imageURL,
        preAuthorizationDate: dateAPIFormate(CNDate),
        isBase64Srt: isBase64Srt,
        editSign: editSign,
        statusId: statusId,
        isDoctor:
          role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? true : false,
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message);
          localStorage.removeItem("comprehensiveId");
          localStorage.removeItem("CNDoctorId");
          localStorage.removeItem("CNPatientId");
          navigate("/manageOpProgresNotes");
          localStorage.removeItem("isPreAuthorization");
          localStorage.removeItem("preAuthorization");
          localStorage.removeItem("comprehensiveId");
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 500) {
            localStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const handleCancel = () => {
    navigate(
      isPreAuthorization && preAuthorization
        ? "/manage-preAuthorization-notes"
        : "/ManageComprehensive"
    );
  };

  useEffect(() => {
    fetchComprehensiveData();
  }, []);

  return (
    <div className="page-content-wrapper">
      <div className="page-content">
        <div className="page-bar">
          <div className="page-title-breadcrumb">
            <div className=" pull-left">
              <div className="page-title">
                {isPreAuthorization && preAuthorization
                  ? EDIT_PREAUTHORIZATION
                  : EDIT_COMPREHENSIVE}
              </div>
            </div>
            <ol className="breadcrumb page-breadcrumb pull-right">
              <li>
                <i className="fa fa-home"></i>&nbsp;
                <NavLink className="parent-item" to="/dashboard">
                  {HOME}
                </NavLink>
                &nbsp;<i className="fa fa-angle-right"></i>
              </li>
              <li>
                <i className="fa fa-sticky-note" aria-hidden="true"></i>&nbsp;
                <NavLink
                  className="parent-item"
                  to={
                    isPreAuthorization && preAuthorization
                      ? "/manage-preAuthorization-notes"
                      : "/ManageComprehensive"
                  }
                >
                  {isPreAuthorization && preAuthorization
                    ? PREAUTHORIZATION_LIST
                    : COMPREHENSIVE_LIST}
                </NavLink>
                &nbsp;<i className="fa fa-angle-right"></i>
              </li>
              <li className="active">
                {isPreAuthorization && preAuthorization
                  ? EDIT_PREAUTHORIZATION
                  : EDIT_COMPREHENSIVE}
              </li>
            </ol>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="card card-box">
              <div className="card-head">
                <header>
                  {isPreAuthorization && preAuthorization
                    ? PREAUTHORIZATION_INFORMATION
                    : COMPREHENSIVE_INFORMATION}
                </header>
              </div>

              <div className="card-body">
                {isLoading ? (
                  <div className="form-body" style={{ height: "100px" }}>
                    <div id="text">
                      <i className="fa fa-spinner fa-spin fa-2x"></i>
                    </div>
                  </div>
                ) : (
                  <CKEditor
                    config={{
                      height: "130",
                      removePlugins: [
                        "CKFinderUploadAdapter",
                        "CKFinder",
                        "EasyImage",
                        "Image",
                        "ImageCaption",
                        "ImageStyle",
                        "ImageToolbar",
                        "ImageUpload",
                        "MediaEmbed",
                      ],
                    }}
                    editor={ClassicEditor}
                    id="editor1"
                    onReady={(editor) => {
                      editor.setData(editorValue);
                    }}
                    onChange={(event, editor) => {
                      handleEditorChange(editor.getData());
                    }}
                  />
                )}
                <div>
                  <br />
                  <b>{DOCTOR_NAME}:</b> {doctorInfo.firstName}{" "}
                  {doctorInfo.lastName}
                  <br />
                  {/* <div className="row d-flex">
                    <div className="col-md-1">
                      <b>{DATE}:</b>{" "}
                    </div>
                    <div className="col-md-3">
                      <DatePicker
                        className="form-control input-height mr-sm-2"
                        // selected={new Date()}
                        value={dateFormateMonthDateYear(CNDate)}
                        placeholderText={"Please Select Date"}
                        maxDate={new Date()}
                        scrollableMonthDropdown={true}
                        showYearDropdown
                        showMonthDropdown
                        onChange={(date) => setCNDate(date)}
                      />
                    </div>
                  </div> */}
                  <br />
                </div>
                <div className="d-flex justify-content-center">
                  <button className="btn btn-success mx-2" onClick={handleSave}>
                    {SAVE}
                  </button>
                  <button
                    className="btn btn-success mx-2"
                    onClick={handlePdfDownload}
                  >
                    {DOWNLOAD}
                  </button>
                  <button
                    className="btn btn-default mx-2"
                    onClick={handleCancel}
                  >
                    {CANCEL}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditPostOp;
