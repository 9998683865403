import axios from "axios";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Zoom from "react-medium-image-zoom";
import { frontURL } from "../../../Config";
import { ACTION, CANCEL, RECEIPT_FILE, FILE_NAME, NO, THUMBNAIL } from "../../../Helper/Constants";

export default function DownloadReceiptFiles(props) {
    const handleDownload = (image, fileName, fixName) => {
        axios({
            url: `${frontURL}${image}`, //your url
            method: "GET",
            responseType: "blob", // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${fileName ? fileName : fixName + ".png"}`); //or any other extension
            //or any other extension
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        });
    };
    return (
        <>
            <Modal show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{RECEIPT_FILE}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {" "}
                    <div className="table-scrollable">
                        <table className="table table-hover table-checkable order-column full-width" id="example4">
                            <thead>
                                <tr>
                                    <th>{NO}</th>
                                    <th>{FILE_NAME}</th>
                                    <th>{THUMBNAIL}</th>
                                    <th>{ACTION}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!props.receiptFile ? (
                                    <>
                                        {
                                            <tr className="text-center" style={{ color: "red" }}>
                                                <td colSpan={15} className="text-center mt-5" style={{ color: "red" }}>
                                                    {/* No data Found */}
                                                    <img alt="" src="assets/img/NoDataFound/no_data_found.png" style={{ height: "200px", width: "auto" }} />
                                                </td>
                                            </tr>
                                        }
                                    </>
                                ) : (
                                    props.receiptFile &&
                                    props.receiptFile.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{(1 - 1) * 100 + i + 1}</td>
                                                <td>{item.fileName ? item.fileName : RECEIPT_FILE + ".png"}</td>

                                                <td>
                                                    {item.document && item.document.split(".").at(-1).toLowerCase() == "pdf" ? (
                                                        <div type="button" className="btn btn-outline-danger" onClick={() => window.open(item.document, "_blank")}>
                                                            <i className="fa  fa-file-pdf-o fa-2x my-2"></i>
                                                        </div>
                                                    ) : (
                                                        <Zoom>
                                                            <img src={item.document} width="100" height="100" alt="Receipt Document" style={{ height: "50px", width: "50px" }} />
                                                        </Zoom>
                                                    )}
                                                </td>
                                                <td>
                                                    <button type="button" className="btn btn-success btn-xs" onClick={() => handleDownload(item.document, item.fileName, RECEIPT_FILE)}>
                                                        <i className="fa  fa-download"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })
                                )}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        {CANCEL}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
