import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { baseURL } from "../../Config";
import {
    ACTION,
    ADD,
    BIRTH_DATE,
    FIRST_NAME,
    LAST_NAME,
    MEDICARE,
    OFCLAIMS,
    PATIENT_LIST,
    REGISTER_DATE,
    DASH,
    HOME,
    ROWS_PER_PAGE,
    CREATED_BY,
    ROLES,
    OFFICE_NAME,
    SELECT_OFFICE,
    DOCTOR_PAGES,
} from "../../Helper/Constants";
import ReactPaginate from "react-paginate";
import { dateFormateMonthDateYear, dateTimeFormate } from "../../Helper/Helper";
import { useNavigate, Link, NavLink } from "react-router-dom";
import { DELETE_PATIENT_API, MANAGE_PATIENT_API, OFFICE_LIST_WITHOUT_PAGINATION } from "../../Helper/ApiPath";
import NoDataFound from "../CommonComponent/NoDataFound";
import { DELETE_PATIENT_MESSAGE } from "../../Helper/Messages";
import Swal from "sweetalert2";
import Loader from "../CommonComponent/Loader";
import Cookies from "universal-cookie";
import Select from "react-select";

function ManagePatient() {
    const cookies = new Cookies();
    const patientPagination = JSON.parse(sessionStorage.getItem("patientPagination"));
    let adminRole = localStorage.getItem("adminRole");

    let role = cookies.get("role");
    if (role) {
        role = cookies.get("role").roleId;
    }
    let pageAccess = cookies.get("doctorPageAccess") ? (cookies.get("doctorPageAccess").length ? cookies.get("doctorPageAccess") : [14, 15, 16, 17, 18, 19, 20]) : "";
    const [currentPage, setCurrentPage] = useState(0);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [patientList, setPatientList] = useState([]);
    const [query, setQuery] = useState(patientPagination && patientPagination.query ? patientPagination.query : "");
    const [offset, setOffset] = useState(patientPagination && patientPagination.offset ? patientPagination.offset : 1);

    const [rowCount, setRowCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [office, setOffice] = useState(patientPagination && patientPagination.officeId ? patientPagination.officeId : "");
    const [officeList, setOfficeList] = useState([]);

    let token = JSON.parse(localStorage.getItem("logToken"));
    let officeId = localStorage.getItem("officeId");
    let isTrial = cookies.get("isTrial");
    if (isTrial) {
        isTrial = cookies.get("isTrial").isTrial;
    }
    const handleSearch = (e) => {
        sessionStorage.setItem(
            "patientPagination",
            JSON.stringify({
                query: query,
                offset: offset,
                officeId: office,
            })
        );
        setQuery(e.target.value);
        setOffset(1);
        setCurrentPage(0);
    };
    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${MANAGE_PATIENT_API}`, {
                headers: { authorization: token.token },
                page: offset,
                rowsPerPage: ROWS_PER_PAGE,
                officeId: role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? officeId : office,
                search: query,
            })
            .then((res) => {
                setIsLoading(false);
                setPatientList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        sessionStorage.removeItem("patientPagination");
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleEdit = async (id) => {
        sessionStorage.setItem(
            "patientPagination",
            JSON.stringify({
                query: query,
                offset: offset,
                officeId: office,
            })
        );
        localStorage.setItem("patientId", id);
        navigate("/editPatient");
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: DELETE_PATIENT_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/${DELETE_PATIENT_API}/${id}`, {
                            headers: { authorization: token.token },
                        })
                        .then((res) => {
                            Swal.fire(res.data.message, " ", "success");

                            fetchData();
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    let officeSelectList = [
        {
            id: "",
            name: SELECT_OFFICE,
        },
    ];

    const officeListData = () => {
        axios
            .post(`${baseURL}/${OFFICE_LIST_WITHOUT_PAGINATION}`, {
                headers: { authorization: token.token },
            })
            .then((res) => {
                setOfficeList(res.data.data ? officeSelectList.concat(res.data.data) : officeSelectList);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const officeOption =
        officeList &&
        officeList.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const handleOfficeChange = (e) => {
        sessionStorage.removeItem("patientPagination");
        setOffice(e.value);
        setOffset(1);
        setCurrentPage(0);
    };

    const handlePatient = (e) => {
        navigate("/addPatient");
    };
    const handleId = (id, officeId) => {
        sessionStorage.setItem(
            "patientPagination",
            JSON.stringify({
                query: query,
                offset: offset,
                officeId: office,
            })
        );
        localStorage.setItem("patientId", id);
        localStorage.setItem("officeId", officeId);
    };
    useEffect(() => {
        localStorage.removeItem("patientId");
        fetchData();
        if (role != ROLES.DOCTOR && adminRole != ROLES.DOCTOR) {
            officeListData();
        }
    }, [offset, query, office]);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{PATIENT_LIST}</div>
                            </div>
                            {isTrial == 0 || pageAccess.includes(DOCTOR_PAGES.Patient) ? (
                                <ol className="breadcrumb page-breadcrumb pull-right">
                                    <li>
                                        <i className="fa fa-home"></i>&nbsp;
                                        <NavLink className="parent-item" to="/dashboard">
                                            {HOME}
                                        </NavLink>
                                        &nbsp;<i className="fa fa-angle-right"></i>
                                    </li>
                                    <li className="active">{PATIENT_LIST}</li>
                                </ol>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tabbable-line">
                                <div className="tab-content">
                                    <div className="tab-pane active fontawesome-demo" id="tab1">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card">
                                                    <div className="card-body ">
                                                        <div className="row">
                                                            <div className="d-flex justify-content-between">
                                                                <div className="col-md-2">
                                                                    {role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? (
                                                                        <div className="btn-group">
                                                                            <button id="addRow" className="btn btn-info" onClick={handlePatient}>
                                                                                {ADD} <i className="fa fa-plus"></i>
                                                                            </button>
                                                                        </div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                                <div className="col-5"></div>
                                                                {role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? (
                                                                    <div className="col-md-2"></div>
                                                                ) : (
                                                                    <div className="col-md-2">
                                                                        <Select
                                                                            name="office"
                                                                            options={officeOption}
                                                                            value={officeOption && officeOption.filter(({ value }) => value == office)}
                                                                            onChange={handleOfficeChange}
                                                                            placeholder={SELECT_OFFICE}
                                                                        ></Select>
                                                                    </div>
                                                                )}
                                                                <div className="col-2 d-flex justify-content-end">
                                                                    <input
                                                                        className="form-control mr-sm-2"
                                                                        name="query"
                                                                        type="search"
                                                                        value={query}
                                                                        placeholder="Patient Name"
                                                                        aria-label="Search"
                                                                        onChange={handleSearch}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="table-scrollable" style={isLoading ? { border: "none" } : {}}>
                                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                <thead>
                                                                    <tr>
                                                                        {role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? "" : <th>{OFFICE_NAME}</th>}
                                                                        <th>{LAST_NAME}</th>
                                                                        <th>{FIRST_NAME}</th>
                                                                        <th>{BIRTH_DATE}</th>
                                                                        <th>{REGISTER_DATE}</th>
                                                                        <th>{MEDICARE}</th>
                                                                        <th>{OFCLAIMS}</th>
                                                                        <th>{CREATED_BY}</th>
                                                                        <th>{ACTION}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={isLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                                    {isLoading ? (
                                                                        <>
                                                                            <Loader />
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {!patientList ? (
                                                                                <>{isLoading ? "" : <NoDataFound />}</>
                                                                            ) : (
                                                                                patientList &&
                                                                                patientList.map((item, index) => {
                                                                                    return (
                                                                                        <tr className="odd gradeX" key={index}>
                                                                                            {role == ROLES.DOCTOR || adminRole == ROLES.DOCTOR ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td className="left">{item.office ? item.office.name : DASH}</td>
                                                                                            )}
                                                                                            <td className="left">
                                                                                                <Link
                                                                                                    to="/patientProfile"
                                                                                                    style={{ textDecoration: "underline", color: "#2a32be" }}
                                                                                                    onClick={() => handleId(item.id, item.office.uniqueId)}
                                                                                                >
                                                                                                    {item.lastName}
                                                                                                </Link>
                                                                                            </td>
                                                                                            <td className="left">{item.firstName}</td>
                                                                                            <td>{item.dob ? dateFormateMonthDateYear(item.dob) : DASH}</td>
                                                                                            <td className="left">{dateTimeFormate(item.created_at)}</td>
                                                                                            <td className={`${item.ssnId ? "left" : "mr-2"}`}>
                                                                                                {item.MedicarePatientId ? item.MedicarePatientId : DASH}
                                                                                            </td>
                                                                                            <td className="center">{item.totalClaim}</td>
                                                                                            <td>{item.create_by ? item.create_by : DASH}</td>
                                                                                            <td>
                                                                                                <button className="btn btn-primary btn-xs" onClick={() => handleEdit(item.id)}>
                                                                                                    <i className="fa fa-pencil"></i>
                                                                                                </button>
                                                                                                {item.totalClaim > 0 ? (
                                                                                                    ""
                                                                                                ) : (
                                                                                                    <button className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
                                                                                                        <i className="fa fa-trash-o"></i>
                                                                                                    </button>
                                                                                                )}
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mx-4">
                                                        <div>Total {patientList == null ? "0" : rowCount.toLocaleString("en-US")} Patient</div>
                                                        <div className="pagination-center">
                                                            <ReactPaginate
                                                                initialPage={0}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={0}
                                                                pageRangeDisplayed={3}
                                                                onPageChange={handlePageClick}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                pageLinkClassName="page-link"
                                                                breakLinkClassName="page-link"
                                                                nextLinkClassName="page-link"
                                                                previousLinkClassName="page-link"
                                                                pageClassName="page-item"
                                                                breakClassName="page-item"
                                                                nextClassName="page-item"
                                                                previousClassName="page-item"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"
                                                                disableInitialCallback={true}
                                                                forcePage={patientPagination && patientPagination.offset ? patientPagination.offset - 1 : currentPage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManagePatient;
