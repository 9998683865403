import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { NavLink, useNavigate } from "react-router-dom";
import { ACTION, ADD, CANCEL, HOME, VALUE, NAME, PAYER_INFORMATION, ROWS_PER_PAGE, UPDATE, DASH } from "../../Helper/Constants";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { baseURL } from "../../Config";
import { PAYER_ADD, PAYER_LIST_ADMIN_API, PAYER_EDIT, PAYER_UPDATE } from "../../Helper/ApiPath";
import { DELETE_MESSAGE } from "../../Helper/Messages";
import NoDataFound from "../CommonComponent/NoDataFound";
import Swal from "sweetalert2";
import Loader from "../CommonComponent/Loader";

function ManagePayer() {
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [payerList, setPayerList] = useState([]);
    const [offset, setOffset] = useState(1);
    const [rowCount, setRowCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [proLoader, setProLoader] = useState(false);
    const token = JSON.parse(localStorage.getItem("logToken"));
    const navigate = useNavigate();
    const [payerValue, setPayerValue] = useState({
        value: "",
        name: "",
    });

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const [filterValues, setfilterValues] = useState({
        search: "",
    });
    const handleFilterValues = (e) => {
        const { name, value } = e.target;
        setfilterValues({ ...filterValues, [name]: value });
        setOffset(1);
        setCurrentPage(0);
    };

    const [editPayerStatus, setEditPayerStatus] = useState(false);

    const [showPayer, setShowPayer] = useState(false);
    const handleClosePayer = () => setShowPayer(false);
    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${PAYER_LIST_ADMIN_API}`, {
                headers: { authorization: token.token },
                page: offset,
                rowsPerPage: ROWS_PER_PAGE,
                search: filterValues.search,
            })
            .then((res) => {
                setIsLoading(false);
                setPayerList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleShowPayer = (type) => {
        if (type === "add") {
            setShowPayer(true);
            setPayerValue("");
            setEditPayerStatus(false);
        } else {
            setShowPayer(true);
        }
        setProLoader(false);
    };

    const handleAddPayer = async (id) => {
        if (!proLoader) {
            if (!payerValue.value) {
                toast.error("Enter payer value");
            } else if (!payerValue.name) {
                toast.error("Enter payer name");
            } else {
                setProLoader(true);
                await axios
                    .post(`${baseURL}/${PAYER_ADD}`, {
                        headers: { authorization: token.token },
                        value: payerValue.value,
                        name: payerValue.name,
                    })
                    .then((res) => {
                        setProLoader(false);
                        handleClosePayer(false);
                        toast.success("Payer has been added successfully");
                        setPayerValue("");
                        fetchData();
                    })
                    .catch((err) => {
                        setProLoader(false);
                        toast.error(err.response.data.message);
                    });
            }
        }
    };

    const handleEditPayer = async (id) => {
        handleShowPayer(true);
        setEditPayerStatus(true);

        await axios
            .get(`${baseURL}/${PAYER_EDIT}/${id}`, {
                headers: {
                    authorization: token.token,
                },
            })
            .then((res) => {
                handleShowPayer(true);
                if (res.status === 200) {
                    setPayerValue(res.data.data);
                    setEditPayerStatus(true);
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleUpdatePayer = async () => {
        if (!proLoader) {
            if (!payerValue.value) {
                toast.error("Enter payer value");
            } else if (!payerValue.name) {
                toast.error("Enter payer name");
            } else {
                setProLoader(true);
                await axios
                    .post(`${baseURL}/${PAYER_UPDATE}`, {
                        headers: { authorization: token.token },
                        id: payerValue.id,
                        value: payerValue.value,
                        name: payerValue.name,
                    })
                    .then((res) => {
                        setProLoader(false);
                        if (res.status === 200) {
                            handleClosePayer(false);
                            setPayerValue({
                                value: "",
                                name: "",
                            });
                            toast.success(res.data.message);
                            fetchData();
                        }
                    })
                    .catch((err) => {
                        setProLoader(false);
                        toast.error(err.response.data.message);
                    });
            }
        }
    };

    const handleDeletePayer = async (id) => {
        Swal.fire({
            title: DELETE_MESSAGE,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/${PAYER_EDIT}/${id}`, {
                            headers: {
                                authorization: token.token,
                            },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                setPayerValue(res.data.data);
                                Swal.fire(res.data.message, " ", "success");

                                fetchData();
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    useEffect(() => {
        fetchData();
    }, [offset, filterValues]);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">Payer List</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">Payer</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tabbable-line">
                                <div className="tab-content">
                                    <div className="tab-pane active fontawesome-demo" id="tab1">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card" style={isLoading ? { height: "35rem" } : {}}>
                                                    <div className="card-head">
                                                        <header>{PAYER_INFORMATION}</header>
                                                    </div>
                                                    <div className="card-body ">
                                                        <div className="row">
                                                            <div className="row d-flex justify-content-between">
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="btn-group">
                                                                        <button id="addRow" className="btn btn-info" onClick={() => handleShowPayer("add")}>
                                                                            {ADD}
                                                                            <i className="fa fa-plus"></i>
                                                                        </button>
                                                                        <Modal show={showPayer} onHide={handleClosePayer}>
                                                                            <Modal.Header closeButton>
                                                                                {editPayerStatus ? <Modal.Title>Update Payer</Modal.Title> : <Modal.Title>Add Payer</Modal.Title>}
                                                                            </Modal.Header>
                                                                            <Modal.Body>
                                                                                <div className="form-group">
                                                                                    <span className="">{VALUE}</span>
                                                                                    <span className="requireField"> * </span>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        value={payerValue.value}
                                                                                        placeholder="Enter value"
                                                                                        onChange={(e) => setPayerValue({ ...payerValue, value: e.target.value })}
                                                                                    />
                                                                                </div>
                                                                                <div className="form-group">
                                                                                    <span className="">{NAME}</span>
                                                                                    <span className="requireField"> * </span>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        value={payerValue.name}
                                                                                        placeholder="Enter name"
                                                                                        onChange={(e) => setPayerValue({ ...payerValue, name: e.target.value })}
                                                                                    />
                                                                                </div>
                                                                            </Modal.Body>
                                                                            <div className="d-flex justify-content-center mb-3">
                                                                                <div className="center">
                                                                                    {editPayerStatus ? (
                                                                                        <Button variant="primary" onClick={handleUpdatePayer}>
                                                                                            {proLoader ? <i className="spinner-border"></i> : ""} {UPDATE}
                                                                                        </Button>
                                                                                    ) : (
                                                                                        <Button variant="primary" onClick={handleAddPayer}>
                                                                                            {proLoader ? <i className="spinner-border"></i> : ""} {ADD}
                                                                                        </Button>
                                                                                    )}

                                                                                    <Button className="mx-3" variant="secondary" onClick={handleClosePayer}>
                                                                                        {CANCEL}
                                                                                    </Button>
                                                                                </div>
                                                                            </div>
                                                                        </Modal>
                                                                    </div>
                                                                    <div className="btn-group" style={{ marginRight: "-20px" }}>
                                                                        <input
                                                                            className="form-control"
                                                                            name="search"
                                                                            type="search"
                                                                            placeholder="Search"
                                                                            aria-label="Search"
                                                                            value={filterValues.search}
                                                                            onChange={handleFilterValues}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-scrollable" style={isLoading ? { border: "none" } : {}}>
                                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{VALUE}</th>
                                                                        <th>{NAME}</th>
                                                                        <th>{ACTION}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody style={isLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                                    {isLoading ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        <>
                                                                            {!payerList ? (
                                                                                <>{isLoading ? "" : <NoDataFound />}</>
                                                                            ) : (
                                                                                payerList &&
                                                                                payerList.map((item, index) => {
                                                                                    return (
                                                                                        <tr key={index}>
                                                                                            <td>{item.value ? item.value : DASH}</td>
                                                                                            <td>{item.name}</td>
                                                                                            <td>
                                                                                                <button className="btn btn-primary btn-xs" onClick={() => handleEditPayer(item.id)}>
                                                                                                    <i className="fa fa-pencil"></i>
                                                                                                </button>
                                                                                                <button type="button" className="btn btn-danger btn-xs" onClick={() => handleDeletePayer(item.id)}>
                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mx-4">
                                                        <div>Total {payerList == null ? "0" : rowCount} Payer</div>
                                                        <div className="pagination-center">
                                                            <ReactPaginate
                                                                initialPage={0}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={0}
                                                                pageRangeDisplayed={3}
                                                                onPageChange={handlePageClick}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                pageLinkClassName="page-link"
                                                                breakLinkClassName="page-link"
                                                                nextLinkClassName="page-link"
                                                                previousLinkClassName="page-link"
                                                                pageClassName="page-item"
                                                                breakClassName="page-item"
                                                                nextClassName="page-item"
                                                                previousClassName="page-item"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"
                                                                disableInitialCallback={true}
                                                                forcePage={currentPage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManagePayer;
