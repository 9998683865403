import axios from "axios";
import React, { useState } from "react";
import { baseURL } from "../../Config";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CONFIRM, CONFIRM_PASSWORD, PASSWORD, SHOW_PASSWORD } from "../../Helper/Constants";
import { CONFIRM_RESET_PASSWORD } from "../../Helper/ApiPath";
import { EMPTY_FIELD, MATCH_PASSWORD } from "../../Helper/Messages";

function ConfirmPassword() {
    const [password, setPassword] = useState("");
    const [cPassword, setCPassword] = useState("");
    const navigate = useNavigate();

    const showPassword = () => {
        var pswd = document.getElementById("password");
        if (pswd.type === "password") {
            pswd.type = "text";
        } else {
            pswd.type = "password";
        }
    };
    const handleConfirm = (e) => {
        e.preventDefault();
        if (!password || !cPassword) {
            toast.error(EMPTY_FIELD);
        } else if (password !== cPassword) {
            toast.error(MATCH_PASSWORD);
        } else {
            axios
                .post(`${baseURL}/${CONFIRM_RESET_PASSWORD}`, { newPass: password })
                .then((res) => {
                    if (res.status == 200) {
                        toast.success(res.data.message);
                        navigate("/login");
                    }
                })
                .catch((err) => {
                    toast.error(err.response.data.message);
                });
        }
    };

    return (
        <>
            <div className="limiter">
                <div className="container-login100 page-background">
                    <div className="wrap-login100">
                        <form className="login100-form validate-form">
                            <span className="login100-form-logo">
                                <img alt="" src="assets/img/hospital.png" />
                            </span>

                            <p className="text-center txt-small-heading">
                                Forgot Your Password? <br />
                                Let Us Help You.
                            </p>
                            <div className="form-group">
                                <i className="fa fa-lock font-left textColor" aria-hidden="true"></i>
                                <span className="textColor">{PASSWORD}</span>
                                <span className="requireField"> * </span>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    placeholder="Enter Password.."
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <i className="fa fa-lock font-left textColor" aria-hidden="true"></i>
                                <span className="textColor">{CONFIRM_PASSWORD}</span>
                                <span className="requireField"> * </span>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    name="cPassword"
                                    placeholder="Confirm Your Password.."
                                    value={cPassword}
                                    onChange={(e) => setCPassword(e.target.value)}
                                />
                            </div>
                            <div className="contact100-form-checkbox">
                                <input className="input-checkbox100" id="ckb1" type="checkbox" name="remember-me" onClick={showPassword} />
                                <label className="label-checkbox100" htmlFor="ckb1">
                                    {SHOW_PASSWORD}
                                </label>
                            </div>
                            <div className="container-login100-form-btn">
                                <button className="login100-form-btn" onClick={(e) => handleConfirm(e)}>
                                    {CONFIRM}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ConfirmPassword;
