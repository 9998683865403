import React, { useEffect, useState } from "react";
import {
    HOME,
    CREATED_DATE,
    DASH,
    ROWS_PER_PAGE,
    EOB_LIST,
    OFFICE_NAME,
    PAYER,
    EFT_CHECK,
    REMIT_DATE,
    PAID_AMOUNT,
    DOWNLOAD_EOB,
    SELECT_PAYER,
    DOWNLOAD,
    DOWNLOAD_EXCEL,
} from "../../Helper/Constants";
import { EOB_DOCUMENT_LIST_API, EOB_EXCEL_DOWNLOAD_API, EOB_LIST_API, PAYER_LIST_WITHOUT_MIDDLEWARE_API } from "../../Helper/ApiPath";
import { dateAPIFormate, dateFormateMonthDateYear } from "../../Helper/Helper";
import axios from "axios";
import { baseURL, frontURL } from "../../Config";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { NavLink, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NoDataFound from "../CommonComponent/NoDataFound";
import Loader from "../CommonComponent/Loader";
import Select from "react-select";
import DownloadEOBFiles from "../Forms/EOB/DownloadEOBFiles";

function DoctorManageEOB() {
    const [currentPage, setCurrentPage] = useState(0);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [EOBList, setEOBList] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [payer, setPayer] = useState([]);
    const [show, setShow] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);

    let token = JSON.parse(localStorage.getItem("logToken"));
    let officeId = localStorage.getItem("officeId");

    var date = new Date();
    const [filterValues, setFilterValues] = useState({
        status: "",
        officeSearch: "",
        payerSearch: "",
        startDate: new Date(date.setMonth(date.getMonth() - 12)),
        endDate: new Date(date.setMonth(date.getMonth() + 13)),
        EFTCheck: "",
    });

    const [EOBFile, setEOBFile] = useState([]);

    const fetchThumbnailData = async (EOBId) => {
        await axios
            .post(`${baseURL}/${EOB_DOCUMENT_LIST_API}`, {
                headers: { authorization: token.token },
                EOBId: EOBId,
            })
            .then((res) => {
                setEOBFile(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleShow = (id) => {
        setShow(true);
        fetchThumbnailData(id);
    };

    const handleClose = () => {
        setShow(false);
    };

    let selectpayerList = [
        {
            id: "",
            value: "",
            name: SELECT_PAYER,
        },
    ];

    const payerList = () => {
        axios
            .get(`${baseURL}/${PAYER_LIST_WITHOUT_MIDDLEWARE_API}`)
            .then((res) => {
                setPayer(selectpayerList.concat(res.data.data));
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const payerOptions =
        payer &&
        payer.map((item, i) => {
            return {
                id: item.id,
                value: item.value,
                label: item.name,
            };
        });

    const handlePayerChange = (e) => {
        let value = e.id;
        setFilterValues({ ...filterValues, payerSearch: value });
    };

    const fetchData = async () => {
        await axios
            .post(`${baseURL}/${EOB_LIST_API}`, {
                headers: { authorization: token.token },
                officeId: officeId,
                payerId: filterValues.payerSearch,
                startDate: dateAPIFormate(filterValues.startDate),
                endDate: dateAPIFormate(filterValues.endDate),
                EFTCheck: filterValues.EFTCheck,
                page: offset,
                rowsPerPage: ROWS_PER_PAGE,
                adminYN: "N",
            })
            .then((res) => {
                setIsLoading(false);
                setEOBList(res.data.data[0]);
                setRowCount(res.data.extraData.rowCount);
                setPageCount(res.data.extraData.pageCount);
                setTotalAmount(res.data.data[1].totalPaidAmount);
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.response) {
                    if (err.response.status === 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleExcelDownload = async () => {
        await axios
            .post(`${baseURL}/${EOB_EXCEL_DOWNLOAD_API}`, {
                headers: { authorization: token.token },
                officeId: officeId,
                payerId: filterValues.payerSearch,
                startDate: dateAPIFormate(filterValues.startDate),
                endDate: dateAPIFormate(filterValues.endDate),
            })
            .then((res) => {
                if (res.status === 200) {
                    axios({
                        url: `${frontURL}${res.data.data.EobPath}`, //your url
                        method: "GET",
                        responseType: "blob", // important
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", "eobdownload" + ".xlsx"); //or any other extension
                        link.click();
                        link.remove();
                        window.URL.revokeObjectURL(url);
                    });
                    // console.log("res", res.data.data.EobPath);
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        payerList();
        fetchData();
    }, [offset, filterValues]);

    return (
        <>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="page-bar">
                        <div className="page-title-breadcrumb">
                            <div className=" pull-left">
                                <div className="page-title">{EOB_LIST}</div>
                            </div>
                            <ol className="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i className="fa fa-home"></i>&nbsp;
                                    <NavLink className="parent-item" to="/dashboard">
                                        {HOME}
                                    </NavLink>
                                    &nbsp;<i className="fa fa-angle-right"></i>
                                </li>
                                <li className="active">{EOB_LIST}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tabbable-line">
                                <div className="tab-content">
                                    <div className="tab-pane active fontawesome-demo" id="tab1">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card" style={isLoading ? { height: "35rem" } : {}}>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="justify-content-between">
                                                                <div className="row">
                                                                    <div className="btn-group col-md-3 pr-0">
                                                                        <div className="col-md-4">
                                                                            <label className="mt-2">{REMIT_DATE}</label>
                                                                        </div>
                                                                        <DatePicker
                                                                            className="form-control mr-sm-2"
                                                                            selected={filterValues.startDate}
                                                                            maxDate={new Date().setMonth(new Date().getMonth() + 1)}
                                                                            yearDropdownItemNumber={100}
                                                                            scrollableMonthDropdown={true}
                                                                            showYearDropdown
                                                                            showMonthDropdown
                                                                            onChange={(date) => setFilterValues({ ...filterValues, startDate: date })}
                                                                        />
                                                                        <span className="todate">To</span>
                                                                    </div>
                                                                    <div className="btn-group col-md-2">
                                                                        <DatePicker
                                                                            className="form-control mr-sm-2"
                                                                            selected={filterValues.endDate}
                                                                            minDate={filterValues.startDate}
                                                                            maxDate={new Date().setMonth(new Date().getMonth() + 1)}
                                                                            yearDropdownItemNumber={100}
                                                                            scrollableMonthDropdown={true}
                                                                            showYearDropdown
                                                                            showMonthDropdown
                                                                            onChange={(date) => setFilterValues({ ...filterValues, endDate: date })}
                                                                        />
                                                                    </div>
                                                                    <div className="col-1"></div>
                                                                    <div className="col-6">
                                                                        <div className="row d-flex justify-content-end">
                                                                            <Select className="col-5" name="payer" options={payerOptions} onChange={handlePayerChange} placeholder={SELECT_PAYER} />

                                                                            <div className="col-3">
                                                                                <div className="btn-group nameBtnDoctor">
                                                                                    <input
                                                                                        className="form-control"
                                                                                        type="text"
                                                                                        name="EFTCheck"
                                                                                        onChange={(e) => setFilterValues({ ...filterValues, EFTCheck: e.target.value })}
                                                                                        placeholder={EFT_CHECK}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="table-scrollable" style={isLoading ? { border: "none" } : {}}>
                                                            <table className="table table-hover table-checkable order-column full-width" id="example4">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{OFFICE_NAME}</th>
                                                                        <th>{PAYER}</th>
                                                                        <th>{EFT_CHECK}</th>
                                                                        <th>{REMIT_DATE}</th>
                                                                        <th>{PAID_AMOUNT}</th>
                                                                        <th>{CREATED_DATE}</th>
                                                                        <th>{DOWNLOAD_EOB}</th>
                                                                    </tr>
                                                                </thead>
                                                                <DownloadEOBFiles show={show} handleShow={handleShow} handleClose={handleClose} EOBFile={EOBFile} />

                                                                <tbody style={isLoading ? { borderStyle: "none", borderColor: "#fff" } : {}}>
                                                                    {isLoading ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        <>
                                                                            {!EOBList || (EOBList && !EOBList.length) ? (
                                                                                <NoDataFound />
                                                                            ) : (
                                                                                EOBList &&
                                                                                EOBList.map((item, index) => {
                                                                                    return (
                                                                                        <tr className="odd gradeX">
                                                                                            <td>{item.office.name}</td>
                                                                                            <td>{item.payer.name}</td>
                                                                                            <td>{item.EFTCheck ? item.EFTCheck : DASH}</td>
                                                                                            <td>{item.remitDate ? dateFormateMonthDateYear(item.remitDate) : DASH}</td>
                                                                                            <td>${item.paidAmount ? item.paidAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : DASH}</td>
                                                                                            <td>{dateFormateMonthDateYear(item.created_at)}</td>
                                                                                            <td>
                                                                                                <button className="btn btn-success" onClick={() => handleShow(item.id)}>
                                                                                                    {DOWNLOAD} <i className="fa fa-download"></i>
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mx-4">
                                                        <div className="d-flex justify-content-start">
                                                            <div>Total {EOBList == null ? "0" : rowCount.toLocaleString("en-US")} EOB</div>
                                                            {/* <div className="mx-3">Total Amount ${totalAmount == null ? "0" : totalAmount.toLocaleString("en-US")} </div> */}
                                                            {/* <button className="btn btn-warning mx-3" onClick={handleExcelDownload} style={{ marginBottom: "auto" }}>
                                                                {DOWNLOAD_EXCEL} <i className="fa fa-download"></i>
                                                            </button> */}
                                                        </div>
                                                        <div className="pagination-center">
                                                            <ReactPaginate
                                                                initialPage={0}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={0}
                                                                pageRangeDisplayed={3}
                                                                onPageChange={handlePageClick}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                pageLinkClassName="page-link"
                                                                breakLinkClassName="page-link"
                                                                nextLinkClassName="page-link"
                                                                previousLinkClassName="page-link"
                                                                pageClassName="page-item"
                                                                breakClassName="page-item"
                                                                nextClassName="page-item"
                                                                previousClassName="page-item"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"
                                                                disableInitialCallback={true}
                                                                forcePage={currentPage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DoctorManageEOB;
