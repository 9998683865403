import axios from "axios";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../../Config";
import { EDIT_DOCTOR_PROFILE_PIC, UPLOAD_AGREEMENT_API, UPLOAD_ATTACHMENT } from "../../../Helper/ApiPath";
import { AGREEMENT_FILE, ATTACHMENT, CANCEL, UPDATE } from "../../../Helper/Constants";
import { UPLOAD_IMAGE } from "../../../Helper/Messages";

function NoticeDocument(props) {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    let logToken = JSON.parse(localStorage.getItem("logToken")).token;

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let [attachment, setAttachment] = useState({ preview: "", data: "" });

    const handleChange = (e) => {
        const attachment = {
            preview: URL.createObjectURL(e.target.files[0]),
            data: e.target.files[0],
        };
        // console.log("first", attachment);
        const fileExtension = attachment.data.name.split(".").at(-1).toLowerCase();
        const allowedFileTypes = ["pdf", "jpg", "png", "gif"];
        if (!allowedFileTypes.includes(fileExtension)) {
            e.target.value = null;
            alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
        } else {
            let element = document.getElementById("button");
            element.classList.remove("d-none");
            setAttachment(attachment);
        }
    };

    const handleUpload = async () => {
        let formData = new FormData();
        if (!attachment.data) {
            toast.error(UPLOAD_IMAGE);
        } else {
            formData.append("attachment", attachment.data);
            formData.append("id", props.noticeId);
            await axios
                .post(`${baseURL}/${UPLOAD_ATTACHMENT}`, formData, {
                    headers: { authorization: logToken, "Content-Type": "multipart/form-data", accept: "application/json" },
                })
                .then((res) => {
                    if (res.status === 200) {
                        setShow(false);
                        props.fetchData();
                        toast.success(res.data.message);
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    return (
        <>
            <button type="button" className="btn btn-success btn-xs" onClick={handleShow}>
                <i className="fa fa-upload"></i>
            </button>
            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{ATTACHMENT}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="offset-3 col-md-6">
                            <input type="file" className="form-control" name="attachment" onChange={handleChange} />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="primary d-none" id="button" onClick={handleUpload}>
                        {UPDATE}
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        {CANCEL}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default NoticeDocument;
