import axios from "axios";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { baseURL } from "../../../Config";
import { ADMIN_INSERT_CSV_DATA, ADMIN_VALIDATE_CSV_DATA, ADMIN_UPLOAD_QUESTION_EXCEL_FILE } from "../../../Helper/ApiPath";
import { CANCEL, DOWNLOAD_EXCEL, UPLOAD_EXCEL } from "../../../Helper/Constants";
import { ARE_YOU_SURE_YOU_WANT_YO_UPLOAD_THIS_FILE } from "../../../Helper/Messages";

function UploadExcelFile(props) {
    let navigate = useNavigate();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let token = JSON.parse(localStorage.getItem("logToken"));
    let [File, setFile] = useState({ preview: "", data: "" });

    const handleFileChange = (e) => {
        const data = {
            preview: URL.createObjectURL(e.target.files[0]),
            data: e.target.files[0],
            name: e.target.files[0].name,
        };

        const fileExtension = data.data.name.split(".").at(-1).toLowerCase();

        let allowedFileTypes = ["xml", "xlsx"];

        if (!allowedFileTypes.includes(fileExtension)) {
            e.target.value = null;
            alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
        } else {
            let element = document.getElementById("button");
            element.classList.remove("d-none");
            setFile(data);
        }
    };
    const handleValidateUploadExcel = async () => {
        let formData = new FormData();
        formData.append("excelFile", File.data);
        await axios
            .post(`${baseURL}/${ADMIN_UPLOAD_QUESTION_EXCEL_FILE}`, formData, {
                headers: { authorization: token.token, "Content-Type": "application/vnd.ms-excel" },
            })
            .then(async (response) => {
                if (response.data.success == true) {
                    handleClose();
                    await axios
                        .post(`${baseURL}/${ADMIN_VALIDATE_CSV_DATA}`, {
                            headers: { authorization: token.token },
                            isTabData: true,
                        })
                        .then(async (res) => {
                            if (res.data.success == true) {
                                await axios
                                    .post(`${baseURL}/${ADMIN_VALIDATE_CSV_DATA}`, {
                                        headers: { authorization: token.token },
                                        isGroupData: true,
                                    })
                                    .then(async (res1) => {
                                        var arry1 = res.data.data.grouplist;
                                        var arry2 = res1.data.data.grouplist;
                                        arry1 = arry1.filter(function (item, index, inputArray) {
                                            return inputArray.indexOf(item) == index;
                                        });
                                        arry1 = arry1.filter((val) => !arry2.includes(val));
                                        var html = "";
                                        html =
                                            res.data.data.tablist.length == 0
                                                ? ""
                                                : '<h5 style="color:black;"><b> New  TabList : </b></h5> <h5  style="color:black;">' + res.data.data.tablist.toString() + "</h5></br> ";
                                        var html2 =
                                            arry1.length == 0 && arry2.length == 0
                                                ? ""
                                                : arry1.length == 0 && arry2.length >= 0
                                                ? '<h5  style="color:black;"><b> New  GroupList : </b></h5> <h5  style="color:black;">' + arry2.toString() + "</h5></br>"
                                                : arry1.length > 0 && arry2.length > 0
                                                ? '<h5  style="color:black;"><b> New  GroupList : </b></h5> <h5  style="color:black;">' + arry1.toString() + "," + arry2.toString() + "</h5></br>"
                                                : arry1.length > 0 && arry1 != ""
                                                ? '<h5  style="color:black;"><b> New  GroupList : </b></h5> <h5  style="color:black;">' + arry1.toString() + "</h5></br>"
                                                : arry2.length > 0 && arry2 != ""
                                                ? '<h5  style="color:black;"><b> New  GroupList : </b></h5> <h5  style="color:black;">' + arry2.toString() + "</h5></br>"
                                                : "";
                                        html = html + html2;
                                        html = html == "" ? "<h3>No New Tab and Group</h3>" : html;

                                        Swal.fire({
                                            title: ARE_YOU_SURE_YOU_WANT_YO_UPLOAD_THIS_FILE,
                                            html: html,
                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                            confirmButtonText: "Yes, upload it!",
                                        }).then(async (result) => {
                                            if (result.isConfirmed) {
                                                handleUploadExcel();
                                            }
                                        });
                                    })
                                    .catch((err) => {
                                        if (err.response) {
                                            if (err.response.status == 500) {
                                                localStorage.clear();
                                                navigate("/login");
                                            }
                                            if (err.response.status == 422) {
                                                Swal.fire({
                                                    title: err.response.data.message,
                                                    icon: "danger",
                                                    showCancelButton: true,
                                                    confirmButtonColor: "#3085d6",
                                                    cancelButtonColor: "#d33",
                                                });
                                            } else {
                                                toast.error(err.response.data.message);
                                            }
                                        }
                                    });
                            }
                        });
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    }
                    if (err.response.status == 422) {
                        Swal.fire({
                            title: err.response.data.message,
                            icon: "danger",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                        });
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const handleUploadExcel = async () => {
        await axios
            .post(`${baseURL}/${ADMIN_INSERT_CSV_DATA}`, {
                headers: { authorization: token.token },
                isTabData: true,
            })
            .then(async (res) => {
                if (res.data.success == true) {
                    await axios
                        .post(`${baseURL}/${ADMIN_INSERT_CSV_DATA}`, {
                            headers: { authorization: token.token },
                            isGroupData: true,
                        })
                        .then(async (res1) => {
                            if (res1.data.success == true) {
                                await axios
                                    .post(`${baseURL}/${ADMIN_INSERT_CSV_DATA}`, {
                                        headers: { authorization: token.token },
                                        isQuestion: true,
                                    })
                                    .then(() => {
                                        Swal.fire(res.data.message, " ", "success").then(async () => {
                                            props.fetchData();
                                            props.fetchTabList();
                                        });
                                    })
                                    .catch((err) => {
                                        if (err.response) {
                                            if (err.response.status == 500) {
                                                localStorage.clear();
                                                navigate("/login");
                                            } else {
                                                toast.error(err.response.data.message);
                                            }
                                        }
                                    });
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            })

            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    return (
        <>
            {" "}
            <button id="addRow" className="btn btn-success" onClick={handleShow}>
                {UPLOAD_EXCEL}
                <i className="fa fa-upload mx-1"></i>
            </button>
            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{UPLOAD_EXCEL}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="offset-3 col-md-6">
                            <input type="file" className="form-control" name="File" onChange={handleFileChange} />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <button id="addRow" className="btn btn-warning" onClick={props.handleDownload}>
                        {DOWNLOAD_EXCEL}
                        <i className="fa fa-download mx-1"></i>
                    </button>
                    <Button variant="primary  d-none" id="button" onClick={handleValidateUploadExcel}>
                        Validate
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        {CANCEL}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default UploadExcelFile;
