import React from "react";
import Cookies from "universal-cookie";

function Loader() {
    const cookies = new Cookies();

    let role = cookies.get("role");
    if (role) {
        role = cookies.get("role").roleId;
    }
    return (
        <>
            <tr className="" style={{ height: "20rem" }}>
                <td colSpan={15} className="text-center mt-5">
                    <i
                        className="fa fa-spinner fa-spin fa-2x"
                        style={{
                            position: "relative",
                            top: "2rem",
                            fontSize: "50px",
                            zIndex: 2,
                        }}
                    ></i>
                </td>
            </tr>
        </>
    );
}

export default Loader;
